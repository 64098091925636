CKEDITOR.on('dialogDefinition', ev => {
  const oSelf = ev.sender.currentInstance.akElm;

  if (!oSelf)
    ev.cancel();

  // Take the dialog name and its definition from the event data.
  const dialogName = ev.data.name;
  const dialogDefinition = ev.data.definition;
  const dialog = dialogDefinition.dialog;

  dialog.on('show', function() {
    /* if the dialog hasn't been moved yet, set original position */
    if ($(this.parts.dialog.$).css('position') !== 'absolute') {
      $(this.parts.dialog.$).css(
        {
          'position': 'absolute',
          'top': `calc(50% - ${this.getSize().height / 2}px)`,
          'left': `calc(50% - ${this.getSize().width / 2}px)`
        }
      );
    }
  });

  // Check if the definition is from the dialog we're
  // interested on (the "Image" dialog).
  if (dialogName == 'image' || dialogName == 'image2') {
    // Get a reference to the "Image Properties" tab.
    const infoTab = dialogDefinition.getContents('info');

    // Get a reference to the "Browse Server" button.
    const browse = infoTab.get('browse');
    // Instruct filebrowser plugin to skip hooking into this button.
    browse['filebrowser'] = false;
    // The "Browse Server" button is hidden by default.
    browse['hidden'] = false;
    // Add our own callback.
    browse['onClick'] = function() {
      const imgDialog = this.getDialog();
      const cZIdx1 = CKEDITOR.document.findOne('.cke_dialog_background_cover').getStyle('z-index');
      const cZIdx2 = imgDialog.parts.dialog.getStyle('z-index');

      // move ckeditor image dialog into background
      CKEDITOR.document.findOne('.cke_dialog_background_cover').setStyle('z-index', '-1');
      imgDialog.parts.dialog.setStyle('z-index', '-1');

      const oPromise = app.controller.launchContainer({
        caller:	oSelf.parent,
        containerName: 'chooseFileG',
        pages: '0,1',
        fetchOnInit: true
      });

      oPromise.done(oDlg => {
        oDlg.dhx.setModal(true);
        oDlg.callBack = function(chosenFile, pressedCancel) {
          // bring back ckeditor image dialog into foreground
          CKEDITOR.document.findOne('.cke_dialog_background_cover').setStyle('z-index', cZIdx1);
          imgDialog.parts.dialog.setStyle('z-index', cZIdx2);
          if (pressedCancel === true) {
            // handled in crm.serverFileChosen: oSelf.dynObject.container.controller.close();
            return;
          } else
          if (chosenFile) imgDialog.getContentElement('info', 'src').setValue(chosenFile);

        };
      });
    };
  }
});

// Focus trap handling when launching dialog
CKEDITOR.on('dialogDefinition', e => {
  const dialogDefinition = e.data.definition;
  dialogDefinition.onShow = function() {
    akioma.swat.MasterLayout.disableLastFocusTrap();
  };
  dialogDefinition.onHide = function() {
    akioma.swat.MasterLayout.enableLastFocusTrap();
  };
});
