LoadDataHelper.loadData = function(cValue, akElm, fn) {
  const aVals = cValue.split('|');
  if (cValue && cValue[0] == '$') {
    // load values from business entity
    const sBEName = aVals[0].substr(1);
    const extKey = aVals[1];

    try {
      const oBEoptions = {
        'att': {
          cacheLimit: 50,
          catalogURI: '',
          dataSource: '',
          entityName: 'eGenericListEntry',
          extKey: extKey,
          foreignKey: '',
          id: 'dgenericlistentry',
          identifier: 'dgenericlistentry',
          name: 'businessEntity',
          resourceName: sBEName,
          serviceURI: ''
        }
      };

      const be = new $['ak_businessEntity'](oBEoptions);

      be.finishConstruct();

      // get key values from business entity
      be.openQuery({}, data => {
        if (fn)
          fn(data);
      });
    } catch (oErr) {
      akioma.log.error(`Error loading data from BE to ${akElm.opt.view}`, oErr);
    }
  } else
    fn(aVals);

};
