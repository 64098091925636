/**
 * Class for Data Store Adapter
 * @class DataAdapter
 */
class DataAdapter {
  constructor() {
    /**
    * Event subscribers.
    *
    * @private
    *
    * @property {Object}
    */
    this._subscribers = {};
  }

  /**
   * Emit an event to all subscribers.
   *
   * @private
   *
   * @param {string} eventName Name of the event to emit.
   * @param {Object} data Data emitted.
   * @param {boolean} [success=true] If the emitted event is successful (not an error).
   *
   * @return {Promise}
   */
  _emitEvent(eventName, data, success = true) {
    if (isNull(this._subscribers[eventName]))
      return Promise.resolve();

    const emitterPromises = this._subscribers[eventName].map(
      callback =>
        new Promise(resolve => {
          try {
            const callbackParameters = akioma.DataAdapterHelper.convertEventParameter(eventName, data, this, success);

            callback(...callbackParameters);
          } catch (error) {
            this._log.warn(error);
          }

          resolve();
        })
    );

    return Promise.all(emitterPromises);
  }

  /**
   * Subscribe to an event from this data adapter.
   *
   * @param {string} eventName Name of the event on which to subscribe to.
   * @param {function} callback Function callback to execute when event fires.
   */
  subscribe(eventName, callback) {
    if (isNull(this._subscribers[eventName]))
      this._subscribers[eventName] = [];

    this._subscribers[eventName].push(callback);
  }

  /**
   * Unsubscribe from an event from this data adapter.
   *
   * @param {string} eventName Name of the event on which to unsubscribe.
   * @param {Object|null} callback 'Who' (callback function) is unsubscribing from this event. </br>
   *                              If null, it will unsubscribe all specified eventName subscribers.
   */
  unsubscribe(eventName, callback) {
    if (isNull(this._subscribers[eventName]))
      return;

    const index = this._subscribers[eventName].indexOf(callback);

    if (index === -1)
      return;

    this._subscribers[eventName].splice(index, 1);

    if (this._subscribers[eventName].length === 0)
      delete this._subscribers[eventName];
  }

  /**
   * Unsubscribe all subscribers from this adapter.
   */
  unsubscribeAll() {
    this._subscribers = {};
  }
}

akioma.DataAdapter = DataAdapter;
