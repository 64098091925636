akioma.gridHideViewColumns = function(oCaller) {
  const dhxWins = new dhtmlXWindows(oDhx.skin);
  const w = dhxWins.createWindow({
    id: 'showhide_w',
    caption: 'Spalten-Einstellungen',
    width: 350,
    height: 500,
    center: true
  });
  w.button('park').hide();
  w.button('minmax').hide();
  w.setModal(true);

  const tmpGrid = w.attachGrid();
  tmpGrid.setImagePath(oDhx.imagePath);
  tmpGrid.setHeader('Visible, Column');
  tmpGrid.setInitWidths('70,*');
  tmpGrid.setColAlign('center,left');
  tmpGrid.setColTypes('ch,ro');
  tmpGrid.init();
  const data = [];
  for (let i = 0; i < oCaller.dhx.getColumnCount(); i++)
    data.push(Array(!oCaller.dhx.isColumnHidden(i), oCaller.dhx.getColumnLabel(i)));
  tmpGrid.parse(data, 'jsarray');
  tmpGrid.attachEvent('onCellChanged', (rId, cInd, nValue) => {
    oCaller.dhx.setColumnHidden(rId - 1, !+nValue);
    UserProfile.saveLocalProfileData(oCaller, akioma.getGridColumnSettings(oCaller));
  });
};

akioma.getDefaultEQOperator = function(id) {
  const q = EQ.client.getQuery();
  const aAttrs = q.getModel().model.rootEntity.subEntities[0].attributes;
  for (let i = 0; i < aAttrs.length; i++) {
    if (aAttrs[i].id == id)
      return aAttrs[i].defaultOperator;
  }
};

// get filter in grid window
akioma.gridFilterFields = function(oCaller, elm, cType) {
  if (cType == 'popup') {
    let myPop;

    const showPopup = inp => {
      if (myPop.isVisible())
        myPop.hide();
      else {
        const x = inp.offset().left;
        const y = 0;
        const w = 400;
        const h = 200;
        myPop.show(x, y, w, h);
      }
    };

    if (!myPop) {
      myPop = new dhtmlXPopup();
      myPop.attachHTML('<div id="toolbar-grid-header"></div><div style="width: 450px;" id="queryBuilder-test-popup"></div>');
    }

    const $table = elm.closest('table');

    $(myPop.p).find('.dhx_popup_area').addClass('osivgridfilterpopup');
    if (akioma.gridFilterFieldsPopup) {
      akioma.gridFilterFieldsPopup.unload();
      delete akioma.gridFilterFieldsPopup;
    }

    myPop.attachEvent('onShow', () => {
      myPop.p.style.top = `${$table.offset().top + elm.parent().parent().height()}px`;
      myPop.p.style.left = `${elm.offset().left - 20}px`;
      $(myPop.p).find('.dhx_popup_arrow').css({ 'marginLeft': '-39%' });
    });
    const query = EQ.client.getQuery();

    myPop.attachEvent('onShow', () => {
      oCaller.tempAfterCallback = function() {
        oCaller.fnAfterChangedCallback(oCaller);
      };
      query.addChangedCallback(oCaller.tempAfterCallback);
    });

    myPop.attachEvent('onHide', () => {
      query.removeChangedCallback(oCaller.tempAfterCallback);
      akioma.gridFilterFieldsPopup.unload();
      oCaller.bGridFilterOpened = false;
      delete akioma.gridFilterFieldsPopup;
    });
    showPopup(elm);

    akioma.gridFilterFieldsPopup = myPop;
    oCaller.gridFilterFieldsPopup = myPop;

    const myToolbar = new dhtmlXToolbarObject('toolbar-grid-header');
    myToolbar.setIconset('awesome');

    myToolbar.addButton(2, 1, null, 'fa fa-plus');
    myToolbar.addButton(1, 2, null, 'fa fa-close');

    myToolbar.setAlign('right');
    const oSelf = oCaller;

    EQ.client.defaultQuery.query.root.linkType = 'Any';

    const opts = {
      'view': 'queryBuilder',
      'att': {
        'id': 'akQuery__ID___testQ',
        'mainOperator': 'and',
        'attachmentID': 'queryBuilder-test-popup',
        'queryFieldFormat': '{attr}'
      },
      'sub': []
    };

    const oNewFilterScreen = app.controller.parseProc(opts, oSelf);

    oCaller.queryWindow = oNewFilterScreen;

    myToolbar.attachEvent('onClick', id => {
      // on save
      if (id == 1)
        myPop.hide();
      else if (id == 2) {
        const q = EQ.client.getQuery();
        const cColID = oSelf.cColumnIdCurrentlyFiltered;
        oSelf.bSkipValueUpdates = true;
        const cDefaultOperator = akioma.getDefaultEQOperator(id);
        q.addSimpleCondition({ attr: `${oSelf.dataSource.entityName}.${cColID}`, operator: cDefaultOperator, value: new String(), enabled: true });

        oSelf.bSkipValueUpdates = false;
      }
    });
  } else if (cType == 'window') {
    const w = akioma.mainWindows.createWindow({
      id: 'showhide_w',
      caption: 'Filtern',
      width: 730,
      height: 350,
      center: true
    });
    w.button('park').hide();
    w.button('minmax').hide();
    w.setModal(true);
    w.centerOnScreen();
    w.attachHTMLString('<div id="queryBuilder-test-popup"></div>');

    const myToolbar = w.attachToolbar();
    myToolbar.addButton(1, 0, 'Filter');
    myToolbar.addButton(2, 1, 'Cancel');
    const oSelf = oCaller;
    const dhxWinCell = w;

    const opts = {
      'view': 'queryBuilder',
      'att': {
        'id': 'akQuery__ID___testQ',

        'attachmentID': 'queryBuilder-test-popup'
      },
      'parent': oCaller,
      'sub': []
    };
    const oNewFilterScreen = new $.ak_queryBuilder(opts);
    oNewFilterScreen.finishConstruct();

    myToolbar.attachEvent('onClick', id => {
      // on save
      if (id == 1) {
        oNewFilterScreen.updateAkFilter(oSelf.dataSource.query);
        oSelf.dataSource.openQuery();
      } else
        dhxWinCell.close();

    });
  }
};

// get columns settings
akioma.getGridColumnSettings = function(oCaller) {
  const oGrid = oCaller.dhx;
  const oSettings = {};

  oSettings.multiline = oCaller.bMultiline;
  oSettings.columns = [];
  for (let i = 0; i < oGrid.getColumnsNum(); i++) {
    oSettings.columns.push({
      hidden: oGrid.isColumnHidden(i),
      size: oGrid.isColumnHidden(i) ? (parseInt(oGrid.hdr.rows[0].cells[i]._oldWidth) || oGrid.initCellWidth[i]) : oGrid.getColWidth(i),
      id: oGrid.getColumnId(i)
    });
  }
  return oSettings;
};

// apply columns settings
akioma.applyGridColumnSettings = function(oCaller, settings) {
  try {
    if (!settings)
      return;

    const oGrid = oCaller.dhx;

    if (typeof settings == 'string')
      settings = JSON.parse(settings);

    // apply multiline settings
    oCaller.bMultiline = settings.multiline;
    const oTable = $(oCaller.dhx.obj);
    if (oCaller.bMultiline == true)
      oTable.removeClass('row20px');
    else
      oTable.addClass('row20px');

    let columns;
    if (settings.columns)
      columns = settings.columns;
    else
      columns = settings;

    for (let i in columns) {
      i = Number(i);

      let colind = -1;
      // get actual index of column // DO NOT USE getColIndex (dhtmlx v5.0.2) because it breaks data loading
      for (let j = 0; j < oGrid.columnIds.length; j++) {
        if (oGrid.columnIds[j] == columns[i].id) {
          colind = j;
          break;
        }
      }
      // Fix a bug when i >= oGrid.columnIds.length then on oGrid.columnIds on position i there will be an undefined value
      if (i >= oGrid.columnIds.length)
        i = oGrid.columnIds.length - 1;

      if (i != colind && colind != -1)
        oGrid.moveColumn(colind, i);

      if (columns[i].size != oGrid.getColWidth(i))
        oGrid.setColWidth(i, columns[i].size);
      if (columns[i].hidden != oGrid.isColumnHidden(i))
        oGrid.setColumnHidden(i, columns[i].hidden);
    }
  } catch (e) {
    !_isIE && console.error('Error loading grid settings', e);
  }
};

akioma.launchGridScreenIdentifyGrid = function(self) {
  let grid = null;
  switch (self.type) {
    case 'datagrid2':
      grid = self.controller;
      break;
    case 'toolbar':
      grid = self.getLink('TOOLBAR:TARGET').controller;
      break;
  }
  return grid;
};

akioma.launchGridMaintenanceScreen = function(self) {
  const grid = akioma.launchGridScreenIdentifyGrid(self);
  if (isNull(grid)) {
    akioma.log.error('Could not identify grid for launch!');
    return;
  }

  // establish launch screen
  let screen = grid.opt.updateRecordContainer;
  if (screen == '')
    screen = grid.opt.FolderWindowToLaunch;
  if (screen == '') {
    akioma.log.error('Could not idntify screen to launch!');
    return;
  }

  // identify currently selcted record
  const dataSource = grid.dataSource;
  if (isNull(dataSource)) {
    akioma.log.error('Could not identify grid data source!');
    return;
  }
  const recordHdl = dataSource.getIndex();
  if (isNull(recordHdl)) {
    akioma.log.error('Could not identify current record!');
    return;
  }

  // launch the screen
  akioma.launchContainer(screen, recordHdl);
};

akioma.launchGridCreateDialog = function(self) {
  const grid = akioma.launchGridScreenIdentifyGrid(self);
  if (isNull(grid)) {
    akioma.log.error('Could not identify grid for launch!');
    return;
  }

  // establish launch screen
  const screen = grid.opt.addRecordContainer;
  if (screen == '') {
    akioma.log.error('Could not idntify screen to launch!');
    return;
  }

  // launch the screen
  return app.controller.launchContainer({
    containerName: screen,
    autoAdd: true
  });
};

// global context function
// eslint-disable-next-line no-unused-vars
function getRowsToBatchOptions(oSelf) {
  const aData = [ { 'hdl': 50, 'value': 50 }, { 'hdl': 100, 'value': 100 }, { 'hdl': 200, 'value': 200 }, { 'hdl': 500, 'value': 500 }, { 'hdl': 750, 'value': 750 }, { 'hdl': 1000, 'value': 1000 } ];
  oSelf.setComboOptions(aData);
  dhtmlx.delay(() => {
    oSelf.click(aData[0].hdl);
  });
}

akioma.launchOwnerWithChild = function(self, cScreen, cOwnerField, cChildField) {
  const oGrid = self.parent;
  const cOwnerHdl = oGrid.dataSource.dynObject.getValue(cOwnerField);
  const cChildHdl = oGrid.dataSource.dynObject.getValue(cChildField);
  app.controller.launchContainer({
    containerName: cScreen,
    data: true,
    extLink: cOwnerHdl,
    pages: '1,2',
    self: oGrid,
    customData: { childHdl: cChildHdl }
  });
};
