

/**
 * Generic method for opening new record dialog
 * @param  {Object} self      The dynObject
 * @param  {String} cRepoName The repository name to load the new record screen.
 */
akioma.openNewRecordDialog = function(self, cRepoName) {
  // launch a container
  const promise = app.controller.launchContainer({
    containerName: cRepoName,
    fetchOnInit: true,
    autoAdd: true,
    caller: self
  }).done(oRes => {
    const BE = oRes.dynObject.getLink('PRIMARYSDO:TARGET').controller;
    if (BE)
      BE.stop = true;
  });

  return promise;
};

/**
 * Generic method for saving in changes in a Business Entity from a Form
 * @param  {object} self An Akioma DynObject
 */
akioma.saveRecord = function(self) {
  akioma.skipQuery = true;

  const BE = self.controller.dataSource;
  if (self.controller.dhx.validate())
    BE.updateRecord({});
  akioma.skipQuery = false;
};

/**
 * Method used for saving the new record and opening the newly created record
 * @param  {Object} self      The dynObject
 * @param  {String} cRepoName The repository object name used for loading the newly created record
 * @param {object} oOpts Extra options settings
 * @param {object} oOpts.closeWindow Specify if window should be closed or not
 */
akioma.saveNewRecord = function(self, cRepoName, oOpts) {
  const oWin = self.container;
  const oForm = oWin.controller.getDescendant('form');
  let bCloseWin = true;

  if (oOpts && oOpts.closeWindow != undefined)
    bCloseWin = oOpts.closeWindow;

  akioma.saveRecord (oForm.dynObject);

  oForm.dataSource.addAfterSaveChangesOnceCallback((success, jsdo, data) => {
    if (success) {
      if (bCloseWin)
        oWin.controller.close();

      if (cRepoName) {
        // added option to specify custom reposition to source field
        let cHdl;
        if (oOpts && oOpts.keyField != undefined)
          cHdl = data[oOpts.keyField];
        else
          cHdl = data.selfhdl;

        app.controller.launchContainer({
          containerName: cRepoName,
          repositionTo: cHdl,
          fetchOnInit: true,
          caller: self
        });
      }
    }
  });
};

/**
 * Generic method for copy new record dialog
 * @param  {Object} self      The dynObject
 * @param  {String} cRepoName The repository name to load the new record screen.
 */
akioma.openCopyRecordDialog = function(self, cRepoName) {
  // launch a container
  const cSelectedHdl = self.container.getLink('PRIMARYSDO:TARGET').getValue('selfhdl');
  const promise = app.controller.launchContainer({
    proc: 'launchContainer.r',
    para: `RunFile=${cRepoName}&Page=*`,
    data: true,
    async: true
  });


  promise.done(oRes => {
    const BE = oRes.dynObject.getLink('PRIMARYSDO:TARGET').controller;

    BE.stop = true;
    // wait for catalog load and then getInitialValues copyRecord
    BE.addAfterCatalogAdd(() => {
      BE.copyRecord(cSelectedHdl);
    });
  });
};

/**
 * Method for setting a dynSelect value from an external screen
 * @param  {Object} self      The dynObject
 */
akioma.chooseWindowRecordSelected = function(self) {
  const oWindow = self.controller.getAncestor('window');

  let oGrid;
  if (self.type == 'toolbar')
    oGrid = oWindow.getDescendant('datagrid2') || oWindow.getDescendant('dataview');
  else
    oGrid = self.controller;

  const oDynSelect = oWindow.caller;
  const oSelectedRecords = oGrid.getSelectedRecords();
  oDynSelect.bChooseWindow = true;
  oDynSelect.dynSelectControl.positionToRecord(oSelectedRecords);

  oWindow.close();
};

/**
 * Method for closing a screen
 * @param  {Object} self      The dynObject
 */
akioma.chooseWindowClose = function(self) {
  const oWindow = self.controller.getAncestor('window');
  oWindow.close();
};
