/**
 * Toolbar cancel default behavior in chooseFileG window
 * @param self the event source dynObject
 */
akioma.onToolbarCancelChooseFile = function(self) {
  const chooseFileG = self.parent.controller; // use parents instead of container for desktop windows

  if (Object.getPrototypeOf(chooseFileG) !== $.ak_window.prototype)
    return;

  chooseFileG.close();
};

/**
 * Toolbar cancel default behavior in chooseFileG window
 * @param self the event source dynObject
 */
akioma.onToolbarGoChooseFile = function(self) {
  const chooseFileG = self.parent.controller; // use parents instead of container for desktop windows
  const customData = chooseFileG.customData;
  const callback = customData.callback;

  if (self.parent.controller.caller.opt && self.parent.controller.caller.opt.name === 'textdocument')
    akioma.text.afterChooseDocument(self);

  app.controller.callAkiomaCode(self, callback);
};

/**
 * onBeforeFetch event handler for the DFileList datasource used in the chooseFileG window
 * @param self the event source dynObject
 */
akioma.onBeforeFetchDFileList = function(self) {
  const dFileList = self.controller;
  const chooseFileG = self.parent.controller; // use parents instead of container for desktop windows
  const groupHdl = chooseFileG.customData.groupHdl;

  dFileList.newfilter.clearAll();
  dFileList.newfilter.addCondition('groupHdl', 'eq', groupHdl);
};

/**
 * UploadComplete event handler in the chooseFileUploadV upload file control
 * @param self the event source dynObject
 */
akioma.onUploadCompleteChooseFile = function(self) {
  const chooseFileG = self.parent; // use parents instead of container for desktop windows
  const dFileList = chooseFileG.getObject('dFileList').controller;

  dFileList.openQuery();
};

/**
 * UploadFileAdd event handler in the chooseFileUploadV upload file control
 * @param self the event source dynObject
 */
akioma.onUploadFileChooseFile = function(self) {
  const chooseFileG = self.parent; // use parents instead of container for desktop windows
  const customData = chooseFileG.controller.customData;

  const gcFiles = self.getObject('gcFiles').controller;
  const dirImg = chooseFileG.getObject('DirImg');
  const imageTree = chooseFileG.getObject('imageTree').controller;

  const dir = imageTree.getIndex();
  const groupHdl = customData.groupHdl;
  const rootHdl = dirImg.getValue('selfhdl');

  gcFiles.setURL(`/web/Upload?GroupHdl=${encodeURIComponent(groupHdl)}&RootHdl=${encodeURIComponent(rootHdl)}&DirName=${encodeURIComponent(dir)}`);
};

/**
 * Selects a directory in the toolbar in the chooseFileG window
 * @param self the event source dynObject
 * @param combo combobox field name
 */
akioma.serverDirChosen = function(self, combo) {
  combo = combo || 'dirImg';

  const chooseFileG = self.parent; // use parents instead of container for desktop windows
  const dFileList = chooseFileG.getObject('dFileList').controller;

  const SelfHdl = self.getValue(combo);
  const NavTarget = self.getLink('NAVIGATION:TARGET').controller;

  NavTarget.openQuery({ extLink: SelfHdl });

  dFileList.dhx.clearAll();
};

/**
 * Selects a file in the toolbar in the chooseFileG window
 * @param self the event source dynObject
 * @param combo combobox field name
 */
akioma.serverFileChosen = function(self, cancelled) {
  const dFileList = self.container.getObject('dFileList');

  let filename = '';

  try {
    filename = dFileList.getValue('clientfilename');
  } catch (err) {
    filename = '';
    akioma.log.error([ 'Error:', err ]);
  }

  if (self.container.controller.callBack)
    self.container.controller.callBack(filename, cancelled);
  else {
    self.container.caller.controller.prop.fullPath = filename;
    if (self.container.caller.controller.oImgBoxActive != undefined)
      self.container.caller.controller.oImgBoxActive.setValue(filename);

    if (self.container.caller.controller && self.container.caller.controller.view == 'form') {
      const form = self.container.caller.controller;
      form.dhx.setItemValue('templatefile', filename);
    } else {
      const form = self.container.caller.getLink('TABLEIO:TARGET').controller;
      form.dhx.setItemValue('image', filename);
    }
  }

  self.container.controller.close();
};

akioma.downloadDocument = function(self) {

  const view = self;
  const dataSource = self.getLink('DATA:SRC');
  const selfHdl = dataSource.getValue('selfhdl');
  const filename = dataSource.getValue('cfilename');

  if (!view.controller.dhx.getSelected() || !selfHdl)
    return;

  const url = `/web/ExternalDocument?SelfHdl=${encodeURIComponent(selfHdl)}`;

  fetch(url).then(response => {
    if (response.status != 200) {
      response.json().then(err => {
        akioma.NotificationMessage.showError({ text: err.returnValue });
      });
    } else {
      response.blob().then(data => {
        const url = URL.createObjectURL(data);
        const link = document.createElement('a');

        link.setAttribute('type', 'hidden'); // make it hidden if needed
        link.download = filename;
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    }
  });
};

/**
 * Method used for toggling SwatPanelSwitcher from extdocf
 *
 * @param   {object}  self
 *
 */
akioma.toggleExternalDocFrame = function(self) {
  const oPanelSw = self.container.getFirstChildByType('panelSwitcher');
  const currentView = oPanelSw.getCurrentView();
  switch (currentView) {
    case 'extdocsdatagridf':
      oPanelSw.switchView('extdocsdataviewf');
      break;
    case 'extdocsdataviewf':
      oPanelSw.switchView('extdocsdatagridf');
      break;
  }
};

akioma.serverExternalDocumentFileChosen = function(self) {
  const container = self.container;

  // filename selected
  const dFileList = container.getObject('dFileList');
  const filename = dFileList.getValue('clientfilename');

  if (!filename) {
    akioma.NotificationMessage.showError({ text: 'Datei nicht ausgewählt' });
    return;
  }

  // find the source SelfHdl of Master
  const oRecHdl = container.caller.topScreen.getLink('PRIMARYSDO:TARGET').getValue('selfhdl');

  akioma.invokeServerTask({
    name: 'Akioma.Crm.MasterData.General.ExternalDocumentsBT',
    methodName: 'LinkExternalDocument',
    paramObj: {
      plcParameter: {
        RecHdl: oRecHdl,
        DocumentName: filename
      }
    }
  }).then(() => {
    const oDataSource = container.caller.controller.dataSource;
    oDataSource.reloadData();
    container.controller.close();
  });
};

akioma.externalFileDrop = function(self, file) {
  const cParent = self.parent.parent.name;
  let cFullPath = '';

  cFullPath = file[0].mozFullPath;
  if (!cFullPath)
    cFullPath = file[0].name;

  self.parent.controller.prop.fullPath = cFullPath;

  if (cParent != 'extdocf')
    return;

  if (cParent == 'imagedisabledf') {
    akioma.message({ text: 'Bild ist Read-Only!', type: 'error' });
    return;
  }

  if (cParent == 'imagef') {
    const oFrame = self.parent.parent.parent;
    const oSDO = oFrame.parent.getLink('PRIMARYSDO:TARGET');
    const cHdl = oSDO.getValue('selfhdl');
    const cType = self.parent.getValue('imageType');
    const cFile = file[0].name;
    const oReturn = app.controller.callServerMethod('std/img/saveImage.p', [
      { type: 'iCHAR', value: cHdl },
      { type: 'iCHAR', value: cType },
      { type: 'iCHAR', value: cFile },
      { type: 'iCHAR', value: '' },
      { type: 'iCHAR', value: '' },
      { type: 'oCHAR', name: 'cStatus' }
    ]);
    akioma.messaging.info(`document added:</br>${cFile}</br>${oReturn.cStatus}`);
    return;
  }

  if (cParent == 'Mailingg') {
    const oViewer = self.container.getObject('ChooseFormMailingV');
    const oField = oViewer.getField('gcdocfile');
    oField.parent.setValue('gcdocfile', file[0].name);
  }

  if (cParent == 'extdocf') {
    const oFrame = self.parent.parent.parent;
    const oSDO = oFrame.parent.getLink('PRIMARYSDO:TARGET');
    const oGrid = self.parent.getLink('TABLEIO:TARGET');
    const cHdl = oSDO.getValue('selfhdl');
    const cFile = file[0].name;

    app.controller.callAction({
      ActionType: 'PUBLISH',
      Action: 'recordAdd',
      Category: 'FILTER',
      RunPar: null,
      CallObject: null,
      caller: self.parent.controller /* addDB*/
    },
    self.parent.controller);

    if (oGrid)
      oGrid.controller.recordAdd();

    const oReturn = app.controller.callServerMethod('std/extdoc/addExternalDoc.p', [
      { type: 'iCHAR', value: cHdl },
      { type: 'iCHAR', value: cFile },
      { type: 'iCHAR', value: '' },
      { type: 'iCHAR', value: '' },
      { type: 'oCHAR', name: 'cStatus' }
    ]);

    akioma.messaging.info(`unable to access image:</br>${cFile}</br>${oReturn.cStatus}`);
    return;
  }
};

/**
 * Called when the GenericImportW initializes
 * @param {object} self The akioma object
 * @return {void}
 */
akioma.initializeGenericImport = function(self) {
  if (self.controller.customData) {
    let functionIdentifier = self.controller.customData.guid;
    if (isNull(functionIdentifier))
      functionIdentifier = self.launchedFrom.name;
    if (isNull(functionIdentifier))
      akioma.log.error('Could not indentify valid import function identifier!');

    const title = self.controller.customData.title || 'Import data';

    const url = `web/Excel/Upload?function=${encodeURIComponent(functionIdentifier)}`;
    const gcFiles = self.getObject('gcFiles').controller;

    // there is no setURL in the Akioma API
    gcFiles.setURL(url);

    self.container.controller.setTitle(title);
  }
};

/**
 * Client logic for template screens
 */
akioma.expDefOtherV = {
  /**
 * After displat event on templae screen's expDefOtherV
 * Will automatically assign targettype to '.docx' when invalid
 * @param {object} self the form object
 */
  afterDisplayEvent: function(self) {
    if (self.getValue('targettype') !== '.docx' && self.getValue('targettype') !== '.pdf') {
      self.controller.dataSource.setFieldValue({ name: 'targettype', value: '.docx' });
      self.setValue('targettype', '.docx');
      self.controller._dispatch('setFormFieldDirty', { hasChanges: true, id: self.getObject('targettype').controller.opt.id });
    }
  }
};
