/**
 * Class for handling menu maintenance screens
 *
 * @class
 */
// global context class
// eslint-disable-next-line no-unused-vars
class MenuMaintenance {

  /**
     * Method for displaying the json serialized parameter in a data field
	 * @static
     * @memberOf MenuMaintenance
     * @param {object} oForm The form where the data will be displayed
	 * @param {string} cJsonFormField The name of the data field in which to display the json
	 * @param {string} cJsonDataField The name of the field in the form's datasource where the json is located
     */
  static displayJson(oForm, cJsonFormField, cJsonDataField) {
    const oDataSource = oForm.dataSource;
    const oField = oForm.dynObject.getField(cJsonFormField);

    if (oDataSource && oField) {
      const cParameterString = oDataSource.getFieldValue(cJsonDataField);
      oField.setValue(cParameterString);
    }
  }

  /**
     * Method for assigning the value of the serialized json parameter from a data field to the data source
	 * @static
     * @memberOf MenuMaintenance
     * @param {object} oForm The form where the data will be displayed
	 * @param {string} cJsonFormField The name of the data field from where to fetch the json
	 * @param {string} cJsonDataField The name of the field in the form's datasource where the json will be stored
     */
  static storeJson(oForm, cJsonFormField, cJsonDataField) {
    const oDataSource = oForm.dataSource;
    const oField = oForm.dynObject.getField(cJsonFormField);

    if (oDataSource && oField && oForm.getFieldHasChanges(cJsonFormField)) {
      const cParameterString = oField.getValue();
      oDataSource.setFieldValue({ name: cJsonDataField, value: cParameterString });
    }
  }

  /**
     * Method for displaying the json serialized parameter in a form field container (block, fieldset)
	 * @static
     * @memberOf MenuMaintenance
     * @param {object} oForm The form where the data will be displayed
	 * @param {string} cJsonContainer The name of the form field container
	 * @param {string} cJsonFieldName The name of the field in the form's datasource where the json is located
	 * @param {string} cValidType The fields will be populated only if it matches the json's SerializedType value or if it is '*'
     */
  static displayJsonProperties(oForm, cJsonContainer, cJsonFieldName, cValidType) {
    const oDataSource = oForm.dataSource;
    const oPropertiesFieldset = oForm.dynObject.getField(cJsonContainer);

    if (oDataSource && oPropertiesFieldset) {
      const cParameterString = oDataSource.getFieldValue(cJsonFieldName);
      const oParameterObject = JSON.parse(cParameterString);

      if (isNull(oParameterObject)) return;

      if (cValidType != '*' && oParameterObject.SerializedType && oParameterObject.SerializedType != cValidType) return;

      for (const cFieldName in oPropertiesFieldset.childs) {
        const oField = oPropertiesFieldset.childs[cFieldName].controller;
        const cFieldInstanceName = oField.opt.InstanceName;

        // setInitialValue doesn't reset the field value to initial, if field.opt.initial is undefined
        // this is used when resetting the fields of the serialized json for a new record
        if (oField.opt.initial)
          oField.setInitialValue();
        else
          oField.setValue('');

        if (Object.prototype.hasOwnProperty.call(oParameterObject, cFieldInstanceName))
          oForm.dynObject.setValue(cFieldInstanceName, oParameterObject[cFieldInstanceName]);
      }
    }
  }

  /**
     * Method for updating the serialized json with the fields of a form field container
	 * @static
     * @memberOf MenuMaintenance
     * @param {object} oForm The form where the source fields are located
	 * @param {string} cJsonContainer The name of the form field container
	 * @param {string} cJsonFieldName The name of the field in the form's datasource where the json will be stored
	 * @param {string} cValidType The data source will be updated only if it matches the json's SerializedType value or if it is '*'
     */
  static storeJsonProperties(oForm, cJsonContainer, cJsonFieldName, cValidType) {
    const oDataSource = oForm.dataSource;
    const oPropertiesFieldset = oForm.dynObject.getField(cJsonContainer);

    if (oDataSource && oPropertiesFieldset) {
      const cParameterString = oDataSource.getFieldValue(cJsonFieldName);
      const oParameterObject = JSON.parse(cParameterString);

      if (isNull(oParameterObject)) return;

      if (cValidType != '*' && oParameterObject.SerializedType && oParameterObject.SerializedType != cValidType) return;

      for (const cFieldName in oPropertiesFieldset.childs) {
        const oField = oPropertiesFieldset.childs[cFieldName].controller;
        const cFieldInstanceName = oField.opt.InstanceName;
        if (oForm.getFieldHasChanges(cFieldInstanceName)) {
          if (oField.opt.type == 'checkbox')
            oParameterObject[cFieldInstanceName] = (oField.getValue() == 'yes');
          else
            oParameterObject[cFieldInstanceName] = oField.getValue();
        }
      }

      oDataSource.setFieldValue({ name: cJsonFieldName, value: JSON.stringify(oParameterObject, null, 2) });
    }
  }

  /**
     * Method for displaying the json serialized parameter in a form
	 * @static
     * @memberOf MenuMaintenance
     * @param {object} oForm The form where the data will be displayed
	 * @param {string} cJsonFieldName The name of the field in the form's datasource where the json is located
     */
  static displayJsonPropertiesInForm(oForm, cJsonFieldName) {
    const oDataSource = oForm.dataSource;

    if (oDataSource) {
      const cParameterString = oDataSource.getFieldValue(cJsonFieldName);
      const oParameterObject = JSON.parse(cParameterString);

      if (isNull(oParameterObject)) return;

      const formChilds = oForm.childs.filter(child => child.view === 'fieldset');
      for (const cFieldset in formChilds) {
        const oFieldset = formChilds[cFieldset].dynObject;

        if (!oFieldset.controller.dhx.is(':hidden')) {
          for (const cField in oFieldset.childs) {
            const oField = oFieldset.childs[cField].controller;
            const cFieldInstanceName = oField.opt.InstanceName;
            if (cFieldInstanceName.startsWith('_')) continue;

            if (oField.opt.initial)
              oField.setInitialValue();
            else
              oField.setValue('');

            if (Object.prototype.hasOwnProperty.call(oParameterObject, cFieldInstanceName))
              oForm.dynObject.setValue(cFieldInstanceName, oParameterObject[cFieldInstanceName]);
          }
        }
      }
    }
  }

  /**
     * Method for updating the serialized json with the fields of a form
	 * @static
     * @memberOf MenuMaintenance
     * @param {object} oForm The form where the data will be displayed
	 * @param {string} cJsonFieldName The name of the field in the form's datasource where the json is located
     */
  static storeJsonPropertiesFromForm(oForm, cJsonFieldName) {
    const oDataSource = oForm.dataSource;

    if (oDataSource) {
      const cParameterString = oDataSource.getFieldValue(cJsonFieldName);
      const oParameterObject = JSON.parse(cParameterString);

      if (isNull(oParameterObject)) return;

      const formChilds = oForm.childs.filter(child => child.view === 'fieldset');
      for (const cFieldset in formChilds) {
        const oFieldset = formChilds[cFieldset].dynObject;

        if (!oFieldset.controller.dhx.is(':hidden')) {
          for (const cField in oFieldset.childs) {
            const oField = oFieldset.childs[cField].controller;
            const cFieldInstanceName = oField.opt.InstanceName;
            if (cFieldInstanceName.startsWith('_')) continue;

            if (oForm.getFieldHasChanges(cFieldInstanceName)) {
              if (oField.opt.type == 'checkbox')
                oParameterObject[cFieldInstanceName] = (oField.getValue() == 'yes');
              else
                oParameterObject[cFieldInstanceName] = oField.getValue();
            }
          }
        }
      }
      oDataSource.setFieldValue({ name: cJsonFieldName, value: JSON.stringify(oParameterObject, null, 2) });
    }
  }

  /**
     * Method for processing the serialized json parameter of a menu function
	 * @static
     * @memberOf MenuMaintenance
     * @param {object} oDataSource The menu function data source
	 * @param {string} cJsonFieldName The name of the json serialized parameter field
     */
  static processJsonData(oDataSource, cJsonFieldName) {
    const oWindow = oDataSource.parent;
    const oTabbar = oWindow.dynObject.getChildOfType('tabbar').controller;

    const cParameterString = oDataSource.getFieldValue(cJsonFieldName);
    const oParameterObject = JSON.parse(cParameterString);

    if (oParameterObject && oParameterObject.SerializedType) {
      const aRelFieldsets = [],
        cType = oParameterObject.SerializedType;
      let lHidePage = false,
        cTitle = 'Properties';

      switch (cType) {
        case 'Akioma.Swat.Studio.Menu.DynCombo':
          aRelFieldsets.push('dyncombo');
        // fallthrough
        case 'Akioma.Swat.Studio.Menu.Combo':
          aRelFieldsets.push('combo');
        // fallthrough
        case 'Akioma.Swat.Studio.Menu.Action':
        case 'Akioma.Swat.Studio.Menu.Input':
        case 'Akioma.Swat.Studio.Menu.Lookup':
        case 'Akioma.Swat.Studio.Menu.Toggle':
          aRelFieldsets.push('action');
        // fallthrough
        case 'Akioma.Swat.Studio.Menu.Label':
        case 'Akioma.Swat.Studio.Menu.Ribbon': {
          aRelFieldsets.push('base');
          const aTypeArray = cType.split('.');
          const cSimpleType = aTypeArray[aTypeArray.length - 1];
          cTitle = `${cSimpleType} Properties`;
          break;
        }
        default:
          lHidePage = true;
          break;
      }

      if (lHidePage) {
        oTabbar.viewPage('JSON');
        oTabbar.setActivePage('JSON');
        oTabbar.hidePage('Properties');
      } else {
        const oForm = oTabbar.getPage('Properties').getAllChildrenByType('form')[0];

        // SWAT-5056 if SwatDevTools is enabled, keep the dataSourceName object name alongside new title
        if (akioma.getSessionProperty('objectNamesInTitles')) {
          const titleParts = oForm.opt.title.split(' | ');
          titleParts[titleParts.length - 1] = cTitle;
          oForm.opt.title = titleParts.join(' | ');
        } else
          oForm.opt.title = cTitle;

        const oPanel = oForm.parent;
        if (oForm.opt.title && oPanel.opt.hideHeader != true && oPanel.setOption !== undefined)
          oPanel.setOption('title', oForm.opt.title);

        const formChilds = oForm.childs.filter(child => child.view === 'fieldset');
        for (const cFieldset in formChilds) {
          const oFieldset = formChilds[cFieldset];
          const cFieldsetName = oFieldset.dynObject.name;
          const cBaseName = aRelFieldsets.find(base => cFieldsetName.startsWith(base));
          if (isNull(cBaseName))
            oFieldset.dhx.hide();
          else
            oFieldset.dhx.show();
        }

        MenuMaintenance.displayJsonPropertiesInForm(oForm, cJsonFieldName);

        oTabbar.viewPage('Properties');
      }

      const oJsonForm = oTabbar.getPage('JSON').getAllChildrenByType('form')[0];
      MenuMaintenance.displayJson(oJsonForm, 'parameter', 'functioncallparameter');
    }
  }

  /**
     * Method for triggering the menu function save in the create dialog and launching and positioning it in the maintenance window
	 * @static
     * @memberOf MenuMaintenance
     * @param {object} oSelf The object where the method is called
     */
  static saveFunction(oSelf) {
    const oWin = oSelf.dynObject.container;
    const oForm = oWin.controller.getDescendant('form');

    akioma.saveRecord(oForm.dynObject);
    oForm.dataSource.fireAfterUpdate(function(jsdo, success, request, data) {
      if (success) {
        oWin.controller.close();

        app.controller.launchContainer({
          proc: 'menufunctiondetailwindow',
          para: 'Page=*',
          // repositionTo //after rendering check PRIMARYSDO and position
          repositionTo: data.functionguid,
          async: true,
          data: true,
          activation: true,
          extLink: '',
          self: this
        });
      }
    });
  }

  /**
     * Method for repositioning the menu function data source
	 * @static
     * @memberOf MenuMaintenance
     * @param {object} oSelf The object where the method is called
     */
  static repositionFunction(oSelf) {
    // conditionally, assign mapped fields
    const oForm = oSelf.dynObject.getParentOfType('form');
    if (oForm.getValue('menuname') == '') oForm.setValue('menuname', oSelf.businessEntity.getFieldValue('functionname'));
    if (oForm.getValue('menusmallimage') == '') oForm.setValue('menusmallimage', oSelf.businessEntity.getFieldValue('functionsmallimage'));
    if (oForm.getValue('menulargeimage') == '') oForm.setValue('menulargeimage', oSelf.businessEntity.getFieldValue('functionlargeimage'));

    // reposition function details frame data source
    const oFrame = oSelf.dynObject.screen;
    const oMenuBE = oFrame.childs.menustructd.controller;
    const oFunctionBE = oSelf.dynObject.screen.childs.MenuFunctionEntity.controller;
    oFunctionBE.query.addUniqueCondition('functionguid', 'eq', oMenuBE.getFieldValue('functionguid'));
    oFunctionBE.openQuery();
  }

  /**
     * Method for launching a menu function from the menu structure maintenance screen
	 * @static
     * @memberOf MenuMaintenance
     * @param {object} oSelf The object where the method is called
     */
  static launchFunction(oSelf) {
    const oContainer = getActionContainer(oSelf.dynObject, false);
    const oNavTarget = oContainer.getLink('PRIMARYSDO:TARGET');
    const cId = oNavTarget.getValue('functionguid');

    app.controller.launchContainer({
      proc: 'menufunctiondetailwindow',
      para: 'Page=*',
      // repositionTo //after rendering check PRIMARYSDO and position
      repositionTo: cId,
      async: true,
      data: true,
      activation: true,
      extLink: '',
      self: this
    });
  }

  /**
     * Method for launching any menu structure from a grid by its root and positioning to it
	 * @static
     * @memberOf MenuMaintenance
     * @param {object} oSelf The column from where the menu structure is launched
     */
  static launchMenuMaintenance(oSelf) {
    const oGrid = oSelf.parent;
    const cMenuHdl = oGrid.dataSource.dynObject.getValue('selfhdl');
    akioma.invokeServerTask({
      name: 'Akioma.Swat.Menu.Struct.MenuStructEntity',
      methodName: 'GetChainToRoot',
      paramObj: { plcParameter: { Value: cMenuHdl } }
    }
    ).done(oResult => {
      const cRootHdl = oResult.plcParameter.Value.split(',')[0];
      app.controller.launchContainer({
        containerName: 'menumaintenancew',
        data: true,
        pages: '1,2',
        self: oGrid,
        repositionTo: cRootHdl,
        customData: { MenuHdl: cMenuHdl }
      });
    });
  }

  /**
     * Method for positioning to a menu structure in a treegrid
	 * @static
     * @memberOf MenuMaintenance
     * @param {object} oWindow The window containing the treegrid
     */
  static positionToMenu(oWindow) {
    const oTreeGrid = oWindow.controller.getDescendant('treegrid');
    if (oWindow.controller.customData && oWindow.controller.customData.MenuHdl)
      oTreeGrid.reposition2Hdl(oWindow.controller.customData.MenuHdl);
  }

  /**
	 * Method for synchronizing values between form fields
	 * @param {object} oField The parent field
	 * @param {string} cCustomFields The children fields
	 */
  static synchronizeFields(oField, cCustomFields) {
    const cValue = oField.getValue();
    const oForm = oField.dynObject.getParentOfType('form');
    cCustomFields.split(',').forEach(fieldname => {
      const oSubField = oForm.getObject(fieldname).controller;
      if (oSubField.view === 'dynselect') {
        const oValue = {};
        oValue[oSubField.opt.lookupKeyValueBinding] = cValue;
        oValue[oSubField.opt.name] = cValue;
        oSubField.setValue(oValue);
      } else
        oSubField.setValue(cValue);
      if (!fieldname.startsWith('_'))
        oForm.controller.setFieldHasChanges(oSubField.opt.name, true);
    });
  }

  /**
	 * Custom handling for menu function action type parameter
	 * @param {object} oActionTypeField The menu function action type fields
	 */
  static functionActionTypeHandling(oActionTypeField) {
    const cValue = oActionTypeField.getValue();
    const oForm = oActionTypeField.dynObject.getParentOfType('form').controller;
    switch (cValue.toUpperCase()) {
      case 'LAUNCH':
        oForm.hideFormField('actionparameter');
        oForm.showFormField('_actionparameterrepomasterselect');
        break;
      default:
        oForm.showFormField('actionparameter');
        oForm.hideFormField('_actionparameterrepomasterselect');
        break;
    }
  }
}
