/**
* @typedef visibilityRules
* @example
* { "currentPage": "positionen,kopfdaten,!*", "currentNodeType": "pos.ofr.grpTxt,!*" }
* @type {Object}
* @property {string} currentPage - checks for CANDO pattern, for the tabbar/sidebar pages. <br/>
* eg. <code>1,2,3,4,!*</code> or by pagekey <code>positionen,kopfdaten,!*</code>
* @property {string} currentNodeType - checks for CANDO pattern for the treegrid nodes. <br/>
* for eg <code>pos.ofr.txt,!*</code>
* @property {string} customization - checks customization from sessionData.customization settings.
*/

/**
 * VisibilityRules class handles visibility rules check using Progress CANDO pattern, for ribbons or tabbar
 * @namespace VisibilityRules
 * @property {boolean} trace Display debug messages in console
 */
const VisibilityRules = { trace: false };

/**
 * Check rule for each ribbontab, ribbonblock, ribboncombo, ribbonbutton, ribboninput
 * @param  {object} oObj     The Akioma controll object (eg. ribbon)
 * @param  {integer} iNewPage The page/tab index
 * @param  {string} cPageKey The page/tab pagekey.
 * @memberOf VisibilityRules
 * @return {void}
 */
VisibilityRules.checkRibbonVisibilityRules = function(oObj, iNewPage, cPageKey) {

  // check for each ribbontab
  oObj.children('ribbontab', function() {

    VisibilityRules._callVisibilityRulesCheck(oObj, this, iNewPage, cPageKey);

    // check for each ribbonblock
    this.children('ribbonblock', function() {

      VisibilityRules._callVisibilityRulesCheckAllSubitems(oObj, this, iNewPage, cPageKey);

    });

  });
  // check for each ribbonblock
  oObj.children('ribbonblock', function() {
    VisibilityRules._callVisibilityRulesCheckAllSubitems(oObj, this, iNewPage, cPageKey);
  });


};
/**
 * Calls visibility rules check on itself and children ribbon items
 * @param  {object} oObj     The ribbon object
 * @param  {object} self     The parent object
 * @param  {integer} iNewPage The page index
 * @param  {string} cPageKey The page pagekey attribute
 * @memberOf VisibilityRules
 * @private
 * @return {void}
 */
VisibilityRules._callVisibilityRulesCheckAllSubitems = function(oObj, self, iNewPage, cPageKey) {

  VisibilityRules._callVisibilityRulesCheck(oObj, self, iNewPage, cPageKey);

  // check visibility rules for ribboncombo, ribbonbutton, ribboninput

  self.children('ribboncombo', function() {
    VisibilityRules._callVisibilityRulesCheck(oObj, this, iNewPage, cPageKey);
  });
  self.children('ribbonbutton', function() {
    VisibilityRules._callVisibilityRulesCheck(oObj, this, iNewPage, cPageKey);
  });
  self.children('ribboninput', function() {
    VisibilityRules._callVisibilityRulesCheck(oObj, this, iNewPage, cPageKey);
  });
};
/**
 * Check rule for each toolbarbutton, toolbarcombo, toolbarfillin
 * @param  {object} oObj The toolbar object
 * @memberOf VisibilityRules
 * @return {void}
 */
VisibilityRules.checkToolbarVisibilityRules = function(oObj) {
  oObj.children('toolbarbutton', function() {
    VisibilityRules._callVisibilityRulesCheck(oObj, this);
  });
  oObj.children('toolbarcombo', function() {
    VisibilityRules._callVisibilityRulesCheck(oObj, this);
  });
  oObj.children('toolbarfillin', function() {
    VisibilityRules._callVisibilityRulesCheck(oObj, this);
  });
};
/**
 * Check rule for each tab
 * @param  {object} oObj The tabbar object
 * @memberOf VisibilityRules
 * @return {void}
 */
VisibilityRules.checkTabbarVisibilityRules = function(oObj) {
  oObj.children('tab', function() {
    VisibilityRules._callVisibilityRulesCheck(oObj, this);
  });
};

/**
 * Check rule for each panelMenu button
 * @param  {object} oObj  The menuStructure object
 * @param  {object} oItem The menuFunction  object
 * @memberOf VisibilityRules
 * @return {void}
 */
VisibilityRules.checkPanelMenuVisibilityRules = function(oObj, oItem) {
  VisibilityRules._callVisibilityRulesCheck(oObj.parent, oItem);
};

/**
 * Check rule for form
 * @param  {object} oObj The form object
 * @memberOf VisibilityRules
 * @return {void}
 */
VisibilityRules.checkFormVisibilityRules = function(oObj) {
  let cVisibleRule = oObj.opt.SecurityRestrictions;
  if (cVisibleRule) {
    cVisibleRule = cVisibleRule.replace(/'/g, '"');
    const oRule = JSON.parse(cVisibleRule);
    VisibilityRules._runVisibleCheck(oObj, oObj, oRule);
  }
};

/**
 * Calls the visibility rules check or any type of object
 * @param  {object} oObj
 * @param  {object} item
 * @param  {integer} iNewPage The page index
 * @param  {string} cPageKey The page key
 * @memberOf VisibilityRules
 * @private
 * @return {void}
 */
VisibilityRules._callVisibilityRulesCheck = function(oObj, item, iNewPage, cPageKey) {
  const cVisibleRule = item.opt.SecurityToken || item.opt.visibilityRules;
  let oRule;
  if (cVisibleRule) {
    try {
      let cVisibleRuleCopy = cVisibleRule;
      cVisibleRuleCopy = cVisibleRuleCopy.replace(/'/g, '"');
      oRule = JSON.parse(cVisibleRuleCopy);
    } catch (e) {
      oRule = cVisibleRule;
    }

    VisibilityRules._runVisibleCheck(oObj, item, oRule, iNewPage, cPageKey);
  }
};
/**
 * Checks the visibility rules for a given object
 * @param  {object} oObj            The parent object
 * @param  {object} item            The item to check the visibilityRules for
 * @param  {visibilityRules} visibilityRules The visibility rules object settings
 * @param  {integer} iNewPage        The page index.
 * @param  {string} cPageKey        The page key.
 * @private
 * @memberOf VisibilityRules
 * @return {void|boolean}                Nothing or returns true if visibilityRules is an empty object
 */
VisibilityRules._runVisibleCheck = function(oObj, item, visibilityRules, iNewPage, cPageKey) {
  if ($.isEmptyObject(visibilityRules))
    return true;

  // Rules (functions) defined for checking
  const rulesToCheck = [
    VisibilityRules._checkNotRestricted,
    VisibilityRules._checkPrimaryDOAuthorization,
    VisibilityRules._checkCurrentPage,
    VisibilityRules._checkCurrentNode,
    VisibilityRules._checkCustomization
  ];

  // All options that *could* be used by a rule
  const ruleOptions = { visibilityRules, oObj, iNewPage, cPageKey };
  let visibilityRuleCheck = true;
  // Check all defined rules, if one of them fails, stop checking
  for (const rule of rulesToCheck) {
    if (!rule(ruleOptions)) {
      visibilityRuleCheck = false;
      break;
    }
  }

  // check for global container visibility status
  const oWin = oObj.dynObject.container.controller;

  if (oWin._visibilityRules == undefined)
    oWin._visibilityRules = [];


  // display block if all have passed the CANDO
  if (visibilityRuleCheck || (oWin._visibilityRules[item.opt.id] == true && VisibilityRules._checkNotRootNode({ oObj }))) {
    if (VisibilityRules.trace)
      akioma.log.info(`visibility rule for ${item.opt.id} passed`, oObj.dhx._items[item.opt.id], item);

    if (oObj.view == 'ribbon') {
      if (oObj.dhx._items[item.opt.id])
        oObj.dhx.show(item.opt.id);
    } else if (oObj.view == 'toolbar') {
      if (oObj.dhx.objPull[oObj.dhx.idPrefix + item.opt.name])
        oObj.dhx.showItem(item.opt.name);
    } else if (oObj.view == 'form')
      oObj.dhx.unlock();
    else if (oObj.view == 'tabbar')
      (oObj.opt.viewMode == 'sidebar') ? oObj.dhx.items(item.opt.id).show() : oObj.dhx.tabs(item.opt.id).show();
    else if (!isNull(oObj.opt.panelMenu)) {
      const panel = oObj.getAncestor('panel');
      panel.showPanelMenuButton(item.code);
    }

    oWin._visibilityRules[item.opt.id] = true;
  } else {
    if (VisibilityRules.trace)
      akioma.log.info(`visibility rule for ${item.opt.id} did not pass`, oObj.dhx._items[item.opt.id], item);

    if (oObj.view == 'ribbon') {
      if (oObj.dhx._items[item.opt.id])
        oObj.dhx.hide(item.opt.id);
    } else if (oObj.view == 'toolbar') {
      if (oObj.dhx.objPull[oObj.dhx.idPrefix + item.opt.name])
        oObj.dhx.hideItem(item.opt.name);
    } else if (oObj.view == 'form')
      oObj.dhx.lock();
    else if (oObj.view == 'tabbar')
      (oObj.opt.viewMode == 'sidebar') ? oObj.dhx.items(item.opt.id).hide() : oObj.dhx.tabs(item.opt.id).hide();
    else if (!isNull(oObj.opt.panelMenu)) {
      const panel = oObj.getAncestor('panel');
      panel.hidePanelMenuButton(item.code);
    }
  }
};

/**
 * Checks the NotRestricted visibilityRule
 * @param  {object} options Options for which to check
 * @param  {object} options.visibilityRules visibilityRules to check
 * @private
 * @memberOf VisibilityRules
 * @return {boolean} Returns boolean if check passed successfuly.
 */
VisibilityRules._checkNotRestricted = function(options) {
  const { visibilityRules } = options;

  if (!visibilityRules.NotRestricted) return true;

  return !securityIsRestricted(visibilityRules.NotRestricted);
};

/**
 * Checks the PrimaryDOAuthorization visibilityRule
 * @param  {object} options Options for which to check
 * @param  {object} options.visibilityRules visibilityRules to check
 * @param  {object} options.oObj Object to check
 * @private
 * @memberOf VisibilityRules
 * @return {boolean} Returns boolean if check passed successfuly.
 */
VisibilityRules._checkPrimaryDOAuthorization = function(options) {
  const { oObj, visibilityRules } = options;

  if (!visibilityRules.PrimaryDOAuthorization) return true;

  const primaryDOTableIO = oObj.dynObject.getLink('TABLEIO:TARGET') || oObj.dynObject;

  let primaryDO = primaryDOTableIO.getLink('DISPLAY:SRC');
  if (!primaryDO)
    primaryDO = primaryDOTableIO.getLink('DATA:SRC');

  if (!primaryDO) return true;

  const catalogSecurityRestrictions = primaryDO.controller.getSmartRestrictions();

  if (!catalogSecurityRestrictions) return true;

  const securityMappings = {
    CanCreate: 'ServiceInterface.Create',
    CanDelete: 'ServiceInterface.Delete',
    CanModify: 'ServiceInterface.Modify',
    CanFetch: 'ServiceInterface.FetchData',
    CanSaveChanges: 'ServiceInterface.SaveChanges'
  };

  const authorizationsList = [];
  for (const securityToken of Object.keys(securityMappings)) {
    const catalogToken = securityMappings[securityToken];

    if (!catalogSecurityRestrictions[catalogToken])
      authorizationsList.push(securityToken);
  }

  const authorizations = authorizationsList.join(',');

  return akioma.canDo(authorizations, visibilityRules.PrimaryDOAuthorization);
};

/**
 * Checks the currentPage visibilityRule
 * @param  {object} options Options for which to check
 * @param  {object} options.visibilityRules visibilityRules to check
 * @param  {object} options.oObj Object to check
 * @param  {string|null} options.cPageKey PageKey to check
 * @param  {integer|null} options.iNewPage New page number to check
 * @private
 * @memberOf VisibilityRules
 * @return {boolean} Returns boolean if check passed successfuly.
 */
VisibilityRules._checkCurrentPage = function(options) {
  const { visibilityRules, oObj, cPageKey, iNewPage } = options;

  if (!visibilityRules.currentPage) return true;

  let currentPageAlias = cPageKey;
  if (typeof (currentPageAlias) === 'undefined' || currentPageAlias === null)
    currentPageAlias = iNewPage;
  if (typeof (currentPageAlias) === 'undefined' || currentPageAlias === null) {
    const tabbar = oObj.dynObject.container.controller.getDescendant('tabbar');
    if (!isNull(tabbar))
      currentPageAlias = tabbar.currentPageNum();
  }

  return akioma.canDo(visibilityRules.currentPage, currentPageAlias);
};

/**
 * Checks the currentNode visibilityRule
 * @param  {object} options Options for which to check
 * @param  {object} options.visibilityRules visibilityRules to check
 * @param  {object} options.oObj Object to check
 * @private
 * @memberOf VisibilityRules
 * @return {boolean} Returns boolean if check passed successfuly.
 */
VisibilityRules._checkCurrentNode = function(options) {
  const { visibilityRules, oObj } = options;

  if (!visibilityRules.currentNodeType) return true;

  const treeGrid = oObj.dynObject.container.controller.getDescendant('treegrid');

  if (!treeGrid) return true;

  return akioma.canDo(visibilityRules.currentNodeType, treeGrid.typeKey);
};

/**
 * Checks the customization visibilityRule
 * @param  {object} options Options for which to check
 * @param  {object} options.visibilityRules visibilityRules to check
 * @private
 * @memberOf VisibilityRules
 * @return {boolean} Returns boolean if check passed successfuly.
 */
VisibilityRules._checkCustomization = function(options) {
  const { visibilityRules } = options;

  if (!visibilityRules.customization) return true;

  return akioma.canDo(visibilityRules.customization, akioma.getSessionProperty('customization'));
};

/**
 * Checks if the object contains a node and if so, if it's not a node type
 * @param  {object} options Options for which to check
 * @param  {object} options.oObj Object to check
 * @private
 * @memberOf VisibilityRules
 * @return {boolean} Returns boolean if check passed successfuly.
 */
VisibilityRules._checkNotRootNode = function(options) {
  const { oObj } = options;

  const treeGrid = oObj.dynObject.container.controller.getDescendant('treegrid');

  if (!treeGrid) return true;

  if (!treeGrid.typeKey) return true;

  return !treeGrid.typeKey.startsWith('pos.own.');
};
