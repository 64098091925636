
dhtmlXForm.prototype.items.dynlookup = {

  render: function(item, data) {

    item._type = 'combo';
    item._enabled = true;
    item._value = null;
    item._newValue = null;
    item.data = data;

    const skin = item.getForm().skin;
    if (typeof (data.inputWidth) != 'undefined' && skin == 'material' && String(data.inputWidth).match(/^\d*$/) != null)
      data.inputWidth = parseInt(data.inputWidth) + 2;


    this.doAddLabel(item, data);
    this.doAddInput(item, data, 'SELECT', null, true, true, 'dhxform_select');
    this.doAttachEvents(item);
    this.doLoadOpts(item, data);

    // allow selection to prevent broking combo logic
    item.onselectstart = function() {
      return true;
    };

    item.childNodes[item._ll ? 1 : 0].childNodes[0].setAttribute('mode', data.comboType || '');
    if (data.comboImagePath) item.childNodes[item._ll ? 1 : 0].childNodes[0].setAttribute('imagePath', data.comboImagePath);
    if (data.comboDefaultImage) item.childNodes[item._ll ? 1 : 0].childNodes[0].setAttribute('defaultImage', data.comboDefaultImage);
    if (data.comboDefaultImageDis) item.childNodes[item._ll ? 1 : 0].childNodes[0].setAttribute('defaultImageDis', data.comboDefaultImageDis);

    item._combo = new dhtmlXComboFromSelect(item.childNodes[item._ll ? 1 : 0].childNodes[0]);
    item._combo.setSkin(skin);
    item._combo._currentComboValue = item._combo.getSelectedValue();
    item._combo.getInput().id = data.uid;

    if (skin == 'material') item._combo.list.className += ` dhxform_obj_${skin}`;

    const k = this;
    item._combo.attachEvent('onChange', function() {
      k.doOnChange(this);
    });

    if (data.connector) this.doLoadOptsConnector(item, data.connector);

    if (data.filtering)
      item._combo.enableFilteringMode(true);
    else if (data.serverFiltering)
      item._combo.enableFilteringMode(true, data.serverFiltering, data.filterCache, data.filterSubLoad);


    if (data.readonly == true) this.setReadonly(item, true);
    if (data.hidden == true) this.hide(item);

    if (data.style) item._combo.DOMelem_input.style.cssText += data.style;

    // change dynLookup icon
    if (data.buttonIcon.indexOf('.') == -1) {
      $(item).find('div.dhxcombo_select_button').html(`<i class="${data.buttonIcon}" aria-hidden="true" style="font-size:16px;"></i>`);
      $(item).find('div.dhxcombo_select_button').css('margin-top', '3px');
    } else
      $(item).find('div.dhxcombo_select_button').html(`<img src="${data.buttonIcon}" width="18" height="18" style="margin-top:3px"/>`);

    item._combo.attachEvent('onFocus', function() {
      let item = this.cont.parentNode.parentNode;
      let f = item.getForm();
      if ((f.skin == 'dhx_terrace' || f.skin == 'material') && this.cont.className.search(/combo_in_focus/) < 0) this.cont.className += ' combo_in_focus';
      f.callEvent('onFocus', [item._idd]);
      f = item = null;
    });

    item._combo.attachEvent('onBlur', function() {
      let item = this.cont.parentNode.parentNode;
      let f = item.getForm();
      if ((f.skin == 'dhx_terrace' || f.skin == 'material') && this.cont.className.search(/combo_in_focus/) >= 0) this.cont.className = this.cont.className.replace(/\s{0,}combo_in_focus/gi, '');
      f.callEvent('onBlur', [item._idd]);
      f = item = null;
    });


    return this;
  },

  destruct: function(item) {

    // unload combo
    item.childNodes[item._ll ? 1 : 0].childNodes[0].onchange = null;

    item._combo._currentComboValue = null;
    item._combo.unload();
    item._combo = null;

    // unload item
    item._apiChange = null;
    this.d2(item);
    item = null;

  },

  doAttachEvents: function(item) {

    const that = this;

    item.childNodes[item._ll ? 1 : 0].childNodes[0].onchange = function() {
      that.doOnChange(this);
      that.doValidate(this.DOMParent.parentNode.parentNode);
    };
  },

  doValidate: function(item) {
    if (item.getForm().hot_validate) this._validate(item);
  },

  doOnChange: function(combo) {
    const item = combo.base.parentNode.parentNode.parentNode;
    if (item._apiChange) return;
    combo._newComboValue = combo.getSelectedValue();
    if (combo._newComboValue != combo._currentComboValue) {
      if (item.checkEvent('onBeforeChange')) {
        if (item.callEvent('onBeforeChange', [ item._idd, combo._currentComboValue, combo._newComboValue ]) !== true) {
          // restore last value
          // not the best solution, should be improved
          window.setTimeout(() => {
            combo.setComboValue(combo._currentComboValue);
          }, 1);
          return false;
        }
      }
      combo._currentComboValue = combo._newComboValue;
      item.callEvent('onChange', [ item._idd, combo._currentComboValue ]);
    }
    item._autoCheck();
  },

  doLoadOptsConnector: function(item, url) {
    let that = this;
    let i = item;
    item._connector_working = true;
    item._apiChange = true;
    item._combo.load(url, () => {
      // try to set value if it was called while options loading was in progress
      i.callEvent('onOptionsLoaded', [i._idd]);
      i._connector_working = false;
      if (i._connector_value != null) {
        that.setValue(i, i._connector_value);
        i._connector_value = null;
      }
      i._apiChange = false;
      that = i = null;
    });
  },

  enable: function(item) {
    if (String(item.className).search('disabled') >= 0) item.className = String(item.className).replace(/disabled/gi, '');
    item._enabled = true;
    item._combo.enable();
  },

  disable: function(item) {
    if (String(item.className).search('disabled') < 0) item.className += ' disabled';
    item._enabled = false;
    item._combo.disable();
  },

  getCombo: function(item) {
    return item._combo;
  },

  setValue: function(item, val) {
    if (item._connector_working) { // attemp to set value while optins not yet loaded (connector used)
      item._connector_value = val;
      return;
    }
    item._apiChange = true;
    const oSelf = $.getObjectByName({ id: item.data.userdata.id });
    // if(oSelf.opt.showKey == '_self')
    //  val = oSelf.parent.dataSource.dynObject.getValue(oSelf.opt.showDesc);
    if (oSelf.lastValText != undefined) {
      item._combo.setComboValue(oSelf.lastValText, true);
      delete oSelf.lastValText;
    } else
      item._combo.setComboValue(val);
      // oSelf.lastValText = val;

    item._combo._currentComboValue = item._combo.getActualValue();
    item._apiChange = false;

    const $itemcont = $(item._combo.DOMParent).parent().parent();
    const cComboVal = item._combo.DOMelem_input.value;
    if (cComboVal != null) {
      if (cComboVal.length > 0)
        $itemcont.addClass('active');
      else
        $itemcont.removeClass('active');
    }

  },

  getValue: function(item) {

    const oSelf = $.getObjectByName({ id: item.data.userdata.id });

    if (oSelf.opt.valueField == 'key') {
      if (oSelf.opt.showDesc == '_self') {
        oSelf.val.valueDesc = akioma.getForm(oSelf).dataSource.dynObject.getValue(oSelf.opt.name);
        return oSelf.val.valueDesc;
      } else if (oSelf.opt.showKey == '_self') {
        oSelf.val.valueKey = akioma.getForm(oSelf).dataSource.dynObject.getValue(oSelf.opt.name);
        return oSelf.val.valueKey;
      }
    } else {
      if (oSelf.opt.showKey == '_self') {
        // oSelf.val.valueKey = oSelf.parent.dataSource.dynObject.getValue(oSelf.opt.name);
        // return oSelf.val.valueKey;
        oSelf.lastValText = akioma.getForm(oSelf).dataSource.dynObject.getValue(oSelf.opt.name);
        return oSelf.val.valueKey;
      }
      if (oSelf.val.value == '' && oSelf.opt.showDesc != '_self')
        oSelf.val.value = akioma.getForm(oSelf).dataSource.dynObject.getValue(oSelf.opt.showDesc);

      return oSelf.val.value;
    }
    return item._combo.getActualValue();
  },

  setWidth: function(item, width) {
    item.childNodes[item._ll ? 1 : 0].childNodes[0].style.width = `${width}px`;
  },

  setReadonly: function(item, state) {
    if (!item._combo) return;
    item._combo_ro = state;
    item._combo.readonly(item._combo_ro);
  },

  isReadonly: function(item) {
    return item._combo_ro || false;
  },

  setFocus: function(item) {
    if (item._enabled) item._combo.setFocus();
  },

  _setCss: function(item, skin, fontSize) {
    // update font-size for input and list-options div
    item._combo.setFontSize(fontSize, fontSize);
  }

};

(function() {
  for (const a in { doAddLabel: 1, doAddInput: 1, doLoadOpts: 1, doUnloadNestedLists: 1, setText: 1, getText: 1, isEnabled: 1, _checkNoteWidth: 1 })
    dhtmlXForm.prototype.items.dynlookup[a] = dhtmlXForm.prototype.items.select[a];
})();

dhtmlXForm.prototype.items.dynlookup.d2 = dhtmlXForm.prototype.items.select.destruct;

dhtmlXForm.prototype.getDynLookup = function(name) {
  return this.doWithItem(name, 'getCombo');
};
dhtmlXForm.prototype.getFormData_dynlookup = function(name) {
  return this.doWithItem(name, 'getValue');
};
dhtmlXForm.prototype.setFormData_dynlookup = function(name, value) {
  return this.doWithItem(name, 'setValue', value);
};

akioma.onBeforeDynLookup = function(self) {
  // accesss lookup business entity
  const oLookupBE = self.controller.businessEntity;
  const oCustomerLookup = self.parent.childs.customerhdl;

  if (oCustomerLookup) {
    const cSelfHdl = oCustomerLookup.getValue();
    oLookupBE.query.clearAll();
    if (cSelfHdl)
      oLookupBE.query.addCondition('busrelhdl', 'eq', cSelfHdl);

  }
};

(function($) {

  // ********************* lookup no. 2 combo autocomplete ********************
  $.extend({
    ak_dynlookup: function(options) {
      const oSelf = this,
        defaults = {
          disabled: false,
          valueField: 'hdl'
        };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;
      this.view = options.view;

      this.registerDynObject = true;

      if (this.opt.mappedFields)
        this.opt.valueKey = this.opt.mappedFields;

      if (!this.opt.customStyle)
        this.opt.customStyle = this.view;

      // get parent
      const oParent = this.parent;
      if (oParent) {

        this.opt.label = akioma.tran(`${akioma.getForm(this).opt.name}.${this.opt.name}`, { defaultValue: this.opt.label });

        oParent.prop.fields.push({
          type: 'dynlookup',
          inputTop: parseInt(this.opt.top),
          inputLeft: parseInt(this.opt.left),
          inputWidth: parseInt(this.opt.width),
          label: this.opt.label,
          labelTop: parseInt(this.opt.top),
          labelLeft: oParent.labelLeft(this),
          required: (this.opt.required) ? this.opt.required : false,
          name: this.opt.name,
          disabled: this.opt.disabled,
          readonly: this.opt.readonly,
          className: (this.opt.autoComplete) ? '' : 'w4-formField w4-inputField',
          // info:            true,
          position: 'label-top',
          buttonIcon: (this.opt.buttonIcon) ? this.opt.buttonIcon : 'fa fa-search',
          userdata: { id: this.opt.id }
        });


        // append to elements in form
        if (this.parent.view == 'form')
          this.parent.elements.push(this);
        else if (this.parent.view == 'fieldset')
          this.parent.parent.elements.push(this);

        this.serverProp = { srvrProp: [] };


        // set BE
        if (this.opt.resourceName) {


          const oBEoptions =
                    {
                      'att': {
                        cacheLimit: 50,
                        catalogURI: '',
                        dataSource: '',
                        entityName: oSelf.opt.entityName,
                        id: 'dynlookup_businessEntity',
                        identifier: 'selfhdl',
                        name: 'businessEntity',
                        rowsToBatch: 100,
                        resourceName: oSelf.opt.resourceName,
                        serviceURI: ''
                      }
                    };

          oSelf.businessEntity = new $['ak_businessEntity'](oBEoptions);
          oSelf.businessEntity.finishConstruct();
          oSelf.businessEntity.endConstruct();
        } else
          akioma.notification({ type: 'error', text: `Please provide business entity for dynlookup ${this.opt.name}` });

        $.extend(this, {
          val: {
            value: '',
            valueKey: '',
            valueDesc: ''
          }
        });


      }
    }
  });

  // methods for form
  $.ak_dynlookup.prototype = {

    // finish construct
    finishConstruct: function() {
      // get field
      const parentForm = akioma.getForm(this);
      const formAkId = parentForm.akId;
      this.form = parentForm.dhx;
      this.dhx = this.form.getDynLookup(this.opt.name);

      const oSelf = this;
      const oCombo = this.dhx;

      // set akId
      const formControl = $($(oCombo.base).parent()).parent();
      $(formControl[0]).attr('akId', `${formAkId}-${this.opt.name}`);

      // set akStyle in dynLookup
      $(formControl[0]).attr('akStyle', oSelf.opt.customStyle);

      oCombo.attachEvent('onBlur', () => {
        oSelf._checkForActiveClass();
      });

      $(oCombo.DOMelem_input).on('keyup', e => {
        // if the key pressed is a character and not backspace or delete
        // then update value of dynlookup
        if (e.key.length > 1 && e.keyCode != 8 && e.keyCode != 46)
          return false;
        try {
          const cActualComboTextValue = $(oCombo.DOMelem_input).val();
          if (oSelf.opt.showKey == '_self') {
            oSelf.val.valueKey = cActualComboTextValue;
            oSelf.dhx.setComboText(oSelf.val.valueKey);
            oSelf.lastValText = oSelf.val.valueKey;
          } else if (oSelf.opt.showDesc == '_self') {
            // combo.akElm.
            oSelf.val.valueDesc = cActualComboTextValue;
            oSelf.dhx.setComboText(oSelf.val.valueDesc);
            oSelf.lastValText = oSelf.val.valueDesc;
          }
          if (oSelf.opt.valueField == 'hdl')
            oSelf.setValue(cActualComboTextValue);
          else
            oSelf.setValueKey(cActualComboTextValue);

          if (cActualComboTextValue.length == 0)
            oSelf.val.valueDesc = oSelf.val.value = oSelf.val.valueKey = '';

        } catch (e) {
          akioma.log.error(e);
        }
      });

      if (this.opt.serverProp) {
        const aProp = this.opt.serverProp.split('|');
        for (const i in aProp) {
          const aVal = aProp[i].split('#');
          this.setSrvProp(aVal[0], aVal[1]);
        }
      }

      const oForm = akioma.getForm(this);
      // lookup 2 update lookup val attribute to match mappings in showKey,showDesc attributes
      try {
        if (oForm && oForm.dataSource) {
          oForm.dataSource.dynObject.controller.dhx.attachEvent('onAfterCursorChange', () => {
            const oItem = oForm.dataSource.dynObject.controller.dhx.item(oForm.dataSource.dynObject.controller.dhx.getCursor());
            try {
              if (oSelf.opt.showKey == '_self')
                oSelf.val.valueKey = oItem[oSelf.opt.name];
              else
                oSelf.val.valueKey = oItem[oSelf.opt.showKey];
              if (oSelf.opt.showDesc == '_self')
                oSelf.val.valueDesc = oItem[oSelf.opt.name];
              else
                oSelf.val.valueDesc = oItem[oSelf.opt.showDesc];
              if (oSelf.opt.showKey != '_self' && oSelf.opt.showKey != '_self')
                oSelf.val.value = oItem[oSelf.opt.name];

              delete oSelf.lastValText;
            } catch (e) {
              console.warn('Error in dynlookup binding:', oItem, e);
            }

          });

          oForm.dataSource.dynObject.controller.dhx.attachEvent('onBeforeCursorChange', () => {
            delete oSelf.lastValText;
          });
        }
      } catch (e) {
        akioma.log.error('Could not update attributes in dynlookup ', e);
      }

      $(oCombo.DOMelem).addClass('lookup2');
      $(oCombo.DOMelem_button).on('click', () => {
        if (!oSelf.opt.disabled)
          oSelf.popupDialog();
        return false;
      });

      const oInput = $(oCombo.DOMelem_input);

      const myPop = new dhtmlXPopup({ form: oForm.dhx, id: this.opt.name });
      myPop.attachEvent('onBeforeHide', (type, e) => {
        // stop if from input to prevent hide bug
        if (e.target == oInput)
          return false;
        return true;
      });

      const myLookup = null;

      const iMinStartChars = oSelf.opt.autocompleteMinChars || 0;

      $(oInput).on('paste keyup', e => {

        if (e.keyCode == 40 || e.keyCode == 38 || e.keyCode == 13)
          return false;

        if (myLookup == null)
          if (!myPop.isVisible()) myPop.show(oSelf.opt.name);
          // return;

        // myGrid.filterBy(1,"");
        if (!myPop.isVisible()) myPop.show();
        const text = $(oInput).val();

        if (text.length > iMinStartChars)
          autoCompleteSearch(text);
        else {
          akioma.getForm(oSelf).dataSource.dynObject.setValue(oSelf.opt.name, '');
          oSelf.val.value = '';
          oSelf.val.valueKey = '';
          oSelf.val.valueDesc = '';
        }
      });


      // initial fetch
      let bInitiallyFetched = false;

      $(oInput).on('focusin', () => {
        myPop.show();
        let text = $(oInput).val();

        // if initial fetch change key to initial fetch val
        if (oSelf.opt.initialFetch && !bInitiallyFetched)
          text = oSelf.opt.initialFetch;


        if (text.length > iMinStartChars && oSelf.opt.initialFetch != '') {
          myPop.show(oSelf.opt.name);
          autoCompleteSearch(text);
        }
      });

      $(oInput).on('keydown', e => {

        bInitiallyFetched = true;
        const $this = $(myPop.p).find('.selected.searchItem');

        if (e.keyCode == 40) {
          let $next = $this.next();
          if ($next.length < 1)
            $next = $(myPop.p).find('.searchItem:first-child');
          $this.removeClass('selected');
          $next.addClass('selected');
          $next.closest($(myPop.p).find('.results-container')).scrollTop($next.index() * $next.outerHeight());
          return false;
        } else if (e.keyCode == 38) {
          let $prev = $this.prev();
          if ($prev.length < 1)
            $prev = $(myPop.p).find('.searchItem:last-child');
          $this.removeClass('selected');
          $prev.addClass('selected');
          $prev.closest($(myPop.p).find('.results-container')).scrollTop($prev.index() * $prev.outerHeight());
          return false;
        } else if (e.keyCode == 13) {

          $this.click();
          return false;
        }
      });

      $(myPop.p).on('click', '.searchItem', function() {
        const val = $(this).attr('dynrowid');
        oSelf.selectionChanged(val);
        myPop.hide();
        return true;
      });


      let $current;

      $(myPop.p).on('mouseover', '.searchItem', function() {
        $(this).parent().find('.selected').removeClass('selected');
        $current = $(this);
        $current.addClass('selected');
      });


      function autoCompleteSearch(cInput) {


        // call on before event code
        if (oSelf.opt.onBeforeFetch)
          applyAkiomaCode(oSelf, oSelf.opt.onBeforeFetch);


        oSelf.businessEntity.setNamedQueryParam('AutoCompleteSearch', 'SearchKey', cInput, 'character');
        if (oSelf.businessEntity.jsdo.fillxhr)
          oSelf.businessEntity.jsdo.fillxhr.abort();

        // clear existing results while pending request
        if (myPop) {
          const $results = $(myPop.p).find('.results-container');
          if ($results.length > 0)
            $results.empty();
        }

        oSelf.businessEntity.openQuery({ applyFilters: true });
        oSelf.businessEntity.aCallback['afterFill'] = function(rows) {
          const oData = { results: rows };
          const aKeys = oSelf.opt.template.split('|');

          // check if multi fields
          const aFieldsShowImg = aKeys[2]; // img
          const aFieldsShowKey = aKeys[3].split(','); // key
          const aFieldsShowDesc = aKeys[4].split(','); // desc

          const genericTemplateName = aKeys[0];
          const aGenericKeys = {
            id: '_id',
            img: aFieldsShowImg,
            key: aFieldsShowKey,
            desc: aFieldsShowDesc
          };
          const templateF = Handlebars.compile(akioma.handlebarsTemplates[genericTemplateName](aGenericKeys));
          const result = templateF(oData);
          myPop.attachHTML(result);

        };
      }


      $(oCombo.DOMelem_input).focusout(() => {
        setTimeout(() => {

          myPop.hide(oSelf.opt.name);
          akioma.triggerTreeEnterKey = true;
        }, 500);
        oSelf.eventLeave();
      });

      $(oCombo.DOMelem).addClass('lookup2');


      oCombo.akElm = this;
    },
    endConstruct: function() {
      this._checkForActiveClass();
    },
    // en- or disable field ********************
    enabled: function() {

      if (typeof this.opt.disabled != 'boolean')
        return;


    },
    _calculateServerProp: function() {
      let cProps = '';
      let iterator = 0;

      for (const i in this.serverProp) {
        if (i != 'srvrProp') {
          let cExtra = '';
          if (iterator > 0)
            cExtra = '&';
          const cProp = `${cExtra + i}=${this.serverProp[i]}`;

          cProps += cProp;
          iterator++;
        }

      }

      cProps = `&${cProps}`;

      return cProps;
    },
    getSrvProp: function(cName) {
      if (this.serverProp[cName])
        return this.serverProp[cName];
      else
        return '';
    },

    setSrvProp: function(cName, cValue) {
      if ($.inArray(cName, this.serverProp.srvrProp) == -1)
        this.serverProp.srvrProp.push(cName);

      if (cValue.substr(0, 1) == '$') {
        let oSelf, self, cCode;
        try {
          // get variable for dynObject
          oSelf = this;
          // required in eval context
          // eslint-disable-next-line no-unused-vars
          self = oSelf.dynObject;
          cCode = cValue.substr(1);
          this.serverProp[cName] = eval(cCode);
        } catch (e) {
          !_isIE && console.error([ 'Error executing akioma code', oSelf, cCode, e ]);
          akioma.message({ type: 'alert-error', title: 'Error executing akioma code', text: `${cCode}<br />${e.message}` });
        }
      } else
        this.serverProp[cName] = cValue;
    },

    delSrvProp: function(cName) {
      if (this.serverProp[cName]) {
        delete this.serverProp[cName];
        $.removeEntry(this.serverProp.srvrProp, cName);
      }
    },

    // get value *****************
    getValue: function() {
      if (this.opt.valueField == 'key')
        return this.val.valueKey;
      else
        return this.val.value;
    },

    // set value ********************
    setValue: function(cValue) {
      this.val.value = cValue;
      this.lastValText = cValue;
    },
    setVisibleValue: function(cVal) {
      if (this.opt.valueField == 'hdl') {
        this.val.value = cVal;
        this.dhx.setComboText(cVal);
      } else if (this.opt.valueKey == 'key') {
        this.val.valueKey = cVal;
        this.dhx.setComboText(cVal);
      }
    },

    // get value key *****************
    getValueKey: function() {

      return this.val.valueKey;
    },
    setValueExt: function(cHdl, cKey, cDesc) {
      // set value
      this.setValue(cHdl);
      this.setValueKey(cKey);
      this.setValueDesc(cDesc);

    },
    _checkForActiveClass: function() {
      const val = this.dhx.getComboText();
      const $itemcont = $(this.dhx.DOMParent).parent().parent();

      if (val.length > 0)
        $itemcont.addClass('active');
      else
        $itemcont.removeClass('active');
    },

    // set value key ********************
    setValueKey: function(cValue) {
      this.val.valueKey = cValue;

      if (this.opt.showKey == '_self') {

        this.dhx.setComboText(this.val.valueKey);
        this.lastValText = this.val.valueKey;

        // if(this.opt.valueField == 'key')
        //  this.dhx.setComboValue(this.val.valueKey, true);
        // else
        //  this.dhx.setComboValue(this.val.value, true);
      } else {
        try {
          const oLookupShowKey = this.form.getDynLookup(this.opt.showKey);
          oLookupShowKey.setComboText(this.val.valueKey);
          oLookupShowKey.akElm._checkForActiveClass();

          if (oLookupShowKey.akElm.opt.valueField == 'key')
            oLookupShowKey.setComboValue(this.val.valueKey, true);
          else
            oLookupShowKey.setComboValue(this.val.value, true);
          oLookupShowKey.akElm.val.value = this.val.value;
          oLookupShowKey.akElm.val.valueKey = this.val.valueKey;
          oLookupShowKey.akElm.val.valueDesc = this.val.valueDesc;
          // update datasource fields
          if (this.opt.showKey != '_self') {
            akioma.getForm(this).dataSource.setFieldValue({
              name: this.opt.showKey,
              value: this.val.valueKey
            });
          }
          if (this.opt.showDesc != '_self') {
            akioma.getForm(this).dataSource.setFieldValue({
              name: this.opt.showDesc,
              value: this.val.valueDesc
            });
          }

          // last text value
          oLookupShowKey.akElm.lastValText = oLookupShowKey.akElm.val.valueKey;
        } catch (e) {
          akioma.log.error(`Could not bind to ${this.opt.showKey} dynlookup`, e);
        }
      }

      this._checkForActiveClass();
      // this.dhx.setComboValue(this.val.value);

    },

    // get value desc ********************
    getValueDesc: function() {


      return this.val.valueDesc;
    },


    // set value desc ********************
    setValueDesc: function(cValue) {
      this.val.valueDesc = cValue;


      if (this.opt.showDesc == '_self') {
        this.dhx.setComboText(cValue);
        this.lastValText = cValue;
        // if(this.opt.valueField == 'key')
        //  this.dhx.setComboValue(cValue, true);
        // else
        //  this.dhx.setComboValue(this.val.value, true);
      } else {
        try {
          const oLookupShowDesc = this.form.getDynLookup(this.opt.showDesc);
          if (oLookupShowDesc != null) {
            oLookupShowDesc.setComboText(this.val.valueDesc);
            oLookupShowDesc.akElm._checkForActiveClass();
          } else if (this.opt.showDesc != '_self' && this.opt.showKey != '_self') {
            this.form.getDynLookup(this.opt.name).setComboText(this.val.valueDesc);
            this.form.getDynLookup(this.opt.name).akElm._checkForActiveClass();
          }


          if (oLookupShowDesc.akElm.opt.valueField == 'key')
            oLookupShowDesc.setComboValue(this.val.valueKey, true);
          else
            oLookupShowDesc.setComboValue(this.val.value, true);

          oLookupShowDesc.akElm.val.valueDesc = (this.val.valueDesc);
          oLookupShowDesc.akElm.val.valueKey = (this.val.valueKey);
          oLookupShowDesc.akElm.val.value = (this.val.value);

          // update datasource valuedesc
          const cField = oLookupShowDesc.akElm.opt.showDesc;
          akioma.getForm(this).dataSource.setFieldValue({
            name: cField,
            value: this.val.valueDesc
          });

          oLookupShowDesc.akElm.lastValText = oLookupShowDesc.akElm.val.valueDesc;
        } catch (e) {
          akioma.log.error(`Could not bind to ${this.opt.showDesc} dynlookup`, e);
        }

      }
      this._checkForActiveClass();
      // this.dhx.setComboValue(this.val.value);

    },

    // get value from external ********
    getExtValue: function(oSource) {

      // get value
      const cValueKey = oSource.getFieldValue(this.opt.extKey);
      const cValueHdl = oSource.getFieldValue(this.opt.extHdl);
      const cValueDesc = oSource.getFieldValue(this.opt.extDesc);

      this.val.value = cValueHdl;
      this.val.valueKey = cValueKey;
      this.val.valueDesc = cValueDesc;


      // set value
      this.setValue(cValueHdl);
      this.setValueKey(cValueKey);
      this.setValueDesc(cValueDesc);

      // check if we have to call the leave event
      if (this.opt.leaveEvent)
        app.controller.callAkiomaCode(this, this.opt.leaveEvent);

      // set focus to lookup field
      this.form.setItemFocus(this.opt.name);
    },

    // popup dialog **********************
    popupDialog: function() {
      const oForm = this.getAncestor('form');

      if (this.security.readOnly)
        return;

      // check for disabled
      if (!this.opt.disabled && this.opt.lookupDialog) {

        // call dialog
        app.controller.launchContainer({
          proc: 'popup.r',
          para: `RunFile=${this.opt.lookupDialog}&FieldName=${this.opt.name}&TargetId=${oForm.opt.id}`,
          self: this,
          data: true
        });
      }
    },

    _getSelectedValueKey: function(oSelected, cFields) {
      const aFields = cFields.split(',');

      for (let i = 0; i < aFields.length; i++) {
        const cField = aFields[i];
        if (cField.indexOf('\'') == -1)
          return oSelected[cField];

      }
    },

    _getSelectedValueDesc: function(oSelected, cFields) {
      const aFields = cFields.split(',');
      let cRes = '';
      for (let i = 0; i < aFields.length; i++) {
        const cField = aFields[i];
        if (cField.indexOf('\'') == -1)
          cRes += oSelected[cField];
        else
          cRes += cField.replace(/["']/g, '');

      }

      return cRes;
    },

    selectionChanged: function(cDataSourceID) {
      // var oSelected = this.dhx.getSelectedText();
      const oSelf = this;
      const oSelected = oSelf.businessEntity.dhx.item(cDataSourceID);
      oSelf.businessEntity.dhx.setCursor(cDataSourceID);

      const aKeys = oSelf.opt.template.split('|');
      // var aGenericKeys = ['_id', aKeys[1], aKeys[2],aKeys[3]];
      if (oSelected != '') {

        this.lastSelected = oSelected;

        this.val.value = oSelected[aKeys[1]]; // hdl

        const cValueKey = oSelf._getSelectedValueKey(oSelected, aKeys[3]);
        this.val.valueKey = cValueKey; // key

        const cValueDesc = oSelf._getSelectedValueDesc(oSelected, aKeys[4]);
        this.val.valueDesc = cValueDesc; // desc
        this.setValue(oSelected[aKeys[1]]);
        this.setValueKey(cValueKey);
        this.setValueDesc(cValueDesc);

        // tell treegrid that it can now execute enter key event after 500ms
        const oFormDataSource = akioma.getForm(this).dataSource;
        if (oFormDataSource) {
          const oTreeGrid = oFormDataSource.dataSource;
          if (oTreeGrid) {
            oTreeGrid.triggerTreeEnterKey = false;
            if (this.timeout != undefined)
              clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              oTreeGrid.triggerTreeEnterKey = true;
            }, 500);
          }

        }

        // call selection event
        if (this.opt.validateEvent)
          app.controller.callAkiomaCode(this, this.opt.validateEvent);


        // check if we have to call the leave event
        if (this.opt.leaveEvent)
          app.controller.callAkiomaCode(this, this.opt.leaveEvent);
      }


    },
    changed: function() {
      this.val.value = this.dhx.getComboText();
      this.getValueFromServer(this.dhx.getComboText());
    },

    // get value from server *************
    getValueFromServer: function(value) {

      if (!value)
        value = this.getValueKey();

      // check first if values has been changed
      if (this.oldValue == value)
        return;

      const oData = getLookupValue({
        value: value,
        tableName: this.opt.tableName,
        extHdl: this.opt.extHdl,
        extKey: this.opt.extKey
      });

      if (oData) {
        if (akioma.getForm(this).dataSource)
          this.setValueKey(this.getValueKey());
        this.setValue(oData.value);
        this.setValueDesc(oData.valueDesc);

        if (akioma.getForm(this).dataSource)
          akioma.getForm(this).dataSource.setChanged(true);

        // check if we have to call the leave event
        if (this.opt.leaveEvent)
          app.controller.callAkiomaCode(this, this.opt.leaveEvent);
      }
    },

    // get value *****************
    onFocus: function(value) {
      // save old value for compare
      this.oldValue = value;
      akioma.lastFocus.field = this.opt.name;
      akioma.lastFocus.form = this.parent.opt.name;
    },

    cursorChange: function() {
      delete this.lastValText;
      const oCombo = this.dhx;
      const oComboInp = oCombo.getInput();
      const $comboHead = $(oCombo.hdr);
      const $comboList = $(oCombo.list);
      $comboList.addClass('hidelist');
      $comboHead.addClass('hidelist');


      $(oComboInp).one('keypress', () => {
        $comboList.removeClass('hidelist');
        $comboHead.removeClass('hidelist');
      });
    },
    dataAvailable: function() {
      delete this.lastValText;
    },
    eventLeave: function() {
      const oCombo = this.dhx,
        $itemcont = $(oCombo.DOMParent).parent().parent();
      $itemcont.removeClass('focusedinp');
    },
    eventFocus: function() {
      const oCombo = this.form.getCombo(this.opt.name);
      const $itemcont = $(oCombo.DOMParent).parent().parent();
      $itemcont.addClass('active').addClass('focusedinp');
    },

    // destroy ***************
    destroy: function() {}
  };

})(jQuery, jQuery);
