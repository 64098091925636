/**
 * DataFilter Class is used for generating the akQuery filter
 * @param {Object} opt Settings for DataFilter Class
 *
 * @class DataFilter
 */
const DataFilter = function(opt) {
  this.filter = [];

  this.mainOperator = opt.mainOperator;
  this.businessEntity = opt.businessEntity;
  this.prevFieldNameAdded = '';
  this.aSubOperators = [];
  this.aFilters = [];
  this.fieldlist = null;
};

/**
 * Method used for adding a new filter condition
 * @param {String} name        The name of the field for the condition.
 * @param {String} operator    The operator.
 * @param {String} value       The value of the condition.
 * @param {boolean} bSameasprev Boolean used for multiple same field name conditions.
 * @instance
 * @memberOf DataFilter
 */
DataFilter.prototype.addCondition = function(name, operator, value, bSameasprev) {

  let bLoadGroup = false;
  if (bSameasprev != undefined && this.prevFieldNameAdded != '')
    bLoadGroup = true;

  if (!bLoadGroup && this.aFilters[name] == undefined)
    this.aFilters[name] = [];

  if (!bLoadGroup)
    this.aFilters[name].push({ field: name, operator: operator, value: value });
  else
    this.aFilters[this.prevFieldNameAdded].push({ field: name, operator: operator, value: value });

  this.buildQuery();

  if (!bLoadGroup)
    this.prevFieldNameAdded = name;
};
/**
 * Method used for adding a new unique condition, will overwrite any conditions with the same field name
 * @param {String} name     The name of the field for the condition.
 * @param {String} operator The operator.
 * @param {String} value    The value of the condition.
 * @instance
 * @memberOf DataFilter
 */
DataFilter.prototype.addUniqueCondition = function(name, operator, value) {
  this.aFilters[name] = [];
  this.aFilters[name].push({ field: name, operator: operator, value: value });
  this.buildQuery();
};

/**
 * Method used to set the fieldlist operator
 * @param  {String} value The list of field names
 * @instance
 * @memberOf DataFilter
 */
DataFilter.prototype.setFieldList = function(value) {
  this.fieldlist = value;
};


/**
 * Method used for removing an existing filter condition
 * @param {String} name     The name of the field for the condition.
 * @param {String} operator The operator.
 * @param {String} value    The value of the condition.
 * @instance
 * @memberOf DataFilter
 */
DataFilter.prototype.removeCondition = function(name, operator, value) {
  if (this.aFilters[name]) {
    for (let i = 0; i < this.aFilters[name].length; i++) {
      const oItem = this.aFilters[name][i];
      if (oItem.field == name && oItem.operator == operator && oItem.value == value)
        this.aFilters[name].splice(i, 1);
    }
    this.buildQuery();
  }
};

/**
 * Method used for checking if a field has filters
 * @param  {String}  name The name of the field
 * @return {Boolean}      True, if the filter exists, false otherwise
 * @instance
 * @memberOf DataFilter
 */
DataFilter.prototype.hasConditions = function(name) {
  return (this.aFilters[name] && this.aFilters[name].length > 0) ? true : false;
};

/**
 * Method used for checking if a field has a specific filter
 * @param  {String}   name     The name of the field
 * @param  {String}   operator The operator
 * @param  {String}   value    The value of the filter
 * @return {Boolean}           True, if the filter exists, false otherwise
 * @instance
 * @memberOf DataFilter
 */
DataFilter.prototype.hasCondition = function(name, operator, value) {
  if (this.aFilters[name]) {
    for (let i = 0; i < this.aFilters[name].length; i++) {
      const oItem = this.aFilters[name][i];
      if (oItem.field == name && oItem.operator == operator && oItem.value == value)
        return true;
    }
  }
  return false;
};


/**
 * This method is used to clear all filter conditions.
 * @instance
 * @memberOf DataFilter
 */
DataFilter.prototype.clearAll = function() {
  this.aFilters = [];
  this.filter = [];
  this.buildQuery();
};

/**
 * Method used to return the filter conditions from a given field name.
 * @param  {String}   cField The field name to look for.
 * @return {Object[]|null}   Array of conditions
 * @instance
 * @memberOf DataFilter
 */
DataFilter.prototype.getFilterByName = function(cField) {
  return (this.aFilters[cField]) ? this.aFilters[cField] : null;
};


/**
 * Method used to return value of a filter from a given field name.
 * @param  {String} cField The field name to look for.
 * @return {String}        The value of the condition found.
 * @instance
 * @memberOf DataFilter
 */
DataFilter.prototype.getFilterValue = function(cField) {
  for (const i in this.aFilters) {
    if (i == cField) {
      if (this.aFilters[i] && this.aFilters[i][0])
        return this.aFilters[i][0].value;
      else
        return null;
    }
  }
  return null;
};
/**
 * Method used to return operator of a filter from a given field name.
 * @param  {String} cField The field name to look for.
 * @return {String}        The value of the condition found.
 * @instance
 * @memberOf DataFilter
 */
DataFilter.prototype.getFilterOperator = function(cField) {
  for (const i in this.aFilters) {
    if (i == cField) {
      if (this.aFilters[i] && this.aFilters[i][0])
        return this.aFilters[i][0].operator;
      else
        return null;
    }
  }
  return null;
};
/**
 * Method used for setting a suboperator.
 * @param {String} cFieldName Name of field to set the suboperator for.
 * @param {String} operator   The operator to be set as suboperator.
 * @instance
 * @memberOf DataFilter
 */
DataFilter.prototype.setSubOperator = function(cFieldName, operator) {
  this.aSubOperators[cFieldName] = operator;
};
/**
 * Method used to return the json structure of the filter.
 * @return {Object} The filter result.
 * @instance
 * @memberOf DataFilter
 */
DataFilter.prototype.getFilter = function() {
  this.filter = [];
  const aObjFilters = [];
  for (const fieldname in this.aFilters) {
    const aFiltersForName = this.aFilters[fieldname];

    // for 2 conditions
    if (aFiltersForName.length >= 1) {
      if (this.aSubOperators[fieldname]) {
        aObjFilters[fieldname] = {
          'logic': this.aSubOperators[fieldname] || 'or',
          'filters': []
        };
      } else {
        aObjFilters[fieldname] = {
          'logic': this.aSubOperators[fieldname] || this.mainOperator || 'or',
          'filters': []
        };
      }
      for (const j in aFiltersForName)
        aObjFilters[fieldname].filters.push(aFiltersForName[j]);

    }
  }

  let iCounter = 0;
  //   build filter
  for (const i in aObjFilters) {
    this.filter.push(aObjFilters[i]);
    iCounter++;
  }

  if (iCounter == 1)
    return this.filter[0];
  else if (this.filter.length > 0) {
    return {
      logic: this.mainOperator,
      filters: this.filter

    };
  } else return [];
};
/**
 * Method used for building the filter query.
 * @instance
 * @memberOf DataFilter
 */
DataFilter.prototype.buildQuery = function() {
  const oFilters = this.getFilter();

  let oQuery;
  if (oFilters.length > 0 || oFilters.length == undefined) {
    oQuery = {
      SDO: this.businessEntity.opt.SDO, // entity name
      ui_context: {
        controlName: this.businessEntity.controllerName, // control
        controlType: '', // grid etc
        container: '' // container name, window frame..
      },
      filters: oFilters
    };
  } else
    oQuery = {};


  if (this.fieldlist)
    oQuery.fieldlist = this.fieldlist;

  if (this.fulltextsearch != undefined)
    oQuery.fulltextsearch = this.fulltextsearch;
  this.businessEntity.urlQuery.akQuery = oQuery;
  if (this.cStringSorting) {
    this.businessEntity.urlQuery.filters = { orderBy: this.cStringSorting };
    delete this.cStringSorting;
  } else if (this.aSorting) {
    this.businessEntity.urlQuery.filters = { orderBy: '' };
    for (const x in this.aSorting) {
      if (x > 0)
        this.businessEntity.urlQuery.filters.orderBy += ',';


      this.businessEntity.urlQuery.filters.orderBy += (this.aSorting[x].direction == 'asc' ? this.aSorting[x].field : (`${this.aSorting[x].field} ${this.aSorting[x].direction}`));
    }
  }


};

/**
 * Method used for setting the filter sorting from an array.
 * @param {Array} aSort Settings for sorting.
 * @instance
 * @memberOf DataFilter
 */
DataFilter.prototype.setSorting = function(aSort) {
  this.aSorting = aSort;
  this.buildQuery();

  const setSortingCallback = oObserver => {
    if (aSort.length > 0) {
      const sort = {
        col: oObserver.dhx.getColIndexById(aSort[0].field.toLowerCase()),
        name: aSort[0].field,
        dir: aSort[0].direction
      };

      const oSortState = {
        state: true,
        iCol: sort.col,
        order: sort.dir
      };

      oObserver.SetSorting(oSortState);
    } else
      oObserver.SetSorting(false);
  };

  this.businessEntity.forEachGridObservable(this.businessEntity.observers, setSortingCallback);

};
/**
 * Method used for setting the filter sorting from a string.
 * @param {String} cSort The sort string
 * @instance
 * @memberOf DataFilter
 */
DataFilter.prototype.setStringSorting = function(cSort) {
  this.cStringSorting = cSort;
};
/**
 * Method used for clearing the sort fields.
 * @instance
 * @memberOf DataFilter
 * @return {void}
 */
DataFilter.prototype.clearSort = function() {
  this.cStringSorting = '';
  this.aSorting = null;

  if (this.businessEntity.urlQuery.filters)
    delete this.businessEntity.urlQuery.filters.orderBy;

  const _clearSortCallback = oObserver => {
    oObserver.dhx.setSortImgState(false);
    oObserver.SetSorting(false);
  };

  this.businessEntity.forEachGridObservable(this.businessEntity.observers, _clearSortCallback);

};
/**
 * Method used for setting the special full text search value.
 * @param {String} cValue Method used for setting the full text search value.
 * @instance
 * @memberOf DataFilter
 */
DataFilter.prototype.setFullTextSearch = function(cValue) {
  this.fulltextsearch = cValue;
  this.buildQuery();
};
