akioma.onInitJiraDesktop = function() {
  if (!akioma.sessionContext) return;
  if (akioma.sessionContext.eSwatSessionContext.HasJiraAccess && akioma.sessionContext.eSwatSessionContext.HasBitbucketAccess) return;
  app.controller.launchContainer({ containerName: 'JiraLoginDialog' });
};

akioma.launchGitpodWorkspace = function(oSelf, repositoryField = 'repositoryurl', branchField = 'branchname') {
  try {
    const oWindow = oSelf.controller.getAncestor('window');
    const oValue = oSelf.parent.getField('launchGitpodSelect').controller.getValue();
    if (!oValue) {
      akioma.notification({ type: 'error', text: 'No branch selected.', expire: 4000 });
      return;
    }

    const oGrid = oWindow.caller;
    const oSelectedRecords = oGrid.getSelectedRecords();
    if (oSelectedRecords.length === 0) {
      akioma.notification({ type: 'error', text: 'No branch selected in the grid.', expire: 4000 });
      return;
    }

    let cRepositoryUrl = oSelectedRecords[0][repositoryField];
    if (!cRepositoryUrl) {
      akioma.notification({ type: 'error', text: 'Invalid repository url!', expire: 4000 });
      return;
    }
    if (!cRepositoryUrl.endsWith('/'))
      cRepositoryUrl = `${cRepositoryUrl}/`;

    let cBranchName = '';
    switch (oValue) {
      case 'DEVELOP':
        cBranchName = 'develop';
        break;
      case 'BETA':
        cBranchName = 'beta';
        break;
      case 'MASTER':
        cBranchName = 'master';
        break;
      case 'SELECTED-BRANCH': {
        cBranchName = oSelectedRecords[0][branchField];
        break;
      }
    }
    if (!cBranchName) {
      akioma.notification({ type: 'error', text: 'Invalid branch name!', expire: 4000 });
      return;
    }

    loadApplicationSettingsByDomain('integration').then(settings => {
      let cGitpodUrl = settings.gitpodUrl || '';
      if (!cGitpodUrl) {
        akioma.notification({ type: 'error', text: 'Invalid Gitpod Url!', expire: 4000 });
        return;
      }
      if (!cGitpodUrl.endsWith('/'))
        cGitpodUrl = `${cGitpodUrl}/`;

      const cEnvironmentUrl = `${cGitpodUrl}#${cRepositoryUrl}branch/${cBranchName}`;
      window.open(cEnvironmentUrl, '_blank');

      oWindow.close();
    });
  } catch (err) {
    akioma.log.error(err);
  }
};

akioma.jiraLoginInit = function(oSelf) {
  const oForm = oSelf.getChildOfType('form');

  // temp hide jira fieldset
  oForm.controller.hideFormField('jira');

  loadApplicationSettingsByDomain('integration').then(settings => {
    if (settings.jiraUrl) {
      const oJiraUrl = oForm.getObject('jira_url');
      oJiraUrl.setValue(settings.jiraUrl);
    }
    if (settings.bitbucketUrl) {
      const oBitbucketUrl = oForm.getObject('bitbucket_url');
      oBitbucketUrl.setValue(settings.bitbucketUrl);
    }
  });
};

akioma.jiraLogin = function(oSelf) {
  const oFieldset = oSelf.parent;
  const cIntegrationName = oFieldset.name;

  const cUrl = oFieldset.dynObject.childs[`${cIntegrationName}_url`].getValue();
  if (cUrl == null || cUrl == '') return;

  const cUserName = oFieldset.dynObject.childs[`${cIntegrationName}_username`].getValue();
  if (cUserName == null || cUserName == '') return;

  const cPassword = oFieldset.dynObject.childs[`${cIntegrationName}_password`].getValue();
  if (cPassword == null || cPassword == '') return;

  akioma.invokeServerTask({
    name: 'Akioma.Swat.Authentication.AuthenticationHelperBT',
    methodName: 'AddIntegrationAuthenticationDetails',
    paramObj: {
      plcParameter: {
        Url: cUrl,
        UserName: cUserName,
        Password: cPassword,
        Integration: cIntegrationName
      }
    }
  }).then(() => {
    getSessionContext().then(result => akioma.setSessionProperty(`Has${cIntegrationName}Access`, result.eSwatSessionContext.HasJiraAccess, null, false));
    akioma.notification({ text: `Successful ${cIntegrationName} login!`, type: 'success' });
  });
};
