// ************************* toolbarfillin ********************************
$.extend({
  ak_toolbarfillin: function(options) {
    const oSelf = this,
      defaults = {
        id: '',
        name: '',
        title: ''
      };

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;

    this.registerDynObject = true;
    this.useParentDynObjectLink = true;

    // get new element
    const cName = this.opt.name;

    const iPos = this.parent.opt.pos + 1;
    this.parent.opt.pos = iPos;

    // get toolbar
    const oToolbar = this.parent.dhx;
    if (oToolbar) {
      // check for document import
      if (this.opt.typeDetail) {
        switch (this.opt.typeDetail) {
          case 'docimport':
            oToolbar.addInput(cName, iPos, '', 160);

            dhtmlx.delay(() => {
              const cPrefix = oToolbar.idPrefix,
                oElm = oToolbar.objPull[cPrefix + cName];
              $(oElm.obj).html('<input class=\'inp\' type=\'file\' style=\'width: 200px;\' multiple />');
              $(oElm.obj)
                .find('input')
                .bind('change', evt => {
                  evt.stopPropagation();
                  evt.preventDefault();

                  oSelf.files = evt.target.files;

                  dhtmlx.delay(function() {
                    // call action
                    app.controller.callAction({
                      ActionType: this.opt.actiontype,
                      Action: this.opt.action,
                      Category: this.opt.category,
                      RunPar: this.opt.RunPar,
                      CallObject: this.opt.logicalObject,
                      caller: this,
                      click: this.opt.name
                    },
                    this);
                  }, oSelf);

                  return false;
                });
            }, this);
            break;

          default:
            oToolbar.addInput(cName, iPos, '', 80);
            if (this.parent.opt.containerObject === 'header') {
              /* different styles if this toolbar is displayed in a panel header*/
              const btn = oToolbar.objPull[oToolbar.idPrefix + cName].obj;
              // btn.style.float = "left";
              btn.style.background = 'transparent';
              // btn.style.border = "none";
            }

            break;

        }
      } else
        oToolbar.addInput(cName, iPos, '', 80);


    } else
      !_isIE && console.error(`No valid parent for toolbarfillin ${cName}`);

  }
});

// methods for fill in
$.ak_toolbarfillin.prototype = {

  // enter text ***************
  enterText: function(cValue) {

    this.value = cValue;

    if (this.parent.toolbarMenuStructure != undefined) {
      const oMenu = this.parent.toolbarMenuStructure.getMenuItemByCode(this.opt.id);
      const oMenuAction = this.parent.toolbarMenuStructure.aMenuActions[oMenu.id];
      this.opt.actiontype = oMenuAction.ActionType;
      this.opt.action = oMenuAction.Action;
      this.opt.category = oMenuAction.Category;
    }

    // call action
    app.controller.callAction({
      ActionType: this.opt.actiontype,
      Action: this.opt.action,
      Category: this.opt.category,
      RunPar: this.opt.RunPar,
      CallObject: this.opt.logicalObject,
      caller: this,
      click: this.opt.name
    },
    this);
    return true;
  },

  // get field value *********
  getFieldValue: function() {
    return this.value;
  },

  finishConstruct: function() {
    this.parent.aToolbarItems.push(this.opt.name);
  }
};
