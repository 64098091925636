akioma.appointment = {};
akioma.appointment.DE = {
  date: {
    month_full: [ ' Januar', ' Februar', ' März ', ' April', ' Mai', ' Juni', ' Juli', ' August', ' September ', ' Oktober', ' November ', ' Dezember' ],
    month_short: [ 'Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez' ],
    day_full: [ 'Sonntag', 'Montag', 'Dienstag', ' Mittwoch', ' Donnerstag', 'Freitag', 'Samstag' ],
    day_short: [ 'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa' ]
  },
  labels: {
    dhx_cal_today_button: 'Heute',
    day_tab: 'Tag',
    week_tab: 'Woche',
    month_tab: 'Monat',
    new_event: 'neuer Eintrag',
    icon_save: 'Speichern',
    icon_cancel: 'Abbrechen',
    icon_details: 'Details',
    icon_edit: 'Ändern',
    icon_delete: 'Löschen',
    confirm_closing: '', // "Ihre Veränderungen werden verloren sein, wollen Sie ergänzen? "
    confirm_deleting: 'Der Eintrag wird gelöscht',
    section_description: 'Beschreibung',
    section_time: 'Zeitspanne',
    full_day: 'Ganzer Tag',

    confirm_recurring: 'Wollen Sie alle Einträge bearbeiten oder nur diesen einzelnen Eintrag?',
    section_recurring: 'Wiederholung',
    button_recurring: 'Aus',
    button_recurring_open: 'An',
    button_edit_series: 'Bearbeiten Sie die Serie',
    button_edit_occurrence: 'Bearbeiten Sie eine Kopie',

    /* agenda view extension*/
    agenda_tab: 'Agenda',
    date: 'Datum',
    description: 'Beschreibung',

    /* year view extension*/
    year_tab: 'Jahre',

    /* week agenda view extension*/
    week_agenda_tab: 'Agenda',

    /* grid view extension*/
    grid_tab: 'Grid',

    /* touch tooltip*/
    drag_to_create: 'Drag to create',
    drag_to_move: 'Drag to move',

    /* dhtmlx message default buttons */
    message_ok: 'OK',
    message_cancel: 'Cancel',

    /* wai aria labels for non-text controls */
    next: 'Next',
    prev: 'Previous',
    year: 'Year',
    month: 'Month',
    day: 'Day',
    hour: 'Hour',
    minute: 'Minute'
  }
};

akioma.appointment.ES = {
  date: {
    month_full: [ 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre' ],
    month_short: [ 'Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic' ],
    day_full: [ 'Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado' ],
    day_short: [ 'Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb' ]
  },
  labels: {
    dhx_cal_today_button: 'Hoy',
    day_tab: 'Día',
    week_tab: 'Semana',
    month_tab: 'Mes',
    new_event: 'Nuevo evento',
    icon_save: 'Guardar',
    icon_cancel: 'Cancelar',
    icon_details: 'Detalles',
    icon_edit: 'Editar',
    icon_delete: 'Eliminar',
    confirm_closing: '', // "Sus cambios se perderán, continuar ?"
    confirm_deleting: 'El evento se borrará definitivamente, ¿continuar?',
    section_description: 'Descripción',
    section_time: 'Período',
    full_day: 'Todo el día',

    confirm_recurring: '¿Desea modificar el conjunto de eventos repetidos?',
    section_recurring: 'Repita el evento',
    button_recurring: 'Impedido',
    button_recurring_open: 'Permitido',
    button_edit_series: 'Editar la serie',
    button_edit_occurrence: 'Editar este evento',

    /* agenda view extension*/
    agenda_tab: 'Día',
    date: 'Fecha',
    description: 'Descripción',

    /* year view extension*/
    year_tab: 'Año',

    /* week agenda view extension*/
    week_agenda_tab: 'Día',

    /* grid view extension*/
    grid_tab: 'Reja',

    /* touch tooltip*/
    drag_to_create: 'Drag to create',
    drag_to_move: 'Drag to move',

    /* dhtmlx message default buttons */
    message_ok: 'OK',
    message_cancel: 'Cancel',

    /* wai aria labels for non-text controls */
    next: 'Next',
    prev: 'Previous',
    year: 'Year',
    month: 'Month',
    day: 'Day',
    hour: 'Hour',
    minute: 'Minute'
  }
};

akioma.appointment.FR = {
  date: {
    month_full: [ 'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre' ],
    month_short: [ 'Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin', 'Juil', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc' ],
    day_full: [ 'Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi' ],
    day_short: [ 'Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam' ]
  },
  labels: {
    dhx_cal_today_button: 'Aujourd\'hui',
    day_tab: 'Jour',
    week_tab: 'Semaine',
    month_tab: 'Mois',
    new_event: 'Nouvel événement',
    icon_save: 'Enregistrer',
    icon_cancel: 'Annuler',
    icon_details: 'Détails',
    icon_edit: 'Modifier',
    icon_delete: 'Effacer',
    confirm_closing: '', // Vos modifications seront perdus, êtes-vous sûr ?
    confirm_deleting: 'L\'événement sera effacé sans appel, êtes-vous sûr ?',
    section_description: 'Description',
    section_time: 'Période',
    full_day: 'Journée complète',

    confirm_recurring: 'Voulez-vous éditer toute une série d\'évènements répétés?',
    section_recurring: 'Périodicité',
    button_recurring: 'Désactivé',
    button_recurring_open: 'Activé',
    button_edit_series: 'Modifier la série',
    button_edit_occurrence: 'Modifier une copie',

    /* agenda view extension*/
    agenda_tab: 'Jour',
    date: 'Date',
    description: 'Description',

    /* year view extension*/
    year_tab: 'Année',

    /* week agenda view extension*/
    week_agenda_tab: 'Jour',

    /* grid view extension*/
    grid_tab: 'Grille',

    /* touch tooltip*/
    drag_to_create: 'Drag to create',
    drag_to_move: 'Drag to move',

    /* dhtmlx message default buttons */
    message_ok: 'OK',
    message_cancel: 'Cancel',

    /* wai aria labels for non-text controls */
    next: 'Next',
    prev: 'Previous',
    year: 'Year',
    month: 'Month',
    day: 'Day',
    hour: 'Hour',
    minute: 'Minute'
  }
};

akioma.appointment.IT = {
  date: {
    month_full: [ 'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre' ],
    month_short: [ 'Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic' ],
    day_full: [ 'Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato' ],
    day_short: [ 'Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab' ]
  },
  labels: {
    dhx_cal_today_button: 'Oggi',
    day_tab: 'Giorno',
    week_tab: 'Settimana',
    month_tab: 'Mese',
    new_event: 'Nuovo evento',
    icon_save: 'Salva',
    icon_cancel: 'Chiudi',
    icon_details: 'Dettagli',
    icon_edit: 'Modifica',
    icon_delete: 'Elimina',
    confirm_closing: '', // Le modifiche apportate saranno perse, siete sicuri?
    confirm_deleting: 'L\'evento sarà eliminato, siete sicuri?',
    section_description: 'Descrizione',
    section_time: 'Periodo di tempo',
    full_day: 'Intera giornata',

    confirm_recurring: 'Vuoi modificare l\'intera serie di eventi?',
    section_recurring: 'Ripetere l\'evento',
    button_recurring: 'Disattivato',
    button_recurring_open: 'Attivato',
    button_edit_series: 'Modificare la serie',
    button_edit_occurrence: 'Modificare una copia',

    /* agenda view extension*/
    agenda_tab: 'Agenda',
    date: 'Data',
    description: 'Descrizione',

    /* year view extension*/
    year_tab: 'Anni',

    /* week agenda view extension*/
    week_agenda_tab: 'Agenda',

    /* grid view extension*/
    grid_tab: 'Griglia',

    /* touch tooltip*/
    drag_to_create: 'Drag to create',
    drag_to_move: 'Drag to move',

    /* dhtmlx message default buttons */
    message_ok: 'OK',
    message_cancel: 'Cancel',

    /* wai aria labels for non-text controls */
    next: 'Next',
    prev: 'Previous',
    year: 'Year',
    month: 'Month',
    day: 'Day',
    hour: 'Hour',
    minute: 'Minute'
  }
};
