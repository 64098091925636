/**
 * Base Class for Grid based Objects
 * @class BaseDataGrid
 *
 */
akioma.BaseDataGrid = function(options) {
  this._addParentContainerClass(options.parent.dhx);
  this.registerDynObject = true;
};

akioma.BaseDataGrid.prototype = {
  /**
   * Method for registering filter dynselect ENTER keypress listener
   * @memberof BaseDataGrid
   * @instance
   * @private
   */
  _registerFilterEnterKeypress() {
    Mousetrap.bindGlobal(`enter.${this.opt.id}`, e => {
      try {
        const bActiveWindow = this.dynObject.container.controller.dhx.isOnTop();
        if (bActiveWindow) {
          // check if input from grid header is in focus
          if ($.contains(this.dhx.hdr, document.activeElement)) {
            if ($(document.activeElement).closest('.select2').length === 0) {
              $.each(this.dhx.filters, function() {
                this[0].blur();
              });
              this.FilterGo();
            }
            e.stopImmediatePropagation();
          }
        }
      } catch (e) {
        console.error('Error saving posframe form values', e);
      }
    });
  },

  /**
   * Method used for adding a class on the grid object parent for styling
   * @param {Object} parent
   * @memberof BaseDataGrid
   * @instance
   * @private
   */
  _addParentContainerClass(parent) {
    $(parent.cell).find('.dhx_cell_cont_layout').addClass('gridWrapper');
  }
};
