/**
 * Base Akioma Object with methods
 * @class ak_global
 * @property {DHTMLXObject} dhx The DHTMLX Object
 * @property {dynObject} dynObject The Akioma dynObject
 */
$.ak_global = function() {};

Object.assign($.ak_global.prototype, akioma.ObjectStore.prototype); // extends object store class

Object.assign($.ak_global.prototype, { /** @lends ak_global */
  /**
   * @constructs ak_global
   */
  construct() {

    // save link to akioma object
    if (this.dhx)
      this.dhx.akElm = this;

    this.name = this.opt.name;

    // create dynobject
    if (this.registerDynObject) {

      // generate control namespace
      this.generateNamespace();

      // setup vuex dynamic module under generated namespace
      if (this.hasModuleRegister() && this.opt.id !== '')
        this.setupVuexStore(); // register module and watchers

      this.dynObject = new dynObject({
        controller: this,
        attributes: this.opt,
        name: this.opt.name,
        SDO: this.opt.SDO,
        type: this.view,
        dataSource: this.dataSource,
        parent: null
      });

      if (this.parent) {
        const oDynParent = $.getDynParent(this.parent);
        if (oDynParent) {
          this.dynObject.parent = oDynParent.dynObject;
          oDynParent.dynObject.childs[this.opt.name] = this.dynObject;
        }
      }

      switch (this.view) {
        case 'winframe':
          this.dynObject.container = null;
          break;
        case 'window':
        case 'popup':
          this.dynObject.container = this.dynObject;
          break;
        case 'frame':
          this.dynObject.screen = this.dynObject;
          if (this.dynObject.parent)
            this.dynObject.container = this.dynObject.parent.container;
          break;
        default:
          if (this.dynObject.parent) {
            this.dynObject.container = this.dynObject.parent.container;
            this.dynObject.screen = (this.dynObject.parent.screen) ? this.dynObject.parent.screen : this.dynObject.parent.container;
          }
          break;
      }

      this.dynObject.topScreen = this.dynObject.container;

      if (this.dynObject.type === 'window')
        this.dynObject.topRuleScreen = this.dynObject;

      else
      if (this.dynObject.type === 'frame' && this.dynObject.parent.topRuleScreen && this.dynObject.parent.topRuleScreen.controller.bMainWindow)
        this.dynObject.topRuleScreen = this.dynObject;

      else
      if (this.dynObject.parent)
        this.dynObject.topRuleScreen = this.dynObject.parent.topRuleScreen;

      if (this.dynObject.type === 'window' || this.dynObject.type === 'frame' && !(this.dynObject.parent.type === this.dynObject.type && this.dynObject.parent.name === this.dynObject.name))
        this.dynObject.directRuleScreen = this.dynObject;

      else
      if (this.dynObject.parent)
        this.dynObject.directRuleScreen = this.dynObject.parent.directRuleScreen;
    }
  },

  /**
   * Method used to call eventOnStateChanged
   * @instance
   * @memberof ak_global
   * @protected
   * @param {String|Object} state a string containing all the customStates of the current object
   * or an object with the dirtystate(hasChanges/hasErrors) and the value(true/false)
   */
  _callEventOnStateChanged(state) {
    if (this.opt.eventOnStateChanged) {
      try {
        this.stateData = state;
        app.controller.callAkiomaCode(this, this.opt.eventOnStateChanged);
      } catch (e) {
        console.error(e);
      }
    }
  },

  /**
   * Get custom state data.
   * @instance
   * @memberof ak_global
   * @protected
   * @param {Object} fieldStore
   * @returns {String}
   */
  _getCustomStateData: function(fieldStore) {
    let stateData = '';
    fieldStore.customStates.forEach(customState => {
      stateData = `${stateData} ${customState.name}`;
    });
    return stateData.trim();
  },

  /**
   * Return the object with the given object name from its children objects. (down the tree)
   * @param  {String} name Name of Akioma Object
   * @returns {ak_global|dynObject}
   * @instance
   * @memberOf ak_global
   */
  getObject(name) {
    let result;
    if (this.childs) {
      // check type in all children
      if (!$.isEmptyObject(this.childs)) {
        for (const i in this.childs) {
          if (this.childs[i]) {
            const childName = this.childs[i].name;
            if (childName && childName.toLowerCase() === name.toLowerCase()) {
              if (this.childs[i].dynObject)
                return this.childs[i].dynObject;
              else
                return this.childs[i];
            }

            if (this.childs[i].childs) {
              result = this.childs[i].getObject(name);
              if (result && result.dynObject)
                return result.dynObject;
              else if (result)
                return result;
            }
          }
        }
      } else {
        let objectController = this.controller;
        if (!objectController)
          objectController = this;

        const object = $.getObjectByName({ name: name, start: objectController });
        if (object && object.dynObject)
          return object.dynObject;
        else
          return object;

      }
    }
    return null;
  },

  // loop through childs
  children(cType, callback) {
    let aArray;

    if (typeof cType == 'string')
      aArray = $.grep(this.childs, oElm => cType.indexOf(oElm.view) > -1);
    else {
      callback = cType;
      aArray = this.childs;
    }

    return jQuery.each(aArray, callback);
  },

  // loop through childs
  callInChildren(cMethod, oElm) {
    let i;

    try {
      // call here
      if (typeof this[cMethod] == 'function')
        this[cMethod](oElm);
    } catch (oErr) {
      akioma.log.error([ 'callInChildren error method:', cMethod, this, oElm ]);
    }
    // check for all children
    for (i in this.childs) {
      try {
        if (this.childs[i].childs.length != 0 && this.childs[i].view != 'popover')
          this.childs[i].callInChildren(cMethod, oElm);
      } catch (oErr) {
        akioma.log.error([ 'callInChildren child-errorerror method:', cMethod, this, oElm, this.childs[i], i ]);
      }
    }
  },

  // check if object is on the same level
  checkObjSiblings(oCheck) {
    const oParent = this.parent;

    let bSameLvl = false;
    if (oParent) {
      oParent.children(oCheck.view, function() {
        if (this == oCheck)
          bSameLvl = true;
      });
    }

    return bSameLvl;
  },

  // register key ********************
  registerKey(oElm) {
    let cKey = '';

    if (typeof oElm.key == 'string') {
      const aKey = oElm.key.split('-');
      cKey = `${((aKey.indexOf('CTRL') > -1) ? 'C' : 'c')
        + ((aKey.indexOf('ALT') > -1) ? 'A' : 'a')
      }s`
        + '-';

      switch (aKey[aKey.length - 1]) {
        case 'INS':
          cKey += '';
          break;
        default:
          cKey += aKey[aKey.length - 1].charCodeAt(0);
          break;
      }
    } else {
      cKey = `${((oElm.key.ctrlKey) ? 'C' : 'c')
        + ((oElm.key.altKey) ? 'A' : 'a')
        + ((oElm.key.shiftKey) ? 'S' : 's')
      }-${oElm.key.keyCode.charCodeAt(0)}`;
    }

    this.keys[cKey] = oElm.action;
  },

  // set property ********************
  setProperty(oElm) {
    if (typeof this.setLocalProperty == 'undefined')
      setProperty(this, oElm);
  },

  // get name for a specific link **************
  getObjName(cLinkName, cLinkType) {
    // for links created dynamically check dynObjName
    if (this.dynObjName)
      return this.dynObjName;

    const aTmpLink = $.grep((`${this.opt.channelSrc},${this.opt.channelTrg}`).split(','), entry => {
      const aEntry = entry.split(':');
      return (aEntry.length == 3 && aEntry[0] == cLinkName && aEntry[1] == cLinkType);
    });
    if (aTmpLink.length > 0 && aTmpLink[0].split(':').length > 1)
      return aTmpLink[0].split(':')[2];
    return null;
  },

  /**
   * This method returns the first descendant element that matches the given object type.
   * @param {String|Array} aName Input any object type name or array of object types
   * @returns {Object|null} the Akioma Object found or null
   * @instance
   * @memberOf ak_global
   *
   */
  getAncestor(aName) {

    // change to array
    if (typeof aName == 'string')
      aName = aName.split(',');

    // check own
    if (aName.indexOf(this.view) > -1)
      return this;

    // check for parent
    if (this.parent)
      return this.parent.getAncestor(aName);

    return null;
  },

  /**
   * This method returns the first descendant element that matches the given object type and it's having second parameter as a stop condition.
   * @param {String|Array} aName Input any object type name or array of object types
   * @param {String} objectType object type used as a stop condition
   * @returns {Object|null} the Akioma Object found or null
   * @instance
   * @memberOf ak_global
   *
   */
  getAncestorUntil(aName, objectType) {

    // change to array
    if (typeof aName == 'string')
      aName = aName.split(',');

    if (this.view == objectType)
      return null;


    // check own
    if (aName.indexOf(this.view) > -1)
      return this;

    // check for parent
    if (this.parent)
      return this.parent.getAncestorUntil(aName, objectType);

    return null;
  },

  /**
   * Returns list of children inside this object, of a given type.
   * @param  {string|array} type Name of object type eg. tabbar
   * @param{boolean} bExclude Exclude current object, look only in children tree
   * @instance
   * @memberOf ak_global
   * @returns {array}       The list of objects of the given type
   */
  getAllChildrenByType(type, bExclude) {
    let aList = [];
    let bMulti = false;

    if ($.isArray(type))
      bMulti = true;


    if (!bMulti && this.view == type)
      aList.push(this);
    else if (bMulti && type.indexOf(this.view) !== -1 && !bExclude)
      aList.push(this);


    for (const x in this.childs) {
      const oChild = this.childs[x];
      const aChildrenFound = oChild.getAllChildrenByType(type);

      if (aChildrenFound.length > 0)
        aList = aList.concat(aChildrenFound);
    }

    return aList;
  },

  /**
   * This method returns the first descendant element that matches the given object type.
   * @param {String|Array} aName Input any object type name or array of object types
   * @returns {Object} the Akioma Object found
   * @instance
   * @memberOf ak_global
   *
   */
  getDescendant(aName) {
    let oElm, i;

    // change to array
    if (typeof aName == 'string')
      aName = aName.split(',');

    // check own
    if (aName.indexOf(this.view) > -1)
      return this;

    // check for children
    for (i in this.childs) {
      if (this.childs[i] && this.childs[i].getDescendant)
        oElm = this.childs[i].getDescendant(aName);
      if (oElm)
        return oElm;
    }

    return null;
  },

  /**
   * Add an panel message and return the index of the message
   * @param  {object} msg contains the message text and type
   * @instance
   * @memberOf ak_global
   * @return {number} the index of the message
   */
  addPanelMessage(msg) {
    let oSelf = this;
    if (this.view.toLowerCase() === 'ittext')
      oSelf = this.parent;

    const name = oSelf.getFilteredNamespace();
    name.push('getCursor');
    if (oSelf.parent && typeof (oSelf.parent.displayPanelMessage) === 'function') {
      const iIdx = oSelf.parent.displayPanelMessage(msg);

      akioma.executeAsync(() => {
        oSelf.dhx.setSizes();
      });

      return iIdx;
    }
  },

  /**
   * Method for adding debug info(title) and outline to elements
   * @instance
   * @memberof ak_global
   */
  addObjectDebugInfo() {
    if (app.sessionData.objectNamesInTitles) {
      const objectName = this.opt.SubObjectName || this.opt.ObjectMasterName || this.opt.name;
      if (akioma.DevTools.devToolObjectsToSkip.includes(objectName))
        return;

      let oParent = this.parent;
      while (oParent != undefined) {

        const parentName = oParent.opt.SubObjectName || oParent.opt.ObjectMasterName || oParent.opt.name;
        if (akioma.DevTools.devToolObjectsToSkip.includes(parentName))
          return;

        oParent = oParent.parent;
      }

      const dataSourceName = !isNull(this.opt.dataSource) ? this.opt.dataSource.split('|')[0] : '';
      this.opt.title = `${dataSourceName} >> ${this.opt.name}  | ${this.opt.title}`;

      // add panel styling devmode
      let oPanel = this.getAncestor('panel');
      if (!oPanel)
        oPanel = this.getAncestor('frame');
      if (oPanel)
        $(oPanel.dhx.cell).attr('akdevmode', true);
    }
  },

  /**
   * Add an window message and return the index of the message
   * @param  {object} msg contains the message text and type
   * @instance
   * @memberOf ak_global
   * @return {number} the index of the message
   */
  addWindowMessage(msg) {
    if (typeof (this.displayWindowMessage) === 'function') {
      const iIdx = this.displayWindowMessage(msg);

      akioma.executeAsync(() => {
        this.dhx._setSize();
      });

      return iIdx;
    }
  },

  /**
   * Remove an panel message from a controller by the given id
   * @param  {number} id the index of the message
   * @returns {void}
   * @instance
   * @memberOf ak_global
   */
  removePanelMessage(id) {
    if (this.parent && typeof (this.parent.removePanelMessageById) === 'function') {
      this.parent.removePanelMessageById(id);

      akioma.executeAsync(() => {
        this.dhx.setSizes();
      });
    }
  },

  /**
   * Remove an window message from a controller by the given id
   * @param  {number} id the index of the message
   * @returns {void}
   * @instance
   * @memberOf ak_global
   */
  removeWindowMessage(id) {
    if (typeof (this.removeWindowMessageById) === 'function') {
      this.removeWindowMessageById(id);

      akioma.executeAsync(() => {
        this.dhx._setSize();
      });
    }
  },

  /**
   * Remove all panel message from a controller
   * @returns {void}
   * @instance
   * @memberOf ak_global
   */
  clearPanelMessages() {
    if (this.parent && typeof (this.parent.clearAllPanelMessages) === 'function') {
      this.parent.clearAllPanelMessages();

      akioma.executeAsync(() => {
        this.dhx.setSizes();
      });
    }
  },

  /**
   * Remove all Window message from a controller
   * @returns {void}
   * @instance
   * @memberOf ak_global
   */
  clearWindowMessages() {
    if (typeof (this.clearAllWindowMessages) === 'function') {
      this.clearAllWindowMessages();

      akioma.executeAsync(() => {
        this.dhx._setSize();
      });
    }
  },

  /**
   * Set a custom state on a controller
   * @example
   * var field1 = akioma.root.dynObject.getObject('offerheaderdata').getField('selfdesc')
   * field1.controller.setCustomState('Alert1', {})
   *
   * @param {string} name
   * @param {object} options
   * @returns {void}
   * @instance
   * @memberof ak_global
   */
  setCustomState(name, options) {
    const id = this.opt.id;
    // If form field
    if (this.form) {
      this.getAncestor('form')._dispatch('setFormFieldCustomState', { id, name, options });
      if (options && options.bubbleUp)
        this.getAncestor('form')._dispatch('setCustomState', { id, name, options });
    } else
      this._dispatch('setCustomState', { id, name, options });
  },

  /**
   * Remove a custom state from a controller
   * @example
   * var field1 = akioma.root.dynObject.getObject('offerheaderdata').getField('selfdesc')
   * field1.controller.clearCustomState('isPrinted')
   *
   * @param {string} name
   * @returns {void}
   * @instance
   * @memberof ak_global
   */
  clearCustomState(name) {
    if (this.form) {
      const id = this.opt.id;
      this.getAncestor('form')._dispatch('clearFormFieldCustomState', { id, name });
      this.getAncestor('form')._dispatch('clearCustomState', { name });
    } else
      this._dispatch('clearCustomState', { name });
  },

  /**
   * Method for clearing up the hasChanges state on the object
   * @instance
   * @memberof ak_global
   */
  clearHasChanges() {
    if (this.oVuexState && this.oVuexState.attributes && this.oVuexState.attributes.hasChanges) {
      if (this.view === 'datagrid2')
        this._commit('CLEAR_CHANGED_ROWS');
      this._dispatch('decrementHasChanges', 1);
    }
    if (this.view.toLowerCase().startsWith('businessentity'))
      this.clearLinkedHasChanges();
  },

  /**
   * Method for checking if object has changes
   * @memberof ak_global
   * @returns {boolean}
   */
  hasChanges() {
    if (this.view.toLowerCase().startsWith('businessentity'))
      return this.hasLinkedChanges();

    if (this.oVuexState && this.oVuexState.attributes)
      return this.oVuexState.attributes.hasChanges;
    return false;
  },

  // evaluate code in instance
  callCode(cCode, oLaunchedFrom) {
    const oSelf = this,
      self = (this.dynObject) ? this.dynObject : {};

    // decide if it's server or client code
    if (cCode.substr(0, 1) == '$') {
      cCode = cCode.substr(1);
      akioma.swat.evaluateCode({ code: cCode, controller: oSelf, dynObj: self, launchedFrom: oLaunchedFrom, catchError: true });
    } else {
      try {
        const aCode = eval(`[${cCode}]`);
        app.controller.callServerMethod(aCode[0], aCode[1]);
      } catch (e) {
        !_isIE && console.warn([ 'Error executing akioma code', oSelf, cCode, e ]);
        akioma.message({ type: 'alert-error', title: 'callCode', text: `Error executing akioma code: <br />${e.message}<br />${cCode}` });
      }
    }
  },

  // destruct **************************
  destruct() {
    let i;

    if (this.opt.ObjectInstanceGuid)
      delete window.akRepositoryObjects[this.opt.ObjectInstanceGuid];
    else if (this.opt.ObjectMasterGuid)
      delete window.akRepositoryObjects[this.opt.ObjectMasterGuid];

    if (this.parent && this.parent.VueInstance) {
      this.parent.VueInstance.unmount();
      delete this.parent.VueInstance;
    }

    if (this._akiomaWrapperObject) {
      this._akiomaWrapperObject.destroy();
      delete this._akiomaWrapperObject;
    }
    if (this.dhx && this.dhx.akElm)
      delete this.dhx.akElm;

    // call destruct in all children
    if (this.childs.length > 0) {
      try {
        while (this.childs.length > 0) {
          if (typeof this.childs[0].destruct == 'function')
            this.childs[0].destruct();
          else
            $.removeEntry(this.childs, this.childs[0]);
        }
      } catch (e) {
        !_isIE && console.error([ 'Error destruct children: ', this, this.childs[0].view, this.childs[0], e ]);
      }
    }

    // remove object from observer
    try {
      if (this.dataSource && this.dataSource.observers)
        $.removeEntry(this.dataSource.observers, this);

    } catch (e) {
      !_isIE && console.error([ 'Error deleting from observer: ', this.view, this, e ]);
    }

    // unsubscribe links
    try {
      if (this.links.length > 0) {
        for (i in this.links)
          app.messenger.unSubscribe(this, this.links[i]);
      }
    } catch (e) {
      !_isIE && console.error([ 'Error unsubscribing: ', this.view, this, e ]);
      akioma.message({ type: 'alert-error', title: 'Destruct', text: `Error unsubscribing: ${this.view}` });
    }
    // before delete
    // call before destroy in class
    try {
      if (typeof this.beforeDestroy == 'function')
        this.beforeDestroy();
    } catch (e) {
      !_isIE && console.error([ 'Error before destroy events cleanup: ', this.view, this, e ]);
    }

    // delete dynobject
    try {
      if (this.dynObject)
        this.dynObject.deleteObject();

    } catch (e) {
      !_isIE && console.error([ 'Error deleting dynObject', this, this.dynObject, e ]);
    }

    if (this.aPanelMenuButtons) {
      this.aPanelMenuButtons.forEach(el => {
        if (el.menu) {
          el.menu.destroy();
          delete el.menu;
        }
        if (el.dhx)
          $(el.dhx).off();
      });
    }

    // call destroy in class
    try {
      if (typeof this.destroy == 'function')
        this.destroy();
    } catch (e) {
      !_isIE && console.error([ 'Error destroying: ', this.view, this, e ]);
    }

    this.destroyVuexStore(); // unregister module and watchers

    // delete this in parent
    try {
      if (this.parent && this.parent.childs)
        $.removeEntry(this.parent.childs, this);
    } catch (e) {
      !_isIE && console.error([ 'Error deleting from childs in parent: ', this.view, this, e ]);
    }

    // assign different values
    try {
      if (this.dataSource)
        this.dataSource = null;

      // delete
      delete this;
    } catch (e) {
      !_isIE && console.error([ 'Error deleting: ', this.view, this, e ]);
      akioma.message({ type: 'alert-error', title: 'Destruct', text: `Error deleting: ${this.view}` });
    }
  }
});

