/**
* The ShortcutManagerClass handles the global keyboard shortcuts for an environment.
* @class ShortcutManagerClass
*/
akioma.ShortcutManagerClass = class {

  constructor() {
    // global shortcuts used in akioma/js/KeyboardShortcutsBinder
    this.UrmelScreenEvent = 'shift+ctrl+alt+u';
    this.AutoTranEvent = 'shift+ctrl+alt+t';
    this.OpenRepository = 'shift+ctrl+alt+l';
    this.DeleteLocalStorage = 'shift+alt+del';
    this.HelpKeyboardEvent = 'f1';
    this.VersionKeyboardEvent = 'shift+alt+f1';
    this.OmnisearchScreen = 'ctrl+f';
    this.DesktopModeEvent = 'ctrl+d';
    this.CloseWindowKeyboardEvent = 'alt+f3';
    this.NextWindow = 'shift+alt+up';
    this.PreviousWindow = 'shift+alt+down';
    this.ClearAllProgressState = 'shift+alt+ctrl+del';
    this.ObjectNamesInTitles = 'shift+ctrl+alt+p';

    // local shortcuts
    this.FocusRibbonToolbar = 'alt+ctrl+r';

    this.GridResetFilter = 'f9';
    this.GridResetRefreshFilter = 'shift+f9';

    this.OpenLayoutDesignerScreen = 'ctrl+shift+alt+d';

    this.SwitchTabbarLeft = 'ctrl+left';
    this.SwitchTabbarRight = 'ctrl+right';
    this.SwitchSidebarUp = 'alt+up';
    this.SwitchSidebarDown = 'alt+down';
    this.NextPanel = 'alt+right';
    this.PreviousPanel = 'alt+left';
  }

  /**
    * Method for getting the keyboard shortcut for a certain action
    * @param {string}cId cId The name of the action
    * @return the shortcut for the given action
    * @memberOf ShortcutManagerClass
    */
  get(cId) {
    return this[cId];
  }

  /**
    * Method for setting the keyboard shortcut for a certain action
    * @param {string}cId cId The name of the action
    * @param {string}cShortcut cShortcut The new shortcut
    * @memberOf ShortcutManagerClass
    */
  set(cId, cShortcut) {
    this[cId] = cShortcut;
  }

  /**
     * Method for binding global shortcut.
     * @param {string} keys
     * @param {function} callback
     * @param {any} [action]
     */
  bindGlobal(keys, callback, action) {
    Mousetrap.bindGlobal(keys, callback, action);
  }
};
