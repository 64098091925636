//
// Form Builder Control


(function($) {

  $.extend({
    /**
     * SwatFormDesign Control
     * @class ak_formbuilder
     * @param {Object} options Repository attributes for SwatFormDesign.
     * @param {string} options.BorderTitle The Title of a dynamic Viewer or Browser
     * @param {string} options.EventOnInitialize client side code to run when Container has been initialized
     * @param {string} options.contextMenu the id of a menuStructure which will be used as a context-menu
     * @param {string} options.titleHeader specifies which panelHeader to use. when empty, then uses the header of its own panel. if "none" then no header at all. if "parent" it uses the header of the parent panel
     * @param {string} options.floatingActionButton the id of a menustructure, which will be rendered as a FAB
     * @param {string} options.LayoutOptions List of multi-layout options for the object.
     * @param {string} options.panelMenu comma separated list of menu-structures which will be shown as panelHeader-Buttons </br>
     * Can also contain the flag #NoDropDown that specifies that the menu should not be loaded as a dropdown but each menu item should be added in the panel-Header. </br>
     * For example: </br>
     * <code>menuStructSave,menuSettings#NoDropDown,menuLookup</code> </br>
     * The buttons support font icons with the following attributes: </br>
     * 1. Css attributes, defined like this: fa fa-user#color:red </br>
     * 2. Css classes, defined like this: fa fa-user#_style:module_prod
     * 3. Stacked font icons, defined like this: fas fa-circle$fas fa-flag. Both icons also support Css attributes or Css classes, like this: fas fa-circle#color:red$fas fa-flag#_style:module_prod </br>
     */

    ak_formbuilder: function(options) {
      const oSelf = this,
        defaults = {
          id: 'form-build-test',
          name: 'Form builder'
        };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;

      this.registerDynObject = true;
      this.useParentDynObjectLink = true;

      this.aListNewInstances = []; // array of all newly added instances for replacing insguid in attributes
      this.aListNewTempGuids = []; // for the temp guids on add

      this.aListNewInstancesContainers = [];
      this.aListNewTempGuidsContainers = [];

      // attach new html element to parent
      const oParent = this.parent;
      if (oParent) {

        const cFormBuilderHTML = `<div id='${this.opt.id}' style='height:100%;width:100%;'></div>`;

        // oParent.dhx.attachHTMLString(cFormBuilderHTML);
        const oLayout = oParent.dhx.attachLayout({
          pattern: '1C',
          cells: [{ id: 'a', text: 'Form build' }]
        });

        oLayout.cells('a').attachHTMLString(cFormBuilderHTML);

        // var app = dhtmlxApp.start("formbuilder", $('#form-build-test')[0]);
        oSelf.dhx = dhtmlxApp.start('formbuilder', oLayout.cells('a'));
        oSelf.updatedAttributes = [];
        oSelf.updatedMasterAttributes = [];
        window.lastOpenedFormBuilder = oSelf.dhx;

        // lets add more configs
        oSelf.dhx.attachEvent('onItemSelect', (type, guid) => {
          this.onItemSelect(type, guid, oSelf);
        });

        // on form field selected lazy load attributes
        oSelf.dhx.config.onFormFieldSelect = function(element) {
          return oSelf._onFormFieldSelect(oSelf, element);
        };

        window.myTreeMenu.attachEvent('onClick', function(e) {
          if (e == 'block_delete') {
            const cBlockName = window.myMockup.items[this.conf.ctx_id].formData.name;
            oSelf.deleteBlock(this, cBlockName, e);
          } else if (e === 'all_open_master') {
            const guid = window.myMockup.items[this.conf.ctx_id].formData.guid;
            const instanceStore = oSelf.dataSource.getStore('eSmartObjectInstance');
            const idInsStore = oSelf.dataSource.getIdFrom('objectinstanceguid', guid);
            const instanceRecord = instanceStore.item(idInsStore);

            // launch in new external screen
            if (!isNull(instanceRecord)) {
              akioma.launchExternalScreen({
                autostartObjects: 'layoutdesignerw',
                custom: {
                  ObjectName: instanceRecord.objectmastername,
                  ObjectGuid: instanceRecord.objectmasterguid,
                  ObjectType: instanceRecord.instancetypename
                }
              });
            }
          }
        });

        // on attribute update
        oSelf.dhx.config.onConfigUpdate = function(objkey, label, value) {
          const guid = objkey;

          if (objkey && objkey === akioma.globalFormbuilderNextOpen.objectmasterguid) {
            // update for object masters
            const cMasterGuid = objkey;
            const oMasterStore = oSelf.businessEntity.getStore('eSmartObjectMaster');
            oSelf.businessEntity.switchJSDOWorkingRecord('eSmartObjectMaster');

            if (label == 'name') {
              let storeID;
              let item = oMasterStore.item(cMasterGuid);
              if (!item) {
                storeID = oSelf.businessEntity.getIdFrom('objectmasterguid', cMasterGuid);
                item = oMasterStore.item(storeID);
              }
              item.objectname = value;
              oMasterStore.update(storeID, item);
            } else {
              // here we are going to update the props and add to updatedAttributes array for later saving
              const oAttr = _.findWhere(oSelf.props[guid], { label: label });

              oAttr.value = value;
              if (oAttr.original.RepositoryType.toUpperCase() == 'CHARACTER')

                oAttr.original.CharacterValue = value;
              else if (oAttr.original.RepositoryType.toUpperCase() == 'LOGICAL')
                oAttr.original.LogicalValue = value;
              else if (oAttr.original.RepositoryType.toUpperCase() == 'DECIMAL') {
                if (value != null)
                  oAttr.original.DecimalValue = parseFloat(value);
                else
                  oAttr.original.DecimalValue = 0;
              } else if (oAttr.original.RepositoryType.toUpperCase() == 'INTEGER') {
                if (value != null)
                  oAttr.original.IntegerValue = parseInt(value);
                else
                  oAttr.original.IntegerValue = 0;
              }
              if (oSelf.updatedMasterAttributes[guid] == undefined)
                oSelf.updatedMasterAttributes[guid] = [];
              oAttr.original.IsInherited = false;

              oSelf.updatedMasterAttributes[guid][label] = oAttr.original;
            }
          } else if (label == 'name') {
            // this should only happen on update of Instances
            // this means we need to update the objectinstance name not the attributes

            const cInsGuid = guid;
            const oInstanceStore = oSelf.businessEntity.getStore('eSmartObjectInstance');
            oSelf.businessEntity.switchJSDOWorkingRecord('eSmartObjectInstance');

            let item = oInstanceStore.item(cInsGuid);
            if (!item) {
              const storeID = oSelf.businessEntity.getIdFrom('objectinstanceguid', cInsGuid);
              item = oInstanceStore.item(storeID);
            }

            item.instancename = value;
            oInstanceStore.update(cInsGuid, item);

            const oMockupElm = window.myMockup.items[window.myMockup.lastSelected];
            const $mockElm = $(oMockupElm.node);
            // in mockup update input text
            if ($mockElm.find('> .input_item_text').length > 0)
              $mockElm.find('> .input_item_text').text(`[i] ${value}`);


            // in treeview update mockup items text
            const cIdSelected = window.myTreeView.getSelectedId();
            window.myTreeView.setItemText(window.myTreeView.getSelectedId(), `[${cIdSelected}] [${oMockupElm.type}] ${value}`);

          } else { // this happens for all other attributes
            // here we are going to update the props and add to updatedAttributes array for later saving
            const oAttr = _.findWhere(oSelf.props[guid], { label: label });
            oAttr.value = value;
            if (oAttr.original.RepositoryType.toUpperCase() == 'CHARACTER')
              oAttr.original.CharacterValue = value;
            else if (oAttr.original.RepositoryType.toUpperCase() == 'LOGICAL')
              oAttr.original.LogicalValue = value;
            else if (oAttr.original.RepositoryType.toUpperCase() == 'DECIMAL') {
              if (value != null)
                oAttr.original.DecimalValue = parseFloat(value);
              else
                oAttr.original.DecimalValue = 0;
            } else if (oAttr.original.RepositoryType.toUpperCase() == 'INTEGER') {
              if (oAttr.original.IntegerValue != null)
                oAttr.original.IntegerValue = parseInt(value);
              else
                oAttr.original.IntegerValue = 0;
            }
            if (oSelf.updatedAttributes[guid] == undefined)
              oSelf.updatedAttributes[guid] = [];
            oAttr.original.IsInherited = false;

            oSelf.updatedAttributes[guid][label] = oAttr.original;
          }
        };

        window.formBuilder = oSelf.dhx;
        window.akFormBuilderActive = oSelf;

        oSelf.props = [];

        oSelf.aTempContainerFields = [];
        oSelf.aContainerGuids = [];

        $(oSelf.dhx.container.cell).addClass('akformbuilder');

      }

      this.dhx.container.dataObj.attachEvent('onPanelResizeFinish', ids => {
        try {
          this.customSaveLayoutSettings(this, ids);
        } catch (e) {
          console.error(e);
        }

      });
    }
  });

  // methods for form
  $.ak_formbuilder.prototype = {
    /**
     * Method for selecting BE store instance from eSmartObjectInstance
     * @instance
     * @memberof ak_formbuilder
     * @param {string} guid
     */
    selectInstanceByGuid(guid) {
      const id = this.dataSource.getIdFrom('objectinstanceguid', guid);
      const insStore = this.dataSource.getStore('eSmartObjectInstance');
      insStore.setCursor(id);
    },
    /**
     * Method for selecting BE store instance from eSmartObjectInstance
     * @instance
     * @memberof ak_formbuilder
     * @param {string} guid
     */
    selectMasterByGuid(guid) {
      const id = this.dataSource.getIdFrom('objectmasterguid', guid);
      const masterStore = this.dataSource.getStore('eSmartObjectMaster');
      masterStore.setCursor(id);
    },
    /**
     * Method to prompt for block delete and deletion.
     * @instance
     * @private
     * @memberof ak_formbuilder
     * @param {HTMLElement} treeMenu
     * @param {string} blockName name of the block to remove
     * @param {event} e
     */
    deleteBlock(treeMenu, blockName, e) {
      akioma.message({
        type: 'confirm',
        title: 'Delete Container',
        text: `This will remove "${blockName}" and all children form fields. Are you sure you want to continue?`,
        buttonText: 'Yes',
        callback: result => {
          if (result)
            this.dhx.callEvent('onItemDelete', [ 'ctx', treeMenu.conf.ctx_id, e.replace(/_delete$/, ''), null ]);

        }
      });
    },
    /**
     * Method called when an item from mockup is selected
     * @private
     * @instance
     * @memberof ak_formbuilder
     * @param {string} type
     * @param {string} guid
     * @param {ak_formbuilder} oSelf
     */
    onItemSelect(type, guid, oSelf) {
      const oMockupElm = window.myMockup.items[guid];
      if (this.lastActiveGuid !== oMockupElm.formData.guid) {
        let guid = oMockupElm.formData.guid;
        if (oMockupElm.formData.type === 'root')
          guid = oSelf.cLoadedObjMasterGuid;


        this._onFormFieldSelect(this, guid).then(res => {

          if (res) {
            const fakeResponse = { dsDesignAttributeValue: { dsDesignAttributeValue: { eDesignAttributeValue: res } } };
            const propertyGrid = this.dynObject.container.getFirstChildByType('propertygrid');
            propertyGrid.parseAttributes(fakeResponse);
          }
        });
        this.lastActiveGuid = oMockupElm.formData.guid;
      }
    },

    /**
     * Method used for loading the form fields
     * @private
     * @instance
     * @memberOf ak_formbuilder
     * @param  {string} cObjMasterName The name of the Object Master form to load
     * @param  {string} cObjMasterGuid The Object Master Guid
     */
    loadFormFields: function(cObjMasterName, cObjMasterGuid) {
      const oDhxDataSourceObjInstances = this.store.getStore('eSmartObjectInstance').data.pull;

      const aInstances = [];
      const oSelf = this;

      oSelf.aListNewInstances = []; // reset
      oSelf.aListNewTempGuids = [];

      oSelf.aListNewInstancesContainers = [];
      oSelf.aListNewTempGuidsContainers = [];

      oSelf.cLoadedObjMasterGuid = cObjMasterGuid;
      const instsStore = oDhxDataSourceObjInstances;

      const instsStoreFields = _.chain(instsStore).filter(item => (item.instancetypename != 'SwatFieldset' && item.instancetypename != 'SwatBlock')).sortBy('objectsequence').value();

      // get all blocks
      const instsStoreContainers = _.filter(instsStore, item => (item.instancetypename == 'SwatFieldset' || item.instancetypename == 'SwatBlock'));

      const firstLvlContainers = _.chain(instsStoreContainers).filter(item => (item.parentinstanceguid == '' || item.parentinstanceguid == null)).sortBy(elm => elm.objectsequence).value();

      let otherLvlContainers = _.filter(instsStoreContainers, item => (item.parentinstanceguid != '' && item.parentinstanceguid != null));


      let oConfig = [];
      const aParentInstances = [];

      function searchTree(oConf, guidToFind) {
        if (oConf.guid == guidToFind)
          return oConf;
        else if (oConf.list != null) {
          let result = null;
          for (let i = 0; result == null && i < oConf.list.length; i++)
            result = searchTree(oConf.list[i], guidToFind);

          return result;
        } else if (oConf.length) {
          let result = null;
          for (let i = 0; result == null && i < oConf.length; i++)
            result = searchTree(oConf[i], guidToFind);

          return result;
        }
        return null;
      }

      // add first lvl containers
      for (const el in firstLvlContainers) {
        const oElm = firstLvlContainers[el];
        if (aInstances[oElm.containerobjectmasterguid] == undefined)
          aInstances[oElm.containerobjectmasterguid] = [];

        // aInstancesGuids.push(oElm.objectinstanceguid);
        const newinstanceobj = {
          parentid: oElm.containerobjectmasterguid,
          parentname: oElm.instancename,
          id: oElm.objectinstanceguid,
          objguid: oElm.objectinstanceguid,
          objname: oElm.instancename,
          objtype: oElm.objectmastername.toLowerCase(),
          order: oElm.objectsequence,
          parentinsguid: oElm.parentinstanceguid
        };


        const oFormField = oSelf.getNewFormField(newinstanceobj);
        oFormField.label = oElm.instancename;

        // add form field to config
        oFormField.order = newinstanceobj.order;
        oConfig.push(oFormField);

      }

      // add the rest of the lvl containers
      let iNoOfInstancesAdded = 0;
      otherLvlContainers = _.chain(otherLvlContainers).sortBy(item => `${item.objectmasterguid}_${item.objectsequence}`).value();
      const LoadTreeStuctFormContainers = function() {
        for (const el in otherLvlContainers) {
          const oElm = otherLvlContainers[el];
          if (aInstances[oElm.containerobjectmasterguid] == undefined)
            aInstances[oElm.containerobjectmasterguid] = [];

          const newinstanceobj = {
            parentid: oElm.containerobjectmasterguid,
            parentname: oElm.instancename,
            id: oElm.objectinstanceguid,
            objguid: oElm.objectinstanceguid,
            objname: oElm.instancename,
            objtype: oElm.objectmastername.toLowerCase(),
            order: oElm.objectsequence,
            parentinsguid: oElm.parentinstanceguid
          };

          const oFormField = oSelf.getNewFormField(newinstanceobj);
          oFormField.label = oElm.instancename;

          const oElmFound = searchTree(oConfig, oElm.parentinstanceguid);
          const oElmExists = searchTree(oConfig, oElm.objectinstanceguid);
          if (oElmExists == undefined && oElmFound) {

            oFormField.order = newinstanceobj.order;
            oElmFound.list.push(oFormField);

            iNoOfInstancesAdded++;
          }
        }

        if (iNoOfInstancesAdded < otherLvlContainers.length)
          LoadTreeStuctFormContainers();
      };

      try {
        LoadTreeStuctFormContainers();
      } catch (e) {
        akioma.log.error('Error parsing tree struct form containers', oConfig, aParentInstances);
      }

      // add object instaces
      for (const key in instsStoreFields) {
        const oElm = instsStoreFields[key];
        if (aInstances[oElm.containerobjectmasterguid] == undefined)
          aInstances[oElm.containerobjectmasterguid] = [];


        const newinstanceobj = {
          parentid: oElm.containerobjectmasterguid,
          parentname: oElm.instancename,
          id: oElm.objectinstanceguid,
          objguid: oElm.objectinstanceguid,
          objname: oElm.instancename,
          objtype: oElm.instancetypename,
          order: oElm.objectsequence,
          parentinsguid: oElm.parentinstanceguid
        };

        const oFormField = oSelf.getNewFormField(newinstanceobj);
        oFormField.label = oElm.instancename;
        const oElmFound = searchTree(oConfig, oElm.parentinstanceguid);
        // add form field to config
        if (oElmFound) {
          oFormField.order = newinstanceobj.order;
          if (oElmFound.list)
            oElmFound.list.push(oFormField);
          else if (oElmFound.length >= 0)
            oConfig.push(oFormField);
          else
            console.warn('Could not add ', oFormField);
        } else {
          oFormField.order = newinstanceobj.order;
          oConfig.push(oFormField);
        }
      }

      const sortConfig = function(oConfig) {
        for (const i in oConfig) {
          if (oConfig[i].list)
            oConfig[i].list = sortConfig(oConfig[i].list);
        }

        return _.sortBy(oConfig, item => item.order);

      };
      oConfig = sortConfig(oConfig);

      let data = dhx4.temp = null;
      try {
        eval(`dhx4.temp=${JSON.stringify(oConfig)}`), data = dhx4.temp;
      } catch (e) {
        akioma.log.warn(e);
      }
      delete dhx4.temp, null != data && (oSelf.dhx.callEvent('onDataImport', [data]));


      // when an element is removed
      window.myMockup.config = {};
      window.myMockup.config.onElementDelete = function(item) {
        try {
          // reenable add button if no columns in parent container
          const iLengthOfColChildren = _.filter(window.myMockup.items, e => e.parentId == item.parentId && e.type == 'column' && item.id != e.id).length;

          // then remove disabled class to enable click
          if (iLengthOfColChildren == 0) {
            $(item.node).parent().parent().parent()
              .find('.dhxmockup_btn_add')
              .removeClass('disabled');
          }

          const oInstanceStore = oSelf.businessEntity.getStore('eSmartObjectInstance');
          oSelf.businessEntity.switchJSDOWorkingRecord('eSmartObjectInstance');
          if (item.formData.guid) {
            const tDataSourceID = oSelf.businessEntity.getIdFrom('objectinstanceguid', item.formData.guid);

            if (tDataSourceID)
              oInstanceStore.remove(tDataSourceID);
            else {
              const itm = _.findWhere(oSelf.aTempContainerFields, { ObjectInstanceGuid: item.formData.guid });
              oSelf.aTempContainerFields.splice(oSelf.aTempContainerFields.indexOf(itm), 1);
            }
          }

        } catch (e) {
          console.warn('Could not remove element', e);
        }

      };

      const _getParentWithGuid = function(items, id) {
        if (items[id].formData != undefined && items[id].formData.type == 'root')
          return { formData: { guid: '' } };
        if ((items[id].formData == undefined || items[id].formData.guid == undefined) ||
                    items[id].formData.type == 'column') {
          if (items[id].parentId)
            return _getParentWithGuid(items, items[id].parentId);
          else
            return { formData: { guid: '' } };
        } else
          return items[id];
      };

      window.myMockup.config.onElementMove = function(target, destination, index, elements, mouseevent, dnd) {
        try { // update parentintanceguid for the target element
          if (destination == null)
            destination = window.myMockup._baseID;
          const oParent = _getParentWithGuid(elements, destination);

          oSelf.businessEntity.switchJSDOWorkingRecord('eSmartObjectInstance');
          const oInstanceStore = oSelf.businessEntity.getStore('eSmartObjectInstance');
          if (oParent.formData.guid || oParent.formData.guid === '') {
            let tDataSourceID = oSelf.businessEntity.getIdFrom('objectinstanceguid', elements[target].formData.guid);
            let currItem;
            if (tDataSourceID == undefined) {
              tDataSourceID = elements[target].formData.guid;
              currItem = oInstanceStore.item(elements[target].formData.guid);
            } else
              currItem = oInstanceStore.item(tDataSourceID);


            if (currItem) {
              currItem.parentinstanceguid = oParent.formData.guid;
              elements[target].formData.parentinsguid = currItem.parentinstanceguid;
              oInstanceStore.update(tDataSourceID, currItem);
            }
          }

          // for column type drop change all column children parent instances
          if (elements[target].type == 'column') {
            const aElms = $(elements[target].node).find(' > :not(.dhxmockup_btn_del,.dhxmockup_btn_add)');
            elements[target].formData.parentinsguid = oParent.formData.guid;
            for (const x in aElms) {
              const idd = aElms[x]._idd;
              if (idd) {
                const guid = elements[idd].formData.guid;
                const tRDataSourceID = oSelf.businessEntity.getIdFrom('objectinstanceguid', guid);
                // if in instance store
                if (tRDataSourceID) {
                  const oitm = oInstanceStore.item(tRDataSourceID);
                  oitm.parentinstanceguid = oParent.formData.guid;
                  oInstanceStore.update(tRDataSourceID, oitm);
                } else { // else in special array
                  const aTempContainerCol = _.filter(oSelf.aTempContainerFields, { ObjectInstanceGuid: elements[idd].formData.guid })[0];
                  const iContainerColInd = oSelf.aTempContainerFields.indexOf(aTempContainerCol);
                  aTempContainerCol.ParentInstanceGuid = oParent.formData.guid;

                  oSelf.aTempContainerFields[iContainerColInd] = aTempContainerCol;
                }
                elements[idd].formData.parentinsguid = oParent.formData.guid;
              }
            }
          }

          const oCurrentDropLvlElms = _.filter(elements, item => item.formData && item.formData.parentinsguid == oParent.formData.guid);
          oCurrentDropLvlElms.splice(oCurrentDropLvlElms.indexOf(elements[target]), 1);
          oCurrentDropLvlElms.splice(index, 0, elements[target]);
          for (const i in oCurrentDropLvlElms) {
            const cInsGuid = oCurrentDropLvlElms[i].formData.guid;
            let tDataSourceID = oSelf.businessEntity.getIdFrom('objectinstanceguid', cInsGuid);
            let currItem = oInstanceStore.item(tDataSourceID);

            // if not in container temp type save to BE
            if (tDataSourceID == undefined) {
              tDataSourceID = oCurrentDropLvlElms[i].formData.guid;
              currItem = oInstanceStore.item(oCurrentDropLvlElms[i].formData.guid);
            } else
              currItem = oInstanceStore.item(tDataSourceID);


            const aTempContainer = _.filter(oSelf.aTempContainerFields, { ObjectInstanceGuid: oCurrentDropLvlElms[i].formData.guid });
            // if not in container temp type save to BE
            if (aTempContainer.length == 0) {
              const iChildIndex = dnd.getChildIndexDOM2(elements, mouseevent, destination, [oCurrentDropLvlElms[i].node], target);
              currItem.ObjectSequence = (iChildIndex + 1);
              currItem.objectsequence = (iChildIndex + 1);
              oInstanceStore.update(tDataSourceID, currItem);
            } else {
              const iContainerInd = oSelf.aTempContainerFields.indexOf(aTempContainer[0]);
              const oContainerElem = aTempContainer[0];
              const iChildIndex = dnd.getChildIndexDOM2(elements, mouseevent, destination, [oCurrentDropLvlElms[i].node], target);
              oContainerElem.ParentInstanceGuid = oParent.formData.guid;
              oContainerElem.ObjectSequence = iChildIndex + 1;
              oSelf.aTempContainerFields[iContainerInd] = oContainerElem;
            }
          }
        } catch (e) {
          console.warn('onElementMove ', e);
        }
      };

      oSelf.aTempContainerFields = [];
      oSelf.aContainerGuids = [];

      // define object businessEntity sstore here
      const cResourceName = 'Consultingwerk.SmartFramework.Repository.Object.ObjectMasterBusinessEntity';
      const oBEoptions =
            {
              'att': {
                cacheLimit: 50,
                multiStore: true,
                catalogURI: '',
                dataSource: '',
                entityName: 'eSmartObjectMaster',
                id: 'offerw45613645_businessEntity',
                identifier: 'selfhdl',
                name: 'businessEntity',
                rowsToBatch: 1000,
                tableRefFilter: 'eSmartObjectMaster',
                resourceName: cResourceName,
                serviceURI: ''
              }
            };
      const businessEntity = new $['ak_businessEntity2'](oBEoptions);

      businessEntity.query.mainOperator = 'or';
      businessEntity.query.addCondition('ObjectName', 'eq', 'SimpleSwatFieldset');
      businessEntity.query.addCondition('ObjectName', 'eq', 'SimpleSwatBlock');
      businessEntity.query.addCondition('ObjectName', 'eq', 'SimpleSwatNewColumn');
      const newObjects = [];
      businessEntity.aCallback['afterFill'] = function(rows) {
        // save FormBlock and Fieldset guids for later creating
        const elements = rows[0];
        for (const i in elements) {
          const element = elements[i];
          if (element.objecttypename == 'SwatBlock')
            newObjects['SwatBlock'] = element.objectmasterguid;
          else if (element.objecttypename == 'SwatFieldset')
            newObjects['SwatFieldset'] = element.objectmasterguid;
          else if (element.objecttypename == 'SwatNewColumn')
            newObjects['SwatNewColumn'] = element.objectmasterguid;
        }

      };
      businessEntity.finishConstruct();
      businessEntity.endConstruct();

      // on button click on context menu create new fieldsets,blocks, columns
      window.myMockup.config.contextMenuClick = function(container, type) {

        const deferred = $.Deferred();
        // create in temporary array that will be saved using businessTask
        let tempObjInsGuid = dhtmlx.uid();
        let cRepoType = '';
        let uniqueinstancename = '';
        let cObjectMasterGuid = '';

        if (type == 'new_block') {
          uniqueinstancename = `Block${tempObjInsGuid}`;
          cRepoType = 'SwatBlock';
          cObjectMasterGuid = newObjects['SwatBlock'];
        } else if (type == 'new_fieldset') {
          uniqueinstancename = `Fieldset${tempObjInsGuid}`;
          cRepoType = 'SwatFieldset';
          cObjectMasterGuid = newObjects['SwatFieldset'];
        } else if (type == 'new_column') {
          uniqueinstancename = `Column${tempObjInsGuid}`;
          cRepoType = 'SwatNewColumn';
          cObjectMasterGuid = newObjects['SwatNewColumn'];
        }

        const cParentInstanceGuid = container.formData.guid;

        // for sequence check number of elements and increment with one
        const aSameLvlInstances = _.filter(oSelf.businessEntity.getStore('eSmartObjectInstance').data.pull, item => (item.parentinstanceguid == cParentInstanceGuid));
        const iSequence = aSameLvlInstances.length + 1;

        const oInstanceStore = oSelf.businessEntity.getStore('eSmartObjectInstance');
        const cNextAddedInsID = uuid();
        oInstanceStore.add({
          objectinsanceguid: tempObjInsGuid,
          containerobjectmasterguid: oSelf.cLoadedObjMasterGuid,
          instancedescription: '',
          instancename: uniqueinstancename,
          instancetypename: cRepoType,
          layoutposition: '',
          objectmasterguid: cObjectMasterGuid,
          objectmastername: `Simple${cRepoType}`,
          objectsequence: iSequence,
          parentinstanceguid: (cParentInstanceGuid || ''),
          parentinstancename: '',
          pageguid: ''
        }, cNextAddedInsID);

        const oNew = oInstanceStore.item(cNextAddedInsID);
        oNew.objectinstanceguid = tempObjInsGuid = cNextAddedInsID;

        oSelf.aListNewInstances.push(uniqueinstancename);
        oSelf.aListNewTempGuids.push(tempObjInsGuid);
        oSelf.aContainerGuids.push(tempObjInsGuid);


        oSelf.getAncestor('window').dhx.progressOn();

        // get object master design attributes
        akioma.RepositoryStructure.fetchMasterDesignAttributes(cObjectMasterGuid).done(oResult => {
          let aProps = oResult.dsDesignAttributeValue.dsDesignAttributeValue.eDesignAttributeValue;
          aProps = _.sortBy(aProps, item => [ item.ObjectInstanceGuid, item.AttributeLabel ].join('_'));

          const objectmastername = `Simple${cRepoType}`;
          aProps.unshift({
            AttributeLabel: 'ObjectMasterName',
            name: 'objectmastername',
            type: 'input',
            CharacterValue: objectmastername,
            RepositoryType: 'Character',
            ObjectMasterGuid: tempObjInsGuid,
            ObjectType: cRepoType,
            openExternal: true,
            readonly: true
          });
          aProps.unshift({
            AttributeLabel: 'name',
            name: 'name',
            type: 'input',
            CharacterValue: uniqueinstancename,
            RepositoryType: 'Character',
            ObjectMasterGuid: tempObjInsGuid
          });

          aProps = akioma.repository.parseObjectProps(oSelf, tempObjInsGuid, aProps);

          aProps.map(prop => {
            prop.ObjectInstanceGuid = tempObjInsGuid;
            return prop;
          });

          oSelf.getAncestor('window').dhx.progressOff();

          deferred.resolve({
            guid: tempObjInsGuid,
            parentinsguid: oNew.parentinstanceguid,
            label: oNew.instancename
          });

        }).fail(() => {
          deferred.reject();
        });

        return deferred.promise();
      };

      if (window.lastOpenedDraggableFormbuilderGrid && window.lastOpenedDraggableFormbuilderGrid.entBox)
        window.myMockup.initCustomDrop(window.lastOpenedDraggableFormbuilderGrid);

      oSelf.parent.dhx.progressOff();

      this._onFormFieldSelect(this, this.dataSource.getIndex());

    },
    /**
     * Method used to apply the new sequnces on the dropped level
     * @private
     * @instance
     * @memberOf ak_formbuilder
     * @param  {array} elements    List of Formbuilder elements
     * @param  {object} oParent     The parent object
     * @param  {object} dnd         Formbuilder dnd object
     * @param  {string} destination The destination form field object id.
     * @param  {string} target      The target form field id.
     */
    applySequenceMove: function(elements, oParent, dnd, destination, target) {
      const oSelf = this;
      oSelf.businessEntity.switchJSDOWorkingRecord('eSmartObjectInstance');

      let tDataSourceID;
      const oInstanceStore = oSelf.businessEntity.getStore('eSmartObjectInstance');
      if (oParent.formData.guid || oParent.formData.guid === '') {
        tDataSourceID = oSelf.businessEntity.getIdFrom('objectinstanceguid', elements[target].formData.guid);
        const currItem = oInstanceStore.item(tDataSourceID);
        if (currItem) {
          currItem.parentinstanceguid = oParent.formData.guid;
          oInstanceStore.update(tDataSourceID, currItem);
        }
      }

      // go over each sibling and set new object sequence
      const oCurrentDropLvlElms = _.filter(elements, item => item.formData && item.formData.parentinsguid == oParent.formData.guid);

      oCurrentDropLvlElms.push(elements[target]);

      for (const i in oCurrentDropLvlElms) {
        const cInsGuid = oCurrentDropLvlElms[i].formData.guid;
        tDataSourceID = oSelf.businessEntity.getIdFrom('objectinstanceguid', cInsGuid);

        // if not in container temp type save to BE
        let currItem;
        if (tDataSourceID == undefined) {
          tDataSourceID = oCurrentDropLvlElms[i].formData.guid;
          currItem = oInstanceStore.item(oCurrentDropLvlElms[i].formData.guid);
        } else
          currItem = oInstanceStore.item(tDataSourceID);


        if (currItem == undefined)
          tDataSourceID = oSelf.businessEntity.getIdFrom('objectinstancename', cInsGuid);


        const iLengthOfTempContainerFields = _.filter(oSelf.aTempContainerFields, item => oCurrentDropLvlElms[i].formData && (item.ObjectInstanceGuid == oCurrentDropLvlElms[i].formData.guid)).length;
        if (iLengthOfTempContainerFields == -1 || currItem != undefined) {
          let iChildIndex = dnd.getChildIndexDOM2(elements, null, destination, [oCurrentDropLvlElms[i].node], target);
          if (iChildIndex < 0)
            iChildIndex = 1;

          if (currItem) {
            currItem.ObjectSequence = (iChildIndex + 1);
            currItem.objectsequence = (iChildIndex + 1);
            oInstanceStore.update(tDataSourceID, currItem);
          }
        } else {
          let iContainerInd = oSelf.aTempContainerFields.indexOf(oCurrentDropLvlElms[i]);
          for (const q in oSelf.aTempContainerFields) {
            if (oCurrentDropLvlElms[i].formData && oSelf.aTempContainerFields[q].ObjectInstanceGuid == oCurrentDropLvlElms[i].formData.guid)
              iContainerInd = q;

          }
          const oContainerElem = oSelf.aTempContainerFields[iContainerInd];
          let iChildIndex = dnd.getChildIndexDOM2(elements, null, destination, [oCurrentDropLvlElms[i].node], target);
          if (iChildIndex < 0)
            iChildIndex = 1;
          oContainerElem.ObjectSequence = iChildIndex + 1;
          oContainerElem.objectsequence = iChildIndex + 1;
          oSelf.aTempContainerFields[iContainerInd] = oContainerElem;
        }

      }
    },
    // to apply sequence fieldname per parent children on NEW ADD
    /**
     * Method used to apply sequence fieldname per parent children on NEW ADD
     * @private
     * @instance
     * @memberOf ak_formbuilder
     * @param  {array} elements    List of Formbuilder elements
     * @param  {object} oParent     The parent object
     * @param  {object} dnd         Formbuilder dnd object
     * @param  {string} orgParent
     */
    applySequence: function(elements, oParent, dnd, orgParent) {
      const oSelf = this;
      const oCurrentDropLvlElms = _.filter(elements, item => item.formData && item.formData.parentinsguid == oParent.formData.guid);

      oSelf.businessEntity.switchJSDOWorkingRecord('eSmartObjectInstance');
      const oInstanceStore = oSelf.businessEntity.getStore('eSmartObjectInstance');
      for (const i in oCurrentDropLvlElms) {
        const cInsGuid = oCurrentDropLvlElms[i].formData.guid;
        let tDataSourceID = oSelf.businessEntity.getIdFrom('objectinstanceguid', cInsGuid);

        // if not in container temp type save to BE
        let currItem;
        if (tDataSourceID == undefined) {
          tDataSourceID = oCurrentDropLvlElms[i].formData.guid;
          currItem = oInstanceStore.item(oCurrentDropLvlElms[i].formData.guid);
        } else
          currItem = oInstanceStore.item(tDataSourceID);


        // if not in container temp type save to BE
        if (tDataSourceID && currItem) {
          const aAllOfType = _.filter(elements, item => item.type == 'block' || item.type == 'column' || item.type == 'newcolumn' || item.type == 'fieldset');
          const target = aAllOfType[aAllOfType.length - 1];

          let iChildIndex = dnd.getChildIndexDOM2(elements, null, orgParent, [oCurrentDropLvlElms[i].node], target.id);
          if (iChildIndex < 0)
            iChildIndex = 1;
          currItem.ObjectSequence = (iChildIndex + 1);
          currItem.objectsequence = (iChildIndex + 1);

          oInstanceStore.update(tDataSourceID, currItem);
        } else {
          const oContainerElem = _.findWhere(oSelf.aTempContainerFields, { ObjectInstanceGuid: oCurrentDropLvlElms[i].formData.guid });
          let iChildIndex = dnd.getChildIndexDOM2(elements, null, orgParent, [oCurrentDropLvlElms[i].node], oCurrentDropLvlElms[i].id);
          if (iChildIndex < 0)
            iChildIndex = 1;
          oContainerElem.ObjectSequence = iChildIndex + 1;
          _.extend(_.findWhere(oSelf.aTempContainerFields, { ObjectInstanceGuid: oCurrentDropLvlElms[i].formData.guid }), oContainerElem);
        }

      }
    },
    // save the temporary fieldsets, blocks and columns - deprecated
    saveTempContainerFields: function() {

      const deferred = $.Deferred();
      const oSelf = this;
      if (oSelf.aTempContainerFields.length > 0) {
        akioma.invokeServerTask({
          name: 'Akioma.Swat.Repository.FormDesignerBT',
          methodName: 'CreateSpecialInstances',
          paramObj: {
            plcParameter: {},
            dsObjectMaster: { eSmartObjectInstance: oSelf.aTempContainerFields }
          }
        }).done(() => {
          // now replace the stores temp ids with new ids
          oSelf.aTempContainerFields = [];
          oSelf.businessEntity.switchJSDOWorkingRecord('eSmartObjectInstance');
          deferred.resolve();
        })
          .fail(([ , , request ]) => {
            akioma.notification({
              type: 'error',
              text: 'Could not save Blocks, Fieldsets or Columns.',
              moretext: JSON.stringify(request.response, null, 4)
            });

            deferred.reject();
          });
      } else
        deferred.resolve();


      return deferred.promise();
    },
    // remove instance of fieldset, block and column
    removeContainerFields: function(aObjectInstanceGuids) {
      akioma.invokeServerTask(
        {
          name: 'Akioma.Swat.Repository.FormDesignerBT',
          methodName: 'RemoveSpecialInstances',
          paramObj: { plcParameter: { Value: aObjectInstanceGuids.join(',') } }
        });
    },
    // on form field select, use for loading attributes
    /**
     * Method executed on the client side when selecting a new form element for loading the attributes of the field.
     * @private
     * @instance
     * @memberOf ak_formbuilder
     * @param  {object} oSelf   The formbuilder object
     * @param  {object} element The form field object
     */
    _onFormFieldSelect: function(oSelf, guid) {
      const deferred = $.Deferred();

      const currentGuid = oSelf.dataSource.getIdFrom('objectinstanceguid', guid);
      if (currentGuid)
        oSelf.dataSource.getStore('eSmartObjectInstance').setCursor(currentGuid);


      if (oSelf.props[guid] != undefined) {
        let existingAttrs = [];
        if (!isNull(oSelf.props[guid])) {
          existingAttrs = oSelf.props[guid].map(el => {
            if (el.original) return el.original;
          });
        }
        deferred.resolve(existingAttrs);
      } else if (guid) {
        oSelf.parent.dhx.progressOn();
        let promiseReq = null;
        const propertyGrid = this.dynObject.container.getFirstChildByType('propertygrid');
        const typeOfRecord = (akioma.globalFormbuilderNextOpen.objectmasterguid == guid ? 'master' : 'instances');
        akioma.repository.setGenericDesignerPropsContext({
          typeOfRecord: typeOfRecord,
          dataSource: this.dataSource,
          propertyGrid
        });

        // get properties
        promiseReq = propertyGrid.getProperties(guid);
        promiseReq.then(props => {
          try {
            if (typeOfRecord === 'master') {
              props.map(prop => {
                prop.ObjectMasterGuid = guid;

                return prop;
              });
            }

            props = akioma.repository.parseObjectProps(oSelf, guid, props);
          } catch (e) {
            akioma.log.error(e);
          }
          deferred.resolve(props);
          oSelf.parent.dhx.progressOff();

        }).catch(() => {
          deferred.reject();
          oSelf.parent.dhx.progressOff();
        });

      } else
        deferred.reject();


      return deferred.promise();
    },
    /**
     * Method that returns visual form field type from repository object instances
     * @private
     * @instance
     * @memberOf ak_formbuilder
     * @param  {object} oObj The form field object
     * @return {object}      The form field visual object that contains the correct object type.
     */
    getNewFormField: function(oObj) {
      let oResult = null;

      const cObjName = oObj.objname.split('.');
      let cName;
      if (cObjName.length > 0)
        cName = cObjName[cObjName.length - 1];
      else
        cName = cObjName[0];

      switch (oObj.objtype.toLowerCase()) {
      // for block type
        case 'swatblock':
        case 'swatformblock':
        case 'simpleswatblock':
          oResult = {
            type: 'block',
            guid: oObj.objguid,
            name: cName,
            parentinsguid: oObj.parentinsguid,
            list: []
          };
          break;
        // for fieldset type
        case 'swatfieldset':
        case 'swatformfieldset':
        case 'simpleswatfieldset':
          oResult = {
            type: 'fieldset',
            guid: oObj.objguid,
            name: cName,
            parentinsguid: oObj.parentinsguid,
            list: []
          };
          break;
        // for new collumn
        case 'swatnewcolumn':
        case 'simpleswatnewcolumn':
          oResult = {
            type: 'newcolumn',
            guid: oObj.objguid,
            name: cName,
            parentinsguid: oObj.parentinsguid
          };
          break;
        case 'swatlookup':
        case 'swattranslatable':
        case 'swatselection':
        case 'swatselect':
          oResult = {
            type: 'select',
            guid: oObj.objguid,
            name: cName,
            parentinsguid: oObj.parentinsguid
          };
          break;
        // for combobox, tokenselect
        case 'dyncombobox':
        case 'swatcombobox':
        case 'swatdynamiccombobox':
        case 'combobox':
        case 'tokenselect':
          oResult = {
            type: 'combo',
            guid: oObj.objguid,
            name: cName,
            parentinsguid: oObj.parentinsguid
          };
          break;
        // for calendar
        case 'calendar':
        case 'swatappointment':
          oResult = {
            type: 'calendar',
            guid: oObj.objguid,
            name: cName,
            parentinsguid: oObj.parentinsguid,
            position: 'label-top'
          };
          break;
        // for radio
        case 'radio':
        case 'dynradioset':
          oResult = {
            type: 'radio',
            guid: oObj.objguid,
            name: cName,
            parentinsguid: oObj.parentinsguid
          };
          break;
        // for checkbox
        case 'checkbox':
        case 'dyntoggle':
        case 'swattoggle':
          oResult = {
            type: 'checkbox',
            guid: oObj.objguid,
            name: cName,
            parentinsguid: oObj.parentinsguid
          };
          break;
        // for password
        case 'password':
          oResult = {
            type: 'password',
            guid: oObj.objguid,
            name: cName,
            parentinsguid: oObj.parentinsguid
          };
          break;
        case 'swateditor':
          oResult = {
            type: 'editor',
            guid: oObj.objguid,
            name: cName,
            parentinsguid: oObj.parentinsguid
          };
          break;
        case 'swatupload':
          oResult = {
            type: 'file',
            guid: oObj.objguid,
            name: cName,
            parentinsguid: oObj.parentinsguid
          };
          break;
        // for btn2state
        case 'btn2state':
        case 'swattwostate':
          oResult = {
            type: 'btn2state',
            guid: oObj.objguid,
            name: cName,
            parentinsguid: oObj.parentinsguid
          };
          break;
          // for inputs
        case 'dynfillin':
        case 'swatdatafield':
        case 'swatinput':
          oResult = {
            type: 'input',
            guid: oObj.objguid,
            name: cName,
            label: oObj.objectinstancename,
            parentinsguid: oObj.parentinsguid
          };
          break;
          // case button
        case 'dynbutton':
        case 'swatbutton':
          oResult = {
            type: 'button',
            guid: oObj.objguid,
            name: cName,
            label: oObj.objectinstancename,
            parentinsguid: oObj.parentinsguid
          };
          break;
        default:
          oResult = {
            type: 'input',
            guid: oObj.objguid,
            name: cName,
            label: oObj.objectinstancename,
            parentinsguid: oObj.parentinsguid
          };
      }

      return oResult;
    },
    /**
     * Method for clearing attibute updates in cache
     * @memberof ak_formbuilder
     * @returns {void}
     */
    clearAttributeUpdates: function() {
      this.updatedMasterAttributes = [];
      this.updatedAttributes = [];
    },
    /**
     * Method for clearing cached props
     * @memberof ak_formbuilder
     * @instance
     * @return  {void}
     */
    clearCachedProps: function() {
      this.props = [];
    },
    /**
     * Method for reloading currently selected Mock field
     * @memberof ak_formbuilder
     * @instance
     * @returns {void}
     */
    reloadMockFieldSelection: function() {
      const mockItem = window.myMockup.items[window.myMockup.lastSelected];
      if (!isNull(mockItem)) {
        const guid = mockItem.formData.guid;
        this.props[guid] = null;
        this._onFormFieldSelect(this, guid).then(res => {
          if (res) {
            const fakeResponse = { dsDesignAttributeValue: { dsDesignAttributeValue: { eDesignAttributeValue: res } } };
            const propertyGrid = this.dynObject.container.getFirstChildByType('propertygrid');
            propertyGrid.parseAttributes(fakeResponse);
          }
        });
      }
    },
    // finish construct **********
    finishConstruct: function() {
      const oSelf = this;
      oSelf.businessEntity = oSelf.dataSource;
      oSelf.store = oSelf.businessEntity;
      // oSelf.businessEntity.jsdo.fillxhr.abort()
      oSelf.businessEntity.stop = true;
      oSelf.businessEntity.addAfterCatalogAdd(this._loadData.bind(this));

      this.dynObject.loadUserProfileSettings();
    },
    _loadData: function() {
      const oSelf = this;
      oSelf.businessEntity.query.clearAll();
      oSelf.businessEntity.query.addCondition('ObjectName', 'eq', akioma.globalFormbuilderNextOpen.objectmastername || 'vLog'/* cObjMasterName*/);
      oSelf.parent.dhx.progressOn();
      oSelf.businessEntity.stop = false;
      oSelf.businessEntity.aCallback['afterFill'] = function(data) {
        // if there is one object master then load
        if (data[0].length >= 1)
          oSelf.loadFormFields(akioma.globalFormbuilderNextOpen.objectmastername || 'vLog', akioma.globalFormbuilderNextOpen.objectmasterguid || 'd293fcce-d204-5abb-3114-f75b08fddac8');
        else {
          oSelf.parent.dhx.progressOff();
          akioma.notification({ type: 'error', text: `Could not open form repository object: "${akioma.globalFormbuilderNextOpen.objectmastername}". No ObjectMaster found with that name.` });
        }
      };
      this.bIsLoaded = true;
    },

    // destroy
    /**
     * Destroys the current ak_formbuilder object
     * @private
     * @instance
     * @memberOf ak_formbuilder
     */
    destroy: function() {
      this.dhx.unload();
      window.lastOpenedFormBuilder = null;
      window.akFormBuilderActive = null;
    },
    // save all in memory temporary attributes, will also remove them from memory
    /**
     * Method used for saving all the temporary attributes, will also remove them from memory.
     * @private
     * @instance
     * @memberOf ak_formbuilder
     */
    saveAttributes() {
      const oSelf = this;
      oSelf.parent.dhx.progressOn();
      const deferred = $.Deferred();
      const deferreds = [];

      // save object instances attributes
      let bSkipSave = false;
      let bHasChanges = false;
      for (const ObjectInstanceGuid in oSelf.updatedAttributes) {
        const oInsItems = [];

        if (oSelf.updatedAttributes[ObjectInstanceGuid] != null) {
          for (const j in oSelf.updatedAttributes[ObjectInstanceGuid]) {
            if (oSelf.updatedAttributes[ObjectInstanceGuid][j].ObjectInstanceGuid == '')
              bSkipSave = true;
            oInsItems.push(oSelf.updatedAttributes[ObjectInstanceGuid][j]);
          }

          if (bSkipSave == false) {
            bHasChanges = true;
            const updateObjectInstanceAttributes = (oSelf, ObjectInstanceGuid, oInsItems, deferreds) => {
              const deferredAttributesUpdate = akioma.invokeServerTask({
                name: 'Akioma.Swat.Repository.AttributeValuesTask',
                methodName: 'UpdateObjectInstanceDesignAttributes',
                paramObj: {
                  plcParameter: { ObjectInstanceGuid: ObjectInstanceGuid },
                  dsDesignAttributeValue: { dsDesignAttributeValue: { eDesignAttributeValue: oInsItems } }

                }
              }).done(() => {
                oSelf.updatedAttributes[ObjectInstanceGuid] = null;
              })
                .fail(() => {
                  akioma.notification({ type: 'error', text: 'Could not update object attribute !' });
                });
              deferreds.push(deferredAttributesUpdate);
            };
            updateObjectInstanceAttributes(oSelf, ObjectInstanceGuid, oInsItems, deferreds);
          }
        }
      }

      const bSkipSave2 = false;
      for (const ObjectMasterGuid in oSelf.updatedMasterAttributes) {
        const oMasterItems = [];

        if (oSelf.updatedMasterAttributes[ObjectMasterGuid] != null) {

          for (const j in oSelf.updatedMasterAttributes[ObjectMasterGuid]) {
            if (oSelf.updatedMasterAttributes[ObjectMasterGuid][j].ObjectMasterGuid == '')
              bSkipSave = true;
            oMasterItems.push(oSelf.updatedMasterAttributes[ObjectMasterGuid][j]);
          }
          if (bSkipSave2 == false) {
            bHasChanges = true;

            const updateObjectMasterAttributes = function(oSelf, ObjectMasterGuid, deferreds) {
              const deferredMasterAttributesUpdate = akioma.invokeServerTask({
                name: 'Akioma.Swat.Repository.AttributeValuesTask',
                methodName: 'UpdateObjectMasterDesignAttributes',
                paramObj: {
                  plcParameter: { ObjectMasterGuid: ObjectMasterGuid },
                  dsDesignAttributeValue: { dsDesignAttributeValue: { eDesignAttributeValue: oMasterItems } }

                }
              }).done(() => {
                oSelf.updatedMasterAttributes[ObjectMasterGuid] = null;
              }).fail(() => {
                akioma.notification({ type: 'error', text: 'Could not update object attribute !' });
              });
              deferreds.push(deferredMasterAttributesUpdate);
            };
            updateObjectMasterAttributes(oSelf, ObjectMasterGuid, deferreds);
          }
        }
      }

      $.when.apply(null, deferreds).done(() => {
        deferred.resolve({ hasChanges: bHasChanges });
        oSelf.parent.dhx.progressOff();
      }).fail(() => {
        deferred.reject();
      });

      return deferred.promise();
    },

    /**
     * Method for saving user profile layout settings for one or multiple given cell ids
     * @param {object} controller akioma controller
     * @param {array} ids multiple cell ids
     * @instance
     * @returns {void}
     */
    customSaveLayoutSettings(controller, ids) {
      // save window settings including panel sizes
      const childSizes = [];
      if (ids) {
        for (const i in ids) {
          const cell = controller.dhx.container.dataObj.cells(ids[i]);
          const cellWidth = cell.getWidth();
          const cellHeight = cell.getHeight();
          if (cellWidth < 100)
            cell.setWidth(100);


          childSizes.push({
            id: ids[i],
            height: cellHeight,
            width: cellWidth
          });

        }

        // save window settings including panel sizes
        if (this.bIsLoaded)
          controller.dynObject.saveUserProfileSettings(childSizes);
      }
    }
  };
})(jQuery, jQuery);
