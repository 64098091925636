// ********************* uploader ********************
$.extend({
  /**
     * SwatUpload Control
     * @class ak_upload
     * @param {Object} options Repository attributes for SwatUpload.
     * @param {boolean} options.ENABLED WidgetAttributes Enabled
     * @param {number} options.COLUMN Column position. This may currently be used when rendering some objects. There is no getColumns function, use getCol to retrieve the realized value from an object.
     * @param {string} options.HELP WidgetAttributes Help
     * @param {number} options.WIDTH-CHARS Width in characters. This may currently be used when rendering some objects. There is no get function, use getWidth to retrieve the realized value from an object.
     * @param {number} options.ROW Row position.
     * @param {string} options.LABEL WidgetAttributes Label
     * @param {string} options.EventClick Client side validation code (JavaScript)
     */

  ak_upload: function(options) {
    const defaults = {};

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;

    this.registerDynObject = true;
    this.useParentDynObjectLink = true;

    if (!this.opt.uploadURL)
      this.opt.uploadURL = '_ServerURL_';

    if (this.opt.uploadURL.search(/_ServerURL_/gi) >= 0)
      this.opt.uploadURL = this.opt.uploadURL.replace(/_ServerURL_/gi, app.sessionData.hostURL);

    if (this.opt.uploadTargetDir) {

      if (this.opt.uploadTargetDir.indexOf(':') >= 0) {
        const rootArr = this.opt.uploadTargetDir.split(':');
        const dirLength = rootArr[1].length;
        const dir = rootArr[1].substring(1, dirLength);

        this.opt.uploadTargetDir = `/${rootArr[0]}/${dir}`;
      }
      this.opt.uploadURL += this.opt.uploadTargetDir;
    }

    let titleScreen = true;
    if (this.opt.uploadShowTitle)
      titleScreen = this.opt.uploadShowTitle;

    let autoStart = false;
    if (this.opt.uploadAutoStart)
      autoStart = this.opt.uploadAutoStart;

    let autoRemove = false;
    if (this.opt.uploadAutoremove)
      autoRemove = this.opt.uploadAutoremove;

    const oParent = this.parent;
    if (oParent) {
      this.opt.label = akioma.tran(`${akioma.getForm(this).opt.name}.${this.opt.name}`, { defaultValue: this.opt.label });

      oParent.prop.fields.push({
        type: 'upload',
        url: this.opt.uploadURL,
        name: this.opt.name,
        inputWidth: parseInt(this.opt.width),
        offsetTop: parseInt(this.opt.top),
        offsetLeft: parseInt(this.opt.left),
        disabled: this.opt.disabled,
        autoStart: autoStart,
        titleScreen: titleScreen,
        autoRemove: autoRemove,
        position: (this.opt.positionMode != undefined) ? this.opt.positionMode : oParent.opt.positionMode,
        titleText: 'drag-n-drop files here or click to select files.'
      });

      // append to elements in form
      if (this.parent.view == 'form')
        this.parent.elements.push(this);
    }
  }
});

// methods for form
$.ak_upload.prototype = {

  finishConstruct: function() {
    // get field
    this.form = akioma.getForm(this).dhx;
    this.dhx = this.form.getUploader(this.opt.name);
  },

  // event uploadServerCallbackObject *******************
  EventUploadComplete: function() {
    // check if we have to call the create event
    if (this.opt.EventUploadComplete)
      app.controller.callAkiomaCode(this, this.opt.EventUploadComplete);
  },
  // event uploadValidFileTypes *******************
  EventUploadFileAdd: function() {
    // check if we have to call the create event
    if (this.opt.EventUploadFileAdd)
      app.controller.callAkiomaCode(this, this.opt.EventUploadFileAdd);
  },

  setURL: function(url) {
    this.dhx.setURL(url);
    this.opt.uploadURL = url;
  }
};
