(function($) {

  // ***************** portalset ******************
  $.extend({
    ak_portalset: function(options) {
      const oSelf = this,
        defaults = {},
        lHideParentHeader = false;

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;

      if (!this.opt.layout)
        this.opt.layout = '1C';

      const oParent = this.parent.dhx;
      if (oParent) {
        // get header visible -> header will be hidden after attach layout
        let lHeader = (oParent.isHeaderVisible) ? oParent.isHeaderVisible() : false;
        const lNew = (this.parent.dynObject && this.parent.dynObject.type === 'frame') ? true : false;

        if (!lNew)
          oParent._isCell = false;

        const oLayout = oParent.attachPortal(this.opt.layout, oDhx.skin);
        if (!lNew)
          oParent._isCell = true;
        if (lNew && lHideParentHeader) {
          oParent.hideHeader();
          lHeader = false;
        }

        // check if we have to reactivate header
        if (lHeader)
          oParent.showHeader();

        oLayout.attachEvent('onExpand', id => {
          for (const i in oSelf.childs) {
            if (oSelf.childs[i].opt.layout == id) {
              dhtmlx.delay(function() {
                this.childs[i].execOpt('expand');
              }, oSelf, [id], 10);
              return;
            }
          }
        });

        $.extend(this, { dhx: oLayout });
      } else
        akioma.log.error(`portalset: Error creating Layout '${this.opt.name}': `);


    }
  });

  $.ak_portalset.prototype = {
    endConstruct: function() {
      this.dhx.setSizes();
    },

    destroy: function() {

      // check if dhtmlx element exists -> destroy all attached elements
      if (this.dhx) {
        try {
          // check if we are attached in a dhx element
          if (this.dhx.detachMenu) this.dhx.detachMenu();
          if (this.dhx.detachToolbar) this.dhx.detachToolbar();

          this.dhx = null;
        } catch (e) {
          !_isIE && console.error([ 'Error destroying', this.view, this.opt.name, e.message ]);
        }
      }
    }
  };
})(jQuery, jQuery);
