akioma.security = {};

akioma.security.assignments = {
  onFormInitialize: oForm => {
    const oFormDynObject = oForm.dynObject;
    const oRealmSelect = oFormDynObject.getObject('SecurityRealmDescription');
    akioma.security.assignments.onSecurityRealmSelect(oRealmSelect.controller, false);
    const oOwnerTypeSelect = oFormDynObject.getObject('OwnerType');
    akioma.security.assignments.onOwnerTypeSelect(oOwnerTypeSelect.controller, false);
  },
  onSecurityRealmSelect: (oRealmSelect, bSetValues = true) => {
    const oForm = oRealmSelect.dynObject.getParentOfType('form');
    const oItemSelect = oForm.getObject('securityitemdescription');

    oItemSelect.controller.businessEntity.getQuery().addUniqueCondition('SecurityRealmGuid', '=', oRealmSelect.getValue());
    if (bSetValues) akioma.swat.setFormFieldValue(oItemSelect, '');
  },
  onOwnerTypeSelect: (oOwnerTypeSelect, bSetValues = true) => {
    const oForm = oOwnerTypeSelect.dynObject.getParentOfType('form').controller;
    if (oOwnerTypeSelect.getValue() === 'G') {
      oForm.hideFormField('username');
      oForm.showFormField('groupname');
      if (bSetValues) {
        akioma.swat.setFormFieldValue(oForm.dynObject.getObject('username'), '');
        oForm.dataSource.setFieldValue({ name: 'userguid', value: '' });
      }
    } else {
      oForm.showFormField('username');
      oForm.hideFormField('groupname');
      if (bSetValues) {
        akioma.swat.setFormFieldValue(oForm.dynObject.getObject('groupname'), '');
        oForm.dataSource.setFieldValue({ name: 'groupguid', value: '' });
      }
    }
  },
  onSecurityItemSelectFetch: oItemSelect => {
    const oGrid = oItemSelect.parent;
    const oGridFilters = oGrid.getFilterArray();
    const oRealmFilterIndex = oGridFilters.fields.findIndex(name => name === 'securityrealmguid');
    oItemSelect.businessEntity.getQuery().clearAll();
    if (oRealmFilterIndex > -1) {
      const oRealmSelection = JSON.parse(oGridFilters.values[oRealmFilterIndex]);
      const oRealmSelectionGuid = oRealmSelection[0]['id'];
      oItemSelect.businessEntity.getQuery().addUniqueCondition('SecurityRealmGuid', '=', oRealmSelectionGuid);
    }
  }
};

/**
 * Calls LockSession method on the SCL ContextStoreBusinessEntity
 * The called method locks a selected user session and redirects the user to the login screen.
 * @param {object} self toolbar dynObject
 */
akioma.security.lockSession = function(self) {

  const oBusinessEntity = self.parent.controller.getDescendant('businessEntity');
  let selectedRecord;
  if (oBusinessEntity)
    selectedRecord = oBusinessEntity.getSelectedRecord();

  if (selectedRecord) {
    akioma.message({
      type: 'confirm',
      title: akioma.tran('messageBox.title.confirm', { defaultValue: 'Confirm' }),
      text: akioma.tran('messageBox.text.confirmLockSession', { defaultValue: 'Are you sure?' }),
      buttonText: akioma.tran('messageBox.button.yes', { defaultValue: 'close Window' }),
      callback: function(result) {
        if (result) {
          akioma.invokeServerTask({
            name: 'Consultingwerk.SmartFramework.Context.ContextStoreBusinessEntity',
            methodName: 'LockSession',
            paramObj: { plcParameter: { Value: selectedRecord.sessionid } }
          }).fail(e => {
            let errorMsg = '';
            if (e[0].responseJSON && e[0].responseJSON.message)
              errorMsg = e[0].responseJSON.message;

            if (e[2])
              errorMsg = e[2];
            akioma.message({ type: 'error', title: 'Lock Session', text: `Error locking session <br />${errorMsg}` });
          });
        }
      }
    });
  }
};
