dhtmlXForm.prototype.items.select.setFocus = function(item) {
  $('select', item).focus();
};
dhtmlXForm.prototype.items.multiselect.setFocus = function(item) {
  $('select', item).focus();
};
dhtmlXForm.prototype.items.checkbox.setFocus = function(item) {
  item.childNodes[item._ll ? 0 : 1].childNodes[0].focus();
  // $( "input:first", item ).focus();
};
dhtmlXForm.prototype.items.radio.setFocus = function(item) {
  $('input:first', item).focus();
};

dhtmlXForm.prototype.items.translat = {
  render: function(item, data) {

    item._type = 'translat';
    item._enabled = true;

    this.doAddLabel(item, data);

    const oSelf = $.getObjectByName({ id: data.userdata.id });

    item.akElm = oSelf;
    item._name - oSelf.opt.name;
    oSelf.dhx = item;

    // -> bind input to form
    $('<div class="dhxform_control">'
      + '<img src="/imgs/akioma/globe-26.png" height="14" width="14" class="akTranImg" />'
      + '<input type="text" class="akTranField dhxform_textarea" />'
      + '</div>')
      .appendTo(item)
      .css({
        left: data.inputLeft + 3,
        top: data.inputTop
      })
      .find('input.akTranField')
      .blur(() => {
        oSelf.eventLeave();
      })
      .focus(() => {
        oSelf.eventFocus();
      })
      .change(() => {
        oSelf.eventChange();
      })
      .end()
      .find('img')
      .hover(
        function() {
          $(this)
            .css({ cursor: 'hand' });
        },
        function() {
          $(this)
            .css({ cursor: 'pointer' });
        }
      )
      .click(() => {
        oSelf.eventClick();
      })
      .end()
      .get(0);

    // set akStyle in translat
    $(oSelf.dhx).find('.dhxform_control').attr('akStyle', oSelf.opt.customStyle);

    return this;
  },
  enable: function(item) {
    $('input:first', item).removeAttr('readonly');
  },
  disable: function(item) {
    $('input:first', item).attr('readonly', 'readonly');
  },

  setValue: function(item, value) {
    item.akElm.setValue(value);
  },
  setLangValue: function(item, value) {
    $(item)
      .find('.dhxform_note')
      .val(value);
  },

  getValue: function(item) {
    const cValue = this.getLangValue(item);

    return cValue;
  },
  setValueInput: function(item, value) {
    if (value && value.length > 0)
      $(item).addClass('active');
    else
      $(item).removeClass('active');

    $('input:first', item).val(value);
    if (item.akElm)
      item.akElm._old_val = value;
  },

  getLangValue: function(item) {
    return $(item).find('input').val();
  },

  setFocus: function(item) {
    $('input:first', item).focus();
  },

  getTranslat: function(item) {
    return $(item).find('input').get(0);
  }
};
(function() {
  for (const a in { doAddLabel: 1, destruct: 1, doUnloadNestedLists: 1, setText: 1, getText: 1, isEnabled: 1, setWidth: 1 })
    dhtmlXForm.prototype.items.translat[a] = dhtmlXForm.prototype.items.input[a];
})();
(function() {
  dhtmlXForm.prototype.items.translat._getItemNode = function(item) {
    return item;
  };
})();
dhtmlXForm.prototype.getTranslat = function(name) {
  return this.doWithItem(name, 'getTranslat');
};
dhtmlXForm.prototype.setValueInput = function(name, value) {
  return this.doWithItem(name, 'setValueInput', value);
};
dhtmlXForm.prototype.setFormData_translat = function(name, value) {
  return this.doWithItem(name, 'setValue', value);
};
dhtmlXForm.prototype.getFormData_translat = function(name) {
  return this.doWithItem(name, 'getValue');
};

(function($) {

  // ********************* translat ********************
  $.extend({
    /**
     * SwatTranslatable Control
     * @class ak_translat
     * @param {Object} options Repository attributes for SwatTranslatable.
     * @param {boolean} options.CanSort Set to FALSE if this field should not be used to sort the data object query.
     * @param {number} options.WIDTH-CHARS Width in characters. This may currently be used when rendering some objects. There is no get function, use getWidth to retrieve the realized value from an object.
     * @param {string} options.align alignment of column. can be left, right or centered
     * @param {string} options.HELP WidgetAttributes Help
     * @param {number} options.ROW Row position.
     * @param {boolean} options.Mandatory WidgetAttributes Mandatory
     * @param {string} options.VisualizationType WidgetAttributes VisualizationType
     * @param {string} options.LABEL WidgetAttributes Label
     * @param {string} options.EventEntryType Language of "entry" trigger
     * @param {number} options.HEIGHT-CHARS Height in characters. This may currently be used when rendering some objects. There is no get function, use getHeight to retrieve the realized value from an object.
     * @param {boolean} options.VISIBLE WidgetAttributes Visible
     * @param {boolean} options.CHECKED
     * @param {string} options.TemplateFile The relative path and filename of the static object used as the template at design time
     * @param {string} options.InitialValue WidgetAttributes InitialValue
     * @param {string} options.EventEntry Event which should run on entry of a field
     * @param {string} options.LIST-ITEMS
     * @param {boolean} options.CanFilter Set to FALSE if this field should not be used to filter the data object query.
     * @param {string} options.ViewAs The 'ViewAs' definition  of the selection.&#10;- combo-box,radio-set,selection-list OR browse &#10;- Uses colon as separator to define SUB-TYPE for combo-box or &#10;horizontal/vertical radio-set,
     * @param {string} options.FolderWindowToLaunch If Dynamics is running, this property specifies a window to launch upon the occurence of toolbar events "View", "Copy", "Modify" or "Add".
     * @param {string} options.EventLeaveType Language, the event is written in.
     * @param {string} options.TableName WidgetAttributes TableName
     * @param {string} options.FORMAT WidgetAttributes Format
     * @param {string} options.LIST-ITEM-PAIRS
     * @param {string} options.EventAkValidate Client side validation code (JavaScript)
     * @param {string} options.DATA-TYPE WidgetAttributes Data-Type
     * @param {string} options.Validation Validation-Rules, e.g. ValidInteger,ValidEMail...
     * @param {string} options.FilterOperator
     * @param {boolean} options.LABELS If false then NO-LABEL is used.  This attribute applies to most field level widgets and frames
     * @param {string} options.FieldName The name of the associated SDO field this SmartDataField maps to. This is usually 'set' from the containing SmartDataViewer.
     * @param {boolean} options.ENABLED WidgetAttributes Enabled
     * @param {boolean} options.CreateField
     * @param {string} options.EventLeave Event when leaving a field
     * @param {string} options.SUBTYPE
     * @param {boolean} options.MULTIPLE
     * @param {string} options.Width A widget's width. The unit of measurement is determined by another&#10;parameter.
     * @param {number} options.COLUMN Column position. This may currently be used when rendering some objects. There is no getColumns function, use getCol to retrieve the realized value from an object.
     * @param {string} options.FieldLabel Label for the Dynamic Lookup/Dynamic Combo field on the viewer.
     * @param {string} options.KeyField Name of the Dynamic Lookup/Dynamic Combo key field to assign value from (Table.Field)
     * @param {string} options.EventClick Client side validation code (JavaScript)
     * @param {string} options.filter
     */

    ak_translat: function(options) {

      akioma.BaseFormDataField.call(this, options);

      const defaults = {};

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;
      this.view = options.view;

      this.registerDynObject = true;
      this.registerVuexWatcher = true;
      this.useParentDynObjectLink = true;

      // get parent
      const oParent = this.parent;

      if (oParent) {

        if (!this.opt.customStyle)
          this.opt.customStyle = this.view;

        this.opt.label = akioma.tran(`${akioma.getForm(this).opt.name}.${this.opt.name}`, { defaultValue: this.opt.label });
        oParent.prop.fields.push({
          type: 'translat',
          inputTop: parseInt(this.opt.top),
          inputLeft: parseInt(this.opt.left),
          inputWidth: parseInt(this.opt.width),
          label: this.opt.label,
          labelTop: parseInt(this.opt.top),
          labelLeft: oParent.labelLeft(this),
          name: this.opt.name,
          // readonly:this.opt.disabled ? true : false,
          disabled: this.opt.disabled ? true : false,
          errorMsg: '',
          hasError: false,
          mandatory: this.opt.required,
          enabled: !this.opt.enabled,
          className: 'w4-formField w4-inputField tranFormField active',
          position: 'label-top',
          userdata: { id: this.opt.id }
        });

        // append to elements in form
        if (this.parent.view == 'form')
          this.parent.elements.push(this);

        $.extend(this, { security: {} });
      }
    }
  });

  // methods for translat
  Object.assign($.ak_translat.prototype, akioma.BaseFormDataField.prototype, {
    componentOptions: function() {
      const oSelf = this;
      return {
        watch: {
          'getters.getFormFieldState': {
            fn: function(newValue, oldValue) {
              oSelf._hasFormFieldChangesWatcher(newValue, oldValue);
            },
            params: [this.opt.id]
          },
          'getters.getFormFieldEnabled': {
            fn: function(bEnabled) {
              oSelf._enabledFormFieldWatcher(bEnabled);
            },
            params: [this.opt.id]
          },
          'getters.getFormFieldMandatory': {
            fn: function(bMandatory) {
              oSelf._mandatoryFormFieldWatcher(bMandatory);
            },
            params: [this.opt.id]
          },
          'getters.getFormFieldError': {
            fn: function(newValue, oldValue) {
              oSelf._errorFormFieldWatcher(newValue, oldValue);
            },
            params: [this.opt.id]
          },
          'getters.getFormFieldErrorMsg': {
            fn: function(cErrorMsg) {
              oSelf._errorFormFieldMsgWatcher(cErrorMsg);
            },
            params: [this.opt.id]
          }
        }
      };
    },
    _enabledFormFieldWatcher: function(bEnabled) {
      const oSelf = this;
      const oForm = akioma.getForm(oSelf);
      const cName = oSelf.opt.name;

      if (oForm.dhx) {
        if (bEnabled) {
          oForm.dhx.enableItem(cName);
          oSelf.security.readOnly = false;
        } else {
          oForm.dhx.disableItem(cName);
          oSelf.security.readOnly = true;
        }
      }


    },
    _mandatoryFormFieldWatcher: function(bMandatory) {
      const oSelf = this;
      const oForm = akioma.getForm(oSelf);
      const cName = oSelf.opt.name;

      if (oForm && oForm.dhx) {
        if (typeof (oSelf.setRequired) == 'function')
          oSelf.setRequired(bMandatory);

        oForm.dhx.setRequired(cName, bMandatory);
      }


    },
    /**
 * Watcher for the form error message
 */
    _errorFormFieldMsgWatcher: function(newValue) {
      const oSelf = this;
      const cName = oSelf.opt.name;
      const oForm = akioma.getForm(oSelf);
      const oFormField = akioma.getDhxFormElement(oForm.dhx, cName);

      // setup error message under input
      if (oFormField) {
        const oFormCtrl = $(oFormField).find('> .dhxform_control');
        if (oFormCtrl.find('.validation-error-smartmessage').length == 0)
          oFormCtrl.append(`<span class="validation-error-smartmessage">${newValue}</span>`);
        else
          oFormCtrl.find('.validation-error-smartmessage').text(newValue);
      }

    },
    // finish construct **********
    finishConstruct: function() {
      // get field
      this.form = akioma.getForm(this).dhx;

      const translatNode = akioma.getDhxFormElement(this.form, this.opt.name);
      this.$domElement = $(translatNode);
      this.setResponsiveSizes();
      this.domTag = this.$domElement.find('.akTranField')[0];
      this._setTabIndex(this.domTag);
    },

    // get value *****************
    getValue: function() {
      return this.form.getItemValue(this.opt.name);
    },

    // set value ********************
    setValue: function() {
      if (!this.form)
        return;
      this.form.doWithItem(this.opt.name, 'setValueInput', akioma.getForm(this).dataSource.getFieldValue(this.opt.name));
      this.changeDataLang(app.sessionData.dataLanguage);
    },

    // event change *******************
    eventChange: function() {
      const oForm = akioma.getForm(this);
      // changed value -> set dataprocessor to changed
      oForm.dataSource.setChanged(true);
      const cVal = $(oForm.dhx.getTranslat(this.opt.name)).val();
      this._old_val = this._val;
      this._val = cVal;
    },

    // event leave *******************
    eventLeave: function() {
      // check if we have to call the leave event
      if (this.opt.leaveEvent)
        app.controller.callAkiomaCode(this, this.opt.leaveEvent);
      this.eventBlur();
    },
    eventFocus: function() {
      const formDiv = akioma.getForm(this).dhx.cont.children[0];
      $(formDiv).children().removeClass('focusedinp');
      const $itemcont = $(this.form.getTranslat(this.opt.name)).parent().parent();
      $itemcont.addClass('active').addClass('focusedinp');
    },
    eventBlur: function() {
      const $itemcont = $(this.form.getTranslat(this.opt.name)).parent().parent();
      const cVal = $(this.form.getTranslat(this.opt.name)).val();
      if (cVal.length == 0)
        $itemcont.removeClass('active');

      $itemcont.removeClass('focusedinp');

      // vuex dirty change
      if (this._old_val !== cVal) {
        const oForm = this.form.akElm;
        oForm.setFieldHasChanges(this.opt.name, true);
      }

    },
    changeDataLang: function(cLang) {
      const oSelf = this,
        cName = (this.opt.keyField) ? this.opt.keyField : this.opt.name,
        cSelfHdl = akioma.getForm(this).dataSource.getIndex();

      // !_isIE && console.log(["changeDataLang:", cLang, this]);

      if (cSelfHdl && this.lastLoadedLang !== cSelfHdl) {
        this.lastLoadedLang = cSelfHdl;
        akioma.invokeServerTask ({
          name: 'Akioma.Swat.Translation.TranslationBT',
          methodName: 'GetDataTranslation',
          paramObj: {
            plcParameter: {
              SelfHdl: cSelfHdl,
              FieldName: cName,
              LanguageHdl: cLang,
              Options: ''
            }
          }
        }).done(oRes => {
          const oResult = oRes.plcParameter;
          if (oSelf.form) {
            if (oResult.Translation)
              oSelf.form.setNote(oSelf.opt.name, { text: oResult.Translation });
            else
              oSelf.form.setNote(oSelf.opt.name, { text: '' });

          }
        });
      }

    },

    saveTranslat: function() {

      // get form
      const oForm = this.form,
        cHdl = akioma.getForm(this).dataSource.getIndex(),
        cName = (this.opt.keyField) ? this.opt.keyField : this.opt.name,
        cValue = oForm.doWithItem(this.opt.name, 'getLangValue');

      const oReturn = app.controller.callServerMethod('std/tran/setdatatranslation.p',
        [
          { type: 'iCHAR', value: cHdl },
          { type: 'iCHAR', value: cName },
          { type: 'iCHAR', value: app.sessionData.dataLanguage },
          { type: 'iCHAR', value: cValue },
          { type: 'iCHAR', value: '' },
          { type: 'oCHAR', name: 'result' }
        ]);
      if (oReturn.result)
        akioma.log.error(`Error saving translation: ${oReturn.result}`);

    },

    // event click *******************
    eventClick: function() {
      if (this.security.readOnly)
        return;
      const oPara = {};

      const oForm = akioma.getForm(this),
        cIndex = oForm.dataSource.getIndex(),
        cName = (this.opt.keyField) ? this.opt.keyField : this.opt.name;

      oPara.refHdl = cIndex;
      oPara.fieldName = cName;

      app.controller.launchContainer({
        proc: 'translationsW.r',
        // para:oPara,
        self: this,
        caller: this
      });
    }

  });

})(jQuery, jQuery);
