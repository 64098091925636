// ****************** popup **********************************
$.extend({
  ak_popup: function(options) {
    const oSelf = this,
      defaults = {
        type: 'popup',
        title: '',
        width: 850,
        height: 600,
        htmlclass: '',
        close: false
      };

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;

    this.registerDynObject = true;

    /* quick fix because drag&drop-dialog too small with new UI and SaveProfile does not work:*/
    this.opt.width = this.opt.width + 20;
    this.opt.height = this.opt.height + 20;

    const oParent = this.parent.dhx;
    if (oParent) {
      const oWindow = oParent.createWindow({
        id: this.opt.id,
        width: this.opt.width,
        height: this.opt.height,
        move: true,
        park: false,
        resize: true,
        center: true,
        modal: true
      });

      oWindow.button('close').enable();
      oWindow.button('close').attachEvent('onClick', () => {
        oSelf.close();
      });

      oWindow.addUserButton('conf', 1, 'Configure');
      const contextMenu = oWindow.button('conf').attachContextMenu({
        icons_path: '/imgs/',
        items: [
          { id: 'saveProfile', text: 'Save Window Profile' },
          { id: 'removeFromServerCache', text: 'Refresh Screen' }
        ]
      });
      contextMenu.attachEvent('onClick', id => {
        switch (id) {
          case 'posClip':
            oSelf.posWindow('clip');
            break;
          case 'lastSize':
            oSelf.posWindow('last');
            break;
          case 'posUp':
            oSelf.posWindow('up');
            break;
          case 'posDown':
            oSelf.posWindow('down');
            break;
          case 'posLeft':
            oSelf.posWindow('left');
            break;
          case 'posRight':
            oSelf.posWindow('right');
            break;
          case 'saveProfile':
            oSelf.saveProfile();
            break;
          case 'removeFromServerCache':
            oSelf.removeFromServerCache();
            break;
          case 'enableFields':
            oSelf.callInChildren('setProperty', { readOnly: false });
            break;
          case 'disableFields':
            oSelf.callInChildren('setProperty', { readOnly: true });
            break;
          case 'showFields':
            oSelf.callInChildren('setProperty', { security: { hidden: false } });
            break;
          case 'hideFields':
            oSelf.callInChildren('setProperty', { security: { hidden: true } });
            break;

        }
      });

      oWindow.setText(`- ${this.opt.title ? this.opt.title : ''} -`);

      // setting id for setting title lateron
      $(oWindow.wins.vp)
        .find('.dhxwin_text_inside')
        .attr('id', `${this.opt.id}_Title`);

      if (this.opt.htmlclass) {
        $(oWindow)
          .find('div:first')
          .addClass(this.opt.htmlclass);
      }


      // setup mousetrap object for this window for keyboard shortcuts
      const cell = oWindow.cell;
      $(cell).attr('tabindex', 1);
      this.oMouseTrap = new Mousetrap(cell);


      // extend object with internal settings
      $.extend(this, {
        dhx: oWindow,
        dom: null,
        menu: null
      });
    } else
      akioma.log.error(`No valid parent for popup ${this.opt.name}`);


  }
});

$.ak_popup.prototype = {
  // finish construct *****************
  finishConstruct: function() {

    // check for size of panelset
    const oPanel = this.getDescendant('panelset');
    if (oPanel) {

      // now set dimension
      if (oPanel.opt.width && oPanel.opt.height) {
        const $windowsParentViewport = $(this.parent.dhx.vp);

        let iHeight;
        if (oPanel.opt.height > $windowsParentViewport.height())
          iHeight = $windowsParentViewport.height();
        else
          iHeight = oPanel.opt.height;

        const iWidth = oPanel.opt.width;

        this.dhx.setDimension(iWidth, iHeight);
      }


      if (oPanel.opt.top && oPanel.opt.left)
        this.dhx.setPosition(oPanel.opt.left, oPanel.opt.top);

      if (oPanel.opt.maximized)
        this.dhx.maximize();
    }

    this.setTitle('Dialog');
  },

  endConstruct: function() {
    // init event
    if (this.opt.onInit) {
      dhtmlx.delay(function() {
        applyAkiomaCode(this, this.opt.onInit);
      }, this);
    }
  },

  close: function() {

    // check for event
    if (this.opt.closeEvent)
      app.controller.callAkiomaCode(this, this.opt.closeEvent);

    // destroy element
    this.destruct();
    return true;
  },

  // data available ***************
  dataAvailable: function(oElm) {
    this.openQuery(oElm);
  },

  // open query
  openQuery: function(oElm) {
    // try to get primarysdo
    const cObjectID = this.opt.name + this.opt.linkid;
    const aData = app.messenger.getObjects(this.dynObject, `PRIMARYSDO:TRG:${cObjectID}`);
    if (aData) {
      for (const i in aData)
        aData[i].openQuery(oElm);
    }
  },

  // set option ************
  setOption: function(cOption, cValue) {
    switch (cOption) {
      case 'title':
        this.dhx.setText(cValue);
        break;
    }
  },

  // set title *************
  setTitle: function(cTitle) {
    let cLabel = (this.opt.label) ? this.opt.label : '';
    cLabel = akioma.tran(`${this.opt.name}.` + '_title', { defaultValue: cLabel });

    if (app.sessionData.objectNamesInTitles)
      cLabel = `${this.opt.name}  | ${cLabel}`;

    // set title in window
    this.dhx.setText(`${cLabel} - ${cTitle}`);

    this.opt.title = cTitle;
  },

  // transfer field to caller *********************
  transferField: function(oElm) {
    const aTarget = this.opt.targetId.split(','),
      cTargetId = aTarget[0],
      oTarget = $.getObjectByName({ id: cTargetId });
    if (!oTarget) {
      !_isIE && console.error([ 'No target for popup:', this, this.opt.targetId ]);
      return false;
    }

    let oField;
    switch (oTarget.view) {
      case 'datagrid':
        for (const i in oTarget.childs) {
          if (oTarget.childs[i].opt.dataField == this.opt.fieldName) {
            oField = oTarget.childs[i];
            break;
          }
        }
        break;
      default: {
        const oDyn = oTarget.dynObject.getField(this.opt.fieldName);
        oField = (oDyn) ? oDyn.controller : null;
        break;
      }
    }

    if (!oField || oField.length < 1) {
      !_isIE && console.error([ 'popup: Error getting field:', this, oTarget, this.opt.fieldName ]);
      return false;
    }

    // tell field to get values (dependent on field type)
    if (aTarget[1] && aTarget[1] == 'filter')
      oField.setLookupFilter(oElm.dataSource);
    else
      oField.getExtValue(oElm.dataSource);

  },

  // save profile ********************************
  saveProfile: function() {
    const oWindow = this.dhx;

    // get string for layout
    const aDim = oWindow.getDimension(),
      aPos = oWindow.getPosition(),
      aPar = [`${aDim[0]}|${aDim[1]}|${aPos[0]}|${aPos[1]}|${oWindow.isMaximized()}`];

    const pushPanel = (cId, oItem) => {
      aPar.push(`${cId}|${oItem.getId()}|${oItem.getWidth()}|${oItem.getHeight()}|${oItem.isCollapsed()}`);
    };
    const getPanelPar = (iId, oElm) => {
      // run function for each panel inside
      oElm.children('panel', function() {

        // export panel size
        pushPanel(iId, this.dhx);

        // on home page look for tabbar
        if (iId == 0) {
          this.children('tabbar', function() {
            let i = 0;
            this.children('tab', function() {
              i += 1;
              const oPanelset = this.getDescendant('panelset');
              if (oPanelset)
                getPanelPar(i, oPanelset);
            });
          });
        }
      });
    };

    // get layout
    this.children('panelset', function() {
      getPanelPar(0, this);
    });

    // save profile
    $.ajax({
      type: 'POST',
      url: '/akioma/saveProfile.p',
      dataType: 'json',
      data: `Proc=${this.opt.name}&Profile=${aPar.join(';')}`,
      success: function() {},
      error: function(xhr, textStatus, errorThrown) {
        akioma.log.error(`Error saving profile: ${textStatus} -> ${errorThrown}`);
      }
    });
  },

  destroy: function() {
    // check if dhtmlx element exists -> destroy all attached elements
    if (this.dhx) {
      // delete all elements
      this.dhx.detachMenu();
      this.dhx.detachToolbar();
      this.dhx.detachObject(true);
      this.dhx.close();
    }
  }
};
