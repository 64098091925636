// ********************* combo ********************
$.extend({
  ak_combooption: function(options) {
    const defaults = {};
    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;
  }
});

$.ak_combooption.prototype = {};
