if (!akioma)
  akioma = {};
if (!akioma.repository)
  akioma.repository = {};

akioma.repository.objectGenerators = {
  common: {
    generateObject: function(oDialog, cMethod, oParameters) {
      akioma.invokeServerTask({
        name: 'Akioma.Swat.Repository.RepositoryObjectsGeneratorBT',
        methodName: cMethod,
        paramObj: oParameters,
        showWaitCursor: true,
        uiContext: oDialog
      }).done(oResult => {
        akioma.notification({ type: 'success', text: `"${oResult.plcParameter.Name}" has been generated successfully!` });
        oDialog.controller.close();
      }).fail(() => {
        akioma.notification({ type: 'error', text: 'Error generating object!' });
      });
    },
    onSourceChange: function(oSelf) {
      let cSourceType = oSelf.getValue();
      if (!isNull(cSourceType)) cSourceType = cSourceType.toLowerCase();
      const oForm = oSelf.dynObject.getParentOfType('form').controller;
      oForm.childs.filter(field => field.view === 'fieldset').forEach(fieldset => {
        if (fieldset.opt.name.startsWith('_')) return;
        if (fieldset.opt.name.endsWith(`_${cSourceType}`)) {
          oForm.showFormField(fieldset.opt.name);
          fieldset.childs.forEach(field => {
            oForm.setRequired(field.opt.name, true);
          });
        } else {
          oForm.hideFormField(fieldset.opt.name);
          fieldset.childs.forEach(field => {
            oForm.setRequired(field.opt.name, false);
          });
        }
      });
    }
  },
  schemaGenerator: {
    onCreateSchema: function(oSelf) {
      const oForm = oSelf.dynObject.parent.getFirstChildByType('form');

      const cFileName = oForm.dynObject.getValue('fileName');
      const cFileLocation = oForm.dynObject.getValue('fileLocation');
      const cObjectMasterGuidList = oForm.dynObject.getValue('ObjectMasterSelect');

      if (!oForm.validate()) return;

      akioma.invokeServerTask({
        name: 'Akioma.Swat.Repository.SchemaBT',
        methodName: 'GenerateSchema',
        paramObj: {
          plcParameter: {
            EntityList: cObjectMasterGuidList,
            FileName: cFileName,
            FileLocation: cFileLocation
          }
        },
        showWaitCursor: true,
        uiContext: oForm.dynObject
      }).done(oResult => {
        akioma.notification({ type: 'success', text: oResult.plcParameter.Message });
      }).fail(oResult => {
        akioma.notification({ type: 'error', text: oResult.plcParameter.Message });
      });
    }
  },
  entityGenerator: {
    onInitialize: function(oSelf) {
      const oDialog = oSelf.dynObject;
      const oForm = oDialog.getFirstChildByType('form');
      const oSourceTypeField = oForm.dynObject.getObject('sourcetype');
      akioma.repository.objectGenerators.common.onSourceChange(oSourceTypeField.controller);
    },
    onCreateEntity: function(oSelf) {
      const oDialog = oSelf.dynObject.container;
      const oForm = oDialog.getFirstChildByType('form');
      if (!oForm.validate()) return;

      const oParameter = {
        plcParameter: {
        // base properties
          Name: oForm.getValue('name'),
          Description: oForm.getValue('description'),
          Module: oForm.getValue('module'),

          // source type
          SourceType: oForm.getValue('sourcetype'),
          // business entity properties
          BusinessEntity: oForm.dynObject.getObject('businessentity').controller.getValue('desc'),
          // json properties
          SampleDataJson: oForm.getValue('sampledatajson'),
          // database properties
          Database: oForm.getValue('database'),
          SourceTables: oForm.getValue('sourcetables'),
          SourceQuery: oForm.getValue('sourcequery'),
          PrimaryUniqueKeys: oForm.dynObject.getValue('primaryuniquekeys'),
          ExtentPositionFields: (oForm.dynObject.getValue('extentpositionfields') == 'yes')
        }
      };
      akioma.repository.objectGenerators.common.generateObject(oDialog, 'GenerateEntity', oParameter);
    },
    onDatabaseSelect: function(oSelf) {
      const oForm = oSelf.getParentOfType('form');
      const oFieldsDynselect = oForm.getObject('sourcetables');
      oFieldsDynselect.controller.setReadonly(false);
      oFieldsDynselect.controller.setValue('');
    },
    onBeforeFecthTables: function(oSelf) {
      const oForm = akioma.getForm(oSelf.controller);
      oSelf.controller.businessEntity.query.addUniqueCondition('databasename', '=', oForm.getValue('database'));
    },
    onTableSelect: function(oSelf) {
      const cDatabaseTables = oSelf.getValue();
      if (cDatabaseTables > '') {
        const aDatabaseTables = cDatabaseTables.split(',');
        const oForm = akioma.getForm(oSelf.controller).dynObject;
        const oSourceQuery = oForm.getObject('sourcequery');
        if (oSourceQuery.getValue() === '') {
          let cQuery = 'FOR';
          aDatabaseTables.forEach((table, index) => {
            cQuery += `${index > 0 ? ',' : ''} EACH ${table}`;
          });
          cQuery += ' INDEXED-REPOSITION';
          oSourceQuery.setValue(cQuery);
        }
      }
    }
  },
  dataSourceGenerator: {
    onInitialize: function(oSelf) {
      const oDialog = oSelf.dynObject;
      const oForm = oDialog.getFirstChildByType('form');
      const oSourceTypeField = oForm.dynObject.getObject('sourcetype');
      akioma.repository.objectGenerators.common.onSourceChange(oSourceTypeField.controller);
    },
    onBusinessEntitySelect: function(oSelf) {
      const oForm = oSelf.getParentOfType('form');
      const oTableDynselect = oForm.getObject('businessentitytable');

      oTableDynselect.controller.setReadonly(false);
      oTableDynselect.controller.setValue('');
    },
    onBeforeFecthTables: function(oSelf) {
      const oForm = akioma.getForm(oSelf.controller);
      oSelf.controller.businessEntity.query.addUniqueCondition('businessentityguid', '=', oForm.getValue('businessentityguid'));
    },
    onCreateDataSource: function(oSelf) {
      const oDialog = oSelf.dynObject.container;
      const oForm = oDialog.getFirstChildByType('form');
      const cSourceType = oForm.getValue('sourcetype');
      const oParameter = {
        plcParameter: {
        // base properties
          Name: oForm.getValue('name'),
          Description: oForm.getValue('description'),
          Module: oForm.getValue('module'),

          // source type
          SourceType: cSourceType,
          // business entity properties
          BusinessEntity: oForm.dynObject.getObject('businessentity').controller.getValue('desc'),
          // entity properties
          EntityObject: oForm.dynObject.getObject('entityobject').controller.getValue('desc'),
          // common properties
          Table: (cSourceType === 'BusinessEntity') ? oForm.getValue('businessentitytable') : oForm.getValue('entitytable')
        }
      };
      akioma.repository.objectGenerators.common.generateObject(oDialog, 'GenerateDataSource', oParameter);
    }
  },
  dynSelectGenerator: {
    onCreateDynSelect: function(oSelf) {
      const oDialog = oSelf.dynObject.container;
      const oForm = oDialog.getFirstChildByType('form');
      const oParameter = {
        plcParameter: {
        // base properties
          Name: oForm.getValue('name'),
          Description: oForm.getValue('description'),
          Module: oForm.getValue('module'),

          // dynselect properties
          Label: oForm.getValue('label'),
          DataSourceObject: oForm.dynObject.getObject('datasourceobject').controller.getValue('desc'),
          GuidField: oForm.getValue('guidfield'),
          KeyField: oForm.getValue('keyfield'),
          DescriptionField: oForm.getValue('descriptionfield')
        }
      };
      akioma.repository.objectGenerators.common.generateObject(oDialog, 'GenerateDynSelect', oParameter);
    }
  },
  fieldContainerGenerator: {
    onInitialize: function(oSelf) {
      oSelf.setTitle(oSelf.customData.title);
    },
    onBeforeFetchName: function(oSelf) {
      const oDialog = oSelf.container.controller;
      if (oDialog.customData.objectType)
        oSelf.controller.businessEntity.query.addUniqueCondition('objecttypename', '=', oDialog.customData.objectType);
    },
    onEntitySelect: function(oSelf) {
      const oForm = oSelf.getParentOfType('form');
      const oFieldsDynselect = oForm.getObject('fields');
      const oModuleDynselect = oForm.getObject('module');

      oFieldsDynselect.controller.setReadonly(false);
      oFieldsDynselect.controller.setValue('');
      oModuleDynselect.controller.dynSelectControl.positionToRecord(oSelf.controller.businessEntity.getFieldValue('moduleguid'));
    },
    onBeforeFecthInstances: function(oSelf) {
      const oForm = akioma.getForm(oSelf.controller);
      oSelf.controller.businessEntity.query.addUniqueCondition('containerobjectmasterguid', '=', oForm.getValue('entityguid'));
    },
    onCreateContainer: function(oSelf) {
      const oDialog = oSelf.dynObject.container;
      const oForm = oDialog.getFirstChildByType('form');
      const oParameter = {
        plcParameter: {
        // base properties
          Name: oForm.getValue('name'),
          Description: oForm.getValue('description'),
          Module: oForm.getValue('module'),
          // fieldcontainer properties
          Title: oForm.getValue('title'),
          Entity: oForm.getValue('entity'),
          Fieldlist: oForm.getValue('fields')
        }
      };
      akioma.repository.objectGenerators.common.generateObject(oDialog, oDialog.controller.customData.generateMethod, oParameter);
    }
  },
  screenGenerator: {
    onCreateScreen: function(oSelf) {
      const oDialog = oSelf.dynObject.container;
      const oForm = oDialog.getFirstChildByType('form');
      const oParameter = {
        plcParameter: {
        // base properties
          Name: oForm.getValue('name'),
          Description: oForm.getValue('description'),
          Module: oForm.getValue('module'),
          // screen properties
          Title: oForm.getValue('title'),
          Template: oForm.getValue('template'),
          DataSource: oForm.getValue('datasource'),
          Grid: oForm.getValue('grid'),
          Form: oForm.getValue('form'),
          ReplaceNames: (oForm.getValue('replacenames') == 'yes')
        }
      };
      akioma.repository.objectGenerators.common.generateObject(oDialog, 'GenerateScreen', oParameter);
    }
  },
  batchGenerator: {
    onTriggerBatchGenerate: function(oSelf) {
      const oDialog = oSelf.dynObject.screen;
      const oForm = oDialog.getFirstChildByType('form').dynObject;
      const cSourceType = oForm.getObject('sourcetype').controller.getValue('desc');
      const cSourcePatternField = (cSourceType === 'BusinessEntity' ? 'businssentitypattern' : 'tablepattern');

      akioma.invokeServerTask({
        name: 'Akioma.Swat.Repository.RepositoryObjectsGeneratorBT',
        methodName: 'BatchGenerate',
        paramObj: {
          plcParameter: {
            // source properties
            SourceType: cSourceType,
            Database: oForm.getObject('database').controller.getValue('desc'),
            SourcePatternList: oForm.getObject(cSourcePatternField).controller.getValue('desc'),
            SuffixExcludeList: oForm.getValue('suffixexcludelist'),
            // module properties
            Product: oForm.getObject('product').controller.getValue('desc'),
            ModuleNamePattern: oForm.getValue('modulenamepattern'),
            // entity properties
            EntityNamePattern: oForm.getValue('entitynamepattern'),
            // data source properties
            GenerateDataSource: oForm.getValue('generatedatasources') == 'yes',
            DataSourceNamePattern: oForm.getValue('datasourcenamepattern'),
            // dynselect properties
            GenerateDynSelect: oForm.getValue('generatedynselects') == 'yes',
            DynSelectNamePattern: oForm.getValue('dynselectnamepattern'),
            // grid properties
            GenerateGrid: oForm.getValue('generategrids') == 'yes',
            GridNamePattern: oForm.getValue('gridnamepattern'),
            GridFieldListPattern: oForm.getValue('gridfieldlistpattern'),
            GridTitlePattern: oForm.getValue('gridtitlepattern'),
            // form properties
            GenerateForm: oForm.getValue('generateforms') == 'yes',
            FormNamePattern: oForm.getValue('formnamepattern'),
            FormFieldListPattern: oForm.getValue('formfieldlistpattern'),
            FormTitlePattern: oForm.getValue('formtitlepattern'),
            // scren properties
            GenerateScreens: oForm.getValue('generatescreens') == 'yes',
            // desktop screen properties
            GenerateDesktopScreen: oForm.getValue('generatedesktopscreen') == 'yes',
            DesktopScreenTemplate: oForm.getObject('desktopscreentemplate').controller.getValue('desc'),
            DesktopScreenNamePattern: oForm.getValue('desktopscreennamepattern'),
            DesktopScreenTitlePattern: oForm.getValue('desktopscreentitlepattern'),
            // maintenance screen properties
            GenerateMaintenanceScreen: oForm.getValue('generatemaintenancescreen') == 'yes',
            MaintenanceScreenTemplate: oForm.getObject('maintenancescreentemplate').controller.getValue('desc'),
            MaintenanceScreenNamePattern: oForm.getValue('maintenancescreennamepattern'),
            MaintenanceScreenTitlePattern: oForm.getValue('maintenancescreentitlepattern'),
            // create dialg properties
            GenerateCreateDialog: oForm.getValue('generatecreatedialog') == 'yes',
            CreateDialogTemplate: oForm.getObject('createdialogtemplate').controller.getValue('desc'),
            CreateDialogNamePattern: oForm.getValue('createdialognamepattern'),
            CreateDialogTitlePattern: oForm.getValue('createdialogtitlepattern')
          }
        },
        showWaitCursor: true,
        uiContext: oDialog
      }).done(() => {
        akioma.notification({ type: 'success', text: 'Objects have been generated successfully!' });
        oDialog.controller.close();
      }).fail(() => {
        akioma.notification({ type: 'error', text: 'Error generating objects!' });
      });
    }
  }
};

akioma.repository.datasourceDatasetDefinitionGenerator = {
  onGenerate: oSelf => {
    const oDialog = oSelf.dynObject.screen;
    const oForm = oDialog.getFirstChildByType('form').dynObject;

    akioma.invokeServerTask({
      name: 'Akioma.Swat.Repository.EntityDesignerBT',
      methodName: 'GenerateDataset',
      paramObj: { plcParameter: { DataSourceObject: oForm.getValue('objectmasterselect') } }
    }).done(result => {
      oForm.setValue('datasetdefinition', result.plcParameter.DatasetDefinition);
    });
  }
};

akioma.repository.businessEntityGenerator = {
  onDatabaseSelect: function(oSelf) {
    const oForm = oSelf.getParentOfType('form');
    const oFieldsDynselect = oForm.getObject('tables');

    oFieldsDynselect.controller.setReadonly(false);
    oFieldsDynselect.controller.setValue('');
  },
  onBeforeFecthTables: function(oSelf) {
    const oForm = akioma.getForm(oSelf.controller);
    oSelf.controller.businessEntity.query.addUniqueCondition('databasename', '=', oForm.getValue('database'));
  },
  onGenerate: function(oSelf) {
    const oDialog = oSelf.dynObject.container;
    const oForm = oDialog.getFirstChildByType('form');
    akioma.invokeServerTask({
      name: 'Akioma.Swat.Studio.BusinessEntity.BusinessEntityDesignerBT',
      methodName: 'GenerateBusinessEntity',
      paramObj: {
        plcParameter: {
          Package: oForm.getValue('package'),
          CreateSubpackage: (oForm.getValue('createdistinct') == 'yes'),
          BusinessEntityNamePattern: oForm.getValue('entitynamepattern'),
          DataAccessNamePattern: oForm.getValue('dataaccessnamepattern'),
          DatasetControllerNamePattern: oForm.getValue('controllernamepattern'),
          Camelcase: (oForm.getValue('forceuppercase') == 'yes'),
          Database: oForm.getValue('database'),
          TableNames: oForm.getValue('tables'),
          GenerateDatasetModel: (oForm.getValue('generatedatasetmodel') == 'yes')
        }
      },
      showWaitCursor: true,
      uiContext: oDialog
    }).done(() => {
      akioma.notification({ type: 'success', text: 'Business entities have been generated successfully!' });
      oDialog.controller.close();
    }).fail(() => {
      akioma.notification({ type: 'error', text: 'Error generating business entities!' });
    });
  },
  registerBusinessEntities: function() {
    akioma.invokeServerTask({
      name: 'Akioma.Swat.Studio.BusinessEntity.BusinessEntityDesignerBT',
      methodName: 'RegisterBusinessEntities',
      paramObj: { plcParameter: {} }
    }).done(() => {
      akioma.notification({ type: 'success', text: 'Finished scanning and registering business entities!' });
    });
  }
};
