(function($, jQuery) {

  jQuery.extend({
    removeEntry: function(aArray, cEntry) {

      if (!cEntry)
        return null;

      // check if array
      if (!(aArray instanceof Array))
        return null;

      try {
        const i = aArray.indexOf(cEntry);
        if (i > -1)
          aArray.splice(i, 1);
        return i;
      } catch (e) {
        !_isIE && console.error([ 'Error removing entry from array', cEntry, aArray ]);
      }
    }
  });

})(jQuery, jQuery);

const oDataObjects = {};

const dataObject = function(options) {
  const defaults = {
    name: '',
    primFields: 'selfhdl'
  };
  $.extend(this, defaults, options);
  this.initData();
  oDataObjects[this.name] = this;
};

dataObject.prototype = {

  // ******  get selected index  *****
  initData: function() {
    if (this.data) {
      this.data.indexes = null;
      this.data.records = null;
      this.data.updrecords = null;
      this.data.changed = null;
      this.data.filters = null;
    }
    this.selected = '';
    this.actRecord = null,
    this.data = {
      records: {},
      updrecords: {},
      indexes: new Array(),
      changed: new Array(),
      filters: new Array()
    };
  },

  // ******  get selected index  *****
  getIndex: function() {
    if (this.selected)
      return this.selected;

    if (this.data.indexes && this.data.indexes.length > 0) {
      this.setIndex(this.data.indexes[0]);
      return this.selected;
    }

    return null;
  },

  // ******* set selected index *********
  setIndex: function(index) {

    if (!index) {
      if (this.data.indexes && this.data.indexes.length > 0)
        index = this.data.indexes[0];
      else
        return false;
    }

    if (this.data.indexes.indexOf(index) == -1)
      return false;

    if (index != '' && index == this.selected)
      return false;

    // select index and set actual record
    this.selected = index;
    this.actRecord = this.data.records[index];

    return true;
  },

  // ********* get value of selected record *******
  getValue: function(name, index) {

    if (!index)
      index = this.selected;

    if (!this.data.records[index])
      return null;

    if (!this.data.records[index][name])
      return null;

    return this.data.records[index][name];
  },

  // ******* set value of object ***********
  setValue: function(name, value, index) {

    // check for index
    if (!index)
      index = this.selected;

    // check if record is available
    if (!this.data.records[index])
      return;

    // check if values has been changed
    if (this.data.records[index][name] == value)
      return;

    // check if field is in record
    if (this.data.records[index][name] == 'undefined')
      return;

    // check if record is already changed
    if (this.data.changed.indexOf(index) == -1) {
      // copy record first
      this.data.updrecords[index] = {};
      $.extend(true, this.data.updrecords[index], this.data.records[index]);

      this.data.records[index].rowmod = 'U';
      this.data.changed.push(index);
    }

    // now update value
    this.data.records[index][name] = value;

    return;
  },

  // ******* get primkey of a record *******
  getPrimKey: function(oRec) {
    if (!this.primFields)
      return '';

    const aPrim = this.primFields.split(',');

    let cKey = '';
    for (const i in aPrim)
      cKey += (cKey ? ',' : '') + oRec[aPrim[i]];

    return cKey;
  },

  // ****** copy record ***********
  copyRecord: function(oldIndex, newIndex) {

    // check if valid
    if (!this.data.records[oldIndex] || this.data.records[newIndex])
      return;

    // copy record
    this.data.records[newIndex] = {};
    $.extend(this.data.records[newIndex], this.data.records[oldIndex]);

    // change modification to "add"
    this.data.records[newIndex].rowmod = 'A';

    // push entries in arrays
    this.data.indexes.push(newIndex);
    this.data.changed.push(newIndex);
  },

  // ******* import from json object *******
  importJSON: function(aData, cName, lUpd) {
    let cPrim;

    // if no valid record -> leave
    if (!aData[cName])
      return;

    // import all records
    for (const i in aData[cName]) {
      cPrim = this.getPrimKey(aData[cName][i]);

      // if record is already available -> delete the old one first
      if (this.data.indexes.indexOf(cPrim) > -1) {
        if (this.data.updrecords[cPrim])
          delete this.data.updrecords[cPrim];
        if (this.data.changed.indexOf(cPrim) > -1)
          $.removeEntry(this.data.changed, cPrim);

        delete this.data.records[cPrim];
        $.removeEntry(this.data.indexes, cPrim);
      }

      // put the new record inside
      this.data.records[cPrim] = {};
      $.extend(this.data.records[cPrim], aData[cName][i]);
      this.data.indexes.push(cPrim);

      // check if record has rowmod attribute -> add to changed
      if (lUpd && this.getValue('rowmod', cPrim)) {
        this.data.changed.push(cPrim);

        if (this.getValue('rowmod', cPrim) == 'U') {
          this.data.updrecords[cPrim] = {};
          $.extend(this.data.updrecords[cPrim], aData[cName][i]);
        }
      } else if (!lUpd) {
        // reset rowmod
        this.data.records[cPrim].rowmod = '';
      }

    }

    // import filters
    for (const i in aData.gtFilters)
      this.data.filters.push(aData.gtFilters[i]);

  },

  // ******* get changed records *******
  getChangedRecords: function(cPrefix) {
    const aChanged = {};
    aChanged[cPrefix] = new Array();

    for (const i in this.data.changed) {
      const cPrim = this.data.changed[i];

      // copy record
      aChanged[cPrefix].push(this.data.records[cPrim]);

      // check if changed records available
      if (this.data.updrecords[cPrim])
        aChanged[cPrefix].push(this.data.updrecords[cPrim]);
    }

    return aChanged;
  },

  // ******* get filter records *******
  getFilter: function() {
    if (this.data && this.data.filters)
      return this.data.filters;
  },

  // ******* undoRecord *******
  undoRecord: function() {

    // check for selected index
    if (!this.selected)
      return;

    // check if changed record exists
    if (this.data.changed.indexOf(this.selected) > -1)
      $.removeEntry(this.data.changed, this.selected);


    // now copy record from changed to origin and delete changes
    if (this.data.updrecords[this.selected]) {
      $.extend(true, this.data.records[this.selected], this.data.records[this.selected], this.data.updrecords[this.selected]);
      delete this.data.updrecords[this.selected];
    }

    // in case of -> reset fields
    this.data.records[this.selected].rowmod = '';
  },

  // *********** destroy ****************
  destroy: function() {

    // delete from global
    if (oDataObjects[this.name]) {
      oDataObjects[this.name] = null;
      delete oDataObjects[this.name];
    }

    this.data.indexes = null;
    this.data.records = null;
    this.data.updrecords = null;
    this.data.changed = null;
    this.data.filters = null;
    this.data = null;
    delete this;
  }
};
