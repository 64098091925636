
/**
 * Adapter for ElasticSearch DataStore for usage in an akioma.businessEntity
 * @class
 */
class ElasticSearchDataAdapter extends akioma.DataAdapter {

  /**
   * Constructor of the ElasticSearch Data Adapter.
   *
   * @param {string} index Name of the Elastic search index from which to obtain data.
   */
  constructor(index, type) {
    super();

    const settings = akioma.getElasticApplicationSettings();
    /**
     * Underlying ElasticSearch DataStore (collection).
     *
     * @private
     *
     * @property {akioma.ElasticSearchHttpClient}
     */
    this._client = new akioma.ElasticSearchHttpClient({
      baseUrl: settings.baseUrl,
      index
    });

    /**
     * Underlying ElasticSearch DataStore payload
     *
     * @private
     *
     * @property {array}
     */
    this._lastPayload = [];

    /**
     * Underlying ElasticSearch DataStore type filter
     *
     * @private
     *
     * @property {string}
     */
    this._type = type || null;

    /**
     * Logger for this elastic search adapter.
     *
     * @private
     *
     * @property {akioma.Logger}
     */
    this._log = akioma.log.getLogger('ElasticDataAdapter');
  }

  /**
   * Fill data from the data store.
   *
   * @param {Object} options AkQuery parameter object.
   *
   * @returns {Promise}
   */
  fill(options) {

    const query = akioma.ElasticSearchAdapterHelper.convertAkQueryToElasticSearch(options);

    if (!isNull(options.skip))
      query.from = options.skip;

    if (!isNull(options.top))
      query.size = options.top;

    if (!isNull(this._type) && this._type !== '') {
      if (!query.query)
        query.query = {};

      if (!query.query.bool)
        query.query.bool = {};

      if (!query.query.bool.filter)
        query.query.bool.filter = {};

      query.query.bool.filter.match = { type: this._type };
    }

    if (options.filters.orderBy) {
      const sortData = options.filters.orderBy.split(' ');
      query.sort = [{ [sortData[0]]: sortData[1] || 'asc' }];
    }

    const fillPromise = this._client.search(query);

    fillPromise.done(res => {
      let records = [];
      if (res.error) {
        const errorObj = { message: res.error.caused_by ? res.error.caused_by.reason : res.error.root_cause.reason };
        this._emitEvent('afterFill', errorObj, false);
        return;
      }
      if (options.mergeMode)
        records = this._lastPayload.concat(res.hits.hits.map(val => val._source));
      else if (res.hits && res.hits.hits)
        records = res.hits.hits.map(val => val._source);

      this._lastPayload = records;
      this._emitEvent('afterFill', records);
    }).fail(error => {
      const response = error.responseText;
      const status = error.status;
      let text = 'Error when performing request to ElasticSearch API';

      if (status !== 500 && response)
        text = $(response).text().substr(0, 200);

      const errorObj = {
        message: text,
        xhr: error
      };
      this._emitEvent('afterFill', errorObj, false);
    });

    return fillPromise;
  }

}

akioma.ElasticSearchDataAdapter = ElasticSearchDataAdapter;
