
app.grid = {
  // call from cell
  cellCall: function(event, cId, iRow, iCol) {

    const oGrid = $.getObjectByName({ id: cId }),
      oCell = oGrid.childs[iCol],
      cKey = oCell.opt.keyField;

    if (oGrid.dataSource)
      oGrid.dataSource.setIndex(iRow);


    let cHdl;
    if (cKey)
      cHdl = oGrid.dataSource.dynObject.getValue(cKey);


    let cWinToLaunch;


    // use updateRecordContainer from column
    if (oCell.opt.updateRecordContainer)
      cWinToLaunch = oCell.opt.updateRecordContainer;
    // otherwise use Grid updateRecordContainer
    else if (oGrid.opt.updateRecordContainer)
      cWinToLaunch = oGrid.opt.updateRecordContainer;
    // last case scenario, use FolderWindowToLaunch
    else
      cWinToLaunch = oGrid.opt.FolderWindowToLaunch;


    // in case of "#dynamic" it will open the Hdl as the container
    if (cWinToLaunch.toLowerCase() === '#dynamic') {
      app.controller.launchContainer({
        pages: '0,1',
        extLink: cHdl,
        makeActive: !event.ctrlKey,
        caller: oGrid.dynObject
      });
    } else {

      app.controller.launchContainer({
        containerName: cWinToLaunch,
        pages: '0,1',
        // after rendering check PRIMARYSDO and position
        repositionTo: cHdl || oGrid.dataSource.dynObject.getValue('selfhdl'),
        data: true,
        activation: !event.ctrlKey,
        caller: oGrid.dynObject
      });

    }
  },

  //
  cellRun: function(cId, iRow, iCol) {
    const oGrid = $.getObjectByName({ id: cId }),
      oCell = oGrid.childs[iCol],
      cKey = oCell.opt.keyField;

    if (oGrid.dataSource)
      oGrid.dataSource.dhx.setCursor(iRow);


    let cHdl;
    if (cKey)
      cHdl = oGrid.dataSource.dynObject.getValue(cKey);


    oCell.prop.hdl = cHdl;
    app.controller.callAkiomaCode(oCell, oCell.opt.clickEvent);
  },

  // convert filter and put it in combo
  convertFilter: function(oSelf, oDef) {

    const oFilter = oSelf.getFilterArray();

    for (const i in oFilter.fields) {
      oDef.definition.push({
        fieldname: oFilter.fields[i],
        operator: oFilter.operators[i],
        value: oFilter.values[i]
      });
    }

    return oDef;
  }
};
