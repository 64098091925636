akioma.entry = app.entry;
akioma.numEntries = app.numEntries;
// adds a new accordion item
akioma.eventEmitter = new EventEmitter2();
akioma.socketConnection = new akioma.SocketConnection();
akioma.translation = new akioma.Translation();
akioma.ckEditorConfig = new akioma.CkEditorConfig();
akioma.devTools = new akioma.DevTools();
akioma.text2Speech = new akioma.Text2Speech();
akioma.shortcutManager = new akioma.ShortcutManagerClass();

// set global date format
// TODO - Move Date methods in a dedicated class. They're mostly used only internally
akioma.globalData = {
  dateFormat: { en: '%d.%m.%Y' }, // set global date format here
  dateTimeFormat: { en: '%d.%m.%Y %H:%i' }, // set global datetime format here
  globalNumericFormat: {
    numberFormat: '0,000.00',
    groupSep: '.',
    decSep: ','
  }
};
window.dhx.dateLang = 'en'; // set date language here
window.dhx.dateFormat = akioma.globalData.dateFormat;
window.dhx.dateTimeFormat = akioma.globalData.dateTimeFormat;

akioma.lastFocus = {};

dhtmlXCalendarObject.prototype.langData['de'] = {
  dateformat: '%Y-%m-%d',
  monthesFNames: [ 'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember' ],
  monthesSNames: [ 'Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez' ],
  daysFNames: [ 'Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag' ],
  daysSNames: [ 'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa' ],
  weekstart: 1
};

dhtmlXCalendarObject.prototype.langData['en'] = {
  dateformat: '%Y-%m-%d',
  monthesFNames: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ],
  monthesSNames: [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ],
  daysFNames: [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday' ],
  daysSNames: [ 'Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa' ],
  weekstart: 1
};

akioma.mainWindowView = true;
