akioma.version =
{
  "Major": 0,
  "Minor": 0,
  "Patch": 0,
  "PreReleaseTag": "",
  "PreReleaseTagWithDash": "",
  "PreReleaseLabel": "",
  "PreReleaseNumber": 0,
  "WeightedPreReleaseNumber": 0,
  "BuildMetaData": 0,
  "BuildMetaDataPadded": "0",
  "FullBuildMetaData": "",
  "MajorMinorPatch": "",
  "SemVer": "",
  "LegacySemVer": "",
  "LegacySemVerPadded": "",
  "AssemblySemVer": "",
  "AssemblySemFileVer": "",
  "FullSemVer": "0.0.0-UNVERSIONED",
  "InformationalVersion": "",
  "BranchName": "",
  "Sha": "",
  "ShortSha": "",
  "NuGetVersionV2": "",
  "NuGetVersion": "",
  "NuGetPreReleaseTagV2": "",
  "NuGetPreReleaseTag": "",
  "VersionSourceSha": "",
  "CommitsSinceVersionSource": 0,
  "CommitsSinceVersionSourcePadded": "",
  "CommitDate": ""
};
