// Applies a function defined in BE. This can be set in e.g. in EventRowChosen.
akioma.applyMenuFunctionFromBE = function(self) {
  const oGrid = self.controller,
    oSource = oGrid.dataSource.dynObject,
    cActionType = oSource.getValue('actiontype'),
    cActionParam = oSource.getValue('actionparameter');
  if (cActionType && cActionParam) {
    if (cActionType.toUpperCase() == 'RUN')
      app.controller.callAkiomaCode(null, cActionParam);
    if (cActionType.toUpperCase() == 'LAUNCH') {
      app.controller.launchContainer({
        self: oGrid,
        proc: cActionParam,
        data: true
      });
    }
  }
};

akioma.createBusinessEntity = function(oSelf, oAttributes) {
  // set BE
  if (oAttributes == undefined)
    oAttributes = oSelf.opt;

  if (oAttributes.entityName) {

    const oBEoptions = {
      att: {
        catalogURI: '',
        dataSource: '',
        entityName: oAttributes.entityTable,
        extKey: 'selfhdl',
        id: 'dynSelect_businessEntity',
        identifier: 'selfhdl',
        name: 'businessEntity',
        rowsToBatch: 1000,
        resourceName: oAttributes.entityName,
        serviceURI: '',
        SUBTYPE: oAttributes.SUBTYPE,
        initialFetch: '#none'
      },
      view: 'businessEntity'
    };

    oSelf.businessEntity = new $['ak_businessEntity'](oBEoptions);
    oSelf.businessEntity.finishConstruct();
    oSelf.businessEntity.endConstruct();

  } else if (oSelf.parent.view != 'datagrid2')
    akioma.notification({ type: 'error', text: `Please provide business entity for ${oSelf.opt.name}` });
};

/**
 * Method executed after add catalog on business entity query
 * @param {object} res Response object from JSDO
 * @param {Deferred} deferred Jquery deferred promise
 * @param {object} opts Settings for JSDO resource
 */
akioma.onAfterAddCatalogQueryEntity = function(res, deferred, opts) {
  try {

    // check for error type object
    if (akioma.isObjOfTypeError(res)) {
      akioma.log.error(res);
      return;
    }
    const jsdo = new progress.data.JSDO({ name: opts.name });

    if (opts.filter) {
      if (opts.filter instanceof Array) {
        opts.filter = {
          logic: 'and',
          filters: opts.filter
        };
      }

      if (!opts.filter.akQuery) {
        opts.filter = {
          akQuery: {
            SDO: opts.name,
            filters: opts.filter
          }
        };
      }
    }

    jsdo.fill(opts.filter)
      .then(({ jsdo }) => {
        deferred.resolve(jsdo.getData());
      })
      .catch(res => {
        // check for error type object
        if (akioma.isObjOfTypeError(res)) {
          akioma.log.error(res);
          return;
        }
        const { jsdo } = res;

        const errors = jsdo.getErrors();
        let msg = '';

        for (let i = 0; i < errors.length; i++) {
          msg += msg ? '\n' : '';

          switch (errors[i].type) {
            case progress.data.JSDO.RETVAL:
              msg += `Server App Return Value: ${errors[i].error}`;
              break;

            case progress.data.JSDO.APP_ERROR:
              msg += `Server App Error #${errors[i].errorNum}: ${errors[i].error}`;
              break;

            case progress.data.JSDO.ERROR:
              msg += `Server General Error: ${errors[i].error}`;
              break;

            default:
              msg += errors[i].error;
              break;
          }
        }

        deferred.reject(new Error(msg));
      });
  } catch (err) {
    deferred.reject(err);
  }
};

/**
 * Method used to query on the business entity
 * @param {object} opts
 */
akioma.queryBusinessEntity = function(opts) {
  const deferred = $.Deferred();
  const serviceURI = opts.serviceURI || window.location.origin;
  const jsdoSession = akioma.restSession;
  const jsdoSettings = {
    serviceURI: serviceURI,
    catalogURIs: `${serviceURI}/web/Catalog/${opts.name}`,
    authenticationModel: progress.data.Session.AUTH_TYPE_FORM
  };

  jsdoSession.addCatalog(jsdoSettings.catalogURIs)
    .then(res => {
      akioma.onAfterAddCatalogQueryEntity(res, deferred, opts);
    })
    .catch(res => {
      akioma.onAfterAddCatalogQueryEntity(res, deferred, opts);
    });
  return deferred.promise();
};

/**
 * Method used for initializing the refresh data for all the businessEntities
 */
akioma.enableGlobalRefresh = function() {
  // Setup for refreshScheme, enabled by default
  if (isNull(akioma.appdata))
    akioma.appdata = {};

  akioma.appdata.refreshAfterSave = true;
};
