/**
 * @class BaseLog
 * @namespace akioma
 */
akioma.BaseLog = class {
  /**
     * Init root or module based logger
     * @constructor
     * @memberof BaseLog
     */
  constructor(name, logger) {
    if (name === 'root')
      this.log = logger;
    else
      this.log = logger.getLogger(name);
  }

  /**
     * Display error level message
     * @memberof BaseLog
     * @param {any} msg
     * @returns {void}
     */
  error(...msg) {
    this.log.error(...msg);
  }

  /**
     * Display warn level message
     * @memberof BaseLog
     * @returns {void}
     */
  warn(...msg) {
    this.log.warn(...msg);
  }

  /**
     * Display info level message
     * @memberof BaseLog
     * @returns {void}
     */
  info(...msg) {
    this.log.info(...msg);
  }

  /**
     * Display debug level message
     * @memberof BaseLog
     * @returns {void}
     */
  debug(...msg) {
    this.log.debug(...msg);
  }

  /**
     * Display trace level message
     * @memberof BaseLog
     * @returns {void}
     */
  trace(...msg) {
    this.log.trace(...msg);
  }

  /**
     * This disables all logging below the given level, so that after a log.setLevel("warn") call log.warn("something")
     * or log.error("something") will output messages, but log.info("something") will not.
     * @memberof BaseLog
     * @returns {void}
     */
  setLevel(level) {
    this.log.setLevel(level);
  }

  /**
     * Returns the current logging level, as a number from 0 (trace) to 5 (silent)
     * @memberof BaseLog
     * @returns {integer}
     */
  getLevel() {
    return this.log.getLevel();
  }

  /**
     * These enable all log messages, and are equivalent to log.setLevel("trace")
     * @memberof BaseLog
     * @returns {void}
     */
  enableAll() {
    this.log.enableAll();
  }

  /**
     * These disable all log messages, and are equivalent to log.setLevel("silent")
     * @memberof BaseLog
     * @returns {void}
     */
  disableAll() {
    this.log.disableAll();
  }

};
