// ********************* listbargroup ******************
$.extend({
  ak_listbargroup: function(options) {
    const oSelf = this,
      defaults = {
        tree: null,
        title: '',
        id: ''
      };

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;

    // get parent
    const oAcc = this.parent.dhx;
    if (oAcc) {
      // add item to accordion
      oAcc.addItem(this.opt.id, this.opt.label);
      const oCell = oAcc.cells(this.opt.id);

      $(oCell).attr('id', `ListGroup${this.opt.id}`);

      // attach data view
      const oData = oCell.attachDataView({
        container: `ListGroup${this.opt.id}`,
        select: false,
        type: 'ListbarDetail',
        drag: true
      });

      oSelf.aPanelHeaderBtns = [];

      oData.customize({
        width: oCell.offsetWidth - 28,
        height: 80
      });

      oData.attachEvent('onItemClick', (cId, oEvent, oTarget) => {
        oSelf.click(cId, oEvent, oTarget);
      });
      oData.attachEvent('onBeforeDrop', oContext => oSelf.drop(oContext));

      $.extend(this, {
        dhx: oAcc.cells(this.opt.id),
        dataView: oData
      });

      // attach header button
      oSelf.attachContextMenu(oAcc.cells(this.opt.id));

    } else
      !_isIE && console.error(`No valid parent for listbargroup ${this.opt.id}`);

  }
});

// methods for listbargroup
$.ak_listbargroup.prototype = {


  attachContextMenu: function(oHeader) {

    const oSelf = this;
    const btnConf = { className: 'class-headerList-btn', width: 12, height: 12 };
    const popup = oHeader.attachHeaderButton(btnConf, '');
    const layout = popup.attachLayout(210, 140, '1C');

    /* !!!Test*/
    const ribbon = layout.attachRibbon({
      items: [
        {
          id: 'options', type: 'block', text: 'Settings',
          list: [

            { id: 'renameGroup', type: 'button', text: 'Rename Group' },
            { id: 'delGroup', type: 'button', text: 'Delete Group' },
            { id: 'delAllGroupItems', type: 'button', text: 'Delete All Items' },
            { id: 'undock', type: 'button', text: 'Undock' }
          ]
        }
      ]
    });
    const r = layout.base.getElementsByClassName('dhx_cell_layout');
    const h = layout.base.getElementsByClassName('dhx_cell_hdr');
    $(h).remove();

    if (r != null && r[0] != null)
      r[0].style.width = '';
    else
      akioma.log.error('dhx_cell_layout[0]');

    if (layout.dataNodes.ribbonObj != null) layout.dataNodes.ribbonObj.style.width = '';

    /* !!!Test*/
    (function(oSelf) {
      ribbon.attachEvent('onClick', cId => {
        oSelf.contextMenu(cId);
        popup.hide();
      });

    })(oSelf);

    oSelf.aPanelHeaderBtns.push(popup);

    return;


  },

  // click
  click: function(cId, oEvent) {

    // on delete item click
    if ($(oEvent.target).hasClass('close-item-dataview')) {
      const oElm = $.getObjectByName({ 'id': cId.split('-')[1] });
      oElm.delItem();
    } else {
      const oView = this.dataView,
        oRec = oView.get(cId);
        // run in tree (if available)
      const oListBar = this.getAncestor('listbar'),
        oTree = oListBar.getTree();
      if (oTree)
        oTree.reposition2Hdl(oRec.targetHdl);

      else {
        // activate window
        app.controller.launchContainer({
          proc: 'launchContainer.r',
          para: `SelfHdl=${oRec.targetHdl}&Page=0,1`,
          data: true,
          extLink: oRec.targetHdl,
          self: this
        });

      }
    }
  },

  // onclick
  contextMenu: function(cId) {
    switch (cId) {
      case 'undock':
        this.parent.dhx.forEachItem(cell => {
          if (cell.isOpened())
            cell.undock();
        });
        break;
      case 'addGroup':
        this.addGroup();
        break;
      case 'delGroup':
        this.delGroup();
        break;
      case 'renameGroup':
        this.renameGroup();
        break;
      case 'refresh': {
        const oList = this.getAncestor('listbar');
        oList.dataAvailable({});
        break;
      }
      case 'delAllGroupItems':
        this.delAllGroupItems();
        break;
    }
  },

  // add group **********
  addGroup: function() {
    const oList = this.getAncestor('listbar'),
      cHdl = oList.dataSource.getIndex();
    const oBox = dhtmlx.modalbox({
      title: 'Neue Gruppe',
      text: 'Titel der neuen Gruppe: <input type=\'text\' class=\'w4-inputField\' name=\'newgroup\' />',
      buttons: [ 'Save', 'Cancel' ],
      callback: function(result) {
        if (result == 0) {
          app.controller.callServerMethod('akioma/ak_listbar.r',
            [
              { type: 'iCHAR', value: cHdl },
              { type: 'iCHAR', value: $(oBox).find('input').val() }
            ]);

          oList.dataAvailable({});
          oList.dhx.cells(oList.childs[oList.childs.length - 1].opt.id).open();
        }
      }
    });
  },

  // delete group **********
  delGroup: function() {
    const oList = this.getAncestor('listbar'),
      cHdl = this.opt.selfHdl,
      oGroup = this; // oList.dataSource.getIndex();

    $.ajax({
      async: true,
      type: 'POST',
      url: '/akioma/getlistbar.xml',
      dataType: 'json',
      data: `Mode=delGroup&GroupHdl=${cHdl}`,
      success: function(data) {
        if (!data.ok)
          akioma.message({ type: 'alert-error', title: 'Delete group', text: 'Error deleting group.' });
        else {
          oList.dhx.removeItem(oGroup.opt.id);
          oGroup.destroy();

          let oPrevGroup = null;
          oList.dhx.forEachItem(cell => {
            oPrevGroup = cell;
          });

          if (oPrevGroup != null)
            oPrevGroup.open();
        }
      },
      error: function(xhr, textStatus, errorThrown) {
        akioma.log.error(`Error loading html from 'listbar': ${textStatus} -> ${errorThrown}`);
      }
    });
  },

  // delete all entries of group **********
  delAllGroupItems: function() {
    const cHdl = this.opt.selfHdl,
      oGroup = this;

    $.ajax({
      async: false,
      type: 'POST',
      url: '/akioma/getlistbar.xml',
      dataType: 'json',
      data: `Mode=delAllGroupEntries&GroupHdl=${cHdl}`,
      success: function(data) {
        if (!data.ok)
          akioma.message({ type: 'alert-error', title: 'Delete all entries', text: 'Error deleting entries.' });
        else
          oGroup.dataView.clearAll();

      },
      error: function(xhr, textStatus, errorThrown) {
        akioma.log.error(`Error loading html from 'listbar': ${textStatus} -> ${errorThrown}`);
      }
    });
  },


  // rename group **********
  renameGroup: function() {
    const cHdl = this.opt.selfHdl,
      oGroup = this;
    const oBox = dhtmlx.modalbox({
      title: 'Gruppe umbenennen',
      text: `<div>Neuer Titel: <input  onClick='this.select();' value='${oGroup.opt.label}' type='text' class='w4-inputField' name='newgroup' /></div>`,
      buttons: [ 'Save', 'Cancel' ],
      callback: function(result) {
        if (result == 0) {
          $.ajax({
            async: false,
            type: 'POST',
            url: '/akioma/getlistbar.xml',
            dataType: 'json',
            data: `Mode=renameGroup&GroupHdl=${cHdl}&Name=${$(oBox).find('input').val()}`,
            success: function(data) {
              if (!data.ok)
                akioma.message({ type: 'alert-error', title: 'Rename group', text: 'Error renaming group.' });
              else
                oGroup.dhx.setText($(oBox).find('input').val());

            },
            error: function(xhr, textStatus, errorThrown) {
              akioma.log.error(`Error loading html from 'listbar': ${textStatus} -> ${errorThrown}`);
            }
          });
        }
      }
    });
  },

  // drop ***************
  drop: function(oContext) {
    const oGrid = oContext.from,
      oSelf = this;

    // get tree object from dhtmlx
    const oElm = this.getAncestor('listbar');
    if (!oElm)
      return false;

    // get datasource of controller
    const oSource = oElm.dataSource;
    let cOwner;
    if (oSource)
      cOwner = oSource.getIndex();


    // get values from grid
    const cSelectedIDs = oGrid.getSelectedId(),
      aHdl = cSelectedIDs.split(',');

    for (let i = 0; i < aHdl.length; i++) {
      let cHdl = aHdl[i];
      let cIcon = 'struct007.gif';
      let cLabel;
      if (oGrid.isTreeGrid()) {
        cLabel = oGrid.getItemText(cHdl);
        cIcon = oGrid.getItemImage(cHdl);
      } else {
        cLabel = oGrid.cells(cHdl, 1).getValue();
        cHdl = oGrid.akElm.dataSource.dynObject.getValue('selfhdl');
      }

      $.ajax({
        async: true,
        type: 'POST',
        url: '/akioma/getlistbar.xml',
        dataType: 'json',
        data: `Mode=createItem&GroupHdl=${this.opt.selfHdl}&TargetHdl=${cHdl}&OwnerHdl=${cOwner}&Image=/imgs/${cIcon}&Label=${cLabel}`,
        success: function(data) {
          try {
            if (data.error) {
              !_isIE && console.error([ 'Error:', data.error, data ]);
              akioma.message({ type: 'alert-error', title: 'Error dropping', text: data.error });
            } else {
              // get icon from drag and drop item
              data.att.image = `imgs/${cIcon}`;
              app.controller.parseProc(data, oSelf);
            }
          } catch (e) {
            !_isIE && console.error([ 'Error while parsing of listbar', data, e ]);
            akioma.message({ type: 'alert-error', title: 'Error parsing listbar', text: e.message });
          }
        },
        error: function(xhr, textStatus, errorThrown) {
          !_isIE && console.error([ 'Error loading from listbar', textStatus, errorThrown ]);
          akioma.message({ type: 'alert-error', title: 'Error loading listbar', text: `${textStatus} -> ${errorThrown}` });
        }
      });
    }


    return false;
  },

  destroy: function() {
    // check if dhtmlx element exists -> destroy all attached elements
    if (this.dhx) {
      try {
        // breathing window destroy, fix for stability and performance
        new Promise(resolve => {
          for (const i in this.aPanelHeaderBtns)
            this.aPanelHeaderBtns[i].unload();

          resolve();
        });


        const index = this.parent.childs.indexOf(this);
        if (index > -1) {
          this.parent.childs.splice(index, 1);
          this.parent.dhx.removeItem(this.opt.id);
        }

      } catch (e) {
        !_isIE && console.error([ 'Error destroying', this.view, this.opt.name, e.message ]);
      }
    }
  }
};
