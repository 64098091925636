// add mechanism for triggering events
akioma.events.domains = ['akioma.events'];
akioma.events.triggerEvent = (eventName, ...args) => {
  for (const index in akioma.events.domains) {
    const event = eval(`${akioma.events.domains[index]}.${eventName}`);
    if (event !== undefined)
      return event(...args);
  }

  const event = eval(eventName);
  if (event !== undefined)
    return event(...args);

  throw new Error(`Could not find event ${eventName}`);
};

// global context function
// eslint-disable-next-line no-unused-vars
function setValueOnServer(Self, cProc) {
  const cValue = self.getValue();
  app.controller.callServerMethod(cProc, [
    { type: 'iCHAR', value: cValue },
    { type: 'oCHAR', name: 'cstatus' }
  ]);
}

// global context function
// eslint-disable-next-line no-unused-vars
function getPrimarySDOHdl(oSelf) {
  const oNavTarget = oSelf.container.getLink('PRIMARYSDO:TARGET');
  if (isValidObj(oNavTarget) == false) {
    akioma.log.error(`cannot get Primary-SDO Target for: ${oSelf.name}`);
    return '';
  }

  return oNavTarget.getValue('selfhdl');
}

// global context function
// eslint-disable-next-line no-unused-vars
function getCallerValue2(oSelf, cFieldName) {
  const oCaller = oSelf.container.caller;
  if (isValidObj(oCaller) == false) {
    akioma.log.error(`cannot get caller for: ${oSelf.container.name}`);
    return '';
  }

  const oNavTarget = oCaller.parent.getLink('PRIMARYSDO:TARGET');
  if (isValidObj(oNavTarget) == false) {
    akioma.log.error(`cannot get Primary-SDO Target for: ${oCaller.name} (${oCaller.type})`);
    return '';
  }

  return oNavTarget.getValue(cFieldName);
}

function isValidObj(objToTest) {
  if (null == objToTest) return false;
  if ('undefined' == typeof (objToTest)) return false;
  return true;
}

// Saves newly added jsdo object to backend and display confirmation message
// global context function
// eslint-disable-next-line no-unused-vars
function tb_DialogSaveRecord(oSelf) {
  const BE = oSelf.container.controller.dynObject.getLink('PRIMARYSDO:TARGET').controller;

  // submit form changes before saving
  akioma.skipQuery = true;
  oSelf.container.controller.getDescendant('form').submitRow();
  akioma.skipQuery = false;
  // after creating display message of newly created record

  BE.addCallback('afterCreate', (success, data) => {
    if (success) {
      oSelf.container.controller.close();
      data = BE.objectKeysToLowerCase(data);
      const cHdl = data.selfhdl;
      const cNo = data.selfno || data.selfdesc;
      akioma.notification({
        type: 'link',
        message: 'New record created: ',
        linkValue: cHdl,
        linkDesc: cNo,
        bottom: ''
      });
    }
  });

  // send data
  BE.dc.sendData();
}

// global context function
// eslint-disable-next-line no-unused-vars
function tb_DialogCancel(oSelf) {
  oSelf.container.controller.promptWindowClose();
}

// global context function
// eslint-disable-next-line no-unused-vars
function tb_WinClose(oSelf) {
  oSelf.container.controller.promptWindowClose();
}

// global context function
// eslint-disable-next-line no-unused-vars
function refreshCaller(oSelf, cOptions) {
  if (!cOptions)
    cOptions = 'parent';

  let oCaller = oSelf;
  if (cOptions !== 'self') {
    oCaller = oSelf.container.caller;
    if (!isValidObj(oCaller)) {
      akioma.log.error(`cannot get caller for: ${oSelf.name}`);
      return '';
    }
  }

  const oNavTarget = oCaller.screen.getLink('PRIMARYSDO:TARGET');
  if (!isValidObj(oNavTarget)) {
    akioma.log.error(`cannot get Primary-SDO Target for: "${oCaller.name}" ("${oCaller.type}")`);
    return '';
  }
  oNavTarget.controller.reloadData();
}

// global context function
// eslint-disable-next-line no-unused-vars
function tb_DialogOK(oSelf) {
  const cDialog = `${oSelf.container.name}_OK`;
  try {
    const lCancel = akioma.events.triggerEvent(cDialog, oSelf);
    if (lCancel == true)
      oSelf.container.controller.close();
  } catch (err) {
    akioma.log.error([ 'error: unable to call dialog ok handler', cDialog, err ]);
  }
}

// global context function
// eslint-disable-next-line no-unused-vars
function logViewerInit(self) {
  const oReturn = app.controller.callServerMethod('std/dbg/getLogSettings.p', [
    { type: 'iCHAR', value: '' },
    { type: 'oCHAR', name: 'cLogFile' },
    { type: 'oCHAR', name: 'cEntryTypes' },
    { type: 'oCHAR', name: 'cDebugLevel' },
    { type: 'oINT', name: 'iLogLevel' },
    { type: 'oINT', name: 'iLogThreshold' },
    { type: 'oINT', name: 'iNumLogfiles' },
    { type: 'oCHAR', name: 'cDebugAlert' }
  ]);

  self.setValue('giloglevel', oReturn.iLogLevel);
  self.setValue('ginumlogfiles', oReturn.iNumLogfiles);
  self.setValue('gilogthreshold', oReturn.iLogThreshold);
  self.setValue('gclogfile', oReturn.cLogFile);
  self.setValue('gcentrytypes', oReturn.cEntryTypes);
  self.setValue('gcdebuglevel', oReturn.cDebugLevel);
  self.setValue('gcdebugalert', oReturn.cDebugAlert);
}

function callPrintFunctionality(self, formName, businessTaskName) {
  const win = self.container;
  const form = win.getObject(formName);
  const grid = win.caller;
  const data = grid.getLink('DATA:SOURCE');

  if (!form.controller.validate()) return;

  const opts = {
    BusinessEntity: data.controller.opt.resourceName,
    EntityName: data.controller.opt.entityName,
    Template: '',

    OptionNames: [],
    OptionValues: [],

    FieldNames: [],
    FieldLabels: [],

    FilterFields: [],
    FilterOperators: [],
    FilterValues: [],

    SortFields: [],
    SortDescends: []
  };

  // get options
  const options = form.getFieldValues();

  for (const key in options) {
    opts.OptionNames.push(key);
    opts.OptionValues.push(options[key]);
  }

  // get columns
  grid.controller.childs.forEach(child => {
    if (child.opt.dataField) {
      opts.FieldNames.push(child.opt.dataField);
      opts.FieldLabels.push(child.opt.label);
    }
  });

  // get filter
  const filter = grid.controller.getFilterArray(false);

  opts.FilterFields = filter.fields;
  opts.FilterOperators = filter.operators;
  opts.FilterValues = filter.values;

  // add foreignkey to filter
  const foreignKey = data.controller.getForeignKey();
  if (foreignKey.name && foreignKey.value) {

    opts.FilterFields.push(foreignKey.name);
    opts.FilterOperators.push('=');
    opts.FilterValues.push(foreignKey.value);
  }

  akioma.invokeServerTask({
    name: businessTaskName,
    methodName: 'ExportGrid',
    paramObj: { plcParameter: opts },
    showWaitCursor: true,
    uiContext: win
  }).always(ret => {
    ret = ret.plcParameter;
    if (ret.Error) {
      akioma.notification({
        type: 'error',
        text: ret.Error,
        expire: -1
      });
    } else {
      akioma.messaging.documentInfo(
        akioma.tran('msg.docPrinted', { defaultValue: 'Dokument wurde gedruckt' }),
        ret.Result,
        akioma.tran('msg.open', { defaultValue: 'Öffnen' }),
        'OK');
      win.controller.close();
    }
  });
}

// global context function
// eslint-disable-next-line no-unused-vars
function wWordReport_OK(self) {
  callPrintFunctionality(self, 'wordv', 'Akioma.Crm.Printing.ExportGridToWordTask');
}

// global context function
// eslint-disable-next-line no-unused-vars
function wExcel_OK(self) {
  callPrintFunctionality(self, 'excelv', 'Akioma.Crm.Printing.ExportGridToExcelTask');
}

// global context function
// eslint-disable-next-line no-unused-vars
function compileDocx(oSelf) { // swat
  const oSDO = oSelf.parent.getLink('PRIMARYSDO:TARGET');
  const lcHdl = oSDO.getValue('selfhdl');

  const oReturn = app.controller.callServerMethod('mod/sys/compileDocX.p', [
    { type: 'iCHAR', value: lcHdl },
    { type: 'iCHAR', value: '' },
    { type: 'oCHAR', name: 'result' }
  ]);

  akioma.notification({
    type: 'info',
    text: `Fertig. <br>${oReturn.result}`,
    lifetime: -1
  });
}

// global context function
// eslint-disable-next-line no-unused-vars
function importFormLabelsTxt(oSelf) { // swat
  const container = oSelf.container;
  const dFileList = container.getObject('dFileList');
  const filename = dFileList.getValue('clientfilename');

  if (!filename) {
    akioma.notification({ type: 'error', text: 'Datei nicht ausgewählt' });
    return;
  }

  akioma.invokeServerTask({
    name: 'Akioma.Crm.MasterData.General.FormLabelBT',
    methodName: 'importFormLabels',
    paramObj: {
      plcParameter: {
        ImportFileName: filename,
        OverwriteIfExists: true
      }
    }
  }).then(({ plcParameter }) => {
    akioma.notification({
      type: 'info',
      text: `Fertig. ${plcParameter.UpdatedRecordsCount} Datensätze aktualisiert`,
      expire: -1
    });
  });

  container.controller.close();
}

// global context function
// eslint-disable-next-line no-unused-vars
function exportFormLabels(oSelf) { // swat
  let cFile = oSelf ? oSelf.container.getObject('dExportDefinition').getValue('misc2') : '';
  if (!cFile)
    cFile = 'form_labels.xml';

  akioma.message({
    text: 'Dateiname exportieren', type: 'input', inputVal: cFile, callback: file => {
      if (file === null)
        return;

      const oReturn = app.controller.callServerMethod('stubs/exportLabels.p', [
        { type: 'iCHAR', value: '' },
        { type: 'iCHAR', value: file },
        { type: 'oCHAR', name: 'creturn' }
      ]);

      if (!oReturn.errorStatus) {
        akioma.notification({
          type: 'info',
          text: `Fertig. ${oReturn.creturn}`,
          lifetime: -1
        });
      }
    }
  });
}

// global context function
// eslint-disable-next-line no-unused-vars
function logSet_Click(self) { // swat
  const iLogLevel = self.parent.getValue('giloglevel');
  const cEntryTypes = self.parent.getValue('gcentrytypes');
  const cLogFile = self.parent.getValue('gclogfile');
  const cDebugAlert = self.parent.getValue('gcdebugalert');
  const cDebugLevel = self.parent.getValue('gcdebuglevel');
  app.controller.callServerMethod('std/dbg/setLogSettings.p', [
    { type: 'iCHAR', value: '' },
    { type: 'iCHAR', value: cLogFile },
    { type: 'iCHAR', value: cEntryTypes },
    { type: 'iCHAR', value: cDebugLevel },
    { type: 'iINT', value: iLogLevel },
    { type: 'iCHAR', value: cDebugAlert },
    { type: 'oChar', name: 'cReturn' }
  ]);
}

// global context function
// eslint-disable-next-line no-unused-vars
function dlgDragDrop_Init(oSelf, oContext, cType) { // swat
  akioma.treeDragAndDrop.initializeDialog(oSelf, oContext, cType);
}

// global context function
// eslint-disable-next-line no-unused-vars
function gotoRec(self) { // swat
  const oNavTarget = self.getLink('NAVIGATION:TARGET');
  let cSdo = '';

  const cValue = self.getValue();
  if (cValue == '')
    oNavTarget.controller.reloadData();

  if (cValue.substring(0, 1) == '_') {
    location.href = cValue.substring(1);
    return;
  }

  if (cValue.substring(0, 1) == '!') {
    const lOld = false;
    if (lOld)
      akioma.launchContainer (cValue.substring(1), '');
    else {
      app.controller.launchContainer({
        proc: `${cValue.substring(1)}.r`,
        self: this,
        data: true
      });
    }
    return;
  }

  if (isValidObj(oNavTarget) == false) {
    akioma.log.error(`cannot get Primary-SDO Target for: ${self.name} (${self.type})`);
    return '';
  }

  cSdo = oNavTarget.controller.opt.SDO;
  const oReturn = app.controller.callServerMethod('stubs/handleToKeyBySdo.p', [
    { type: 'iCHAR', value: cValue },
    { type: 'iCHAR', value: cSdo },
    { type: 'oChar', name: 'cHdl' }
  ]);

  if (!isValidHdl(oReturn.cHdl)) {
    akioma.notification({
      type: 'info',
      text: `Ungültiger Schlüssel: ${cValue}`,
      lifetime: 10000
    });

    return;
  }

  oNavTarget.controller.urlQuery.extLink = oReturn.cHdl;
  oNavTarget.controller.openQuery();

  return;
}

// global context function
// eslint-disable-next-line no-unused-vars
function setUseComboCacheState(self) { // swat
  const cState = self.parent.controller.dhx.getItemState (self.name);
  app.sessionData.useComboCache = cState;
  return;
}

// global context function
// eslint-disable-next-line no-unused-vars
function getUseComboCacheState(self) {
  const cState = app.sessionData.useComboCache;
  self.parent.controller.dhx.setItemState (self.name, cState);
  return;
}
