/**
* @class akioma.date
*/
akioma.date = {};

/**
 * Replaces all occurences in a string with a given set of mappings
 * @param  {String}  cString String to be replaced
 * @param  {Object}  oData Object with mappings
 * @return {String}        String replaced
 * @instance
 * @memberOf akioma
 */
akioma.date.replaceOccurences = function(cString, oData) {
  const r = new RegExp(Object.keys(oData).join('|'), 'gi');
  const result = cString.replace(r, matched => oData[matched]);
  return result;
};

/**
 * Converts Dhtmlx date/datetime format to InputMask date/datetime format
 * @param  {String}  cFormat Dhtmlx date/datetime format
 * @return {String}          InputMask format
 * @private
 * @instance
 * @memberOf akioma
 */
akioma.date.convertDateFormat = function(cFormat) {
  const oDateMapping = {
    '%d': 'dd',
    '%m': 'mm',
    '%Y': 'yyyy',
    '%H': 'HH',
    '%i': 'MM'
  };
  return akioma.date.replaceOccurences(cFormat, oDateMapping);
};

/**
 * Converts Dhtmlx date/datetime format to DD.MM.YYYY
 * @param  {String}  cFormat Dhtmlx date/datetime format
 * @return {String}          new format (DD.MM.YYYY HH:mm)
 * @private
 * @instance
 * @memberOf akioma
 */
akioma.date.convertDhtmlxFormat = function(cFormat) {
  const oDateMapping = {
    '%d': 'DD',
    '%m': 'MM',
    '%Y': 'YYYY',
    '%H': 'HH',
    '%i': 'mm',
    ':%s.%u%P': '', // remove seconds and timezone
    '-': '.',
    'T': ' '
  };
  return akioma.date.replaceOccurences(cFormat, oDateMapping);
};

/**
 * Convert to Moment format
 * @param {String} date
 * @return {String}
 * @private
 * @instance
 * @memberof akioma
 *
 */
akioma.date.convertInputMaskToMoment = function(date) {
  date = date.toUpperCase();
  if (date.includes(':MM'))
    date = date.replace(':MM', ':mm');
  return date;
};

/**
 * Gets maximum date/datetime format based on InputMask format
 * @param  {String}  cFormat Inputmask date/datetime format
 * @param  {bool}    bMode   Specifies if the function should return the maximum or minimum date. True for max, false for min
 * @return {String}          InputMask maximum date/datetime format
 * @private
 * @instance
 * @memberOf akioma
 */
akioma.date.getMax_MinDate = function(cFormat, bMode) {
  const oDate = new Date();
  const oDateMapping = {
    'dd': '31',
    'mm': '12',
    'yyyy': (bMode) ? oDate.getFullYear() + 200 : oDate.getFullYear() - 200,
    'HH': '23',
    'MM': '59'
  };
  return akioma.date.replaceOccurences(cFormat, oDateMapping);
};

/**
 * Replaces missing parts from a date with current date
 * @param  {String}  cFormat Inputmask date/datetime format
 * @param  {String}  cDate   The date value
 * @return {Object}          New date object
 * @instance
 * @memberOf akioma
 */
akioma.date.fillDate = function(cFormat, cDate) {

  function getFromDate(c) {
    const iIndex = cFormat.indexOf(c);
    const cPart = (c == 'yyyy') ? cDate.substring(iIndex, iIndex + 4) : cDate.substring(iIndex, iIndex + 2);
    return cPart;
  }

  const oNewDate = new Date();
  const month = Number(getFromDate('mm')) - 1;

  oNewDate.setDate (Number(getFromDate('dd')) || oNewDate.getDate());
  oNewDate.setFullYear(Number(getFromDate('yyyy')) || oNewDate.getFullYear());
  oNewDate.setHours (Number(getFromDate('HH')) || oNewDate.getHours());
  oNewDate.setMinutes (Number(getFromDate('MM')) || oNewDate.getMinutes());
  oNewDate.setMonth (!isNaN(month) ? month : oNewDate.getMonth());

  return oNewDate;
};
