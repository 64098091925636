akioma.adjustMainWinStyling = function(oSelf) {
  const windowObj = oSelf.controller;

  if (!windowObj.panelsetAdjust) {
    const oPanel = windowObj.getDescendant('panelset');
    oPanel.dhx.setOffsets({ left: 0, top: 0, right: 0, bottom: 0 });
    windowObj.panelsetAdjust = true;
  }

  const sidebar = windowObj.getDescendant('tabbar');
  if (sidebar != null) {
    $(sidebar.dhx.cont.children[0]).addClass('sidebar_removeBorders');
    $(sidebar.dhx.cont.children[0]).find('div.dhxsidebar_item').addClass('sidebar_removeMargin');
    $(oSelf.controller.dhx.cell).addClass('winCell_reposition');
  }

};
