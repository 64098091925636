/**
 * A dynObject is created for each Element of a screen which is rendered at runtime.
 * These elements are visual Objects (Grid,Form,Window,Button,Input,dynSelect...) as well as non-visual Objects (e.g. Datasources).
 * The dynObject Objects represent the Repository-Hierarchy of a Screen at runtime. each dynObject has a parent attribute,
 * which links to its parent Object (e.g. a Form for a Field, or the Window for a Form...).
 * The dynObject does not contain Methods specific to a certain control-type, but rather generic information, which is common
 * to each control. It is mainly used to access/scan the structure of a Screen as it is defined in the Repository, at runtime.
 *
 * This is mostly used in eventCode, e.g. eventLeave for an input in a Form, or eventClick for click of a Button. In all event code, the corresponding Object is accessible through the Variable self.  This returns the dynObject of the Object, for which the event occurred.
 *
 * Then, using functions on the dynObject like e.g.
 *
 * parent
 *
 *
 * container
 *
 *
 * getLink()
 *
 *
 * getObject()
 *
 *
 * you can find the objects you need, and process the needed client-logic.
 *
 * Using the controller attribute of a dynObject, you can access the actual specific control
 * (e.g. the actual {@link ak_accordion ak_accordion}, {@link ak_appointment ak_appointment}, {@link ak_businessEntity ak_businessEntity},
 * {@link ak_button ak_button}, {@link ak_calendar ak_calendar}, {@link ak_chart ak_chart}, {@link ak_combobox ak_combobox},
 * {@link ak_datagrid ak_datagrid}, {@link ak_dataview ak_dataview}, {@link ak_designer ak_designer}, {@link ak_diagram ak_diagram},
 * {@link ak_docviewer ak_docviewer}, {@link ak_dynselect ak_dynselect}, {@link ak_fabbutton ak_fabbutton}, {@link ak_fieldset ak_fieldset},
 * {@link ak_form ak_form}, {@link ak_formbuilder ak_formbuilder}, {@link ak_frame ak_frame}, {@link ak_gantt ak_gantt},
 * {@link ak_gauge ak_gauge}, {@link ak_image ak_image})
 *
 * @param {object} options Settings for dynObject
 * @param {string} options.name Name of DynObject
 * @param {string} options.selfHdl
 * @param {Object} options.controller Akioma Object
 * @param {Object} options.attributes The list of repository attributes.
 * @param {string} options.type Type of DynObject
 * @param {Object} options.parent The parent of the current DynObject
 * @param {Object} options.container The container attribute links to the top-level control, usually a Window or a Dialog.
 * It can be used to access other parts of a screen, by first using the container attribute, to get the top object of a screen, and from there access some other object.
 * e.g. in a leave event: self.container.getObject("myOtherForm")
 *
 * It can also be used to figure out the object that launched the container, by using the caller attribute.
 * @param {Object} options.childs List of children objects
 * @param {Object} options.links List of links on object
 * @class dynObject
 *
 *
 */
window.dynObject = function(options) {
  const defaults = {
    name: '',
    selfHdl: null,
    controller: null,
    attributes: {},
    type: '',
    parent: null,
    container: null,
    dataSource: null,
    childs: {},
    links: {}
  };

  $.extend(this, defaults, options);
};

dynObject.prototype = {
  // get object with a specific type (up the tree)
  getParentOfType: function(cType) {
    if (this.parent) {
      // check type
      if (this.parent.type === cType)
        return this.parent;
      else
        return this.parent.getParentOfType(cType);
    } else
      return null;
  },

  // get object with a specific type (down the tree)
  getChildOfType: function(cType) {
    if (this.childs) {
      // check type in all children
      for (const i in this.childs) {
        if (this.childs[i].type === cType)
          return this.childs[i];
      }
    }
    return null;
  },

  /**
   * Return the object with the given object name from its children objects. (down the tree)
   * @param  {String} name Name of Akioma Object
   * @return {object}
   * @instance
   * @memberOf dynObject
   */
  getObject: function(name) {
    return this.controller.getObject(name);
  },

  /**
   * Return the first child found with the given type
   * @example
   * let oDataView = self.getFirstChildByType('dataview');
   * @param  {string} cType The object type
   * @return {object}       The object found
   * @instance
   */
  getFirstChildByType: function(cType) {
    return this.controller.getDescendant(cType);
  },

  /**
   * Return parent by given type name.
   * @example
   * let oTabbar = self.getFirstParentByType('tabbar');
   * @param  {string} cType The object type
   * @return {object}
   * @instance
   * @memberOf dynObject
   */
  getFirstParentByType: function(cType) {
    return this.controller.getAncestor(cType);
  },

  setBGColor: function(cName, cColor) {
    try {
      const oForm = this.controller.dhx,
        oField = oForm.getInput(cName);
      $(oField).css('background-color', cColor);
    } catch (e) {
      akioma.log.error(`Could not set BG color for form item:${cName}`, e);
    }
  },

  /**
   * DEPRECATED method. Use "getObject".
   * @deprecated
   */
  getObject2: function(name) {
    return this.getObject(name);
  },

  _searchNestedFormElements: function(oParent, name) {
    for (const i in oParent.childs) {
      if (i.toLowerCase() == name.toLowerCase())
        return oParent.childs[i];
      else if (oParent.childs[i].type == 'fieldset' || oParent.childs[i].type == 'block') {
        const oResult = this._searchNestedFormElements(oParent.childs[i], name);
        if (oResult != null)
          return oResult;
      }
    }
  },

  /**
   * Method that returns the current layout object sizes, window or panel
   * @memberof dynObject
   * @instance
   * @returns {object} User profile settings object
   */
  getObjectSizes: function() {
    const oSelf = this.controller;
    const oSettings = {
      name: oSelf.opt.name,
      type: oSelf.view,
      id: oSelf.getObjectsNamespace().join('/')
    };
    const cObjType = oSelf.view;

    if (cObjType === 'window') {
      const oWindow = oSelf.dhx,
        aPos = oWindow.getPosition(),
        aDim = oWindow.getDimension(),
        bMaximized = oWindow.isMaximized();

      let oWinParent = this.controller.parent.dhx;
      const bStick = this.controller.opt.floating;

      // if modal window then calculate for modal containeror if floating
      if (this.controller.opt.modal || (bStick && !akioma.toggleFlag))
        oWinParent = akioma.oWindowsModals;

      const oWinConf = oWinParent.w[oWindow.getId()].conf,
        $windowsParentViewport = $(oSelf.parent.dhx.vp);
      const xPercent = ((bMaximized) ? (oWinConf.lastMX / $windowsParentViewport.width() * 100) : (aPos[0] / $windowsParentViewport.width() * 100)),
        yPercent = ((bMaximized) ? (oWinConf.lastMY / $windowsParentViewport.height() * 100) : (aPos[1] / $windowsParentViewport.height() * 100)),
        wPercent = ((bMaximized) ? (oWinConf.lastMW / $windowsParentViewport.width() * 100) : (aDim[0] / $windowsParentViewport.width() * 100)),
        hPercent = ((bMaximized) ? (oWinConf.lastMH / $windowsParentViewport.height() * 100) : (aDim[1] / $windowsParentViewport.height() * 100));

      oSettings.xPercent = xPercent;
      oSettings.yPercent = yPercent;
      oSettings.wPercent = wPercent;
      oSettings.hPercent = hPercent;
      oSettings.maximized = bMaximized;
    } else {
      const oPanel = this.getFirstParentByType('panel').dhx,
        oWin = oSelf.getAncestor('window').dhx,
        aWinDim = oWin.getDimension(),
        wPercent = oPanel.getWidth() / aWinDim[0] * 100,
        hPercent = oPanel.getHeight() / aWinDim[1] * 100;

      if (!oPanel.isCollapsed()) {
        if (!isNaN(hPercent))
          oSettings.hPercent = hPercent;
        if (!isNaN(wPercent))
          oSettings.wPercent = wPercent;
      } else if ($(oPanel.cell).find('div[class^="dhxlayout_arrow_h"], div[class*="dhxlayout_arrow_h"]').length > 0) {
        if (!isNaN(wPercent))
          oSettings.wPercent = wPercent;
      } else if (!isNaN(hPercent))
        oSettings.hPercent = hPercent;
      oSettings.collapsed = oPanel.isCollapsed();
    }

    return oSettings;
  },

  /**
   * Method for saving current object settings in vuex store and localhost
   * @param {array} childSizes array containg childs id, height, width
   * @instance
   * @memberof dynObject
   */
  saveUserProfileSettings: function(childSizes) {
    const oSettings = this.getObjectSizes(); // returns sizes for UserSettings object
    if (childSizes)
      oSettings.childSizes = childSizes;

    akioma.VuexStore.dispatch('swat/userprofile/setLayoutObjectSettings', oSettings);
  },

  /**
   * Read/Load the user profile settings for the current object
   * @instance
   * @memberof dynObject
   */
  readUserProfileSettings: function() {
    // check if exists in store
    const cNamespace = this.controller.getObjectsNamespace();
    const oVuexUserProfileSettings = akioma.VuexStore.getters['swat/userprofile/getLayoutObjectSetting'](cNamespace.join('/'));
    return oVuexUserProfileSettings;
  },

  /**
   * Loads the userprofile settings for the current object
   * @instance
   * @memberOf dynObject
   */
  loadUserProfileSettings: function() {
    let oContainer;
    const oSelf = this.controller;
    const oObjSettings = this.readUserProfileSettings();

    if (!oObjSettings)
      return;

    let iDifHeight = 0;

    if (akioma.oWindowsParentCell && akioma.oWindowsParentCell.childs.indexOf(oSelf) != -1)
      iDifHeight = -38;


    // load either for window or for panel control
    try {
      if (oObjSettings.type === 'window') {
        oContainer = oSelf.dhx;
        const iWOnePercent = $(oSelf.parent.dhx.vp).width() / 100,
          iHOnePercent = ($(oSelf.parent.dhx.vp).height() + iDifHeight) / 100;

        if (!isNaN(oObjSettings.wPercent) || !isNaN(oObjSettings.hPercent))
          oContainer.setDimension(oObjSettings.wPercent * iWOnePercent, oObjSettings.hPercent * iHOnePercent);
        if (!isNaN(oObjSettings.xPercent) && !isNaN(oObjSettings.yPercent))
          oContainer.setPosition(oObjSettings.xPercent * iWOnePercent, oObjSettings.yPercent * iHOnePercent);

        if (oObjSettings.maximized)
          oContainer.maximize();
        else
          oContainer.minimize();


      } else {
        const oWin = oSelf.getAncestor('window'),
          aWinDim = oWin.dhx.getDimension(),
          iWOnePercentPanel = aWinDim[0] / 100,
          iHOnePercentPanel = aWinDim[1] / 100;


        oContainer = oSelf.dynObject.getFirstParentByType('panel').dhx;

        const wPercent = oObjSettings.wPercent * iWOnePercentPanel,
          hPercent = oObjSettings.hPercent * iHOnePercentPanel;

        if (!isNaN(wPercent))
          oContainer.setWidth(wPercent);

        if (!isNaN(hPercent))
          oContainer.setHeight(hPercent);

        if (oObjSettings.collapsed)
          oContainer.collapse();
        else
          oContainer.expand();

        if (oObjSettings.childSizes) {
          oObjSettings.childSizes.forEach(element => {
            const cell = oSelf.dhx.topCell.cells(element.id);
            if (!isNull(cell)) {
              cell.setWidth(element.width);
              cell.setHeight(element.height);
            }
          });
        }
      }
    } catch (e) {
      console.warn(`Could not load user profile settings for : ${oSelf.opt.name}`);
    }
  },

  // get value of object
  /**
   * Get value of object
   * @param  {String} name Name of Akioma Control
   * @return {any} returns
   * @example
   * var cEltGewalt = self.getLink("DATA:SRC").getValue("stamm_elt_gewalt")
   */
  getValue: function(name) {
    let oField, oChild, i;
    let cValue;

    switch (this.type) {
      case 'datagrid2':
      case 'datagrid':
        try {
          cValue = this.controller.getFieldValue(name);
          if (!cValue && name.toLowerCase() == 'selfhdl') {
            cValue = this.controller.getFieldValue('SelfHdl');
            if (!cValue) cValue = this.controller.getFieldValue('selfHdl');
            if (!cValue) cValue = this.controller.getFieldValue('selfhdl');

          }
          return cValue;
        } catch (e) {
          akioma.message({ type: 'alert-error', title: 'getValue', text: `Error while getting ${this.type} value for '${name}': ${e.message}` });
        }
        break;
      case 'dataview':
      case 'gridedit':
      case 'treegrid':
      case 'datasource':
      case 'businessEntity':
      case 'ribboncombo':
      case 'toolbarcombo':
        try {
          cValue = this.controller.getFieldValue(name);
          if (!cValue && name.toLowerCase() == 'selfhdl') {
            cValue = this.controller.getFieldValue('SelfHdl');
            if (!cValue) cValue = this.controller.getFieldValue('selfHdl');
            if (!cValue) cValue = this.controller.getFieldValue('selfhdl');

          }
          return cValue;
        } catch (e) {
          akioma.message({ type: 'alert-error', title: 'getValue', text: `Error while getting ${this.type} value for '${name}': ${e.message}` });
        }
        break;
      case 'input':
      case 'inputnum':
      case 'multilist':
      case 'imagebox':
      case 'tokenselect':
      case 'image':
      case 'lookup':
      case 'lookup2':
      case 'dynselect':
      case 'dynlookup':
      case 'translat':
      case 'calendar':
      case 'combobox':
      case 'datagridcol':
        try {
          return this.controller.getValue();
        } catch (e) {
          akioma.message({ type: 'alert-error', title: 'getValue', text: `Error while getting ${this.type} value for '${name}': ${e.message}` });
        }
        break;
      case 'form':
        try {
          oField = this._searchNestedFormElements(this, name);
          if (oField)
            return oField.controller.getValue();
          if (this.controller.dataSource == undefined && this.controller.formStore) {

            const oItem = this.controller.formStore.item(this.controller.formStore.getCursor());
            if (oItem[name])
              return oItem[name];
            else
              return '';
          }
          return null;
        } catch (e) {
          akioma.message({ type: 'alert-error', title: 'getValue', text: `Error while getting ${this.type} value for '${name}': ${e.message}` });
        }
        break;
      case 'toolbar':
        try {
          oField = this.childs[name];
          return oField.controller.getFieldValue('value');
        } catch (e) {
          akioma.message({ type: 'alert-error', title: 'getValue', text: `Error while getting ${this.type} value for '${name}': ${e.message}` });
        }
        break;
      case 'ribbon':
        return this.getObject2(`${this.controller.ribbonMenuID}_${name}`).getValue();
      case 'popup':
      case 'window':
        try {
          for (i in this.childs) {
            oChild = this.childs[i];
            if (oChild.childs[name])
              return oChild.childs[name].getValue();
          }
        } catch (e) {
          akioma.message({ type: 'alert-error', title: 'getValue', text: `Error while getting ${this.type} value for '${name}': ${e.message}` });
        }
        break;
      default:
        try {
          if (!isNull(this.controller))
            return this.controller.value;
        } catch (e) {
          akioma.message({ type: 'alert-error', title: 'getValue', text: `Error while getting ${this.type} value for '${name}': ${e.message}` });
        }
        break;
    }
    return null;
  },

  // set value of object
  /**
   * Set value of Object
   * @param {string} name Name of Akioma Object
   * @param {any} value Value of Akioma Object
   * @example
   * self.setValue('name', 'Akioma');
   */
  setValue: function(name, value) {
    let oField, oChild;
    switch (this.type) {
      case 'businessEntity':
        try {
          return this.controller.setFieldValue({
            name: name,
            value: value,
            state: 'updated'
          });
        } catch (e) {
          akioma.message({ type: 'alert-error', title: 'setValue', text: `Error while getting ${this.type} value for '${name}': ${value} -> ${e.message}` });
        }
        break;
      case 'datasource':
        try {
          return this.controller.setFieldValue({
            name: name,
            value: value,
            state: 'U'
          });
        } catch (e) {
          akioma.message({ type: 'alert-error', title: 'setValue', text: `Error while getting ${this.type} value for '${name}': ${value} -> ${e.message}` });
        }
        break;
      case 'datagrid':
      case 'dataview':
      case 'gridedit':
      case 'toolbar':
        try {
          return this.controller.setFieldValue(name, value);
        } catch (e) {
          akioma.message({ type: 'alert-error', title: 'setValue', text: `Error while getting ${this.type} value for '${name}': ${value} -> ${e.message}` });
        }
        break;
      case 'form':
        try {
          oField = this._searchNestedFormElements(this, name);
          if (oField)
            oField.controller.setValue(value);
          if (this.controller.dataSource == undefined && this.controller.formStore) {
            const oNew = this.controller.formStore.item(this.controller.formStore.getCursor());
            oNew[name] = value;
            this.controller.formStore.update(this.controller.formStore.getCursor(), oNew);
          }
        } catch (e) {
          akioma.message({ type: 'alert-error', title: 'setValue', text: `Error while setting ${this.type} value for '${name}': ${e.message}` });
        }
        break;
      case 'popup':
      case 'window':
        try {
          for (const i in this.childs) {
            oChild = this.childs[i];
            if (oChild[name])
              oChild[name].setValue(value);
          }
        } catch (e) {
          akioma.message({ type: 'alert-error', title: 'setValue', text: `Error while setting ${this.type} value for '${name}': ${e.message}` });
        }
        break;
      default:
        try {
        // set value and trigger blur
          if (arguments.length < 2)
            value = name;
          this.controller.setValue(value);
        } catch (e) {
          akioma.message({ type: 'alert-error', title: 'setValue', text: `Error while setting ${this.type} value for '${name}': ${e.message}` });
        }
        break;
    }
    return null;
  },

  // get property value
  getPropValue: function(cName) {
    // check if property available
    if (this.controller.opt[cName])
      return this.controller.opt[cName];
    // return empty sring
    return '';
  },

  getFieldValues: function() {
    const form = (this.type === 'form' ? this : this.parent),
      ret = {};

    addChildValues(form);

    return ret;

    function addChildValues(parent) {
      for (const key in parent.childs) {
        const child = parent.childs[key];

        switch (child.type) {
          case 'fieldset':
          case 'block':
            addChildValues(child);
            break;

          case 'button':
            break;

          default:
            ret[key] = child.getValue();
            break;
        }
      }
    }
  },

  // get all values
  getAllValues: function(oElm, cReturnType) {
    const aValue = [];

    if (!cReturnType)
      cReturnType = 'string';

    // check if we have a target
    if (!oElm)
      oElm = (this.type == 'form') ? this : this.parent;


    // before starting -> update all values to get them in dhtmlx form fields
    oElm.controller.dhx._updateValues();

    // get values of all fields
    addChildValues(oElm);

    if (cReturnType == 'string')
      return aValue.join('|');
    else
      return aValue;

    function addChildValues(poParent) {
      for (const cKey in poParent.childs) {
        const oChild = poParent.childs[cKey];
        switch (oChild.type) {
          case 'fieldset':
          case 'block':
            addChildValues(oChild);
            break;
          case 'checkbox':
            aValue.push(`${cKey}#character#${oChild.getValue()}`);
            break;
          case 'button':
            break;
          default:
            aValue.push(`${cKey}#${oChild.controller.opt.dataType}#${oChild.getValue()}`);
            break;
        }
      }
    }

  },

  // get a link object
  /**
   * Get a link object
   * @param  {string} cLink String separated with ":" including link type and SRC/TRG
   * @return {dynObject}
   * @example
    * var oDataSource = self.getLink("DATA:SRC");
  */
  getLink: function(cLink) {
    const ret = this.getLinks(cLink);
    if (!ret)
      return null;
    return ret[0];
  },

  getLinks: function(cLink) {
    // for field -> use parent for getting link
    if (this.controller.useParentDynObjectLink)
      return this.parent.getLinks(cLink);

    // MLi: quick hack to support ribbon instead of toolbar
    if (this.controller.view == 'ribbonblock')
      return this.parent.parent.parent.getLinks(cLink);
    else if (this.controller.view == 'ribbontab')
      return this.parent.parent.getLinks(cLink);

    // the link has to be checked via messenger
    // check for link
    const aLink = cLink.split(':');
    if (aLink.length != 2) {
      !_isIE && console.error([ 'Error in getLink: wrong link', cLink, aLink ]);
      return null;
    }

    // change source or target
    aLink[1] = (aLink[1] == 'TARGET') ? 'TRG' : 'SRC';

    // check if we are the correct object -> if error, use parent
    const oTarget = (this.controller.links) ? this : this.parent;

    // get complete string for link
    const cObjName = oTarget.controller.getObjName(aLink[0], (aLink[1] == 'TRG') ? 'SRC' : 'TRG');
    cLink = `${aLink.join(':')}:${cObjName}`;

    // now we can get object
    const aElm = app.messenger.getObjects(oTarget, cLink);

    // check if object is valid
    if (!aElm || aElm.length == 0)
      return null;

    return aElm;
  },

  // get a specific field (or array of fields)
  getLinkValue: function(cLink, cName) {
    const oElm = this.getLink(cLink);

    if (oElm)
      return oElm.getValue(cName);
    else
      akioma.message({ type: 'alert-error', title: 'getLinkValue', text: `Error getting a link value: ${cLink} - ${cName}` });

  },

  // get a specific field (or array of fields)
  getField: function(name) {
    const oFields = new Array();

    switch (this.type) {
      case 'form':
        try {
        // checks for elements, checks fieldsets,blocks containers for nested elements
          return this._searchNestedFormElements(this, name);
        } catch (e) {
          akioma.message({ type: 'alert-error', title: 'getField', text: `Error while getting ${this.type} field for '${name}': ${e.message}` });
        }
        break;
      case 'toolbar':
        try {
          return this.childs[name];
        } catch (e) {
          akioma.message({ type: 'alert-error', title: 'getField', text: `Error while getting ${this.type} field for '${name}': ${e.message}` });
        }
        break;
      case 'popup':
      case 'window':
        try {
          for (const i in this.childs) {
            const oChild = this.childs[i];
            if (oChild[name])
              oFields.push(oChild[name]);
          }
        } catch (e) {
          akioma.message({ type: 'alert-error', title: 'getField', text: `Error while getting ${this.type} field for '${name}': ${e.message}` });
        }
        break;
      default:
        akioma.message({ type: 'alert-error', title: 'getField', text: `Error: getting ${this.type} field for '${name}' not allowed` });
        break;
    }
    return oFields;
  },

  getSrvProp: function(cName) {
    switch (this.type) {
      case 'businessEntity':
      case 'datasource':
        return this.controller.getSrvProp(cName);
      default:
        !_isIE && console.warn([ 'Error getting server property for', this.type ]);
        break;
    }
  },

  setSrvProp: function(cName, cValue) {
    switch (this.type) {
      case 'businessEntity':
      case 'datasource':
        return this.controller.setSrvProp(cName, cValue);
      default:
        !_isIE && console.warn([ 'Error setting server property for', this.type ]);
        break;
    }
  },

  delSrvProp: function(cName) {
    switch (this.type) {
      case 'businessEntity':
      case 'datasource':
        return this.controller.delSrvProp(cName);
      default:
        !_isIE && console.warn([ 'Error setting server property for', this.type ]);
        break;
    }
  },

  subscribe: function(cLink) {
    app.messenger.subscribe(this, cLink);
  },

  openQuery: function(oElm) {
    return this.controller.openQuery(oElm);
  },
  setQueryParam: function(oElm) {
    return this.controller.setQueryParam(oElm);
  },

  callCode: function(cCode, oLaunchedFrom) {
    const self = this,
      oSelf = this.controller;

    // decide if it's server or client code
    if (cCode.substr(0, 1) == '$')
      akioma.swat.evaluateCode({ code: cCode, controller: oSelf, dynObj: self, launchedFrom: oLaunchedFrom, catchError: true });
    else {
      try {
        const aCode = eval(`[${cCode}]`);
        app.controller.callServerMethod(aCode[0], aCode[1]);
      } catch (e) {
        !_isIE && console.warn([ 'Error executing akioma code', oSelf, cCode, e ]);
        akioma.message({ type: 'alert-error', title: 'callCode', text: `Error executing akioma code: <br />${e.message}<br />${cCode}` });
      }
    }
  },

  deleteObject: function() {

    // delete from children of parents
    if (this.parent && this.parent.childs[this.name]) {
      this.parent.childs[this.name] = null;
      $.removeEntry(this.parent.childs, this.parent.childs[this.name]);
    }

    this.controller = null;
    this.name = null;
    this.SDO = null;
    this.type = null;
    this.container = null;
    this.parent = null;
    this.childs = null;
    this.dataSource = null;
    this.links = null;
  }
};
