/**
 * Class for context menu on Grid type Objects
 * @class GridContextMenu
 * @namespace akioma
 */
akioma.GridContextMenu = class {
  constructor(grid, pointerId) {
    this._pointerId = pointerId;
    this._grid = grid;
    this._dhxMenu = null;
    this._attachContextMenu();
  }

  get menu() {
    return this._dhxMenu;
  }

  set menu(menuObj) {
    this._dhxMenu = menuObj;
  }

  /**
     * Method to attach context menu on grid object
     * @instance
     * @private
     * @memberof GridContextMenu
     */
  _attachContextMenu() {
    const oGrid = this._grid.dhx;
    const cPointer = this._pointerId;
    const template = `<i class="fa fa-ellipsis-v more-grid-btn" 
                            id="${cPointer}" 
                            akid="${(`${this._grid.akId}-${this._grid.opt.contextMenu}`)}">
                        </i>`;
    $(oGrid.entBox).after(template);

    this._attachClickEvent();
    this._attachHoverEvents();
  }

  /**
     * Method to bind context menu mouse enter/leave events
     * @private
     * @instance
     * @memberof GridContextMenu
     */
  _attachHoverEvents() {
    const oGrid = this._grid.dhx;
    const cPointer = this._pointerId;

    oGrid.attachEvent('onMouseOver', (id, ind) => {
      // position pointer here
      const $pointer = $(`#${cPointer}`);
      if ($pointer.length > 0) {

        const oCell = oGrid.cells(id, ind);
        let iExtra = 0;

        if (this._grid.cSearchInputID)
          iExtra += $(`#${this._grid.cSearchInputID}`).height();


        const iTop = $(oGrid.hdr).height() + iExtra + $(oCell.cell).parent()[0].offsetTop + ($(oCell.cell).parent().height() / 2) - $(oCell.cell).closest('.objbox').scrollTop();

        $pointer.attr('rowid', id).css({ 'top': iTop }).show();
      }
    });

    $(oGrid.entBox).parent().on('mouseleave', () => {
      $(`#${cPointer}`).hide();
    });
  }

  /**
     * Method used for creating the Context Menu object and applying the click event
     * @param {object}  menu
     * @param {array} menuItems
     */
  _renderMenu(menu, menuItems) {

    const $this = $(`#${this._pointerId}`);
    const oSelf = this._grid;

    if (menu.aMenuStructure.eventPre)
      applyAkiomaCode(menu, menu.aMenuStructure.eventPre);

    const oContextMenu = new dhtmlXMenuObject({ context: true });

    oContextMenu.setIconset('awesome');
    oContextMenu.loadStruct(menuItems);
    oContextMenu.renderAsContextMenu();

    oContextMenu.attachEvent('onClick', this._onContextMenuButtonClick.bind(this));

    this.menu = oContextMenu;

    const oElm = {
      x: $(oSelf.dhx.entBox).offset().left,
      y: $this.offset().top
    };

    this.menu.showContextMenu($this.offset().left, oElm.y);

    /**
         * Client side code to run when context menu is initialized has been initialized
         * @event ak_datagrid#EventOnContextMenuOpen
         * @event ak_treegrid#EventOnContextMenuOpen
         * @event ak_propertygrid#EventOnContextMenuOpen
         * @type {object}
         */
    if (oSelf.opt.EventOnContextMenuOpen)
      app.controller.callAkiomaCode(oSelf, oSelf.opt.EventOnContextMenuOpen);

  }

  /**
     * Method called after rendering the context menu for Grid object
     * @instance
     * @private
     * @memberof GridContextMenu
     */
  _onAfterContextMenuCreate() {
    const oSelf = this._grid;

    if (oSelf.menuContextLoaded == undefined) {
      oSelf.menuContextLoaded = true;

      this.mainMenu.scanMenuItemsObj(item => {
        this.mainMenu.aMainMenuOptions.push({ id: item.id, code: item.code, img: item.icon, text: item.label, hidden: item.hidden });
        this.mainMenu.aMainMenuOptionsOriginal.push({ id: item.id, code: item.code, img: item.icon, text: item.label, hidden: item.hidden });
      });

      this._renderMenu(this.mainMenu, this.mainMenu.aMainMenuOptions);
    } else {
      this.mainMenu.aMainMenuOptions = this.mainMenu.aMainMenuOptionsOriginal.slice(0);
      this._renderMenu(this.mainMenu, this.mainMenu.aMainMenuOptions);
    }

  }

  /**
     * Method called on Context menu button click
     * @instance
     * @memberof GridContextMenu
     * @private
     * @param {string} menuitemId
     */
  _onContextMenuButtonClick(menuitemId) {
    this.mainMenu.applyAction(menuitemId, this._grid);
    return true;
  }

  /**
     * Method for attaching a context menu click event on the pointer object
     * @instance
     * @memberof GridContextMenu
     * @private
     */
  _attachClickEvent() {
    const oSelf = this._grid;
    const oGridContextMenu = this;
    const oGrid = this._grid.dhx;

    const cPointer = this._pointerId;

    $(`#${cPointer}`).on('click', function(e) {

      e.preventDefault();

      const $this = $(this);


      // clicking grid row first
      oGrid.selectRowById($this.attr('rowid'), false, true, true);

      if (oGridContextMenu.menu)
        oGridContextMenu._onAfterContextMenuCreate();
      else {
        const cID = oSelf.opt.contextMenu;

        oGridContextMenu.mainMenu = app.controller.parseProc({
          view: 'menustructure',
          att: { id: cID },
          sub: []
        }, oSelf);

        oGridContextMenu.mainMenu.aMainMenuOptionsOriginal = [];
        oGridContextMenu.mainMenu.aMainMenuOptions = [];
        oGridContextMenu.mainMenu.loadMenuElements(oGridContextMenu._onAfterContextMenuCreate.bind(oGridContextMenu), false, true);
      }

      return false;

    });
  }

  /**
     * Method for getting the context menu item by code
     * @instance
     * @memberof GridContextMenu
     */
  getContextMenuItemByCode(code) {
    const menuItem = Object.values(this.mainMenu.aMenuItems).find(menuitem => menuitem.code === code);
    return menuItem;
  }

  /**
     * Method for showing a given context menu item
     * @param {string} itemCode Menu structure code for showing the item
     * @instance
     * @memberof GridContextMenu
     */
  showContextMenuItem(itemCode) {
    const menuItem = this.getContextMenuItemByCode(itemCode);
    if (menuItem)
      this.menu.showItem(menuItem.id);

  }

  /**
     * Method for hiding a given context menu item
     * @param {string} itemCode Menu structure code for hiing the item
     * @instance
     * @memberof GridContextMenu
     */
  hideContextMenuItem(itemCode) {
    const menuItem = this.getContextMenuItemByCode(itemCode);
    if (menuItem)
      this.menu.hideItem(menuItem.id);

  }

  /**
     * Method for showing all context menu items
     * @instance
     * @memberof GridContextMenu
     */
  showContextMenuItems() {
    this.menu.show();
  }

  /**
     * Method for hiding all context menu items
     * @instance
     * @memberof GridContextMenu
     */
  hideContextMenuItems() {
    this.menu.hide();
  }

  destroy() {
    this.menu.unload();
    this.mainMenu.destroy();
    delete this.mainMenu;
  }
};
