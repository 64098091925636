app.toolbar = {
  getDynCombo: function() {

    const oSelf = this,
      cObjName = this.opt.objectName,
      cBEVals = this.opt.ListItemPairs;
    if (cBEVals != undefined && cBEVals[0] == '$') {
      // loads data JSDO
      LoadDataHelper.loadData(cBEVals, oSelf, data => {
        const aData = data,
          aOpt = [{ hdl: '', value: '' }];

        for (let i = 0; i < aData.length; i++) {
          aOpt.push({
            hdl: aData[i].selfhdl,
            value: aData[i].selfdesc
          });
        }


        if (aOpt.length > 0) {
          oSelf.setComboOptions(aOpt);
          dhtmlx.delay(() => {
            oSelf.click(aOpt[0].hdl);
          });
        }

      });
    } else {
      $.ajax({
        type: 'POST',
        async: false,
        url: `/akioma/getCombo.xml?Type=ComboToolbar&objectName=${cObjName}`,
        dataType: 'text',
        success: function(data) {
          const oData = data;

          const oElm = $(oData).find('option'),
            aOpt = [{ hdl: '', value: '' }];

          oElm.each((index, elm) => {
            aOpt.push({
              hdl: $(elm).attr('value'),
              value: $(elm).text()
            });
          });

          if (aOpt.length > 0) {
            oSelf.setComboOptions(aOpt);
            dhtmlx.delay(() => {
              oSelf.click(aOpt[0].hdl);
            });
          }
        },
        error: function(xhr, textStatus, errorThrown) {
          akioma.log.error(`Error loading combo '${textStatus}': ${errorThrown}`, 3);
        }
      });
    }
  },

  // select default language
  selDefLng: function() {
    this.click(app.sessionData.dataLanguage);
  },

  // get pin mode
  getPinMode: function(oSelf) {

    if (app.sessionData.textPin)
      oSelf.parent.dhx.setItemState(oSelf.opt.name, true);

  },

  // text pin
  textPin: function(oSelf) {
    const oTool = oSelf.parent.dynObject;
    if (oTool) {
      const cLang = oTool.getValue('textLang');
      if (oSelf.parent.dhx.getItemState(oSelf.opt.name)) {
        app.sessionData.textPin = true;
        app.sessionData.textLang = cLang;
      } else {
        app.sessionData.textPin = false;
        app.sessionData.textLang = null;
      }
    }

  }
};
