/**
 * Function: isShapeEvent
 *
 * Returns true if the event is for the shape of the given state. This
 * implementation always returns true.
 *
 * Parameters:
 *
 * state - <mxCellState> whose shape fired the event.
 * evt - Mouse event which was fired.
 */
mxCellRenderer.prototype.isShapeEvent = function(state, evt) {
  return $(evt.target).closest('div[akloadingwindow="true"]').length === 0;
};

/**
 * Function: image
 *
 * Private helper function to create SVG elements, overwritten to support SVG sprites in mxClient
 * Extended to support font awesome icons in diagram
 */
mxSvgCanvas2D.prototype.image = function(x, y, w, h, src, aspect, flipH, flipV, cell = {}) {

  src = src.replace('...', '');

  // for connector replace with arrow hardcoded
  if (src.endsWith('connector.gif')) {
    cell = {
      icon: akioma.repository.LayoutDesigner.icons.diagramLinkConnector.icon,
      style: { customStyle: akioma.repository.LayoutDesigner.icons.diagramLinkConnector.style }
    };
    src = cell.icon;
  }

  const split = src.split(' ');
  const srcFirstClass = split[0];

  if (srcFirstClass !== 'fad')
    src = this.converter.convert(src);

  // LATER: Add option for embedding images as base64.
  aspect = (aspect != null) ? aspect : true;
  flipH = (flipH != null) ? flipH : false;
  flipV = (flipV != null) ? flipV : false;

  const s = this.state;
  x += s.dx;
  y += s.dy;

  let node;
  // check for fad font awesome support
  if (srcFirstClass === 'fad')
    node = this.createElement('svg');
  else
    node = this.createElement('image');

  node.setAttribute('x', this.format(x * s.scale) + this.imageOffset);
  node.setAttribute('y', this.format(y * s.scale) + this.imageOffset);
  node.setAttribute('width', this.format(w * s.scale));
  node.setAttribute('height', this.format(h * s.scale));

  // Workaround for missing namespace support
  if (srcFirstClass !== 'fad') {
    if (node.setAttributeNS == null)
      node.setAttribute('xlink:href', src);
    else
      node.setAttributeNS(mxConstants.NS_XLINK, 'xlink:href', src);

  }

  const map = {
    'fad': 'duotone',
    'fas': 'solid',
    'far': 'regular'
  };

  if (srcFirstClass === 'fad') {
    const filename = map[split[0]];
    const fontsPath = `/fonts/font_awesome_pro/sprites/${filename}.svg`;
    split.splice(1).forEach(className => {
      const use = this.createElement('use');
      use.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `${fontsPath}#${className.replace('fa-', '')}`);
      node.appendChild(use);
    });
  }

  if (cell && cell.style)
    node.setAttribute('style', cell.style.customStyle);


  if (!aspect)
    node.setAttribute('preserveAspectRatio', 'none');


  if (s.alpha < 1 || s.fillAlpha < 1)
    node.setAttribute('opacity', s.alpha * s.fillAlpha);


  let tr = this.state.transform || '';

  if (flipH || flipV) {
    let sx = 1;
    let sy = 1;
    let dx = 0;
    let dy = 0;

    if (flipH) {
      sx = -1;
      dx = -w - 2 * x;
    }

    if (flipV) {
      sy = -1;
      dy = -h - 2 * y;
    }

    // Adds image tansformation to existing transform
    tr += `scale(${sx},${sy})translate(${dx * s.scale},${dy * s.scale})`;
  }

  if (tr.length > 0)
    node.setAttribute('transform', tr);


  if (!this.pointerEvents)
    node.setAttribute('pointer-events', 'none');


  this.root.appendChild(node);
};


/**
 * Function: paintVertexShape
 *
 * Generic background painting implementation, method overwrite to pass image object
 */
mxImageShape.prototype.paintVertexShape = function(c, x, y, w, h) {
  if (this.image != null) {
    const fill = mxUtils.getValue(this.style, mxConstants.STYLE_IMAGE_BACKGROUND, null);
    let stroke = mxUtils.getValue(this.style, mxConstants.STYLE_IMAGE_BORDER, null);

    if (fill != null) {
      // Stroke rendering required for shadow
      c.setFillColor(fill);
      c.setStrokeColor(stroke);
      c.rect(x, y, w, h);
      c.fillAndStroke();
    }

    // FlipH/V are implicit via mxShape.updateTransform
    c.image(x, y, w, h, this.image, this.preserveImageAspect, false, false, this);

    stroke = mxUtils.getValue(this.style, mxConstants.STYLE_IMAGE_BORDER, null);
    if (stroke != null) {
      c.setShadow(false);
      c.setStrokeColor(stroke);
      c.rect(x, y, w, h);
      c.stroke();
    }
  } else
    mxRectangleShape.prototype.paintBackground.apply(this, arguments);

};
