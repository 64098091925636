akioma.registerMruEntry = function(cHdl) {
  akioma.invokeServerTask({
    name: 'Akioma.Swat.System.MruBT',
    methodName: 'RegisterRecord',
    paramObj: { plcParameter: { EntryHdl: cHdl } }
  }).done(oRes => {
    akioma.log.info(`Registered new MRU entry! (${cHdl} -> ${oRes.EntryHdl} => ${oRes.NewHdl})`);
    akioma.swat.RefreshEmitter.refreshData('eMru*', { lastRowState: 'add', lastUpdatedRecord: { selfhdl: oRes.NewHdl } });
  }).fail(() => {
    akioma.log.error(`Error registering entry in MRU list! (${cHdl})`);
  });
};

akioma.registerMruEntryFromBE = function(oSelf) {
  const oController = isNull(oSelf.controller) ? oSelf : oSelf.controller;
  akioma.registerMruEntry(oController.getIndex());
};
