

// *************** portal ***********************
$.extend({
  ak_portal: function(options) {
    const defaults = {
      label: '',
      height: 300,
      width: 500,
      collapsed: false
    };

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;

    // get portal
    const oPortalset = this.parent.dhx;
    if (oPortalset) {

      try {
        // get portal
        let cPortalID = this.opt.layout;
        if (cPortalID == '2E')
          cPortalID = 'a';

        const oPortal = oPortalset.cells(cPortalID);
        if (oPortal) {
          // check for header
          if (this.opt.label)
            oPortal.setText(this.opt.label);
          else
            oPortal.hideHeader();


          $.extend(this, { dhx: oPortal });

        } else {
          akioma.message({ type: 'alert-error', title: `Invalid Portal: ${this.opt.layout}`, text: `Error creating Portal:<br />Name: ${this.parent.opt.name}<br />` });
          this.cancel = true;
        }
      } catch (e) {
        !_isIE && console.error(`Error creating Portal ${this.opt.label} (${this.opt.layout})`, e.message);
        akioma.message({ type: 'alert-error', title: `Create Portal ${this.opt.layout}`, text: `Error creating Portal:<br />Name: ${this.parent.opt.name}<br />${e.message}` });
        this.cancel = true;
      }
    } else {
      !_isIE && console.error(`No valid parent for Portal ${this.opt.label} (${this.opt.layout})`);
      this.cancel = true;
    }
  }
});

// methods for Portal
$.ak_portal.prototype = {
  finishConstruct: function() {

    const oPortal = this.dhx;
    if (this.opt.hideHeader)
      oPortal.hideHeader();

    // if height or width is available -> set it
    if (this.opt.collapsed)
      oPortal.collapse();

  },

  endConstruct: function() { },

  setOption: function(option, value) {

    this.opt[option] = value;

    const oPortal = this.dhx;
    const oPortalset = this.parent.dhx;

    if (oPortal && oPortalset) {
      switch (option) {
        case 'title':
          oPortal.showHeader();
          oPortal.setText(value);
          break;
        case 'height':
          break;
        case 'width':
          break;
        case 'attachObject':
          oPortal.attachObject(value);
          break;
        case 'treeCont':
          this.prop.treeCont = value;
          break;
      }
    }
    return this;
  },

  // hide header
  hideHeader: function() {
    this.dhx.hideHeader();
  },

  // show header
  showHeader: function() {
    this.dhx.showHeader();
  },

  // attach object
  attachObject: function(oNode) {
    // get element
    const oPortal = this.dhx;
    if (oPortal)
      oPortal.attachObject(oNode);
  },

  // attach context menu
  attachContextMenu: function() {

    // add id to header
    $(this.dhx).find('div > div:first').attr('id', `Container${this.opt.id}`);

    // menu on header
    const oSelf = this,
      oMenu = new dhtmlXMenuObject();
    oMenu.renderAsContextMenu();
    // dhx4 modif below
    if (document.getElementById(`Container${this.opt.id}`) == null)
      akioma.log.error('attempt to init context menu on layout\'s header, fix me later please, #1837372');
    else
      oMenu.addContextZone(`Container${this.opt.id}`);

    oMenu.addNewChild(oMenu.topId, 0, 'saveProfile', 'Save Detail profile');
    oMenu.attachEvent('onClick', id => {
      oSelf.contextMenu(id);
    });
  },

  // context menu
  contextMenu: function() {
    // check for first frame
    if (this.childs[0] && this.childs[0].view == 'frame')
      this.childs[0].saveProfile();

  },

  toggleShowHide: function() {
    // get element
    const oPortal = this.dhx;
    if (oPortal) {
      if (oPortal.isCollapsed())
        oPortal.expand();
      else
        oPortal.collapse();
    }
  },

  // load content in container
  loadContent: function(elm) {

    // check if object in frame already loaded
    if (this.prop.progName != elm.progName) {

      // load frame in container
      app.controller.launchContainer({
        target: this,
        proc: 'include.r',
        para: `RunFile=${elm.progName}&Page=0,1&TypeKey=${elm.typeKey}&Datasource=${elm.dataSource}&selfHdl=${elm.rowId}`,
        self: elm.self
      });

      this.prop.progName = elm.progName;
    }
  },

  // show data in content
  showContData: function(elm) {
    if (this.childs && this.childs.length > 0) {
      const oFrame = this.childs[0];

      // if datasource exists
      let oSource = oFrame.getDataSource();
      if (oSource) {
        oSource = oSource.controller;

        let oMode = null;
        if (elm.self.mode[elm.rowId])
          oMode = elm.self.mode[elm.rowId];

        if (oMode && oMode.recMode == 'add') {
          oSource.addRecord({
            extLink: elm.rowId,
            caller: elm.self,
            mode: oMode
          });
        } else {
          oSource.openQuery({
            foreignKey: elm.rowId,
            caller: elm.self,
            mode: oMode
          });
        }
      }
    }

  },

  // execute option
  execOpt: function(type, elm) {

    switch (type) {

      // delete contents in container
      case 'deleteCont':
        // destroy children
        if (this.childs.length > 0) {
          this.childs[0].destroy();
          delete this.prop.progName;
        }
        break;

        // open container
      case 'openCont':
        // save content data
        this.prop.contData = elm;

        // check if Portal is collapsed -> save status
        if (this.dhx.isCollapsed()) {

          // save info
          this.prop.contInfo = elm;

        } else {

          // check if object in frame already loaded
          this.loadContent(elm);

          // set actual row as external link
          this.showContData(elm);
        }
        break;

        // expand -> load container and/or data
      case 'expand':
        // check if something has changed
        if (this.prop.contInfo) {

          // check if we have to load container
          if (this.prop.progName != this.prop.contInfo.progName)
            this.loadContent(this.prop.contInfo);

          // load data
          this.showContData(this.prop.contInfo);

          // delete info
          delete this.prop.contInfo;

        }
        this.parent.dhx.profressOff();
    }
  }
};
