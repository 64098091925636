// fileSelect form control

window.dhx4.template.printFileEntry = function(value, filename) {
  let sResult = '';
  if (value)
    sResult = `<div class="file-entry doc"><img alt="icon" src="${value}" /><p>${filename}</p><a class="close" data-key="${value}" href="#"></a></div>`;

  return sResult;
};

window.dhx4.template.printImgEntry = function(value, filename) {
  let sResult = '';
  if (value)
    sResult = `<div class="file-entry"><p>${filename}</p><img width="140" class="img-entry" src="${value}" /><a class="close" data-key="${value}" href="#"></a></div>`;

  return sResult;
};

dhtmlXForm.prototype.items.fileselect = {
  render: function(item, data) {
    item._type = 'fileselect';

    const oSelf = $.getObjectByName({ id: data.userdata.id });
    item._multiselect = oSelf.opt.multiSelect;
    this.doAddLabel(item, data);

    const html = '<div class="dhxform_control" style="left:#left#;top:#top#;width:#width#;">' +
                  '<div style="width:#contWidth#;" class="akTokenSelectItems"></div>' +
                  '<a href="#" class="akTokenSelectBtn">' +
                      '<img src="/imgs/akioma/activity_feed-26.png" height="14" width="14" />' +
                  '</a>' +
                '</div>';
    const values = {
      left: oSelf.opt.left,
      top: oSelf.opt.top,
      width: oSelf.opt.width,
      contWidth: `${parseInt(oSelf.opt.width) - 25}px`
    };

    const template = window.dhx4.template(html, values);
    $(template).appendTo(item)
      .find('a').on('click', () => {
        oSelf.eventClick();
        return false;
      })
      .end()
      .on('click', '.close', function() {
        const sKey = $(this).attr('data-key'),
          aKeysSelected = item._value.split(' '),
          index = aKeysSelected.indexOf(sKey);

        aKeysSelected.splice(index, 1);
        item._value = aKeysSelected.join(' ');

        $(this).parent().remove();

        return false;
      });


    item.akElm = oSelf;
    oSelf.dhx = item;

    return this;
  },

  setValue: function(item, value) {
    let aKeys;
    if (item._multiselect)
      aKeys = value.split(' ');
    else {
      aKeys = [];
      aKeys[0] = value;
    }
    let htmlEntries = '';
    for (const i in aKeys) {
      if (aKeys[i].length > 0) {
        const filename = aKeys[i].replace(/^.*[\\/]/, '');
        const ext = filename.substr(filename.lastIndexOf('.') + 1);

        let html, values;
        switch (ext.toLowerCase()) {
          case 'jpg':
          case 'jpeg':
          case 'giff':
          case 'png':
            html = `#value|printImgEntry:${filename}#`;
            values = { value: value, filename: filename };
            break;
          case 'doc':
          case 'docx':
            html = `#value|printFileEntry:${filename}#`;
            values = { value: '/imgs/filetypes/ico_doc_48.gif', filename: filename };
            break;
          case 'xls':
            html = `#value|printFileEntry:${filename}#`;
            values = { value: '/imgs/filetypes/ico_xls_48.gif', filename: filename };
            break;
          default:
            html = `#value|printFileEntry:${filename}#`;
            values = { filename: filename };
        }
        const itemHTML = window.dhx4.template(html, values);
        htmlEntries += itemHTML;
      }
    }
    $(item).find('.akTokenSelectItems').empty().append(htmlEntries);
    item._value = value;
  },
  getValue: function(item) {
    return item._value;
  },
  getFileSelect: function(item) {
    return item;
  },
  setKeyValues: function(item, value) {
    // console.log('set key values',item, value);
    item._keyvalues = [];
    const aVals = value.split(',');
    for (let i = 0; i < aVals.length; i += 2)
      item._keyvalues[aVals[i + 1]] = aVals[i];

  }
};

(function() {
  for (const a in { doAddLabel: 1, doAddInput: 1, destruct: 1, doUnloadNestedLists: 1, setText: 1, getText: 1, enable: 1, disable: 1, setWidth: 1 })
    dhtmlXForm.prototype.items.fileselect[a] = dhtmlXForm.prototype.items.input[a];
})();

dhtmlXForm.prototype.setKeyValues = function(name, value) {
  this.doWithItem(name, 'setKeyValues', value);
};

dhtmlXForm.prototype.getFileSelect = function(name) {
  return this.doWithItem(name, 'getFileSelect');
};

dhtmlXForm.prototype.setFormData_fileselect = function(name, value) {
  return this.doWithItem(name, 'setValue', value);
};

dhtmlXForm.prototype.getFormData_fileselect = function(name) {
  return this.doWithItem(name, 'getValue');
};

(function($) {

  // ********************* file select ********************
  $.extend({
    ak_fileSelect: function(options) {
      const defaults = {};

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;

      this.useParentDynObjectLink = true;
      this.registerDynObject = true;

      // get parent
      const oParent = this.parent;
      if (oParent) {

        oParent.prop.fields.push({
          type: 'fileselect',
          inputTop: parseInt(this.opt.top),
          inputLeft: parseInt(this.opt.left),
          inputWidth: parseInt(this.opt.width),
          label: this.opt.label,
          labelTop: parseInt(this.opt.top),
          labelLeft: oParent.labelLeft(this),
          name: this.opt.name,
          readonly: this.opt.readonly,
          position: (this.opt.positionMode != undefined) ? this.opt.positionMode : oParent.opt.positionMode,
          className: 'w4-formField',
          userdata: { id: this.opt.id }
        });

        // append to elements in form
        if (this.parent.view == 'form')
          this.parent.elements.push(this);

        // oInput.akElm = this;
        $.extend(this, { security: {} });
      }
    }
  });

  // methods for tokenselect
  $.ak_fileSelect.prototype = {
    // finish construct **********
    finishConstruct: function() {
      // get field
      this.form = this.parent.dhx;
    },

    // get value *****************
    getValue: function() {
      return this.form.getItemValue(this.opt.name);
    },

    // set value ********************
    setValue: function(cValue) {
      this.form.setItemValue(this.opt.name, cValue);
    },

    // event leave *******************
    eventLeave: function() {
      // check if we have to call the leave event
      if (this.opt.leaveEvent)
        app.controller.callAkiomaCode(this, this.opt.leaveEvent);
    },

    // event click *******************
    eventClick: function() {
      const oPara = {},
        oForm = this.dynObject.parent.controller,
        cName = (this.opt.keyField) ? this.opt.keyField : this.opt.name;

      let cIndex = '';
      if (oForm.dataSource)
        cIndex = oForm.dataSource.getIndex();
      oPara.refHdl = cIndex;
      oPara.fieldName = cName;

      app.controller.launchContainer({
        proc: 'choosefileg.r',
        para: '',
        data: true,
        self: oForm
      });
    }
  };

})(jQuery, jQuery);
