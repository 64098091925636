// global context function
// eslint-disable-next-line no-unused-vars
function getValidLayoutPatterns(self) {
  const aData = [
    { 'hdl': '1C', 'value': '1C' },
    { 'hdl': '2E', 'value': '2E' },
    { 'hdl': '2U', 'value': '2U' },
    { 'hdl': '3E', 'value': '3E' },
    { 'hdl': '3W', 'value': '3W' },
    { 'hdl': '3L', 'value': '3L' }
  ];

  self.controller.setComboOptions(aData);
  dhtmlx.delay(() => {
    self.controller.click(aData[0].hdl);
  });
}

// global context function
// eslint-disable-next-line no-unused-vars
function setDesignTimeLayout(oElm) {
  const oCont = oElm.container.controller,
    oDesign = oCont.getDescendant('designlayout');
  oDesign.applyLayout(oElm.getValue('cbLayoutPattern'));
}
