const akRepository = {
  aData: [],
  loadDef: function() {

    /* MLi: deactivated for now
        try{
            akioma.restSession.addCatalog(app.sessionData.restAdapterURI + "/rest/api/JSDO/Catalog/Akioma.Swat.Repository.ContainerEntity");
        akRepository.jsdo = new progress.data.JSDO({
            //serviceURI = "https://" + app.sessionData.serverURL + "/web";
            //catalogURI = this.catalogURI = this.serviceURI + "/Catalog/" + this.resourceName;
            name: 'Akioma.Swat.Repository.ContainerEntity'
        });
        var oParameters = {
            containerKey : 'bomofr2f,grpofrf,article_ofrf,controlPosF,sumposofrf,grptotofrf,txtposofrf,txtmodofrf,itemposofrf,grptxtofrf',
            options : 'Page=0,1'
        }
        akRepository.jsdo.fill(JSON.stringify(oParameters));

        akRepository.jsdo.subscribe('afterFill', function(jsdo, success, request) {
            jsdo.unsubscribe('afterFill', arguments.callee);
            jsdo.eContainer.foreach(function(container){
                akRepository.aData[container.data.containerKey] = container.data;
            });

        });

        }catch (oErr){
            console.error("addCatalog Error", oErr);
        }
		*/
  },
  selectContainer: function(cContainer) {
    akRepository.cSelectedContainer = cContainer;
  },
  getSelectedContainer: function() {
    return akRepository.aData[akRepository.cSelectedContainer];
  },
  setCurrentTypeKey: function(cTypeKey) {
    akRepository.cCurrentTypeKey = cTypeKey;
  },
  getCurrentTypeKey: function() {
    return akRepository.cCurrentTypeKey;
  }
};
