/**
 * SwatSidebar Control
 * @class ak_sidebar
 * @param {Object} options Repository attributes for SwatSidebar.
 */
$.ak_SwatSidebar = class {
  constructor(options) {
    const defaults = {};

    this.opt		= Object.assign({}, defaults, options.att);
    this.parent	= options.parent;
    this.registerDynObject = true;
    this.useParentDynObjectLink = true;

    this.registerVuexModule = true;

    this.event = {}; // object for event information

    // get parent
    const oParent	= this.parent;

    if (oParent) {
      const id = this.opt.id;
      this.parent.dhx.attachHTMLString(`<div id="${id}" class="v-sidebar"></div>`);
      this.$domElement = $(this.parent.dhx.cell).find(`#${id}`);
      this.$domElement.parent().parent().addClass('sidebar-control');
      this.$domElement.css('height', '100%');
      this.parent.parent.dhx.setSeparatorSize(0, 1);
      this.parent.dhx.setWidth(300);
      this.parent.opt.fixWidth = true;
    }
  }

  finishConstruct() {
    this.render();
  }

  /**
   * Method for setting the menu id for sidebar items
   * @param {string} menuId Menu structure id
   */
  setMenu(menuId) {
    this._commit('SET_MENU', menuId);
  }

  /**
   * Method for rendering the vue templates
   * @memberof ak_sidebar
   * @returns {void}
   */
  render() {
    const namespace = this.getFilteredNamespace();

    this.vueInstance = new akioma.VueInstancesFactory('sidebar', {
      namespace,
      options: this.oVuexState,
      controller: this,
      eventOnInitialize: this.onInitializeListener.bind(this),
      eventOnItemSelect: this.onSelectItemListener.bind(this),
      eventOnToggleCollapse: this.onToggleCollapse.bind(this)
    });
    this.obj = this.vueInstance.mount(this.$domElement[0]);
  }

  /**
   * Listener executed on initialize event
   * @returns {Promise<any>}
   */
  onInitializeListener() {
    let EventInitPromise;

    try {
      const self = this.dynObject;
      if (this.opt.EventOnInitialize && this.opt.EventOnInitialize.substr(1))
        EventInitPromise = akioma.swat.evaluateCode({ code: this.opt.EventOnInitialize.substr(1), dynObj: self });
    } catch (e) {
      akioma.notification({ type: 'error', text: `Error when calling EventOnInitialize method call: ${this.opt.EventOnInitialize}` });
    }

    return EventInitPromise;
  }

  /**
   * Listener called on select of sidebar item
   * @param {object} menuItem The menu item
   */
  onSelectItemListener(menuItem) {
    this.event.selectedObj = menuItem;

    let options = {};
    try {
      const self = this.dynObject;
      if (this.opt.EventAfterTabSelected && this.opt.EventAfterTabSelected.substr(1))
        options = akioma.swat.evaluateCode({ code: this.opt.EventAfterTabSelected.substr(1), dynObj: self });
    } catch (e) {
      akioma.notification({ type: 'error', text: `Error when calling EventAfterTabSelected method call: ${this.opt.EventOnInitialize}` });
    }

    if (menuItem.Function && !options.preventAction) { // in case of action call event
      const menuAction = akioma.swat.getActionObject(menuItem);
      window.app.controller.callAction(menuAction, this);
    }
  }

  /**
   * Listener called on collapse toggle event
   * @param {boolean} isCollapsed Sidebar collapsed state
   */
  onToggleCollapse(isCollapsed) {
    if (isCollapsed)
      setTimeout(() => this.parent.dhx.setWidth(80), 300);
    else
      this.parent.dhx.setWidth(300);
  }

  /**
   * Method destruct of sidebar object
   */
  destroy() {
    this.vueInstance.unmount();
  }
};
