akioma.user.createNewUser = function(self) {
  if (self.type == 'toolbar')
    self = self.getLink('TOOLBAR:TARGET');


  const oReturn = app.controller.callServerMethod('mod/zuser/createUser.p', [
    { type: 'iCHAR', value: 'createUser' },
    { type: 'iTT', value: self.getAllValues() },
    { type: 'oCHAR', name: 'creturn' }
  ]);
  if (isValidHdl(oReturn.creturn) == false) {
    akioma.message({ text: `Fehler in User-Neuanlage: ${oReturn.creturn}`, type: 'error' });
    return;
  }

  app.controller.launchContainer({
    proc: 'userw.r',
    para: `SelfHdl=${oReturn.creturn}&Page=0,1`,
    extLink: oReturn.creturn,
    self: this,
    data: true
  });

  self.container.controller.close();
};

akioma.user.userChangePW = function(self) {
  const cHdl = app.sessionData.userHdl;
  const oForm = self.parent.childs.userChangePwV.controller;
  let isValidForm = false;
  let cNotificationMessage = '';

  if (oForm) {
    if (!securityIsRestricted('CanResetPW'))
      isValidForm = oForm.getValue('gcNewPassword') ? true : false;
    else
      isValidForm = oForm.validate();
  }

  if (isValidForm) {
    const oReturn = app.controller.callServerMethod('mod/zuser/setPassword.p',
      [
        { type: 'iCHAR', value: cHdl },
        { type: 'iCHAR', value: self.getLink('TOOLBAR:TARGET').getValue('gcOldPassword') },
        { type: 'iCHAR', value: self.getLink('TOOLBAR:TARGET').getValue('gcNewPassword') },
        { type: 'oCHAR', name: 'cStatus' }
      ]);

    if (oReturn.cStatus) {
      self.screen.controller._dispatch('decrementHasChanges', 1);
      cNotificationMessage = oReturn.cStatus;
    }
  } else
    cNotificationMessage = 'Bitte füllen Sie alle Pflichtfelder aus.';

  akioma.notification({
    type: 'info',
    text: cNotificationMessage,
    lifetime: 0
  });

  return true;
};

akioma.user.userResetPW = function(self) {
  const cHdl = self.getLink('DATA:SOURCE').getValue('selfhdl');
  const oReturn = app.controller.callServerMethod('mod/zuser/setPassword.p',
    [
      { type: 'iCHAR', value: cHdl },
      { type: 'iCHAR', value: '' },
      { type: 'iCHAR', value: '_RESET_' },
      { type: 'oCHAR', name: 'cStatus' }
    ]);

  if (oReturn.cStatus) {
    akioma.notification({
      type: 'info',
      text: oReturn.cStatus,
      lifetime: 0
    });
  }

  return true;
};
