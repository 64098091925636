// set global date format
// TODO - Move Date methods in a dedicated class. They're mostly used only internally
akioma.globalData = {
  dateFormat: { en: '%d.%m.%Y' }, // set global date format here
  dateTimeFormat: { en: '%d.%m.%Y %H:%i' }, // set global datetime format here
  globalNumericFormat: {
    numberFormat: '0,000.00',
    groupSep: '.',
    decSep: ','
  }
};
window.dhx.dateLang = 'en'; // set date language here
window.dhx.dateFormat = akioma.globalData.dateFormat;
window.dhx.dateTimeFormat = akioma.globalData.dateTimeFormat;

/**
 * Returns a datetime in front-end format
 * @param  {string} date
 * @return {string}
 * @instance
 * @memberOf akioma
 */
akioma.getFrontendDateTimeFormat = function(date) {
  const format = akioma.date.convertDhtmlxFormat(akioma.globalData.dateTimeFormat.en);
  return moment(date, 'YYYY-MM-DD HH:mm').format(format);
};

/**
 * Returns a date in front-end format
 * @param  {string} date
 * @return {string}
 * @instance
 * @memberOf akioma
 */
akioma.getFrontendDateFormat = function(date) {
  const format = akioma.date.convertDhtmlxFormat(akioma.globalData.dateFormat.en);
  return moment(date, 'DD.MM.YYYY').format(format);
};
