/**
 * BasePanelSwitcher class
 * @class BasePanelSwitcher
 */
akioma.BasePanelSwitcher = class {
  /**
   * Method will activate only links in the active view
   * @param  {string} cActiveView Name of active view
   * @instance
   * @memberOf ak_panel
   * @return {void}
   */
  activateOnlyActiveViewLinks(cActiveView) {
    const self = this;
    this.children('frame', function() {
      if (this.inParentView !== cActiveView) {
        // deactivate links per view not active
        self.deactivateLinks(this);

      } else
        self.activateLinks(this);

    });
  }

  /**
   * Method to return current panel view
   * @instance
   * @memberof ak_panel
   * @returns ak_frame
   */
  getActiveFrameView() {
    let oFrame = null;
    const activeView = this.getCurrentView();

    this.children('frame', function() {
      if (this.inParentView === activeView)
        oFrame = this;

    });

    return oFrame;
  }

  /**
   * Method used for deactivating links inside of a container
   * @param  {dynObject} oCont
   * @instance
   * @memberOf ak_panel
   * @return {void}
   */
  deactivateLinks(oCont) {
    oCont.children('businessEntity', function() {
      this.activeLink = false;
    });
  }

  /**
   * Method used for activating the links found inside a container
   * @param  {dynObject} oCont
   * @instance
   * @memberOf ak_panel
   * @return {void}
   */
  activateLinks(oCont) {
    oCont.children('businessEntity', function() {
      this.activeLink = true;
    });
  }

  /**
   * Method for cleaning up panel header menu data
   * @param {string} viewName Name of the view inside panel to use for panel header menu cleanup
   * @instance
   * @private
   * @memberof ak_panel
   */
  _cleanPanelHeader(viewName) {
    try {
      const oPanel = this.dhx;
      if (!isNull(oPanel) && !isNull(oPanel.cell)) {
        const firstPanelObj = this.getParentOnlyChildInView(viewName);
        if (!isNull(firstPanelObj)) {
          $(oPanel.cell).find('> .dhx_cell_hdr > .dhx_cell_hdr_text > div').remove();
          $(oPanel.cell).find('> .dhx_cell_hdr > .dynSelectPanelHeader').remove();
        }
      }
    } catch (e) {
      akioma.log.error(e);
    }

  }

  /**
   * Method for adding panel header menu data
   * @instance
   * @param {string} viewName name of the view
   * @private
   * @memberof ak_panel
   */
  _reloadPanelHeader(viewName) {
    const firstPanelObj = this.getParentOnlyChildInView(viewName);
    if (!isNull(firstPanelObj) && firstPanelObj.opt.panelMenu !== '')
      this.setOption('panelMenu', firstPanelObj.opt.panelMenu, firstPanelObj);
  }

  /**
   * Method for finding the frame object inside the panel view
   * @instance
   * @param {string} viewName name of view
   * @memberOf ak_panel
   * @returns {ak_object|null}
   */
  findViewFrame(viewName) {
    return this.childs.find(child => child.opt.name === viewName);
  }

  /**
   * Method for finding the first child inside a given view that has the titleHeader attribute set to parent-only
   * @instance
   * @param {string} viewName name of view
   * @memberOf ak_panel
   * @returns {ak_object|null}
   */
  getParentOnlyChildInView(viewName) {
    const oFrameActive = this.findViewFrame(viewName);
    let firstPanelObjParentOnly = null;

    if (!isNull(oFrameActive)) {
      const firstPanel = oFrameActive.getDescendant('panel');
      firstPanelObjParentOnly = firstPanel.childs.find(child => child.opt.titleHeader === 'parent-only');
    }

    return firstPanelObjParentOnly;
  }

  /**
   * Method for refreshing a given view Datasources
   * @param {string} viewName name of view
   * @private
   * @memberOf ak_panel
   */
  _refreshViewData(viewName) {
    const frame = this.findViewFrame(viewName);
    if (frame)
      frame.refreshViewDataFetch();
  }

  /**
   * Changes the view of the panel to a new given view name
   * @param  {string} cViewName The view name
   * @instance
   * @memberOf ak_panel
   * @returns {boolean} True if view is already loaded, false otherwise
   */
  showView(cViewName) {
    try {
      const oPanel = this.dhx;
      const bViewVisible = oPanel.showView(cViewName);

      if (!bViewVisible) {
        this._cleanPanelHeader(cViewName);
        this._reloadPanelHeader(cViewName);
        this._refreshViewData(cViewName);
      }

      this.activateOnlyActiveViewLinks(cViewName);

      // decrement changes in hidden form if exists
      if (bViewVisible) {
        const oForm = this.getDescendant('form');
        if (oForm)
          oForm._dispatch('decrementHasChanges', 1);

      }

      return bViewVisible;
    } catch (e) {
      akioma.notification({
        type: 'error',
        text: akioma.tran('PanelSwitcher.errorSwitchView', { defaultValue: `Could not switch view in "${this.opt.name}" to view: ${cViewName}` }),
        expire: 5000
      });
    }
  }

  /**
     * Method for switching a panel view to given view name and custom options
     * @memberof ak_panel
     * @instance
     * @param {string} cView Name of the view to load
     * @param {object} options Options for view and launchContainer action in view
     * @param {string} options.containerName The name of the object from the repository to load. </br>
    The initial page can be specified using a '|' after the containerName, like this: 'customerScreen|20' or 'customerScreen|pagekey20'. Check startingPage attribute for more details. </br>
    The containerName can also be a function. In that function, the actual containerName will need to be set. StartingPage attribute can also be set in the function.
    * @param {string} options.pages The initial pages loading interval, eg. <code>0,1</code>
    * @param {boolean} options.dynGuid Use the dynamic guid. </br>
    * Useful when loading a tab for eg, where you could load the same repository object multiple times and you need the links to be unique
    * @param {object} options.parentControl The dynObject or controller in which the launched container will be added
    * @param {string} options.containerName The name of the container, used for displaying in different views inside panels
    * @param {object} options.caller The caller object, from where the launchContainer is runned
    * @param {object} options.launchedFrom The object from where the launchContainer is runned (used mostly for buttons in Ribbon/Toolbar).
    * @param {boolean} options.autoAdd Automatically add a new record on the resulting screen PrimarySDO:SRC BusinessEntity
    * @param {boolean} options.fetchOnInit If the new repository screen should load its data or not, calls BE openQuery.
    * @param {string} options.view The name of the view to use to load the new repository object in. Requires target also.</br>
    * @param {object} options.params The list of parameters for launchContainer request
    * @param {boolean} options.activation Default value is "true", that means that when opening the new repository object the user will be switched to Non-Desktops mode.
    * if specified "false" then it will not switch to the Non-Desktop mode but will open the corresponding object window in Non-Desktop.
    * @param {boolean} options.noContextSwitch Default value is "false", that means the repository object will be opened in the current active view, desktop or non-desktop if it
    * is modal and if it is not modal then it will always get opened in the non-desktop mode, unless we specify noContextSwitch to be "true". In this case it will open in the
    * current active context when launched, desktop or non-desktop.
    * @param {boolean} options.allowMultipleInstances If set to "true", multiple instances will be allowed. If set to "false", only one instance will be allowed to be opened at one moment.
    * @param {string} options.params.TypeKey
    * @param {string} options.params.SelfHdl
    * @param {string} options.params.Datasource
    * @param {string} options.params.TargetId
    * @param {string} options.repositionTo
    * Automatically position the PRIMARYSDO:SRC businessEntity to the SelfHdl given value. </br>
    * The value can also be a method that returns the repositionTo value. for eg. <code>$ akioma.getSelfHdlToPosition(self) </code>
    * @example
    * //Where self will represent the businessEntity
    * akioma.getSelfHdlToPosition = function(self){
    *
    * 	var oWin = self.controller.getDescendant('window');
    * 	if(oWin.opt.name == 'TestWindow'){
    * 		return '123131';
    * 	}
    *
    *
    * }
    *
    * @param {string|object} options.customData Contains the information for repositioning in a businessEntity. If it starts with '$' it will be interpreted as a function which will return the actual value of customData.
    * <br> <a href="/sports-webui/docs/Order.js.html#line39" target="_blank">CustomData example </a> </br>
    * @param {string} options.foreignKeyProvider Used for setting the foreign keys on the PRIMARYSDO BusinessEntity after loading the repository object. </br>
    * <a href="/sports-webui/docs/Order_getOrderForeignFields.ts.html" target="_blank">ForeignKeys Example</a> </br>
    * This Sample can be tested in the Customer-Desktop, in the FAB select "+" menu item
    * @param {integer|string} options.startingPage Specifies the initial page selected after launching a container. It can be the position of page (index starting from 1) OR PageKey attribute
    * @returns Promise<ak_frame>
  */
  switchView(cView, options) {
    return new Promise((resolve, reject) => {
      // show the view, will return false if already loaded
      const oPanel = this.dhx;
      const isViewLoaded = Object.keys(oPanel.conf.views_loaded).indexOf(cView) > -1;
      if (isViewLoaded) {
        if (oPanel.conf.view !== cView)
          oPanel.showView(cView);
        const frame = _.find(this.childs, el => el.opt.name === cView);
        resolve(frame);
      } else {
        const defaults = {
          parentControl: this, // parentControl, the target in which you will add the new container/view
          view: cView,
          containerName: cView,
          pages: '0,1',
          allowMultipleInstances: true,
          fetchOnInit: true
        };

        options = Object.assign(defaults, options || {});
        // launch the new frame with form and pass in the dynamic links
        app.controller.launchContainer(options).then(frame => {
          oPanel.showView(cView);
          resolve(frame);
        }).fail(() => {
          reject();
        });
      }
    });
  }

  /**
   * Method for getting a panel view
   * @param  {string} cViewName The name of the panel view
   * @instance
   * @memberOf ak_panel
   * @returns {object}
   */
  getView(cViewName) {
    const oSelf = this,
      oPanel = this.dhx;
    try {
      return oPanel.views[cViewName];
    } catch (e) {
      akioma.notification({
        type: 'error',
        text: akioma.tran('PanelSwitcher.errorGetView', { defaultValue: `Could not get view in "${oSelf.opt.name}", view: ${cViewName}` }),
        expire: 5000
      });
    }
  }

  /**
   * Returns the current panel view
   * @instance
   * @memberOf ak_panel
   * @return {string} The name of the panel view
   */
  getCurrentView() {
    const oPanel = this.dhx;
    const cCurrentView = oPanel.getViewName();
    return cCurrentView;
  }
};
