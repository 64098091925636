// ************************* menubaritem ********************************
$.extend({
  ak_menubaritem: function(options) {
    const defaults = {
      id: '',
      title: '',
      disabled: false,
      image: '',
      image2: ''
    };

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;

    // get new element
    const cName = this.opt.id,
      cTitle = this.opt.title,
      lDisable = this.opt.disabled,
      cImage = (this.opt.image == '') ? null : this.opt.image,
      cImage2 = (this.opt.image2 == '') ? null : this.opt.image2,
      cParent = this.parent.opt.id;

    // get menubar
    const oElm = this.getAncestor('menubar'),
      oMenubar = oElm.dhx;
    if (oMenubar) {

      // check parent
      if (this.parent.view == 'menubar') {
        const cPos = this.parent.lastMenu;
        this.parent.lastMenu = cName;
        oMenubar.addNewSibling(cPos, cName, cTitle, lDisable, cImage, cImage2);
      } else {
        const iPos = this.parent.pos + 1;
        this.parent.pos = iPos;
        oMenubar.addNewChild(cParent, iPos, cName, cTitle, lDisable, cImage, cImage2);
      }

      $.extend(this, {
        dhx: oMenubar,
        menu: oElm,
        pos: -1
      });
    } else
      !_isIE && console.error(`No valid parent for menuitem ${cName}`);

  }
});

// methods for menubar
$.ak_menubaritem.prototype = {
  // click *****************
  click: function() {

    // call action
    app.controller.callAction({
      ActionType: this.opt.actiontype,
      Action: this.opt.action,
      Category: this.opt.category,
      RunPar: this.opt.RunPar,
      CallObject: this.opt.logicalObject,
      caller: this
    },
    this.menu);
    return true;
  },

  // destroy
  destroy: function() {
    this.menu = null;
    this.dhx = null;
  }
};
