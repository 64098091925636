/**
 * Searches recursively for closest ancestor containing a different ribbon/toolbar
 * @param  {Object}  oSelf    Controller used for starting the search. This parameter is used recursively.
 * @param  {Object}  oInitial Controller used for starting the search. This controller is used to prevent returning the same ribbon/toolbar
 * @return {Object}           Closest ancestor ribbon/toolbar or the initial ribbon/toolbar if there is no ancestor
 * @instance
 * @memberOf akioma
 */
akioma.getRibbonToolbar = function(oSelf, oInitial) {
  let oParent, bNoMoreContainers;
  if (oSelf.view == 'window')
    oParent = oSelf;
  else if (oSelf.parent) {
    oParent = oSelf.parent.getAncestor('frame');
    if (oParent.opt.name == 'AkiomaMainLayout') {
      oParent = oSelf.getAncestor('window');
      bNoMoreContainers = true;
    }
  }

  if (oParent) {
    const oControl = oParent.getDescendant('ribbon') || oParent.getDescendant('toolbar');
    if ((oControl && oControl != oInitial) || bNoMoreContainers) {
      const oTab = oControl.getAncestor('tab');
      if (!oTab || ($(oTab.dhx.cell).css('visibility') == 'visible'))
        return oControl;
      return oInitial;
    }
    return akioma.getRibbonToolbar(oParent, oInitial);
  }
};

/**
 * Focuses the first element from a given toolbar/ribbon
 * @param  {Object}  oSelf    Ribbon/Toolbar controller where the focus will be applied
 * @instance
 * @memberOf akioma
 */
akioma.focusRibbonToolbar = function(oSelf) {
  if (oSelf) {
    const oDhx = oSelf.dhx._base || oSelf.dhx.base;
    oDhx.click();
    const oNewItem = oSelf.getNextItem();
    if (oNewItem)
      oSelf._focusItem(oNewItem);
  }
};

akioma.checkBtnsDefaultGoCancel = function(oSelf) {
  const oToolbar = oSelf.getDescendant('toolbar');
  const oRibbon = oSelf.getDescendant('ribbon');
  const oForm = oSelf.getDescendant('form');
  let oDefaultGoBtn = null;
  let oDefaultCancelBtn = null;

  // for ribbonbtns,toolbarbtns and form btns,defaultGo
  if (oRibbon && oRibbon.defaultGoBtn)
    oDefaultGoBtn = oRibbon.defaultGoBtn;
  else if (oToolbar && oToolbar.defaultGoBtn)
    oDefaultGoBtn = oToolbar.defaultGoBtn;
  else if (oForm && oForm.defaultGoBtn)
    oDefaultGoBtn = oForm.defaultGoBtn;


  // for ribbonbtns,toolbarbtns and form btns,defaultCancels
  if (oRibbon && oRibbon.defaultCancelBtn)
    oDefaultCancelBtn = oRibbon.defaultCancelBtn;
  else if (oToolbar && oToolbar.defaultCancelBtn)
    oDefaultCancelBtn = oToolbar.defaultCancelBtn;
  else if (oForm && oForm.defaultCancelBtn)
    oDefaultCancelBtn = oForm.defaultCancelBtn;


  // enter. defaultGo
  if (oDefaultGoBtn) {
    let delayTimeout = 0,
      dATimeout = null;

    if (oRibbon) {
      delayTimeout = oRibbon.ribbonClickDelay;
      dATimeout = oRibbon.dATimeout;
    } else if (oToolbar) {
      delayTimeout = oToolbar.toolbarClickDelay;
      dATimeout = oToolbar.dATimeout;
    }

    const delayedClick = () => {

      clearTimeout(dATimeout);

      if (oDefaultGoBtn.view == 'ribbonbutton')
        oDefaultGoBtn.click(oDefaultGoBtn.opt.id);
      else
        oDefaultGoBtn.click(oDefaultGoBtn.cName);

    };

    if (!isNull(oDefaultGoBtn.form) && akioma.WaitCursor.isWaitStateActive(oDefaultGoBtn.form.akElm.dynObject))
      return;

    // check and apply delayed click call
    if (!dATimeout)
      delayedClick();
    else
      dATimeout = setTimeout(delayedClick, delayTimeout);

  }

  // esc key. defaultCancel
  if (oDefaultCancelBtn)
    oDefaultCancelBtn.click(oDefaultCancelBtn.cName);

};

akioma.isChildOf = function(oDynObject, cParent) {
  if (oDynObject.parent) {
    if (oDynObject.parent.name == cParent)
      return true;
    else
      return akioma.isChildOf(oDynObject.parent, cParent);
  }
  return false;
};

// Reposition TreeGrid based on customData.childHdl attribute. This can be placed in EventOnInitialize attribute in window.
akioma.positionTreeOnOwnerInit = function(self) {
  // check for chld hdl in customData
  let cPositionHdl = null;
  if (self.controller.customData) {
    cPositionHdl = self.controller.customData.childHdl;
    if (isNull(cPositionHdl))
      cPositionHdl = self.controller.customData.launchByHdl;
  }
  if (isNull(cPositionHdl)) return;

  // get tree and reposition to child if, found
  const oTreeGrid = self.controller.getDescendant('treegrid');
  if (oTreeGrid)
    oTreeGrid.reposition2Hdl(cPositionHdl);
};

// returns form element in nested blocks/fieldsets
akioma.getDhxFormElement = function(oForm, id) {
  if (oForm && oForm.itemPull) {
    if (oForm.itemPull[oForm.idPrefix + id] != undefined)
      return oForm.itemPull[oForm.idPrefix + id];
  }

  if (oForm && oForm.itemPull) {
    for (const i in oForm.itemPull) {
      if (oForm.itemPull[i]._list) {
        for (const l in oForm.itemPull[i]._list) {
          const res = akioma.getDhxFormElement(oForm.itemPull[i]._list[l], id);
          if (res)
            return res;
        }
      }
    }
  }

  return null;
};

akioma.getContainerByName = function(cName) {
  try {
    const aConts = app.controller.getContainersOpened();
    return aConts[cName];
  } catch (e) {
    console.warn(e);
  }
};

akioma.filterSearch = function(oSelf) {
  let oTarget;
  const cValue = oSelf.getValue();

  if (oSelf.type != 'form') { // must be toolbar then
    oTarget = oSelf.getLink('NAVIGATION:TARGET');
  }

  if (oTarget)
    oTarget.controller.filterSearch (null, cValue);
};

/**
 * Finds the parent form controller from a given Akioma controller
 * @example
 * var oForm = akioma.getForm(self.controller);
 * @desc Get the parent form control.
 * @param  {Object} oElm Akioma Object
 * @return {ak_form}
 */
akioma.getForm = function(oElm) {
  return oElm.getAncestor('form');
};

akioma.getRibbon = function(oElm) {
  return oElm.getAncestor('ribbon');
};

// set property -> and check security
// global context function
// eslint-disable-next-line no-unused-vars
function setProperty(oSelf, oProp) {
  if (oSelf.parent.view == 'form' && typeof oSelf.parent.enableFields == 'undefined') {
    const oElm = $.extend({}, oProp);

    // if new record -> use createfield for enabling fields
    if (oElm.createField)
      oElm.readOnly = (oSelf.opt.create == undefined) ? (oSelf.opt.disabled) : !oSelf.opt.create;

    else if (oElm.security) { // check if we have to set a switch for security
      if (oElm.security.readOnly != undefined)
        oElm.readOnly = oSelf.security.readOnly = (oSelf.opt.disabled) ? true : oElm.security.readOnly;
      if (oElm.security.hidden != undefined)
        oElm.hidden = oSelf.security.hidden = (oSelf.opt.hidden) ? true : oElm.security.hidden;
    } else {
      // now check the switch we have to set
      oElm.readOnly = (oSelf.security.readOnly) ? true : ((oElm.readOnly != undefined) ? oElm.readOnly : false);
      oElm.hidden = (oSelf.security.hidden) ? true : ((oElm.hidden != undefined) ? oElm.hidden : false);
    }

    switch (oSelf.view) {
      case 'input':
      case 'inputnum':
      case 'calendar':
      case 'combobox':
      case 'lookup':
      case 'translat':
        if (oElm.readOnly)
          oSelf.form.disableItem(oSelf.opt.name);
        else
          oSelf.form.enableItem(oSelf.opt.name);

        if (oElm.hidden || oSelf.security.hidden)
          oSelf.form.hideItem(oSelf.opt.name);
        else
          oSelf.form.showItem(oSelf.opt.name);
        break;
      case 'datagrid':
      case 'dataview':
      case 'gridedit':
      case 'treegrid':
        switch (oElm.mode) {
          case 'set':
            oSelf.security.readOnly = oElm.readOnly;
            oSelf.security.hidden = oElm.hidden;
            break;
          case 'check':
            if (oSelf.security.readOnly)
              return false;
            else
              return true;
        }
        break;
      case 'ittext': {
        const oText = oSelf.form.getTextedit(oSelf.opt.name),
          oEditor = $(oText).find('textarea').ckeditorGet();

        dhtmlx.delay((oElm, oEditor) => {
          if (oEditor.status !== 'ready') {
            oEditor.on('instanceReady', () => {
              updateEditorReadOnlyStatus();
            });
          } else
            updateEditorReadOnlyStatus();


          function updateEditorReadOnlyStatus() {
            if (oElm.readOnly)
            // to get read only mode in editor -> call it with empty
              oEditor.setReadOnly(true);
            else
            // to enable the editor -> call it with true
              oEditor.setReadOnly(false);
          }
        }, this, [ oElm, oEditor ]);

        break;
      }
    }
  } else
    return true;
}
