dhtmlXForm.prototype.items.template._render || (dhtmlXForm.prototype.items.template._render = dhtmlXForm.prototype.items.template.render);
dhtmlXForm.prototype.items.template.render = function(item, data) {
  if (data.userdata && data.userdata.id) {
    const oSelf = $.getObjectByName({ id: data.userdata.id });
    item.akElm = oSelf;
    oSelf.dhx = item;
  }

  this._render(item, data);
  return this;
};

function setImageValue(cName, cValue) {
  const oForm = this.getForm();
  if (!cValue)
    return '<p>[]</p>';

  $('.dhxform_label', oForm.cont).css({ display: 'block' });

  return `<img class='form-img' src='${cValue}'></img><p>${cValue}</p>`;
}

(function($) {

  // ***************** image ******************
  $.extend({
    /**
     * SwatImage Control
     * @class ak_image
     * @param {Object} options Repository attributes for SwatImage.
     * @param {string} options.BorderTitle The Title of a dynamic Viewer or Browser
     * @param {string} options.EventOnInitialize client side code to run when Container has been initialized
     * @param {string} options.contextMenu the id of a menuStructure which will be used as a context-menu
     * @param {string} options.floatingActionButton the id of a menustructure, which will be rendered as a FAB
     * @param {string} options.LayoutOptions List of multi-layout options for the object.
     * @param {string} options.panelMenu comma separated list of menu-structures which will be shown as panelHeader-Buttons </br>
     * Can also contain the flag #NoDropDown that specifies that the menu should not be loaded as a dropdown but each menu item should be added in the panel-Header. </br>
     * For example: </br>
     * <code>menuStructSave,menuSettings#NoDropDown,menuLookup</code> </br>
     * The buttons support font icons with the following attributes: </br>
     * 1. Css attributes, defined like this: fa fa-user#color:red </br>
             * 2. Css classes, defined like this: fa fa-user#_style:module_prod
             * 3. Stacked font icons, defined like this: fas fa-circle$fas fa-flag. Both icons also support Css attributes or Css classes, like this: fas fa-circle#color:red$fas fa-flag#_style:module_prod </br>
     * @param {boolean} options.ENABLED WidgetAttributes Enabled
     * @param {number} options.MinHeight The pre-determined minimum height of a visual object
     * @param {number} options.MinWidth The pre-determined minimum width of a visual object.
     * @param {number} options.WIDTH-CHARS Width in characters. This may currently be used when rendering some objects. There is no get function, use getWidth to retrieve the realized value from an object.
     * @param {string} options.ForeignFields A comma-separated list, consisting of thefirst local db fieldname, followed by the matching source temp-table field name, followed by more pairs if there is more than one field to match.
     * @param {string} options.titleHeader specifies which panelHeader to use. when empty, then uses the header of its own panel. if "none" then no header at all. if "parent" it uses the header of the parent panel
     * @param {boolean} options.STRETCH-TO-FIT When TRUE, images are automatically resized to fit the available space.
     * @param {string} options.typeRange The type range which makes up a combobox in a Toolbar
     * @param {number} options.HEIGHT-CHARS Height in characters. This may currently be used when rendering some objects. There is no get function, use getHeight to retrieve the realized value from an object.
     */
    ak_image: function(options) {
      const oSelf = this,
        defaults = { page: 0 };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;
      this.view = options.view;

      this.registerDynObject = true;
      this.useParentDynObjectLink = true;

      // set title in panel (image sitzt in einem Form, deshalb 2x parent... */
      if (!this.opt.title)
        this.opt.title = 'Bilder';
      this.opt.title = akioma.tran(`${akioma.getForm(this).opt.name}._title`, { defaultValue: this.opt.title });

      this.parent.parent.setOption('title', this.opt.title);

      if (!this.opt.customStyle)
        this.opt.customStyle = this.view;

      // get parent
      const oParent = this.parent;
      if (oParent) {
        // -> bind image to layout
        oParent.prop.fields.push({
          type: 'template',
          label: this.opt.label ? this.opt.label : '',
          name: 'image',
          readonly: this.opt.readonly || false,
          className: 'w4-formField w4-inputField w4-imageField',
          format: setImageValue,
          userdata: { id: this.opt.id }
        });

        try {
          oParent.parent.parent.dhx.attachEvent('onResizeFinish', () => {
            oSelf._resizeFit();
          });
        } catch (e) {
          akioma.log.error(e);
        }

        // append to elements in form
        if (this.parent.view == 'form')
          this.parent.elements.push(this);

      } else
        !_isIE && console.error('No valid parent for image ');
    }
  });

  $.ak_image.prototype = {
    // finish construct
    finishConstruct: function() {
      // get field
      this.form = this.parent.dhx;
      this.cActIndex = '-';
    },

    // finish editor ***************
    finishEditor: function() {
      // get size from parent
      const oPanel = this.getAncestor('panel').dhx;
      if (oPanel) {
        // now load content
        const oType = this.dynObject.parent.getLink('TABLEIO:SOURCE').controller;
        getAkImage(oType);
      } else
        akioma.log.error([ 'Textedit: no valid panel', this ]);
    },

    // get value *****************
    getValue: function() {
      return this.form.getItemValue(this.opt.name);
    },

    // set value ********************
    setValue: function(cValue) {
      this.form.setItemValue(this.opt.name, cValue);
    },

    _resizeFit: function() {
      try {
        const oParent = this.parent;
        const oImg = $(oParent.dhx.cont).find('.w4-formField img');
        if (oImg.length > 0 && oParent.dhx)
          oImg.css({ 'max-height': oParent.parent.dhx.getHeight() - 94 });

      } catch (e) {
        akioma.log.error('resize fit image not found', e);
      }
    },

    // called when cursor has been changed *********
    cursorChange: function() {
      // now load content
      const oType = this.dynObject.parent.getLink('TABLEIO:SOURCE').controller;
      getAkImage(oType);
    }
  };
})(jQuery, jQuery);
