// image form control

window.dhx4.template.printImg = function(value) {
  let sResult = '';
  if (value)
    sResult = `<img width="100%" height="100%" class="img-entry" src="${value}" />`;

  return sResult;
};

dhtmlXForm.prototype.items.imagebox = {
  render: function(item, data) {
    item._type = 'imagebox';

    const oSelf = $.getObjectByName({ id: data.userdata.id });

    this.doAddLabel(item, data);

    const html = '<div class="dhxform_control" style="left:#left#;top:#top#;width:#width#;height:#height#;">' +
                            '<div class="imagebox-formfield dhxform_image"></div>' +
                        '</div>';
    const values = {
      left: oSelf.opt.left,
      top: oSelf.opt.top,
      width: oSelf.opt.width,
      height: oSelf.opt.height
    };

    const template = window.dhx4.template(html, values);
    $(template).appendTo(item)
      .find('.imagebox-formfield').on('click', () => {
        oSelf.eventClick();
        return false;
      });


    item.akElm = oSelf;
    oSelf.dhx = item;

    return this;
  },
  setValue: function(item, value) {
    // console.log('img value:' + value);
    // set emptyImage if set
    if ((value == '' || value == null) && item.akElm.opt.emptyImage)
      value = item.akElm.opt.emptyImage;

    const html = '#value|printImg#';
    const values = { value: value };
    const itemHTML = window.dhx4.template(html, values);

    $(item).find('.imagebox-formfield').empty().append(itemHTML);
    item._value = value;
  },
  getValue: function(item) {
    return item._value;
  },
  getImgBox: function(item) {
    return item;
  }
};

(function() {
  for (const a in { doAddLabel: 1, doAddInput: 1, destruct: 1, doUnloadNestedLists: 1, setText: 1, getText: 1, enable: 1, disable: 1, setWidth: 1 })
    dhtmlXForm.prototype.items.imagebox[a] = dhtmlXForm.prototype.items.input[a];
})();

dhtmlXForm.prototype.getImgBox = function(name) {
  return this.doWithItem(name, 'getImgBox');
};
dhtmlXForm.prototype.setFormData_imagebox = function(name, value) {
  return this.doWithItem(name, 'setValue', value);
};
dhtmlXForm.prototype.getFormData_imagebox = function(name) {
  return this.doWithItem(name, 'getValue');
};

(function($) {
  // ********************* image ********************
  $.extend({
    ak_imagebox: function(options) {
      const defaults = {};

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;

      this.useParentDynObjectLink = true;
      this.registerDynObject = true;

      // get parent
      const oParent = this.parent;
      if (oParent) {

        oParent.prop.fields.push({
          type: 'imagebox',
          inputTop: parseInt(this.opt.top),
          inputLeft: parseInt(this.opt.left),
          inputWidth: parseInt(this.opt.width),
          label: this.opt.label,
          labelTop: parseInt(this.opt.top),
          labelLeft: oParent.labelLeft(this),
          name: this.opt.name,
          readonly: this.opt.readonly,
          position: (this.opt.positionMode != undefined) ? this.opt.positionMode : oParent.opt.positionMode,
          className: 'w4-formField',
          userdata: { id: this.opt.id }
        });

        // append to elements in form
        if (this.parent.view == 'form')
          this.parent.elements.push(this);

        // oInput.akElm = this;
        $.extend(this, { security: {} });
      }
    }
  });

  // methods for imagebox
  $.ak_imagebox.prototype = {
    // finish construct **********
    finishConstruct: function() {
      // get field
      this.form = this.parent.dhx;
    },

    // get value *****************
    getValue: function() {
      return this.form.getItemValue(this.opt.name);
    },

    // set value ********************
    setValue: function(cValue) {
      this.form.setItemValue(this.opt.name, cValue);
    },

    // event leave *******************
    eventLeave: function() {
      // check if we have to call the leave event
      if (this.opt.leaveEvent)
        app.controller.callAkiomaCode(this, this.opt.leaveEvent);
    },

    // event click *******************
    eventClick: function() {
      const oPara = {},
        oForm = this.dynObject.parent.controller,
        cName = (this.opt.keyField) ? this.opt.keyField : this.opt.name;

      let cIndex = '';
      if (oForm.dataSource)
        cIndex = oForm.dataSource.getIndex();
      oPara.refHdl = cIndex;
      oPara.fieldName = cName;

      app.controller.launchContainer({
        proc: 'choosefileg.r',
        para: '',
        data: true,
        self: oForm
      });

      this.parent.oImgBoxActive = this;
    }
  };
})(jQuery, jQuery);
