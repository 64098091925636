akioma.EventBeforeFetch_filter = function(self, cFilter) {
  const oQuery = self.controller.getQuery();
  oQuery.addCondition('ObjectTypeName', 'eq', cFilter);
};

/**
  * Client logic function executed onValidate event for omniSearch dynSelect. The function opens the selected record.
  * @param {object} self DynSelect dynObject
  **/
akioma.omniSearchFilter = function(self) {
  const dynSelect = self.controller;
  const selectedRecord = dynSelect.getSelectedRecord();
  app.controller.launchContainer({
    self: self,
    containerName: (selectedRecord['detailsscreen'] > '' ? selectedRecord['detailsscreen'] : selectedRecord['selfhdl']),
    pages: '0,1',
    data: true,
    repositionTo: selectedRecord['selfhdl']
  });
  self.screen.controller.close();
};

// TODO - This method should be part of the FilterManager class
akioma.toggleFilter = function(self, event) {
  const oSelf = self.controller;
  const aExtendedFormat = {
    'true': { icon: 'far fa-check-square' },
    'false': { icon: 'far fa-square' }
  };

  const oHeader = event.currentTarget;
  const menuName = oHeader.getAttribute('menu-name');
  const cCurrentState = oSelf.FilterManager.getPanelFilterState(menuName);
  let toRemoveFilter = false;

  let oFilterIcon, oNewIcon;
  switch (cCurrentState.toString()) {
    case '':
      oFilterIcon = aExtendedFormat['true'];
      oNewIcon = akioma.icons.replaceIcon(oFilterIcon);
      oHeader.icon = oFilterIcon.icon;
      break;
    case 'false':
      oFilterIcon = aExtendedFormat['true'];
      oNewIcon = akioma.icons.replaceIcon(oFilterIcon);
      oHeader.icon = oFilterIcon.icon;
      break;
    case 'true':
      oFilterIcon = aExtendedFormat['false'];
      oNewIcon = akioma.icons.replaceIcon(oFilterIcon);
      oHeader.icon = oFilterIcon.icon;
      toRemoveFilter = true;
      break;
  }

  const oFirstIcon = $(oHeader).find('i').eq(0);
  const oSecondIcon = $(oHeader).find('i').eq(1);
  $(oHeader).removeClass('stackedIcon');

  $(oFirstIcon).removeClass().addClass(`${oNewIcon.icon1} ${oNewIcon.style1}`);
  oFirstIcon[0].style.cssText = oNewIcon.attributes1;
  $(oSecondIcon).removeClass().addClass('fa-stack-1x fa-inverse');
  if (oNewIcon.icon2) {
    $(oSecondIcon).addClass(`${oNewIcon.icon2} ${oNewIcon.style2}`);
    oSecondIcon[0].style.cssText = oNewIcon.attributes2;
    $(oFirstIcon).addClass('fa-stack-2x fa-2x fa-fw');
    $(oHeader).addClass('stackedIcon');
  } else
    $(oFirstIcon).addClass('fa-stack-lg fa-lg fa-fw');


  if (!toRemoveFilter)
    oSelf.FilterManager.setPanelFilterState(menuName, true);
  else
    oSelf.FilterManager.setPanelFilterState(menuName, false);

  oSelf.FilterGo();
};

akioma.filterDynamicallyGrid = function(self, fieldName, columnName, operator) {
  const oWindow = self.container;
  const oForm = akioma.getForm(oWindow.caller.controller);
  const filterVal = oForm.dynObject.getObject2(fieldName).controller.getValue();
  self.controller.query.addUniqueCondition(columnName, operator, filterVal);
  return;
};

// change filter dynamically based on businessEntity or form input value
// fieldName  - input name which contains the search key
// columnName - name of column which should be filtered
// operator   - e.g. startswith
akioma.filterDynamically = function(self, fieldName, columnName, operator) {
  const form = akioma.getForm(self.parent.controller);
  let filterVal = '';

  if (fieldName.indexOf('!') === -1) {
    filterVal = form.dynObject.getObject2(fieldName).controller.getValue();
    self.controller.businessEntity.query.addUniqueCondition(columnName, operator, filterVal);
  }

  return;
};

//  filter grid based on form input value
//  fieldName  - input name
//  columnName - grid column name
//  operator   - e.g. startswith
akioma.filterFormGrid = function(self, fieldName, columnName, operator) {
  const form = akioma.getForm(self.parent.controller);
  let filterVal = '';
  const oDataSource = form.dynObject.getLink('DATA:SRC').controller;

  const fnFilter = function() {
    if (fieldName.indexOf('!') > -1) { // from be
      const cBEFieldName = fieldName.substr(1, fieldName.length);
      filterVal = oDataSource.dynObject.getValue(cBEFieldName);
    } else
      filterVal = form.getValue(fieldName);
    if (filterVal == null)
      filterVal = '';
    self.controller.query.addUniqueCondition(columnName, operator, filterVal);
  };

  if (oDataSource.dataLoaded == undefined) {
    self.controller.stop = true;
    oDataSource.aAfterFillOnce.push(() => {
      fnFilter();
      self.controller.stop = false;
      self.controller.openQuery({});
    });
  } else
    fnFilter();
};

akioma.setFilterParameter = function(self) {
  self.controller.query.addUniqueCondition('SDO', 'eq', self.controller.parent.caller.dataSource.opt.name);
  self.controller.query.addUniqueCondition('Grid', 'eq', self.controller.parent.caller.opt.name);
};

akioma.filterManagerOption = function(self, cOption) {
  const oCallerGrid = (self.container.caller) ? self.container.caller.controller : self.controller;
  const oFilterGrid = (self.container.caller) ? self.container.controller.getDescendant('datagrid2') : undefined;
  oCallerGrid.FilterManager.setFilterGrid(oFilterGrid);
  switch (cOption) {
    case 'add':
      oCallerGrid.FilterManager.addFilter();
      break;
    case 'save':
      oCallerGrid.FilterManager.saveFilter();
      break;
    case 'delete':
      oCallerGrid.FilterManager.removeFilter().then(() => {
        const filterHdl = oCallerGrid.FilterManager.getDefaultFilter();

        if (filterHdl != undefined)
          oFilterGrid.setSelectedFilterRowByHdl(filterHdl);
      });
      break;
    case 'rename':
      oCallerGrid.FilterManager.renameFilter();
      break;
    case 'default':
      oCallerGrid.FilterManager.setDefault();
      break;
    case 'refresh':
      oFilterGrid.FilterGo();
      break;
    default:
      akioma.log.warn('Not an option');
  }
};

akioma.onInitFilter = function(self) {
  const oFilterGrid = (self.container.caller) ? self.container.controller.getDescendant('datagrid2') : undefined;
  const oCallerGrid = (self.container.caller) ? self.container.caller.controller : self.controller;
  const filterhdl = oCallerGrid.FilterManager.getCurrentFilter();
  oFilterGrid.setSelectedFilterRowByHdl(filterhdl);
};
