(function($) {

  /**
   * Creates akioma.oWindowsModals if it is undefined. Used for creating new modal windows.
   */
  akioma.createModalWindows = function() {
    if (akioma.oWindowsModals == undefined) {
      akioma.oWindowsModals = new dhtmlXWindows({
        image_path: oDhx.imagePath,
        skin: oDhx.skin,
        parent: '#main'
      });

      akioma.oWindowsModals.attachEvent('onFocus', win => {
        akioma.swat.MasterLayout.disableLastFocusTrap();
        akioma.swat.MasterLayout.lastActiveWindow = win;
        akioma.swat.MasterLayout.enableLastFocusTrap();
      });
    }
  };

  akioma.buildMultiWindowLayout = function() {
    const cMainLayout = app.sessionData.baseLayoutObject;

    const data = {
      view: 'root',
      att: {
        id: 'maincontainer',
        name: 'maincontainer'
      }
    };

    oDhxTree = $.dhxObject(data);

    akioma.root = oDhxTree;

    akioma.swat.Root = akioma.swat.SwatFactory.createSwatObject(akioma.root);

    return app.controller.launchContainer({
      target: oDhxTree,
      proc: 'include.r',
      para: `RunFile=${cMainLayout}&Page=*`,
      data: true,
      async: true
    });

  };

  // build multiwindow header with taskbar
  akioma.mlBuildHeader = function(main_layout, oSelf) {
    akioma.root.getDescendant('panelset').dhx.setSeparatorSize(0, 0);

    const cHeaderID = `main-header-${dhtmlx.uid()}`;

    main_layout.closest('.dhxform_obj_material').addClass('headerFormContainer').addClass('taskbar-header');

    main_layout.attr('id', cHeaderID);
    main_layout.addClass('multiwindow-header-cont');
    // build main app layout
    akioma.headerSection = new dhtmlXLayoutObject({
      pattern: '3W',
      parent: cHeaderID,
      offsets: { // optional, offsets for fullscreen init
        top: 0, // you can specify all four sides
        right: 0, // or only the side where you want to have an offset
        bottom: 0,
        left: 0
      }
    });
    akioma.headerSection.setSeparatorSize(0, 0);
    akioma.headerSection.setSeparatorSize(1, 0);
    main_layout = akioma.headerSection;

    let bContainsTaskbar = app.containsVueTaskbarHeader;
    let a = main_layout.cells('a');
    $(a.cell).addClass('headerlayouts-ml');
    a.hideHeader();
    const themeName = akioma.Themes.getTheme();
    if (themeName == 'akioma-simple' ||
            themeName == 'feather' ||
            themeName == 'dark-simple')
      UserProfile.homeButtonWidth = 60;
    else
      UserProfile.homeButtonWidth = 101;

    a.setWidth(UserProfile.homeButtonWidth);
    a.fixSize(1, 0);

    a = main_layout.cells('a');
    const toolbar_2 = a.attachToolbar();

    toolbar_2.setIconset('awesome');
    toolbar_2.addButtonTwoState('home', 1, '', 'switchdesktopsbtn', 'switchdesktopsbtn');

    // Handling for stacked icons
    if (!app.containsVueTaskbarHeader) {
      let cStackedTemplate = `<div class='homeIcon'>
                    <i class='fa-2x fa-fw firstIcon switchdesktopsbtn'></i>
                    <i class='fa-stack-1x fa-inverse secondIcon switchdesktopsbtn'</i>
                </div>`;
      if (themeName == 'feather' || themeName == 'playground-dark') {
        cStackedTemplate = `
                    <div class='homeIcon'>
                    </div>
                `;
      }
      const cIdPrefix = toolbar_2.idPrefix;
      const oHomeBtn = toolbar_2.objPull[`${cIdPrefix}home`].obj;
      $(oHomeBtn).find('i').remove();
      $(oHomeBtn).html(cStackedTemplate);

      if (akioma.getAkIdMode() == 'extended')
        $(oHomeBtn).attr('akId', 'homeButton');
    }


    toolbar_2.attachEvent('onBeforeStateChange', (id, state) => {
      if (id == 'home') {
        // check if no window in multiwindow winframe
        if (akioma.oWindowsParentCell.childs.length == 0 && state)
          return false;
      }

      return true;
    });

    // on home toolbar
    toolbar_2.attachEvent('onStateChange', (id, state) => {

      // home toolbar button click
      if (id == 'home')
        akioma.toggleAkMultiWindow(state);

    });

    toolbar_2.setItemState('home', true);
    akioma.homeMultiWindowsToolbar = toolbar_2;


    const b = main_layout.cells('b');
    main_layout.cells('b').hideHeader();
    akioma.headerLayoutCell = b;

    // /old taskbar
    if (!bContainsTaskbar) {
      akioma.dataViewTabs = b.attachDataView({
        height: 40,
        type: {
          height: 40,
          width: 180,
          template: 'headertaskbar'
        },
        drag: true,
        select: true
      });
      akioma.dataViewTabs.define('type', 'headertaskbar');

    } else {
      // setup taskbar for osiv
      akioma.setupVueTaskbarHeader(main_layout, b);
    }


    main_layout.cells('c').hideHeader();

    const c = main_layout.cells('c');

    c.setWidth(UserProfile.panelSize);

    akioma.toolBar = c.attachForm([]);
    $(c.cell).addClass('system-header');
    // styling systemPanel
    akioma.toolBar.cont.style.padding = 0;

    // add background here and remove video bg
    if ($('#mw-wins-container')[0]) {
      const themeName = akioma.Themes.getTheme();

      if (themeName == 'feather')
        $('#mw-wins-container')[0].style.backgroundImage = 'url(/imgs/bgs/akiomabg.png)';
      else
        $('#mw-wins-container')[0].style.backgroundImage = 'url(/imgs/bgs/akioma_cloud.jpg)';


      $('#mw-wins-container')[0].style.backgroundSize = 'cover';
    }

    // global user profile dropdown menu here
    const opts = {
      type: 'combo',
      width: 100,
      className: 'userprofile',
      label: '',
      name: 'afterLogin',
      icon: '/dhtmlx/imgs/akioma/businessman-20.png',
      options: []
    };
    const userProfileMenu = app.controller.parseProc({
      view: 'menustructure',
      att: {
        id: (app.userMenuCode || 'UserProfileDropdown'),
        depth: 0
      },
      sub: []
    }, oSelf);

    let it;

    const onfinish = function() {
      userProfileMenu.scanMenuItemsObj(item => {
        opts.options.push({ text: item.label, value: item.id });
      });
      akioma.toolBar.addItem(null, opts, 1);

      it = akioma.toolBar.getCombo('afterLogin');
      $(it.base).addClass('akUserProfile');

      // load user avatar
      const typeKey = 'img.usr.main';
      $.ajax({
        type: 'POST',
        url: '/akioma/getdata.xml',
        dataType: 'json',
        data: `action=getImage&refHdl=${app.sessionData.userHdl}&Type=${typeKey}`,
        success: function(data) {
          let imageUrl = data.image;
          if (!imageUrl)
            imageUrl = '/imgs/profile/default-avatar.jpg';

          $(it.base).prepend(`<img src="${imageUrl}" alt="${app.sessionData.userDesc}"/>`);
        },
        error: function(xhr, textStatus, errorThrown) {
          akioma.notification({ type: 'error', text: `Error getting data from 'getimage': ${textStatus} -> ${errorThrown}` });
        }
      });

      $(it.DOMlist).addClass('topimp');

      it.unSelectOption();
      it.attachEvent('onChange', function(value) {

        if (value)
          userProfileMenu.applyAction(value, this);

        it.unSelectOption();
      });

      $(it.base).parent().append(`<p class="akUserInfo" title="${app.sessionData.userDesc}">${app.sessionData.userKey}</p>`);
    };
    const userProfileMenuPromise = userProfileMenu.loadMenuElements();
    userProfileMenuPromise.done(onfinish);

    const createMenuLookup = structureCode => {
      const oSelf = this;
      const aMainMenuOptions = [];
      let result;

      const mainMenu = app.controller.parseProc({
        view: 'menustructure',
        att: {
          id: structureCode,
          depth: 1
        },
        sub: []
      }, akioma.root.getDescendant('panelset').childs[0]);

      const onfinish = function() {
        mainMenu.scan((itemid, label, icon) => {
          aMainMenuOptions.push({ id: itemid, text: label, img: icon });
        });
        const templateMenu = Handlebars.compile(akioma.handlebarsTemplates.autocompleteSearchBT.templateMenu);
        const oData = { results: aMainMenuOptions };
        result = templateMenu(oData);
        mainMenu.result = result;
        oSelf.menuLookup = mainMenu;
      };
      mainMenu.loadMenuElements(onfinish);
    };

    const addHeaderAutoCompleteSearch = () => {
      const optsSearch = { type: 'input', label: '', name: 'searchInput', width: 240, className: 'searchInput', inputHeight: 20 };
      akioma.toolBar.addItem(null, optsSearch, 1);
      const oInput = akioma.toolBar.getInput('searchInput');

      // create menu lookup here
      createMenuLookup(app.headerLookupMenuCode || 'headerLookup');

      myPop = new dhtmlXPopup({ form: akioma.toolBar, id: ['searchInput'] });
      myPop.p.className += ' mainLookup';
      const myLookup = null;
      self.gridSearchInputPopup = myPop;

      const oMouseTrap = new Mousetrap(oInput);
      oMouseTrap.bind('enter', function() {
        if (!myPop.isVisible()) myPop.show('searchInput');
        else {
          const searchItem = $(myPop.p).find('.searchItem').first()[0];
          const val = $(myPop.p).find('.searchItem').first().attr('id');
          app.controller.launchContainer({
            self: searchItem,
            para: `SelfHdl=${val}&Page=0,1`,
            extLink: val,
            data: true

          });

          this.gridSearchInputPopup.hide();
        }

      });
      oMouseTrap.bind('down', () => {
        $(myPop.p).find('.searchItem').first().focus();
        $(myPop.p).find('.searchItem').first().addClass('selected');

      });
      oMouseTrap.bind('up', () => {
        $(myPop.p).find('.searchItem').last().focus();
        $(myPop.p).find('.searchItem').last().addClass('selected');

      });

      akioma.toolBar.attachEvent('onInputChange', id => {
        if (id != 'searchInput') return;
        if (myLookup == null)
          if (!myPop.isVisible()) myPop.show('searchInput');

        if (!myPop.isVisible()) myPop.show('searchInput');
        const text = akioma.toolBar.getItemValue('searchInput');

        autoCompleteSearch(text);

      });
    };
    userProfileMenuPromise.always(addHeaderAutoCompleteSearch);


    function autoCompleteSearch(cInput) {
      const serviceURI = window.location.origin,
        jsdoSettings = {
          serviceURI: serviceURI,
          catalogURIs: `${serviceURI}/web/Catalog/Akioma.Swat.AutoCompleteSearchBT`,
          authenticationModel: progress.data.Session.AUTH_TYPE_FORM
        },
        oSelf = this;

      // after addCatalog perform a business task method call
      const onAfterAddCatalog = res => {
        // check for error type object
        if (akioma.isObjOfTypeError(res)) {
          akioma.log.error(res);
          return;
        }

        const dsTasks = new progress.data.JSDO ({ name: 'Akioma.Swat.AutoCompleteSearchBT' });

        // call param with input search term

        const oParameter = {
          plcParameter: {
            name: 'omniSearch',
            key: cInput,
            pos: ''
          }
        };

        // Perform the asynchronous call
        // true = asynchronously
        const call = dsTasks.GetMatchingResultSet (oParameter, true).deferred.promise();

        call.then (({ request }) => {

          // Retrieve JavaScript object from ABL serializable parameter
          const oOutput = request.response;

          const templateResults = Handlebars.compile(akioma.handlebarsTemplates.autocompleteSearchBT.templateResults);

          const oData = { results: oOutput.dsAutoCompleteSearch.autoCompleteSearch.searchResult };

          let result = templateResults(oData);
          if (oSelf.menuLookup)
            result += oSelf.menuLookup.result;
          myPop.attachHTML(result);
          myPop.p.children[1].className = 'dhx_popup_arrow dhx_popup_arrow_bottom';

          $('.searchItem').on('click', function() {
            const val = $(this).attr('id');
            app.controller.launchContainer({
              self: this,
              para: `SelfHdl=${val}&Page=0,1`,
              extLink: val,
              data: true
            });
            oSelf.gridSearchInputPopup.hide();
            return true;
          });

          $('.footer-add').on('click', function() {
            const id = $(this).attr('id');
            oSelf.menuLookup.applyAction(id, oSelf);
            oSelf.gridSearchInputPopup.hide();
            return true;
          });

          let $current;
          $('.searchItem:first-child').addClass('selected');
          $('.searchItem').mouseover(function() {
            $('.searchItem').removeClass('selected');
            $current = $(this);
            $current.addClass('selected');
          });
          $('.searchItem').mouseout(() => {
            $current.removeClass('selected');
          });

          // arrow-keys navigation for the omnisearch
          $(myPop.p).find('.searchItem').first().attr('tabindex', '0');
          $(myPop.p).find('.searchItem').each(function() {
            const searchItem = this;
            const oMouseTrap = Mousetrap(this);
            oMouseTrap.bind('down', () => {

              $(searchItem).removeClass('selected');
              if ($(searchItem).next().length) {
                $current = $(searchItem).next();
                $current.attr('tabindex', '0');
                $current.focus();
                $current.addClass('selected');
              } else {
                setTimeout(() => {
                  akioma.toolBar.getInput('searchInput').focus();
                }, 0);
              }

            });
            oMouseTrap.bind('up', () => {
              $(searchItem).removeClass('selected');
              if ($(searchItem).prev().length) {
                $current = $(searchItem).prev();
                $current.attr('tabindex', '0');
                $current.focus();
                $current.addClass('selected');
              } else {
                setTimeout(() => {
                  akioma.toolBar.getInput('searchInput').focus();
                }, 0);

              }

            });

            oMouseTrap.bind('enter', () => {
              $(searchItem).removeClass('selected');
              const val = $(searchItem).attr('id');
              app.controller.launchContainer({
                self: searchItem,
                para: `SelfHdl=${val}&Page=0,1`,
                extLink: val,
                data: true

              });
              oSelf.gridSearchInputPopup.hide();
              return true;
            });

          });


        }); // call.done

      }; // addCatalog.done

      // add catalog first
      akioma.restSession.addCatalog(jsdoSettings.catalogURIs).then(res => {
        onAfterAddCatalog(res);
      }).catch(e => {
        akioma.log.error(e);
      });

    }

    function addSocketIOStatusBtn() {
      // socket.io connection status
      akioma.toolBar.addItem(null, { type: 'btn2state', label: '', className: 'connectionBtn', checked: true }, 3);
      akioma.connectionBtn = $(akioma.toolBar.cont).find('.dhx_toolbar_btn:last-of-type');
      akioma.messaging.checkConnectionStatus();
    }

    userProfileMenuPromise.always(addSocketIOStatusBtn);

    akioma.aMultiWindows = [];

    bContainsTaskbar = app.containsVueTaskbarHeader;
    if (!bContainsTaskbar) {
      // toggle desktop state
      akioma.dataViewTabs.attachEvent('onItemClick', (id, ev) => {
        // dataview close and fit window
        const $target = $(ev.target),
          bClose = $target.hasClass('close-icon'),
          bFit = $target.hasClass('fit-icon');

        let oWin = null;
        if (bClose || bFit)
          oWin = $.getObjectByName({ id: id });


        if (bClose) {
          oWin.promptWindowClose().done(() => {
            if (akioma.dataViewTabs.get(id))
              akioma.dataViewTabs.remove(id);
          });

          return false;
        } else if (bFit) {
          oWin.parent._fitInWin(oWin.parent, id);
          return false;
        }

        // sets multiwindows winframe as visible
        akioma.toggleAkMultiWindow(false);
      });

      // on before drop arranges windows one next to the other
      akioma.dataViewTabs.attachEvent('onBeforeDrop', (context, ev) => {
        try {
          if (!akioma.toggleFlag) {
            akioma.stopWindowLoad = true;
            akioma.toggleAkMultiWindow(false);
          }

          const oWinSrc = akioma.aMultiWindows[context.start],
            oWinTrg = akioma.aMultiWindows[context.target];
          // move to left and right
          oWinSrc.posWindowAndCollapse('right');
          oWinTrg.posWindowAndCollapse('left');

          Animation.playRippleOnDrop(ev);

          if (oWinSrc && oWinTrg)
            return false;
        } catch (e) {
          console.error('Error on drop dataview item', e);
        }
      });
    }

    akioma.autoStartLaunchPromise = app.controller.launchContainer({
      proc: app.sessionData.autostartObjects || 'mainw.r',
      untilParent: true,
      data: true
    });

    // Logic for ExternalScreen with Multiple Windows and 3rd external screen, 2nd external screen
    if (akioma.ExternalScreen.externalScreenContainer) {
      app.controller.launchContainer({ containerName: akioma.ExternalScreen.externalScreenContainer });

      const oData = { name: akioma.ExternalScreen.externalScreenContainer };

      localStorage.setItem(`ExternalScreen.${akioma.ExternalScreen.externalScreenContainer}`, JSON.stringify(oData));
    }
  };

  // builds the akioma Vue Grouped Taskbar
  akioma.setupVueTaskbarHeader = function(main_layout, b) {

    // creates vue taskbar instance
    akioma.VueTaskbar = akioma.TaskbarObject.createInstance();

    // new taskbar
    $(b.cell).find('.dhx_cell_cont_layout')[0].innerHTML = '<div id="headertaskbar"></div>';

    akioma.VueTaskbar.onClose = function(item) {

      try {
        const id = item.id;
        const oWin = akioma.aMultiWindows[id];

        if (item.hasChanges) {
          akioma.message({
            type: 'warning',
            title: akioma.tran('messageBox.title.warning', { defaultValue: 'Warning' }),
            text: akioma.tran('messageBox.text.unsavedChanges', { defaultValue: 'Closing the window will remove all the unsaved changes. Are you sure you want to continue?' }),
            buttonText: akioma.tran('messageBox.button.yes', { defaultValue: 'close Window' }),
            callback: function(result) {
              if (result)
                oWin.closeAndSelectLastAvail(item);
              else
                return;
            }
          });

        } else
          oWin.closeAndSelectLastAvail(item);


      } catch (e) {
        console.error('Error on drop dataview item', e);
      }
    };


    function closeAllAndSelectNextAvail(items) {
      for (const index in items) {
        const item = items[index];
        const id = item.id;
        const oWin = akioma.aMultiWindows[id];

        if (oWin)
          oWin.close();

      }

      if (akioma.oWindowsParentCell.dhx.getTopmostWindow())
        akioma.VuexStore.commit('taskbar/SELECT_ITEM', akioma.oWindowsParentCell.dhx.getTopmostWindow().akElm.opt.id);

    }

    akioma.VueTaskbar.onCloseGroup = function(group, items) {

      try {
        const bGroupHasChanges = akioma.VuexStore.getters['taskbar/getHasChangesGroup'](group.id);


        if (bGroupHasChanges) {
          akioma.message({
            type: 'warning',
            title: akioma.tran('messageBox.title.warning', { defaultValue: 'Warning' }),
            text: akioma.tran('messageBox.text.unsavedChanges', { defaultValue: 'Closing the window will remove all the unsaved changes. Are you sure you want to continue?' }),
            buttonText: akioma.tran('messageBox.button.yes', { defaultValue: 'close Window' }),
            callback: function(result) {
              if (result) {
                akioma.VuexStore.dispatch('taskbar/removeGroup', group);
                closeAllAndSelectNextAvail(items);
              } else
                return;
            }
          });

        } else {
          closeAllAndSelectNextAvail(items);
          akioma.VuexStore.dispatch('taskbar/removeGroup', group);
        }


      } catch (e) {
        console.error('Error on drop dataview item', e);
      }
    };

    akioma.VueTaskbar.onSelect = function(item) {
      let id = item.id;

      id = id.replace('_extern', '');

      const windowControl = akioma.aMultiWindows[id];

      windowControl.disableFocusSelect = true;
      windowControl.dhx.bringToTop();
      windowControl.disableFocusSelect = false;

      if (windowControl.parent === akioma.oWindowsParentCell)
        akioma.toggleAkMultiWindow(false);
      else
        akioma.toggleAkMultiWindow(true);

      if (!isNull(akioma.TaskbarObject.events.onSelect))
        akioma.TaskbarObject.events.onSelect.forEach(callback => callback(id, windowControl));

    };

    akioma.TaskbarObject.mount('#headertaskbar');
  };


  // ****************** window **********************************
  $.extend({
    /**
     * SwatWindow Control
     * @class ak_window
     * @tutorial window-desc
     * @param {Object} options Repository attributes for SwatWindow.
     * @param {string} options.PrivateData
     * @param {string} options.LayoutOptions List of multi-layout options for the object.
     * @param {boolean} options.isModal defines if the container should be modal (blocking all other ui elements)
     * @param {string} options.LABEL WidgetAttributes Label
     * @param {string} options.icon Image displayed in window title and shortTitle. The image can be a font-icon with the following attributes: </br>
     * 1. Css attributes, defined like this: fa fa-user#color:red </br>
     * 2. Css classes, defined like this: fa fa-user#_style:module_prod
     * 3. Stacked font icons, defined like this: fas fa-circle$fas fa-flag. Both icons also support Css attributes or Css classes, like this: fas fa-circle#color:red$fas fa-flag#_style:module_prod </br>
     * @param {string} options.HtmlClass CLASS property for an HTML tag
     * @param {string} options.typeRange The type range which makes up a combobox in a Toolbar
     * @param {string} options.TITLE Browser, Frame, Dialog and Window title
     * @param {string} options.shortTitle Title displayed in taskbar
     * @param {boolean} options.floating Default is "false", if specified "true" it will set the window as floating above all the other windows
     * @param {string} options.EventPreInitialize
     * @param {string} options.EventOnInitialize client side code to run when Container has been initialized
     * @param {string} options.hasChangesStyle Default is "akhaschanges" if isModal is set to false, otherwise it will be empty. In case it is empty the styling will not be applied.
     # Example - SWAT-1251
      * The init hide all tabs method.
      * <pre class="prettyprint source">
      * <code>
      * akioma.onInitHideItems = function(self){
      *    // useful for hiding all pages on init
      *     var oTabbar = self.controller.getDescendant('tabbar');
      *     oTabbar.hideAllPages();
      *
      * };
      * </code></pre>
      * @fires ak_window#EventPreInitialize
      * @fires ak_window#EventOnInitialize
      * @fires ak_window#EventOnClose
      */
    ak_window: function(options) {
      const oSelf = this,
        defaults = {
          type: 'window',
          pos: 0,
          close: false,
          state: 'active',
          title: '',
          width: 1024,
          height: 650,
          htmlclass: '',
          tab: null
        };

      this.focusTrap = null;
      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;
      this.view = options.view;

      this.registerVuexModule = true;
      this.registerDynObject = true;

      if (options.securityRestrictions)
        this.securityRestrictions = options.securityRestrictions;

      this._pendingMenuStructures = [];
      this._pendingMenuPromises = [];

      this.lastUIContext = null;


      let oParent, bStick;
      // check if window needs to be added in mainLayout
      if (akioma.cWindowParentCell) {
        // if main window already added the main window, add next window to the desktop area

        bStick = (this.opt.floating || false);

        if (!this.opt.modal && !bStick) {
          // checks for first window
          const bMultiWindowMainEmpty = ($('#mw-main-win-container')[0].children.length == 0);

          let cId;
          if (bMultiWindowMainEmpty) {
            this.bMainWindow = true;
            akioma.mainWindowAkUX = this;
            cId = 'mw-main-win-container';
          } else
            cId = 'mw-wins-container';

          // show/hide main content winframes
          const cExistingVPID = akioma.oWindowsParentCell.dhx.vp.getAttribute('id');
          if (bMultiWindowMainEmpty) {
            if (cExistingVPID != 'mw-main-win-container')
              akioma.oWindowParentCell.dhx.attachViewportTo(cId);

            akioma.toggleAkMultiWindow(true);
          } else {
            if (cExistingVPID != 'mw-wins-container')
              akioma.oWindowsParentCell.dhx.attachViewportTo(cId);

            akioma.toggleAkMultiWindow(false);
            akioma.aMultiWindows[this.opt.id] = this;
          }
        }
        oParent = this.parent.dhx;
      } else
        oParent = akioma.mainWindows;


      if (this.opt.modal || (bStick && !akioma.toggleFlag)) {
        akioma.createModalWindows();
        oParent = akioma.oWindowsModals;
      }


      if (oParent) {
        if (!this.opt.customStyle)
          this.opt.customStyle = this.view;

        const bCentered = true;

        // hardcoded login screen values
        if (this.opt.name == app.sessionData.loginScreen) {
          this.opt.width = 487;
          this.opt.height = 370;
        }

        const oWindow = oParent.createWindow({
          id: this.opt.id,
          caption: this.opt.title,
          width: this.opt.width,
          height: this.opt.height,
          move: true,
          park: true,
          resize: true,
          center: bCentered,
          akElm: this
        });

        if (akioma.aWindows)
          akioma.aWindows.push(this);

        oWindow.akiomaWindowObject = this;

        oWindow.button('stick').show();
        oWindow.button('stick').enable();

        // @todo dehardcode the screens below
        if (options.att.name == 'chooseFileG' || options.att.name == 'salesConfigW' || options.att.name == 'salesConfMaintW' || options.att.name == 'rbDataFieldMaintW')
          oWindow.stick();


        if (this.close)
          oWindow.button('close').enable();
        else
          oWindow.button('close').disable();

        // setting id for setting title lateron

        $(oWindow.wins.vp)
          .find('.dhxwin_text_inside')
          .attr('id', `${this.opt.id}_Title`);

        let tmResize = null;

        const doOnResizeEnd = () => {
          const bVisibleWin = $(oWindow.cell).is(':visible');

          if (bVisibleWin) {
            if (oSelf.bRecentlyResized) {
              oSelf.loadSettingsForWindow();
              oSelf.bRecentlyResized = false;
            }
          }

          if (akioma.mainWindowView)
            akioma.bResizeNextHomeViewToggle = true;
          else if (!akioma.mainWindowView)
            akioma.bResizeNextMultiWindowsViewToggle = true;

          oSelf.bRecentlyResized = true;

          if (bVisibleWin && (typeof oWindow.isMaximized == 'function') && (oWindow.isMaximized())) {
            oWindow.akElm.stopSavingSettingsFlag = true;
            oWindow.minimize();
            oWindow.maximize();
            oWindow.akElm.stopSavingSettingsFlag = false;
          }

          if (akioma.headerSection) {
            akioma.headerSection.setSizes();
            akioma.headerSection.cells('c').setWidth(UserProfile.panelSize);
          }
        };
        oSelf.doOnResizeStart = function() {
          if (tmResize)
            window.clearTimeout(tmResize);

          tmResize = window.setTimeout(doOnResizeEnd, 200);
        };

        oSelf.bRecentlyResized = true;

        window.addEventListener('resize', oSelf.doOnResizeStart, false);

        // -------------------
        const items = [
          { id: 'transparent', text: akioma.tran('windowButton.set.transparent', { defaultValue: 'Make transparent' }) },
          { id: 'clearWindowProfile', text: akioma.tran('windowButton.set.clearWindowProfile', { defaultValue: 'Clear Window Layout' }) },
          { id: 'removeFromServerCache', text: akioma.tran('windowButton.set.removeFromServerCache', { defaultValue: 'Refresh Screen' }) },
          { id: 'enableFields', text: akioma.tran('windowButton.set.enableFields', { defaultValue: 'Änderungs-Modus' }) },
          { id: 'disableFields', text: akioma.tran('windowButton.set.disableFields', { defaultValue: 'Ansichts-Modus' }) },
          { id: 'designer-open', text: akioma.tran('windowButton.set.designerOpen', { defaultValue: 'Open Designer' }) },
          { id: 'designer', text: akioma.tran('windowButton.set.designerPosition', { defaultValue: 'Position Designer In External Screen' }) },
          { id: 'designer-open-external', text: akioma.tran('windowButton.set.designerOpenExternal', { defaultValue: 'Open Designer In External Screen' }) }
        ];

        if (!securityIsRestricted('CanEnableRibbonButtons'))
          items.push({ id: 'enable-all-ribbon-items', text: 'Enable all ribbon items' });

        // -------------------
        oWindow.addUserButton('conf', 1, 'Configure');
        let contextMenu = oWindow.button('conf').attachContextMenu({
          icons_path: '/imgs/',
          items: items
        });
        if (contextMenu) {
          contextMenu.attachEvent('onClick', id => {
            switch (id) {
              case 'saveProfile':
                oSelf.saveProfile();
                break;
              case 'saveWindowProfile':
                oSelf.saveWindowProfile();
                break;
              case 'clearWindowProfile':
                oSelf.clearWindowProfile();
                break;
              case 'removeFromServerCache':
                oSelf.removeFromServerCache();
                break;
              case 'enableFields':
                oSelf.callInChildren('setProperty', { readOnly: false });
                break;
              case 'disableFields':
                oSelf.callInChildren('setProperty', { readOnly: true });
                break;
              case 'showFields':
                oSelf.callInChildren('setProperty', { security: { hidden: false } });
                break;
              case 'transparent':
                akioma.makeTransparent(oSelf.dynObject);
                break;
              case 'hideFields':
                oSelf.callInChildren('setProperty', { security: { hidden: true } });
                break;
              case 'designer-open': {
                const oDesigner = akioma.root.dynObject.getFirstChildByType('designer');
                if (oDesigner) {
                  akioma.message({
                    type: 'confirm',
                    title: 'Open Designer',
                    text: 'Open the repository object in the existing Designer?',
                    callback: function(result) {
                      if (result) {
                        oDesigner.dynObject.container.controller.setActiveWindow();
                        akioma.repository.openObjectInDesigner(oSelf);
                      } else
                        akioma.launchDesignerWithSelection(oSelf);

                    }
                  });

                } else
                  akioma.repository.openObjectInDesigner(oSelf);
                break;
              }
              case 'designer':
                akioma.tempRepoObject = oWindow;

                akioma.repository.positionDesignerInExternalScreen(oSelf);

                break;
              case 'designer-open-external':
                akioma.tempRepoObject = oWindow;

                akioma.repository.openObjectInDesigner(oSelf, true);

                break;

              case 'enable-all-ribbon-items': {
                const oRibbon = oSelf.dynObject.getFirstChildByType('ribbon');
                oRibbon.enableAllItems();

                break;
              }
            }
          });
        }


        oWindow.setIconCss('');

        oWindow.addUserButton('set', 1, 'Settings');
        contextMenu = oWindow.button('set').attachContextMenu({
          icons_path: '/imgs/',
          items: [
            {
              id: 'posClip',
              text: akioma.tran('windowButton.set.posClip', { defaultValue: 'In Fenster einpassen' }),
              img: 'fenster_einpassen.png'
            },
            {
              id: 'posUp',
              text: akioma.tran('windowButton.set.posUp', { defaultValue: 'Oben' }),
              img: 'fenster_oben.png'
            },
            {
              id: 'posDown',
              text: akioma.tran('windowButton.set.posDown', { defaultValue: 'Unten' }),
              img: 'fenster_unten.png'
            },
            {
              id: 'posLeft',
              text: akioma.tran('windowButton.set.posLeft', { defaultValue: 'Links' }),
              img: 'fenster_links.png'
            },
            {
              id: 'posRight',
              text: akioma.tran('windowButton.set.posRight', { defaultValue: 'Rechts' }),
              img: 'fenster_rechts.png'
            },
            {
              id: 'lastSize',
              text: akioma.tran('windowButton.set.lastSize', { defaultValue: 'Letzte Position herstellen' }),
              img: 'fenster_letzte_Position_herstellen.png'
            },
            {
              id: 'background',
              text: akioma.tran('windowButton.set.background', { defaultValue: 'Move to background' })
            }
          ]
        });

        if (contextMenu) {
          contextMenu.attachEvent('onClick', id => {
            switch (id) {
              case 'posClip':
                oSelf.posWindow('clip');
                break;
              case 'lastSize':
                oSelf.posWindow('last');
                break;
              case 'posUp':
                oSelf.posWindow('up');
                break;
              case 'posDown':
                oSelf.posWindow('down');
                break;
              case 'posLeft':
                oSelf.posWindowAndCollapse('left');
                break;
              case 'posRight':
                oSelf.posWindowAndCollapse('right');
                break;
              case 'background':
                if (!oSelf.dhx.isKeepOnBottom())
                  oSelf.dhx.keepOnBottom(true);
                else
                  oSelf.dhx.keepOnBottom(false);

                break;
            }
          });
        }


        // setup mousetrap object for this window for keyboard shortcuts
        const cell = oWindow.cell;
        $(cell).attr('tabindex', 1);

        this.oMouseTrap = new Mousetrap(cell);

        if (this.opt.name != 'mainDesktopW') {

          this.oMouseTrap.bind(akioma.shortcutManager.get('FocusRibbonToolbar'), e => {
            if (e.preventDefault)
              e.preventDefault();

            const oControl = oSelf.getDescendant('ribbon') || oSelf.getDescendant('toolbar');
            akioma.focusRibbonToolbar(oControl);

            if (e.stopPropagation)
              e.stopPropagation();
          });
        }


        // check only if there is a default go or default cancel attribute set to any, toolbar, form, ribbon children
        // only apply if there is one
        // do not trigger when in form opened state for combobox, lookup or any other form element

        oWindow.button('close').attachEvent('onClick', () => {
          oSelf.promptWindowClose();
        });

        const oDataViewTabs = akioma.dataViewTabs;

        if (oDataViewTabs) {
          oDataViewTabs.attachEvent('onItemClick', id => {
            if (!oSelf.dhx.wins)
              return;

            let oWin;
            if (oSelf.userData && oSelf.userData.type == 'widget')
              oWin = akioma.mainWindows.window(id);
            else
              oWin = oSelf.dhx.wins.window(id);

            if (oSelf.opt.state == 'min')
              oSelf.showhide('win');

            if (oWin)
              oWin.bringToTop();
          });
        }

        oSelf._iDataSourceToLoad = 0;

        akioma.BaseLayoutContainer.call(this);
        // flag for progress State
        oSelf.hasActiveProgressState = false;

        /**
         * @property iDataSourceToLoad is the number of dataSources that still need to be loaded inside the current Window.
         * When it reaches "0" it will emit the dataLoaded event
         */
        Object.defineProperties(this, {
          'iDataSourceToLoad': {
            'get': function() {
              return this._iDataSourceToLoad;
            },
            'set': function(newval) {
              // console.log('set dataSource to load for ' + this.opt.name, newval);
              if (newval == 0)
                akioma.eventEmitter.emit(`${this.opt.id}:dataLoaded`, this);

              this._iDataSourceToLoad = newval;
            }
          }
        });

        // on move and resize window
        oWindow.attachEvent('onMoveFinish', oSelf.onMoveResizeSave);
        oWindow.attachEvent('onResizeFinish', win => {
          oSelf.loadSettingsForWindowInnerPanels();
          if (oSelf.windowLoaded && oSelf.windowLoaded == true)
            oSelf.onMoveResizeSave(win, true);
        });


        oWindow.attachEvent('onMaximize', win => {

          oSelf.loadSettingsForWindowInnerPanels();

          win.setPosition(0, 0);
          if (oSelf.windowLoaded && oSelf.windowLoaded == true)
            oSelf.onMoveResizeSave(win, true);
        });

        oWindow.attachEvent('onMinimize', win => {
          oSelf.loadSettingsForWindowInnerPanels();

          if (oSelf.windowLoaded && oSelf.windowLoaded == true)
            oSelf.onMoveResizeSave(win, true);
        });

        // if bordercolor -> set border
        if (this.opt.htmlclass) {
          $(oWindow)
            .find('div:first')
            .addClass(this.opt.htmlclass);
        }

        // translated titleShort and window title
        this.opt.titleShort = akioma.tran(`${this.opt.name}.` + '_titleShort', { defaultValue: this.opt.titleShort });
        this.opt.TITLE = akioma.tran(`${this.opt.name}.` + '_title', { defaultValue: this.opt.TITLE });

        // extend object with internal settings
        $.extend(this, {
          dhx: oWindow,
          dom: null,
          tabs: oDataViewTabs,
          menu: null
        });

        this._setProgressState(true);

      } else
        !_isIE && console.error(`No valid parent for window ${this.name}`);

    }
  });

  // methods for window
  Object.assign($.ak_window.prototype, akioma.BaseLayoutContainer.prototype, {
    componentOptions: function() {
      const oSelf = this;
      return {
        watch: {
          'state.attributes.hasChanges': function(newValue, oldValue) {
            oSelf._hasChangesWatcher(newValue, oldValue);
          },
          'state.attributes.customStates': {
            fn: function(customStates) {
              oSelf._hasCustomStates(customStates);
            },
            watchOptions: { deep: true }
          },
          'state.attributes.hasErrors': function(newValue, oldValue) {
            oSelf._hasErrorsWatcher(newValue, oldValue);
          },
          'getters.getCustomStatesUp': {
            fn: function(customStates) {
              oSelf._customStatesWatcher(customStates);
            },
            params: [this.opt.id]
          }
        }
      };
    },

    /**
     * Method for returning the title of the window
     * @instance
     * @memberof ak_window
     * @returns {string}
     */
    getTitle: function() {
      const oSelf = this;
      const htmlCell = oSelf.dhx.cell;
      return $(htmlCell).siblings('.dhxwin_hdr').text();
    },

    /**
     * Watcher for the customState attribute
     * @param {array} customStates
     * @protected
     * @returns {void}
     */
    _hasCustomStates: function(customStates) {
      if (isNull(customStates)) return null;

      let value = '';
      for (let index = 0; index < customStates.length; index++) {
        if (index == 0)
          value += customStates[index].name;
        else
          value += ` ${customStates[index].name}`;

      }

      if (value)
        $(this.dhx.cell).parent().attr('akcustomstate', value);
      else
        $(this.dhx.cell).parent().removeAttr('akcustomstate');

      akioma.eventEmitter.emit([ 'Window', 'CustomStateChanged' ], { self: this, value });

      const stateData = this._getCustomStateData(this.oVuexState.attributes);
      this._callEventOnStateChanged(stateData);
    },

    promptWindowClose: function() {
      const deferred = $.Deferred();
      const oWin = this;
      if (this.oVuexState.attributes.hasChanges) {
        akioma.message({
          type: 'warning',
          title: akioma.tran('messageBox.title.warning', { defaultValue: 'Warning' }),
          text: akioma.tran('messageBox.text.unsavedChanges', { defaultValue: 'Closing the window will remove all the unsaved changes. Are you sure you want to continue?' }),
          buttonText: akioma.tran('messageBox.button.yes', { defaultValue: 'close Window' }),
          callback: function(result) {
            if (result) {
              oWin.close();
              deferred.resolve();
            } else
              return;
          }
        });

      } else {
        oWin.close();
        deferred.resolve();
      }
      return deferred.promise();
    },
    /**
     * HasErrors Watcher for vuex store
     * @param {boolean} newValue
     */
    _hasErrorsWatcher: function(newValue) {

      const hasErrorsStyle = this.oVuexState.attributes.hasErrorsStyle;
      if (hasErrorsStyle !== '') {
        $(this.dhx.cell).parent().attr(hasErrorsStyle, newValue);

        const bContainsTaskbar = app.containsVueTaskbarHeader;

        // contains new vue taskbar
        if (bContainsTaskbar) {
          akioma.VuexStore.commit('taskbar/SET_HAS_ERRORS_ITEM', {
            hasErrors: newValue,
            id: this.opt.id
          });

        } else if (akioma.dataViewTabs) {
          // contains dhtmlx dataview taskbar
          const item = akioma.dataViewTabs.get(this.opt.id);
          if (item) {
            item.cssError = newValue;
            akioma.dataViewTabs.update(this.opt.id, item);
          }
        }
      }
      this._callEventOnStateChanged({ state: 'hasErrors', value: newValue });
    },
    _customStatesWatcher: function(customStates) {
      let value = '';
      for (let index = 0; index < customStates.length; index++) {
        if (index == 0)
          value += customStates[index].name;
        else
          value += ` ${customStates[index].name}`;

      }
      if (value)
        $(this.dhx).attr('akcustomstate', value);
      else
        $(this.dhx).removeAttr('akcustomstate');

    },
    /**
     * HasChanges vuex watcher
     * @param {boolean} newValue
     */
    _hasChangesWatcher: function(newValue) {
      const oSelf = this;

      const hasChangesStyle = oSelf.oVuexState.attributes.hasChangesStyle;

      if (hasChangesStyle !== '') {
        $(oSelf.dhx.cell).parent().attr(hasChangesStyle, newValue);

        const bContainsTaskbar = app.containsVueTaskbarHeader;

        // contains new vue taskbar
        if (bContainsTaskbar) {
          akioma.VuexStore.commit('taskbar/SET_HAS_CHANGES_ITEM', {
            hasChanges: newValue,
            id: oSelf.opt.id
          });

        } else if (akioma.dataViewTabs) {
          // contains dhtmlx dataview taskbar
          const item = akioma.dataViewTabs.get(oSelf.opt.id);
          if (item) {
            item.cssText = newValue;
            item.cssErrors = oSelf.oVuexState.attributes.hasErrors;

            akioma.dataViewTabs.update(oSelf.opt.id, item);
          }
        }
      }
      this._callEventOnStateChanged({ state: 'hasChanges', value: newValue });

    },
    _getAllDataAndRepoPromises: function() {
      const oSelf = this;

      const aPromises = [];
      const aInnerElms = oSelf.getAllChildrenByType([ 'frame', 'tabbar', 'datagrid2' ], true); // get all nested children of type frame, tabbar, grid

      if (aInnerElms.length > 0) {

        // for each inner add to promise array and wait until content is loaded
        for (const x in aInnerElms) {
          const oNestedElm = aInnerElms[x];
          if (oNestedElm.view == 'frame' && oNestedElm.opt.file && oNestedElm.promiseFrame && oNestedElm.promiseFrame.state() !== 'resolved')
            aPromises.push(oNestedElm.promiseFrame);
          else if (oNestedElm.view == 'tabbar' && oNestedElm.promiseTab && oNestedElm.promiseTab.state() !== 'resolved')
            aPromises.push(oNestedElm.promiseTab);


        }

      }


      return aPromises;
    },
    /**
     * Method for setting the progress cursor on for this particular window
     * @param {boolean} bPauseProgressOff A value of true will stop all the future progressOff method calls for this element until reset
     * @memberof ak_window
     * @instance
     * @returns {void}
     */
    progressOn(bPauseProgressOff) {
      if (bPauseProgressOff !== undefined)
        this.dhx.stopProgressOff = bPauseProgressOff;

      this.dhx.progressOn();
      this.hasActiveCursor = true;
    },

    /**
     * Method for setting the progress cursor off for this particular window
     * @param {boolean} bPauseProgressOff A value of true will stop all the future progressOff method calls for
     *  this element until reset
     * @memberof ak_window
     * @instance
     * @returns {void}
     */
    progressOff(bPauseProgressOff) {
      if (bPauseProgressOff !== undefined)
        this.dhx.stopProgressOff = bPauseProgressOff;

      this.dhx.progressOff();
      this.hasActiveCursor = false;
    },
    /**
     * Sets blocking progress state on window, waitCursor debug mode support
     * @memberof ak_window
     * @instance
     * @param {boolean} bState
     */
    _setProgressState(bState) {
      try {
        const oSelf = this;
        $(oSelf.dhx.cell).parent().attr('akloadingWindow', bState);

        oSelf.hasActiveProgressState = bState;

        if (akioma.WaitCursor.debugMode)
          $(oSelf.dhx.cell).parent().addClass('debug-mode');
        else
          $(oSelf.dhx.cell).parent().removeClass('debug-mode');


        if (akioma.headerLayoutCell)
          $(akioma.headerLayoutCell.cell).attr('akloadingWindow', bState);

      } catch (e) {
        akioma.log.error(e);
      }
    },
    _waitForAllNestedLoaded: function() {
      const oSelf = this;
      const aPromises = oSelf._getAllDataAndRepoPromises();
      const deferred = $.Deferred();

      // now all nested screens are loaded
      $.when(...aPromises).then(() => {
        // look for next level nested loaded possible screens and wait for those to load also before triggering the event
        // if found just wait again for all the promises to finish up
        const aNestPromises = oSelf._getAllDataAndRepoPromises();
        let oPromise;
        if (aNestPromises.length > 0)
          oPromise = oSelf._waitForAllNestedLoaded();

        // if there are no more nested objects of type tabbar or frame
        //  then wait for the datasource to load, if not yet loaded, and trigger the eventAfterTabSelected

        if (oPromise) {
          oPromise.done(() => {
            deferred.resolve();
          });
        } else
          deferred.resolve();


      });

      return deferred.promise();
    },
    // finish construct *****************
    finishConstruct: function() {
      const oSelf = this;

      const oAllRepoLoaded = this._waitForAllNestedLoaded();

      oAllRepoLoaded.done(() => {
        const iParentWinDataToLoad = oSelf.iDataSourceToLoad;

        //  parentwin dataload flag is setup, if gt 0 means it's loading BEs
        if (iParentWinDataToLoad > 0) {
          akioma.eventEmitter.once(`${oSelf.opt.id}:dataLoaded`, () => {
            // else set tab as loaded
            oSelf._setProgressState(false);
            akioma.eventEmitter.emit(`${oSelf.opt.id}:repoAndDataLoaded`); // signals full load
          });
        } else { // else set tab as loaded
          oSelf._setProgressState(false);
          akioma.eventEmitter.emit(`${oSelf.opt.id}:repoAndDataLoaded`); // signals full load
        }
      });


      oSelf._dispatch('setHasChangesStyle', this.opt.hasChangesStyle);
      oSelf._dispatch('setHasErrorsStyle', this.opt.hasErrorsStyle);


      // check for size of panelset
      const oPanel = this.getDescendant('panelset');
      if (oPanel) { // now set dimension
        if (oPanel.opt.width && oPanel.opt.height) {
          const $windowsParentViewport = $(oSelf.parent.dhx.vp);

          let iHeight;
          if (oPanel.opt.height > $windowsParentViewport.height())
            iHeight = $windowsParentViewport.height();
          else
            iHeight = oPanel.opt.height;

          const iWidth = oPanel.opt.width;

          this.dhx.setDimension(iWidth, iHeight);
        }

        if (oPanel.opt.top && oPanel.opt.left)
          this.dhx.setPosition(oPanel.opt.left, oPanel.opt.top);

        if (oPanel.opt.maximized)
          this.dhx.maximize();
      } else akioma.log.warn('no panel for window:', this);

      this.setTitle('');

      // set akStyle in window
      $(oSelf.dhx.cell.parentElement).attr('akStyle', oSelf.opt.customStyle);

      const oWindow = this.dhx;
      const bModal = oSelf.opt.modal || false;
      if (this.bMainWindow && !bModal) {

        this._addResizeObserver();
        oWindow.hideHeader();
        oWindow.setPosition(0, 0);
        oWindow.allowResize(false);
      }


      if (bModal)
        oWindow.setModal(true);


      if (bModal)
        $(oWindow.cell).parent().attr('modalwindow', true);


      if (akioma.adjustMainWinStyling && this.bMainWindow) {
        app.controller.callAkiomaCode(oSelf, '$ akioma.adjustMainWinStyling(self);');
        this.panelsetAdjust = true;
      }


      if (this.opt.floating)
        oWindow.stick();


      if (!this.opt.icon)
        this.opt.icon = 'fa fa-window';

      const isLoginScreen = app.sessionData.loginScreen === this.opt.name;
      const oUserProfileWinSettings = this.dynObject.loadUserProfileSettings();

      if (oUserProfileWinSettings == undefined && !this.opt.modal && !isLoginScreen) {
        this.stopSavingSettingsFlag = true;
        oWindow.maximize();
        this.stopSavingSettingsFlag = false;
      }

      // load initial Settings for window
      if (!isLoginScreen)
        this.loadSettingsForWindowInnerPanels();

      oWindow.setIconCss('');

      if (akioma.getSessionProperty('customization') == 'OSIV' && securityIsRestricted('CanUseDeveloperTools')) {
        oWindow.button('set').hide();
        oWindow.button('stick').hide();
        oWindow.button('park').hide();
      }

      if (securityIsRestricted('CanUseDeveloperTools'))
        oWindow.button('conf').hide();

      if (securityIsRestricted('CanEnableRibbonButtons') || !oSelf.dynObject.getFirstChildByType('ribbon')) {
        const contextMenu = oWindow.button('conf').getContextMenu();
        if (contextMenu)
          contextMenu.hideItem('enable-all-ribbon-items');
      }

      this.focusTrap = focusTrap.createFocusTrap(this.dhx.cell, { allowOutsideClick: true });
      this.focusTrap.activate();
    },

    /**
     * Adding resize observer for keeping maximized master layout size
     * @memberof ak_window
     * @private
     * @instance
     */
    _addResizeObserver() {
      this.dhx.wins.vp.resizeObserver = new ResizeObserver(() => {
        const width = this.dhx.wins.vp.offsetWidth,
          height = this.dhx.wins.vp.offsetHeight;
        this.dhx.setDimension(width, height);
      });
      this.dhx.wins.vp.resizeObserver.observe(this.dhx.wins.vp);
    },

    /**
     * Method called after window has been attached in DOM
     * @param {array} namespace
     * @private
     * @instance
     * @memberof ak_window
     * @return {void}
     */
    afterAttachWindow: function(namespace) {
      const oWindow = this.dhx;
      this.attachWindowMessages(oWindow, namespace);
    },
    /**
     * Method for attaching window panel vue instance
     * @param {dhtmlxWindowCell} oWindow
     * @param {namespace} namespace Control namespace
     * @private
     * @instance
     * @memberof ak_window
     * @return {void}
     */
    attachWindowMessages: function(oWindow, namespace) {
      const id = oWindow.akElm.opt.id;
      $(oWindow.cell).find('.dhx_cell_cont_layout').first().prepend(`<div id="${id}"></div>`);
      const el = $(oWindow.cell).find(`#${id}`)[0];

      const controller = oWindow.akElm.childs.find(child => [ 'toolbar', 'ribbon', 'menustructure', 'businessEntity' ].indexOf(child.view) === -1);
      const dataSource = controller && controller.dataSource;

      let datasourceNamespace = [];
      if (dataSource)
        datasourceNamespace = dataSource.getFilteredNamespace();

      try {
        this.VueInstance = new akioma.VueInstancesFactory('panel-messages', {
          namespace,
          datasourceNamespace,
          dataSource,
          controller
        });
        this.VueInstance.mount(el);
      } catch (e) {
        console.error(e);
      }
    },

    /**
     * Add an panel message and return the index of the message
     * @param  {object} msg contains the message text and type
     * @instance
     * @memberOf ak_panel
     * @return {number} the index of the message
     */
    displayWindowMessage: function(msg) {
      this._dispatch('setPanelMSG', msg);
      return this._getters('getPanelMSG').length - 1;
    },

    /**
     * Remove an panel message from a controller by the given id
     * @param  {number} id the index of the message
     * @returns {void}
     * @instance
     * @memberOf ak_panel
     */
    removeWindowMessageById: function(id) {
      this._dispatch('removePanelMsgById', id);
    },

    /**
     * Remove all panel message from a controller
     * @returns {void}
     * @instance
     * @memberOf ak_panel
     */
    clearAllWindowMessages: function() {
      this._dispatch('clearPanelMsg');
    },

    /**
     * Gets the next window. If there is no next window, gets the first window
     * @return {ak_window} Next window
     * @instance
     * @memberOf ak_window
     */
    getNextWindow: function() {
      const oSelf = this;
      if (oSelf.dhx.isModal())
        return;
      for (let i = 0; i < akioma.aWindows.length; i++) {
        if (oSelf.opt.id == akioma.aWindows[i].opt.id) {
          const oNext = akioma.aWindows[i + 1] || akioma.aWindows[1];
          if (!oNext.dhx.isModal())
            return oNext;
        }
      }
    },

    /**
     * Gets the previous window. If there is no previous window, gets the last window
     * @return {ak_window} Previous window
     * @instance
     * @memberOf ak_window
     */
    getPrevWindow: function() {
      const oSelf = this;
      if (oSelf.dhx.isModal())
        return;
      for (let i = 0; i < akioma.aWindows.length; i++) {
        if (oSelf.opt.id == akioma.aWindows[i].opt.id) {
          const oPrev = (i - 1 > 0) ? akioma.aWindows[i - 1] : akioma.aWindows[akioma.aWindows.length - 1];
          if (!oPrev.dhx.isModal())
            return oPrev;
        }
      }
    },

    /**
     * Returns the bottommost window
     * @memberof ak_window
     * @instance
     * @returns {Object} Bottommost window
     */
    getBottommostWindow: function() {
      const oSelf = this;
      const oWindow = oSelf.parent.dhx.getBottommostWindow().akElm;
      return oWindow;
    },

    /**
     * Returns the topmost window
     * @memberof ak_window
     * @instance
     * @returns {Object} Topmost window
     */
    getTopmostWindow: function() {
      const oSelf = this;
      const oWindow = oSelf.parent.dhx.getTopmostWindow().akElm;
      return oWindow;
    },

    /**
     * Sets the window as active
     * @instance
     * @memberOf ak_window
     */
    setActiveWindow: function() {
      const oSelf = this;
      oSelf.dhx.bringToTop();
      akioma.toggleAkMultiWindow(false);
    },
    /**
     * Method called after the window has been fully parsed, repository data loaded and parsed
     * @memberof ak_window
     * @instance
     * @returns {void}
     */
    afterStructParsed: function() {

      // stop here if no more pendingMenuStructures
      if (this._pendingMenuStructures.length == 0)
        return false;

      // load MenuStructure codes
      const menuStructuresLoaded = akioma.MenuStructureFactory.loadMenus(this._pendingMenuStructures);

      menuStructuresLoaded.done(res => {
        for (const x in res) {
          const menu = this._pendingMenuStructures[x];

          const promise = this._pendingMenuPromises[x];
          // add to memory cache
          akioma.MenuStructureFactory.add(menu.opt.id, res[x]);

          if (res[x] === null) {
            akioma.notification({
              type: 'error',
              text: `Could not find menustructure code '${menu.opt.id}'.`
            });
            promise.resolve([]);
          } else
            promise.resolve(res[x]); // resolve menu structure

        }
      }).fail(e => {
        akioma.MenuStructureFactory.showMenuLoadError(e, this._pendingMenuStructures);
      });

    },

    endConstruct: function() {
      const oSelf = this;

      // init event
      dhtmlx.delay(function() {
        /**
         * Client side code to run when Container has been initialized
         * @event ak_window#EventOnInitialize
         * @type {object}
         */
        if (this.opt.onInit)
          applyAkiomaCode(this, this.opt.onInit);

        oSelf.callRules({
          eventEntity: oSelf.dynObject.name,
          eventName: 'onInitialize',
          eventSource: ''
        });
      }, this, null, 10);

      // load window settings
      oSelf.loadSettingsForWindow();


      // checks autoAdd BE record option
      oSelf.checkAutoAdd();

      oSelf.windowLoaded = true;

      const aPanels = this.getAllChildrenByType('panelset');
      if (aPanels.length > 0) {
        const oPanelSet = aPanels[aPanels.length - 1];
        const oPanel = oPanelSet.getFirstPanel();
        if (oPanel) {
          oPanel.setActivePanelState();
          oPanel.setFocus(true);
        }
      }
    },

    _resizeWindowToFit: function() {

      try {
        const oSelf = this,
          iVPWidth = oSelf.parent.dhx.vp.offsetWidth,
          iVPHeight = oSelf.parent.dhx.vp.offsetHeight,
          aDim = oSelf.dhx.getDimension(),
          aPos = oSelf.dhx.getPosition(),
          iWindowWidth = aDim[0],
          iWindowHeight = aDim[1];
        let iWindowX = aPos[0],
          iWindowY = aPos[1];

        let iDifWidth;
        if (iWindowWidth > iVPWidth)
          iDifWidth = iVPWidth - (iWindowWidth + iWindowX);


        let iDifHeight;
        if (iWindowHeight > iVPHeight)
          iDifHeight = iVPHeight - (iWindowHeight + iWindowY);


        let iNewWidth = iWindowWidth,
          iNewHeight = iWindowHeight;

        if (iDifWidth) {
          iNewWidth = iVPWidth;
          iWindowX = 0;
        }

        if (iDifHeight) {
          iNewHeight = iVPHeight;
          iWindowY = 0;
        }

        oSelf.dhx.setPosition(iWindowX, iWindowY);
        oSelf.dhx.setDimension(iNewWidth, iNewHeight);
      } catch (e) {
        akioma.log.error(e);
      }
    },

    loadSettingsForWindow: function() {
      // load window saved session
      const oSelf = this;
      oSelf.stopSavingSettingsFlag = true; // stop next changes localstorage savings //TURNOFF

      // don't load settings for base layout
      if (oSelf.bMainWindow)
        return false;

      oSelf.dynObject.loadUserProfileSettings();
      oSelf.stopSavingSettingsFlag = false; // savings to localstorage //TURNON
    },

    /**
     * Method for loading the settings of each nested child object
     * @instance
     * @memberof ak_window
     * @return  {object}  The object parent control for which the settings will be loaded
     */
    loadSettings: function(oParent) {

      for (const x in oParent.childs) {

        const child = oParent.childs[x];

        if (child.dynObject && child.dynObject.loadUserProfileSettings)
          child.dynObject.loadUserProfileSettings();

        if (child.childs.length > 0)
          this.loadSettings(child);


      }
    },
    // loads panel settings for responsive
    loadSettingsForWindowInnerPanels: function() {
      const oSelf = this;
      const bPreviousSavingSettingsFlag = oSelf.stopSavingSettingsFlag;

      oSelf.stopSavingSettingsFlag = true; // stop next changes localstorage savings //TURNOFF

      try {
        oSelf.loadSettings(oSelf);
      } catch (e) {
        akioma.log.error('Inner window panels settings loading failed', e);
      }

      oSelf.stopSavingSettingsFlag = bPreviousSavingSettingsFlag || false;
    },
    loadPanelSettings: function(oSelf, aWindowPanels) {
      oSelf.children('panelset', function() {
        let i = 0;
        const aWinDim = oSelf.dhx.getDimension(),
          iWOnePercent = aWinDim[0] / 100,
          iHOnePercent = aWinDim[1] / 100;

        this.children('panel', function() {
          if (this.dhx.getId() == aWindowPanels[i].id) {
            this.dhx.fixSize(true, true);
            if (!isNaN(aWindowPanels[i].wPercent))
              this.dhx.setWidth(aWindowPanels[i].wPercent * iWOnePercent);

            if (!isNaN(aWindowPanels[i].hPercent))

              this.dhx.setHeight(aWindowPanels[i].hPercent * iHOnePercent);

            this.dhx.fixSize(false, false);

            if (aWindowPanels[i].collapsed)
              this.dhx.collapse();
            else
              this.dhx.expand();

            this.children('tabbar', function() {
              let t = 0;
              this.children('tab', function() {
                t++;
                let oPanelset = this.getDescendant('panelset');

                const oFrame = this.getDescendant('frame');

                if (oFrame)
                  oPanelset = oFrame.getDescendant('panelset');


                const oCurrentTab = $.grep(aWindowPanels[i].tabs, e => e.tab_id == t)[0];
                if (oPanelset && oCurrentTab) {
                  let p = 0;
                  oPanelset.children('panel', function() {
                    const oItem = this.dhx;
                    if (oItem.getId() == oCurrentTab.panels[p].id) {
                      oItem.fixSize(true, true);
                      if (!isNaN(oCurrentTab.panels[p].wPercent) && oCurrentTab.panels[p].wPercent > 0)
                        oItem.setWidth(oCurrentTab.panels[p].wPercent * iWOnePercent);

                      if (!isNaN(oCurrentTab.panels[p].hPercent) && oCurrentTab.panels[p].hPercent > 0)
                        oItem.setHeight(oCurrentTab.panels[p].hPercent * iHOnePercent);

                      oItem.fixSize(false, false);

                      if (oCurrentTab.panels[p].collapsed)
                        oItem.collapse();
                      else
                        oItem.expand();
                    }
                    p++;
                  });
                }
              });
            });
          }
          i++;
        });

      });
    },
    // this is used to automatically adding new business entity record
    checkAutoAdd: function() {
      const oSelf = this;
      // create new business entity record if autoadd option is true
      if (oSelf.opt.autoAdd) {
        const BE = oSelf.dynObject.getLink('PRIMARYSDO:TARGET').controller;

        // wait for initial BE load before adding new record
        BE.dhx.attachEvent('onXLE', () => {

          // create default object from businessentity schema
          const oSchema = BE.jsdo.getSchema();
          const newRecordObj = {};
          for (const i in oSchema) {
            if (oSchema[i].default != undefined)
              newRecordObj[oSchema[i].name] = oSchema[i].default;
          }

          // add new record
          const iIndex = BE.dhx.add(newRecordObj);
          BE.dhx.setCursor(iIndex);
        });
      }
    },
    onMoveResizeSave: function(win, bParent) {
      const oSelf = bParent ? this : this.akElm;
      // this flag will stop settings saving
      if (oSelf.stopSavingSettingsFlag == true)
        return true;

      if (oSelf.objectsnamespace.length > 0)
        oSelf.dynObject.saveUserProfileSettings();

    },
    // make desktop widget
    makeDesktop: function() {
      const oSelf = this,
        oWin = this.dhx;
      const $window = $(oSelf.dhx.cell).parent();
      const iOpacity = 0.8;
      oWin.allowPark();
      oWin.keepInViewport(true);


      // make window transparent
      $window.css({
        'background': `rgba(255, 255, 255, ${iOpacity})`,
        'opacity': 0.95
      });


      // remove close-icon and maximize
      $window.find('.dhxwin_button_close,.dhxwin_button_stick').remove();

      akioma.aNonDesktopWindows.splice(akioma.aNonDesktopWindows.indexOf(oSelf), 1);

      akioma.aDesktopWindows.push(oSelf);

      akioma.toolBar.setItemValue('mDesktop', true);

      oSelf.bIsDesktopWidget = true;


      const iWOnePercent = window.innerWidth / 100,
        iHOnePercent = window.innerHeight / 100;

      let windowOpen = !(oWin.isParked());
      // save window position and size on park
      $window.find('.dhxwin_button_park').on('click', () => {
        // windowOpen = !(oWin.isParked());
        if (!windowOpen) {
          if (oSelf.parkedDownPosDim != null) {
            // if negative x or y reset to 0

            if (oSelf.parkedDownPosDim.xPercent < 0)
              oSelf.parkedDownPosDim.xPercent = 0;
            if (oSelf.parkedDownPosDim.yPercent < 0)
              oSelf.parkedDownPosDim.yPercent = 0;

            if (oSelf.parkedDownPosDim.yPercent > (100 - (40 / window.innerHeight * 100)))
              oSelf.parkedDownPosDim.yPercent = 55;

            if (oSelf.parkedDownPosDim.xPercent > (100 - (100 / window.innerWidth * 100)))
              oSelf.parkedDownPosDim.xPercent = 70;

            if (!isNaN(oSelf.parkedDownPosDim.xPercent) && !isNaN(oSelf.parkedDownPosDim.wPercent)) {
              oWin.setDimension(oSelf.parkedDownPosDim.wPercent * iWOnePercent, oSelf.parkedDownPosDim.hPercent * iHOnePercent);
              oWin.setPosition(oSelf.parkedDownPosDim.xPercent * iWOnePercent, oSelf.parkedDownPosDim.yPercent * iHOnePercent);
            }

          }
          $window.attr('parkedwin', false);
        } else {

          if (oSelf.parkedUpPosDim != null) {
            // if negative x or y reset to 0
            if (oSelf.parkedUpPosDim.xPercent < 0)
              oSelf.parkedUpPosDim.xPercent = 0;
            if (oSelf.parkedUpPosDim.yPercent < 0)
              oSelf.parkedUpPosDim.yPercent = 0;

            if (oSelf.parkedUpPosDim.yPercent > (100 - (40 / window.innerHeight * 100)))
              oSelf.parkedUpPosDim.yPercent = 55;

            if (oSelf.parkedUpPosDim.xPercent > (100 - (100 / window.innerWidth * 100)))
              oSelf.parkedUpPosDim.xPercent = 70;

            if (!isNaN(oSelf.parkedUpPosDim.xPercent) && !isNaN(oSelf.parkedUpPosDim.yPercent))
              oWin.setPosition(oSelf.parkedUpPosDim.xPercent * iWOnePercent, oSelf.parkedUpPosDim.yPercent * iHOnePercent);
          }
          $window.attr('parkedwin', true);
        }
        oSelf.onMoveResizeSave(oWin, true);

        windowOpen = (!windowOpen);
      });
    },

    // data available ***************
    dataAvailable: function(oElm) {
      this.openQuery(oElm);
    },

    // add record in promary data
    addRecord: function(oElm) {
      // try to get primarysdo
      const aData = app.messenger.getObjects(this.dynObject, `PRIMARYSDO:TRG:${this.opt.name}`);
      if (aData) {
        for (const i in aData) {
          if (aData[i].controller.addRecord)
            aData[i].controller.addRecord(oElm);
        }
      }
    },

    // open query
    openQuery: function(oElm) {
      // try to get primarysdo
      const cObjectID = this.opt.name + this.opt.linkid;
      const aData = app.messenger.getObjects(this.dynObject, `PRIMARYSDO:TRG:${cObjectID}`);
      if (aData) {
        for (const i in aData)
          aData[i].openQuery(oElm);
      }
    },
    setQueryParam: function(oElm) {
      const cObjectID = this.opt.name + this.opt.linkid;
      const aData = app.messenger.getObjects(this.dynObject, `PRIMARYSDO:TRG:${cObjectID}`);
      if (aData) {
        for (const i in aData)
          aData[i].setQueryParam(oElm);
      }
    },

    setWaitState: function(cState) {
      const oPanel = this.getDescendant('panelset');
      if (oPanel) {
        if (cState == 'wait')
          oPanel.dhx.progressOn();
        else
          oPanel.dhx.progressOff();
      }
    },

    showhide: function(cType) {

      switch (cType) {
      // check if we have to bring it on top
        case 'tab':
          if (this.opt.state != 'min' && !this.dhx.isOnTop()) {
            this.dhx.bringToTop();
            return;
          }
          break;
        // minimize window
        case 'min':
          if (this.opt.state != 'min') {
            this.dhx.hide();
            this.opt.state = 'min';
          }
          break;
      }
      switch (this.opt.state) {

        // minimize it
        case 'active':
          this.dhx.hide();
          this.opt.state = 'min';
          break;
        // activate it
        default:
          this.dhx.show();
          this.dhx.bringToTop();
          this.opt.state = 'active';
          break;
      }
    },

    /**
     * Method for closing the window and selecting the last active one
     * @param {boolean} [item] The taskbar object for the window. If not specified, will be determined within the method
     * @memberof ak_window
     * @instance
     */
    closeAndSelectLastAvail: function(item) {

      if (isNull(item))
        item = akioma.VuexStore.getters['taskbar/getItemById'](this.opt.id);

      this.close();

      const currentActive = akioma.VuexStore.getters['taskbar/getActiveItemInGroup'](item.parentid);
      if (currentActive) {
        akioma.VuexStore.dispatch('taskbar/selectItem', currentActive.id);
        const item = akioma.VuexStore.getters['taskbar/getItemById'](currentActive.id);
        akioma.VueTaskbar.config.globalProperties.$events.$emit('taskbar:selectItem', item);
        const wdw = akioma.aMultiWindows[currentActive.id];
        if (wdw)
          wdw.setActiveWindow();
      }
    },

    close: function() {

      const oSelf = this;

      /**
       * Client side code to run when a window has been closed
       * @event ak_window#EventOnClose
       * @type {object}
       */
      if (oSelf.opt.EventOnClose)
        app.controller.callAkiomaCode(oSelf, oSelf.opt.EventOnClose);

      // desktops screens
      if (akioma.desktopsEnabled) {
        const iNonIndex = akioma.aNonDesktopWindows.indexOf(this);
        if (iNonIndex > -1)
          akioma.aNonDesktopWindows.splice(iNonIndex, 1);
        const iWidIndex = akioma.aDesktopWindows.indexOf(this);
        if (iWidIndex > -1)
          akioma.aDesktopWindows.splice(iWidIndex, 1);

        if (akioma.aNonDesktopWindows.length == 0)
          toggleDesktop(true);

      }

      akioma.eventEmitter.emit([ 'Window', 'Close' ], this);

      // destroy element
      this.destruct();
      window.removeEventListener('resize', this.doOnResizeStart, false);


      if (akioma.oWindowsParentCell.childs > 0)
        akioma.toggleAkMultiWindow(false);
      else if (akioma.mainWindowView && akioma.oWindowsParentCell.childs.length == 0)
        akioma.toggleAkMultiWindow(true);


      return true;
    },

    setTitle: function(cTitle) {

      let cLabel = (this.opt.label) ? this.opt.label : '';
      cLabel = akioma.tran(`${this.opt.name}.` + '_title', { defaultValue: cLabel });

      if (app.sessionData.objectNamesInTitles)
        cLabel = `${this.opt.name}  | ${cLabel}`;

      // set title in window
      const oSelf = this;
      const BE = this.dynObject.getLink('PRIMARYSDO:TARGET');

      if (!BE || !this.opt.TITLE) {
        // build up title parts and set
        const aTitleParts = [];
        if (cLabel !== undefined && cLabel !== '')
          aTitleParts.push(cLabel);

        if (cTitle !== undefined && cTitle !== '')
          aTitleParts.push(cTitle);

        this.dhx.setText(aTitleParts.join(' - '));

        this.opt.title = cTitle;

        return;
      }

      // if there is a businessEntity PRIMARYSDO Link then
      const beObj = BE.controller;

      let cTemplate = oSelf.getTitleTemplate();

      // no filtering on BE, set title
      const isFetchable = !(BE.controller.stop || BE.controller.opt.initialFetch == '#none');
      if (!isFetchable) {
        const template = Handlebars.compile(cTemplate);
        cTemplate = template({});
        oSelf.dhx.setText(cTemplate);
      } else {
        const setWindowTemplateTitle = () => {
          const cTemplateCompiled = BE.controller.getRecordDescription(cTemplate);
          oSelf.dhx.setText(cTemplateCompiled);
        };

        setWindowTemplateTitle();

        beObj.addAfterFillOnceCallback(setWindowTemplateTitle);

        if (beObj.fireAfterUpdate)
          beObj.fireAfterUpdate(setWindowTemplateTitle);

      }

    },
    /**
     * Method for returning title template that includes icon
     * @memberOf ak_window
     * @instance
     * @returns {string}
     */
    getTitleTemplate() {
      let cTemplate = this.opt.TITLE;
      let imgText = '';

      if (this.opt.icon && this.opt.icon.startsWith('fa'))
        imgText = `{{#iconHelper "${this.opt.icon}" "" this}}{{/iconHelper}}`;
      else if (this.opt.icon !== '')
        imgText = `{{#iconHelper ${this.opt.icon} "" this}}{{/iconHelper}}`;

      cTemplate = `${imgText} ${this.opt.TITLE}`;

      return cTemplate;
    },

    removeFromServerCache: function() {
      const oReturn = app.controller.callServerMethod('stubs/removeContainerFromCache.p', [
        { type: 'iCHAR', value: this.opt.name },
        { type: 'oCHAR', name: 'cResult' }
      ]);

      if (oReturn.cResult)
        akioma.messaging.info(oReturn.cResult);
    },

    // save profile ********************************
    saveProfile: function() {
      const oWindow = this.dhx;

      // get string for layout
      const aDim = oWindow.getDimension(),
        aPos = oWindow.getPosition(),
        aPar = [`${aDim[0]}|${aDim[1]}|${aPos[0]}|${aPos[1]}|${oWindow.isMaximized()}`];

      const pushPanel = (cId, oItem) => {
        aPar.push(`${cId}|${oItem.getId()}|${oItem.getWidth()}|${oItem.getHeight()}|${oItem.isCollapsed()}`);
      };
      const getPanelPar = (iId, oElm) => {
        // run function for each panel inside
        oElm.children('panel', function() {

          // export panel size
          pushPanel(iId, this.dhx);

          // on home page look for tabbar
          if (iId == 0) {
            this.children('tabbar', function() {
              let i = 0;
              this.children('tab', function() {
                i += 1;
                const oPanelset = this.getDescendant('panelset');
                if (oPanelset)
                  getPanelPar(i, oPanelset);
              });
            });
          }
        });
      };

      // get layout
      this.children('panelset', function() {
        getPanelPar(0, this);
      });

      // save profile
      $.ajax({
        type: 'POST',
        url: '/akioma/saveProfile.p',
        dataType: 'json',
        data: `Proc=${this.opt.name}&Profile=${aPar.join(';')}`,
        success: function() {},
        error: function(xhr, textStatus, errorThrown) {
          akioma.log.error(`Error saving profile: ${textStatus} -> ${errorThrown}`);
        }
      });
    },
    saveWindowProfile: function() {
      // save window profile settings to do
    },


    clearWindowProfile: function() {
      const oSelf = this;
      UserProfile.clearLocalProfileData(oSelf);
    },
    // position window **************
    posWindow: function(cType) {
      // get max sizes
      const aMaxDim = akioma.mainWindowAkUX.dhx.getDimension(),
        iMaxHeight = aMaxDim[1],
        iMaxWidth = aMaxDim[0],
        iTop = 0,
        iWidth = iMaxWidth / 2,
        iHeight = iMaxHeight;
      const iLeft = (cType == 'right') ? iWidth + 1 : 0;
      let aDim;

      this.stopSavingSettingsFlag = true;

      // now position window
      switch (cType) {
        case 'stayOnTop':
        // save position first
          this.dhx.stick();
          break;
        case 'max':
        // save position first
          aDim = this.dhx.getDimension();
          this.prop.lastWidth = aDim[0];
          this.prop.lastHeight = aDim[1];
          this.dhx.maximize();
          break;
        case 'last':
        // position to last size only if last size exists
          if (this.prop.lastLeft) {
            this.dhx.setDimension(this.prop.lastWidth, this.prop.lastHeight);
            this.dhx.setPosition(this.prop.lastLeft, this.prop.lastTop);

            this.dhx.bringToTop();
          }
          break;
        case 'clip':
        // save position first
          aDim = this.dhx.getDimension();
          this.prop.lastWidth = aDim[0];
          this.prop.lastHeight = aDim[1];
          aDim = this.dhx.getPosition();
          this.prop.lastLeft = Math.max(0, aDim[0]);
          this.prop.lastTop = Math.max(0, aDim[1]);
          this.dhx.setDimension(Math.min(this.prop.lastWidth, iMaxWidth - this.prop.lastLeft), Math.min(this.prop.lastHeight, iMaxHeight - this.prop.lastTop));
          this.dhx.setPosition(this.prop.lastLeft, this.prop.lastTop);
          this.dhx.bringToTop();
          break;
        case 'up':
        // save position first
          aDim = this.dhx.getDimension();
          this.prop.lastWidth = aDim[0];
          this.prop.lastHeight = aDim[1];
          aDim = this.dhx.getPosition();
          this.prop.lastLeft = aDim[0];
          this.prop.lastTop = aDim[1];

          this.dhx.setDimension(this.prop.lastWidth, iMaxHeight / 2);
          this.dhx.setPosition(this.prop.lastLeft, 0);
          this.dhx.bringToTop();
          break;
        case 'down':
        // save position first
          aDim = this.dhx.getDimension();
          this.prop.lastWidth = aDim[0];
          this.prop.lastHeight = aDim[1];
          aDim = this.dhx.getPosition();
          this.prop.lastLeft = aDim[0];
          this.prop.lastTop = aDim[1];

          this.dhx.setDimension(this.prop.lastWidth, iMaxHeight / 2);
          this.dhx.setPosition(this.prop.lastLeft, (iMaxHeight / 2) + 1);

          this.dhx.bringToTop();
          break;
        default:
          // save position first
          aDim = this.dhx.getDimension();
          this.prop.lastWidth = aDim[0];
          this.prop.lastHeight = aDim[1];
          aDim = this.dhx.getPosition();
          this.prop.lastLeft = aDim[0];
          this.prop.lastTop = aDim[1];

          this.dhx.setDimension(iWidth, iHeight);
          this.dhx.setPosition(iLeft, iTop);
          this.dhx.bringToTop();
          break;
      }

      this.stopSavingSettingsFlag = false;
    },
    posWindowAndCollapse: function(sPos) {
      const iMaxHeight = $(akioma.oWindowsParentCell.dhx.vp).height(),
        iMaxWidth = $(akioma.oWindowsParentCell.dhx.vp).width(),
        iTop = 0,
        iWidth = iMaxWidth / 2,
        iHeight = iMaxHeight;
      const iLeft = (sPos == 'right') ? iWidth + 1 : 0;
      let aDim;

      this.stopSavingSettingsFlag = true;

      // check for tree grid and collapse details panel
      const oTreeGrid = this.getDescendant('treegrid');
      if (oTreeGrid) {
        const oDetailsPanel = oTreeGrid.parent.parent.childs[oTreeGrid.parent.parent.childs.length - 1].dhx;
        if (oDetailsPanel)
          oDetailsPanel.collapse();
      }

      if (this.dhx.isMaximized())
        this.dhx.minimize();
      // save position first
      aDim = this.dhx.getDimension();
      this.prop.lastWidth = aDim[0];
      this.prop.lastHeight = aDim[1];
      aDim = this.dhx.getPosition();
      this.prop.lastLeft = aDim[0];
      this.prop.lastTop = aDim[1];

      this.dhx.setDimension(iWidth, iHeight);
      this.dhx.setPosition(iLeft, iTop);

      this.dhx.bringToTop();

      this.stopSavingSettingsFlag = false;

    },

    // destroy **********************
    destroy: function() {
      // check if dhtmlx element exists -> destroy it
      try {

        this.focusTrap.deactivate();
        this.focusTrap = null;

        akioma.swat.MasterLayout.lastActiveWindow = akioma.swat.MasterLayout.getTopMostWindow();
        akioma.swat.MasterLayout.enableLastFocusTrap();

        const oSelf = this;
        this.oMouseTrap.reset();
        delete this.oMouseTrap;

        this.lastUIContext = null;

        // remove global references of the currently destroyed window object
        if (akioma.aMultiWindows[this.opt.id] !== undefined)
          delete akioma.aMultiWindows[this.opt.id];

        // remove ContainerUUID from launchContainer manager
        try {
          if (this.ContainerUUID !== undefined) {
            const containerInx = app.controller.aInsOpened.indexOf(this.ContainerUUID);

            if (containerInx > -1)
              app.controller.aInsOpened.splice(containerInx, 1);

            delete app.controller.aInsWinObjects[this.ContainerUUID];
          }
        } catch (e) {
          console.warn(e);
        }


        if (this.tabs) {
          const oTabItem = this.tabs.item(this.opt.id);
          if (oTabItem != undefined)
            this.tabs.remove(this.opt.id);
        }

        akioma.aWindows.forEach((window, index) => {
          if (oSelf.opt.id == window.opt.id)
            akioma.aWindows.splice(index, 1);
        });
      } catch (e) {
        !_isIE && console.error(['Error closing tabbar item']);
      }

      // check if dhtmlx element exists -> destroy all attached elements
      if (this.dhx) {
        try {
          if (this.dhx.wins.vp.resizeObserver)
            this.dhx.wins.vp.resizeObserver.disconnect();

          // check if we are attached in a dhx element
          this.dhx.detachMenu();
          this.dhx.detachToolbar();
          this.dhx.detachObject(true);

          this.dhx.close();

        } catch (e) {
          !_isIE && console.error([ 'Error destroying', this.view, this.opt.name, e.message ]);
        }
      }
    },

    /**
     * Retrive customData of a ak_window object
     * @returns {object|string}
     * @instance
     * @memberof ak_window
     */
    getCustomData: function() {
      if (this.customData)
        return this.customData;

      return null;
    }
  });


})(jQuery, jQuery);
