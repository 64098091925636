/**
 * Themes class handling styling themes for SWAT
 * @class Themes
 * @namespace akioma
 */
akioma.Themes = class {

  /**
   * Method for setting the active theme, also loads the theme if new theme set
   *
   * @static
   * @param {Object} theme //theme object containing the key and selfhdl
   * @returns {void}
   * @memberof Themes
   * @returns {Promise}
   */
  static setTheme(theme) {
    return new Promise(resolve => {

      if (theme.key == akioma.Themes.activeTheme.key && theme.selfhdl == akioma.Themes.activeTheme.selfhdl)
        resolve(); // theme already set, prevent flickering
      else {
        // set new active theme and load theme file
        akioma.Themes.activeTheme.key = theme.key;
        akioma.Themes.activeTheme.selfhdl = theme.selfhdl;

        // save to vuex store
        if (akioma.VuexStore) {
          akioma.VuexStore.commit('swat/userprofile/SET_USER_ACTIVE_THEME', {
            key: akioma.Themes.activeTheme.key,
            selfhdl: akioma.Themes.activeTheme.selfhdl
          });
        }

        this.loadActiveTheme().then(() => {
          resolve();
        });
      }

    });
  }

  /**
   * Method for loading active Theme
   * @static
   * @memberof Themes
   * @returns {Promise}
   */
  static loadActiveTheme() {
    let themeUrl;

    return new Promise(resolve => {
      const tempLink = document.createElement('link');

      themeUrl = this.getThemeFilePath();

      $(tempLink).on('load', () => {
        resolve();
      });

      // remove old link
      document.getElementById('theme-updater').remove();

      tempLink.type = 'text/css';
      tempLink.rel = 'stylesheet';
      tempLink.href = themeUrl;
      tempLink.id = 'theme-updater';

      // append new link
      const head = document.getElementsByTagName('head')[0];
      head.appendChild(tempLink);

    });

  }

  /**
   * Returns theme file path, in development or minified for production
   * @static
   * @memberof Themes
   * @returns {string} themeUrl returns path to theme CSS file
   */
  static getThemeFilePath() {
    let themeUrl;
    const buildNo = $('#theme-updater').attr('build-number');
    const filePath = '/themes/';
    let prefix = '';

    // min build prefix
    if (buildNo)
      prefix = '.min';

    let fileName = `main-${akioma.Themes.activeTheme.key}${prefix}.css`;

    if (akioma.Themes.activeTheme.key === '')
      fileName = `main-${akioma.Themes.defaultTheme.key}${prefix}.css`;


    themeUrl = filePath + fileName;

    if (buildNo)
      themeUrl += (`?Build=${buildNo}`);

    return themeUrl;
  }

  /**
   * Return the current theme as a string
   *
   * @static
   * @memberof Themes
   * @returns {String}
   */
  static getTheme() {
    const key = akioma.Themes.activeTheme.key;
    if (!isNull(key))
      return key.toLowerCase();
    return key;
  }

  /**
   * Changes theme to new given object theme with key and selfhdl
   *
   * @static
   * @param {Object} self
   * @memberof Themes
   * @returns {void}
   */
  static changeTheme(self) {

    const newTheme = {
      key: self.controller.businessEntity.getFieldValue('selfkey'),
      selfhdl: self.controller.businessEntity.getFieldValue('selfhdl')
    };

    // in case of remove/delete in dynselect
    if (self.controller.getValue('id') === '' && self.controller.getValue('desc') === '')
      newTheme.key = newTheme.hdl = '';


    this.setTheme(newTheme);
  }

  /**
   * Set active/default theme in the UI dynSelect
   *
   * @static
   * @param {Object} self
   * @memberof Themes
   * @returns {void}
   */
  static setThemeDynSelect(self) {
    if (akioma.Themes.activeTheme.selfhdl) {
      const oThemeDynSelectCtrl = self.getObject('theme').controller.dynSelectControl;
      oThemeDynSelectCtrl.positionToRecord(akioma.Themes.activeTheme.selfhdl);
    }
  }

  /**
   * Set fallback theme in case of error before loading dynamic default theme
   * @static
   * @return  {void}
   */
  static setFallbackTheme() {
    const themeHref = $('#theme-updater').attr('href');
    if (akioma.Themes.defaultTheme.key == '' || themeHref == '')
      this.setTheme(akioma.Themes.fallbackTheme);

  }
};

// active user theme
akioma.Themes.activeTheme = {
  key: '',
  selfhdl: ''
};

// default theme
akioma.Themes.defaultTheme = { key: '' };

// default fallback theme, in case it fails before loading the dynamic default theme
akioma.Themes.fallbackTheme = { key: 'playground-theme' };
