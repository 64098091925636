// ********************* input ********************
$.extend({
  /**
         * SwatInput Control
         * @class ak_input
         * @augments ak_global
         * @param {Object} options Repository attributes for SwatInput.
         * @param {boolean} options.CanSort Set to FALSE if this field should not be used to sort the data object query.
         * @param {number} options.WIDTH-CHARS Width in characters. This may currently be used when rendering some objects. There is no get function, use getWidth to retrieve the realized value from an object.
         * @param {string} options.align alignment of column. can be left, right or centered
         * @param {string} options.HELP WidgetAttributes Help
         * @param {number} options.ROW Row position.
         * @param {boolean} options.Mandatory WidgetAttributes Mandatory
         * @param {string} options.VisualizationType WidgetAttributes VisualizationType
         * @param {string} options.LABEL WidgetAttributes Label
         * @param {string} options.EventEntryType Language of "entry" trigger
         * @param {number} options.HEIGHT-CHARS Height in characters. This may currently be used when rendering some objects. There is no get function, use getHeight to retrieve the realized value from an object.
         * @param {boolean} options.VISIBLE WidgetAttributes Visible
         * @param {boolean} options.CHECKED
         * @param {string} options.TemplateFile The relative path and filename of the static object used as the template at design time
         * @param {string} options.InitialValue WidgetAttributes InitialValue
         * @param {string} options.EventEntry Event which should run on entry of a field/focus in field.
         * @param {string} options.eventOnInfoSelected Executed on info click
         * @param {boolean} options.infoButton Show/hide info icon
         * @param {string} options.LIST-ITEMS
         * @param {boolean} options.CanFilter Set to FALSE if this field should not be used to filter the data object query.
         * @param {string} options.ViewAs The 'ViewAs' definition  of the selection.&#10;- combo-box,radio-set,selection-list OR browse &#10;- Uses colon as separator to define SUB-TYPE for combo-box or &#10;horizontal/vertical radio-set,
         * @param {string} options.FolderWindowToLaunch If Dynamics is running, this property specifies a window to launch upon the occurence of toolbar events "View", "Copy", "Modify" or "Add".
         * @param {string} options.EventLeaveType Language, the event is written in.
         * @param {string} options.TableName WidgetAttributes TableName
         * @param {string} options.FORMAT WidgetAttributes Format
         * @param {string} options.LIST-ITEM-PAIRS
         * @param {string} options.EventAkValidate Client side validation code (JavaScript) <br>
         * <a href="/sports-webui/docs/Customer_onCustomerSalesrepValueChanged.ts.html#" target="_blank">Value Changed Client Logic Example</a><br>
         * This sample can be tested in the Customer Details window, in the Salesrep field
         * @param {string} options.DATA-TYPE WidgetAttributes Data-Type
         * @param {string} options.Validation Validation-Rules, e.g. ValidInteger,ValidEMail...
         * @param {string} options.FilterOperator
         * @param {boolean} options.LABELS If false then NO-LABEL is used.  This attribute applies to most field level widgets and frames
         * @param {string} options.FieldName The name of the associated SDO field this SmartDataField maps to. This is usually 'set' from the containing SmartDataViewer.
         * @param {boolean} options.ENABLED WidgetAttributes Enabled
         * @param {boolean} options.CreateField
         * @param {string} options.EventLeave Event when leaving a field
         * @param {string} options.SUBTYPE
         * @param {boolean} options.MULTIPLE
         * @param {string} options.Width A widget's width. The unit of measurement is determined by another&#10;parameter.
         * @param {number} options.COLUMN Column position. This may currently be used when rendering some objects. There is no getColumns function, use getCol to retrieve the realized value from an object.
         * @param {string} options.EventUploadComplete
         * @param {boolean} options.uploadShowTitle
         * @param {boolean} options.uploadAutoStart
         * @param {boolean} options.uploadAutoRemove
         * @param {string} options.Template
         * @param {string} options.uploadValidFileTypes
         * @param {string} options.note field description displayed below the field
         * @param {string} options.uploadURL
         * @param {string} options.uploadServerCallbackObject
         * @param {string} options.uploadTargetDir
         * @param {string} options.EventUploadFileAdd
         * @param {string} options.EventClick Client side validation code (JavaScript)
         * @param {string} options.filter
         * @param {boolean} options.isResizable Available only for multiline input. If true, the input (textarea) will be resizable. Default value is false.
         * @fires ak_input#EventUploadComplete
         * @fires ak_input#EventAkValidate
         * @fires ak_input#EventClick
         * @fires ak_input#EventLeave
         * @fires ak_input#EventEntry
         * @fires ak_eventOnInfoSelected
         */

  ak_input: function(options) {

    akioma.BaseFormDataField.call(this, options);

    const oSelf = this,
      defaults = {};

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;
    this.view = options.view;

    this.useParentDynObjectLink = true;
    this.registerDynObject = true;
    this.registerVuexWatcher = true;
    this.ignoreValidateEvent = false; // flag for ignoring validate events

    if (this.opt.validation) {
      const oForm = akioma.getForm(this);
      const aValidations = this.opt.validation.split(',');
      for (const v in aValidations) {
        const cInstanceValidation = aValidations[v];
        const aInsValidations = cInstanceValidation.split(':');
        if (oForm.validationRules[this.opt.name] == undefined)
          oForm.validationRules[this.opt.name] = {};

        const cValidationType = aInsValidations[0].trim().toLowerCase();
        if (cValidationType == 'required')
          this.opt.required = true;
          // based on form item name and
        oForm.validationRules[this.opt.name][cValidationType] = {
          smartmessage: aInsValidations[2].trim(),
          value: aInsValidations[1].trim().toLowerCase()
        };
      }

    }

    // create logger on module DataSource
    this.log = akioma.log.getLogger('Form.Input');

    this.log.setLevel('warn');

    // get parent
    const oParent = this.parent;

    let cType, iLabelPos;
    if (oParent) {
      if (!this.opt.customStyle)
        this.opt.customStyle = this.view;
      try {

        switch (this.opt.type) {
          case 'radio':
            cType = 'radio';
            iLabelPos = oParent.labelLeft(this) + 30;
            break;
          case 'checkbox':
            cType = 'checkbox';
            iLabelPos = oParent.labelLeft(this) + 30;
            break;
          case 'password':
            cType = 'password';
            iLabelPos = oParent.labelLeft(this);
            break;
          case 'btn2state':
            cType = 'btn2state';
            iLabelPos = oParent.labelLeft(this);
            break;
          default:
            cType = 'input';
            iLabelPos = oParent.labelLeft(this);
            break;
        }
      } catch (oErr) {
        !_isIE && console.error([ 'Error in creating input', this, oErr.message ]);
      }

      if (!this.opt.label) this.opt.label = ' ';

      this.opt.label = akioma.tran(`${akioma.getForm(this).opt.name}.${this.opt.name}`, { defaultValue: this.opt.label });

      /*
         * note: { text: "Enter your project name here. This field is
         * required.", width:300 }, required: true, validate:
         * "ValidEMail",
         *
         */
      if (app.sessionData.objectNamesInTitles) {
        if (!this.opt.note)
          this.opt.note = '';
        this.opt.note = `${this.opt.name} | ${this.opt.note}`;
      }

      if (this.opt.dataType)
        this.opt.dataType = this.opt.dataType.toLowerCase();


      if (this.opt.validate) {
        if (this.opt.validate.startsWith('regExp'))
          this.opt.validate = this.opt.validate.split(':')[1];
      } else if (this.opt.dataType == 'integer')
        this.opt.validate = 'ValidInteger';
      else if (this.opt.dataType == 'decimal')
        this.opt.validate = 'ValidNumeric';
      else this.opt.validate = '';

      let groupSep,
        decSep;
        // this.opt.numberFormat = "0,000.00";
      if (this.opt.dataType == 'integer' || this.opt.dataType == 'decimal') {
        this.numberFormat = this.opt.numberFormat || app.sessionData.globalNumericFormat || akioma.globalData.globalNumericFormat.numberFormat;
        switch (this.opt.dataType) {
          case 'integer':
            groupSep = app.sessionData.globalNumericGroupSep || akioma.globalData.globalNumericFormat.groupSep;
            this.numericFormatArray = [ this.numberFormat.substring(0, this.numberFormat.indexOf('.')), groupSep ];
            break;
          case 'decimal':
            groupSep = app.sessionData.globalNumericGroupSep || akioma.globalData.globalNumericFormat.groupSep;
            decSep = app.sessionData.globalNumericDecSep || akioma.globalData.globalNumericFormat.decSep;
            this.numericFormatArray = [ this.numberFormat, groupSep, decSep ];
            break;
        }
      }

      if (this.opt.isCurrency)
        this.opt.numberFormat = [ '€ 0,000.00', '.', ',' ];


      // default position top
      let cPosition = 'label-top';

      // for checkbox right
      let cExtraClass;
      if (this.opt.type == 'checkbox')
        cPosition = 'label-right';
      else if (this.opt.type == 'btn2state') {
        cExtraClass = ' btn2state';
        cPosition = 'label-left';
      }

      let cClass = 'w4-formField w4-inputField';
      if (cExtraClass)
        cClass += cExtraClass;

      if (this.opt.note)
        cClass += ' dhxinputwith_note';


      const oConfig = {
        type: cType,
        inputTop: (this.parent.opt.positionMode == 'absolute') ? parseInt(this.opt.top) : 0,
        inputLeft: (this.parent.opt.positionMode == 'absolute') ? parseInt(this.opt.left) : 0,
        labelLeft: (this.parent.opt.positionMode == 'absolute') ? iLabelPos : 0,
        inputWidth: parseInt(this.opt.width),
        label: this.opt.label,
        labelTop: parseInt(this.opt.top),
        name: this.opt.name,
        required: (this.opt.required) ? this.opt.required : false,
        mandatory: (this.opt.required) ? this.opt.required : false,
        hidden: (this.opt.visible == false),
        note: { text: (this.opt.note) ? this.opt.note : '', width: this.opt.width },
        className: cClass,
        checked: (this.opt.value) ? true : false,
        validate: (this.opt.validate.startsWith('$')) ? oSelf.validateField() : this.opt.validate,
        maxLength: (this.opt.maxLength) ? this.opt.maxLength : 200,
        /* dateFormat: "%d.%m.%Y", */
        dateFormat: '%Y-%m-%d',
        numberFormat: (this.numericFormatArray) ? this.numericFormatArray : '',
        rows: (this.opt.rows) ? this.opt.rows : 0,
        position: cPosition,
        enabled: this.opt.ENABLED,
        readonly: !this.opt.ENABLED,
        hasError: false,
        errorMsg: '',
        info: !!this.opt.infoButton,
        tooltip: (this.opt.tooltip) ? this.opt.tooltip : '',
        // value:          (this.opt.value) ? this.opt.value : "",
        userdata: { id: this.opt.id }
      };

      oParent.prop.fields.push(oConfig);


      // append to elements in form

      if (this.parent.view == 'form')
        this.parent.elements.push(this);
      else if (this.parent.view == 'fieldset')
        this.parent.parent.elements.push(this);

    }

    oSelf.customValidationFunctions = {
      checkLength: function(value) {
        if (value.length > 5)
          return true;
        else
          return false;
      },

      greater100: function(value) {
        if (value > 100)
          return true;
        else
          return false;
      }
    };
  }
});

// methods for input
Object.assign($.ak_input.prototype, akioma.BaseFormDataField.prototype, {
  componentOptions: function() {
    const oSelf = this;
    return {
      watch: {
        'getters.getFormFieldState': {
          fn: function(newValue, oldValue) {
            oSelf._hasFormFieldChangesWatcher(newValue, oldValue);
          },
          params: [this.opt.id]
        },
        'getters.getCustomStates': {
          fn: function(customStates) {
            oSelf._customStatesWatcher(customStates);
          },
          params: [this.opt.id]
        },
        'getters.getFormFieldEnabled': {
          fn: function(bEnabled) {
            oSelf._enabledFormFieldWatcher(bEnabled);
          },
          params: [this.opt.id]
        },
        'getters.getFormFieldMandatory': {
          fn: function(bMandatory) {
            oSelf._mandatoryFormFieldWatcher(bMandatory);
          },
          params: [this.opt.id]
        },
        'getters.getFormFieldError': {
          fn: function(newValue, oldValue) {
            oSelf._errorFormFieldWatcher(newValue, oldValue);
          },
          params: [this.opt.id]
        },
        'getters.getFormFieldErrorMsg': {
          fn: function(cErrorMsg) {
            oSelf._errorFormFieldMsgWatcher(cErrorMsg);
          },
          params: [this.opt.id]
        }
      }
    };
  },
  _enabledFormFieldWatcher: function(bEnabled) {
    const oSelf = this;
    const oForm = akioma.getForm(oSelf);
    const cName = oSelf.opt.name;

    if (oForm.dhx) {
      if (bEnabled)
        oForm.setFormFieldReadonly(cName, false);
      else
        oForm.setFormFieldReadonly(cName, true);

    }


  },
  _mandatoryFormFieldWatcher: function(bMandatory) {
    const oSelf = this;
    const oForm = akioma.getForm(oSelf);
    const cName = oSelf.opt.name;

    if (oForm && oForm.dhx) {
      if (typeof (oSelf.setRequired) == 'function')
        oSelf.setRequired(bMandatory);

      oForm.dhx.setRequired(cName, bMandatory);
    }

  },
  /**
     * Watcher for the form error message
     */
  _errorFormFieldMsgWatcher: function(newValue) {
    const oSelf = this;
    const cName = oSelf.opt.name;
    const oForm = akioma.getForm(oSelf);
    const oFormField = akioma.getDhxFormElement(oForm.dhx, cName);

    // setup error message under input
    if (oFormField) {
      const oFormCtrl = $(oFormField).find('> .dhxform_control');
      if (oFormCtrl.find('.validation-error-smartmessage').length == 0)
        oFormCtrl.append(`<span class="validation-error-smartmessage">${newValue}</span>`);
      else
        oFormCtrl.find('.validation-error-smartmessage').text(newValue);
    }

  },
  // finish construct **********
  finishConstruct: function() {
    // get field
    const oSelf = this;
    const parentForm = akioma.getForm(this);
    const formAkId = parentForm.akId;
    this.form = parentForm.dhx;
    oSelf.bValid = true;

    let oInput;
    try {
      this.dhx = this.form.getInput(this.opt.name);
      this.dhx.oElement = $(this.form.getInput(this.opt.name)).parent().parent()[0];
      oInput = $(this.dhx).parent()[0];
      $(oInput).attr('akId', `${formAkId}-${this.opt.name}`); // set akId in input
      $(oInput).attr('akStyle', this.opt.customStyle); // set akStyle in input

      if (akioma.getAkIdMode() == 'extended')
        $(this.dhx).attr('akId', `${formAkId}-${this.opt.name}-${this.view}`);
      if (this.opt.required)
        $(oInput).addClass('akMandatory akInputMandatory');

      if (this.opt.extendedFormat) {
        const aSplit = this.opt.extendedFormat.split(':');
        if (aSplit[0] == 'regex' && aSplit[1])
          $(oInput).find('input').inputmask({ regex: aSplit[1] });
        else {
          $(oInput).find('input').inputmask(this.opt.extendedFormat, {
            'oncomplete': function() {
              oSelf.bValid = true;
            },
            'onincomplete': function() {
              oSelf.bValid = false;
            },
            'onincompleteFocus': function() {
              oSelf.bValid = false;
            },
            autoUnmask: true
          });
        }
      }

      this.$domElement = $(oInput).parent();
      this._setAutocomplete(oInput);
      this.setResponsiveSizes();
    } catch (e) {
      console.error('error in input_finishconstruct setting .dhx');
    }

    try {
      const $itemcont = $(this.dhx.oElement);
      if (this.opt.type == 'checkbox')
        $($itemcont).find('.dhxform_note').css('width', this.opt.width);

    } catch (e) {
      akioma.log.error('No itemcont found for : ', this.opt.name);
    }

    if (this.opt.type == 'checkbox') {
      parentForm.aFormCheckboxesIndex.push(this.opt.name);
      parentForm.aFormCheckboxes.push(this);

    }

    this.setReadonlyCheckbox(!this.opt.ENABLED);
    this.applyInitialValue(parentForm);

    if (this.opt.isResizable && this.opt.rows)
      $(this.dhx).css('resize', 'both');

    if (this.opt.extendedFormat)
      this.form.setNumberFormat(this.opt.name, '0,000.00 Kr', '\'', ',');

    if (this.opt.type == 'checkbox')
      this.domTag = $(oInput).parent().find('.dhxform_label div')[0];
    else
      this.domTag = $(oInput).children()[0];

    this._setTabIndex(this.domTag);
  },
  /**
     * Method for applying the initial attribute value. Only gets applied if there is no BE field with the same name or if there is no dataSource
     * @param {ak_form} parentForm The parent form
     * @memberof ak_input
     * @instance
     * @private
     */
  applyInitialValue: function(parentForm) {
    try {
      if (parentForm.dataSource && parentForm.dataSource.view == 'businessEntity') {
        parentForm.dataSource.addBeforeFillOnceCallback(data => {
          const selectedRec = data[0];
          let fields = [];

          if (selectedRec) {
            fields = Object.keys(selectedRec);
            fields.map(val => val.toLowerCase());
            if (fields.indexOf(this.opt.name) === -1)
              this.setInitialValue();
          } else
            this.setInitialValue();


        });
      } else
        this.setInitialValue();

    } catch (e) {
      this.log.warn(e);
    }

  },
  /**
     * Method for setting the initial value attribute
     * @memberof ak_input
     * @instance
     * @private
     */
  setInitialValue: function() {
    const $itemcont = $(this.dhx.oElement);
    if (this.opt.initial !== undefined) {

      this.setRawValue(this.opt.initial);
      const cVal = this.form.getItemValue(this.opt.name);
      if ($itemcont && (cVal == null || cVal.length == 0))
        $itemcont.addClass('active');
    }
  },

  /**
     * Sets input as anonymised (blurred data)
     * @instance
     * @memberOf ak_input
     */
  setAnonymised: function() {
    const control = $(this.dhx).parent()[0];
    $(control).addClass('anonymisedClass');
  },

  setRequired: function(bRequired) {
    const oSelf = this;
    const oInput = $(oSelf.dhx).parent()[0];
    (bRequired) ? $(oInput).addClass('akMandatory akInputMandatory') : $(oInput).removeClass('akMandatory akInputMandatory');
  },

  /**
     * Displays checkbox as enabled or readonly.
     * @param {boolean} value True for readonly, false for enabled
     * @instance
     * @memberOf ak_input
     */
  setReadonlyCheckbox: function(value) {
    if (this.opt.type == 'checkbox') {
      const element = $(this.dhx.oElement);
      if (value) {
        $(element).addClass('checkbox_disable');
        element[0]._ro = true;
      } else {
        $(element).removeClass('checkbox_disable');
        element[0]._ro = false;
      }
    }
  },

  validateField: function() {
    const t = this;
    return function() {
      const funcName = t.opt.validate.substring(1);
      return t.customValidationFunctions[funcName].apply(t, arguments);
    };
  },

  eventFocus: function() {
    const formDiv = akioma.getForm(this).dhx.cont.children[0];
    $(formDiv).children().removeClass('focusedinp');

    const bEnabled = this.form.akElm._getters('getFormFieldEnabled', this.opt.id);
    if (this.opt.type !== 'btn2state' && bEnabled) {
      const $itemcont = $(this.form.getInput(this.opt.name)).parent().parent();
      $itemcont.addClass('active').addClass('focusedinp');
    }

    this.form.akElm.bFocused = true;
  },

  eventBlur: function() {
    const $itemcont = $(this.form.getInput(this.opt.name)).parent().parent();
    const cVal = this.form.getItemValue(this.opt.name);
    const isAutofill = this.form.getInput(this.opt.name).parentNode.parentNode.isAutofill;
    if ((cVal == null || cVal.length == 0) && this.opt.type !== 'btn2state' && isAutofill !== true)
      $itemcont.removeClass('active');
    $itemcont.removeClass('focusedinp');
  },

  cursorChange: function() {
    const $itemcont = $(this.form.getInput(this.opt.name)).parent().parent();
    const cVal = this.form.getItemValue(this.opt.name);
    if ((cVal === null || String(cVal).length == 0) && !($itemcont.hasClass('focusedinp')))
      $itemcont.removeClass('active');
    else
      $itemcont.addClass('active');
  },

  endConstruct: function() {
    if (this.opt.visible == false)
      this.form.hideItem (this.opt.name);
  },

  // get value *****************
  getValue: function() {
    if (this.opt.type == 'checkbox')
      return (this.form.isItemChecked(this.opt.name) ? 'yes' : 'no');
    else
      return this.form.getItemValue(this.opt.name);
  },

  // get current value toggle box
  getCurrentValueToggleBox: function() {
    if (this.dynObject.akEvent && this.dynObject.akEvent.currentValue) {
      let value = this.dynObject.akEvent.currentValue;
      if (typeof value === 'string') {
        value = value.toLowerCase();
        value = (value !== '' && value !== '0' && value !== 'no' && value !== 'false');
      } else if (typeof value !== 'boolean')
        value = !!value;
      return value;
    }
    return false;
  },

  /**
     * Method used just for setting the value of the input.
     * @param {Object} value
     * @instance
     * @memberOf ak_input
     */
  setRawValue: function(value) {
    let lCheck = false;
    if (this.opt.type == 'checkbox') {
      if (typeof (value) == 'string') {
        if (value.toLowerCase() == 'yes' || value.toLowerCase() == 'ja' || value.toLowerCase() == 'true')
          lCheck = true;
        else
          lCheck = false;

      } else if (typeof (value) == 'number')
        lCheck = (value != 0);
      else
        lCheck = value;
      value = lCheck;
    }
    this.form.setItemValue(this.opt.name, value);
    try {
      const $itemcont = $(this.form.getInput(this.opt.name)).parent().parent();
      if (value.length > 0)
        $itemcont.addClass('active');
    } catch (e) {
      akioma.log.error(`Could not find itemcont for: ${this.opt.name}`);
    }
  },

  /**
     * Setting the value of the input and validate.
     * @param {Object} cValue
     * @instance
     * @memberOf ak_input
     */
  setValue: function(cValue) {
    this.setRawValue(cValue);

    /**
     * Code executed on the client side for validation
     * @event ak_input#EventAkValidate
     * @type {object}
     */
    if (this.opt.validateEvent && !this.ignoreValidateEvent)
      app.controller.callAkiomaCode(this, this.opt.validateEvent);

  },

  // event leave *******************
  eventOnValueChanged: function(cValue, nValue) {
    // check if we have to call the create event
    if (cValue === null && nValue === '') nValue = null;
    if (nValue != undefined && this.cOldVal != nValue) {
      this.cOldVal = nValue;

      // values for call method
      const event = {};
      event.currentValue = nValue;
      event.lastValue = cValue;

      this.dynObject.akEvent = event;

      try {
        if (this.parent.view == 'fieldset' && this.parent.opt.eventOnChanged)
          app.controller.callAkiomaCode(this.parent, this.parent.opt.eventOnChanged);
      } catch (e) {
        akioma.log.error(`Could not call method for: ${this.opt.parent.name}`);
      }
    }
  },
  eventLeave: function() {
    /**
       * Code executed on the client side when an input looses focus.
       * @event ak_input#EventLeave
       * @type {object}
       */
    app.controller.callAkiomaCode(this, this.opt.leaveEvent);

    this.callRules({
      eventEntity: this.opt.InstanceName,
      eventName: 'onLeave',
      eventSource: this.opt.InstanceName,
      eventObject: this
    });
  },
  destroy: function() {
    if (this.opt.extendedFormat) {
      const oInput = $(this.dhx).parent();
      oInput.find('input').inputmask('remove');
    }
    this.akElm = null;
  }
});
