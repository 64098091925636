jQuery.cookie = function(name, value, options) {
  if (typeof value != 'undefined') { // name and value given, set cookie
    options = options || {};
    if (value === null) {
      value = '';
      options.expires = -1;
    }
    let expires = '';
    if (options.expires && (typeof options.expires == 'number' || options.expires.toUTCString)) {
      let date;
      if (typeof options.expires == 'number') {
        date = new Date();
        date.setTime(date.getTime() + (options.expires * 24 * 60 * 60 * 1000));
      } else
        date = options.expires;

      expires = `; expires=${date.toUTCString()}`; // use expires attribute, max-age is not supported by IE
    }
    // CAUTION: Needed to parenthesize options.path and options.domain
    // in the following expressions, otherwise they evaluate to undefined
    // in the packed version for some reason...
    const path = options.path ? `; path=${options.path}` : '';
    const domain = options.domain ? `; domain=${options.domain}` : '';
    const secure = options.secure ? '; secure' : '';
    document.cookie = [ name, '=', encodeURIComponent(value), expires, path, domain, secure ].join('');
  } else { // only name given, get cookie
    let cookieValue = null;
    if (document.cookie && document.cookie != '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = jQuery.trim(cookies[i]);
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) == (`${name}=`)) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  }
};


jQuery.extend({
  Guid: {
    Set: function() {
      let value;
      if (arguments.length == 1) {
        if (this.IsValid(arguments[0]))
          value = arguments[0];
        else
          value = this.Empty();

      }
      $(this).data('value', value);
      return value;
    },

    Empty: function() {
      return '00000000-0000-0000-0000-000000000000';
    },

    IsEmpty: function(gid) {
      return gid == this.Empty() || typeof (gid) == 'undefined' || gid == null || gid == '';
    },

    IsValid: function(value) {
      const rGx = new RegExp('\\b(?:[A-F0-9]{8})(?:-[A-F0-9]{4}){3}-(?:[A-F0-9]{12})\\b');
      return rGx.exec(value) != null;
    },

    New: function() {
      if (arguments.length == 1 && this.IsValid(arguments[0])) {
        $(this).data('value', arguments[0]);
        const value = arguments[0];
        return value;
      }

      const res = [];
      for (let i = 0; i < 8; i++) {
        let hv = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        const rgx = new RegExp('[2345]');
        if (rgx.exec(i.toString()) != null) {
          if (i == 3)
            hv = `6${hv.substr(1, 3)}`;

          res.push('-');
        }
        res.push(hv.toUpperCase());
      }
      const value = res.join('');
      $(this).data('value', value);
      return value;
    },

    Value: function() {
      if ($(this).data('value'))
        return $(this).data('value');

      const val = this.New();
      $(this).data('value', val);
      return val;
    }
  }
})();
