

// ********************* Field set ********************
$.extend({

  /**
   * SwatFieldset Control
   * @class ak_fieldset
   * @param {Object} options Repository attributes for SwatFieldset.
   * @param {string} options.LABEL WidgetAttributes Label
   * @param {boolean} options.LABELS If false then NO-LABEL is used.  This attribute applies to most field level widgets and frames
   */
  ak_fieldset: function(options) {

    akioma.BaseFormFieldContainer.call(this);

    const defaults = {};

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;
    this.view = options.view;

    this.registerDynObject = true;

    // get parent
    const oParent = this.parent;

    this.setOriginalSizes();

    if (oParent) {
      if (!this.opt.customStyle)
        this.opt.customStyle = this.view;

      this.prop = {
        labelCol: {},
        fields: [
          {
            type: 'settings',
            position: (this.opt.positionMode) ? this.opt.positionMode : 'label-left',
            labelWidth: (this.opt.labelWidth) ? this.opt.labelWidth : 120,
            blockOffset: (this.opt.blockOffset) ? this.opt.blockOffset : 2,
            offsetLeft: (this.opt.offsetLeft) ? this.opt.offsetLeft : 2,
            noteWidth: (this.opt.noteWidth) ? this.opt.noteWidth : 100,
            labelAlign: 'left'
          }
        ]
      };

      this.opt.label = akioma.tran(`${akioma.getForm(this).opt.name}.${this.opt.name}`, { defaultValue: this.opt.label });

      if (this.opt.isCollapsable == undefined)
        this.opt.isCollapsable = true;
      if (this.opt.isCollapsable)
        this.opt.label = `<i class="fa fa-angle-up" style="font-size:24px;padding-right:15px;transform-origin:25% 50%;"></i>${this.opt.label}`;

      oParent.prop.fields.push({
        type: 'fieldset',
        label: this.opt.label,
        name: this.opt.name,
        disabled: this.opt.disabled,
        hidden: (this.opt.visible == false),
        className: 'w4-formField w4-inputField',
        list: this.prop.fields
      });

      // append to elements in form
      if (this.parent.view == 'form')
        this.parent.elements.push(this);
    }
  }
});

Object.assign($.ak_fieldset.prototype, akioma.BaseFormFieldContainer.prototype, {
  // finish construct **********
  finishConstruct: function() {
    // get field
    const parentForm = akioma.getForm(this);
    const formAkId = parentForm.akId;
    this.form = parentForm.dhx;
    this.dhx = null;
    let oSelf;

    if (this.view == 'fieldset')
      oSelf = this;

    const fieldsetClass = akioma.getDhxFormElement(this.form, this.opt.name);
    const oHtmlFieldset = $(fieldsetClass).find('fieldset');
    this.dhx = oHtmlFieldset;

    $(oHtmlFieldset).addClass('akFieldset');

    this.$domElement = $(oHtmlFieldset).parent();
    this.$baseDomElement = $(oHtmlFieldset).find('> div > .dhxform_base');


    $(oHtmlFieldset).attr('akId', `${formAkId}-${this.opt.name}`); // set akId in fieldset
    $(oHtmlFieldset).attr('akStyle', this.opt.customStyle); // set akStyle in fieldset


    if (this.opt.isCollapsable) {

      if (this.view == 'fieldset')
        oSelf = this;

      const fieldsetClass = akioma.getDhxFormElement(this.form, this.opt.name);
      const fieldData = UserProfile.loadFormLocalProfileData(this);

      let fieldsetState;
      if (fieldData)
        fieldsetState = fieldData.state;


      if (fieldsetState == 'collapsed') {
        $(this).removeClass('akFieldsetLegend');
        oSelf.setCollapsed(true);
      } else if (fieldsetState == 'expanded' || fieldsetState === undefined || fieldsetState === null) {
        $(this).addClass('akFieldsetLegend');
        oSelf.setCollapsed(false);
      }

      // Double click event on fieldset
      $(fieldsetClass).find('legend').on('click', function() {
        const children = this.parentElement.children;
        for (let i = 1; i < children.length; i++)
          $(children[i]).fadeToggle('fast');
        $(this.children[0]).toggleClass('fa-rotate-180');

        if ($(this.children[0]).hasClass('fa-rotate-180')) {
          $(this).removeClass('akFieldsetLegend');
          fieldsetState = 'collapsed';
        } else {
          $(this).addClass('akFieldsetLegend');
          fieldsetState = 'expanded';
        }
        UserProfile.saveFormLocalProfileData(oSelf, { state: fieldsetState });

        if (oSelf.opt.eventOnChanged)
          app.controller.callAkiomaCode(oSelf, oSelf.opt.eventOnChanged);

      });
    }

    this.setResponsiveSizes();
    this.domTag = oHtmlFieldset[0];
    this._setTabIndex(this.domTag);
  },

  endConstruct: function() {

    if (this.opt.visible == false)
      this.form.hideItem (this.opt.name);

    let oSelf;
    if (this.view == 'fieldset')
      oSelf = this;

    if (oSelf.opt.eventOnChanged)
      app.controller.callAkiomaCode(oSelf, oSelf.opt.eventOnChanged);
  },

  isCollapsed: function() {
    const oFieldData = UserProfile.loadFormLocalProfileData(this);
    return (oFieldData.state === 'collapsed');
  },

  setCollapsed: function(bMode) {
    const oSelf = this;
    const fieldsetClass = akioma.getDhxFormElement(this.form, this.opt.name);
    let fieldsetState = UserProfile.loadFormLocalProfileData(this);
    // load fieldset state saved in userProfile
    const legend = $(fieldsetClass).find('legend');
    const fieldsetChildren = legend[0].parentElement.children;

    // set fieldset collapsed
    if (bMode) {
      for (let i = 1; i < fieldsetChildren.length; i++)
        $(fieldsetChildren[i]).hide('fast');
      $(legend).removeClass('akFieldsetLegend');
      $(legend[0].children[0]).addClass('fa-rotate-180');
      fieldsetState = 'collapsed';
    } else { // set fieldset expanded
      for (let i = 1; i < fieldsetChildren.length; i++)
        $(fieldsetChildren[i]).show('fast');
      $(legend).addClass('akFieldsetLegend');
      $(legend[0].children[0]).removeClass('fa-rotate-180');
      fieldsetState = 'expanded';
    }

    UserProfile.saveFormLocalProfileData(oSelf, { state: fieldsetState });

  },

  setFieldsetTitle: function(cTitle) {
    const oSelf = this;
    const oForm = akioma.getForm(oSelf);
    let cHtmlClass = 'fa fa-angle-up';

    const fieldsetState = UserProfile.loadFormLocalProfileData(this);
    if (fieldsetState == 'collapsed')
      cHtmlClass = 'fa fa-angle-down';
    else if (fieldsetState == 'expanded')
      cHtmlClass = 'fa fa-angle-up';

    const fieldsetClass = oForm.dhx.itemPull[oForm.dhx.idPrefix + oSelf.opt.name];
    const cHtml = `<i class="${cHtmlClass}" style="font-size:24px;padding-right:15px;transform-origin:25% 50%;"></i>${cTitle}`;
    $(fieldsetClass).find('legend').html(cHtml);
  },
  /*
     * stub for the corresponding form function (only needed for
     * absolute positioning)
     */
  labelLeft: function(oElm) {
    return this.parent.labelLeft(oElm);
  },
  destroy() {
    const fieldsetClass = akioma.getDhxFormElement(this.form, this.opt.name);

    $(fieldsetClass).find('legend').off();

    this.prop = null;
  }
});
