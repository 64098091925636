dhtmlXForm.prototype.items.multilist = {
  render: function(item, data) {

    item._type = 'multilist';

    this.doAddLabel(item, data);

    const oSelf = $.getObjectByName({ id: data.userdata.id });

    // -> bind input to form
    $('<div class="dhxform_control"><input type="text" class="akMultiListField dhxform_textarea" /><img src="/imgs/akioma/activity_feed-26.png" height="14" width="14" class="akMultiListImg" /></div>')
      .appendTo(item)
      .css({
        left: oSelf.opt.left,
        top: oSelf.opt.top
      })
      .find('input.akMultiListField')
      .blur(() => {
        oSelf.eventLeave();
      })
      .end()
      .find('img')
      .hover(
        function() {
          $(this)
            .css({ cursor: 'hand' });
        },
        function() {
          $(this)
            .css({ cursor: 'pointer' });
        }
      )
      .click(() => {
        oSelf.eventClick();
      })
      .end()
      .get(0);

    item.akElm = oSelf;
    oSelf.dhx = item;

    return this;
  },

  setValue: function(item, value) {
    $(item)
      .find('input')
      .val(value);
  },

  getValue: function(item) {
    return $(item)
      .find('input')
      .val();
  },

  getMultilist: function(item) {
    return item;
  }
};
(function() {
  for (const a in { doAddLabel: 1, doAddInput: 1, destruct: 1, doUnloadNestedLists: 1, setText: 1, getText: 1, enable: 1, disable: 1, setWidth: 1 })
    dhtmlXForm.prototype.items.multilist[a] = dhtmlXForm.prototype.items.input[a];
})();
dhtmlXForm.prototype.getMultilist = function(name) {
  return this.doWithItem(name, 'getMultilist');
};
dhtmlXForm.prototype.setFormData_multilist = function(name, value) {
  return this.doWithItem(name, 'setValue', value);
};
dhtmlXForm.prototype.getFormData_multilist = function(name) {
  return this.doWithItem(name, 'getValue');
};

(function($) {

  // ********************* multilist ********************
  $.extend({
    ak_multilist: function(options) {

      akioma.BaseFormDataField.call(this, options);

      const defaults = {};

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;

      this.registerDynObject = true;
      this.useParentDynObjectLink = true;

      // get parent
      const oParent = this.parent;

      if (oParent) {

        oParent.prop.fields.push({
          type: 'multilist',
          inputTop: parseInt(this.opt.top),
          inputLeft: parseInt(this.opt.left),
          inputWidth: parseInt(this.opt.width),
          label: this.opt.label,
          labelTop: parseInt(this.opt.top),
          labelLeft: oParent.labelLeft(this),
          name: this.opt.name,
          readonly: this.opt.readonly,
          className: 'w4-formField w4-inputField active akMultiList',
          position: 'label-top',
          userdata: { id: this.opt.id }
        });

        // append to elements in form
        if (this.parent.view == 'form')
          this.parent.elements.push(this);

        // oInput.akElm= this;
        $.extend(this, { security: {} });
      }
    }
  });

  // methods for multilist
  Object.assign($.ak_multilist.prototype, akioma.BaseFormDataField.prototype, {

    // finish construct **********
    finishConstruct: function() {
      // get field
      this.form = this.parent.dhx;
      const field = this.form.getMultilist(this.opt.name);
      this.$domElement = $(field);
      this.setResponsiveSizes();
    },

    // get value *****************
    getValue: function() {
      return this.form.getItemValue(this.opt.name);
    },

    // set value ********************
    setValue: function(cValue) {
      this.form.setItemValue(this.opt.name, cValue);
    },

    // event leave *******************
    eventLeave: function() {
      // check if we have to call the leave event
      if (this.opt.leaveEvent)
        app.controller.callAkiomaCode(this, this.opt.leaveEvent);
    },

    // event click *******************
    eventClick: function() {
      const oForm = this.dynObject.parent.controller;
      const cName = (this.opt.keyField) ? this.opt.keyField : this.opt.name;
      const cIndex = (oForm.dataSource) ? oForm.dataSource.getIndex() : '';

      const oPara = {
        refHdl: cIndex,
        fieldName: cName
      };

      app.controller.launchContainer({
        proc: 'flagsG.r',
        para: oPara,
        self: this,
        caller: this
      });

      return;
    }
  });

})(jQuery, jQuery);
