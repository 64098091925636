

// ***************** dhxobject ********************
$.extend({
  dhxObject: function(options) {
    function checkLink(aLink, oCaller) {
      let i;
      for (i in aLink) {
        try {
          oCaller.controller.links.push(aLink[i]);
          oCaller.subscribe(aLink[i]);
        } catch (e) {
          !_isIE && console.error([ 'Error in checkLink:', oCaller, aLink[i] ]);
        }
      }
    }

    let oNew;
    // check for object
    if (options.view) {
      try {
        // check if this class is already extended
        if (typeof $[`ak_${options.view}`].prototype.construct != 'function')
          $.extend($[`ak_${options.view}`].prototype, $.ak_global.prototype);

        // create new element
        oNew = new $[`ak_${options.view}`](options);
        // if error occurred during construct -> leave
        if (oNew.cancel) {
          // delete oNew;
          oNew = null;
          return null;
        }

        oNew.view = options.view;
        oNew.parent = options.parent;
        oNew.childs = new Array();
        oNew.links = [];
        oNew.keys = {};
        oNew.security = {
          readOnly: false,
          hidden: false
        };

        // check security
        if (oNew.opt.disabled != undefined)
          oNew.security.readOnly = oNew.opt.disabled;
        if (oNew.opt.visible != undefined)
          oNew.security.hidden = (oNew.opt.visible == false);
        if (oNew.opt.hidden != undefined)
          oNew.security.hidden = oNew.opt.hidden;

        if (!oNew.prop)
          oNew.prop = {};

        if (oNew.parent && oNew.parent.childs)
          oNew.parent.childs.push(oNew);
          // default routines
        oNew.construct();
        // check for links
        if (oNew.dynObject && oNew.opt.channelSrc)
          checkLink(oNew.opt.channelSrc.split(','), oNew.dynObject);
        if (oNew.dynObject && oNew.opt.channelTrg)
          checkLink(oNew.opt.channelTrg.split(','), oNew.dynObject);

        if (oNew.opt.ObjectInstanceGuid)
          window.akRepositoryObjects[oNew.opt.ObjectInstanceGuid] = oNew;
        else if (oNew.opt.ObjectMasterGuid)
          window.akRepositoryObjects[oNew.opt.ObjectMasterGuid] = oNew;

        // new repository setup links
        if (app.controller.oLinks) {
          // get all targets and sources and subscribe
          const aLinkSourcesIDs = app.controller.oLinks.sources[oNew.opt.ObjectInstanceGuid];
          for (const i in aLinkSourcesIDs) {
            const oLink = app.controller.oLinks.links[aLinkSourcesIDs[i]];
            if (oLink) {
              const cLinkFull = `${oLink.LinkName.toUpperCase()}:SRC:${window.akRepositoryObjects[oLink.SourceObjectInstanceGuid].opt.name}`;
              const oCaller = oNew.dynObject;
              try {
                oCaller.controller.links.push(cLinkFull);
                oCaller.subscribe(cLinkFull);
              } catch (e) {
                !_isIE && console.error([ 'Error in add link:', oCaller, cLinkFull ]);
              }
            }
          }

          // get all targets and sources and subscribe
          const aLinkIDs = app.controller.oLinks.targets[oNew.opt.ObjectInstanceGuid];
          for (const i in aLinkIDs) {
            const oLink = app.controller.oLinks.links[aLinkIDs[i]];
            if (oLink) {
              const oSourceRepoObj = window.akRepositoryObjects[oLink.SourceObjectInstanceGuid],
                oTargetRepoObj = window.akRepositoryObjects[oLink.TargetObjectInstanceGuid];

              const cLinkFull = `${oLink.LinkName.toUpperCase()}:TRG:${oSourceRepoObj.opt.linkid}`;
              const oCaller = oNew.dynObject;
              try {
                oCaller.controller.dynObjName = oSourceRepoObj.opt.name;
                oCaller.controller.links.push(cLinkFull);
                oCaller.subscribe(cLinkFull);
              } catch (e) {
                !_isIE && console.error([ 'Error in add link:', oCaller, cLinkFull ]);
              }

              if (oLink.LinkName.toUpperCase() != 'NAVIGATION' && oLink.LinkName.toUpperCase() != 'TOOLBAR' &&
                    oLink.LinkName.toUpperCase() != 'USER1' && oLink.LinkName.toUpperCase() != 'TABLEIO')
                oTargetRepoObj.dataSource = oSourceRepoObj;

              if (oSourceRepoObj) {
                if (oSourceRepoObj.addObserver)
                  oSourceRepoObj.addObserver(oTargetRepoObj);
              }
            }
          }
        }

        return oNew;
      } catch (e) {
        console.error([ 'Error when creating', options, oNew, e.message ]);
      }
    }

    return null;
  }
});
