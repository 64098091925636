/**
 *
 */
dhtmlXToolbarObject.prototype.addSegmented = function(groupName, buttonIds, selectedId) {

  // 1st init;
  if (!this._segm_state) {
    this._segm_state = {};
    this._segm_gr = {};
    this._segmEvA = this.attachEvent('onBeforeStateChange', function(id, state) {
      // do not allow release button here if it belong to any segmented group
      if (state == true) {
        const g = this._segm_gr[id];
        if (g != null && this._segm_state[g][id] == true) return false;
      }
      return true;
    });
    this._segmEvB = this.attachEvent('onStateChange', function(id, state) {
      // button was released
      if (state == false) return;
      // release other withing group
      const g = this._segm_gr[id];
      if (g == null) return;
      for (const a in this._segm_state[g]) {
        if (id != a && this._segm_state[g][a] == true) {
          this.setItemState(a, false);
          this.callEvent('onSegmentedChange', [ g, a, false ]);
        }
      }
      // change state for current
      this._segm_state[g][id] = true;
      // event
      this.callEvent('onSegmentedChange', [ g, id, true ]);
    });
  }

  // new group
  if (!this._segm_state[groupName]) this._segm_state[groupName] = {};

  // add buttons to group
  if (!(buttonIds instanceof Array)) buttonIds = [buttonIds];
  for (let q = 0; q < buttonIds.length; q++) {
    if (typeof (this._segm_state[groupName][buttonIds[q]]) == 'undefined') {
      this._segm_state[groupName][buttonIds[q]] = false;
      this._segm_gr[buttonIds[q]] = groupName;
    }
  }

  // select 1st or specified
  if (selectedId == null || this.objPull[this.idPrefix + selectedId] == null) selectedId = buttonIds[0];
  this.setItemState(selectedId, true);
  this._segm_state[groupName][selectedId] = true;

};
dhtmlXToolbarObject.prototype.getPressed = function(groupName) {
  if (!this._segm_state) return null;
  if (!this._segm_state[groupName]) return null;

  let p = null;
  for (const a in this._segm_state[groupName]) if (this._segm_state[groupName][a] == true) p = a;

  return p;
};
