// ******** messenger ***********
/**
 * AK_Messenger class
 * @class AK_Messenger
 */
const AK_Messenger = function() {
  this.trace = false;
  this.logger = akioma.log.getLogger('AkMessenger');
};

AK_Messenger.prototype = {

  // initializes subbarray
  /**
 * Method setSubArray initializes subarray
 * @instance
 * @memberOf AK_Messenger
 * @param {string} cLink  The link
 * @param {array} aArray
 */
  setSubArray: function(cLink, aArray) {
    if (!aArray[cLink])
      aArray[cLink] = new Array();
    return aArray[cLink];
  },

  // subscribe object to channel
  /**
 * Method to subscribe an object to channel
 * @instance
 * @private
 * @memberOf AK_Messenger
 * @param  {object} oCaller
 * @param  {string} cLink
 */
  subscribe: function(oCaller, cLink) {
    // get container
    const oCont = oCaller.container,
      aLink = this.splitLink(cLink);

    if (!oCont) {
      akioma.message({ type: 'alert-error', text: `subscribe: no container${cLink}` });
      return;
    }

    // check if links already initialized
    let oLinks = (oCont) ? oCont.links : null;
    if (!oLinks)
      oLinks = oCont.links = {};

    // check link
    if (!aLink) {
      this.logger.warn([ `subscribe: Link not allowed! -> ${cLink}`, oCaller ]);
      return false;
    }
    this.logger.info([ `subscribe Link -> ${cLink}`, oCaller ]);

    // check LinkName
    if (!oLinks[aLink.LinkName])
      oLinks[aLink.LinkName] = {};
    const oLinkName = oLinks[aLink.LinkName];

    // check for LinkType
    if (!oLinkName[aLink.LinkType])
      oLinkName[aLink.LinkType] = {};
    const oLinkType = oLinkName[aLink.LinkType];

    // check for element name
    if (!oLinkType[aLink.ObjName])
      oLinkType[aLink.ObjName] = new Array();
    const aElements = oLinkType[aLink.ObjName];

    // check for element
    if (aElements.indexOf(aElements, oCaller) == -1)
      aElements.push(oCaller);
  },

  /**
 * Method to unsubscribe a channel link from object
 * @instance
 * @private
 * @memberOf AK_Messenger
 * @param  {object} oCaller
 * @param  {string} cLink
 */
  unSubscribe: function(oCaller, cLink) {
    const aLink = this.splitLink(cLink);

    let oCont;
    if (oCaller instanceof dynObject)
      oCont = oCaller;
    else
      oCont = oCaller.dynObject;

    if (!oCont.container)
      return;

    const oLinks = oCont.container.links;

    // check link
    if (!aLink) {
      this.logger.warn([ `unsubscribe: Link not allowed! -> ${cLink}`, oCaller ]);
      return false;
    }
    this.logger.info([ `unsubscribe Link -> ${cLink}`, oCaller ]);

    // check linkname
    if (!oLinks[aLink.LinkName])
      return;
    const aLinkName = oLinks[aLink.LinkName];

    // check for element name
    if (!aLinkName[aLink.LinkType])
      return;
    const aLinkType = aLinkName[aLink.LinkType];

    // check for element name
    if (!aLinkType[aLink.ObjName])
      return;
    const aElements = aLinkType[aLink.ObjName];

    // remove caller from list
    $.removeEntry(aElements, oCont);
    if (aElements.length == 0)
      delete oLinks[aLink.LinkName][aLink.LinkType][aLink.ObjName];


  },

  // publish
  /**
 * Method for publishing links
 * @instance
 * @private
 * @memberOf AK_Messenger
 * @param  {object} oElm
 */
  publish: function(oElm) {
    try {
      let oCont;
      if (oElm.caller instanceof dynObject)
        oCont = oElm.caller.container;
      else
        oCont = oElm.caller.dynObject.container;

      let aLink;
      if ((typeof oElm.link) == 'object')
        aLink = oElm.link;
      else {
        aLink = this.splitLink(oElm.link);
        oElm.link = aLink;
      }

      const oLinks = oCont.links;

      // check link
      if (!aLink) {
        this.logger.error([ oElm.caller, `publish: Link not allowed! -> :${oElm.method}` ]);
        return false;
      }
      this.logger.info([ `controller:publish: ${aLink.LinkName}:${aLink.LinkType}:${aLink.ObjName} -> ${oElm.method}`, oElm.caller, oElm ]);

      // check linkname
      const aLinkName = this.getSubArray(oLinks, aLink.LinkName.toUpperCase());
      this.logger.info([ 'publish: aLinkName', aLinkName ]);
      if (!aLinkName) return;

      // check linktype
      const aLinkType = this.getSubArray(aLinkName, aLink.LinkType);
      this.logger.info([ 'publish: aLinkType', aLinkType ]);
      if (!aLinkType) return;

      // check for element name
      let aElements = this.getSubArray(aLinkType, aLink.ObjName);
      if (aElements)
        aElements = this.getActiveLinksOnly(aElements);
      this.logger.info([ 'publish: aElements', aElements ]);
      if (!aElements) return;

      for (const i in aElements) {
        if (aElements[i] != oElm.caller) {
          if (aElements[i] && aElements[i].controller && aElements[i].controller[oElm.method]) {
            this.logger.info([ `       -> ${aElements[i].controller.view}`, aElements[i].controller, oElm.method, oElm ]);
            aElements[i].controller[oElm.method](oElm);
          }
        }
      }
    } catch (e) {
      akioma.log.error('Error in controller publish', oElm, e);
    }
  },
  /**
 * Method for searching for active link publishing
 * @param  {array} aElements The list of available links
 * @memberOf AK_Messenger
 * @instance
 * @return {array}           The list of available active links
 */
  getActiveLinksOnly(aElements) {
    const aActiveLinks = [];
    for (const a in aElements) {
      const element = aElements[a];
      if (element.controller.activeLink == undefined || element.controller.activeLink == true)
        aActiveLinks.push(element);

    }

    return aActiveLinks;
  },
  /**
 * Method getObjects will return all elements found with a particular link
 * @instance
 * @memberOf AK_Messenger
 * @param  {object} oElm
 * @param  {string} cLink
 * @return {array}       The elements found with that link.
 */
  getObjects: function(oElm, cLink) {
    if (oElm.container) {
      const aLink = this.splitLink(cLink),
        oLinks = oElm.container.links;

      // check link
      if (!aLink) {
        this.logger.error(`publish: Link not allowed! -> ${cLink}`);
        return false;
      }

      // check linkname
      const aLinkName = this.getSubArray(oLinks, aLink.LinkName);
      if (!aLinkName) return;

      // check linktype
      const aLinkType = this.getSubArray(aLinkName, aLink.LinkType);
      if (!aLinkType) return;

      // check for element name
      const aElements = this.getSubArray(aLinkType, aLink.ObjName);
      if (!aElements) return;

      return aElements;
    }

  },
  /**
 * Method Splits a link string into an array
 * @instance
 * @memberOf AK_Messenger
 * @param  {string} cLink
 * @return {object}       Object that splits string into LinkName, LinkType and ObjName keys.
 */
  splitLink: function(cLink) {
    const aLink = cLink.split(':');

    if (aLink.length != 3)
      return false;

    return {
      LinkName: aLink[0],
      LinkType: aLink[1],
      ObjName: aLink[2]
    };
  },

  // get a sub array
  /**
 * Method that checks fro sub array links
 * @instance
 * @memberOf AK_Messenger
 * @param  {array} aArray Object array
 * @param  {string} cLink
 * @return {array|boolean}        Return array of objects or false if none
 */
  getSubArray: function(aArray, cLink) {
    // check Array
    if (!aArray[cLink])
      return false;

    return aArray[cLink];
  }
};
