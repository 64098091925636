/**
* @class akioma.numeric
*/
akioma.numeric = {};
// TODO - Move Numeric methods in a dedicated class. They're mostly used only internally

/**
 * Allows only digits in a field
 * @param  {Object}   oSelf Controller
 * @param  {HTMLNode} oElement Html node which only allows digits
 * @instance
 * @memberOf akioma
 */
akioma.numeric.preventInput = function(oSelf, oElement) {
  oElement.onkeypress = function(e) {
    if (isNull(oSelf.numeric))
      return true;
    const iIndexOfDecSep = (this.value || this.origVal) ? (this.value || this.origVal).indexOf(oSelf.numeric.decSep) : -1,
      cCharacter = String.fromCharCode((e || event).keyCode);

    let cValidChars = (oSelf.opt.dataType == 'decimal') ? '0123456789.' : '0123456789';
    if (oSelf.view == 'inputnum')
      cValidChars = (oSelf.opt.dataType == 'decimal') ? `0123456789${oSelf.numeric.decSep}` : '0123456789';

    if (!oSelf.numeric.bPositive && (e || event).keyCode == 45) {
      const cText = $(e.target).val();
      if (!cText.startsWith('-')) {
        const cursorStart = e.target.selectionStart;
        const cursorEnd = e.target.selectionEnd;
        // resets cursor position when changing value
        $(e.target).val(`-${cText}`);
        // Set cursor to the old position.
        e.target.setSelectionRange(cursorStart + 1, cursorEnd + 1);
      }
      return false;
    }

    if (cCharacter === oSelf.numeric.decSep && iIndexOfDecSep !== -1)
      return false;

    if (cValidChars.indexOf(cCharacter) === -1)
      return false;
    else if (oSelf.numeric.maxLength !== 0) {
      if (this.selectionStart === this.selectionEnd && (this.selectionStart <= iIndexOfDecSep || iIndexOfDecSep == -1)) {

        let iCurrentLength = 0;
        if (this.value || this.origVal)
          iCurrentLength = (this.value || this.origVal).replace(oSelf.numeric.groupSep, '').split(oSelf.numeric.decSep)[0].replace(/\D+/g, '').length;

        if (iCurrentLength >= oSelf.numeric.maxLength && cCharacter !== oSelf.numeric.decSep)
          return false;
      }
    }
  };


  oElement.onkeydown = function(e) {
    if (isNull(oSelf.numeric))
      return true;
    const ctrl = (e || event).ctrlKey ? (e || event).ctrlKey : (((e || event).keyCode === 17) ? true : false); // detect ctrl Key
    const iIndexOfDecSep = (this.value || this.origVal) ? (this.value || this.origVal).indexOf(oSelf.numeric.decSep) : -1;

    if (ctrl) {
      if ((e || event).keyCode == 86) // does not allow pasting from keyboard
        return false;
      else if ((e || event).keyCode == 88 && this.selectionStart <= iIndexOfDecSep && this.selectionEnd > iIndexOfDecSep)// does not allow cutting if the result does not contain the decimal separator
        return false;
    }

    return true;
  };
};

/**
 * Gets unformatted value for a field
 * @param  {Object} oSelf Controller
 * @return {Integer} Unformatted value
 * @instance
 * @memberOf akioma
 */
akioma.numeric.getUnmaskedValue = function(oSelf) {
  const a = oSelf.dhx.parentElement.parentElement._df;
  const data = $(oSelf.dhx).val();

  // if no format, return data
  if (!a)
    return data;
  // if empty string, return 0
  if (data == '')
    return 0;

  let ndata = parseFloat(data.toString().replace(/[^0-9]*/g, ''));

  if (data == ndata || data == ndata * -1)
    return data;

  // check for negative value
  if (data.toString().substr(0, 1) == '-')
    ndata = ndata * -1;


  // add missing decimals
  let decSep;
  if (data.indexOf(a['d_sep']) > 0)
    decSep = a['d_sep'];
  if (decSep) {
    const cLen = data.split(decSep)[1].length;
    ndata = ndata * Math.pow(10, a['d_len'] - cLen);
    if (a['d_len'] > 0)
      ndata = ndata / Math.pow(10, a['d_len']);
  }

  return ndata;
};

/**
 * Removes formatting from a value.
 * @param {String} cValue Value to unformat.
 * @param {Object} oNumeric Format settings of the value.
 * @return {Integer} Unformatted value
 * @instance
 * @memberOf akioma
 */
akioma.numeric.unformatValue = function(cValue, oNumeric) {
  // Helper function for replacing all occurences of given string
  function replaceAll(cString, cReplace, cReplaceWith) {
    if (cReplace === null || cReplace === undefined || cReplace === '') return cString;
    // split&join was chosen instead of replace w/ global regex to avoid separator values to be treated as special regexp symbols
    return cString.split(cReplace).join(cReplaceWith);
  }

  const bNegative = (cValue.substr(0, 1) == '-') ? true : false,
    cRegString = (oNumeric.decSep) ? `[^0-9\\${oNumeric.decSep}]` : '[^0-9]',
    cRegex = new RegExp(cRegString, 'g');

  let cUnformattedValue = cValue.replace(cRegex, '');
  cUnformattedValue = replaceAll(cUnformattedValue, oNumeric.decSep, '.');

  if (bNegative && cUnformattedValue)
    cUnformattedValue = `-${cUnformattedValue}`;

  // Parse value to number
  return Number(cUnformattedValue);
};
