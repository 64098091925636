/**
 * Global method used for calling a businessTask method automatically based on options
 * @namespace akioma
 * @memberof akioma
 * @param {object} self The controller from where the action was triggered
 * @param {object} opts Settings for businessTask call
 * @param {string} opts.name The name of the businessTask
 * @param {string} opts.methodName The name of the businessTask method
 * @param {object} opts.params The object containing the payload of the businessTask method call
 * @param {object} opts.options The object containing the settings for the businessTask
 * @param {object} opts.eventPre Event which is called before the call is done
 * @param {object} opts.eventPost Event which is called after the call is done
 * @public
 */
akioma.callInvokeServerTask = function(self, opts) {
  const options = opts;
  options.params = { plcParameter: {} };

  const forms = self.screen._controller.getAllChildrenByType('form');
  for (const form of forms) {
    if (!form.validate()) {
      form.dataSource.dynObject.controller.showFormValidationError();
      return;
    }
  }

  try {
    const oActionOptions = JSON.parse(options.options.replace(/'/g, '"'));
    options.optionsJSON = oActionOptions;
  } catch (e) {
    akioma.log.error(e);
  }

  let optionsEventPre = options;
  if (options.eventPre)
    optionsEventPre = callReturnAkiomaCode(self.controller, options.eventPre, options);


  const invokeObject = {
    name: optionsEventPre.name,
    methodName: optionsEventPre.methodName,
    paramObj: optionsEventPre.params
  };

  $.extend(invokeObject, optionsEventPre.optionsJSON);

  akioma.invokeServerTask(invokeObject).done(result => {
    if (options.eventPost)
      callReturnAkiomaCode(self.controller, options.eventPost, result);
  }).fail(result => {
    const response = result[2].response;
    if (options.eventPost)
      callReturnAkiomaCode(self.controller, options.eventPost, response || result);
  });
};

/**
 * Global method used for calling a businessTask method
 * @namespace akioma
 * @memberof akioma
 * @example
 * akioma.invokeServerTask(
 *     { name       : BusinessTask,
 *       methodName : MethodName,
 *       paramObj   : ParameterObject
 *     }).then(function(data){
 *        akioma.swat.Message.message({
 *                                     title  : "Information"
 *                                    , type   : "info"
 *                                    , text   : "Datensatz erfolgreich gespeichert"
 *                                    , expire : 5000
 *                                   });
 *
 *     }).fail(function(jsdo, success, request){
 *         akioma.swat.Message.message({
 *             type: 'error',
 *             text: msg
 *         })
 *     });
 * @param {object} opts Settings for businessTask call
 * @param {string} opts.name The name of the businessTask
 * @param {string} opts.methodName The name of the businessTask method
 * @param {object} opts.paramObj The object containing the payload of the businessTask method call
 * @param {string} [opts.methodType='openedge'] Specifies the type of the method to invoke. It can be either 'openedge' or 'kinvey'. Default: 'openedge'
 * @public
 */
akioma.invokeServerTask = function(opts) {
  const deferred = $.Deferred();

  if (opts.showWaitCursor && opts.uiContext) {
    akioma.WaitCursor.showWaitCursor(opts.uiContext);
    akioma.WaitCursor.showProgressState(opts.uiContext);
  }

  if (opts.methodType == 'kinvey') {
    akioma.KinveyInvokeServerTask(opts).then(response => {
      deferred.resolve(response);
    }).catch(err => {
      deferred.reject(err);
    }).finally(() => {
      if (opts.showWaitCursor && opts.uiContext) {
        akioma.WaitCursor.hideWaitCursor(opts.uiContext);
        akioma.WaitCursor.hideProgressState(opts.uiContext);
      }
    });

    return deferred;
  }

  const BTMethodPromise = akioma.callServerTask(opts);
  const message = new akioma.SmartMessage(opts);

  message.fireOnSuccess(res => {
    if (opts.showWaitCursor && opts.uiContext) {
      akioma.WaitCursor.hideWaitCursor(opts.uiContext);
      akioma.WaitCursor.hideProgressState(opts.uiContext);
    }
    deferred.resolve(res);
  }).fireOnError((...errorDetails) => {
    if (opts.showWaitCursor && opts.uiContext) {
      akioma.WaitCursor.hideWaitCursor(opts.uiContext);
      akioma.WaitCursor.hideProgressState(opts.uiContext);
    }
    // if simple error text message, reject only text
    if (typeof (errorDetails[0]) === 'string')
      deferred.reject(errorDetails[0]);
    else {
      // otherwise return all params, jsdo, success, request data
      deferred.reject(errorDetails);
    }
  });

  BTMethodPromise.done(response => {
    const ret = response.plcParameter;
    if (ret.Error) {
      // special handling for Error, ErrorText pair
      let errorText = ret.ErrorText;
      if (isNull(errorText) && ret.Error != true)
        errorText = ret.Error;

      if (errorText) {
        // displayErrorText always rejects
        message.displayErrorText(errorText);
      } else
        deferred.reject(response);

    } else {
      let questionUnAnswered;
      if (ret.akUiControl && ret.akUiControl.Questions && ret.akUiControl.Questions.length > 0)
        questionUnAnswered = _.findWhere(ret.akUiControl.Questions, { MessageReply: 'Unanswered' });
      else
        questionUnAnswered = false;

      if (questionUnAnswered) {
        for (const question of ret.akUiControl.Questions) {
          if (question.MessageReply.toLowerCase() == 'unanswered') {
            message.loadQuestion(question, ret.akUiControl.Questions, response);
            break;
          }
        }
      } else {
        // if there are no more questions unaswered
        message.callSuccessEvents(response);
      }
    }
  }).fail((jsdo, success, request) => {
    if (opts.showWaitCursor && opts.uiContext) {
      akioma.WaitCursor.hideWaitCursor(opts.uiContext);
      akioma.WaitCursor.hideProgressState(opts.uiContext);
    }

    let cErrorMsg;
    if (request && request.response)
      cErrorMsg = request.response.message ? request.response.message : (`${request.response.title} : ${request.response.returnValue}`);
    else if (jsdo.message) {
      akioma.notification({
        type: 'error',
        text: jsdo.message
      });
    } else {
      akioma.notification({
        type: 'error',
        text: `There was an error invoking the business task '${opts.name}', method named '${opts.methodName}'.`
      });
    }

    if (cErrorMsg && cErrorMsg.indexOf('undefined') === -1) {
      akioma.SmartMessage.parseServerText(cErrorMsg).done(aFinalMsg => {
        let cFinalMsg = '';
        let bModal = false;

        for (const i in aFinalMsg) {
          cFinalMsg += (`${aFinalMsg[i].text}</br>`);
          if (aFinalMsg[i].modal)
            bModal = true;
        }

        // call error events
        message.callErrorEvents(jsdo, success, request);

        if (bModal)
          akioma.message({ type: 'error', text: cFinalMsg });
        else
          akioma.notification({ type: 'error', text: cFinalMsg });


      });
    } else {
      let cErrorText = jsdo.message || '';

      // if multi messages append to one large message
      if (jsdo.messages) {
        jsdo.messages.forEach(msg => {
          cErrorText += (`${msg.message}\n`);
        });
      }

      const errorInfo = {
        statusCode: request.xhr.status,
        statusText: request.xhr.statusText,
        errorText: cErrorText || jsdo.message || '',
        type: request.xhr.type,
        url: jsdo.url,
        extras: [ jsdo, request.xhr ]
      };

      akioma.HttpClient.displayErrorMessage(errorInfo, request.xhr);
    }

    deferred.reject(jsdo, success, request);
  });

  return deferred.promise();
};

/**
 * Method called after catalog add when using invoke server task
 * @param {object} param0 JSDO object
 * @param {Deferred} deferred Deferred Jquery object
 * @param {object} opts Object attributes
 */
akioma.onAfterAddCalatogServerTask = function({ info }, deferred, opts) {
  try {
    const jsdo = new progress.data.JSDO({ name: opts.name });

    if (!jsdo[opts.methodName])
      throw Error(`Method name ${opts.methodName} in ${opts.name} not found`);

    jsdo.invoke(opts.methodName, opts.paramObj).then(({ request }) => {
      deferred.resolve(request.response);
    }).catch(result => {
      // check for error type object
      if (akioma.isObjOfTypeError(result)) {
        akioma.log.error(result);
        return;
      }
      const { jsdo, success, request } = result;

      let err = 'An internal error has occured!';
      let msg = '';

      if (request.response)
        err = request.response.error || request.response.errors || request.response._error || request.response._errors;

      if (err) {
        if (err instanceof Array) {
          for (let i = 0; i < err.length; i++)
            msg += (msg ? '\n' : '') + err[i];
        } else if (typeof err === 'string')
          msg = err;
        else
          msg = JSON.stringify(err);
      }

      deferred.reject(jsdo, success, request);
    });
  } catch (err) {
    deferred.reject(err, false, info);
  }
};

/**
 * Method to call a JSDO invoke server task
 * @params {object} opts
 */
akioma.callServerTask = function(opts) {
  const deferred = $.Deferred();
  const serviceURI = opts.serviceURI || window.location.origin;
  const jsdoSession = akioma.restSession;

  const jsdoSettings = {
    serviceURI: serviceURI,
    catalogURIs: `${serviceURI}/web/Catalog/${opts.name}`,
    authenticationModel: progress.data.Session.AUTH_TYPE_FORM
  };

  const oMobileCatalogService = progress.data.ServicesManager._resources[opts.name];

  if (!oMobileCatalogService) {
    jsdoSession.addCatalog(jsdoSettings.catalogURIs)
      .then(res => {
        akioma.onAfterAddCalatogServerTask(res, deferred, opts);
      })
      .catch(res => {
        akioma.onAfterAddCalatogServerTask(res, deferred, opts);
      });
  } else
    akioma.onAfterAddCalatogServerTask({ jsdosession: jsdoSession, result: 1, info: null }, deferred, opts);

  return deferred.promise();
};

akioma.callServerMethod = app.controller.callServerMethod;
