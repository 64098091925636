

dhtmlXDataView.prototype.types.offerLine = {
  css: 'offerLine',
  template:
dhtmlx.Template.fromHTML('<table border=\'0\' cellpadding=\'0\' cellspacing=\'0\' style=\'width:100%; background=linear-gradient(to right, #F3F3F3 0%, #C1CCD2 100%)\'>\
<tbody>\
<tr>\
<td style=\'height:48px; vertical-align:middle;\' valign=\'middle\' height=\'48\'><img alt=\'\' src=\'{common.image()}\' height=\'48\'/></td>\
<td style=\'height:48px; vertical-align:middle; text-align:left; width:50%;\' align=\'left\' valign=\'middle\' height=\'48\'><span style=\'font-size:18px; line-height: 48px; margin: 0 20px; vertical-align: middle;\'>#selfkey#</span><span style=\'font-size:11px; line-height: 48px; vertical-align: middle;\'> #datarecdesc# </span></td>\
<td style=\'text-align:right; height:48px; width:50%;\' width=\'50%\'><span style=\'font-size:20px; line-height:48px; vertical-align: middle; margin-right:20px;\'>{common.currency()}</span></td>\
</tr>\
<tr>\
<td colspan=\'3\' width=\'100%\'>&nbsp;</td>\
</tr>\
<tr>\
<td colspan=\'3\' width=\'100%\'><table border=\'0\' cellpadding=\'0\' cellspacing=\'0\' style=\'width:100%;\'>\
<tbody>\
<tr>\
<td style=\'width:50%; padding:5px;\'>#texthtml#<br>#id#</td>\
<td style=\'width:50%; vertical-align:top;\' valign=\'top\'><img style=\'width:150px;\' alt=\'\' src=\'#imagefilename#\' /><br><br></td>\
</tr>\
</tbody>\
</table>\
</td>\
</tr>\
</tbody>\
</table>\
\
<table border=\'0\' cellpadding=\'0\' cellspacing=\'0\' style=\'width:100%\'>\
<tbody>\
<tr>\
</tr>\
<tr>\
<td height=\'18\' width=\'50%\'>&nbsp;</td>\
<td height=\'18\' width=\'50%\'>&nbsp;</td>\
</tr>\
</tbody>\
</table>\
'),
  width: 210,
  height: 250,
  margin: 5,
  padding: 0,
  drag_marker: 'dhxdataview_skyblue/dnd_selector_cells.png',
  // custom properties
  icons_src_dir: './',

  currency: function(obj) {
    let eVal = 0;

    if (obj.total && obj.total > 0)
      eVal = obj.total;
    else if (obj.amount && obj.amount > 0)
      eVal = obj.amount;

    return `$ ${eVal}`;
  },

  image: function(obj) {
    let cName = '';
    let iIdx = 0;
    const cTypeKeys = 'ofr,ppsitm,dum,grp,grptxt,txt,txtmod';
    const cTypeImgs = 'offerw,articlew,articlew,bomw,bomw,txtmodw,txtmodw';
    if (obj.typekey)
      cName = obj.typekey;
    else if (obj.originalfilename)
      cName = obj.originalfilename;
    if (cName) {
      cName = akioma.entry(3, cName, '.').toLowerCase();
      iIdx = akioma.lookup(cName, cTypeKeys);
      if (iIdx > 0)
        cName = `taskbar_${akioma.entry(iIdx, cTypeImgs)}`;
    }
    cName = `/dhtmlx/imgs/akioma/${cName || 'fldr'}.png`;
    return cName;
  },

  text: function(obj) {
    let cName = '';
    try {
      if (obj.datarecdesc)
        cName = obj.datarecdesc;
      else if (obj.TypeDesc)
        cName = obj.TypeDesc;

      return cName.split('.')[0];
    } catch (err) {
      akioma.log.error('error', err);
    }

  },

  setBack: function(obj) {
    if (obj.haschilds == true)
      return 'ipguidedinfolinefieldtrue';
    if (obj.haschilds == false)
      return 'ipguidedinfolinefieldfalse';
    else
      return 'ipguidedinfolinefield';

  },

  size: function(obj) {
    return obj.TypeKey ? (obj.TypeKey) : '0';
  }
};

dhtmlXDataView.prototype.types.product = {
  css: 'bpmtasks',
  template: dhtmlx.Template
    .fromHTML('<table border=\'0\' width=\'100%\' cellpadding=\'0\' cellspacing=\'0\'><tr><td  colspan=\'2\' width=\'100%\' class=\'ipguidedkachelheader\'>#selfkey#</td></tr><tr><td width=\'100%\' colspan=\'2\' class=\'ipguidedkachelbild\'><img onmousedown=\'return false;\' style=\'max-width:100%; max-height: 150px; padding: 5px;\' border=\'0\' src=\'{common.image()}\'></td></tr><td width=\'100%\' height=\'46\' colspan=\'2\' class=\'mio\'><span class=\'ipguidedkacheltitel\'>#datarecdesc#</span><br><span class=\'ipguidedkachelsubtitel\'>#id#</span></td></tr></table><table border=\'0\' width=\'100%\' cellpadding=\'0\' cellspacing=\'0\'><tr><tr><td width=\'80\' height=\'18\' class=\'ipguidedinfolinelabel\'>Durchmesser</td><td width=\'65%\' height=\'18\' class=\'ipguidedinfolinefield\'>#costs1#</td></tr><tr><td width=\'35%\' height=\'18\' class=\'ipguidedinfolinelabel\'>Typ</td><td width=\'65%\' height=\'18\' class=\'ipguidedinfolinefield\'>#Misc#</td></tr><tr><td colspan=\'2\' width=\'100%\' height=\'18\' class=\'{common.setBack()}\'>Details</td></tr></table>'),
  width: 210,
  height: 250,
  margin: 5,
  padding: 0,
  drag_marker: 'dhxdataview_skyblue/dnd_selector_cells.png',
  // custom properties
  icons_src_dir: './',
  image: function() {
    return '/bilder/Versuche/ntn_sample.jpg';
  },
  setBack: function(obj) {
    if (obj.haschilds == true)
      return 'ipguidedinfolinefieldtrue';
    if (obj.haschilds == false)
      return 'ipguidedinfolinefieldfalse';
    else
      return 'ipguidedinfolinefield';
  },
  text: function(obj) {
    return `${obj.taskstatus.split('_')[1]}<br> ${obj.activityname}`;
  },
  size: function(obj) {
    return obj.filesize ? (`${obj.filesize}b`) : '0';
  }
};

dhtmlXDataView.prototype.types.product2 = {
  css: 'services',
  template: dhtmlx.Template
    .fromHTML('<table border=\'0\' width=\'300\' cellpadding=\'0\' cellspacing=\'0\'><tr><td class=\'ipprodkacheltable\'><table border=\'0\' width=\'300\' cellpadding=\'0\' cellspacing=\'0\'><tr><td  colspan=\'3\' width=\'300\' class=\'ipprodkachelheader\'>#datarecdesc#</td></tr><tr><td width=\'100\' height=\'50\' class=\'ipprodkachelbild\'><img onmousedown=\'return false;\' style=\'width:100px; height: 50px;\' border=\'0\' src=\'../../dhtmlx/imgs/akioma/dummy1.jpg\'></td><td width=\'100\' height=\'50\' class=\'ipprodkachelbild\'><img onmousedown=\'return false;\' style=\'width:100px; height: 50px;\' border=\'0\' src=\'../../dhtmlx/imgs/akioma/dummy3.jpg\'></td><td width=\'100\' height=\'50\' class=\'ipprodkachelbild\'><img onmousedown=\'return false;\' style=\'width:100px; height: 50px;\' border=\'0\' src=\'../../dhtmlx/imgs/akioma/dummy2.jpg\'></td></tr><tr><td  colspan=\'3\' width=\'300\' class=\'ipprodkacheltitel\'>#groupkey#</td></tr><tr><td  colspan=\'3\' width=\'300\' class=\'ipprodkachelsubtitel\'>#groupdesc#</td></tr></table><table border=\'0\' width=\'300\' cellpadding=\'0\' cellspacing=\'0\'><tr><td width=\'100\' height=\'18\' class=\'ipprodinfolinelabel\'>Art.-Nr.</td><td width=\'200\' height=\'18\' class=\'ipprodinfolinefield\'>#selfkey#</td></tr><tr><td width=\'100\' height=\'18\' class=\'ipprodinfolinelabel\'>Erstellt am</td><td width=\'200\' height=\'18\' class=\'ipprodinfolinefield\'>#datecreated#</td></tr><tr><td width=\'100\' height=\'18\' class=\'ipprodinfolinelabel\'>Erstellt am</td><td width=\'200\' height=\'18\' class=\'ipprodinfolinefield\'>#datecreated#</td></tr></table></td></tr></table>'),
  width: 302,
  height: 182,
  margin: 15,
  padding: 0,
  // custom properties
  icons_src_dir: './',

  size: function(obj) {
    return obj.filesize ? (`${obj.filesize}b`) : '0';
  }
};

dhtmlXDataView.prototype.types.rb_std = {
  css: 'bpmtasks',
  template: dhtmlx.Template
    .fromHTML('<table border=\'0\' width=\'240\' cellpadding=\'0\' cellspacing=\'0\'><tr><td  colspan=\'2\' width=\'240\' class=\'iptaskkachelheader\'>#selfkey#</td></tr><tr><td width=\'50\' height=\'50\' class=\'iptaskkachelbild\'><img onmousedown=\'return false;\' style=\'width:26px; height: 26px; padding: 12px;\' border=\'0\' src=\'/AkiomaShare/images/hp_printer.jpg\'></td><td width=\'190\' height=\'50\' class=\'lio\'><span class=\'iptaskkacheltitel\'>#name#</span><br><span class=\'iptaskkachelsubtitel\'>#id#</span></td></tr></table><table border=\'0\' width=\'240\' cellpadding=\'0\' cellspacing=\'0\'><tr><td width=\'80\' height=\'18\' class=\'iptaskinfolinelabel\'>Ersteller</td><td width=\'160\' height=\'18\' class=\'iptaskinfolinefieldcolor\'>#creator#</td></tr><tr><td width=\'80\' height=\'18\' class=\'iptaskinfolinelabel\'>F&auml;llig am</td><td width=\'160\' height=\'18\' class=\'iptaskinfolinefield\'>#duedate#</td></tr><tr><td width=\'80\' height=\'18\' class=\'iptaskinfolinelabel\'>Priorit&auml;t</td><td width=\'160\' height=\'18\' class=\'{common.setBack()}\'>#priority#</td></tr><tr><td width=\'80\' height=\'18\' class=\'iptaskinfolinelabel\'>Prozess</td><td width=\'160\' height=\'18\' class=\'iptaskinfolinefield\'>#processname#</td></tr><tr><td width=\'80\' height=\'18\' class=\'iptaskinfolinelabel\'>Status</td><td width=\'160\' height=\'18\' class=\'iptaskinfolinefield\'>#taskstatus#</td></tr></table>'),
  width: 240,
  height: 166,
  margin: 5,
  padding: 0,
  drag_marker: 'dhxdataview_skyblue/dnd_selector_cells.png',
  // custom properties
  icons_src_dir: './',
  image: function() {
    return '/bilder/Versuche/ntn_sample.jpg';
  },
  setBack: function(obj) {
    if (obj.priority == 'high')
      return 'iptaskinfolinefieldp1';
    if (obj.priority == 'medium')
      return 'iptaskinfolinefieldp2';
    if (obj.priority == 'low')
      return 'iptaskinfolinefieldp3';
    else
      return 'iptaskinfolinefield';

  },

  text: function(obj) {
    return `${obj.taskstatus.split('_')[1]}<br> ${obj.activityname}`;
  },
  size: function(obj) {
    return obj.filesize ? (`${obj.filesize}b`) : '0';
  }
};

dhtmlXDataView.prototype.types.serviceportal = {
  css: 'tranpack',
  template: dhtmlx.Template
    .fromHTML('<table border=\'0\' width=\'300\' cellpadding=\'0\' cellspacing=\'0\'><tr><td colspan=\'3\' width=\'300\' height=\'18\' class=\'ipkachelheader\'>#selfdesc#</td></tr><tr><td width=\'130\' class=\'ipkachelbild\'><img onmousedown=\'return false;\' style=\'width:32px;\' border=\'0\' src=\'{common.image()}\'></td><td width=\'40\'  height=\'32\' class=\'ipkachelpfeil\'>&gt;&gt;</td><td width=\'130\' class=\'ipkachelbild\'><img onmousedown=\'return false;\' style=\'width:32px; margin: 2px;\' border=\'0\' src=\'{common.image2()}\'></td></tr><tr><td width=\'130\' height=\'16\' class=\'ipsprachlabel\'>#sourcelangkey#</td><td width=\'40\'>&nbsp;</td><td width=\'130\' height=\'16\' class=\'ipsprachlabel\'>#targetlangkey#</td></tr><tr><td colspan=\'3\' width=\'300\' height=\'16\' class=\'ipkachelproductdescription\'>#bomdesc#</td></tr><tr><td colspan=\'3\' width=\'300\' height=\'16\' class=\'ipkachelproductgroup\'>#bomkey#</td></tr><tr><td colspan=\'3\' width=\'300\' height=\'6\' class=\'ipspacer10\'>&nbsp;</td></tr></table><table border=\'0\' width=\'300\' cellpadding=\'0\' cellspacing=\'0\'><tr><td width=\'100\' height=\'18\' class=\'ipinfolinelabel\'>No.</td><td width=\'200\' height=\'18\' class=\'ipinfolinefield\'>#selfkey# (#jobnumber#)</td></tr><tr><td width=\'100\' height=\'18\' class=\'ipinfolinelabel\'>Company</td><td width=\'200\' height=\'18\' class=\'ipinfolinefield\'>{common.getKey()}</td></tr><tr><td width=\'100\' height=\'18\' class=\'ipinfolinelabel\'>Translator</td><td width=\'200\' height=\'18\' class=\'ipinfolinefield\'>#translatorname#</td></tr><tr><td width=\'100\' height=\'18\' class=\'ipinfolinelabel\'>Number of words</td><td width=\'200\' height=\'18\' class=\'ipinfolinefield\'>#numwordstotal#</td></tr><tr><td width=\'100\' height=\'18\' class=\'ipinfolinelabel\'>Due Date</td><td width=\'200\' height=\'18\' class=\'ipinfolinefield\'>#endingdate#</td></tr></table>'),
  width: 300,
  height: 220,
  margin: 8,
  padding: 0,
  // custom properties
  icons_src_dir: './',
  image2: function() {
    return '/dummy/dummy1.jpg';
  },
  image: function() {
    return '/imgs/akioma/computer-48.png';
  },
  getKey: function(obj) {
    if (obj.selfno)
      return obj.selfno;
    else
      return '';
  },
  getDesc: function(obj) {
    if (obj.selfdesc)
      return obj.selfdesc;
    else
      return '';
  },
  size: function(obj) {
    return obj.filesize ? (`${obj.filesize}b`) : '0';
  }
};

dhtmlXDataView.prototype.types.imgthumbs = {
  css: 'fthumbs',
  template: dhtmlx.Template
    .fromHTML('<table border=\'0\' width=\'140\' cellpadding=\'0\' cellspacing=\'0\'><tr><td colspan=\'2\' width=\'140\' height=\'18\' class=\'ipimgkachelheader\'>#cfilename#</td></tr><tr><td colspan=\'2\' width=\'140\' height=\'110\' class=\'lio\'><div class=\'ipimgcontainer\'><img src=\'{common.image()}\' border=\'0\' width=\'100%\' class=\'ipimgkachelbild\'></div></td></tr><tr><td width=\'70\' height=\'22\' class=\'ipimgkachelfooterlinks\'>W: #filewidth#</td><td width=\'70\' height=\'22\' class=\'ipimgkachelfooterrechts\'>H: #fileheight#</td></tr></table>'),
  width: 140,
  height: 150,
  margin: 8,
  padding: 2,
  // custom properties
  thumbs_creator_url: './',
  photos_rel_dir: './',
  image: function(obj) {
    let cName = '';
    if (obj.clientfilename)
      cName = obj.clientfilename;

    return cName;
  },
  text: function(obj) {
    let cName = '';
    if (obj.cfilename)
      cName = obj.cfilename;

    return cName.split('.')[0];
  }
};

dhtmlXDataView.prototype.types.imgmedium = {
  css: 'fthumbs',
  template: dhtmlx.Template
    .fromHTML('<table border=\'0\' width=\'100\' cellpadding=\'0\' cellspacing=\'0\'><tr><td colspan=\'2\' width=\'100\' height=\'18\' class=\'ipimgMkachelheader\'>#cfilename#</td></tr><tr><td colspan=\'2\' width=\'100\' height=\'64\' class=\'lio\'><div class=\'ipimgMcontainer\'><img src=\'{common.image()}\' border=\'0\' width=\'100%\' class=\'ipimgMkachelbild\'></div></td></tr><tr><td width=\'50\' height=\'18\' class=\'ipimgMkachelfooterlinks\'>W: #filewidth#</td><td width=\'50\' height=\'18\' class=\'ipimgMkachelfooterrechts\'>H: #fileheight#</td></tr></table>'),
  width: 100,
  height: 104,
  margin: 8,
  padding: 2,
  // custom properties
  thumbs_creator_url: './',
  photos_rel_dir: './',
  image: function(obj) {
    let cName = '';
    if (obj.clientfilename)
      cName = obj.clientfilename;

    return cName;
  },
  text: function(obj) {
    let cName = '';
    if (obj.cfilename)
      cName = obj.cfilename;

    return cName.split('.')[0];
  }
};

dhtmlXDataView.prototype.types.imglarge = {
  css: 'fthumbs',
  template: dhtmlx.Template
    .fromHTML('<table border=\'0\' width=\'140\' cellpadding=\'0\' cellspacing=\'0\'><tr><td colspan=\'2\' width=\'140\' height=\'18\' class=\'ipimgLkachelheader\'>#cfilename#</td></tr><tr><td colspan=\'2\' width=\'140\' height=\'110\' class=\'lio\'><div class=\'ipimgLcontainer\'><img src=\'{common.image()}\' border=\'0\' width=\'100%\' class=\'ipimgLkachelbild\'></div></td></tr><tr><td width=\'70\' height=\'22\' class=\'ipimgLkachelfooterlinks\'>W: #filewidth#</td><td width=\'70\' height=\'22\' class=\'ipimgLkachelfooterrechts\'>H: #fileheight#</td></tr></table>'),
  width: 140,
  height: 150,
  margin: 8,
  padding: 2,
  // custom properties
  thumbs_creator_url: './',
  photos_rel_dir: './',
  image: function(obj) {
    let cName = '';
    if (obj.clientfilename)
      cName = obj.clientfilename;

    return cName;
  },
  text: function(obj) {
    let cName = '';
    if (obj.cfilename)
      cName = obj.cfilename;

    return cName.split('.')[0];
  }
};

dhtmlXDataView.prototype.types.imglist = {
  css: 'fthumbs',
  template: dhtmlx.Template
    .fromHTML('<div style=\'float: left; width: 20px; height: 16px;\'><img onmousedown=\'return false;\' border=\'0\' src=\'{common.image()}\' width=\'17\'></div><div style=\'float: left; width: 135px; overflow:hidden;\' class=\'dhx_item_text\'><span style=\'padding-left: 2px; padding-right: 2px;\'>#cfilename#</span></div>'),
  template_edit: dhtmlx.Template
    .fromHTML('<div style=\'float: left; width: 20px; height: 16px;\'><img onmousedown=\'return false;\' border=\'0\' src=\'{common.image()}\' width=\'17\'></div><div style=\'float: left; width: 135px;\' class=\'dhx_item_text\'><span style=\'padding-left: 2px; padding-right: 2px;\'><input type=\'text\' style=\'width:100%; height:100%;\' bind=\'obj.name\'></span></div>'),
  template_loading: dhtmlx.Template.fromHTML(''),
  width: 160,
  height: 20,
  margin: 1,
  padding: 0,
  // custom properties
  icons_src_dir: '/imgs/filetypes',
  image: function(obj) {
    let cName = '';
    if (obj.name)
      cName = obj.name;
    return `${this.icons_src_dir}/ico_${cName.split('.')[1] || 'img'
    }_32.gif`;
  },
  text: function(obj) {
    let cName = '';
    if (obj.cfilename)
      cName = obj.cfilename;

    return cName.split('.')[0];
  }
};

dhtmlXDataView.prototype.types.imgthumbs2 = {
  css: 'fthumbs2',
  template: dhtmlx.Template
    .fromHTML('<table border=\'0\' width=\'140\' cellpadding=\'0\' cellspacing=\'0\'><tr><td colspan=\'2\' width=\'140\' height=\'18\' class=\'ipimg2kachelheader\'>#cfilename#</td></tr><tr><td colspan=\'2\' width=\'140\' height=\'110\' class=\'lio\'><div class=\'ipimgcontainer\'><img src=\'{common.image()}\' border=\'0\' width=\'100%\' class=\'ipimg2kachelbild\'></div></td></tr><tr><td width=\'70\' height=\'22\' class=\'ipimgkachelfooterlinks\'>W: #filewidth#</td><td width=\'70\' height=\'22\' class=\'ipimgkachelfooterrechts\'>H: #fileheight#</td></tr></table>'),
  width: 140,
  height: 150,
  margin: 8,
  padding: 2,
  // custom properties
  thumbs_creator_url: './',
  photos_rel_dir: './',
  image: function(obj) {
    let cName = '';
    if (obj.clientfilename)
      cName = obj.clientfilename;

    return cName;
  },
  text: function(obj) {
    let cName = '';
    if (obj.cfilename)
      cName = obj.cfilename;

    return cName.split('.')[0];
  }
};

dhtmlXDataView.prototype.types.recitem = {
  css: 'tranpack',
  template: '<div style=\'background-image:url(/xxbilder/m3_abc_06.jpg); background-size:cover; height:130px !important; width:200px !important\'><b><u>#childkey#</u><br>#attributeflags#</b><br>#childdesc#<br>'
+ '</p><u>Artikel-Bild:</u><img src=\'#itempicture#\' style=\'width:100%; height:100%\'></div>',
  width: 180,
  height: 90,
  margin: 8,
  padding: 8,
  // custom properties
  icons_src_dir: './',
  image2: function(obj) {
    let cName = '/dhtmlx/imgs/akioma/lang_';
    if (obj.targetlangkey)
      cName += obj.targetlangkey.toLowerCase();
    cName += '.png';
    return cName;
  },
  image: function(obj) {
    let cName = '/dhtmlx/imgs/akioma/lang_';
    if (obj.sourcelangkey)
      cName += obj.sourcelangkey.toLowerCase();
    cName += '.png';
    return cName;

  },
  getKey: function(obj) {

    if (obj.translatordesc)
      return obj.translatordesc;
    else
      return '';

  },
  getDesc: function(obj) {
    if (obj.translatorname)
      return obj.translatorname;
    else
      return '';

  },
  size: function(obj) {
    return obj.filesize ? (`${obj.filesize}b`) : '0';
  }
};

dhtmlXDataView.prototype.types.tranpack = {
  css: 'tranpack',
  template: dhtmlx.Template
    .fromHTML('<table border=\'0\' width=\'300\' cellpadding=\'0\' cellspacing=\'0\'><tr><td colspan=\'3\' width=\'300\' height=\'18\' class=\'ipkachelheader\'>#selfdesc#</td></tr><tr><td width=\'130\' class=\'ipkachelbild\'><img onmousedown=\'return false;\' style=\'width:32px;\' border=\'0\' src=\'{common.image()}\'></td><td width=\'40\'  height=\'32\' class=\'ipkachelpfeil\'>&gt;&gt;</td><td width=\'130\' class=\'ipkachelbild\'><img onmousedown=\'return false;\' style=\'width:32px; margin: 2px;\' border=\'0\' src=\'{common.image2()}\'></td></tr><tr><td width=\'130\' height=\'16\' class=\'ipsprachlabel\'>#sourcelangkey#</td><td width=\'40\'>&nbsp;</td><td width=\'130\' height=\'16\' class=\'ipsprachlabel\'>#targetlangkey#</td></tr><tr><td colspan=\'3\' width=\'300\' height=\'16\' class=\'ipkachelproductdescription\'>#bomdesc#</td></tr><tr><td colspan=\'3\' width=\'300\' height=\'16\' class=\'ipkachelproductgroup\'>#bomkey#</td></tr><tr><td colspan=\'3\' width=\'300\' height=\'6\' class=\'ipspacer10\'>&nbsp;</td></tr></table><table border=\'0\' width=\'300\' cellpadding=\'0\' cellspacing=\'0\'><tr><td width=\'100\' height=\'18\' class=\'ipinfolinelabel\'>No.</td><td width=\'200\' height=\'18\' class=\'ipinfolinefield\'>#selfkey# (#jobnumber#)</td></tr><tr><td width=\'100\' height=\'18\' class=\'ipinfolinelabel\'>Company</td><td width=\'200\' height=\'18\' class=\'ipinfolinefield\'>{common.getKey()}</td></tr><tr><td width=\'100\' height=\'18\' class=\'ipinfolinelabel\'>Translator</td><td width=\'200\' height=\'18\' class=\'ipinfolinefield\'>#translatorname#</td></tr><tr><td width=\'100\' height=\'18\' class=\'ipinfolinelabel\'>Number of words</td><td width=\'200\' height=\'18\' class=\'ipinfolinefield\'>#numwordstotal#</td></tr><tr><td width=\'100\' height=\'18\' class=\'ipinfolinelabel\'>Due Date</td><td width=\'200\' height=\'18\' class=\'ipinfolinefield\'>#endingdate#</td></tr></table>'),
  width: 300,
  height: 220,
  margin: 8,
  padding: 0,
  // custom properties
  icons_src_dir: './',
  image2: function(obj) {
    let cName = '/dhtmlx/imgs/akioma/lang_';
    if (obj.targetlangkey)
      cName += obj.targetlangkey.toLowerCase();
    cName += '.png';
    return cName;
  },
  image: function(obj) {
    let cName = '/dhtmlx/imgs/akioma/lang_';
    if (obj.sourcelangkey)
      cName += obj.sourcelangkey.toLowerCase();
    cName += '.png';
    return cName;

  },
  getKey: function(obj) {

    if (obj.translatordesc)
      return obj.translatordesc;
    else
      return '';

  },
  getDesc: function(obj) {
    if (obj.translatorname)
      return obj.translatorname;
    else
      return '';

  },
  size: function(obj) {
    return obj.filesize ? (`${obj.filesize}b`) : '0';
  }
};

dhtmlXDataView.prototype.types.services = {
  css: 'services',
  template: dhtmlx.Template
    .fromHTML('<table border=\'0\' width=\'300\' cellpadding=\'0\' cellspacing=\'0\'><tr><td class=\'ipprodkacheltable\'><table border=\'0\' width=\'300\' cellpadding=\'0\' cellspacing=\'0\'><tr><td  colspan=\'3\' width=\'300\' class=\'ipprodkachelheader\'>#selfdesc#</td></tr><tr><td width=\'100\' height=\'50\' class=\'ipprodkachelbild\'><img onmousedown=\'return false;\' style=\'width:100px; height: 50px;\' border=\'0\' src=\'../../dhtmlx/imgs/akioma/dummy1.jpg\'></td><td width=\'100\' height=\'50\' class=\'ipprodkachelbild\'><img onmousedown=\'return false;\' style=\'width:100px; height: 50px;\' border=\'0\' src=\'../../dhtmlx/imgs/akioma/dummy3.jpg\'></td><td width=\'100\' height=\'50\' class=\'ipprodkachelbild\'><img onmousedown=\'return false;\' style=\'width:100px; height: 50px;\' border=\'0\' src=\'../../dhtmlx/imgs/akioma/dummy2.jpg\'></td></tr><tr><td  colspan=\'3\' width=\'300\' class=\'ipprodkacheltitel\'>#groupkey#</td></tr><tr><td  colspan=\'3\' width=\'300\' class=\'ipprodkachelsubtitel\'>#groupdesc#</td></tr></table><table border=\'0\' width=\'300\' cellpadding=\'0\' cellspacing=\'0\'><tr><td width=\'100\' height=\'18\' class=\'ipprodinfolinelabel\'>Art.-Nr.</td><td width=\'200\' height=\'18\' class=\'ipprodinfolinefield\'>#selfno#</td></tr><tr><td width=\'100\' height=\'18\' class=\'ipprodinfolinelabel\'>Erstellt am</td><td width=\'200\' height=\'18\' class=\'ipprodinfolinefield\'>#datecreated#</td></tr><tr><td width=\'100\' height=\'18\' class=\'ipprodinfolinelabel\'>Erstellt am</td><td width=\'200\' height=\'18\' class=\'ipprodinfolinefield\'>#datecreated#</td></tr></table></td></tr></table>'),
  width: 302,
  height: 182,
  margin: 15,
  padding: 0,
  // custom properties
  icons_src_dir: './',

  size: function(obj) {
    return obj.filesize ? (`${obj.filesize}b`) : '0';
  }
};

dhtmlXDataView.prototype.types.mru = {
  css: 'taskbar',
  template: dhtmlx.Template
    .fromHTML('<i class=\'{common.getIcon()}\' style=\'margin-top: 5px; float: left;margin-right: 5px;min-width: 50px; text-align: center; color: darkgreen;\'></i><div>#objectlabel# <b>{common.getKey()}</b><br>{common.getDesc()}</div>'),
  width: 200,
  height: 50,
  margin: 8,
  padding: 4,
  // custom properties
  getIcon: obj => {
    let icon;
    if (obj.objecticon)
      icon = `${obj.objecticon} fa-3x`;
    else
      icon = 'fad fa-file fa-3x';

    // force icon style from settings
    icon = akioma.icons.forceIconStyle(icon);

    return icon;
  },
  getKey: obj => obj.targetkey ? obj.targetkey : '',
  getDesc: obj => obj.targetdesc ? obj.targetdesc : '',
  size: obj => obj.filesize ? (`${obj.filesize}b`) : '0'
};

dhtmlXDataView.prototype.types.headertaskbar = {
  css: 'headertaskbar',
  template: dhtmlx.Template
    .fromHTML('{common.image()}<span class=\'taskbar-label\'>#label#</span><i class=\'close-icon\'></i><i class=\'fit-icon\'></i></div>'),
  width: 150,
  height: 47,
  margin: 1,
  padding: 2,
  // custom properties
  icons_src_dir: './',
  image: function(obj) {
    if (obj.icon && obj.icon.startsWith('fa')) {
      const imgText = `{{#iconHelper "${obj.icon}" "" this}}{{/iconHelper}}`;
      const templateF = Handlebars.compile(imgText);
      return templateF(obj);
    } else {
      const imgText = `{{#iconHelper ${obj.icon} "" this}}{{/iconHelper}}`;
      const cTemplate = obj.oBE.controller.getRecordDescription(imgText);
      return cTemplate;
    }
  },
  getKey: function(obj) {

    if (obj.Title) {
      const res = akioma.entry(2, obj.Title, '-');
      if (res)
        return akioma.entry(2, obj.Title, '-');
      else
        return '';
    } else
      return '';

  },
  getDesc: function(obj) {
    if (obj.Title) {
      const res = akioma.entry(3, obj.Title, '-');
      if (res)
        return res;
      else
        return '';
    } else
      return '';

  },
  size: function(obj) {
    return obj.filesize ? (`${obj.filesize}b`) : '0';
  }
};

dhtmlXDataView.prototype.types.taskbar = {
  css: 'taskbar',
  template: dhtmlx.Template
    .fromHTML('<img  onmousedown=\'return false;\' onerror=\'this.src={common.defaultPic};\'  style=\'width:26px; height: 26px; float: left; padding-right: 4px;\' border=\'0\' src=\'{common.image()}\'>#label# <b>{common.getKey()}</b><br>{common.getDesc()}<i class=\'close-icon\'></i><i class=\'fit-icon\'></i></div>'),
  width: 150,
  height: 35,
  margin: 1,
  padding: 2,
  defaultPic: '"/dhtmlx/imgs/akioma/taskbar_std.png"',
  // custom properties
  icons_src_dir: './',
  image: function(obj) {
    let cName = '/dhtmlx/imgs/akioma/taskbar_';
    if (obj.objectName)
      cName += `${obj.objectName.toLowerCase()}.png`;
    return cName;
  },
  getKey: function(obj) {

    if (obj.Title)
      return akioma.entry(2, obj.Title, '-');
    else
      return '';

  },
  getDesc: function(obj) {
    if (obj.Title)
      return akioma.entry(3, obj.Title, '-');
    else
      return '';

  },
  size: function(obj) {
    return obj.filesize ? (`${obj.filesize}b`) : '0';
  }
};

const handlebarsTemplate = {
  bpmtasksTemplate: '<table border=\'0\' width=\'193\' cellpadding=\'0\' cellspacing=\'0\' margin-bottom=\'2px\' style=\'box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)\'>' +
  '<tr><td width=\'100%\' height=\'40\' class=\'iptaskkachelbild\'><img onmousedown=\'return false;\' style=\'width:26px; height: 25px; padding: 8px; padding-bottom: 1px\' border=\'0\' src=\'../../dhtmlx/imgs/akioma/parallel_tasks-26_white.png\'></td></tr>' +
  '<tr><td  colspan=\'2\' width=\'240\' class=\'iptaskkachelheader\'>{{activityname}}</td></tr></table>' +
  '<table border=\'0\' width=\'193\' cellpadding=\'0\' cellspacing=\'0\' style=\'position: relative; top: 1.5px; box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12)\'>' +
  '<tr><td width=\'80\' height=\'20\' class=\'iptaskinfolinelabel\'>Created by</td><td width=\'160\' height=\'20\' class=\'iptaskinfolinefieldcolor\' style=\'padding-right:5px\'>{{creator}}</td></tr>' +
  '<tr><td width=\'80\' height=\'20\' class=\'iptaskinfolinelabel\'>Due date</td><td width=\'160\' height=\'20\' class=\'iptaskinfolinefield\' style=\'padding-right:5px\'>{{duedate}}</td></tr>' +
  '<tr><td width=\'80\' height=\'20\' class=\'iptaskinfolinelabel\'>Priority</td><td width=\'160\' height=\'20\' class=\'{{#classHelper priority}}{{/classHelper}}\' style=\'padding-right:5px\' >{{priority}}</td></tr>' +
  '<tr><td width=\'80\' height=\'20\' class=\'iptaskinfolinelabel\'>Process</td><td width=\'160\' height=\'20\' class=\'iptaskinfolinefield\' style=\'padding-right:5px\'>{{processname}}</td></tr>' +
  '<tr><td width=\'80\' height=\'20\' class=\'iptaskinfolinelabel\'>State</td><td width=\'160\' height=\'20\' class=\'iptaskinfolinefield\' style=\'padding-right:5px\'>{{taskstatus}}</td></tr></table>',
  functionsBETemplate: '<table border=\'0\' width=\'193\' cellpadding=\'0\' cellspacing=\'0\' margin-bottom=\'2px\' style=\'box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)\'>' +
  '<tr><td width=\'100%\' height=\'40\' class=\'iptaskkachelbild\'><img onmousedown=\'return false;\' style=\'width:26px; height: 25px; padding: 8px; padding-bottom: 1px\' border=\'0\' src=\'../../dhtmlx/imgs/akioma/parallel_tasks-26_white.png\'></td></tr>' +
  '<tr><td  colspan=\'2\' width=\'240\' class=\'iptaskkachelheader\'>{{functionname}}</td></tr></table>' +
  '<table border=\'0\' width=\'193\' cellpadding=\'0\' cellspacing=\'0\' style=\'position: relative; top: 1.5px; box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12)\'>' +
  '<tr><td width=\'80\' height=\'20\' class=\'iptaskinfolinelabel\'>Small image</td><td><i class=\'{{functionsmallimage}}\' aria-hidden=\'true\' style=\'padding-right:5px\' </i></td></tr></table>'
};


Handlebars.registerHelper('formatIcon', imgSrc => {
  if (imgSrc.includes('fa')) {
    const parts = imgSrc.split('#');
    const icon = parts[0];
    const style = parts[1] || '';
    return new Handlebars.SafeString(`<i class='${icon}' style='${style}' ></i>`);
  }
});

Handlebars.registerHelper('classHelper', value => {
  if (value == 'high')
    return 'iptaskinfolinefieldp1';
  if (value == 'medium')
    return 'iptaskinfolinefieldp2';
  if (value == 'low')
    return 'iptaskinfolinefieldp3';
  else
    return 'iptaskinfolinefield';
});

dhtmlXDataView.prototype.types.bpmtasks = {
  css: 'bpmtasks',
  template: function(obj) {
    const templateF = Handlebars.compile(handlebarsTemplate.bpmtasksTemplate);
    const compiledHtml = templateF(obj);

    return compiledHtml;
  },
  width: 193,
  height: 185,
  margin: 5,
  padding: 0,
  x_count: 3,
  y_count: 6,
  drag_marker: 'dhxdataview_skyblue/dnd_selector_cells.png',
  // custom properties
  icons_src_dir: './',
  image: function() {
    return '/dhtmlx/imgs/akioma/parallel_tasks-26.png';
  },
  setBack: function(obj) {
    if (obj.priority == 'high')
      return 'iptaskinfolinefieldp1';
    if (obj.priority == 'medium')
      return 'iptaskinfolinefieldp2';
    if (obj.priority == 'low')
      return 'iptaskinfolinefieldp3';
    else
      return 'iptaskinfolinefield';

  },

  text: function(obj) {
    return `${obj.taskstatus.split('_')[1]}<br> ${obj.activityname}`;
  },
  size: function(obj) {
    return obj.filesize ? (`${obj.filesize}b`) : '0';
  }
};

dhtmlXDataView.prototype.types.functionsbe = {
  css: 'bpmtasks',
  template: function(obj) {
    const templateF = Handlebars.compile(handlebarsTemplate.functionsBETemplate);
    const compiledHtml = templateF(obj);

    return compiledHtml;
  },
  width: 193,
  height: 185,
  margin: 5,
  padding: 0,
  x_count: 3,
  y_count: 6,
  drag_marker: 'dhxdataview_skyblue/dnd_selector_cells.png',
  // custom properties
  icons_src_dir: './',
  image: function() {
    return '/dhtmlx/imgs/akioma/parallel_tasks-26.png';
  },
  setBack: function(obj) {
    if (obj.priority == 'high')
      return 'iptaskinfolinefieldp1';
    if (obj.priority == 'medium')
      return 'iptaskinfolinefieldp2';
    if (obj.priority == 'low')
      return 'iptaskinfolinefieldp3';
    else
      return 'iptaskinfolinefield';

  },

  text: function(obj) {
    return `${obj.taskstatus.split('_')[1]}<br> ${obj.activityname}`;
  },
  size: function(obj) {
    return obj.filesize ? (`${obj.filesize}b`) : '0';
  }
};

dhtmlXDataView.prototype.types.generictemplate = {
  css: 'genericTemplate',
  template: function(obj, type, dataview) {
    let compiledHtml;
    if (dataview.akElm) {
      const aKeys = dataview.akElm.opt.template.split('|');
      const aGenericKeys = [ aKeys[1], aKeys[2] ];
      const templateF = Handlebars.compile(akioma.handlebarsTemplates.GenericTemplate(aGenericKeys));
      compiledHtml = templateF(obj);
    }
    return compiledHtml;
  },
  width: 193,
  height: 40,
  margin: 10,
  padding: 0,
  x_count: 3,
  y_count: 6,
  drag_marker: 'dhxdataview_skyblue/dnd_selector_cells.png',
  // custom properties
  icons_src_dir: './'
};

dhtmlXDataView.prototype.types.ficon = {
  css: 'ficon',
  template: dhtmlx.Template
    .fromHTML('<div align=\'center\'><img onmousedown=\'return false;\' border=\'0\' src=\'{common.image()}\'><div class=\'dhx_item_text\'>{common.text()}</div></div>'),
  template_edit: dhtmlx.Template
    .fromHTML('<div align=\'center\'><img onmousedown=\'return false;\' border=\'0\' src=\'{common.image()}\'><input class=\'dhx_item_editor\' bind=\'obj.name\'></div>'),
  template_loading: dhtmlx.Template.fromHTML(''),
  width: 75,
  height: 75,
  margin: 1,
  padding: 0,
  drag_marker: 'dhxdataview_skyblue/dnd_selector_cells.png',
  // custom properties
  icons_src_dir: '/imgs/filetypes',
  image: function(obj) {
    let cName = '';
    if (obj.name)
      cName = obj.name;
    else if (obj.originalfilename)
      cName = obj.originalfilename;
    return `${this.icons_src_dir}/ico_${cName.split('.')[1] || 'fldr'
    }_32.gif`;
  },
  text: function(obj) {
    let cName = '';
    try {
      if (obj.name)
        cName = obj.name;
      else if (obj.displayname)
        cName = obj.displayname;

      return cName.split('.')[0];
    } catch (err) {
      akioma.log.error('error', err);
    }

  }
};

dhtmlXDataView.prototype.types.ftiles = {
  css: 'ftiles',
  template: dhtmlx.Template
    .fromHTML('<div style=\'float: left; width: 200px; height: 58px; border: 1px solid #ccc;\' class=\'dhx_item_text grauverlauf\'><img onmousedown=\'return false;\' style=\'width:48px; float: left; margin: 5px; border=\'0\' src=\'{common.image()}\'>{common.text()}</div><div style=\'float: left; position: relative; left: 5px; top: -5px; width: 60px; height: 58px; border: 1px solid #ddd; background-color: #eee; margin-top: 5px;\' class=\'dhx_item_text_gray\'>{common.size()}</div>'),
  template_edit: dhtmlx.Template
    .fromHTML('<img onmousedown=\'return false;\' style=\'width:48px; float: left;\' border=\'0\' src=\'{common.image()}\'><textarea class=\'dhx_item_editor\' bind=\'obj.name\'></textarea></div>'),
  template_loading: dhtmlx.Template.fromHTML(''),
  width: 300,
  height: 70,
  margin: 10,
  padding: 0,
  drag_marker: 'dhxdataview_skyblue/dnd_selector_cells.png',
  // custom properties
  icons_src_dir: '/imgs/filetypes',
  image: function(obj) {
    let cName = '';
    if (obj.nameondisk)
      cName = obj.nameondisk;
    else if (obj.filename)
      cName = obj.filename;
    else if (obj.name)
      cName = obj.name;
    else if (obj.originalfilename)
      cName = obj.originalfilename;
    return `${this.icons_src_dir}/ico_${cName.split('.')[1] || 'fldr'
    }_48.gif`;
  },
  text: function(obj) {
    let cName = '';
    try {
      if (obj.name)
        cName = obj.name;
      else if (obj.displayname)
        cName = obj.displayname;
      else if (obj.filename)
        cName = obj.filename;

      return cName.split('.')[0];
    } catch (err) {
      akioma.log.error('error', err);
    }

  },
  size: function(obj) {
    return obj.filesize ? (`${obj.filesize}b`) : '';
  }
};

dhtmlXDataView.prototype.types.ftable = {
  css: 'ftable',
  template: dhtmlx.Template
    .fromHTML('<div style=\'float: left; width: 30px; height: 16px;\'><img onmousedown=\'return false;\' border=\'0\' src=\'{common.image()}\' width=\'17\'></div><div style=\'float: left; width: 215px; overflow:hidden;\' class=\'dhx_item_text\'><span style=\'padding-left: 2px; padding-right: 2px;\'>{common.text()}</span></div><div style=\'float: left; width: 60px; text-align: right;\' class=\'dhx_item_text\'>{common.size()}</div><div style=\'float: left; width: 130px; padding-left: 10px;\' class=\'dhx_item_text\'>{common.date()}</div>'),
  template_edit: dhtmlx.Template
    .fromHTML('<div style=\'float: left; width: 30px; height: 16px;\'><img onmousedown=\'return false;\' border=\'0\' src=\'{common.image()}\' width=\'17\'></div><div style=\'float: left; width: 215px;\' class=\'dhx_item_text\'><span style=\'padding-left: 2px; padding-right: 2px;\'><input type=\'text\' style=\'width:100%; height:100%;\' bind=\'obj.name\'></span></div><div style=\'float: left; width: 60px; text-align: right;\' class=\'dhx_item_text\'>{common.size()}</div><div style=\'float: left; width: 130px; padding-left: 10px;\' class=\'dhx_item_text\'>{common.date()}</div>'),
  template_loading: dhtmlx.Template.fromHTML(''),
  width: 470,
  height: 26,
  margin: 1,
  padding: 0,
  drag_marker: 'dhxdataview_skyblue/dnd_selector_lines.png',
  // custom properties
  icons_src_dir: '/imgs/filetypes',
  image: function(obj) {
    let cName = '';
    if (obj.name)
      cName = obj.name;
    else if (obj.originalfilename)
      cName = obj.originalfilename;
    return `${this.icons_src_dir}/ico_${cName.split('.')[1] || 'fldr'
    }_32.gif`;
  },
  text: function(obj) {
    let cName = '';
    try {
      if (obj.name)
        cName = obj.name;
      else if (obj.displayname)
        cName = obj.displayname;

      return cName.split('.')[0];
    } catch (err) {
      akioma.log.error('error', err);
    }

  },
  size: function(obj) {
    return obj.filesize ? (`${obj.filesize}b`) : '';
  },
  date: function(obj) {
    return obj.modifdate;
  }
};

dhtmlXDataView.prototype.types.fthumbs = {
  css: 'fthumbs',
  template: dhtmlx.Template
    .fromHTML('<div align=\'center\'><img border=\'0\' src=\'{common.image()}\'><div class=\'dhx_item_text\'><span>{common.text()}</span></div></div>'),
  width: 110,
  height: 116,
  margin: 15,
  padding: 2,
  // custom properties
  thumbs_creator_url: './',
  photos_rel_dir: './',
  image: function(obj) {
    let cName = '';
    if (obj.name)
      cName = obj.name;
    return `${this.thumbs_creator_url}?img=${this.photos_rel_dir}/${
      cName}&width=94&height=94`;
  },
  text: function(obj) {
    let cName = '';
    if (obj.name)
      cName = obj.name;

    return cName.split('.')[0];
  }
};

dhtmlXDataView.prototype.types.largeicons = {
  css: 'largeicons',
  template: dhtmlx.Template
    .fromHTML('<div class=\'img-container\'><img src=\'{common.image()}\'></div><p>{common.text()}</p>'),

  width: 140,
  height: 150,
  margin: 8,
  padding: 2,

  // custom properties
  icons_src_dir: '/imgs/filetypes/win10/',
  image: function(obj) {
    const url = obj.clientfilename || '';
    const filename = obj.cfilename || (url.lastIndexOf('/') > -1 ? url.substr(url.lastIndexOf('/') + 1) : '');
    let ext = obj.ctype || (filename.lastIndexOf('.') > -1 ? filename.substr(filename.lastIndexOf('.') + 1) : '');

    ext = ext.toLowerCase();

    if (ext === 'png'
 || ext === 'jpg'
 || ext === 'jpeg'
     || ext === 'gif'
     || ext === 'bmp'
     || ext === 'tif'
     || ext === 'tiff')
      return url;
    else
      return `${this.icons_src_dir}/${ext || 'img'}.png`;
  },
  text: function(obj) {
    const url = obj.clientfilename || '';
    const filename = obj.cfilename || (url.lastIndexOf('/') > -1 ? url.substr(url.lastIndexOf('/') + 1) : '');

    return filename;
  }
};
