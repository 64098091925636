// array of objects
// object.config  - chart config file
// object.series - array of series configs
akioma.chartTypes.costsStacked = {
  config: {
    view: 'stackedBar',
    value: '#grpsumamount#',
    label: '#grpsumparentdesc#',
    color: '#58dccd',
    gradient: 'falling',
    width: 60,
    tooltip: { template: '#grpsumparentdesc#' },
    xAxis: { template: '\'#grpsumparentdesc#' },
    yAxis: {},
    legend: {
      values: [ { text: 'Type A', color: '#36abee' }, { text: 'Type B', color: '#a7ee70' }, { text: 'Type C', color: '#58dccd' } ],
      valign: 'middle',
      align: 'right',
      width: 90,
      layout: 'y'
    }
  },
  series: [
    {
      value: '#groupsumamountfc#',
      color: '#0000ff'
    }
  ]
};

akioma.chartTypes.costsArea = {
  config: {
    view: 'stackedArea',
    value: '#grpsumamount#',
    color: '#00ccff',
    alpha: 0.6,
    padding: { left: 30 },
    yAxis: {},
    xAxis: {
      lines: true,
      title: 'Revenue by Type',
      template: '#grpsumparentdesc#'
    },
    legend: {
      layout: 'x',
      width: 75,
      align: 'center',
      valign: 'top',
      marker: {
        type: 'round',
        width: 15
      },
      values: [
        { text: 'Pricing Model A', color: '#00ccff' },
        /* {text:"Pricing Model B",color:"#0000ff"},*/
        { text: 'Pricing Model C', color: '#cc33ff' }
      ]
    }
  },
  series: []
};


// used in mainDashboard.json
akioma.chartTypes.configDashboard = {
  config: {
    view: 'line',
    value: '#totalamountopen#',
    item: {
      borderColor: '#447900',
      color: '#69ba00'
    },
    line: {
      color: '#1293f8',
      width: 3
    },
    tooltip: { template: '#totalamountopen#' },
    xAxis: {
      template: '',
      step: 20000
    },
    offset: 0,
    yAxis: {
      start: 0,
      step: 30000,
      end: 150000
    },
    origin: 0,
    legend: {
      layout: 'x',
      width: 75,
      align: 'center',
      valign: 'bottom',
      values: [
        { text: 'Total Amount Open', color: '#4aa397' },
        { text: 'Total Amount ', color: '#66cc00' },
        { text: 'Total Amount Likely', color: '#B22222' }
      ],
      margin: 10
    }
  },
  series: [
    {
      value: '#totalamount#',
      item: {
        borderColor: '#66cc00',
        color: '#66cc00'
      },
      line: {
        color: '#66cc00',
        width: 3
      },
      tooltip: { template: '#totalamount#' }
    },
    {
      value: '#totalamountlikely#',
      item: {
        borderColor: '#B22222',
        color: '#B22222'
      },
      line: {
        color: '#B22222',
        width: 3
      },
      tooltip: { template: '#totalamountlikely#' }
    }
  ]
};
