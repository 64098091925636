/**
 * Method for fetching and assigning the linked external document of a
 * text module to the corresponding field in the maintenance screen
 * @param {Object} self
 */
akioma.text.textDataAvailable = function(self) {
  const textDocumentInput = self.container.getObject('textDocument').controller;
  const configBE = {
    entityName: 'Akioma.Crm.MasterData.General.ExternalDocumentEntity',
    entityTable: 'eExternalDocument'
  };

  akioma.createBusinessEntity(textDocumentInput, configBE);
  const extDocBE = textDocumentInput.businessEntity;

  extDocBE.addAfterFillOnceCallback (rows => {
    const path = rows[0]['extfilename'].split('\\');
    textDocumentInput.setValue(path[path.length - 1]);
  });

  extDocBE.addAfterCatalogAdd(() => {
    const recHdl = self.getValue('selfhdl');
    extDocBE.query.addUniqueCondition('refhdl', 'eq', recHdl);
    extDocBE.openQuery();
  });
};

/**
 * Method event when user select an external document to assign to text module
 * @param {Object} self
 */
akioma.text.afterChooseDocument = function(self) {
  const container = self.container;

  // filename selected
  const dFileList = container.getObject('dFileList');
  const filename = dFileList.getValue('clientfilename');
  if (!filename) {
    akioma.NotificationMessage.showError({ text: 'Datei nicht ausgewählt' });
    return;
  }

  const oRecHdl = container.caller.screen.getLink('PRIMARYSDO:TARGET').getValue('selfhdl');

  akioma.invokeServerTask({
    name: 'Akioma.Crm.MasterData.General.ExternalDocumentsBT',
    methodName: 'LinkExternalDocument',
    paramObj: {
      plcParameter: {
        RecHdl: oRecHdl,
        DocumentName: filename,
        Unique: true
      }
    }
  }).then(() => {
    const oDataSource = container.caller.screen.getLink('PRIMARYSDO:TARGET').controller;
    akioma.text.textDataAvailable(oDataSource.dynObject);
    container.controller.close();
  });
};

/**
 * Delete text module relation dependency
 * @return {Promise}
 */
akioma.text.deleteModuleRelation = function(oDso) {
  const targetDso = oDso.getLink('DATA:TARGET').controller;

  const deletePromise = targetDso.deleteRecord({ closeWindow: false }).then(() => {
    akioma.notification({
      type: 'success',
      text: akioma.tran('txtmod.deleteRelation', { defaultValue: 'Text Module Dependency has been removed.' })
    });
  });

  return deletePromise;
};
