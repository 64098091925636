akioma.sysAdmin_init = function(oSelf) {
  oSelf.setValue('gltrace', app.trace);
  if (app.sessionData.objectNamesInTitles)
    oSelf.setValue('globjectnamesintitle', true);
  else
    oSelf.setValue('globjectnamesintitle', false);
};

akioma.sysAdmin_leave = function(oSelf) {
  if (oSelf.name == 'gltrace')
    app.trace = oSelf.getValue();
  if (oSelf.name == 'globjectnamesintitle')
    app.sessionData.objectNamesInTitles = (oSelf.getValue() == 'yes');
};
