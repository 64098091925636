/**
 * Function used to load text types.
 * @param  {object} oSelf The toolbar dynobject
 */
// global context function
// eslint-disable-next-line no-unused-vars
function getImageTypes(oSelf) {

  const oForm = oSelf.dynObject.getLink('TABLEIO:TARGET').controller,
    cTypeRange = oForm.opt.typeRange;

  const cResourceName = 'Akioma.Swat.System.TypeEntity';
  const deferred = $.Deferred();
  oSelf.parent.textImgTypesPromise = deferred.promise();

  const oBEoptions =
          {
            'att': {
              cacheLimit: 50,
              catalogURI: '',
              dataSource: '',
              entityName: 'eType',
              id: 'offerw45613645_businessEntity',
              identifier: 'SelfHdl',
              name: 'businessEntity',
              rowsToBatch: 1000,
              resourceName: cResourceName,
              serviceURI: ''
            }
          };

  const businessEntity = new $['ak_businessEntity'](oBEoptions);

  businessEntity.addAfterFillOnceCallback(res => {
    try {
      const aOpt = [];
      res.each(item => {
        aOpt.push({
          hdl: item.selfhdl,
          key: item.selfkey,
          value: item.selfdesc
        });
      });
      if (aOpt.length > 0) {

        oSelf.setComboOptions(aOpt);
        dhtmlx.delay(() => {
          if (!(oSelf.parent.textImgTypesPromise && oSelf.parent.textImgTypesPromise.state() == 'resolved')) {
            oSelf.parent.textImgTypesPromise.done(() => {
              oSelf.click(aOpt[0].hdl);
            });
          }

          deferred.resolve();
        });
      }
    } catch (e) {
      deferred.reject();
      akioma.notification({ type: 'error', text: 'Error loading combo options.', expire: 500 });
    }
  });

  businessEntity.finishConstruct();
  businessEntity.endConstruct();

  businessEntity.query.addCondition('selfkey', 'begins', cTypeRange);
  businessEntity.query.addCondition('typesort', 'gt', 0);

  businessEntity.openQuery({});
}

// get text types
/**
 * Function used to load text types.
 * @param  {object} oSelf The toolbar dynobject
 */
// global context function
// eslint-disable-next-line no-unused-vars
function getAkImgDirs(oSelf) {
  const cResourceName = 'Akioma.Crm.MasterData.System.ParameterEntity';
  const deferred = $.Deferred();
  oSelf.parent.filesImgDirsPromise = deferred.promise();

  const oBEoptions =
          {
            'att': {
              cacheLimit: 50,
              catalogURI: '',
              dataSource: '',
              entityName: 'eParameter',
              id: 'offerw45613645_businessEntity',
              identifier: 'SelfHdl',
              name: 'businessEntity',
              rowsToBatch: 1000,
              resourceName: cResourceName,
              serviceURI: ''
            }
          };

  const businessEntity = new $['ak_businessEntity'](oBEoptions);

  businessEntity.addAfterFillOnceCallback(res => {
    try {
      const aOpt = [];
      res.each(item => {
        aOpt.push({
          hdl: item.selfhdl,
          key: item.selfkey,
          value: item.selfdesc
        });
      });
      if (aOpt.length > 0) {

        oSelf.setComboOptions(aOpt);
        dhtmlx.delay(() => {
          oSelf.click(aOpt[0].hdl);
          deferred.resolve();
        });
      }
    } catch (e) {
      deferred.reject();
      akioma.notification({ type: 'error', text: 'Error loading combo options.', expire: 500 });
    }
  });

  businessEntity.finishConstruct();
  businessEntity.endConstruct();

  businessEntity.query.addCondition('grouphdl', 'eq', 'UserImg');
  businessEntity.query.setStringSorting('sort');

  businessEntity.openQuery({});
}

function getAkiomaImageRequest(oSelf) {
  const oTool = oSelf.dynObject,
    cType = oTool.getValue('imageType');
  // check of we have to load a text
  if (cType) {

    const oForm = oSelf.dynObject.getLink('TABLEIO:TARGET').controller;
    if (oForm && oForm.dataSource && oForm.dataSource.getIndex()) {

      let cKey;
      if (oForm.opt.foreignFields)
        cKey = oForm.opt.foreignFields.split(',')[1];
      else
        cKey = 'selfhdl';

      // check if changed
      const cActIndex = oForm.dataSource.getFieldValue(cKey);

      oTool.actIndex = cActIndex;
      oTool.actType = cType;

      $.ajax({
        type: 'POST',
        url: '/akioma/getdata.xml',
        dataType: 'json',
        data: `action=getImage&refHdl=${cActIndex}&Type=${cType}`,
        success: function(data) {
          oForm.dhx.setItemValue('image', data.image);
          $.each(oForm.childs, index => {
            if (oForm.childs[index].view == 'image')
              oForm.childs[index]._resizeFit();
          });
        },
        error: function(xhr, textStatus, errorThrown) {
          akioma.notification({ type: 'error', text: `Error getting data from 'getimage': ${textStatus} -> ${errorThrown}` });
        }
      });
    }
  }
}

// get Image
// global context function
// eslint-disable-next-line no-unused-vars
function getAkImage(oSelf) {
  // if promise available wait for promise of image types first and then get image
  if (oSelf.toolbarMenuStructPromise) {
    oSelf.toolbarMenuStructPromise.then(() => {
      getAkiomaImageRequest(oSelf);
    });
  } else
    getAkiomaImageRequest(oSelf);


}

// save Image
// global context function
// eslint-disable-next-line no-unused-vars
function saveAkImageOld(oSelf) {
  // check of we can save text
  const oTool = oSelf.dynObject,
    cType = oTool.getValue('imageType'),
    oForm = oTool.getLink('TABLEIO:TARGET').controller;

  if (cType) {
    let cKey;
    if (oForm.opt.foreignFields)
      cKey = oForm.opt.foreignFields.split(',')[1];
    else
      cKey = 'selfhdl';

    const cHdl = oForm.dataSource.getFieldValue(cKey);

    // get image
    const cImage = oForm.dhx.getItemValue('image');

    const oPar = {
      RunFile: 'std/img/saveImage.p',
      ParData: 'CHAR,CHAR,CHAR,CHAR,CHAR,CHAR',
      ParType: 'i,i,i,i,i,o',
      Par1: cHdl,
      Par3: cType,
      Par4: cImage,
      Par5: '',
      Par6: 'result'
    };
    // save image
    $.ajax({
      async: false,
      url: '/akioma/runRoutine.r',
      type: 'POST',
      dataType: 'json',
      data: oPar,
      success: function() {},
      error: function(xhr, textStatus, errorThrown) {
        akioma.log.error(`Error saving text: ${textStatus} -> ${errorThrown}`);
      }
    });

  } else
    akioma.notification({ type: 'warn', text: 'Zum Speichern muss Imagetyp gewählt sein!' });

  saveAkImage(oSelf.dynObject);
  return;
}

function saveAkImage(self) {
  const oForm = self.getLink('TABLEIO:TARGET').controller;
  const cHdl = oForm.foreignFieldValue();
  const cType = self.getValue('imageType');
  let cFile = '';
  cFile = self.controller.prop.fullPath;
  if (app.sessionData.imageSavePrefix)
    cFile = app.sessionData.imageSavePrefix + cFile;

  if (cFile == '') {
    akioma.log.error('Kein Dateiname angegeben!');
    return;
  }

  app.controller.callServerMethod('std/img/saveImage.p', [
    { type: 'iCHAR', value: cHdl },
    { type: 'iCHAR', value: cType },
    { type: 'iCHAR', value: cFile },
    { type: 'iCHAR', value: '' },
    { type: 'iCHAR', value: '' },
    { type: 'oCHAR', name: 'cStatus' }
  ]);

  getAkImage(self.controller);

  return;
}

// global context function
// eslint-disable-next-line no-unused-vars
function clearAkImage(self) {
  const oForm = self.getLink('TABLEIO:TARGET').controller;
  const cHdl = oForm.foreignFieldValue();
  const cType = self.getValue('imageType');
  const cFile = '';

  const oImage = oForm.childs[0];
  if (oImage && oImage.opt.disabled) {
    akioma.showServerMessage('Bild ist Schreibgeschützt! Sie haben nicht die notwendigen Zugriffsrechte.');
    return;
  }

  app.controller.callServerMethod('std/img/saveImage.p', [
    { type: 'iCHAR', value: cHdl },
    { type: 'iCHAR', value: cType },
    { type: 'iCHAR', value: '' },
    { type: 'iCHAR', value: '' },
    { type: 'iCHAR', value: '' },
    { type: 'oCHAR', name: 'cStatus' }
  ]);

  akioma.messaging.info(`cleared image:</br>${cFile}</br>` + 'OK');

  getAkImage(self.controller);

  return;
}

/**
 * Toolbar initialization in the chooseFileG window
 * @param self the event source dynObject
 */
akioma.onCreateDirImg = function(self) {
  const dirImg = self.controller;
  const chooseFileG = self.parent.parent.controller; // use parents instead of container for desktop windows

  const customData = chooseFileG.customData || (chooseFileG.customData = {});
  const groupHdl = customData.groupHdl || (customData.groupHdl = 'UserImg');

  // the filter is only used in the file upload. the file type and root directory are enforced on the backend
  akioma.queryBusinessEntity({
    name: 'Akioma.Crm.MasterData.System.ParameterEntity',
    filter: [
      { field: 'groupHdl', operator: 'eq', value: 'FileType' },
      { field: 'SelfKey', operator: 'eq', value: groupHdl }
    ]
  }).done(data => {
    if (!data || data.length === 0)
      return;

    customData.filter = data[0].ParamValue.replace(/\*/g, '');
  }).fail(err => {
    akioma.notification({ type: 'error', text: err.message });
  });

  akioma.queryBusinessEntity({
    name: 'Akioma.Crm.MasterData.System.ParameterEntity',
    filter: {
      filter: { orderBy: 'Level' },
      akQuery: {
        filters: {
          logic: 'and',
          filters: [{ field: 'groupHdl', operator: 'eq', value: groupHdl }]
        }
      }
    }
  }).done(data => {
    if (!data || data.length === 0)
      return;

    const opts = data.map(dir => ({
      hdl: dir.SelfHdl,
      key: dir.SelfKey,
      value: dir.SelfDesc
    }));

    dirImg.setComboOptions(opts);

    dhtmlx.delay(() => {
      dirImg.click(opts[0].hdl);
    });
  }).fail(err => {
    akioma.notification({ type: 'error', text: err.message });
  });
};
