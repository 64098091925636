

/**
* Helper functions for converting JSDO specifics (filters, parameters) to/from Adapter.
* @namespace {akioma}
*/
class DataAdapterHelper {
  /**
    * 'afterFill' event parameter conversion function.
    * @private
    * @param {Object} data Adapter data.
    * @param {Object} objAdapter Instance of adapter.
    * @param {boolean} success Was request successful?
    * @returns {*}
    */
  static afterFillParams(data, objAdapter, success) {
    const fakeJsdo = objAdapter;
    const fakeRequest = { response: data };
    if (!success) {
      fakeRequest.response.message = data.message;
      fakeRequest.xhr = data.xhr || { status: 500 };
      fakeRequest.jsdo = { url: '' };
    }

    // AfterFill (jsdo, success, request)
    return [ fakeJsdo, success, fakeRequest ];
  }

  /**
    * Get a record object from adapter data to be used in after events.
    * @private
    * @param {Object} data adapter data.
    * @returns {Object}
    */
  static getAfterEventRecordParam(data) {
    return {
      data,
      getId() {
        return this.data._id;
      },
      rejectRowChanges() { }
    };
  }

  /**
    *
    * @param {Object} data adapter data.
    * @param {boolean} success Was request successful?
    */
  static getAfterEventRequestParam(data, success) {
    if (success)
      return { response: data };

    const errorMessage = data.message;

    return {
      jsrecord: {
        getErrorString() {
          return errorMessage;
        }
      },
      response: { _errors: [{ _errorMsg: errorMessage }] }
    };
  }

  /**
    * 'afterUpdate' event parameter conversion function.
    * @private
    * @param {Object} data adapter data.
    * @param {Object} objAdapter Instance of adapter.
    * @param {boolean} success Was request successful?
    * @returns {*}
    */
  static afterUpdateParams(data, objAdapter, success) {
    const fakeJsdo = objAdapter;
    const fakeRequest = akioma.DataAdapterHelper.getAfterEventRequestParam(data, success);
    const record = akioma.DataAdapterHelper.getAfterEventRecordParam(data);

    // AfterUpdate (jsdo , record , success , request )
    return [ fakeJsdo, record, success, fakeRequest ];
  }

  /**
    * 'afterCreate' event parameter conversion function.
    * @private
    * @param {Object} data Adapter data.
    * @param {Object} objAdapter Instance of adapter.
    * @param {boolean} success Was request successful?
    * @returns {*}
    */
  static afterCreateParams(data, objAdapter, success) {
    const fakeJsdo = objAdapter;
    const fakeRequest = akioma.DataAdapterHelper.getAfterEventRequestParam(data, success);
    const record = akioma.DataAdapterHelper.getAfterEventRecordParam(data);

    // AfterCreate (jsdo, record, success, request)
    return [ fakeJsdo, record, success, fakeRequest ];
  }

  /**
    * 'afterSave' event parameter conversion function.
    * @private
    * @param {Object} data adapter data.
    * @param {Object} objAdapter instance of adapter.
    * @param {boolean} success Was request successful?
    * @returns {*}
    */
  static afterSaveChangesParams(data, objAdapter, success) {
    const fakeJsdo = objAdapter;
    const fakeRequest = {
      batch: {
        operations: [
          {
            response: { _errors: [{ _errorMsg: data.message }] },
            jsrecord: { data }
          }
        ]
      }
    };

    // AfterSave (jsdo, success, request)
    return [ fakeJsdo, success, fakeRequest ];
  }

  /**
    * 'afterDelete' event parameter conversion function.
    * @private
    * @param {Object} data Adapter data.
    * @param {Object} objAdapter Instance of adapter.
    * @param {boolean} success Was request successful?
    * @returns {*}
    */
  static afterDeleteParams(data, objAdapter, success) {
    const fakeJsdo = objAdapter;
    const fakeRequest = akioma.DataAdapterHelper.getAfterEventRequestParam(data, success);
    const record = akioma.DataAdapterHelper.getAfterEventRecordParam(data);

    // AfterDelete (jsdo, record, success, request)
    return [ fakeJsdo, record, success, fakeRequest ];
  }

  /**
    * Get conversion function for Adapter data to JSDO event parameters
    * for a specific JSDO event.
    * @private
    * @param {string} eventName Event name for which to get conversion function.
    * @returns {Function}
    */
  static eventParamConversionFunction(eventName) {
    switch (eventName) {
      case 'afterFill':
        return akioma.DataAdapterHelper.afterFillParams;
      case 'afterCreate':
        return akioma.DataAdapterHelper.afterCreateParams;
      case 'afterUpdate':
        return akioma.DataAdapterHelper.afterUpdateParams;
      case 'afterSaveChanges':
        return akioma.DataAdapterHelper.afterSaveChangesParams;
      case 'afterDelete':
        return akioma.DataAdapterHelper.afterDeleteParams;
      default:
        throw new Error(`Unsupported event '${eventName}' for parameter conversion!`);
    }
  }

  /**
    * Get default value for the specified data type.
    *
    * @private
    * @param {string} type Type name.
    *
    * @returns {*}
    */
  static getDefaultValueForType(type) {
    switch ((type || '').toLowerCase()) {
      case 'character':
        return '';
      case 'integer':
        return 0;
      default:
        return null;
    }
  }

  /**
    * Get fields from business entity's target link.
    * @private
    * @param {Object} businessEntity Business entity from which to get target link.
    * @returns {Array<Object>}
    */
  static getTargetFields(businessEntity) {
    let dataTarget = businessEntity.dynObject.getLinks('DISPLAY:TARGET');

    if (!isNull(dataTarget))
      return akioma.DataAdapterHelper.getFieldsFromForm(dataTarget[0].controller);

    dataTarget = businessEntity.dynObject.getLinks('DATA:TARGET');

    if (!isNull(dataTarget))
      return akioma.DataAdapterHelper.getFieldsFromGrid(dataTarget[0].controller);

    return [];
  }

  /**
   * Converts Adapter data to JSDO event parameters.
   * @param {string} eventName Name of the event
   * @param {Object} data Adapter data.
   * @param {Object} objAdapter Instance of adapter.
   * @param {boolean} success Was request successful?
   * @returns {Object}
   */
  static convertEventParameter(eventName, data, objAdapter, success) {
    return akioma.DataAdapterHelper.eventParamConversionFunction(eventName)(data, objAdapter, success);
  }

  /**
   * Fixes sorting filter field name from lowercased to real and field name.
   * @param {Object} filters Filters to fix.
   * @param {Array<string>} realFieldNames Array containing real field names.
   */
  static fixSortFilter(filterObj, realFieldNames) {
    if (isNull(realFieldNames)) return;
    // fixes filter field name for sorting
    if (!isNull(filterObj) && !isNull(filterObj.filters) && !isNull(filterObj.filters.orderBy)) {
      const aOrderSplit = filterObj.filters.orderBy.split(' ');
      const indexRealOrderField = realFieldNames.findIndex(realName => realName.toLowerCase() === aOrderSplit[0]);
      aOrderSplit[0] = realFieldNames[indexRealOrderField];
      filterObj.filters.orderBy = aOrderSplit.join(' ');
    }
  }

  /**
  * Checks if the given akFilter is a filter array.
  * @private
  * @param {Object} akFilter AkFilter to check.
  * @returns {boolean}
  */
  static isFilterArray(akFilter) {
    return !isNull(akFilter.logic) || !isNull(akFilter.filters);
  }

  /**
   * Fixes filter field names from lowercased to real and field name.
   * @param {Object} filters Filters to fix.
   * @param {Array<string>} realFieldNames Array containing real field names.
   * @param {Array<string>} fieldTypes Array containing field types.
   */
  static fixFilterFields(filters, realFieldNames, fieldTypes) {
    if (isNull(realFieldNames)) return;

    const akFiltersArray = filters.filters || [];

    // akQuery filters
    akFiltersArray.forEach(filter => {
      if (akioma.DataAdapterHelper.isFilterArray(filter))
        this.fixFilterFields(filter, realFieldNames, fieldTypes);
      else {
        const fieldIndex = realFieldNames.findIndex(realName => realName.toLowerCase() === filter.field);
        if (fieldIndex === -1) return;

        filter.field = realFieldNames[fieldIndex];

        if (isNull(fieldTypes)) return;

        const type = fieldTypes[fieldIndex];
        if (type === 'integer') {
          try {
            filter.value = Number.parseInt(filter.value);
          } catch (e) {
            console.error(e);
          }
        }
      }
    });
  }

  /**
   * Obtains fields from grid. </br>
   * Returned fields are in the format: </br>
   * - fieldName
   * - fieldType
   * - defaultValue
   *
   * @param {Object} grid ak_grid controller.
   *
   * @returns {Array<Object>}
   */
  static getFieldsFromGrid(grid) {
    const fieldNames = grid.aAkId;
    const fieldTypes = grid.cols.dataTypes;

    const fields = [];
    let defaultValue;
    for (let i = 0; i < fieldNames.length; i++) {
      defaultValue = akioma.DataAdapterHelper.getDefaultValueForType(fieldTypes[i]);

      fields.push({
        fieldName: fieldNames[i],
        fieldType: fieldTypes[i],
        defaultValue
      });
    }

    return fields;
  }

  /**
   * Obtains fields from form. </br>
   * Returned fields are in the format: </br>
   * - fieldName
   * - fieldType
   * - defaultValue
   *
   *
   * @param {Object} form ak_form controller.
   *
   * @returns {Array<Object>}
   */
  static getFieldsFromForm(form) {
    return form.childs.map(formInput => {
      const defaultValue = akioma.DataAdapterHelper.getDefaultValueForType(formInput.opt.dataType);

      return {
        fieldName: formInput.opt.InstanceName,
        fieldType: formInput.opt.dataType,
        defaultValue
      };
    });
  }

  /**
   * Gets the record with cased fieldnames.
   *
   * @param {Object} record Record for which to get correct casing fieldnames.
   * @param {Object} businessEntity BusinessEntity which corresponds for the record's table.
   *
   * @returns {Object}
   */
  static getRecordWithCasings(record, businessEntity) {
    const casedRecord = {};

    const fields = akioma.DataAdapterHelper.getTargetFields(businessEntity);

    fields.forEach(field => {
      casedRecord[field.fieldName] = record[field.fieldName.toLowerCase()] || field.defaultValue;
    });

    return casedRecord;
  }
}

akioma.DataAdapterHelper = DataAdapterHelper;
