/**
 * UUID v4 generator in JavaScript (RFC4122 compliant)
 */
// global context function
// eslint-disable-next-line no-unused-vars
function uuid() {
  let uuid = '',
    i, random;
  for (i = 0; i < 32; i++) {
    random = Math.random() * 16 | 0;

    if (i == 8 || i == 12 || i == 16 || i == 20)
      uuid += '-';

    uuid += (i == 12 ? 4 : (i == 16 ? (random & 3 | 8) : random)).toString(16);
  }
  return uuid;
}

akioma.lookup = function(cValue, cList, cDeli, cDeli2) {
  // check delimiter
  if (!cDeli)
    cDeli = ',';

  // create array
  const aList = cList.split(cDeli);

  // get position
  if (cDeli2) {
    for (const i in aList) {
      if (aList[i].split(cDeli2)[0] == cValue)
        return i + 1;
    }
  } else
    return aList.indexOf(cValue) + 1;
};

/**
 * Encodes an HTML string
 * @param  {String} value HTML string
 * @return {string} Encoded string
 * @instance
 * @memberOf akioma
 */
akioma.encodeHTML = function(value) {
  let oDiv = $('<div>');
  const cEncoded = oDiv.text(value).html();
  oDiv = null;
  return cEncoded;
};

/**
 * Decodes a string into an HTML
 * @param  {String} value String to be decoded
 * @return {string} Decoded string
 * @instance
 * @memberOf akioma
 */
akioma.decodeHTML = function(value) {
  let oTextarea = document.createElement('textarea');
  oTextarea.innerHTML = value;
  const cDecoded = oTextarea.value;
  oTextarea = null;
  return cDecoded;
};

// TODO - improve canDO to support more patterns. Implement unit tests - SWAT-3599
// CANDO progress pattern check
akioma.canDo = function(list, val) {
  let bReturn = null;
  const aExp = list.split(',');

  aExp.forEach(cExp => {
    if (bReturn == null) { // process only if values isn't matched yet
      // handle patern negation
      const bNegate = cExp[0] == '!';
      const cPattern = bNegate ? cExp.substr(1) : cExp;

      let bPatternResult = null;
      if (cPattern == val || cPattern == '*') // if pattern matches value or allows all values
        bPatternResult = true;
      else if (cPattern.indexOf('*') > 0) {
        // if pattern contains the special '*' none or many character, check if it matches value
        const cRegExp = cPattern.replace('*', '.*?');
        const oRegExp = new RegExp(cRegExp);
        bPatternResult = oRegExp.test(val);
      }
      // only process result if pattern was matched
      if (bPatternResult == true)
        bReturn = bNegate ? !bPatternResult : bPatternResult;
    }
  });

  if (bReturn == null)
    bReturn = false;
  return bReturn;
};

/**
 * Helper method for detecting Javascript errors
 * @param {object} obj
 */
akioma.isObjOfTypeError = function(obj) {
  const jsErrorTypes = [
    'Error',
    'EvalError',
    'RangeError',
    'ReferenceError',
    'SyntaxError',
    'TypeError',
    'URIError'
  ];

  if (jsErrorTypes.indexOf(obj.name) > -1)
    return true;

  return false;
};

akioma.refreshCode = function(cScript) {
  return $.getScript(`/js/${cScript}.js`);
};

akioma.stringMatches = function(str, search) {
  if (typeof search !== 'string' || this === null)
    return false;

  // Remove special chars
  search = search.replace(new RegExp('([\\.\\\\\\+\\*\\?\\[\\^\\]\\$\\(\\)\\{\\}\\=\\!\\<\\>\\|\\:\\-])', 'g'), '\\$1');
  // Replace % and _ with equivalent regex
  search = search.replace(/%/g, '.*').replace(/_/g, '.');
  // Check matches
  return RegExp(`^${search}$`, 'gi').test(str);
};

akioma.stringReplace = function(str, find, replace) {
  find = find.replace(/([.*+?^=!:${}()|[\]/\\])/g, '\\$1');
  return str.replace(new RegExp(find, 'g'), replace);
};

// global context function
// eslint-disable-next-line no-unused-vars
function getScript(url) {
  return $.ajax({
    url: url,
    dataType: 'text',
    data: '',
    type: 'GET',
    success: function(data) {
      try {
        $.globalEval(data);
      } catch (e) {
        akioma.message({
          type: 'alert-error',
          title: 'Loading script script',
          text: `${url}: ${e.message}`
        });
      }
    },
    error: function(xhr, textStatus, errorThrown) {
      akioma.message({
        type: 'alert-error',
        title: 'Error loading script',
        text: `${url}: ${textStatus} -> ${errorThrown}`
      });
    }
  });
}

roundToDecimal = function(eValue, iDecimals) {
  let factor;
  let eResult = 0;

  try {
    factor = Math.pow(10, iDecimals);
    eResult = (Math.round(eValue * factor) / factor);
  } catch (oErr) {
    akioma.log.error([ `Error rounding ${eValue} to ${iDecimals}`, eResult ]);
  }

  return eResult;
};

// eslint-disable-next-line no-unused-vars
function isValidHdl(cHdl) {
  if (!cHdl)
    return false;

  try {
    if (!(cHdl.length === 20) && !(cHdl.length === 36))
      return false;
    else if (cHdl.length === 20)
      return (/[A-Z0-9]{3}:[0-9]{3}:[0-9]{12}/.test(cHdl)) ? true : false;
    else if (cHdl.length === 36)
      return (/[a-zA-Z0-9]{3}:[a-zA-Z0-9]{32}/.test(cHdl)) ? true : false;
  } catch (err) {
    return false;
  }
}

/**
  * Executes callback asynchronously, returns promise which resolves when execution finishes.
  * @param {Function} callback Callback function to execute.
  * @returns {Promise}
  **/
akioma.executeAsync = function(callback) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        resolve(callback());
      } catch (err) {
        reject(err);
      }
    }, 0);
  });
};

/**
 * Method for returning browser type, 'firefox', 'chrome' or 'other'
 *
 * @return  {string}  'firefox', 'chrome' or 'other'
 */
akioma.getBrowser = function() {
  const cUserAgent = navigator.userAgent.toLowerCase();
  if (cUserAgent.indexOf('firefox') > -1)
    return 'firefox';
  else if (cUserAgent.indexOf('chrome') > -1)
    return 'chrome';
  else
    return 'other';
};

akioma.emptyStorage = function() {
  localStorage.clear();
  sessionStorage.clear();
};

// global context function
// eslint-disable-next-line no-unused-vars
function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

/**
 * Method used for handling HTTP Status Errors
 * @param   {XMLHttpRequest}  xhr   Request Xhr object
 * @param   {string}  cText  Error text message
 * @namespace akioma
 * @return  {void}
 */
akioma.handleHttpStatus = function(xhr, cText) {
  let status = String(xhr.status);
  if (status.startsWith('5'))
    status = '500'; // used only in switch, will display correct status in error message

  let fCallback,
    moretext = null;
  switch (status) {
    case '401': // Session expired
      cText = akioma.tran('httpStatus.401', { defaultValue: '401 - Session has expired' });
      fCallback = result => {
        if (result)
          UserProfile.webLogout();
      };
      break;
    case '403': // Forbidden (no access rights)
      cText = `${cText}<br/>${akioma.tran('httpStatus.403', { defaultValue: '<br/> 403 - Forbidden (no access rights)' })}`;
      break;
    case '500': // Internal server error
      cText = `${cText}<br/>${akioma.tran(`httpStatus.${xhr.status}`, { defaultValue: `<br/> ${xhr.status} - ${xhr.statusText}` })}`;
      if (xhr.responseJSON)
        moretext = JSON.stringify(xhr.responseJSON, null, 4);
      else if (xhr.responseText)
        moretext = xhr.responseText;

      break;
    default:
      break;
  }

  akioma.Themes.setFallbackTheme();

  akioma.message({ type: (xhr.status === '401' ? 'info' : 'error'), text: cText, callback: fCallback, moretext: moretext });

  // clear progress state, there was an error, unblock user pointers interactions
  akioma.WaitCursor.clearProgressStateGlobally();
};

// global context function
// eslint-disable-next-line no-unused-vars
function applyAkiomaCode(oElm, cCode) {
  if (oElm)
    oElm.callCode(cCode);
  else
    akioma.message({ type: 'alert', title: 'applyAkiomaCode', text: `No valid element for code execution of: <br />${cCode}` });
}

// get lookup value *************
// global context function
// eslint-disable-next-line no-unused-vars
function getLookupValue(oElm) {
  // activate window
  const oPar = {
    Table: oElm.tableName,
    Value: oElm.value,
    FieldHdl: oElm.extHdl,
    FieldKey: oElm.extKey
  };

  let oData;
  $.ajax({
    type: 'POST',
    async: false,
    url: '/akioma/getValue.xml',
    data: oPar,
    dataType: 'json',
    success: function(data) {
      oData = data;
    },
    error: function(xhr, textStatus, errorThrown) {
      akioma.log.error(`Error loading lookup value '${oElm.name}': ${errorThrown}`, 3);
    }
  });

  return oData;
}
