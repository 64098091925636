/**
 * Vue taskbar object class
 * @class TaskbarObject
 */
akioma.TaskbarObject = class {
  static events = { onSelect: [] };
  /**
   * Method for creating new vue instance
   * @returns {TaskbarFactory}
   */
  static createInstance() {
    return new akioma.VueInstancesFactory('taskbar', { store: akioma.VuexStore });
  }

  /**
   * Method for mounting the taskbar object in DOM
   * @param {string} domSelector Dom selector
   */
  static mount(domSelector) {
    akioma.VueTaskbar.mount(domSelector);
  }

  /**
   * Method for selecting a taskbar item
   * @param {string} id Id of taskbar item
   */
  static selectItem(id) {
    akioma.VuexStore.dispatch('taskbar/selectItem', id);
    const item = akioma.VuexStore.getters['taskbar/getItemById'](id);
    akioma.VueTaskbar.config.globalProperties.$events.$emit('taskbar:selectItem', item);
  }

  /**
   * Method for adding a new taskbar item
   * @param {object} itemData Taskbar item data
   */
  static addItem(itemData) {
    akioma.VuexStore.dispatch('taskbar/addItem', itemData);
  }

  /**
   * Method for removing the given taskbar item
   * @param {string} id Taskbar Item id
   */
  static removeItem(id) {
    akioma.VuexStore.dispatch('taskbar/removeItemById', id);
  }

  /**
   * Method for adding a new group item
   * @param {object} groupData Group item data
   */
  static addGroup(groupData) {
    akioma.VuexStore.commit('taskbar/ADD_GROUP', groupData);
  }

  /**
   * Method for updating given group with data
   * @param {object} groupData Group item data to update
   */
  static updateGroup(groupData) {
    akioma.VuexStore.dispatch('taskbar/updateGroup', groupData);
  }

  /**
   * Method for retrieving group item
   * @param {string} id Group by id
   * @returns {object}
   */
  static getGroupById(id) {
    return akioma.VuexStore.getters['taskbar/getGroupById'](id);
  }

  /**
   * Method for retrieving the active group id
   * @returns {string} Id of active group
   */
  static getActiveGroupId() {
    return akioma.VuexStore.getters['taskbar/getActiveGroup'];
  }

  /**
   * Method for enabling or disabling the Taskbar
   * @param {boolean} isEnabled Enabled state
   */
  static enable(isEnabled) {
    akioma.VuexStore.commit('taskbar/SET_ENABLED', isEnabled);
  }
};

/**
 * Listener on window custom state change
 * @param {object} options Desktop state
 * @param {object} options.self The window Object
 * @param {boolean} options.value The custom state value
 */
akioma.eventEmitter.on([ 'Window', 'CustomStateChanged' ], ({ self, value }) => {
  // Change to action
  if (akioma.dataViewTabs) {
    // contains dhtmlx dataview taskbar
    const item = akioma.dataViewTabs.get(self.opt.id);
    if (item) {
      item.cssCustomState = value;
      akioma.dataViewTabs.update(self.opt.id, item);
    }
  }
});

/**
 * Event Listener for adding new taskbar items when a new container has been launched
 * @param {ak_window|ak_frame} containerControl Window/Frame container launched
 */
akioma.eventEmitter.on([ 'LaunchContainer', 'AfterLoad' ], containerControl => {
  const primaryDataSource = containerControl.dynObject.getLink('PRIMARYSDO:TARGET');
  const isLoginScreen = app.sessionData.loginScreen === containerControl.opt.name;

  if (isNull(akioma.dataViewTabs) || containerControl.bMainWindow || containerControl.opt.modal || isLoginScreen)
    return;
  if (containerControl.view !== 'window')
    return;

  /**
   * Method for seting current window title in taskbar
   * @return  {void}
   */
  const setActiveTaskbarItem = () => {
    // need to use timeout after add because of delayed rendering in dhtmlx component.. _render_delayed
    setTimeout(() => {
      akioma.dataViewTabs.unselectAll();
      akioma.iActiveDataViewIndex = akioma.dataViewTabs.indexById(containerControl.opt.id);
      akioma.dataViewTabs.show(containerControl.opt.id);
      if (akioma.makeActiveOpenedWindow)
        akioma.dataViewTabs.select(containerControl.opt.id, true);
    }, 50);
  };

  if (primaryDataSource) {
    const primaryDataSourceControl = primaryDataSource.controller;
    // after fill add taskbar item and group
    primaryDataSourceControl.addAfterFillOnceCallback(() => {
      const cTitleTemplateCompiled = primaryDataSourceControl.getRecordDescription(containerControl.opt.TITLE);
      const cTitleShortTemplateCompiled = primaryDataSourceControl.getRecordDescription(containerControl.opt.titleShort);
      akioma.dataViewTabs.add({
        id: containerControl.opt.id,
        label: (cTitleShortTemplateCompiled || cTitleTemplateCompiled),
        Name: containerControl.opt.title,
        icon: containerControl.opt.icon,
        cssText: false,
        oBE: primaryDataSource
      });
      setActiveTaskbarItem();
    });
  } else {
    akioma.dataViewTabs.add({
      id: containerControl.opt.id,
      label: (containerControl.opt.titleShort || containerControl.opt.TITLE),
      Name: containerControl.opt.title,
      icon: containerControl.opt.icon,
      cssText: false
    });
    setActiveTaskbarItem();
  }
});


/**
 * Event listener called on an after save changes on a dataSource/businessEntity,
 * used for updating the group title after a dataSource saveChanges operation
 * @param {ak_businessEntity} dataSource
 */
akioma.eventEmitter.on([ 'DataSource', 'AfterSaveChanges' ], async dataSource => {
  let containerControl;
  let primaryDataSource;

  if (dataSource.container)
    containerControl = dataSource.container.controller;
  if (containerControl && containerControl.dynObject)
    primaryDataSource = containerControl.dynObject.getLink('PRIMARYSDO:TARGET');

  if (isNull(akioma.dataViewTabs) || containerControl?.view !== 'window' || !primaryDataSource)
    return;

  /**
   * Set taskbar title
   * @param {Object} BE
   * @param {string} cTemplate
   * @param {string} cShortTemplate
   * @returns {void}
   */
  const setTaskbarTitle = params => {
    const { BE, cShortTemplate } = params;

    if (BE && BE.controller) {
      const cTitleShortTemplateCompiled = BE.controller.getRecordDescription(cShortTemplate);
      const item = akioma.dataViewTabs.get(containerControl.opt.id);
      if (item) {
        // template changes to display correct akhasChanges and akHasErrors flag in dhtmlx_debug.js
        item.cssText = BE.container.controller.oVuexState.hasChanges;
        item.cssError = BE.container.controller.oVuexState.hasErrors;
        item.cssCustomState = BE.container.controller.oVuexState.customStates;
        item.label = cTitleShortTemplateCompiled;
        item.title = cTitleShortTemplateCompiled;
        akioma.dataViewTabs.update(containerControl.opt.id, item);
      }
    }
  };

  const cShortTemplate = containerControl.opt.titleShort || containerControl.opt.TITLE;
  const cTemplate = containerControl.getTitleTemplate();
  setTaskbarTitle({ BE: primaryDataSource, cTemplate, cShortTemplate });
});
