function eXcell_clist(cell) {

  try {
    this.cell = cell;
    this.grid = this.cell.parentNode.grid;
  } catch (er) {
    akioma.log.warn(er);
  }

  this.edit = function() {
    this.val = this.getValue();

    if (!this.grid.clists[this.cell._cellIndex])
      return;

    const aListVal = this.grid.clists[this.cell._cellIndex].value.split(','),
      aListText = this.grid.clists[this.cell._cellIndex].text.split(','),
      aVal = this.val.split(' ');

    this.obj = document.createElement('DIV');

    let text = '';
    for (const i in aListVal) {
      const j = $.inArray(aListVal[i], aVal);
      if (j > -1)
        text += `<div><input type='checkbox' checked='true' value='${aListVal[i]}'/><label>${aListText[i]}</label></div>`;
      else
        text += `<div><input type='checkbox' value='${aListVal[i]}'/><label>${aListText[i]}</label></div>`;
    }
    text += '<div><input type=\'button\' value=\'Ok\' style=\'width:100px; font-size:8pt;\' onclick=\'this.parentNode.parentNode.editor.grid.editStop();\'/></div>';

    this.obj.editor = this;
    this.obj.innerHTML = text;
    document.body.appendChild(this.obj);
    this.obj.style.position = 'absolute';
    this.obj.className = 'dhx_clist';

    this.obj.onclick = function(e) {
      (e || event).cancelBubble = true;
      return true;
    };

    const arPos = this.grid.getPosition(this.cell);

    this.obj.style.left = `${arPos[0]}px`;
    this.obj.style.top = `${arPos[1] + this.cell.offsetHeight}px`;

    this.obj.getValue = function() {
      const aText = [];
      $(this).find('input:checked').each(function() {
        aText.push($(this).val());
      });
      return aText.join(' ');
    };
  };

  this.getValue = function() {
    return (this.cell._clearCell) ? '' : this.cell.innerHTML.toString()._dhx_trim();
  };

  this.detach = function() {
    if (this.obj) {
      this.setValue(this.obj.getValue());
      this.obj.editor = null;
      this.obj.parentNode.removeChild(this.obj);
      this.obj = null;
    }
    return this.val != this.getValue();
  };
}
eXcell_clist.prototype = new eXcell;

eXcell_clist.prototype.setValue = function(val) {
  if (val === '' || val === this.undefined) {
    this.setCTxtValue(' ', val);
    this.cell._clearCell = true;
  } else {
    this.setCValue(val);
    this.cell._clearCell = false;
  }
};

dhtmlXGridObject.prototype.registerCList = function(col, list) {
  if (!this.clists)
    this.clists = [];
  this.clists[col] = list;
};

//* ************* header for multi select filter ***************
dhtmlXGridObject.prototype._in_header_multi_filter = function(tag, iindex) {
  const oSelf = this.akElm,
    oGrid = this,
    iWidth = parseInt(oSelf.cols.width[iindex]);

  tag.innerHTML = '<input type="text" style="width:90%; font-size:8pt; font-family:Tahoma; -moz-user-select:text; " /><img src="/imgs/akioma/zoom_in-26.png" class="lookupImg" />';
  tag.onclick = tag.onmousedown = function(e) {
    (e || event).cancelBubble = true; return true;
  };
  tag.onselectstart = function() {
    return (event.cancelBubble = true);
  };
  this.makeFilter(tag.firstChild, iindex);

  tag.firstChild._filter = function() {
    return tag.firstChild.value;
  };
  this._filters_ready();

  $(tag)
    .find('input')
    .css({ width: `${iWidth - 40}px` })
    .end()
    .find('img')
    .css({
      height: '14px',
      width: '14px',
      cursor: 'pointer'
    })
    .click(e => {
      if ((e || event).preventDefault)
        (e || event).preventDefault();
      (e || event).cancelBubble = true;

      const cActVal = tag.firstChild.value,
        cText = oGrid.clists[iindex].text.split(','),
        cValue = oGrid.clists[iindex].value.split(',');

      let cHTML = '<div class="dhx_clist" style="text-align: left;">';
      for (const i in cValue)
        cHTML += `<div><input type="checkbox" value="${cValue[i]}"${cActVal.indexOf(cValue[i]) > -1 ? 'checked="checked"' : ''} /><label>${cText[i]}</label></div>`;
      cHTML += '</div>';

      const oBox = dhtmlx.modalbox({
        title: 'Auswahl Kennzeichen',
        text: cHTML,
        buttons: ['Ok'],
        callback: function(result) {
          if (result == 0) {
            const aErg = [];
            $(oBox).find('input').each(function() {
              if (this.checked)
                aErg.push(this.value);
            });
            $(tag).find('input').val(aErg.join(' '));
          }
        }
      });
      return true;
    });
};
