/**
 * Class for handling Http Requests
 * @class HttpClient
 * @namespace akioma
 */
akioma.HttpClient = class {
  static init() {
    this.createLogger();
    this.callbacks = [];
    this.addAjaxGlobalErrorHandlers();
  }

  /**
   * Method for adding the global error handlers
   * @static
   * @memberof HttpClient
   * @returns {void}
   */
  static addAjaxGlobalErrorHandlers() {


    // for all the jquery ajax errors event
    $(document).ajaxError((event, jqxhr, settings, thrownError) => {

      let errorTxt = thrownError;

      if (jqxhr.responseJSON && jqxhr.responseJSON.message)
        errorTxt = jqxhr.responseJSON.message;


      const errorInfo = {
        errorText: errorTxt,
        url: settings.url,
        type: settings.type,
        statusCode: jqxhr.status,
        statusText: jqxhr.statusText,
        extras: [ event, jqxhr, settings, thrownError ]
      };


      // call any callbacks defined
      for (const c in this.callbacks)
        this.callbacks[c](jqxhr);


      // display console error with request information
      this.displayErrorMessage(errorInfo, jqxhr);

    });

    // for all the dhtmlx http errors event
    dhx4.attachEvent('onAjaxError', r => {
      const errorInfo = {
        errorText: r.xmlDoc.statusText,
        url: r.filePath,
        type: r.xmlDoc.type,
        statusCode: r.xmlDoc.status,
        statusText: r.xmlDoc.statusText,
        extras: [ r.async, r.filePath, r.xmlDoc ]
      };

      // display console error with request information
      this.displayErrorMessage(errorInfo, r.xmlDoc);
    });

  }

  /**
     * Displays modal error message for 4xx and/or 5xx errors
     * @static
     * @param {object} errorInfo Error information contains: errorText, url, type, statusCode, statusText
     * @param {XmlHttpRequest} xhr
     * @memberof HttpClient
     * @returns {void}
     */
  static displayErrorMessage(errorInfo, xhr) {

    // if statusCode matches statusText read the statusText from constant error messages
    if (errorInfo.errorText === String(errorInfo.statusCode) && errorInfo.errorText === errorInfo.statusText) {
      errorInfo.statusText = this.ERROR_MESSAGES[errorInfo.statusText];
      xhr.statusText = errorInfo.statusText;
      errorInfo.errorText = '';
    }

    // display console error with request information
    this.logHttpServerError(errorInfo);

    // ignore errors display in ui
    if (this.ErrorsIgnoreUrls.includes(errorInfo.url))
      return;

    // only 5xx errors display
    if (this.Errors500ListUrls.includes(errorInfo.url)) {
      if (String(xhr.status).startsWith('5') || xhr.status === 0)
        akioma.handleHttpStatus(xhr, errorInfo.errorText);
    } else if (typeof (errorInfo.errorText) === 'string') { // display 4xx and 5xx errors; 2xx error also possible in case of jsdo issues
      let cErrorText = errorInfo.errorText;

      if (errorInfo.extras) {
        for (const ct in errorInfo.extras) {
          if (errorInfo.extras[ct] && errorInfo.extras[ct].exception && errorInfo.extras[ct].exception.message)
            cErrorText = `${cErrorText}\n${errorInfo.extras[ct].exception.message}`;
        }

        cErrorText = cErrorText.trim();
      }

      akioma.handleHttpStatus(xhr, cErrorText);
    } else
      akioma.handleHttpStatus(xhr, errorInfo.errorText);
  }

  /**
     * Adds request to whitelist of only 5xx status code error display, does not display 4xx errors, for handling individually
     * @param {string} url Request URL to add to whitelist
     * @static
     * @memberof HttpClient
     */
  static display500ErrorsOnly(url) {
    if (this.Errors500ListUrls.indexOf(url) === -1)
      this.Errors500ListUrls.push(url);
  }

  /**
   * Adds request to ignore errors list, ignores all errors for given url
   * @param {string} url Request URL to add to whitelist
   * @static
   * @memberof HttpClient
   */
  static ignoreErrors(url) {
    if (this.ErrorsIgnoreUrls.indexOf(url) === -1)
      this.ErrorsIgnoreUrls.push(url);
  }

  /**
     * Method for adding Http Error callback
     * @static
     * @memberof HttpClient
     */
  static addHttpErrorCallback(callback) {
    this.callbacks.push(callback);
  }

  /**
     * Method for creating logger for WaitCursor class
     * @static
     * @memberof WaitCursor
     * @returns {void}
     */
  static createLogger() {
    this.logger = akioma.log.getLogger('HttpClient');
    this.logger.setLevel('warn');
  }

  /**
     * Displays HTTP error information
     * @param {object}  req
     * @param {string}  req.statusCode
     * @param {string}  req.statusText
     * @param {string}  req.errorText
     * @param {string}  req.url
     * @param {string}  req.type
     * @param {Array}   req.extras
     * @memberof HttpClient
     * @static
     */
  static logHttpServerError(req) {
    const statusCode = String(req.statusCode);
    if (!(statusCode.startsWith('5') && statusCode.length === 3))
      return;

    const messages = [];
    const basicInfo = `
            Triggered Http Error '${req.errorText}' on Request:\n 
            URL: '${req.url}'\n
            ${req.type ? `Type: '${req.type}'\n` : ''}
            Status Code: '${req.statusCode}' ${req.statusText ? `( ${req.statusText} )` : ''}\n
        `;
    messages.push(basicInfo);

    if (req.extras && req.extras.length > 0)
      messages.push('Extra request info:.\n', ...req.extras);


    this.logger.log.error(...messages);
  }
};

akioma.HttpClient.ERROR_MESSAGES = {
  '500': 'Internal Server Error',
  '501': 'Not Implemented',
  '502': 'Bad Gateway',
  '503': 'Service Unavailable',
  '504': 'Gateway Timeout',
  '505': 'HTTP Version Not Supported',
  '506': 'Variant Also Negotiates',
  '507': 'Insufficient Storage',
  '508': 'Loop Detected',
  '510': 'Not Extended',
  '511': 'Network Authentication Required'
};
akioma.HttpClient.Errors500ListUrls = [];
akioma.HttpClient.ErrorsIgnoreUrls = [];

// init and set global handlers for ajax requests
akioma.HttpClient.init();

