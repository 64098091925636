akioma.buildMainLayoutTabs = function(self) {
  const oTabbar = self.getObject('itFolder');
  if (oTabbar.dynamicDesktopsAdded)
    return;
  oTabbar.dynamicDesktopsAdded = true;
  oTabbar.controller.forceView = 'frame';

  const oReturn = akioma.callServerMethod('Akioma/Swat/UiInteraction/getDesktops.p', [{ type: 'oCHAR', name: 'cDesktops' }]);

  /* go through result and create a tab for each entry. note: the repository objects
  * might be windows, but they shall be rendered as frames
  * maybe this could be handled by an enhancemet to launchContainer, e.g. a new option "forceView".
  * Then the tabbar could always pass in "forceView": "frame"
  * because an object in a tabpage is always a frame. ´
  * the launchContainer would then need to change the "view" attribute of the root of the returned json accordingly.
  *
  * note: seems, that rendering the window as frame already works (at least for test call below)...?!?
  */
  const aItems = oReturn.cDesktops.split(',');

  const onFinishLoadingMenuItems = function(items, mainMenu, firstPromise) {
    mainMenu.getMenuStructure();

    let i = 0;
    for (const x in items) {
      const item = items[x];
      item.cRepoName = mainMenu.aMenuActions[item.id].CallObject;
      item.actionOption = mainMenu.aMenuActions[item.id].actionOptions;
      oTabbar.controller.addTabRepository(item.label, item.cRepoName, firstPromise && (i == 0), item.icon, null, { 'name': item.label, 'actionOption': item.actionOption });
      i++;
    }
  };

  // go over each menustructure and load it
  const aMenuStructures = [];
  // add menustructure load promises to array
  const aPromises = [];

  // create new menustructure objects
  aItems.forEach(structure => {
    const mainMenu = app.controller.parseProc({
      view: 'menustructure',
      att: { id: structure },
      sub: []
    }, self.controller);

    aMenuStructures.push(mainMenu);
    const oPromise = mainMenu.loadMenuElements();
    aPromises.push(oPromise);
  });

  // wait until all menus are loaded and then call finishLoading callback
  $.when.apply(null, aPromises).then(function() {
    for (const i in arguments)
      onFinishLoadingMenuItems(arguments[i], aMenuStructures[i], i == 0);
  });
};

/**
 * Method executed on initilize to retrieve the desktop menu structure items to be loaded as items in Sidebar
 * @param {ak_sidebar} Sidebar Sidebar control
 */
// eslint-disable-next-line no-unused-vars
akioma.buildMainLayoutTabsSidebar = function(Sidebar) {
  Sidebar.controller.forceView = 'frame';
  return new Promise(resolve => {
    const oReturn = akioma.callServerMethod('Akioma/Swat/UiInteraction/getDesktops.p', [{ type: 'oCHAR', name: 'cDesktops' }]);
    Sidebar.controller.setMenu(oReturn.cDesktops);
    resolve();
  });
};

/**
 * Method executed on selection of sidebar to switch to the corresponding panel view in the panel switcher
 * @param {ak_sidebar} Sidebar Sidebar control
 */
// eslint-disable-next-line no-unused-vars
akioma.onDesktopSidebarItemSelect = function(Sidebar) {
  const panelSwitcher = Sidebar.container.getFirstChildByType('panelSwitcher');
  const selectedItemAction = Sidebar.controller.event.selectedObj.Function;
  if (selectedItemAction)
    panelSwitcher.switchView(selectedItemAction.actionParameter);
  const options = { preventAction: true };
  return options;
};

// toggles desktop state **** DEPRECATED// old desktop code
// global context function
// eslint-disable-next-line no-unused-vars
function toggleDesktop(state) {
  const $dataViewTabsObj = $(akioma.dataViewTabs.$view);
  if (state) {
    akioma.stopWindowLoad = false; // disable// used for stoping window loading Profile Settings temporary
    akioma.toggleFlag = state; // used for setting previous active dataview tab
    const cevid = akioma.dataViewTabs.attachEvent('onBeforeSelect', () => false); // this blocks selection until windows are visible again

    // hide all windows that are not in desktop mode
    for (let i = 0; i < akioma.aNonDesktopWindows.length; i++)
      akioma.aNonDesktopWindows[i].dhx.hide();

    $('.desktop-sidebar').show();
    $('#toggle_desktop_btn').addClass('checked');

    $(window).resize();

    // fix attached content gets width and height reset to zero after browser resize
    for (let j = 0; j < akioma.aDesktopWindows.length; j++) {
      const oWin = akioma.aDesktopWindows[j].dhx,
        oAkWinObj = akioma.aDesktopWindows[j];

      oWin.allowResize();

      if (oAkWinObj.bIsDesktopWidget && akioma.toggleFlag)
        oWin.setMaxDimension($(oWin.wins.vp).width(), $(oWin.wins.vp).height());


      if (oWin.isMaximized()) {
        oWin.keepInViewport(true);
        oWin.wins.w[oWin._idd].conf.maxed = false;
        akioma.aDesktopWindows[j].stopSavingSettingsFlag = true;
        oWin.maximize();
        akioma.aDesktopWindows[j].stopSavingSettingsFlag = false;
        oWin.wins.w[oWin._idd].conf.maxed = true;
      }

      oAkWinObj.loadSettingsForWindow();
    }

    // remove dataview blocking select event
    // add class for selected dataview item
    akioma.dataViewTabs.detachEvent(cevid); // clean blocking event
    if (akioma.iActiveDataViewIndex != undefined) {
      dhtmlx.delay(() => {
        $dataViewTabsObj.find('.dhx_dataview_taskbarheader_item_selected').removeClass('dhx_dataview_taskbarheader_item_selected').addClass('dhx_dataview_taskbarheader_item');
        $dataViewTabsObj.find(`.dhx_dataview_item:nth-child(${akioma.iActiveDataViewIndex + 1})`).addClass('dhx_dataview_taskbarheader_item_selected');

        // if there are new settings not yet loaded in desktop apply now
        if (akioma.bLoadSettingsForNextToggleDesktop == true) {
          akioma.bLoadSettingsForNextToggleDesktop = false;
          for (let j = 0; j < akioma.aDesktopWindows.length; j++) {
            const oWin = akioma.aDesktopWindows[j],
              bWindowHidden = oWin.dhx.isHidden();


            // show window to apply dimension and position(doesn't work otherwise)
            if (bWindowHidden)
              oWin.dhx.show();
            oWin.stopSavingSettingsFlag = true; // stops onMoveResize user profile savings
            oWin.dhx.minimize(); // default minimize

            try {
              oWin.loadSettingsForWindow(); // load newly loaded settings
            } catch (e) {
              akioma.log.error('Problem loadingSettingsWindow.', e);
            }
            if (bWindowHidden)
              oWin.dhx.hide();


            oWin.stopSavingSettingsFlag = false; // reset back onMoveResize for user profile settings
          }
        }
      }, 1500);

      dhtmlx.delay(() => {
        $dataViewTabsObj.find('.dhx_dataview_taskbarheader_item_selected').removeClass('dhx_dataview_taskbarheader_item_selected').addClass('dhx_dataview_taskbarheader_item');
        $dataViewTabsObj.find(`.dhx_dataview_item:nth-child(${akioma.iActiveDataViewIndex + 1})`).addClass('dhx_dataview_taskbarheader_item_selected');
      }, 5500);
    }
  } else {
    // show all windows that are not in desktop mode
    for (let i = 0; i < akioma.aNonDesktopWindows.length; i++) {
      akioma.aNonDesktopWindows[i].dhx.show();
      try {
        // happens when opening windows from desktop mode and previously resized browser window
        // we need to reload saved settings again because of resize
        if (akioma.loadWinSetOpenedFlag != undefined)
          akioma.aNonDesktopWindows[i].loadSettingsForWindow(); // load newly loaded settings


      } catch (e) {
        akioma.log.error('Problem loadingSettingsWindow.');
      }
    }
    // after apply settings delete flag
    if (akioma.loadWinSetOpenedFlag != undefined)
      delete akioma.loadWinSetOpenedFlag;

    for (let j = 0; j < akioma.aDesktopWindows.length; j++)
      akioma.aDesktopWindows[j].dhx.denyResize();

    // select previously active dataview tab
    try {
      if (akioma.iActiveDataViewIndex != undefined)
        $dataViewTabsObj.find(` .dhx_dataview_item:nth-child(${akioma.iActiveDataViewIndex + 1})`).click();
    } catch (e) {
      akioma.log.error('Could not select dataview item');
    }

    $('.desktop-sidebar').hide();
    $('#toggle_desktop_btn').removeClass('checked');
    akioma.toggleFlag = state;
  }

  $(window).resize();
}

// on main desktop sidebar selection event // DEPRECATED , old desktop code
function onSidebarItemSelect(id) {
  const mySidebar = akioma.desktopSidebar;

  // load repository definitions from backend
  if (akioma.desktopTypesOpened[id] == undefined) {
    const aWidgets = akioma.desktopTypes[id].split(',');

    // load main window
    for (let numWidgets = 0; numWidgets < aWidgets.length; numWidgets++) {

      const cWidgetContainer = aWidgets[numWidgets];

      akioma.cMainContainer = cWidgetContainer.replace('.r', '');
      app.controller.launchContainer({
        proc: cWidgetContainer,
        para: 'page=0,1',
        data: true,
        self: oDhxTree,
        containerUserData: { type: 'widget' },
        async: true

      });
    }

    akioma.desktopTypesOpened[id] = true;
  }

  // set background on main sidebar
  $(mySidebar.cont).find('> .dhx_cell_sidebar').css({
    background: `url(${akioma.aBackgrounds[id]})`,
    backgroundSize: 'cover'
  });

  // show selected desktopwindows and load settings
  const aDesktopIds = akioma.desktopTypes[id].split(',');

  for (let iw = 0; iw < akioma.aDesktopWindows.length; iw++) {
    akioma.aDesktopWindows[iw].stopSavingSettingsFlag = true; // stop saving user profile settings savings to localstorage
    const cWindow = (`${akioma.aDesktopWindows[iw].opt.name}.r`).toLowerCase(),
      oWin = akioma.aDesktopWindows[iw].dhx;
    if (akioma.desktopTypes[id] != undefined && aDesktopIds.indexOf(cWindow) > -1) {
      oWin.show();
      if (oWin.isMaximized()) {
        oWin.keepInViewport(true);
        oWin.wins.w[oWin._idd].conf.maxed = false;
        oWin.maximize();
        oWin.wins.w[oWin._idd].conf.maxed = true;
      }

      // load settings for window
      akioma.aDesktopWindows[iw].loadSettingsForWindow();


    } else if (!akioma.aDesktopWindows[iw].dhx.isHidden())

      akioma.aDesktopWindows[iw].dhx.hide();

    akioma.aDesktopWindows[iw].stopSavingSettingsFlag = false; // restart saving user profile settings
  }


  return true;
}

// depracated, old desktop code
// global context function
// eslint-disable-next-line no-unused-vars
function createDesktopSidebar(data) {
  const aDesktopItems = data.desktops.split('|');
  const aItems = [];
  akioma.aBackgrounds = [];
  for (let i = 0; i < aDesktopItems.length; i++) {
    const itemProp = aDesktopItems[i].split(',');
    const item = {
      id: itemProp[0],
      text: itemProp[1],
      icon: itemProp[2],
      selected: (i == 0)
    };
    akioma.aBackgrounds[itemProp[0]] = itemProp[3];
    aItems.push(item);
  }

  const mySidebar = akioma.desktopPanel.attachSidebar({
    single_cell: true,
    mousedown: true,
    width: 160,
    template: 'icons_text',
    items: aItems,
    skin: oDhx.skin
  });

  // sidebar selection bug fix : clicking in form element and dragging over sidebar item/ prevent selection
  try {
    let bCorrectSelect = true;
    $(mySidebar.side).find('.dhxsidebar_item').mousedown(() => {
      bCorrectSelect = true;
    });

    mySidebar.attachEvent('onBeforeSelect', () => {
      // check first if dragged from input/prevent selection bug
      if (!bCorrectSelect)
        return false;
      else {
        bCorrectSelect = false;
        return true;
      }
    });
  } catch (e) {
    akioma.log.error('Error checking for correct sidebar selection', e);
  }

  mySidebar.setSideWidth(100);

  // create windows frame
  let oWindows = new dhtmlXWindows({
    image_path: oDhx.imagePath,
    skin: oDhx.skin
  });

  oWindows.attachViewportTo(akioma.desktopPanel.cell);

  const DesktopTypes = [];
  const DesktopWidgets = data.desktopWidgets.split('|');

  for (let i = 0; i < aItems.length; i++) {
    if (DesktopWidgets[i] != '' && DesktopWidgets[i] != undefined)
      DesktopTypes[aItems[i].id] = DesktopWidgets[i].toLowerCase();
  }
  akioma.desktopTypes = DesktopTypes;
  akioma.desktopSidebar = mySidebar;

  // add ripple effect on sidebar
  $(mySidebar.side).find('.dhxsidebar_item').ripple({ color: '#009688' });

  // desktop sidebar selection event
  mySidebar.attachEvent('onBeforeSelect', onSidebarItemSelect);

  $(mySidebar.cont).addClass('desktop-sidebar');
  $(mySidebar.cont).css({
    'z-index': 9999,
    'position': 'absolute',
    'top': 0
  });

  // create windows frame
  oWindows = new dhtmlXWindows({
    image_path: oDhx.imagePath,
    skin: oDhx.skin
  });
  oWindows.attachViewportTo(mySidebar.cells('desktop').cell);
  akioma.desktopWindows = oWindows;
  akioma.desktopPanel.hideHeader();
}
