/**
 * Get menu action object from given menu structure item
 * @param {object} oCurrentItem Menu structure item
 * @returns {object} Menu Action
 */
akioma.swat.getActionObject = function(oCurrentItem) {
  const oCurrAction = {};

  if (oCurrentItem.Function.actionOptions) {
    oCurrAction.actionLoadMenu = oCurrentItem.Function.actionOptions;
    oCurrAction.actionOptions = oCurrentItem.Function.actionOptions;
  }

  if (oCurrentItem.Function.eventPre)
    oCurrAction.eventPre = oCurrentItem.Function.eventPre;

  if (oCurrentItem.Function.eventPost)
    oCurrAction.eventPost = oCurrentItem.Function.eventPost;

  if (oCurrentItem.Function.visualizationType)
    oCurrAction.visualisationType = oCurrentItem.Function.visualizationType;


  if (oCurrentItem.Function.actionType) {
    oCurrAction.ActionType = oCurrentItem.Function.actionType.toUpperCase();
    oCurrAction.Action = '';

    switch (oCurrAction.ActionType) {
      case 'RUN':
        oCurrAction.Action = oCurrentItem.Function.actionParameter;
        break;
      case 'LAUNCH':
        oCurrAction.CallObject = oCurrentItem.Function.actionParameter;
        break;
      case 'PUBLISH':
        oCurrAction.Category = oCurrentItem.Function.actionTarget;
        oCurrAction.Action = oCurrentItem.Function.actionParameter;
        break;
      case 'INVOKE':
        oCurrAction.actionInvoke = {
          name: oCurrentItem.Function.actionTarget,
          methodName: oCurrentItem.Function.actionParameter,
          options: oCurrentItem.Function.actionOptions,
          eventPre: oCurrentItem.Function.eventPre,
          eventPost: oCurrentItem.Function.eventPost
        };

        oCurrAction.Action = `$ akioma.callInvokeServerTask(eventSource, ${JSON.stringify(oCurrAction.actionInvoke)})`;
        break;
    }
  }

  return oCurrAction;
};

// Menu structure control
(function($) {
  $.extend({
    /**
     * @typedef MenuFunction
     * @type {Object}
     * @property {boolean} isBig Used for Ribbon buttons size. If true, the button will have a larger size.
     * @property {boolean} imageOnly If true, the label won't be disaplyed, only the image.
     * @property {string} actionOptions
      if "actionType" is <code>LAUNCH</code> then "actionOptions" can be a string representation of a JSON object, </br>
      options that will be parsed when loading the new repository object, for eg.</br>
      <code>{ 'autoAdd': true, 'pages': '0,2' }</code></br>
      // autoAdd - automatically add a new record on the PRIMARYSDO:SRC found in the newly opened repository object specified in "actionParameter"</br>
      // pages - initially loaded pages
    * @property {string} actionParameter
        &#9658; if "actionType" is <code>RUN</code> then "actionParameter" is the global method name to execute on the UI, eg. <code>$ akioma.filterSearch(self);</code></br>
        &#9658; if "actionType" is <code>LAUNCH</code> then "actionParameter" is the repository object name to load, eg. <code>offerw</code> </br>
        &#9658; if "actionType" is <code>PUBLISH</code> then "actionParameter" is the name of the method to call, eg. <code>fileSaveClose</code>

    * @property {string} actionType Can be of type <code>RUN</code>, <code>PUBLISH</code>, <code>LAUNCH</code>
    * @property {string} actionTarget
      if "actionType" is <code>PUBLISH</code> then "actionTarget" represets the category, eg. <code>TABLEIO</code>,<code>NAVIGATION</code>
    * @property {boolean} isDevelopment
    * @property {boolean} isDisabled
    * @property {boolean} isHidden If true, the menuItem will be hidden.
    * @property {boolean} isDefaultAction If true, the menuItem will be used as default-key.
    * @property {string} selectEvent Can only be set for MenuFunctions having type Combo. This event is triggered when choosing an option in RibbonCombo and ToolbarCombo items.
    */

    /**
     * @typedef Menu
     * @type {Object}
     * @property {boolean} hasChild - if object has children or not.
     * @property {boolean} MenuBeginsAGroup
     * @property {string} FunctionCode the guid of the function if any
     * @property {string} FunctionGuid the guid
     * @property {MenuFunction} Function The menu function.
     * @property {string} MenuGuid The guid of the menu.
     * @property {string} MenuName The name of the menu.
     * @property {number} MenuSequence The menu sequence.
     * @property {string} MenuStructureType Can be item or menu
     */
    /**
     * SCL Menustructure Object.
     * The MenuStructure object is the container of Menu Items and Menu Function.
     * @class
     * @param  {object} options
     */
    ak_menustructure: function(options) {
      const defaults = { depth: 1 };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;

      this.registerDynObject = true;

      // attach new html element to parent
      const oParent = this.parent;

      this.id = this.opt.id;
      /**
               * List of menu items
               * @type {Menu}
               */
      this.aMenuItems = [];
      this.aMenuItemsNoKey = [];
      /**
               * List of menu functions
               * @type {MenuFunction}
               */
      this.aMenuActions = [];
      this.aMenuStructs = [];
      this.aMenuStructure;

      if (oParent) {

        try {
          const id = this.opt.id;
          const param = (this.opt.parameter || 'menustructurecode');
          this.urlPath = `/web/SmartMenuStructure?${param}=${id}&depth=${this.opt.depth}`;
        } catch (e) {
          akioma.notification({ type: 'error', text: `Could not set menu structure URL for ${this.opt.id} from ${oParent.opt.id}` });
        }

      }

    }
  });

  // methods for menustructure
  $.ak_menustructure.prototype = {
    // finish construct **********
    finishConstruct: function() { },

    /**
     * Goes over each menu item and calls the method passed in as parameter.
     * @memberOf ak_menustructure
     * @instance
     * @param  {function} fIterator Function called for each item
     */
    scan: function(fIterator) {

      for (const id in this.aMenuItemsNoKey) {
        if (this.aMenuItemsNoKey[id]) {
          const oMenuItem = this.aMenuItemsNoKey[id];
          const cId = oMenuItem.id;
          const cLabel = oMenuItem.label;
          const cIcon = oMenuItem.icon || '';
          // call method for this menu item
          fIterator(cId, cLabel, cIcon);
        }
      }

    },
    /**
     * Method used for loading the MenuStructure and calling a callback function.
     * @memberOf ak_menustructure
     * @instance
     * @param  {function} fCallback The callback function
     * @param {boolean} bDropDown Specifies if menu elements should be loaded in dropdown or not
     * @return {promise}           The Promise object
     */
    loadMenuElements: function(fCallback, bDropDown, cacheMenu = true) {

      const deferred = $.Deferred();

      const oSelf = this;
      const promiseLoadMenu = this.loadMenu(cacheMenu);

      promiseLoadMenu.then(result => {
        oSelf.bTabExists = false;
        if (result[0] == undefined)
          deferred.resolve([]);
        else {
          oSelf.aMenuStructure = {
            name: result[0].MenuName,
            style: result[0].MenuStyleCode,
            icon: result[0].MenuSmallImage,
            label: result[0].MenuName,
            code: result[0].MenuStructureCode
          };

          if (result[0].Structure) {
            if (result[0].Structure.eventPre)
              oSelf.aMenuStructure.eventPre = result[0].Structure.eventPre;
            if (result[0].Structure.visibilityRules)
              oSelf.aMenuStructure.visibilityRules = result[0].Structure.visibilityRules;
          }

          try {
            if (result[0].Children) {
              const addMenuItems = function(oChildren, cParentID) {
                for (let i = 0; i < oChildren.length; i++) {
                  const oCurrentItem = oChildren[i];

                  // for icon style from session settings
                  oCurrentItem.MenuSmallImage = akioma.icons.forceIconStyle(oCurrentItem.MenuSmallImage);

                  const cId = oCurrentItem.MenuGuid,
                    cMenuCode = oCurrentItem.MenuStructureCode,
                    cItemTitle = oCurrentItem.MenuName,
                    cIcon = oCurrentItem.MenuSmallImage || '',
                    cMenuStyle = oCurrentItem.MenuStyleCode || '',
                    cFunctionCode = oCurrentItem.FunctionCode;

                  if (cFunctionCode == 'enableAllFormFields') {
                    if (oSelf.parent.view !== 'form')
                      continue;
                  }

                  // Used for translation menuFunctions and menuStructures
                  const translateTitle = (cCode, cParentCode, cTitle, bFunction) => {

                    // Translation for Id + menuStructureCode/menuFunctionCode
                    let cItemTitleLocale = cTitle;
                    cItemTitleLocale = akioma.tran(`${oSelf.id}.${cCode}`, { defaultValue: cTitle });

                    // In case it doesn't exist, check for menuStructureCode + menuFunctionCode translation
                    if (cItemTitleLocale == cTitle && cParentCode)
                      cItemTitleLocale = akioma.tran(`${cParentCode}.${cCode}`, { defaultValue: cTitle });

                    // In case it doesn't exist, check only menuFunctionCode translation
                    if (cItemTitleLocale == cTitle && bFunction)
                      cItemTitleLocale = akioma.tran(`menuFunction.${cCode}`, { defaultValue: cTitle });

                    cTitle = cItemTitleLocale;
                    return cTitle;
                  };

                  // Translation for MenuFunctions
                  let cTranslatedTitle = '';
                  let translatedTooltip = '';

                  if (oCurrentItem.Function) {
                    cTranslatedTitle = translateTitle(cFunctionCode, cParentID, cItemTitle, true);
                    if (oCurrentItem.Function.tooltip)
                      translatedTooltip = akioma.tran(`menuFunction.${cFunctionCode}_tooltip`, { defaultValue: cTranslatedTitle });

                  } else {
                    // Translation for MenuStructures
                    cTranslatedTitle = translateTitle(cMenuCode, cParentID, cItemTitle);
                  }

                  if (app.sessionData.objectNamesInTitles) {
                    const titleParts = [ translatedTooltip, cMenuCode, cParentID ];
                    translatedTooltip = titleParts.join(' | ');
                  }

                  // add new menu item
                  oSelf.aMenuItems[cId] = {
                    id: cId,
                    code: cFunctionCode,
                    menuCode: cMenuCode,
                    icon: cIcon,
                    label: cTranslatedTitle,
                    tooltip: translatedTooltip,
                    children: oCurrentItem.HasChild || null
                  };

                  // check for parent in case of multidepths
                  if (cParentID)
                    oSelf.aMenuItems[cId].parentid = cParentID;

                  let cMenuType = null;
                  if (oCurrentItem.MenuStructureType == 'menu') {
                    if (cMenuStyle.toUpperCase() == 'RIBBON') {
                      cMenuType = 'tab';
                      oSelf.bTabExists = true;
                    } else
                      cMenuType = 'block';

                  } else if (oCurrentItem.MenuStructureType.toLowerCase() == 'item')
                    cMenuType = 'item';


                  if (oCurrentItem.MenuBeginsAGroup)
                    cMenuType = 'break';


                  if (oCurrentItem.Function != undefined) {
                    const cType = oCurrentItem.Function._functionClassName.split('.')[4];
                    switch (cType) {
                      case 'Combo':
                      case 'DynCombo':
                        cMenuType = 'combo';
                        oSelf.aMenuItems[cId].tooltip = oCurrentItem.Function.tooltip;
                        oSelf.aMenuItems[cId].selectEvent = oCurrentItem.Function.selectEvent;
                        break;
                      case 'Input':
                        cMenuType = 'input';
                        break;
                      case 'Action':
                        oSelf.aMenuItems[cId].isDefaultAction = oCurrentItem.Function.isDefaultAction;
                        oSelf.aMenuItems[cId].isBig = oCurrentItem.Function.isBig;
                        oSelf.aMenuItems[cId].imageOnly = oCurrentItem.Function.imageOnly;
                        oSelf.aMenuItems[cId].shortcut = oCurrentItem.Function.shortcut;
                        oSelf.aMenuItems[cId].tooltip = translatedTooltip;
                        oSelf.aMenuItems[cId].hidden = oCurrentItem.Function.isHidden;
                        oSelf.aMenuItems[cId].disabled = oCurrentItem.Function.isDisabled;
                        oSelf.aMenuItems[cId].visibilityRules = oCurrentItem.Function.visibilityRules;
                        oSelf.aMenuItems[cId].eventPre = oCurrentItem.Function.eventPre;
                        oSelf.aMenuItems[cId].eventPost = oCurrentItem.Function.eventPost;
                        break;
                      default:
                        break;
                    }

                  }

                  if (oCurrentItem.Function != undefined) {
                    if (oCurrentItem.Function.createEvent)
                      oSelf.aMenuItems[cId].createEvent = oCurrentItem.Function.createEvent;
                  }

                  // set menu type
                  if (cMenuType != null)
                    oSelf.aMenuItems[cId].type = cMenuType;


                  // now check and add actions
                  if (oCurrentItem.Function) {
                    const oCurrAction = akioma.swat.getActionObject(oCurrentItem);
                    oSelf.aMenuActions[cId] = oCurrAction;
                  }

                  // now check and add Structure
                  if (oCurrentItem.Structure) {
                    oSelf.aMenuItems[cId].structure = oCurrentItem.Structure;
                    oSelf.aMenuStructs[cId] = oCurrentItem.Structure;
                  }
                  oSelf.aMenuItemsNoKey.push(oSelf.aMenuItems[cId]);
                }
                for (let j = 0; j < oChildren.length; j++) {
                  const oCurrentItem = oChildren[j];
                  if (oCurrentItem.Children)
                    addMenuItems(oCurrentItem.Children, (oCurrentItem.MenuStructureCode || oCurrentItem.MenuGuid));
                }
              };

              addMenuItems(result[0].Children);
            }

            if (fCallback != undefined)
              fCallback(oSelf.aMenuItems, bDropDown);


            deferred.resolve(oSelf.aMenuItems);

          } catch (e) {
            console.warn('Error creating menuStructure ', oSelf.opt.id, e);
            deferred.reject('Error creating menuStructure ', oSelf.opt.id, e);
          }
        }
      });

      return deferred.promise();
    },

    /**
     * Return all children of a given menu id.
     * @memberOf ak_menustructure
     * @instance
     * @param  {string} cId The id of the parent menu
     * @return {array}     List of children menu items
     */
    getChildren: function(cId) {
      return this.aMenuItems[cId].children;
    },
    /**
     * Method used for setting the preParams options
     * @private
     * @instance
     * @memberOf ak_menustructure
     * @param {string} cId  The id of the menu
     * @param {object} opts The preParams object
     */
    setItemPreParams: function(cId, opts) {
      this.aMenuItems[cId].preParams = opts;
    },
    /**
     * Returns the given menu item preParams
     * @memberOf ak_menustructure
     * @param  {string} cId The id of the menu
     * @return {object}     The params for the pre event
     */
    getItemPreParams: function(cId) {
      return this.aMenuItems[cId].preParams;
    },
    /**
     * Returns the menu structure items
     * @memberOf ak_menustructure
     * @instance
     * @return {array} The list of menu items
     */
    getMenuStructure: function() {
      return this.aMenuStructure;
    },
    /**
     * Goes over each menu items and call the function for each
     * @memberOf ak_menustructure
     * @instance
     * @param  {function} fIterator The function to call for each item
     */
    scanMenuItemsObj: function(fIterator) {
      for (const id in this.aMenuItemsNoKey) {
        const oMenuItem = this.aMenuItemsNoKey[id];

        // call method for this menu item
        fIterator(oMenuItem);
      }
    },
    /**
     * Method that performs the backend request to get the menuStructure data.
     * @memberOf ak_menustructure
     * @instance
     * @return {promise} The Ajax request Promise object
     */
    loadMenu: function(cacheMenu = false) {
      const oSelf = this;
      const showError = function(jqXHR) {
        try {
          if (!akioma.ignoreClientLogicErrors)
            akioma.notification({ type: 'error', text: `Could not load menu structure:<br/>${oSelf.id} from ${oSelf.parent.opt.id}`, more: true, moretext: JSON.stringify(jqXHR.responseJSON, null, 4) });
        } catch (e) {
          if (jqXHR.responseJSON)
            akioma.notification({ type: 'error', text: `Could not load menu structure:<br/>${oSelf.id}`, more: true, moretext: JSON.stringify(jqXHR.responseJSON, null, 4) });
          else
            akioma.notification({ type: 'error', text: `Could not load menu structure:<br/>${oSelf.id}` });

        }
      };

      const deferred = $.Deferred();

      // load from Memory
      if (akioma.MenuStructureFactory.menuStructures[oSelf.opt.id] !== undefined) {
        setTimeout(() => {
          deferred.resolve(akioma.MenuStructureFactory.menuStructures[oSelf.opt.id]);
        }, 1);

        return deferred.promise();
      } else if (oSelf.dynObject.container && !cacheMenu) {
        // add to pending menus list
        const oCont = oSelf.dynObject.getFirstParentByType([ 'frame', 'window' ]);
        // add to pending menu structures list :)
        oCont._pendingMenuStructures.push(this);
        oCont._pendingMenuPromises.push(deferred);
        return deferred.promise();
      } else {
        // load individual menu structure
        return $.ajax({ url: oSelf.urlPath, async: true, error: showError });
      }
    },
    /**
     * Method used for calling a menu function for a given menu item.
     * @memberOf ak_menustructure
     * @instance
     * @param  {string} cId     The id of the menu function.
     * @param  {object} self    The context used when calling the menu function
     * @param  {object} subitem The context used when calling the menu function (used mostly for buttons in Ribbon/Toolbar)
     */
    applyAction: function(cId, self, subitem) {
      const oSelf = this,
        oMenuAction = oSelf.aMenuActions[cId],
        oMenuItem = oSelf.aMenuItems[cId];

      oSelf.oLastSelectedMenu = oSelf.aMenuItems[cId];
      oSelf.cLastSelectedMenuID = cId;
      oMenuAction.launchedFrom = subitem;

      try {
        if (oMenuAction != undefined && oMenuItem != undefined)
          app.controller.callAction(oMenuAction, self);

      } catch (e) {
        console.warn('Error calling Menu Action! ', e, oSelf);
      }

    },
    /**
     * Returns the currently selected menu item.
     * @memberOf ak_menustructure
     * @instance
     * @return {object} The menu structure object
     */
    getSelectedMenuItem: function() {
      return this.oLastSelectedMenu;
    },
    /**
     * Returns menu item from given menu name
     * @param  {string} cMenuName The menu name to find
     * @return {Menu}           Returns a menu object or null
     */
    getMenuItem: function(cMenuName) {

      const oMenuItem = _.find(this.aMenuItemsNoKey, item => item.label == cMenuName);

      return oMenuItem;
    },
    getMenuItemById: function(cMenuID) {

      const oMenuItem = this.aMenuItems[cMenuID];

      return oMenuItem;
    },
    /**
     * Returns menu item from given menu code
     * @param  {string} cMenuCode The menu code to find
     * @return {Menu}           Returns a menu object or null
     */
    getMenuItemByCode: function(cMenuCode) {
      const oMenuItem = _.find(this.aMenuItemsNoKey, item => {
        const cCode = item.menuCode || item.code;
        return cCode == cMenuCode;
      });
      return oMenuItem;
    },
    /**
     * Returns the menu's id.
     * @memberOf ak_menustructure
     * @instance
     * @return {string} The menu id
     */
    getSelectedMenuItemID: function() {
      return this.cLastSelectedMenuID;
    },

    // destroy
    destroy: function() {
      this.aMenuItems = [];
      this.aMenuItemsNoKey = [];
      this.aMenuActions = [];
      this.aMenuStructs = [];
    }

  };
})(jQuery, jQuery);
