akioma.enableAutoTranslate = function() {
  if (app.sessionData.autoTranState == undefined)
    app.sessionData.autoTranState = true;

  const cState = app.sessionData.autoTranState;
  if (!cState) {
    app.sessionData.autoTranState = true;
    akioma.translation.setProperty('saveMissing', false);
  } else {
    if (app.sessionData.userKey != 'axadmin') {
      akioma.messaging.info (`AutoTran not allowed for user<br>${app.sessionData.userKey}<br>`);
      return;
    }
    akioma.translation.setProperty('saveMissing', true);
    akioma.translation.setProperty('saveMissingTo', 'current');
    app.sessionData.autoTranState = false;
    akioma.messaging.info (`AutoTran using path<br>${akioma.translation.getProperty('backend')['addPath']}<br>` + `adding to ${akioma.translation.getProperty('saveMissingTo')}`);
  }
};

akioma.translationsFormFieldLeave = function(oSelf) {
  const cComboName = oSelf.name.replace('input_', 'combo_');
  oSelf.parent.setValue(cComboName, 'T');
};

akioma.initTranslationsForm = function(oSelf) {
  let cHdl = '072:000:100000016879';
  let i;
  let cField = 'selfdesc';

  const caller = oSelf.container.caller;
  if (caller.controller.opt.keyField)
    cField = caller.controller.opt.keyField;
  else
    cField = caller.controller.opt.name;


  cHdl = oSelf.container.caller.parent.controller.dataSource.dynObject.getValue('selfhdl');

  const oReturn = app.controller.callServerMethod('std/tran/gettrans.p',
    [
      { type: 'iCHAR', value: cHdl },
      { type: 'iCHAR', value: cField },
      { type: 'iCHAR', value: '' },
      { type: 'oTT', name: 'recordset' },
      { type: 'oCHAR', name: 'result' }
    ]);


  for (i in oReturn.recordset) {
    oSelf.setValue(`input_${oReturn.recordset[i].SelfHdl}`, oReturn.recordset[i].Translation);
    oSelf.setValue(`combo_${oReturn.recordset[i].SelfHdl}`, oReturn.recordset[i].calcTranState);
  }
};

akioma.getShowForeignLang = function(oSelf) {
  let cState = (localStorage ? localStorage.getItem('tranPopupShowForeignLang') : null);
  if (cState == null)
    cState = true;

  oSelf.parent.controller.dhx.setItemState (oSelf.name, cState);
  return;
};

akioma.tranPopupShowForeignLang = function(oSelf) {
  const oForm = oSelf.getLink('TABLEIO:TARGET');
  const oFieldset = oForm.childs['ForeignLangFieldset'];
  const cState = UserProfile.loadFormLocalProfileData(oFieldset.controller);

  oFieldset.controller.setCollapsed(cState == 'expanded');

  return;
};

akioma.saveTranslations = function(oSelf) {
  const oForm = oSelf.getLink('TABLEIO:TARGET'),
    caller = oSelf.container.caller;

  let cField;
  if (caller.controller.opt.keyField)
    cField = caller.controller.opt.keyField;
  else
    cField = caller.controller.opt.name;


  const cHdl = oSelf.container.caller.parent.controller.dataSource.dynObject.getValue('selfhdl');
  const cValues = oForm.getAllValues();
  app.controller.callServerMethod('akioma/ak_translat.p',
    [
      { type: 'iCHAR', value: 'saveTrans' },
      { type: 'iCHAR', value: cHdl },
      { type: 'iCHAR', value: cField },
      { type: 'iTT', value: cValues }
    ]);
  changeDataLanguage(app.sessionData.dataLanguage);
  try {
    oSelf.container.controller.caller.dynObject.screen.getLink('PRIMARYSDO:TARGET').controller.openQuery();
    oSelf.container.controller.close();
  } catch (e) {
    akioma.log.error(e);
  }
};

akioma.tran = function(key, options) {
  if (!akioma.translation.translateAvailable())
    return options.defaultValue;

  let cERP = null;

  if (akioma.applicationSettings)
    cERP = akioma.applicationSettings.erpName;

  if (!cERP)
    cERP = app.sessionData.erpName;
  if (!cERP) {
    if (app.sessionData.userKey == 'axadmin')
      cERP = 'Navision';
    else
      cERP = 'proALPHA';
  }
  if (!options.defaultValue)
    return '';

  options.erpName = cERP;

  let result;
  try {
    result = akioma.translation.translate(key, options);
    if (!result)
      result = options.defaultValue;
  } catch (e) {
    akioma.log.error(`Translation error ${e}`);
  }
  if (akioma.translation.getProperty('debug'))
    akioma.log.error(`translation for ${key} is: ${result}`);
  return result;
};

/**
 * Method for changing the data language
 * @param   {string|ak_toolbarcombo}  langParam The selected data language or the language toolbar combo
 */
function changeDataLanguage(langParam) {
  let cLang;

  if (typeof (langParam) === 'object')
    cLang = langParam.getValue();
  else
    cLang = langParam;


  // get through all elements
  function changeInChildren(oElm) {
    let i;

    for (i in oElm.childs) {
      if (oElm.childs[i].view == 'translat')
        oElm.childs[i].changeDataLang(cLang);
      changeInChildren(oElm.childs[i]);
    }
  }

  // send new data language to server
  akioma.setSessionProperty('dataLanguage', cLang);

  changeInChildren(oDhxTree);
}

// get languages
// global context function
// eslint-disable-next-line no-unused-vars
function getLanguages(oSelf) {
  // get options
  const oOptions = app.controller.callServerMethod(
    'akioma/getLanguages.p',
    []);

  for (const i in oOptions.comboOptions)
    oOptions.comboOptions[i].image = app.nodeTypes[oOptions.comboOptions[i].hdl] ? app.nodeTypes[oOptions.comboOptions[i].hdl].image : null;

  // add options to combo
  if (oOptions.comboOptions) {
    oSelf.setComboOptions(oOptions.comboOptions);

    // select first entry
    oSelf.click(oOptions.comboOptions[0].hdl);
  }
}
