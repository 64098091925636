(function($) {

  // ***************** chart ******************
  $.extend({
    /**
     * SwatChart Control
     * @class ak_chart
     * @tutorial  chart-desc
     * @param {Object} options Repository attributes for SwatChart.
     * @param {string} options.panelMenu comma separated list of menu-structures which will be shown as panelHeader-Buttons </br>
     * Can also contain the flag #NoDropDown that specifies that the menu should not be loaded as a dropdown but each menu item should be added in the panel-Header. </br>
     * For example: </br>
     * <code>menuStructSave,menuSettings#NoDropDown,menuLookup</code> </br>
     * The buttons support font icons with the following attributes: </br>
     * 1. Css attributes, defined like this: fa fa-user#color:red </br>
             * 2. Css classes, defined like this: fa fa-user#_style:module_prod
             * 3. Stacked font icons, defined like this: fas fa-circle$fas fa-flag. Both icons also support Css attributes or Css classes, like this: fas fa-circle#color:red$fas fa-flag#_style:module_prod </br>
     * @param {string} options.EventOnInitialize client side code to run when Container has been initialized
     * @param {string} options.floatingActionButton the id of a menustructure, which will be rendered as a FAB
     * @param {string} options.BorderTitle The Title of a dynamic Viewer or Browser
     * @param {string} options.contextMenu the id of a menuStructure which will be used as a context-menu
     * @param {string} options.MapName Name fields (comma separated) for showing a name in google maps
     * @param {string} options.titleHeader specifies which panelHeader to use. when empty, then uses the header of its own panel. if "none" then no header at all. if "parent" it uses the header of the parent panel
     * @param {string} options.LayoutOptions List of multi-layout options for the object.
     * @param {string} options.MapAddress Address fields for GoogleMap visualization
     * @param {string} options.Template
     */
    ak_chart: function(options) {
      const oSelf = this,
        defaults = { page: 0 };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;
      this.view = options.view;

      this.registerDynObject = true;
      this.registerVuexModule = true;

      this.parent.setOption('title', 'Chart');

      if (!this.opt.customStyle)
        this.opt.customStyle = this.view;

      // get parent
      const oParent = this.parent.dhx;
      if (oParent) {
        let oChart;

        if (akioma.chartTypes[this.opt.template]) {
          const oChartConf = akioma.chartTypes[this.opt.template];
          // if it has series included
          if (akioma.chartTypes[this.opt.template]) {

            oChart = oParent.attachChart(oChartConf.config);
            if (oChartConf.series.length > 0) {
              for (let i = 0; i < oChartConf.series.length; i++) {
                const oCurrentSeries = oChartConf.series[i];
                oChart.addSeries(oCurrentSeries);
              }
            }
          } else
            akioma.log.error(`Could not find config for chart ${this.opt.name}`, this);


        }

        try {
          // set title in panel
          oSelf.opt.title = akioma.tran(`${this.opt.name}._title`, { defaultValue: oSelf.opt.title });
          oSelf.addObjectDebugInfo();
          oChart.parse([], 'jsarray');
          throw new Error('Force the hardcoded values for now...');
        } catch (oErr) {
          akioma.log.error('error parsing chart data ', oErr);
          oChart.add({
            id: 1,
            value: 5.64,
            label: 2009
          });
          oChart.add({
            id: 2,
            value: 15.64,
            label: 2010
          });
        }

        $.extend(this, { dhx: oChart });

      } else
        !_isIE && console.error('No valid parent for chart ');

    }
  });

  $.ak_chart.prototype = {
    // finish construct **********
    finishConstruct: function() {
      this.dhx.sync(this.dynObject.getLink('DATA:SOURCE').controller.dhx);

      // set akId
      this.akId = ((this.opt.akId) ? this.opt.akId : this.opt.name);

      // set akStyle in chart
      $(this.dhx.$view).attr('akStyle', this.opt.customStyle);

      // set title and panelMenu buttons in panel header
      akioma.setPanelHeader(this);

      // set floatingActionButton in panel
      if (this.opt.floatingActionButton) {
        const oFloatingActionTarget = this;
        this.parent.setOption('floatingActionButton', this.opt.floatingActionButton, oFloatingActionTarget);
      }
    },
    endConstruct: function() {},
    dataAvailable: function() {},
    cursorChange: function() {},
    destroy: function() {}
  };
})(jQuery, jQuery);
