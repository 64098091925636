

// ********************** tab ***********************
$.extend({
  /**
     * SwatTab Control
     * @class ak_tab
     * @tutorial tabbar-desc
     * @param {object} options Repository Attributes for SwatTab
     * @param {string} options.Label Title for tabbar/sidebar item
     * @param {string} options.PageIcon Icon for tabbar/sidebar item. The icon can be either a font icon, either a normal icon. For font-icons, additional attributes can be specified:
     * <br> 1. Css attributes, defined like this: fa fa-user#color:red
     * <br> 2. Css classes, defined like this: fa fa-user#_style:module_prod
     * <br> 3. Stacked font icons, defined like this: fas fa-circle$fas fa-flag. Both icons also support Css attributes or Css classes, like this: fas fa-circle#color:red$fas fa-flag#_style:module_prod
     */
  ak_tab: function(options) {
    const oSelf = this,
      defaults = {
        title: '',
        id: '',
        page: 0,
        selected: false
      };

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;
    this.view = 'tab';
    this.registerVuexModule = true;
    this.frameContainer = true;
    this.bIsLoaded = false;

    this.generateNamespace();

    this.setupVuexStore();

    // initialize tab
    const oParent = this.parent.dhx;
    if (oParent) {

      this.parent.aTabKeys[this.opt.id] = this.opt.PageKey;
      this.parent.aTabGuid[this.opt.PageGuid] = this.opt.id;

      const lIsSidebar = (oSelf.parent.opt.viewMode != 'tabbar' && oSelf.parent.opt.viewMode != 'TABS');

      if (this.opt.PageLabel)
        this.opt.title = this.opt.PageLabel;

      try {
        this.opt.title = akioma.tran(`${this.opt.container}.` + `Tab${this.opt.page}`, { defaultValue: this.opt.title });
        this.opt.title = akioma.tran(`${this.opt.container}.${this.opt.PageKey}`, { defaultValue: this.opt.title });

        if (lIsSidebar) {
          if (this.parent.opt.template == 'text')
            oParent.addItem({ id: this.opt.id, text: this.opt.title });
          else {
            const template = this._buildTemplate();
            oParent.addItem({ id: this.opt.id, iconTemplate: template });
          }

          if (this.opt.PageKey)
            oParent.akElm.aItems[this.opt.PageKey] = this.opt.id;
        } else { // tabbar handling
          // check pos for addTabRepository
          let iAddPos = oParent.getNumberOfTabs();
          if (this.opt.addPos)
            iAddPos = this.opt.addPos;
          if (this.opt.isClosable == undefined)
            this.opt.isClosable = false; // for non-dynamic tabs default is non-closable

          if (this.parent.opt.template == 'text')
            oParent.addTab(this.opt.id, this.opt.title, '*', iAddPos, null, this.opt.isClosable); // add tab with text
          else {
            const template = this._buildTemplate();
            oParent.addTab(this.opt.id, template, '*', iAddPos, null, this.opt.isClosable); // add tab with icon
          }

          if (this.opt.PageKey)
            this.parent.aTabs[this.opt.PageKey] = this.opt.id;

          if (this.parent.opt.nestedTab) {
            oParent.tabsArea.setAttribute('data-nested', 'true');
            if (this.opt.page != 1) {
              oParent.t[this.opt.id].tab.setAttribute('data-nested', 'true');
              oParent.t[this.opt.id].tab.children[0].setAttribute('data-nested', 'true');
            }
          }
        }

        if (this.opt.EnableOnView === false) {
          const tabbarViewMode = this.parent.opt.viewMode;
          if (this.opt.PageKey || (tabbarViewMode && tabbarViewMode.toLowerCase() == 'tabs'))
            this.parent.disablePage(this.opt.PageKey);
          else
            this.parent.disablePage(this.opt.page);

        }


        this.init = options.init || false;
        if (this.opt.selected || (!this.parent.currentPageKey() && !this.parent.getActivePage() && this.opt.EnableOnView !== false)) {

          if (!lIsSidebar) {
            if (this.parent.opt.nestedTab) {
              oParent.t[this.opt.id].tab.setAttribute('data-nested', 'true');
              oParent.t[this.opt.id].tab.children[0].setAttribute('data-nested', 'true');
            }
            oParent.tabs(oSelf.opt.id).setActive(true);
          } else
            oParent.items(oSelf.opt.id).setActive(true);

        }

        // set tooltip
        if (this.opt.tooltip)
          oParent.setTooltip(this.opt.id, this.opt.tooltip);

        if (this.opt.hidden) {
          if (lIsSidebar) oParent.items(this.opt.id).hide();
          else oParent.tabs(this.opt.id).hide();
        }

        $.extend(this, { dhx: oParent.cells(this.opt.id) });

        this.dhx.akElm = this;

      } catch (e) {
        !_isIE && console.error([ 'Error adding tab', this.opt.name, this, e.message ]);
      }
    } else
      !_isIE && console.error(`No valid parent for tab ${this.opt.name}`);

  }
});

// methods for tab
Object.assign($.ak_tab.prototype, $.ak_global.prototype, {

  componentOptions: function() {
    const oSelf = this;
    return {
      watch: {
        'state.attributes.hasChanges': function(newValue, oldValue) {
          oSelf._hasChangesWatcher(newValue, oldValue);
        },
        'state.attributes.customStates': {
          fn: function(customStates) {
            oSelf._hasCustomStates(customStates);
          },
          watchOptions: { deep: true }
        },
        'state.attributes.hasErrors': function(newValue, oldValue) {
          oSelf._hasErrorsWatcher(newValue, oldValue);
        }
      }
    };
  },

  /**
      * Watcher for Errors
      * @param {boolean} newValue
      */
  _hasErrorsWatcher: function(newValue) {
    let oSide, itemside;
    if (this.parent.dhx.sidebar)
      oSide = this.parent.dhx.sidebar.t;
    else
      oSide = this.parent.dhx.t;

    const cSelId = this.opt.id;
    if (oSide) {
      if (oSide[cSelId].item)
        itemside = oSide[cSelId].item;
      else
        itemside = oSide[cSelId].tab;

      if (this.oVuexState.attributes.hasErrorsStyle !== '')
        $(itemside).attr(this.oVuexState.attributes.hasErrorsStyle, newValue);

      if (this.parent.opt.eventOnStateChanged) {
        try {
          this.stateData = {
            'state': 'hasErrors',
            'value': newValue,
            'action': null
          };
          app.controller.callAkiomaCode(this, this.parent.opt.eventOnStateChanged);
        } catch (e) {
          console.error(e);
        }
      }

    }
  },

  /**
      * Watcher for the customState attribute
      * @param {array} customStates
      * @protected
      * @returns {void}
      */
  _hasCustomStates: function(customStates) {
    if (isNull(customStates)) return;

    let oSide = null;
    if (this.parent.dhx.sidebar)
      oSide = this.parent.dhx.sidebar.t;
    else
      oSide = this.parent.dhx.t;

    const cSelId = this.opt.id;
    if (oSide) {
      let itemside = null;
      if (oSide[cSelId].item)
        itemside = oSide[cSelId].item;
      else
        itemside = oSide[cSelId].tab;

      let value = '';
      for (let index = 0; index < customStates.length; index++) {
        if (index == 0)
          value += customStates[index].name;
        else
          value += ` ${customStates[index].name}`;

      }

      if (value)
        $(itemside).attr('akcustomstate', value);
      else
        $(itemside).removeAttr('akcustomstate');

    }

    this.stateData = this._getCustomStateData(this.oVuexState.attributes);
    if (this.parent.opt.eventOnStateChanged) {
      try {
        app.controller.callAkiomaCode(this, this.parent.opt.eventOnStateChanged);
      } catch (e) {
        console.error(e);
      }
    }
  },

  _hasChangesWatcher: function(newValue) {
    const oSelf = this;

    let oSide, itemside;
    if (oSelf.parent.dhx.sidebar)
      oSide = oSelf.parent.dhx.sidebar.t;
    else
      oSide = oSelf.parent.dhx.t;
    if (isNull(oSide))
      return;

    const cSelId = oSelf.opt.id;
    if (oSide[cSelId].item)
      itemside = oSide[cSelId].item;
    else
      itemside = oSide[cSelId].tab;

    if (oSelf.oVuexState.attributes.hasChangesStyle !== '')
      $(itemside).attr(oSelf.oVuexState.attributes.hasChangesStyle, newValue);

    if (this.parent.opt.eventOnStateChanged) {
      try {
        this.stateData = {
          'state': 'hasChanges',
          'value': newValue,
          'action': null
        };
        app.controller.callAkiomaCode(this, this.parent.opt.eventOnStateChanged);
      } catch (e) {
        console.error(e);
      }
    }
  },

  /**
     * Build the tab/sidebar item template
     * @returns {String}
     * @memberof ak_tab
     * @instance
     * @private
     */
  _buildTemplate: function() {
    const templates = this.parent.templates[
      this.parent.opt.template
    ];
    let template = '';

    if (isNull(this.opt.icon) || this.opt.icon === '')
      this.opt.icon = akioma.icons.defaultTab;

    const iconObject = akioma.icons.getIconType(this.opt.icon);
    if (iconObject.type == 'normal-icon')
      template = templates.normalIcons;
    else
      template = templates.fontIcons;
    iconObject.title = this.opt.title;

    return akioma.icons.replaceTemplate(template, iconObject);
  },

  /**
     * Method called after tab select for all forms found in tab, in case the afterDisplay event was not yet triggered but data already loaded
     * @instance
     * @memberof ak_tab
     */
  callLoadedFormsAfterDisplay: function() {
    try {
      const oTab = this;
      const aForms = oTab.getAllChildrenByType('form');

      for (const x in aForms) {
        const oForm = aForms[x];
        const isFormVisible = oForm.isFormVisible();
        if (oForm.bAfterDisplayCalledOnce == false && oForm.dataSource !== undefined) {
          const dhxStore = oForm.dataSource.getStore(oForm.opt.entityName);
          if (!isNull(dhxStore) && dhxStore.getCursor() && isFormVisible)
            oForm.callAfterDisplayEvent();

        }
      }
    } catch (e) {
      akioma.log.error(e);
    }

  },
  finishConstruct: function() {
    if (this.childs.length > 0)
      this.init = true;

    const oTab = this;
    const oParent = this.parent.dhx;

    oTab.bAfterTabSelected = false;

    if (oParent.akElm.opt.template == 'icons') {
      let items = [];
      if (oParent.akElm.opt.viewMode == 'sidebar') {
        items = this.dhx.sidebar.t;
        const oSidebarItem = items[this.opt.id];
        $(oSidebarItem.item).attr('title', this.opt.title);
      } else {
        items = this.dhx.tabbar.t;
        const oTabbarItem = items[this.opt.id];
        $(oTabbarItem.tab).attr('title', this.opt.title);
      }
    }

    if (oParent.akElm.opt.viewMode == 'tabbar' && this.opt.dynTab)
      oParent.tabs(this.opt.id).setActive(true);

    // set akId in SidebarItem/TabbarItem
    const item = (oParent.akElm.opt.viewMode == 'sidebar') ? oParent.t[this.opt.id].item : oParent.t[this.opt.id].tab;
    const parentAkId = oParent.akElm.akId;
    $(item).attr('akId', `${parentAkId}-${this.opt.name}`);

    if (akioma.getAkIdMode() == 'extended') {
      $(item).find('.dhxsidebar_item_icon').attr('akId', `${parentAkId}-${this.opt.name}-icon`);
      $(item).find('.dhxsidebar_item_text').attr('akId', `${parentAkId}-${this.opt.name}-text`);
    }

  },

  destroy: function() {

    // check if dhtmlx element exists -> destroy all attached elements
    if (this.dhx) {
      try {
        // check if we are attached in a dhx element
        this.dhx.detachMenu();
        this.dhx.detachToolbar();
        this.dhx.detachObject(true);

        // remove tab, dhx4
        // actually dhtmlx have unloads, they are called automatically when you close window,
        // all nested content unloaded recursively, you need only unload your custom if any


        // this.parent.dhx.tabs(this.opt.id).close(false);

        // this.parent.dhx.removeTab( this.opt.id, false ); // deprecated
      } catch (e) {
        // !_isIE && console.error( ["Error destroying", this.view, this.opt.name, e.message ] );
      }
    }
  }
});
