/**
* Helper functions for converting JSDO specifics (filters, parameters) to/from Kinvey.
* @namespace {akioma}
*/
class KinveyAdapterHelper {
  /**
  * Get kinvey filter for akFilter equivalent.
  * @private
  * @param {Object} akFilter Filter object.
  * @param {string} akFilter.field Field name.
  * @param {string} akFilter.operator Operator.
  * @param {string} akFilter.value Value.
  * @returns {Object}
  */
  static getKinveyFilter({ field, operator, value }) {
    const kinveyFilter = {};

    // startswith if no asterisk added, only with end asterisk run matches
    if (operator === 'beginsmatches' && !value.endsWith('*') && !value.startsWith('*'))
      operator = 'startswith';

    switch (operator) {
      case '=':
      case 'eq':
        kinveyFilter[field] = { '$eq': value };
        break;
      case 'ne':
      case '<>':
      case 'neq':
        kinveyFilter[field] = { '$ne': value };
        break;
      case 'isnull':
        kinveyFilter[field] = { '$eq': null };
        break;
      case 'isnotnull':
        kinveyFilter[field] = { '$ne': null };
        break;
      case '<':
      case 'lt':
        kinveyFilter[field] = { '$lt': value };
        break;
      case '>':
      case 'gt':
        kinveyFilter[field] = { '$gt': value };
        break;
      case 'le':
      case '<=':
      case 'lte':
        kinveyFilter[field] = { '$lte': value };
        break;
      case 'ge':
      case '>=':
      case 'gte':
        kinveyFilter[field] = { '$gte': value };
        break;
      case 'begins':
      case 'startswith':
        kinveyFilter[field] = { '$regex': `^${value.replace('*', '.*')}` };
        break;
      case 'ends':
      case 'endswith':
        kinveyFilter[field] = { '$regex': `${value.replace('*', '.*')}$` };
        break;
      case 'beginsmatches':
        kinveyFilter[field] = { '$in': Array.isArray(value) ? value : [value.replace('*', '')] };
        break;
      case 'matches':
      case 'contains':
      case 'isin':
        kinveyFilter[field] = { '$in': Array.isArray(value) ? value : [value] };
        break;
      case 'isnotin':
        kinveyFilter[field] = { '$nin': Array.isArray(value) ? value : [value] };
        break;
      default:
        throw new Error(`Unsupported filtering operator: ${operator}`);
    }

    return kinveyFilter;
  }

  /**
  * Checks if the given akFilter is a filter array.
  * @private
  * @param {Object} akFilter AkFilter to check.
  * @returns {boolean}
  */
  static isFilterArray(akFilter) {
    return !isNull(akFilter.logic) || !isNull(akFilter.filters);
  }

  /**
  * Convert akQuery filters to kinvey equivalent.
  * @private
  * @param {object} filters AkQuery filters to convert.
  * @returns {object}
  */
  static convertFiltersToKinvey = function(filters) {
    const akFiltersArray = filters.filters || [];
    const kinveyLogicalOperator = filters.logic === 'and' ? '$and' : '$or';
    const kinveyHelper = akioma.KinveyAdapterHelper;
    const kinveyArrayFilters = akFiltersArray.map(
      akFilter => kinveyHelper.isFilterArray(akFilter) ? kinveyHelper.convertFiltersToKinvey(akFilter) : kinveyHelper.getKinveyFilter(akFilter)
    );

    const kinveyFilter = {};
    kinveyFilter[kinveyLogicalOperator] = kinveyArrayFilters;
    return kinveyFilter;
  };

  /**
   * Converts AkQuery parameter object to kinvey query.
   * @memberOf KinveyAdapterHelper
   * @param {Object} options AkQuery parameter object.
   * @returns {Object}
   */
  static convertAkQueryToKinvey(options) {
    const kinveyQuery = {};

    if (!isNull(options.skip))
      kinveyQuery.skip = options.skip;

    if (!isNull(options.top))
      kinveyQuery.limit = options.top;

    if (!isNull(options.orderBy)) {
      const [ fieldName, orderDirection ] = options.orderBy.split(' ');
      const kinveySort = {};

      kinveySort[fieldName] = orderDirection === 'desc' ? -1 : 1;
      kinveyQuery.sort = kinveySort;
    }

    if (!isNull(options.akQuery.filters))
      kinveyQuery.filter = akioma.KinveyAdapterHelper.convertFiltersToKinvey(options.akQuery.filters);

    return new Kinvey.Query(kinveyQuery);
  }
}

akioma.KinveyAdapterHelper = KinveyAdapterHelper;
