/**
 * SocketTreeGridRoom class for handling treegrid socket.io notification of users
 * @class SocketTreeGridRoom
 */
akioma.SocketTreeGridRoom = class {
  /**
     * Method for joining given socket room id
     *
     * @param   {string}  lastOwner  Socket Room ID
     * @static
     * @memberof SocketTreeGridRoom
     *
     */
  static join(lastOwner) {
    if (akioma.socketConnection.exists()) {
      akioma.log.info('Tree joining room:', lastOwner);

      akioma.socketConnection.emitJoinRoom({
        roomKey: lastOwner,
        type: 'treegridNotification',
        user: app.sessionData.userDesc
      });
    }
  }

  /**
     * Method for binding room join for showing notifications
     * @static
     * @memberof SocketTreeGridRoom
     */
  static bindJoinedListener() {
    if (akioma.socketConnection.exists()) {
      akioma.socketConnection.on('treegridNotification', data => {
        const message = data.action === 'join' ? `${data.user} joined!` : `${data.user} left!`;
        akioma.notification({
          type: 'info',
          text: akioma.tran(`treegridRoomNotification.${data.action}`, { defaultValue: message }),
          lifetime: 10000,
          expire: 10000
        });
      });
    }
  }

  /**
     * Method for leaving a given socket room
     *
     * @param   {string}  lastOwner  The Room ID
     * @static
     * @memberof SocketTreeGridRoom
     *
     */
  static leave(lastOwner) {
    if (akioma.socketConnection.exists()) {
      akioma.socketConnection.emitLeaveRoom({
        roomKey: lastOwner,
        type: 'treegridNotification',
        user: app.sessionData.userDesc
      });
    }
  }
};
