/**
 * RepositoryStructure class encapsulate invokeServerTasks for reducing the duplicated code.
 * @class RepositoryStructure
 * @namespace akioma
 */
akioma.RepositoryStructure = class {

  /**
     * Fetch design attributes for object master
     * @static
     * @param {String} ObjectMasterGuid
     * @returns {function}
     * @memberof RepositoryStructure
     */
  static fetchMasterDesignAttributes(ObjectMasterGuid) {
    return akioma.invokeServerTask({
      name: 'Akioma.Swat.Repository.AttributeValuesTask',
      methodName: 'GetObjectMasterDesignAttributes',
      paramObj: { plcParameter: { ObjectMasterGuid: ObjectMasterGuid } }
    });
  }

  /**
     * Fetch design attributes for object instance
     * @static
     * @param {String} ObjectInstanceGuid
     * @returns {function}
     * @memberof RepositoryStructure
     */
  static fetchInstanceDesignAttributes(ObjectInstanceGuid) {
    return akioma.invokeServerTask({
      name: 'Akioma.Swat.Repository.AttributeValuesTask',
      methodName: 'GetObjectInstancesDesignAttributes',
      paramObj: { plcParameter: { ObjectInstanceGuids: ObjectInstanceGuid } }
    });
  }

  /**
     * Fetch design attributes placeholders for object instance
     * @static
     * @param {Array} InstancesGuids
     * @returns {function}
     * @memberof RepositoryStructure
     */
  static fetchInstancePlaceholderDesignAttributes(InstancesGuids) {
    return akioma.invokeServerTask({
      name: 'Akioma.Swat.Repository.Class.AttributeValueEntity',
      methodName: 'FetchObjectInstancePlaceholderAttributes',
      paramObj: { plcParameter: { Value: InstancesGuids.join(',') } }
    });
  }
};
