// ********************* combo ********************
$.extend({
  /**
     * SwatComboBox Control
     * @class ak_combobox
     * @param {Object} options Repository attributes for SwatComboBox.
     * @param {boolean} options.CanSort Set to FALSE if this field should not be used to sort the data object query.
     * @param {number} options.WIDTH-CHARS Width in characters. This may currently be used when rendering some objects. There is no get function, use getWidth to retrieve the realized value from an object.
     * @param {string} options.align alignment of column. can be left, right or centered
     * @param {string} options.HELP WidgetAttributes Help
     * @param {number} options.ROW Row position.
     * @param {boolean} options.Mandatory WidgetAttributes Mandatory
     * @param {string} options.VisualizationType WidgetAttributes VisualizationType
     * @param {string} options.LABEL WidgetAttributes Label
     * @param {string} options.EventEntryType Language of "entry" trigger
     * @param {number} options.HEIGHT-CHARS Height in characters. This may currently be used when rendering some objects. There is no get function, use getHeight to retrieve the realized value from an object.
     * @param {boolean} options.VISIBLE WidgetAttributes Visible
     * @param {boolean} options.CHECKED
     * @param {string} options.TemplateFile The relative path and filename of the static object used as the template at design time
     * @param {string} options.InitialValue WidgetAttributes InitialValue
     * @param {string} options.EventEntry Event which should run on entry of a field/ focus in field
     * @param {string} options.LIST-ITEMS
     * @param {boolean} options.CanFilter Set to FALSE if this field should not be used to filter the data object query.
     * @param {string} options.ViewAs The 'ViewAs' definition  of the selection.&#10;- combo-box,radio-set,selection-list OR browse &#10;- Uses colon as separator to define SUB-TYPE for combo-box or &#10;horizontal/vertical radio-set,
     * @param {string} options.FolderWindowToLaunch If Dynamics is running, this property specifies a window to launch upon the occurence of toolbar events "View", "Copy", "Modify" or "Add".
     * @param {string} options.EventLeaveType Language, the event is written in.
     * @param {string} options.TableName WidgetAttributes TableName
     * @param {string} options.FORMAT WidgetAttributes Format
     * @param {string} options.LIST-ITEM-PAIRS
     * @param {string} options.EventAkValidate Client side validation code (JavaScript)
     * @param {string} options.DATA-TYPE WidgetAttributes Data-Type
     * @param {string} options.Validation Validation-Rules, e.g. ValidInteger,ValidEMail...
     * @param {string} options.FilterOperator
     * @param {boolean} options.LABELS If false then NO-LABEL is used.  This attribute applies to most field level widgets and frames
     * @param {string} options.FieldName The name of the associated SDO field this SmartDataField maps to. This is usually 'set' from the containing SmartDataViewer.
     * @param {boolean} options.ENABLED WidgetAttributes Enabled
     * @param {boolean} options.CreateField
     * @param {string} options.EventLeave Event when leaving a field
     * @param {string} options.eventOnInfoSelected Executed on info click
     * @param {boolean} options.infoButton Show/hide info icon
     * @param {string} options.SUBTYPE
     * @param {boolean} options.MULTIPLE
     * @param {string} options.Width A widget's width. The unit of measurement is determined by another&#10;parameter.
     * @param {number} options.COLUMN Column position. This may currently be used when rendering some objects. There is no getColumns function, use getCol to retrieve the realized value from an object.
     * @param {string} options.FieldLabel Label for the Dynamic Lookup/Dynamic Combo field on the viewer.
     * @param {string} options.SDFFileName The SmarObject file name of the file used for the SmartLookupObject/SmartComboObject.
     * @param {string} options.EventClick Client side validation code (JavaScript)
     * @param {string} options.filter
     * @fires ak_combobox#EventAkValidate
     * @fires ak_combobox#EventEntry
     * @fires ak_combobox#EventLeave
     * @fires ak_eventOnInfoSelected
     */
  ak_combobox: function(options) {

    akioma.BaseFormDataField.call(this, options);

    const defaults = {};

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;
    this.view = options.view;

    this.registerDynObject = true;
    this.registerVuexWatcher = true;
    this.useParentDynObjectLink = true;

    // get parent
    const oParent = this.parent;

    if (this.opt.validation) {
      const oForm = akioma.getForm(this);
      const aValidations = this.opt.validation.split(',');
      for (const v in aValidations) {
        const cInstanceValidation = aValidations[v];
        const aInsValidations = cInstanceValidation.split(':');
        if (oForm.validationRules[this.opt.name] == undefined)
          oForm.validationRules[this.opt.name] = {};

        const cValidationType = aInsValidations[0].trim().toLowerCase();
        if (cValidationType == 'required')
          this.opt.required = true;
          // based on form item name and
        oForm.validationRules[this.opt.name][cValidationType] = {
          smartmessage: aInsValidations[2].trim(),
          value: aInsValidations[1].trim().toLowerCase()
        };
      }

    }

    if (oParent) {

      if (!this.opt.customStyle)
        this.opt.customStyle = this.view;

      const aOptVal = this.opt.optVal ? this.opt.optVal.split(',') : [],
        aOptText = this.opt.optText ? this.opt.optText.split(',') : [],
        aOptions = [];

      for (const i in aOptVal) {
        aOptions.push({
          text: aOptText[i],
          value: (aOptVal[i] == null ? '' : aOptVal[i])
        });
      }
      for (const i in options.sub) {
        options.sub[i].att.text = akioma.tran(`${akioma.getForm(this).opt.name}.${this.opt.name}_option_${options.sub[i].att.value}`, { defaultValue: options.sub[i].att.text });

        aOptions.push({
          text: options.sub[i].att.text,
          value: (options.sub[i].att.value == null ? '' : options.sub[i].att.value)
        });
      }

      this.opt.label = akioma.tran(`${akioma.getForm(this).opt.name}.${this.opt.name}`, { defaultValue: this.opt.label });

      if (app.sessionData.objectNamesInTitles) {
        if (!this.opt.note)
          this.opt.note = '';
        this.opt.note = `${this.opt.name} | ${this.opt.note}`;
      }

      oParent.prop.fields.push({
        type: 'combo',
        comboType: this.opt.comboType,
        inputTop: parseInt(this.opt.top),
        inputLeft: parseInt(this.opt.left),
        inputWidth: parseInt(this.opt.width),
        label: this.opt.label ? this.opt.label : '',
        labelTop: parseInt(this.opt.top),
        required: (this.opt.required) ? this.opt.required : false,
        labelLeft: oParent.labelLeft(this),
        name: this.opt.name,
        enabled: !this.opt.disabled,
        readonly: this.opt.disabled,
        mandatory: this.opt.required,
        disabled: this.opt.disabled,
        hasError: false,
        errorMsg: '',
        className: 'w4-formField w4-inputField combobox',
        value: (this.opt.initial) ? this.opt.initial : '',
        note: { text: (this.opt.note) ? this.opt.note : '', width: this.opt.width },
        position: 'label-top',
        options: aOptions,
        userdata: { id: this.opt.id }
      });

      // append to elements in form
      akioma.getForm(this).elements.push(this);
    }
  }
});

// methods for form
Object.assign($.ak_combobox.prototype, akioma.BaseFormDataField.prototype, {
  componentOptions: function() {
    const oSelf = this;
    return {
      watch: {
        'getters.getFormFieldState': {
          fn: function(newValue, oldValue) {
            oSelf._hasFormFieldChangesWatcher(newValue, oldValue);
          },
          params: [this.opt.id]
        },
        'getters.getCustomStates': {
          fn: function(customStates) {
            oSelf._customStatesWatcher(customStates);
          },
          params: [this.opt.id]
        },
        'getters.getFormFieldEnabled': {
          fn: function(bEnabled) {
            oSelf._enabledFormFieldWatcher(bEnabled);
          },
          params: [this.opt.id]
        },
        'getters.getFormFieldMandatory': {
          fn: function(bMandatory) {
            oSelf._mandatoryFormFieldWatcher(bMandatory);
          },
          params: [this.opt.id]
        },
        'getters.getFormFieldError': {
          fn: function(newValue, oldValue) {
            oSelf._errorFormFieldWatcher(newValue, oldValue);
          },
          params: [this.opt.id]
        },
        'getters.getFormFieldErrorMsg': {
          fn: function(cErrorMsg) {
            oSelf._errorFormFieldMsgWatcher(cErrorMsg);
          },
          params: [this.opt.id]
        }
      }
    };
  },
  _enabledFormFieldWatcher: function(bEnabled) {
    const oSelf = this;
    const oForm = akioma.getForm(oSelf);
    const cName = oSelf.opt.name;

    if (oForm.dhx) {
      if (bEnabled)
        oForm.dhx.enableItem(cName);
      else
        oForm.dhx.disableItem(cName);

    }
  },
  _mandatoryFormFieldWatcher: function(bMandatory) {
    const oSelf = this;
    const oForm = akioma.getForm(oSelf);
    const cName = oSelf.opt.name;

    if (oForm && oForm.dhx) {
      if (typeof (oSelf.setRequired) == 'function')
        oSelf.setRequired(bMandatory);

      oForm.dhx.setRequired(cName, bMandatory);
    }
  },
  /**
     * Watcher for the form error message
     */
  _errorFormFieldMsgWatcher: function(newValue) {

    const oSelf = this;
    const cName = oSelf.opt.name;
    const oForm = akioma.getForm(oSelf);
    const oFormField = akioma.getDhxFormElement(oForm.dhx, cName);

    // setup error message under input
    if (oFormField) {
      const oFormCtrl = $(oFormField).find('> .dhxform_control');
      if (oFormCtrl.find('.validation-error-smartmessage').length == 0)
        oFormCtrl.append(`<span class="validation-error-smartmessage">${newValue}</span>`);
      else
        oFormCtrl.find('.validation-error-smartmessage').text(newValue);
    }

  },

  /**
   * Set field as required.
   * @memberof ak_combobox
   * @instance
   * @param {Boolean} bRequired
   */
  setRequired: function(bRequired) {
    const oSelf = this;
    const oInput = $(oSelf.dhx.DOMParent).parent();
    (bRequired) ? $(oInput).addClass('akMandatory akComboBoxMandatory') : $(oInput).removeClass('akMandatory akComboBoxMandatory');
  },

  // finish construct **********
  finishConstruct: function() {
    // get field
    const parentForm = akioma.getForm(this);
    const formAkId = parentForm.akId;
    this.form = parentForm.dhx;
    this.dhx = this.form.getCombo(this.opt.name);

    const oCombo = this.dhx;

    const oComboSelector = $(oCombo.base).parent().parent()[0];
    $(oComboSelector).attr('akId', `${formAkId}-${this.opt.name}`); // set akId in combobox
    $(oComboSelector).attr('akStyle', this.opt.customStyle); // set akStyle in combobox
    if (this.opt.required)
      $(oComboSelector).addClass('akMandatory akComboBoxMandatory');

    this.$domElement = $(oComboSelector).parent();
    this.setResponsiveSizes();

    const $itemcont = $(oCombo.DOMParent).parent().parent();
    $itemcont.addClass('active');

    oCombo.readonly(true);

    const oSelf = this;
    const oDataSource = akioma.getForm(this).dataSource;
    let oTreeGrid;
    if (oDataSource)
      oTreeGrid = akioma.getForm(this).dataSource.dataSource;
    const $ComboBase = $(oSelf.dhx.getBase());
    oCombo.attachEvent('onOpen', () => {
      try {
        $ComboBase.addClass('combolist-opened');
        if (oTreeGrid)
          oTreeGrid.triggerTreeEnterKey = false;

      } catch (e) {
        console.warn('Combobox form elem ', oSelf.opt.name, e);
      }
    });

    oCombo.attachEvent('onClose', () => {
      try {
        if (oSelf.timeout)
          clearTimeout(oSelf.timeout);

        oSelf.timeout = setTimeout(() => {
          if (oTreeGrid)
            oTreeGrid.triggerTreeEnterKey = true;

          $ComboBase.removeClass('combolist-opened');
        }, 500);
      } catch (e) {
        console.warn('Combobox form elem ', oSelf.opt.name, e);
      }
    });

    oCombo.attachEvent('onBlur', function() {
      const item = this.DOMParent.parentNode.parentNode;
      item.comboObject.doValidate(item);
      oSelf.eventLeave();
    });

    oCombo.attachEvent('onFocus', () => {
      oSelf.form.akElm.bFocused = true;
    });

    oCombo.attachEvent('onChange', () => {
      oSelf.eventValidate();
    });

    // if we need to load from BE
    const value = this.opt.ListItemPairs;
    if (value && value[0] == '$') {

      LoadDataHelper.loadData(value, oSelf, data => {
        const aVals = [];
        for (let iR = 0; iR < data.length; iR++) {
          const oEntry = {};

          oEntry.value = data[iR].selfkey;
          oEntry.text = data[iR].selfdesc;
          aVals.push(oEntry);
        }
        oCombo.load({ options: aVals });

      });
      // load keys from value
    } else if (this.opt.dynCombo) {

      // load and store the dynCombo entries to localStorage
      const loadComboEntries = function() {
        LoadDataHelper.loadData(value, oSelf, () => {
          if (localStorage) {
            // check if combo is available
            const cData = localStorage.getItem(`ak_combo_${oSelf.opt.comboName}`);

            if (cData && app.sessionData.useComboCache)
              // changed for dhtmlx4: oCombo.loadXMLString(
              // cData );
              oCombo.load(cData);
            else {

              const oLoader = dhtmlxAjax.postSync('/akioma/getCombo.xml', `Type=getCombo&name=${oSelf.opt.name}&comboname=${oSelf.opt.comboName}`);

              localStorage.setItem(`ak_combo_${oSelf.opt.comboName}`, oLoader.xmlDoc.responseText);
              oCombo.load(oLoader.xmlDoc.responseText);
            }
          } else
            oCombo.load(`/akioma/getCombo.xml?Type=getCombo&name=${oSelf.opt.comboName}`);
        });
      };
        // load combo values
      loadComboEntries();

      // add combobox refresh entries menu
      const oReloadMenu = new dhtmlXMenuObject({
        parent: oCombo.DOMParent,
        context: true
      });

      oReloadMenu.addNewChild(oReloadMenu.topId, 1, 'clear', 'Clear cache', false);
      oReloadMenu.addNewChild(oReloadMenu.topId, 2, 'reload', 'Refresh', false);
      oReloadMenu.attachEvent('onClick', id => {
        if (id == 'clear')
          localStorage.removeItem(`ak_combo_${oSelf.opt.comboName}`); // remove entry from localstorage
        else if (id == 'reload') {
          localStorage.removeItem(`ak_combo_${oSelf.opt.comboName}`);
          oCombo.clearAll();
          loadComboEntries();
        }
      });
    }

    if (this.opt.visible == false)
      this.form.hideItem (this.opt.name, true);

    this.domTag = $(oComboSelector).find('.dhxcombo_input')[0];
    this._setTabIndex(this.domTag);
  },

  endConstruct: function() {
    if (this.opt.visible == false)
      this.form.hideItem (this.opt.name);
  },

  // get value *****************
  /**
     * This Method returns the Combo value or Combo text
     * @param  {String} cType If 'desc', will return the Combo text. If not specified, will return the Combo value.
     * @return {String} The value of the Combo
     * @instance
     * @memberOf ak_combobox
     */
  getValue: function(cType) {
    if (cType == 'desc')
      return this.dhx.getComboText();
    else
      return this.form.getItemValue(this.opt.name);
  },

  // set value ********************
  setValue: function(cValue) {
    this.form.setItemValue(this.opt.name, cValue);
    this.eventValidate();
  },

  // set options ********************
  setOptions: function(aOptions) {

    if (aOptions instanceof Array) {
      const oCombo = this.form.getCombo(this.opt.name);

      oCombo && oCombo.addOption(aOptions);

      if (oCombo && aOptions[0] instanceof Array)
        oCombo.selectOption(0, true, true);
    }
  },

  // event leave *******************
  eventLeave: function() {

    // check if we have to call the create event
    if (this.opt.leaveEvent) {
      /**
         * Client side code executed when a field looses focus.
         * @event ak_combobox#EventLeave
         */
      app.controller.callAkiomaCode(this, this.opt.leaveEvent);
    }

    const oCombo = this.dhx,
      $itemcont = $(oCombo.DOMParent).parent().parent();
    $itemcont.removeClass('focusedinp');
  },
  eventFocus: function() {
    const formDiv = akioma.getForm(this).dhx.cont.children[0];
    $(formDiv).children().removeClass('focusedinp');
    const oCombo = this.dhx;
    const $itemcont = $(oCombo.DOMParent).parent().parent();
    $itemcont.addClass('active').addClass('focusedinp');
  },
  eventValidate: function() {
    if (this.opt.validateEvent)
      app.controller.callAkiomaCode(this, this.opt.validateEvent);
  }
});
