(function($) {

  // ***************** datasource ******************
  $.extend({
    ak_datasource: function(options) {
      const defaults = { page: 0 };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;

      this.registerDynObject = true;

      this.observers = new Array();
      this.init = false;
      this.prop = {
        update: false,
        bind: false
      };
      this.serverProp = { srvrProp: [] };

      this._eventsAfterFillOnce = [];

      if (this.opt.ObjectInstanceGuid)
        app.controller.aDataSources[this.opt.ObjectInstanceGuid] = this;

      // check if we have to link it
      this.urlQuery = {
        extKey: this.opt.extKey,
        extLink: this.opt.extLink,
        grid: '',
        recMode: '',
        recSibling: 0,
        recParent: '',
        recType: '',
        filterFields: ''
      };


      this.blockCont = this.getAncestor('frame,popup,window');
    }
  });

  // methods for datasource
  $.ak_datasource.prototype = {

    // finish construct ************
    finishConstruct: function() {
      const oSelf = this;

      if (this.opt.serverProp) {
        const aProp = this.opt.serverProp.split('|');
        for (const i in aProp) {
          const aVal = aProp[i].split('#');
          this.setSrvProp(aVal[0], aVal[1]);
        }
      }

      // if we are a primary sdo -> link it with frame
      if (this.opt.primarySDO) {
        // check for frame
        const oFrame = this.parent;
        if (oFrame && oFrame.view == 'frame') {
          const cObjName = oFrame.getObjName('DATA', 'TRG');
          const aSource = app.messenger.getObjects(this.dynObject, `DATA:SRC:${cObjName}`);
          if (aSource && aSource.length > 0)
            aSource[0].controller.addObserver(this);

          // now check if parent of frame is a frame -> exchange reference for foreignkey
          if (oFrame.parent.view == 'frame') {
            // if foreignkey is the same
            if (oFrame.parent.opt.foreignKey == this.opt.foreignKey)
              this.opt.extKey = oFrame.parent.opt.extKey;
          }
        }
      }

      // check if we have a forign key -> set dataFeed
      if (this.dataSource && this.opt.foreignKey) {

        // create global datastore
        this.dhx = new dhtmlXDataStore({ datatype: 'json' });

        // bind data for datasource
        switch (this.dataSource.view) {
          case 'datasource':
            this.dhx.bind(this.dataSource.dhx, () => true);
            this.prop.bind = true;
            this.openQuery({});
            break;

          case 'treegrid':
            if (this.dataSource.opt.posFrameHandling)
              break; /* treeGrid handles details panel on his own */
            this.dhx.bind(this.dataSource.dhx, (obj, filter) => {
              if (obj) {
                filter.foreignKey = oSelf.opt.foreignKey;
                filter[oSelf.opt.foreignKey] = obj.id;
              }
            });

            // open query for the first time to start sync mode
            this.openQuery({});
            break;
        }

        // otherwise set url
      } else
        this.dhx = new dhtmlXDataStore({ datatype: 'json' });


      this.dhx.attachEvent('onAfterCursorChange', () => {
        oSelf.setTitle();

        const cObjectID = oSelf.opt.name + oSelf.opt.linkid;

        // tell all observers that cursor has been changed
        app.messenger.publish({
          link: {
            LinkName: 'DISPLAY',
            LinkType: 'TRG',
            ObjName: cObjectID
          },
          method: 'cursorChange',
          caller: oSelf
        });
        // tell all observers that cursor has been changed
        app.messenger.publish({
          link: {
            LinkName: 'DATA',
            LinkType: 'TRG',
            ObjName: cObjectID
          },
          method: 'dataAvailable',
          caller: oSelf
        });

        // event onDataAvail
        if (oSelf.opt.onDataAvail) {
          dhtmlx.delay(() => {
            applyAkiomaCode(oSelf, oSelf.opt.onDataAvail);
          });
        }

      });

      this.dhx.attachEvent('onXLS', function() {
        // jquery fire error
        // $( oSelf.blockCont.dhx ).block( { message: "Load data ...", timeout: app.blockTime } );
        // console.log("show loader message here, #118373")
        if (oSelf.lastId == null)
          this.clearAll();
      });

      this.dhx.attachEvent('onXLE', function() {
        if (this.dataCount() > 0)
          this.setCursor(this.first());
        else
          this.refresh();


        oSelf.blockCont.dhx.progressOff();
      });

      this.dhx.data.scheme({
        $init: function() {
          oSelf.prop.update = false;
        },
        $update: function() {
          oSelf.prop.update = true;
        }
      });

      this.dp = new dataProcessor(app.trim(`${this.opt.url}&datatype=store&action=write&context=${this.opt.id}&${$.param(this.serverProp)}`, '&'));
      this.dp.setTransactionMode('POST', true);
      this.dp.setUpdateMode('off');
      this.dp.init(this.dhx);
      this.lastId = null;

      this.dp.attachEvent('onBeforeUpdate', (rid, status) => {
        oSelf.beforeUpdate(rid, status);
        return true;
      });
      this.dp.attachEvent('onAfterUpdate', (sid, action, tid, xml_node) => oSelf.afterUpdate(sid, action, tid, xml_node));
      this.dp.attachEvent('onAfterUpdateFinish', () => {
        oSelf.dhx.refresh();
        oSelf.blockCont.dhx.progressOff();
      });

      this.dp.defineAction('delete', () => true);

      if (this.bQueryOnHold) {
        delete this.bQueryOnHold;
        this.openQuery({});
      }
    },

    endConstruct: function() {
      // check for grid
      for (const i in this.observers) {
        if (this.observers[i].view == 'datagrid') {
          this.urlQuery.grid = this.observers[i].opt.name;
          break;
        }
      }
    },
    addAfterFillOnceCallback: function(fn) {
      this._eventsAfterFillOnce.push(fn);
    },
    dataAvailable: function() {
      this.openQuery({});
    },

    cursorChange: function() {
      this.openQuery({});
    },

    // record description
    getRecordDescription: function(cTemplate) {
      const oSelf = this;

      if (!cTemplate)
        return null;
      let result = '';
      const template = Handlebars.compile(cTemplate);
      if (oSelf.dhx) {
        const oData = oSelf.dhx.item(oSelf.dhx.getCursor());


        if (!$.isEmptyObject(oData))
          result = template(oData);

      }


      return result;
    },

    setTitle: function() {
      // if we are primarySDO -> change title
      if (this.opt.primarySDO) {


        // try to get key
        let cKey = this.getFieldValue('selfno');
        let cDesc = this.getFieldValue('selfdesc');
        if (!cKey)
          cKey = this.getFieldValue('selfkey');

        if (!cKey)
          cKey = this.getFieldValue('artikel-nr');

        if (!cDesc)
          cDesc = this.getFieldValue('fulldesc');


        // set tile in window
        const oWindow = this.getAncestor('window,frame');
        if (oWindow && oWindow.view == 'window') {
          oWindow.setTitle(`${cKey} - ${cDesc}`);

          // check security
          const cProp = this.getFieldValue('rowuserprop');
          oWindow.callInChildren('setProperty', { security: { readOnly: (cProp == 'READ-ONLY') } });

        }
      }
    },

    // add observer ****************
    addObserver: function(observer) {

      // just add observer to list
      if ($.inArray(observer, this.observers) == -1) {
        this.observers.push(observer);

        // and set datasource
        observer.dataSource = this;

      }
    },

    // get url query
    getUrlQuery: function() {
      const query = this.urlQuery;
      return query;
    },

    // get foreign key
    getForeignKey: function() {

      if (this.dataSource && this.opt.foreignKey) {
        const cRefHdl = this.dataSource.getIndex();
        if (cRefHdl) {
          return {
            name: this.opt.foreignKey,
            value: this.dataSource.getFieldValue(this.opt.extKey)
          };
        }
      }

      return {
        name: '',
        value: ''
      };
    },

    // open query ****************
    openQuery: function(oElm) {
      const oSelf = this,
        oData = this.dhx;

      if (!oElm)
        oElm = {};

      if (oSelf.stop)
        return false;


      if (oData == undefined) {
        this.bQueryOnHold = true;
        return;
      }

      // clear datasource


      if (oSelf.bClear == undefined || oSelf.bClear && oSelf.lastId != undefined)
        oData.clearAll();
      // stop if already selected node
      else if (!oSelf.bClear)
        return;
      // copy urlquery
      const oPar = $.extend({}, this.getUrlQuery(), this.serverProp);

      // check for specials
      if (this.dataSource && oElm.foreignKey) {
        oPar.foreignKey = this.opt.foreignKey;
        oPar[this.opt.foreignKey] = oElm.foreignKey;
      } else if (this.dataSource && this.opt.foreignKey) {
        const cRefHdl = this.dataSource.getIndex();
        if (cRefHdl) {
          oPar.foreignKey = this.opt.foreignKey;
          oPar[this.opt.foreignKey] = this.dataSource.getFieldValue(this.opt.extKey);
        }
      }

      if (oElm.extKey)
        oPar.extKey = oElm.extKey;
      if (oElm.extLink)
        oPar.extLink = oElm.extLink;
      if (oElm.sort) {
        oPar.sortField = oElm.sort;
        oPar.sortDir = oElm.dir;

        // put sort conditions in serverprop to resend it
        this.serverProp.sortField = oElm.sort;
        this.serverProp.sortDir = oElm.dir;
      }

      // if switch is on -> tell server not to use a default filter
      if (oElm.filterGo)
        oPar.noDefFilter = true;

      // check for mode -> add mode elements to parameter
      if (oElm.mode)
        $.extend(oPar, oElm.mode, oPar);

      // set url
      const url = app.trim(`${this.opt.url}&datatype=store&context=${this.opt.id}&${$.param(oPar)}`, '&');
      oData.dataFeed = url; // changed by cd

      // reset bind mechanism
      oData.data.detachEvent('onBeforeFilter');
      oData.data.feed = undefined;
      oData.dataFeed_setter(true);

      // !_isIE && console.log( [ "open query", this.prop.bind ] );

      if (this.prop.bind && this.dhx.e && (typeof oElm.bind == 'undefined' || oElm.bind == true)) {
        // get target datastore element


        // e.id can be undefined (line below)


        const key = this.dhx.e.id, // e = settings
          oTarget = dhx.ui.get(key);

        // reset bind in target
        oTarget.ia[key] = false;// ia = _bind_settings
        this.dhx.callEvent('onBindRequest');

        // reset binding for second query (filter)
        this.prop.bind = false;

      } else {
        try {

          const currentCell = this.blockCont.getDescendant('panel');
          if (currentCell.dhx.progressOn)
            currentCell.dhx.progressOn();

          let bChooseFileG;
          try { // check if chooseFileG to fix databinding issue with dataview
            bChooseFileG = (oSelf.dynObject.container && oSelf.dynObject.container.controller.opt.name == 'chooseFileG');
          } catch (e) {
            akioma.log.warn(e);
          }

          oData.load(url, () => {

            if (bChooseFileG && oData.dataCount() == 0 && oData.lastId == undefined && oSelf.observers[0] && oSelf.observers[0].view == 'dataview')
              oData.parse({ 'total_count': 0, 'pos': 0, 'data': [{ 'fix': '' }] });
            oSelf.lastId = oData.first();


            if (oData.dataCount() > 0)
              oData.setCursor && oData.setCursor(oData.first());

            // no record
            else {

              const cObjectID = oSelf.opt.name;

              // tell all observers to clear record
              app.messenger.publish({
                link: {
                  LinkName: 'DISPLAY',
                  LinkType: 'TRG',
                  ObjName: cObjectID
                },
                method: 'clearRecord',
                caller: oSelf
              });
            }

            for (const x in oSelf._eventsAfterFillOnce)
              oSelf._eventsAfterFillOnce[x]();

            oSelf._eventsAfterFillOnce = [];
            currentCell.dhx.progressOff();
          });
        } catch (e) {
          akioma.message({ type: 'alert-error', title: 'Error loading data', text: e.message });
        }
      }
    },

    // relaod data from server
    reloadData: function() {
      this.openQuery({ bind: false });
    },

    // update record **************
    updateRecord: function(oElm) {
      const oSelf = this,
        cId = this.dhx.getCursor();

      // set user fields
      this.dp.serverProcessor = `${this.opt.url}&datatype=store&action=write&context=${this.opt.id}`;
      if (cId && oElm.mode)
        this.dp.serverProcessor += `&${[ `recSibling=${oElm.mode.recSibling}`, `recParent=${oElm.mode.recParent}`, `recType=${oElm.mode.recType}` ].join('&')}`;
      this.dp.serverProcessor += `&${$.param(this.serverProp)}`;
      this.dp.serverProcessor = app.trim(this.dp.serverProcessor, '&');

      // save forms in batch mode
      this.dhx.saveBatch(() => {

        const cObjectID = oSelf.opt.name + oSelf.opt.linkid;

        // tell all observers to update data in source
        app.messenger.publish({
          link: {
            LinkName: 'DISPLAY',
            LinkType: 'TRG',
            ObjName: cObjectID
          },
          method: 'submitRow',
          caller: oSelf
        });

        // check if updates are available
        if (oSelf.dp.getSyncState())
          return;


        oSelf.dp.sendData();

      });
    },

    // add record ***************
    addRecord: function(oElm) {
      const oSelf = this;

      // copy urlquery
      const oPar = $.extend({}, this.getUrlQuery(), this.serverProp);

      // check for specials
      if (this.dataSource && this.opt.foreignKey) {
        const cRefHdl = this.dataSource.getIndex();
        if (cRefHdl) {
          oPar.foreignKey = this.opt.foreignKey;
          oPar[this.opt.foreignKey] = cRefHdl;
        }
      }
      if (oElm.extKey)
        oPar.extKey = oElm.extKey;

      // check for mode
      if (oElm.mode)
        $.extend(oPar, oElm.mode, oPar);

      // minimum set mode to "add"
      oPar.recMode = 'add';
      oPar.dataType = 'store';
      oPar.context = this.opt.id;

      $.ajax({
        async: false,
        type: 'POST',
        url: `/akioma${oSelf.opt.url}`,
        dataType: 'json',
        data: oPar,
        contentType: 'application/x-www-form-urlencoded',
        success: function(data) {
          try {
            // add record
            if (data && data.data && data.data.length > 0) {

              // import data
              const iIndex = oSelf.dhx.add(data.data[0]);

              // now find index
              if (iIndex) {
                oSelf.dhx.setCursor(iIndex);

                // check for tree
                if (oSelf.dataSource && oSelf.dataSource.view == 'treegrid') {
                  const oTree = oSelf.dataSource;
                  oSelf.dhx.item(iIndex).refhdl = oTree.getIndex();
                }
              }
            } else
              oSelf.dhx.setCursor('');
          } catch (e) {
            oSelf.impdata = data;
            akioma.message({ type: 'alert-error', title: 'Add record', text: `Error reading data for ${oSelf.opt.SDO}:<br />${e.message}` });
          } finally {
            oSelf.blockCont.dhx.progressOff();
          }
        },
        error: function(xhr, textStatus, errorThrown) {
          oSelf.blockCont.dhx.progressOff();
          akioma.log.error(`Error getting data from ${oSelf.opt.SDO}: ${textStatus} -> ${errorThrown}`);
        }
      });
    },

    // find data ***************
    findData: function(oElm) {
      const oData = this.dhx.data.pull;

      for (const i in oData) {
        if (oData[i][oElm.field] == oElm.value)
          return Number(i);
      }
      return false;
    },

    // set changed ***************
    setChanged: function(lUpdate, cType) {
      const iCursor = this.dhx.getCursor();

      if (iCursor)
        this.dp.setUpdated(iCursor, lUpdate, cType);
    },

    // undo record **************
    undoRecord: function() {
      this.opt.recMode = '';
      this.opt.recSibling = '';
      this.opt.recParent = '';
      this.opt.recType = '';

      this.data.undoRecord();

      this.resetRecord();
    },

    // reset record ************
    resetRecord: function() {

      const cObjectID = this.opt.name + this.opt.linkid;

      // tell all observers to reset record
      app.messenger.publish({
        link: {
          LinkName: 'DISPLAY',
          LinkType: 'TRG',
          ObjName: cObjectID
        },
        method: 'dataAvailable',
        caller: this
      });
    },

    // get field value ************
    getFieldValue: function(cFieldName, id) {
      let iAct;

      if (id)
        iAct = id;
      else
        iAct = this.dhx.getCursor();
      // check if field exists
      const oItem = this.dhx.item(iAct);
      if (oItem)
        return oItem[cFieldName];


      return null;
    },

    // set field value ************
    setFieldValue: function(oElm) {
      const id = (oElm.index) ? oElm.index : this.dhx.getCursor();
      if (id) {
        const oItem = this.dhx.item(id);
        oItem[oElm.name] = oElm.value;
        if (oElm.state) {
          oItem.rowmod = oElm.state.substr(0, 1);
          this.dp.setUpdated(id, true, oElm.state);
        }
      }
      return true;
    },

    // get indexes ************
    getIndex: function() {
      const id = this.dhx.getCursor();
      if (id)
        return this.dhx.item(id)[this.opt.identifier];
      else
        return '';
    },

    // set index ***********
    setIndex: function(value) {
      const oSelf = this,
        oData = this.dhx;

      let iIndex = -1;
      if (typeof value == 'string') {
        iIndex = this.dhx.data.each(data => {
          if (data[oSelf.opt.identifier] == value)
            return data.id;
        });
      } else
        iIndex = value;

      // get queryposition and recordstate
      let cState = 'NoRecordAvailable';
      if (iIndex) { // check if rownum is first or last
        switch (iIndex) {
          case this.dhx.first():
            cState = 'FirstRecord';
            break;
          case this.dhx.last():
            cState = 'LastRecord';
            break;
          default:
            cState = 'NotFirstOrLast';
            break;
        }
        this.prop.recordState = 'RecordAvailable';
      } else
        this.prop.recordState = cState;

      this.prop.queryPosition = cState;

      // publish queryposition
      app.messenger.publish({
        link: {
          LinkName: 'NAVIGATION',
          LinkType: 'SRC',
          ObjName: this.getObjName('NAVIGATION', 'TRG')
        },
        method: 'queryPosition',
        state: cState,
        caller: this
      });

      // check if value has been changed
      if (oData.getCursor && oData.getCursor() != iIndex)
        oData.setCursor(iIndex);

    },

    // set filter ************
    setFilter: function(oFilter) {
      let aFieldList = (this.urlQuery.filterFields) ? this.urlQuery.filterFields.split(',') : [];

      // delete all filters before
      for (const i in aFieldList)
        delete this.urlQuery[aFieldList[i]];
      aFieldList = [];

      // go through array
      for (const cField in oFilter) {
        // fill filters
        if (oFilter[cField]) {
          aFieldList.push(cField);
          this.urlQuery[cField] = oFilter[cField];
        }
      }

      // set filterfields
      this.urlQuery.filterFields = aFieldList.join(',');

      // detach filter event -> function with url is loaded persistent
      this.dhx.data.detachEvent('onBeforeFilter');
      this.dhx.data.feed = undefined;
      this.dhx.dataFeed_setter(true);
    },

    // fetch **************
    fetch: function(oElm) {
      if (this.dhx.dataCount() < 1)
        return;

      const oData = this.dhx,
        iNumRec = oData.dataCount();
      let iSelected = oData.getCursor();
      const iOldInd = iSelected;

      let cMethod;
      try {
        cMethod = oElm.direction;
      } catch (e) {
        cMethod = 'Next';
      }

      // if we only have one record -> always load
      let lLoad = true;
      if (iNumRec == 1)
        lLoad = true;

      // try to get inside of data
      else if (iSelected) {

        switch (cMethod) {
          case 'First':
            iSelected = oData.first();
            break;
          case 'Prev':
            iSelected = oData.previous(iSelected);
            break;
          case 'Next':
            iSelected = oData.next(iSelected);
            break;
          case 'Last':
            iSelected = oData.last();
            break;
          default:
            !_isIE && console.error([ `Invalid direction for fetch: ${cMethod}`, this ]);
            break;
        }
        if (iSelected) {
          lLoad = false;
          if (iSelected)
            oData.setCursor(iSelected);
        }
      }

      if (lLoad && iOldInd) {

        // get identifier
        const cSelected = oData.item(iOldInd)[this.opt.identifier];

        // set parameter for read
        this.urlQuery.fetch = cMethod;
        this.urlQuery.selfHdl = cSelected;

        // now get data
        this.openQuery({});
      }
    },

    // before update
    beforeUpdate: function() {

      const cObjectID = this.opt.name + this.opt.linkid;
      // check for translation field
      app.messenger.publish({
        link: {
          LinkName: 'DISPLAY',
          LinkType: 'TRG',
          ObjName: cObjectID
        },
        method: 'saveTranslat',
        caller: this
      });

    },

    // after update of dp
    afterUpdate: function(sid, action, tid, oXML) {
      const oSelf = this;
      tid = Number(tid);

      if (!this.dhx)
        return false;

      // if message -> error occured
      if ($(oXML).attr('message')) {
        const cValue = $(oXML).text();
        akioma.showServerMessage(cValue);

        if (oSelf.dataSource && oSelf.dataSource.finishUpdate)
          oSelf.dataSource.finishUpdate(oSelf, false);


        return false;
      }

      // check if item is still available
      const oItem = this.dhx.item(tid);
      if (oItem) {
        const cChanged = (action == 'inserted') ? '*' : $(oXML).find('record > changedfields').text().toLowerCase();
        // get record and update fields
        if (cChanged) {
          $(oXML)
            .find(`record > ${cChanged}`)
            .filter(function() {
              return ($.inArray(this.nodeName, [ 'changedfields', 'rownum', 'rowmod' ]) > -1) ? false : true;
            })
            .each(function() {
              const cField = this.nodeName;
              const cValue = $(this).text();
              oItem[cField] = cValue;
            });
        }

        // reset rowmod
        oItem.rowmod = '';

        const cObjectID = oSelf.opt.name + oSelf.opt.linkid;

        // now check for translation field
        app.messenger.publish({
          link: {
            LinkName: 'DISPLAY',
            LinkType: 'TRG',
            ObjName: cObjectID
          },
          method: 'saveTranslat',
          caller: oSelf
        });

        // set new title in window
        oSelf.setTitle();
      }

      // check if we have to finish the update
      if (oSelf.dataSource && oSelf.dataSource.finishUpdate) {
        const cRef = $(oXML).find('record > refhdl').text();
        oSelf.dataSource.finishUpdate(oSelf, cRef);
      }

      // reset sync state
      this.dp.setUpdated(tid, false);

      return true;
    },

    getSrvProp: function(cName) {
      if (this.serverProp[cName])
        return this.serverProp[cName];
      else
        return '';
    },

    setSrvProp: function(cName, cValue) {

      if ($.inArray(cName, this.serverProp.srvrProp) == -1)
        this.serverProp.srvrProp.push(cName);

      if (cValue.substr(0, 1) == '$') {
        let oSelf, self, cCode;
        try {
          // get variable for dynObject
          oSelf = this;
          // required in eval context
          // eslint-disable-next-line no-unused-vars
          self = oSelf.dynObject;
          cCode = cValue.substr(1);
          this.serverProp[cName] = eval(cCode);
        } catch (e) {
          !_isIE && console.error([ 'Error executing akioma code', oSelf, cCode, e ]);
          if (akioma.ignoreClientLogicErrors)
            akioma.log.error(`client-logic error: ${cCode}`);
          else
            akioma.message({ type: 'alert-error', title: 'Error executing akioma code', text: `${cCode}<br />${e.message}` });
        }
      } else
        this.serverProp[cName] = cValue;
    },

    delSrvProp: function(cName) {
      if (this.serverProp[cName]) {
        delete this.serverProp[cName];
        $.removeEntry(this.serverProp.srvrProp, cName);
      }
    },


    destroy: function() {

      this.dp = null;

      this.dhx = null;

      this.serverProp = null;
      this.urlQuery = null;
      this.observers = null;
    }
  };

})(jQuery, jQuery);
