/**
 * Http Client Service for ElasticSearch REST API
 * @class ElasticSearchHttpClient
 * @namespace akioma
 */
class ElasticSearchHttpClient {
  constructor(options = {}) {
    this._client = $.ajax;
    this._baseUrl = options.baseUrl || '';
    this._index = options.index;
  }

  /**
   * Method for searching on the ElasticSearch API
   * @param {object} query
   * @returns {Deferred}
   */
  search(query) {
    const url = `${this._baseUrl}/${this._index}/_search`;
    akioma.HttpClient.ignoreErrors(url);
    const requestPayload = {
      url,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      processData: false,
      data: JSON.stringify(query)
    };

    const fillPromise = this._client(requestPayload);
    return fillPromise;
  }
}

akioma.ElasticSearchHttpClient = ElasticSearchHttpClient;
