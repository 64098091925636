(function($) {

  // ***************** gantt ******************
  $.extend({
    /**
     * SwatGantt Control
     * @class ak_gantt
     * @tutorial gantt-desc
     * @param {Object} options Repository attributes for SwatGantt.
     * @param {string} options.BorderTitle The Title of a dynamic Viewer or Browser
     * @param {string} options.EventOnInitialize client side code to run when Container has been initialized
     * @param {string} options.contextMenu the id of a menuStructure which will be used as a context-menu
     * @param {string} options.titleHeader specifies which panelHeader to use. when empty, then uses the header of its own panel. if "none" then no header at all. if "parent" it uses the header of the parent panel
     * @param {string} options.floatingActionButton the id of a menustructure, which will be rendered as a FAB
     * @param {string} options.LayoutOptions List of multi-layout options for the object.
     * @param {string} options.panelMenu comma separated list of menu-structures which will be shown as panelHeader-Buttons. </br>
     * Can also contain the flag #NoDropDown that specifies that the menu should not be loaded as a dropdown but each menu item should be added in the panel-Header.</br>
     * For example: </br>
     * <code>menuStructSave,menuSettings#NoDropDown,menuLookup</code> </br>
      * The buttons support font icons with the following attributes: </br>
    * 1. Css attributes, defined like this: fa fa-user#color:red </br>
            * 2. Css classes, defined like this: fa fa-user#_style:module_prod
            * 3. Stacked font icons, defined like this: fas fa-circle$fas fa-flag. Both icons also support Css attributes or Css classes, like this: fas fa-circle#color:red$fas fa-flag#_style:module_prod </br>
    * @param {string} options.MapName Name fields (comma separated) for showing a name in google maps
    * @param {string} options.MapAddress Address fields for GoogleMap visualization
    */

    ak_gantt: function(options) {
      const defaults = { page: 0 };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;

      this.registerDynObject = true;

      // get parent
      const oParent = this.parent.dhx;
      if (oParent) {
        // -> bind gantt to layout
        $('#content').append('<div style="width:450px;height:420px;position:absolute" id="GanttDiv"></div>');

        // currently ignored as the control itself should be checked if it's still working
        // eslint-disable-next-line no-undef
        const ogantt = new GanttChart();
        ogantt.setImagePath('codebase/imgs/');
        ogantt.setEditable(true);
        ogantt.create('GanttDiv');
        oParent.attachObject('GanttDiv');

        $.extend(this, { dhx: ogantt });
      } else
        !_isIE && console.error('No valid parent for gantt ');
    }
  });

  $.ak_gantt.prototype = {
    cursorChange: function() { },
    destroy: function() {}
  };
})(jQuery, jQuery);
