// ************************* menubarseparator ********************************
$.extend({
  ak_menubarseparator: function(options) {
    const defaults = { id: '' };

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;

    // get new element
    const cParent = this.parent.opt.id;

    // get menubar
    const oMenubar = this.parent.dhx;
    if (oMenubar) {
      if (this.parent.view == 'menubar')
        oMenubar.addNewSeparator(cParent, `Sep${this.opt.id}`);
      else {
        const iPos = this.parent.opt.pos + 1;
        this.parent.opt.pos = iPos;
        oMenubar.addNewSeparator(cParent, `Sep${iPos}`);
      }

    } else
      !_isIE && console.error(`No valid parent for menuseparator ${this.opt.id}`);

  }
});
