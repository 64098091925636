window.dhx4.template.printTokenEntry = function(value, key) {
  let sResult = '';
  if (value)
    sResult = `<div class="token-entry">${value}<a class="close" data-key="${key}" href="#"></a></div>`;

  return sResult;
};
dhtmlXForm.prototype.items.tokenselect = {
  render: function(item, data) {
    item._type = 'tokenselect';

    const oSelf = $.getObjectByName({ id: data.userdata.id });
    item._self = oSelf;
    this.doAddLabel(item, data);

    const html = '<div class="dhxform_control" style="left:#left#;top:#top#;">' +
                  '<div class="akTokenSelectItems"></div>' +
                  '<a href="#" class="akTokenSelectBtn">' +
                      '<img src="/imgs/akioma/activity_feed-26.png" height="14" width="14" />' +
                  '</a>' +
                '</div>';
    const values = {
      left: oSelf.opt.left,
      top: oSelf.opt.top
    };

    const template = window.dhx4.template(html, values);
    $(template).appendTo(item)
      .find('a').on('click', () => {
        oSelf.eventClick();
        return false;
      })
      .end()
      .on('click', '.close', function() {
        const sKey = $(this).attr('data-key'),
          aKeysSelected = item._value.split(' '),
          index = aKeysSelected.indexOf(sKey);

        aKeysSelected.splice(index, 1);
        item._value = aKeysSelected.join(' ');

        const oForm = akioma.getForm(oSelf);
        const oField = item._self;

        oForm.setFieldHasChanges(oField.opt.name, true);


        $(this).parent().remove();

        return false;
      });


    item.akElm = oSelf;
    oSelf.dhx = item;

    return this;
  },
  setValue: function(item, value) {
    try {
      if (value != '') {
        const aKeys = value.split(' ');
        let htmlEntries = '';
        for (const i in aKeys) {
          const html = `#value|printTokenEntry:${aKeys[i]}#`;
          const values = { value: item._keyvalues[aKeys[i]] };
          const itemHTML = window.dhx4.template(html, values);
          htmlEntries += itemHTML;
        }

        $(item).find('.akTokenSelectItems').empty().append(htmlEntries);
        item._value = aKeys.join(' ');
      } else {
        $(item).find('.akTokenSelectItems').empty();
        item._value = '';
      }
    } catch (e) {
      akioma.log.error('invalid value', e);
    }
  },
  getValue: function(item) {
    return item._value;
  },
  getTokenSelect: function(item) {
    return item;
  },
  setKeyValues: function(item, value) {
    const oSelf = this;


    // if we need to load from BE
    if (value) {
      if (value[0] == '$') {

        LoadDataHelper.loadData(value, item.akElm, data => {
          const aVals = [];
          for (let iR = 0; iR < data.length; iR++)
            aVals[data[iR].selfkey] = data[iR].selfdesc;

          if (value[0] == '$') {
            item._keyvalues = aVals;
            oSelf.setValue(item, item._value);
          }

        });
        // load keys from value
      } else {
        LoadDataHelper.loadData(value, item.akElm, () => {

          const aVals = value.split(',');
          if (item._keyvalues == undefined)
            item._keyvalues = [];
          for (let i = 0; i < aVals.length; i += 2)
            item._keyvalues[aVals[i + 1]] = aVals[i];

        });
      }
    }


  },
  getKeyValues: function(item) {
    return item._keyvalues;
  }
};
(function() {
  for (const a in { doAddLabel: 1, doAddInput: 1, destruct: 1, doUnloadNestedLists: 1, setText: 1, getText: 1, enable: 1, disable: 1, setWidth: 1 })
    dhtmlXForm.prototype.items.tokenselect[a] = dhtmlXForm.prototype.items.input[a];
})();
dhtmlXForm.prototype.setKeyValues = function(name, value) {
  this.doWithItem(name, 'setKeyValues', value);
};
dhtmlXForm.prototype.getKeyValues = function(name) {
  return this.doWithItem(name, 'getKeyValues');
};
dhtmlXForm.prototype.validateFormField = function(name) {
  return this.doWithItem(name, '_validate');
};
dhtmlXForm.prototype.getTokenSelect = function(name) {
  return this.doWithItem(name, 'getTokenSelect');
};
dhtmlXForm.prototype.setFormData_tokenselect = function(name, value) {
  return this.doWithItem(name, 'setValue', value);
};
dhtmlXForm.prototype.getFormData_tokenselect = function(name) {
  return this.doWithItem(name, 'getValue');
};

(function($) {

  // ********************* token select ********************
  $.extend({
    ak_tokenselect: function(options) {
      const defaults = {};

      akioma.BaseFormDataField.call(this, options);

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;

      this.useParentDynObjectLink = true;
      this.registerVuexWatcher = true;
      this.registerDynObject = true;

      // get parent
      const oParent = this.parent;
      if (oParent) {

        oParent.prop.fields.push({
          type: 'tokenselect',
          inputTop: parseInt(this.opt.top),
          inputLeft: parseInt(this.opt.left),
          inputWidth: parseInt(this.opt.width),
          label: this.opt.label,
          labelTop: parseInt(this.opt.top),
          labelLeft: oParent.labelLeft(this),
          name: this.opt.name,
          readonly: this.opt.readonly,
          mandatory: this.opt.required,
          disabled: this.opt.disabled,
          enabled: !this.opt.disabled,
          hasError: false,
          errorMsg: '',
          className: 'w4-formField aktokenselect',
          position: 'label-top',
          userdata: { id: this.opt.id }
        });

        // append to elements in form
        if (this.parent.view == 'form')
          this.parent.elements.push(this);

        // oInput.akElm = this;
        $.extend(this, { security: {} });
      }
    }
  });

  // methods for tokenselect
  Object.assign($.ak_tokenselect.prototype, akioma.BaseFormDataField.prototype, {
    componentOptions: function() {
      const oSelf = this;
      return {
        watch: {
          'getters.getFormFieldState': {
            fn: function(newValue, oldValue) {
              oSelf._hasFormFieldChangesWatcher(newValue, oldValue);
            },
            params: [this.opt.id]
          },
          'getters.getFormFieldEnabled': {
            fn: function(bEnabled) {
              oSelf._enabledFormFieldWatcher(bEnabled);
            },
            params: [this.opt.id]
          },
          'getters.getFormFieldMandatory': {
            fn: function(bMandatory) {
              oSelf._mandatoryFormFieldWatcher(bMandatory);
            },
            params: [this.opt.id]
          },
          'getters.getFormFieldError': {
            fn: function(newValue, oldValue) {
              oSelf._errorFormFieldWatcher(newValue, oldValue);
            },
            params: [this.opt.id]
          },
          'getters.getFormFieldErrorMsg': {
            fn: function(cErrorMsg) {
              oSelf._errorFormFieldMsgWatcher(cErrorMsg);
            },
            params: [this.opt.id]
          }
        }
      };
    },
    _enabledFormFieldWatcher: function(bEnabled) {
      const oSelf = this;
      const oForm = akioma.getForm(oSelf);
      const cName = oSelf.opt.name;

      if (oForm.dhx) {
        if (bEnabled)
          oForm.setFormFieldReadonly(cName, false);
          // oForm.dhx.enableItem(cName);
        else {
          // oForm.dhx.disableItem(cName);
          oForm.setFormFieldReadonly(cName, true);
        }
      }


    },
    _mandatoryFormFieldWatcher: function(bMandatory) {
      const oSelf = this;
      const oForm = akioma.getForm(oSelf);
      const cName = oSelf.opt.name;

      if (oForm && oForm.dhx) {
        if (typeof (oSelf.setRequired) == 'function')
          oSelf.setRequired(bMandatory);

        oForm.dhx.setRequired(cName, bMandatory);
      }


    },
    /**
         * Watcher for the form error message
         */
    _errorFormFieldMsgWatcher: function(newValue) {
      const oSelf = this;
      const cName = oSelf.opt.name;
      const oForm = akioma.getForm(oSelf);
      const oFormField = akioma.getDhxFormElement(oForm.dhx, cName);

      // setup error message under input
      if (oFormField) {
        const oFormCtrl = $(oFormField).find('> .dhxform_control');
        if (oFormCtrl.find('.validation-error-smartmessage').length == 0)
          oFormCtrl.append(`<span class="validation-error-smartmessage">${newValue}</span>`);
        else
          oFormCtrl.find('.validation-error-smartmessage').text(newValue);
      }

    },
    // finish construct **********
    finishConstruct: function() {
      // get field
      this.form = akioma.getForm(this).dhx;
      this.form.setKeyValues(this.opt.name, this.opt.ListItemPairs);

      const $itemcont = $(this.form.getTokenSelect(this.opt.name));
      $itemcont.addClass('active');
    },

    // get value *****************
    getValue: function() {
      return this.form.getItemValue(this.opt.name);
    },

    // set value ********************
    setValue: function(cValue) {
      this.form.setItemValue(this.opt.name, cValue);
    },

    // event leave *******************
    eventLeave: function() {
      // check if we have to call the leave event
      if (this.opt.leaveEvent)
        app.controller.callAkiomaCode(this, this.opt.leaveEvent);
    },

    // event click *******************
    eventClick: function() {
      const oSelf = this,
        dhxWins = new dhtmlXWindows(),
        id = 'tokenselect',
        multiSelect = this.opt.multiSelect;
      dhxWins.createWindow(id, 100, 100, 650, 400);
      dhxWins.window(id).setText(`Choose ${this.opt.label}`);
      dhxWins.window(id).centerOnScreen();

      const myTree = dhxWins.window(id).attachTree(),
        treeArray = [],
        aKeyVals = this.form.getKeyValues(this.opt.name);

      myTree.setImagesPath(`${oDhx.imagePath}dhxtree_terrace/`);
      myTree.setSkin(oDhx.skin);
      if (multiSelect)
        myTree.enableCheckBoxes(1);
      else
        myTree.enableRadioButtons(1);
      myTree.enableTreeImages(false);
      myTree.enableMultiselection(multiSelect);

      treeArray.push([ 'root', '0', `${this.opt.label} Options` ]);
      for (const ind in aKeyVals)
        treeArray.push([ ind, 'root', aKeyVals[ind] ]);

      myTree.parse(treeArray, 'jsarray');
      myTree.showItemSign('root', false);

      if (!multiSelect) {
        myTree.disableCheckbox('root', true);
        myTree.showItemCheckbox('root', false);
      }

      // set selected from input
      const aSelectedItems = this.getValue().split(' ');
      for (const i in aKeyVals) {
        if ($.inArray(i, aSelectedItems) > -1)
          myTree.setCheck(i, 1);
        else
          myTree.setCheck(i, 0);
      }

      myTree.openItem('root');
      myTree.attachEvent('onCheck', (id, state) => { // check if root, select all others
        if (id == 'root') {
          if (multiSelect)
            myTree.setSubChecked('root', state);
          else
            return false;
        }
      });

      const myToolbar = dhxWins.window(id).attachToolbar();
      myToolbar.addButton(1, 0, '<img src="/dhtmlx/imgs/akioma/checkmark-32.png">');
      myToolbar.addButton(2, 1, '<img src="/dhtmlx/imgs/akioma/cancel-32.png">');
      const dhxWinCell = dhxWins.window(id);
      myToolbar.attachEvent('onClick', id => { // on save
        if (id == 1)
          oSelf.saveSelected();
        dhxWinCell.close();
      });

      this.saveSelected = function() {
        const checked = myTree.getAllCheckedBranches();
        const cNewVal = checked.replace(/,/g, ' ');

        if (oSelf.getValue() !== cNewVal) {
          const oForm = akioma.getForm(oSelf);
          const oField = oSelf;
          oForm.setFieldHasChanges(oField.opt.name, true);
        }

        this.setValue(cNewVal);
      };
    }
  });

})(jQuery, jQuery);
