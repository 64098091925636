dhtmlXRibbon.prototype.setItemToolTip = function(id, text) {
  if (this._items[id])
    this._items[id].base.title = text;

};

dhtmlXRibbon.prototype.getItemToolTip = function(id) {
  if (this._items[id])
    return this._items[id].base.title;

  return '';
};


akioma.getRibbon = function(oSelf) {
  const getRibb = self => {
    if (self.view == 'ribbon')
      return self;
    else
      return getRibb(self.parent);
  };
  return getRibb(oSelf);
};

(function($) {

  // ****************** ribbon ***********************
  $.extend({
    /**
      * SwatRibbon Control
      * @class ak_ribbon
      * @tutorial ribbon-desc
      * @tutorial ribbon-tutorial
      * @param {Object} options Repository attributes for SwatRibbon.
      * @param {string} options.RecordCreate Axilon: Neuanlage-Dialog für komplexe Daten mit mehreren eindeutigen Schlüsselfeldern
      * @param {string} options.ActionGroups Comma separated list of actionGroups.&#10;Repository toolbar uses categories while non-repository objects&#10;uses parent actions
      * @param {string} options.PanelType The type of Panel: Navigation, Save, Update
      * @param {string} options.ContainerObject For a Toolbar specifies where the toolbar should be rendered. if empty, then in the panel it resides, if WIN then in the window&#10;
      * @param {string} options.ToolBarKey Key of the used toolbar
      * @param {string} options.IconPath Defines the handling of icons in the Ribbon. If you want to use font-icons set this to "font:{name}" where {name} is the name of your font, e.g. "fa" for font-awesome. If you want to use image files, leave this empty or specify a server-side path where your images are located
      * @param {string} options.labelDisplay Specifies how all the labels will be handled. Possible values are 'standard', 'show', 'hide'. Default value is 'standard'. </br>
      * standard - labels will be displayed according to the menuFunction settings </br>
      * show - all the labels will be displayed. This setting will overwrite the menuFunction settings </br>
      * hide - all the labels will not be displayed. This setting will overwrite the menuFunction settings
      */
    ak_ribbon: function(options) {
      const oSelf = this,
        defaults = { pos: -1 };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;
      this.view = options.view;
      this.loaded = false;
      this.aItems = [];
      this.oActiveItem = { id: null, index: -1 };

      this.registerDynObject = true;

      this.prop = { fields: [] };
      this.ribbonItems = [];
      this.ribbonClickDelay = 350;


      if (!this.opt.customStyle)
        this.opt.customStyle = this.view;

      if (this.opt.toolbarkey)
        this.ribbonMenuID = this.opt.toolbarkey[0] == '#' ? this.opt.toolbarkey.substring(1) : this.opt.toolbarkey;


      this.oRibbonConf = { arrows_mode: 'auto' };

      if (!this.opt.iconPath)
        this.opt.iconPath = oDhx.imagePath;
      (this.opt.iconPath.startsWith('font')) ? this.oRibbonConf.iconset = 'awesome' : this.oRibbonConf.icons_path = this.opt.iconPath;

      if (this.ribbonMenuID)
        oSelf._createMenuStructure(this.ribbonMenuID);


      this.opt.containerObject = this.opt.ContainerObject;
      const oParent = (this.opt.containerObject && this.opt.containerObject == 'win') ? this.parent.parent.dhx : this.parent.dhx;
      const oRibbon = oParent.attachRibbon(this.oRibbonConf);


      oSelf.bHiddenRibbon = false;

      // add event after all repo and data loaded
      const oParWinId = this.getAncestor('window').opt.id;
      this.afterRepoAndDataLoadedBound = this.afterRepoAndDataLoaded.bind(this);
      akioma.eventEmitter.on(`${oParWinId}:repoAndDataLoaded`, this.afterRepoAndDataLoadedBound);

      $.extend(this, { dhx: oRibbon });

      if (app.trace)
        akioma.log.error([ 'create ribbon', this ]);
    }
  });

  // methods for ribbon *****************************
  $.ak_ribbon.prototype = {
    afterRepoAndDataLoaded: function() {
      const tabbar = this.dynObject.container.getFirstChildByType('tabbar');

      if (!tabbar) {
        if (this.promiseRbMenuStructure == undefined)
          this.show();
        else {
          this.promiseRbMenuStructure.then(() => {
            this.show();
          });
        }
      }

      this.applyResponsiveBehaviour();
    },

    /**
       * Method for applying reponsive behaviour/ CSS class
       * @memberOf ak_ribbon
       * @instance
       * @return {void}
       */
    applyResponsiveBehaviour: function() {
      if (this.opt.responsiveBehaviour)
        $(this.dhx._base).addClass(this.opt.responsiveBehaviour);

    },

    hide: function(bKeepHiddenState) {

      const oSelf = this;
      $(oSelf.dhx._base).attr('akloadingribbon', true);

      if (bKeepHiddenState == undefined)
        oSelf.bHiddenRibbon = false;
      else
        oSelf.bHiddenRibbon = true;

    },

    show: function(bKeepHiddenState) {
      $(this.dhx._base).removeAttr('akloadingribbon');
      if (bKeepHiddenState == undefined)
        this.bHiddenRibbon = true;
      else
        this.bHiddenRibbon = false;
    },

    finishConstruct: function() {

      const oSelf = this;
      this.opt.containerObject = this.opt.ContainerObject;
      const oParent = (this.opt.containerObject && this.opt.containerObject == 'win') ? this.parent.parent.dhx : this.parent.dhx;

      if (!this.opt.definitionFile) {
        const aTabs = [];

        // check if there is at least one tab and if not load items
        let bTabsExist = false;
        for (const i in this.prop.fields) {
          if (this.prop.fields[i].type == 'tab') {
            bTabsExist = true;
            aTabs.push(this.prop.fields[i]);
          }
        }

        this.opt.definitionFile = (bTabsExist) ? { 'tabs': aTabs } : { 'items': this.prop.fields };
        this.oRibbonConf.json = this.opt.definitionFile;
      }

      if (oParent) {

        const oRibbon = this.dhx;
        if (oRibbon) {

          oRibbon.loadStruct(this.oRibbonConf.json);

          // set akStyle in ribbon
          $(oSelf.dhx._base).attr('akStyle', oSelf.opt.customStyle);
          if (!oSelf.bHiddenRibbon)
            oSelf.hide();


          oRibbon.setSkin('material');
          VisibilityRules.checkRibbonVisibilityRules(this);


          oRibbon.attachEvent('onClick', cId => oSelf.click(cId));
          oRibbon.attachEvent('onStateChange', (cId, lState) => oSelf.stateChange(cId, lState));
          oRibbon.attachEvent('onCheck', (cId, lState) => oSelf.checkboxChanged(cId, lState));
          oRibbon.attachEvent('onSelectOption', (cId, iIdx, cText) => oSelf.optionSelected(cId, iIdx, cText));
          oRibbon.attachEvent('onEnter', (cId, cValue) => oSelf.enterText(cId, cValue));

          oRibbon.akElm = this;

          // MouseTrap for keyboard navigation
          const oMouseTrapNavigation = Mousetrap(oRibbon._base);

          oMouseTrapNavigation.bind('right', () => {
            oSelf._focusItem(oSelf.getNextItem(oSelf.oActiveItem.index));

          });

          oMouseTrapNavigation.bind('left', () => {
            oSelf._focusItem(oSelf.getPrevItem(oSelf.oActiveItem.index));
          });

          oMouseTrapNavigation.bind('enter', () => {
            const oItem = oSelf.getItemById(oSelf.oActiveItem.id).controller;
            oSelf.click(oItem.opt.id);
          });

          oMouseTrapNavigation.bind('alt+ctrl+r', e => {
            if (e.preventDefault)
              e.preventDefault();

            const oControl = akioma.getRibbonToolbar(oSelf, oSelf);
            akioma.focusRibbonToolbar(oControl);

            if (e.stopPropagation)
              e.stopPropagation();
          });

          $.extend(this, { dhx: oRibbon });
        } else
          akioma.log.error([ 'error attaching ribbon to Parent:', oParent ]);

      } else
        akioma.log.error(`No valid parent for ribbon ${this.opt.name}`);

    },

    /**
    * Loads ribbon
    * @param  {Object} oConfig Dhtmlx Ribbon configuration
    * @instance
    * @memberOf ak_ribbon
    */
    loadRibbon: function(oConfig) {
      const oRibbon = this.dhx;

      if (oRibbon) {
        oRibbon.loadStruct(oConfig);
        $.extend(this, { dhx: oRibbon });
      }

    },

    /**
    * Creates menuStructure and goes through all structures and functions
    * @param  {String} menuID The menuStructureCode
    * @private
    * @instance
    * @memberOf ak_ribbon
    */
    _createMenuStructure: function(menuID) {
      const oSelf = this;

      const m = app.controller.parseProc({
        view: 'menustructure',
        att: {
          id: menuID,
          depth: 0
        },
        sub: []
      }, oSelf);

      oSelf.ribbonMenuStructure = m;
      oSelf.promiseRbMenuStructure = m.loadMenuElements();
      oSelf.promiseRbMenuStructure.done(() => {

        m.scanMenuItemsObj(item => {
          // Create Dhtmlx Ribbon items
          if (!item.parentid)
            item.parentid = oSelf.ribbonMenuID;
          oSelf._createRibbonItem(item);
        });

        oSelf.loadRibbon(oSelf.oRibbonConf.json);

        // Create Akioma Ribbon items
        for (const block in oSelf.ribbonItems) {
          const oBlock = oSelf.ribbonItems[block];
          oSelf._createAkiomaRibbonItem(oBlock);

          for (const item of oBlock.children)
            oSelf._createAkiomaRibbonItem(item);
        }
      });

    },

    /**
    * Creates DHXRibbon object for a ribbon item
    * @param  {Object} oMenuItem Object containing the options
    * @private
    * @instance
    * @memberOf ak_ribbon
    */
    _createRibbonItem: function(oMenuItem) {

      let oParent;
      if (this.ribbonItems[oMenuItem.parentid])
        oParent = this.ribbonItems[oMenuItem.parentid];
      else {
        oMenuItem.parentid = this.ribbonMenuID;
        oParent = this;
      }

      // create logger on module Toolbar
      const logger = akioma.log.getLogger('Menu.FunctionCode');


      try {
      // display FunctionCode not defined in ribbon menustructure
        if (oMenuItem.code && oMenuItem.code.split('-').length == 5 && oMenuItem.code.length) {
          logger.log.info('=================================');
          logger.log.info('Found FunctionCode with no code:');
          logger.log.info(`FunctionCode:${oMenuItem.code}`);
          logger.log.info(`Label(MenuName):${oMenuItem.label}`);
          logger.log.info(`MenuStructureCode :${oMenuItem.menuCode}`);
          logger.log.info('Toolbar Item:', oMenuItem);
          logger.log.info('=================================');
        }
      } catch (e) {
        logger.log.warn(e);
      }

      const options = {
        view: oMenuItem.type,
        att: {
          id: oMenuItem.menuCode || oMenuItem.code || oMenuItem.id,
          label: oMenuItem.label,
          parentid: oMenuItem.parentid,
          title: oMenuItem.label,
          name: oMenuItem.menuCode || oMenuItem.code || oMenuItem.id,
          image: oMenuItem.icon,
          image2: oMenuItem.icon,
          isBig: oMenuItem.isBig || false,
          imageOnly: oMenuItem.imageOnly || false,
          shortCut: oMenuItem.shortcut,
          tooltip: oMenuItem.tooltip,
          hidden: oMenuItem.hidden || false,
          disabled: oMenuItem.disabled || false,
          children: oMenuItem.children,
          type: oMenuItem.type,
          defaultGoBtn: oMenuItem.isDefaultAction || false,
          visibilityRules: oMenuItem.visibilityRules
        },
        parent: oParent
      };

      if (oMenuItem.structure) {
        if (oMenuItem.structure.visibilityRules)
          options.att.visibilityRules = oMenuItem.structure.visibilityRules;
        if (oMenuItem.structure.isHidden)
          options.att.hidden = oMenuItem.structure.isHidden;
        if (oMenuItem.structure.textPos)
          options.att.textPos = oMenuItem.structure.textPos;
        if (oMenuItem.structure.isDisabled)
          options.att.disabled = oMenuItem.structure.isDisabled;
      }

      let bTab = false;
      options.dhxConfig = {
        'id': options.att.id,
        'text': options.att.title
      };

      switch (options.view) {
        case 'tab':
          options.view = 'ribbontab';
          options.prop = { fields: [] };
          options.dhxConfig.type = 'tab';
          options.dhxConfig.active = true;
          options.dhxConfig.items = options.prop.fields;
          bTab = true;
          break;
        case 'block':
          options.view = 'ribbonblock';
          options.prop = { fields: [] };
          options.dhxConfig.type = 'block';
          options.dhxConfig.mode = 'cols';
          options.dhxConfig.text_pos = options.att.textPos || 'top';
          options.dhxConfig.list = options.prop.fields;
          break;
        case 'break':
          options.view = 'ribbonbreak';
          options.dhxConfig.type = 'newLevel';
          break;
        case 'item':
          options.view = 'ribbonbutton';
          options.dhxConfig.type = 'button';
          options.dhxConfig.img = options.att.image;
          options.dhxConfig.isbig = options.att.isBig;
          options.dhxConfig.hidden = options.att.hidden;
          if (options.att.imageOnly)
            options.dhxConfig.text = '';

          if (this.opt.labelDisplay == 'show')
            options.dhxConfig.text = options.att.title;
          if (this.opt.labelDisplay == 'hide')
            options.dhxConfig.text = '';
          break;
        case 'combo':
          options.view = 'ribboncombo';
          options.att.createEvent = oMenuItem.createEvent;
          options.att.selectEvent = oMenuItem.selectEvent;
          options.dhxConfig.type = 'buttonCombo';
          options.dhxConfig.width = 200;
          options.dhxConfig.text = '';
          options.dhxConfig.mode = 'image';
          break;
        case 'input':
          options.view = 'ribboninput';
          options.dhxConfig.type = 'input';
          options.dhxConfig.disabled = false;
          options.dhxConfig.img = '';
          break;
      }

      if (bTab)
        this.opt.definitionFile = { 'tabs': [] };

      if (oParent.view == 'ribbon')
        this.prop.fields.push(options.dhxConfig);
      else
        oParent.prop.fields.push(options.dhxConfig);

      if (options.view == 'ribbonblock' || options.att.children) {
        this.ribbonItems[options.att.id] = options;
        this.ribbonItems[options.att.id].children = [];
      } else
        this.ribbonItems[oParent.att.id].children.push(options);


    },

    /**
    * Creates Akioma controller for a ribbon item
    * @param  {Object} options Object containing the options
    * @private
    * @instance
    * @memberOf ak_ribbon
    */
    _createAkiomaRibbonItem: function(options) {

      const oParent = (options.parent.akElm) ? options.parent.akElm : options.parent;
      const oNew = app.controller.parseProc({
        view: options.view,
        att: options.att,
        sub: []
      }, oParent);

      options.akElm = oNew;

    },
    /**
    * Method for calling js code after loading the data
    *
    * @param   {function}  callback  callback function
    * @memberof ak_ribbon
    * @instance
    * @return  {void}
    */
    callAfterLoad(callback) {
      if (!isNull(this.promiseRbMenuStructure)) {
        this.promiseRbMenuStructure
          .done(callback).fail(callback);
      } else
        callback();

    },

    /**
    * Hides an item or a block of items in Ribbon
    * @param  {string}  cName Name or menuCode (when using menuStructure) of the item
    * @instance
    * @example
    * // get the Ribbon object of the current container and hide a ribbonBlock with name='ribbonItemTest'
    * // this could e.g. be placed inside an AfterDisplay Event of a Form
    * if (someField == "somevalue")
    *   self.container.getObject("SimpleSwatRibbon").controller.hideItem('ribbonItemTest');
    * @memberOf ak_ribbon
    */
    hideItem: function(cName) {
      this.callAfterLoad(() => {
        this.dhx.hide(cName);
      });
    },

    /**
    * Shows an item or a block of items in Ribbon
    * @param  {string}  cName Name or menuCode (when using menuStructure) of the item
    * @instance
    * @example
    * // get the Ribbon object of the current container and show a ribbonButton with name='ribbonItemTest'
    * // this could e.g. be placed inside an AfterDisplay Event of a Form
    * if (someField == "somevalue")
    *   self.container.getObject("SimpleSwatRibbon").controller.showItem('ribbonItemTest');
    * @memberOf ak_ribbon
    */
    showItem: function(cName) {
      this.callAfterLoad(() => {
        this.dhx.show(cName);
      });
    },

    /**
    * Enables an item or a block of items in Ribbon
    * @param  {string}  cName Name or menuCode (when using menuStructure) of the item
    * @instance
    * @example
    * // get the Ribbon object of the current container and enable a ribbonButton with name='ribbonItemTest'
    * // this could e.g. be placed inside an AfterDisplay Event of a Form
    * if (someField == "somevalue")
    *   self.container.getObject("SimpleSwatRibbon").controller.enableItem('ribbonItemTest');
    * @memberOf ak_ribbon
    */
    enableItem: function(cName) {
      this.callAfterLoad(() => {
        this.dhx.enable(cName);
      });
    },

    /**
    * Enables all visible ribbon items
    * @instance
    * @memberOf ak_ribbon
    */
    enableAllItems: function() {
      this.callAfterLoad(() => {
        this.dhx.enableAllItems();
      });
    },

    /**
    * Disables an item or a block of items in Ribbon
    * @param  {string}  cName Name or menuCode (when using menuStructure) of the item
    * @instance
    * @example
    * // get the Ribbon object of the current container and disable a ribbonButton with name='ribbonItemTest'
    * // this could e.g. be placed inside an AfterDisplay Event of a Form
    * if (someField == "somevalue")
    *   self.container.getObject("SimpleSwatRibbon").controller.disableItem('ribbonItemTest');
    * @memberOf ak_ribbon
    */
    disableItem: function(cName) {
      this.callAfterLoad(() => {
        this.dhx.disable(cName);
      });
    },

    /**
    * Returns the text of an item or a block of items in Ribbon
    * @param  {string}  cName Name or menuCode (when using menuStructure) of the item
    * @returns {string}  Text of the item from the ribbon
    * @instance
    * @memberOf ak_ribbon
    */
    getItemText: function(cName) {
      const oSelf = this;
      const cText = oSelf.dhx.getItemText(cName);
      return cText;
    },

    /**
    * Sets the text of an item or a block of items in Ribbon
    * @param  {string}  cName Name or menuCode (when using menuStructure) of the item
    * @param  {string}  cText The value to set
    * @instance
    * @memberOf ak_ribbon
    */
    setItemText: function(cName, cText) {
      const oSelf = this;
      oSelf.dhx.setItemText(cName, cText);
    },

    /**
    * Returns the tooltip of an item or a block of items in Ribbon
    * @param  {string}  cName Name or menuCode (when using menuStructure) of the item
    * @returns {string}  Tooltip of the item from the ribbon
    * @instance
    * @memberOf ak_ribbon
    */
    getItemToolTip: function(cName) {
      const oSelf = this;
      const cTip = oSelf.dhx.getItemToolTip(cName);
      return cTip;
    },

    /**
    * Sets the tooltip of an item or a block of items in Ribbon
    * @param  {string}  cName Name or menuCode (when using menuStructure) of the item
    * @param  {string}  cTip The value to set
    * @instance
    * @memberOf ak_ribbon
    */
    setItemToolTip: function(cName, cTip) {
      const oSelf = this;
      oSelf.dhx.setItemToolTip(cName, cTip);
    },

    /**
    * Returns the controller of an item from the ribbon
    * @param   {string}  id Name or menuCode (when using menuStructure) of the item
    * @returns {Object}  Controller of the item from the ribbon
    * @instance
    * @memberOf ak_ribbon
    */
    getItemById: function(id) {
      return this.dynObject.getObject(id);
    },

    /**
		* Returns the html of an item from the ribbon
		* @param   {string}  id Name or menuCode (when using menuStructure) of the item
		* @returns {Object}  Html of the item from the ribbon
		* @instance
		* @memberOf ak_ribbon
		*/
    getItemHtml: function(id) {
      return this.dhx.itemPull[id];
    },

    /**
		* Focuses the item from the ribbon
		* @param   {Object}  item Object containing the id and the index of the item
		* @instance
		* @private
		* @memberOf ak_ribbon
		*/
    _focusItem: function(item) {
      const oItemHtml = this.getItemHtml(item.id);
      $(oItemHtml).attr('tabindex', '0');
      $(oItemHtml).focus();
      this.oActiveItem = item;
    },

    /**
    * Returns the next active and visible item from the ribbon
    * @param   {Integer}  iIndex Index of the current item in the ribbon
    * @returns {Object}	  Object containing the id and the index of the next item
    * @instance
    * @private
    * @memberOf ak_ribbon
    */
    getNextItem: function(iIndex) {

      const nextItem = {};
      if (!iIndex && iIndex != 0)
        iIndex = -1;

      do {
        if (iIndex < this.aItems.length - 1)
          iIndex++;
        else
          iIndex = 0;
      }
      while (!this.isVisible(this.aItems[iIndex]) || !this.isEnabled(this.aItems[iIndex]));
      nextItem.id = this.aItems[iIndex];
      nextItem.index = iIndex;
      return nextItem;

    },

    /**
		* Returns the previous active and visible item from the ribbon
		* @param   {Integer}  iIndex Index of the current item in the ribbon
		* @returns {Object}	  Object containing the id and the index of the previous item
		* @instance
		* @private
		* @memberOf ak_ribbon
		*/
    getPrevItem: function(iIndex) {
      const prevItem = {};
      if (!iIndex && iIndex != 0)
        iIndex = this.aItems.length;

      do {
        if (iIndex > 0)
          iIndex--;
        else
          iIndex = this.aItems.length - 1;
      }
      while (!this.isVisible(this.aItems[iIndex]) || !this.isEnabled(this.aItems[iIndex]));
      prevItem.id = this.aItems[iIndex];
      prevItem.index = iIndex;
      return prevItem;

    },

    /**
		* Checks if the item from the ribbon is enabled
		* @param   {Integer}  id 	Name or menuCode (when using menuStructure) of the item
		* @returns {boolean}	  	True for enabled, False otherwise
		* @instance
		* @private
		* @memberOf ak_ribbon
		*/
    isEnabled: function(id) {
      let isEnabled = this.dhx.isEnabled(id);

      if (isEnabled) {
        const oItem = this.getItemById(id);
        if (oItem) {
          const oParent = oItem.parent.controller;
          if (oParent.view !== 'ribbon')
            isEnabled = this.dhx.isEnabled(oParent.opt.id);
        }
      }

      return isEnabled;
    },

    /**
		* Checks if the item from the ribbon is visible
		* @param   {Integer}  id 	Name or menuCode (when using menuStructure) of the item
		* @returns {boolean}	  	True for visible, False otherwise
		* @instance
		* @private
		* @memberOf ak_ribbon
		*/
    isVisible: function(id) {
      let isVisible = this.dhx.isVisible(id);

      if (isVisible) {
        const oItem = this.getItemById(id);
        if (oItem) {
          const oParent = oItem.parent.controller;
          if (oParent.view == 'ribbon')
            isVisible = isNull($(this.dhx._base).attr('akloadingribbon'));
          else
            isVisible = this.dhx.isVisible(oParent.opt.id);
        }
      }

      return isVisible;
    },


    // click
    click: function(cId) {
      const oSelf = this;

      let oTool;
      try {
        oTool = oSelf.dynObject.getObject2(cId);
      } catch (oErr) {
        akioma.log.error(oErr);
      }

      // if the ribbon element can be found
      if (oTool) {

        // if timeout clear timeout
        if (this.dATimeout)
          clearTimeout(this.dATimeout);

        // set timeout and execute click when ended
        this.dATimeout = setTimeout(function() {
          clearTimeout(this.dATimeout);
          oTool.controller.click(cId);
        }, this.ribbonClickDelay);

        return true;
      } else {
        akioma.notification({ type: 'info', text: `clicked on: ${cId}` });

        // look for element and execute click
        for (const i in oSelf.childs) {
          switch (oSelf.childs[i].view) {
            case 'ribboncombo':
              if (this.dhx.getAllListOptions(this.childs[i].opt.name).indexOf(cId) > -1) {
                const oElm = oSelf.childs[i];
                return oElm.click(cId);
              }
              break;
            default:
              if (this.childs[i].opt.name == cId) {
                const oElm = oSelf.childs[i];
                return oElm.click(cId);
              }
              break;
          }
        }
        return false;
      }


    },

    // state change
    optionSelected: function(cId, iIdx, cText) {
      let oTool;
      try {
        oTool = this.dynObject.getObject2(cId);
      } catch (oErr) {
        akioma.log.error(oErr);
      }
      if (oTool)
        return oTool.controller.optionSelected(iIdx, cText);
    },

    // state change
    stateChange: function(cId, lState) {
      let oTool;
      try {
        oTool = this.dynObject.getObject2(cId);
      } catch (oErr) {
        akioma.log.error(oErr);
      }
      if (oTool)
        return oTool.controller.stateChange(lState);

      // look for element and execute click
      for (const i in this.childs) {
        if (this.childs[i].opt.name == cId)
          return this.childs[i].stateChange(lState);
      }
      return false;
    },

    // enter text
    enterText: function(cId, cValue) {
      let oTool;
      try {
        oTool = this.dynObject.getObject2(cId);
      } catch (oErr) {
        akioma.log.error(oErr);
      }

      if (oTool)
        return oTool.controller.enterText(cValue);

      // look for element and execute function
      for (const i in this.childs) {
        if (this.childs[i].opt.name == cId)
          return this.childs[i].enterText(cValue);

      }
      return false;
    },

    // set value
    setFieldValue: function(cName, cValue) {

      // look for element and set value inside
      for (const i in this.childs) {
        if (this.childs[i].opt.name == cName)
          return this.childs[i].setFieldValue(cValue);

      }
    },

    // query position ******************
    queryPosition: function(oElm) {
      const oribbon = this.dhx,
        cState = oElm.state;

      let oNav = null;
      switch (cState) {
        case 'FirstRecord':
          oNav = { navFirst: false, navPrev: false, navNext: true, navLast: true };
          break;
        case 'LastRecord':
          oNav = { navFirst: true, navPrev: true, navNext: false, navLast: false };
          break;
        case 'NotFirstOrLast':
          oNav = { navFirst: true, navPrev: true, navNext: true, navLast: true };
          break;
        default:
          oNav = { navFirst: false, navPrev: false, navNext: false, navLast: false };
          break;
      }

      for (const i in this.childs) {
        const cName = this.childs[i].opt.name;
        if (oNav[cName] != undefined) {
          if (oNav[cName])
            oribbon.enableItem(this.childs[i].opt.id);
          else
            oribbon.disableItem(this.childs[i].opt.id);
        }
      }
    },

    destroy: function() {
      // check if dhtmlx element exists -> destroy it
      if (this.dhx) {
        try {
          // remove event after all repo and data loaded
          const oParWinId = this.getAncestor('window').opt.id;
          akioma.eventEmitter.off(`${oParWinId}:repoAndDataLoaded`, this.afterRepoAndDataLoadedBound);
          // remove all items from ribbon
          const chs = [];

          for (let q = 0; q < this.dhx.childIds.length; q++)
            chs.push(this.dhx.childIds[q]);


          for (let q = 0; q < chs.length; q++) {
            if (this.dhx._items[chs[q]].type == 'tab')
              this.dhx.tabs(chs[q]).close(false);
            else
              this.dhx.removeItem(chs[q]);

          }
          if (this.dhx._tabbar) {
            this.dhx._tabbar.unload();
            this.dhx._tabbar = null;
          }

          this.dhx._base.innerHTML = '';

          if (this.dhx._base._dhx_remove)
            this.dhx._base.parentNode.removeChild(this._base);
          else
            this.dhx._base.className = this.dhx._base.className.replace(/\s?(dhtmlx|dhxrb)(\S*)/ig, '');

          this.dhx = null;

        } catch (e) {
          !_isIE && console.error([ 'Error destroying', this.view, this.opt.name, e.message ]);
        }
      }
    }
  };

})(jQuery, jQuery);
