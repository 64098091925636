// ************************* toolbarseparator ********************************
$.extend({
  ak_toolbarseparator: function(options) {
    const defaults = { id: '' };

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;

    // get new element
    const cName = this.opt.id;

    const iPos = this.parent.opt.pos + 1;
    this.parent.opt.pos = iPos;

    // get toolbar
    const oToolbar = this.parent.dhx;
    if (oToolbar)
      oToolbar.addSeparator(cName, iPos);
    else
      akioma.log.error(`No valid parent for toolbarseparator ${cName}`);
  }
});

$.ak_toolbarseparator.prototype = {
  // finish construct
  finishConstruct: function() {}
};
