

/**
 * The FilterManager class handles Grid filter operations (add, remove, rename, save, setDefault). By default, all the Grids will have a FilterManager class attached to them.
 * @class FilterManager
 * @param {object} oGrid A Grid for which the filter operations will be used.
 *
 */
akioma.FilterManager = class {

  constructor(oGrid) {
    this._oGrid = oGrid;
    this.panelMenuLoaded = $.Deferred();
    this._aPanelFilters = [];
  }

  /**
     * Method for getting the FilterGrid attached to a Grid
     * @memberOf FilterManager
     * @return {object}
     */
  getFilterGrid() {
    return this._oFilterGrid;
  }

  /**
     * Method for setting the FilterGrid for a Grid
     * @param {object} oFilterGrid The FilterGrid that will be attached to the Grid
     * @memberOf FilterManager
     */
  setFilterGrid(oFilterGrid) {
    this._oFilterGrid = oFilterGrid;
  }

  /**
     * Method for getting all the filters for a Grid
     * @memberOf FilterManager
     * @return {array}
     */
  getFilters() {
    return this._aFilters;
  }

  /**
     * Helper method to get the filter for a specified filter handle
     * @param {string} cFilterHdl The handle to search for in the filters
     * @memberof FilterManager
     * @return {object}
     */
  getFilter(cFilterHdl) {
    for (const option in this._aFilters) {
      if (this._aFilters[option].FilterHdl == cFilterHdl)
        return this._aFilters[option];
    }
    return null;
  }

  /**
     * Method for getting all the filters for a Grid
     * @memberOf FilterManager
     * @return {array}
     */
  getFilterDefinition(cFilterHdl) {
    if (this._aFilterDefinition[cFilterHdl])
      return this._aFilterDefinition[cFilterHdl];
    return null;
  }

  /**
     * Method for getting the current filter for a Grid
     * @memberOf FilterManager
     * @return {string}
     */
  getCurrentFilter() {
    return this._cCurrentFilterHdl;
  }

  /**
     * Method for setting the current filter for a Grid
     * @param {string} cFilterHdl The filter handle that will be set as current
     * @memberOf FilterManager
     */
  setCurrentFilter(cFilterHdl) {
    this._cCurrentFilterHdl = cFilterHdl;
  }

  /**
     * Method for getting the default filter for a Grid
     * @memberOf FilterManager
     * @return {string}
     */
  getDefaultFilter() {
    return this._cDefaultFilterHdl;
  }

  /**
     * Method for setting the default filter for a Grid
     * @param {string} cFilterHdl The filter handle that will be set as default
     * @memberOf FilterManager
     */
  setDefaultFilter(cFilterHdl) {
    this._cDefaultFilterHdl = cFilterHdl;
  }

  /**
     * Method for getting the dynSelect filter for a Grid
     * @memberOf FilterManager
     * @return {object}
     */
  getDynSelectFilter() {
    return this._oDynSelectFilter;
  }

  /**
     * Method for setting the dynSelect filter for a Grid
     * @param {object} oDynSelect The dynSelect that will be set for a Grid
     * @memberOf FilterManager
     */
  setDynSelectFilter(oDynSelect) {
    this._oDynSelectFilter = oDynSelect;
  }

  /**
     * Method to set the option in the dynSelect specified by a filter handle
     * @param {string} cFilterHdl
     * @memberof FilterManager
     */
  setDynSelectOption(cFilterHdl) {
    const oDynSelectOption = this.getFilter(cFilterHdl);
    this.getDynSelectFilter().setFilter(cFilterHdl, oDynSelectOption.Description);
  }

  /**
     * Method for getting the panel filters for a Grid with specified/unspecified active state
     * @param {string}cState cState The state of required panel filters
     * @memberOf FilterManager
     * @return {array}
     */
  getPanelFilter(cState) {
    const aFilters = [];
    for (const menu in this._aPanelFilters) {
      if (cState != undefined) {
        if (this._aPanelFilters[menu].state == cState)
          this._addFiltersHelper(menu, aFilters);
      } else
        this._addFiltersHelper(menu, aFilters);
    }
    return aFilters;
  }

  /**
     * Method for setting the panel filter for a Grid
     * @param {string}cName cName The name of the panel filter
     * @param {string}cFilterName cFilterName The name of the field
     * @param {object}oFilter oFilter The filter object to be added to the specified panel filter
     * @memberOf FilterManager
     */
  setPanelFilter(cName, cFilterName, oFilter) {

    if (!this._aPanelFilters[cName]) {
      this._aPanelFilters[cName] = [];
      this._aPanelFilters[cName].state = false;
    }
    if (!this._aPanelFilters[cName].filters)
      this._aPanelFilters[cName].filters = [];
    if (!this._aPanelFilters[cName].filters[cFilterName])
      this._aPanelFilters[cName].filters[cFilterName] = [oFilter];
    else
      this._aPanelFilters[cName].filters[cFilterName].push(oFilter);
  }

  /**
     * Method for setting the state of a panel filter
     * @param {string}cName cName The name of the panel filter
     * @param {boolean}state state The new state of the panel filter
     * @memberOf FilterManager
     */
  setPanelFilterState(cName, state) {
    this._aPanelFilters[cName].state = state;
  }

  /**
     * Method for getting the state of the panel filter
     * @param {string}cName cName The name of the panel filter
     * @memberOf FilterManager
     */
  getPanelFilterState(cName) {
    return this._aPanelFilters[cName] ? this._aPanelFilters[cName].state : null;
  }

  /**
     * Method for setting the filters for a Grid
     * @memberOf FilterManager
     */
  loadFilters() {
    const oThis = this,
      oSelf = this._oGrid;

    oSelf.filtersLoader = oSelf.getFiltersBT();

    oThis.oDynSelectPromise = new jQuery.Deferred;
    oThis.oDynSelectPromise.promise();

    oSelf.filtersLoader.done(function(aFilters, aFilterDefinitions) {
      oThis._aFilters = aFilters;
      oThis._aFilterDefinition = aFilterDefinitions;

      for (const i in aFilters) {
        const oFilter = aFilters[i];
        oFilter.Description = akioma.tran(`filterManager.filters.${oFilter.Description}`, { defaultValue: oFilter.Description });
        if (oFilter.IsDefaultFilter) {
          oThis.setDefaultFilter(oFilter.FilterHdl);
          oThis.useFilter(oFilter.FilterHdl);
          oThis.oDynSelectPromise.resolve(arguments);
        }
      }
    });
  }


  /**
     * Method for adding a new filter
     * @memberOf FilterManager
     */
  addFilter() {

    const oThis = this,
      oSelf = this._oGrid,
      oFilterGrid = this._oFilterGrid,
      oSource = oSelf.dataSource,
      oFilter = oSelf.getFilterArray();

    akioma.message({
      type: 'input',
      title: akioma.tran('filterManager.filterModal.add.title', { defaultValue: 'New filter' }),
      text: akioma.tran('filterManager.filterModal.add.text', { defaultValue: 'Filter name' }),
      callback: function(result) {
        if (result) {
          const cName = result;
          if (cName.length > 100) {
            akioma.swat.Message.message({ type: 'error', text: akioma.tran('filterManager.filterMessages.error.limit', { defaultValue: 'The name of the filter can have a maximum of 100 characters.' }), modal: true });
            oThis.addFilter();
            return;
          }

          const oData = {
            SDO: oSource.opt.name, // 'offerd',
            Grid: oSelf.opt.gridName, // 'offer_largeb',
            Name: cName, // 'APoTest2',
            Fields: oFilter.fields.join(','), // 'selfno,selfdesc',
            Values: oFilter.values.join('|'), // 'ak*|test*',
            Operators: oFilter.operators.join(','), // 'MATCHES,MATCHES',
            Rows: oSelf.iBatchSize,
            Sorting: oSelf.sort.name ? `${oSelf.sort.name},${oSelf.sort.dir}` : '' // 'datecreated,des',
          };
          oSelf.opt.rowsToBatch && (oData.rows = oSelf.opt.rowsToBatch);
          akioma.invokeServerTask({
            name: 'Akioma.Swat.FilterBT',
            methodName: 'CreateFilter',
            paramObj: { plcParameter: oData }

          }).done(oResult => {

            oThis.addFilterObject({
              FilterHdl: oResult.plcParameter.FilterHdl,
              Description: oResult.plcParameter.Name,
              Sort: ''
            });

            // add the filter definitions
            const aDefFields = oFilter.fields;
            const aDefValues = oFilter.values;
            const aDefOperators = oFilter.operators;
            if (aDefFields.length > 0) {
              oThis._aFilterDefinition[oResult.plcParameter.FilterHdl] = [];
              for (const i in aDefFields) {
                oThis.addFilterDefinition(oResult.plcParameter.FilterHdl, {
                  fieldname: aDefFields[i],
                  operator: aDefOperators[i],
                  value: aDefValues[i]
                });
              }
            }

            if (oFilterGrid)
              oFilterGrid.dataSource.openQuery({ repositionTo: oResult.plcParameter.FilterHdl });
            else {
              oThis.useFilter(oResult.plcParameter.FilterHdl);
              oThis.setDynSelectOption(oResult.plcParameter.FilterHdl);
            }

            akioma.notification({ type: 'info', text: `"${oResult.plcParameter.Name}" ${akioma.tran('filterManager.filterMessages.success.add', { defaultValue: 'Filter was added successfully.' })}` });
          }).fail(() => {
            akioma.notification({ type: 'error', text: `${akioma.tran('filterManager.filterMessages.error.add', { defaultValue: 'Could not add new filter' })} "${cName}".` });
          });
        } else
          return;
      }
    });
  }

  /**
     * Method for saving the current filter?
     * @memberOf FilterManager
     */
  saveFilter() {
    const oThis = this,
      oSelf = this._oGrid,
      oFilterGrid = this._oFilterGrid,
      oSource = oSelf.dataSource;

    const cFilter = (oFilterGrid) ? oFilterGrid.dataSource.getSelectedRecord()['filterhdl'] : oThis.getCurrentFilter();

    // get selected filter
    if (cFilter) {
      const oFilter = oSelf.getFilterArray(),
        oData = {
          SDO: oSource.opt.name.toLowerCase(),
          Grid: oSelf.opt.gridName,
          FilterHdl: cFilter,
          Fields: oFilter.fields.join(','),
          Values: oFilter.values.join('|'),
          Operators: oFilter.operators.join(','),
          Rows: oSelf.iBatchSize,
          Sorting: oSelf.sort.name ? `${oSelf.sort.name},${oSelf.sort.dir}` : ''
        };

      oSelf.opt.rowsToBatch && (oData.rows = oSelf.opt.rowsToBatch);

      akioma.invokeServerTask({
        name: 'Akioma.Swat.FilterBT',
        methodName: 'UpdateFilter',
        paramObj: { plcParameter: oData }
      }).done(oResult => {
        if (oResult.plcParameter.Status == 'error')
          akioma.notification({ type: 'error', text: oResult.plcParameter.Message });
        else {

          oThis.removeFilterDefinition(cFilter);

          // add the filter definitions
          const aDefFields = oFilter.fields;
          const aDefValues = oFilter.values;
          const aDefOperators = oFilter.operators;
          if (aDefFields.length > 0) {
            oThis._aFilterDefinition[oResult.plcParameter.FilterHdl] = [];
            for (const i in aDefFields) {
              oThis.addFilterDefinition(oResult.plcParameter.FilterHdl, {
                fieldname: aDefFields[i],
                operator: aDefOperators[i],
                value: aDefValues[i]
              });
            }
          }

          if (oFilterGrid)
            oFilterGrid.dataSource.openQuery({ repositionTo: oResult.plcParameter.FilterHdl });

          akioma.notification({ type: 'info', text: akioma.tran('filterManager.filterMessages.success.save', { defaultValue: 'Selected filter saved.' }) });
        }
      }).fail(() => {
        akioma.notification({ type: 'error', text: akioma.tran('filterManager.filterMessages.error.save', { defaultValue: 'Error saving selected filter.' }) });
      });
    }
  }

  /**
     * Method for removing the selected filter
     * @memberOf FilterManager
     */
  removeFilter() {
    return new Promise((resolve, reject) => {

      const oThis = this,
        oFilterGrid = this._oFilterGrid,
        cDefaultFilter = this.getDefaultFilter();

      const cFilter = (oFilterGrid) ? oFilterGrid.dataSource.getSelectedRecord()['filterhdl'] : oThis.getCurrentFilter();
      if (cFilter == cDefaultFilter)
        akioma.notification({ type: 'error', text: akioma.tran('filterManager.filterMessages.error.removeDefault', { defaultValue: 'Could not remove default filter.' }) });
      else
      if (cFilter) {
        akioma.message({
          type: 'confirm',
          title: akioma.tran('filterManager.filterModal.remove.title', { defaultValue: 'Remove filter' }),
          text: akioma.tran('filterManager.filterModal.remove.text', { defaultValue: 'Should the filter really be removed?' }),
          callback: function(result) {
            const oData = { FilterHdl: cFilter };
            if (result) {
              akioma.invokeServerTask({
                name: 'Akioma.Swat.FilterBT',
                methodName: 'DeleteFilter',
                paramObj: { plcParameter: oData }

              }).done(() => {
                oThis.removeFilterObject(cFilter);
                oThis.removeFilterDefinition(cFilter);

                if (oFilterGrid)
                  oFilterGrid.dataSource.openQuery();
                else {
                  oThis.useFilter(cDefaultFilter);
                  oThis.setDynSelectOption(cDefaultFilter);
                }

                akioma.notification({ type: 'info', text: akioma.tran('filterManager.filterMessages.success.remove', { defaultValue: 'Selected filter removed.' }) });
                resolve();
              }).fail(() => {
                akioma.notification({ type: 'error', text: akioma.tran('filterManager.filterMessages.error.remove', { defaultValue: 'Could not remove selected filter.' }) });
                reject();
              });
            } else
              reject();
          }
        });
      }
    });
  }

  /**
     * Method for renaming the selected filter
     * @memberOf FilterManager
     */
  renameFilter() {
    const oThis = this,
      oFilterGrid = this._oFilterGrid;

    const cFilter = (oFilterGrid) ? oFilterGrid.dataSource.getSelectedRecord()['filterhdl'] : oThis.getCurrentFilter();
    if (cFilter) {
      let cCurrentText = (oFilterGrid) ? oFilterGrid.dataSource.getSelectedRecord()['description'] : oThis.getFilter(cFilter).Description;
      cCurrentText = cCurrentText.replace(/^#+/g, '').trim();
      akioma.message({
        type: 'input',
        title: akioma.tran('filterManager.filterModal.rename', { defaultValue: 'Rename filter' }),
        text: akioma.tran('filterManager.filterModal.add.text', { defaultValue: 'Filter name' }),
        inputVal: cCurrentText,
        callback: function(result) {
          if (result) {
            let cName = result;
            if (cName.length > 100) {
              akioma.swat.Message.message({ type: 'error', text: akioma.tran('filterManager.filterMessages.error.limit', { defaultValue: 'The name of the filter can have a maximum of 100 characters.' }), modal: true });
              oThis.renameFilter();
              return;
            }

            if (cFilter) {
              // save filter
              const oData = { FilterHdl: cFilter, Name: cName };
              akioma.invokeServerTask({
                name: 'Akioma.Swat.FilterBT',
                methodName: 'RenameFilter',
                paramObj: { plcParameter: oData }
              }).done(() => {
                if (cFilter == oThis._cDefaultFilterHdl)
                  cName = `# ${cName}`;
                const oFilter = oThis.getFilterObject(cFilter);
                oFilter.Description = cName;
                oThis.removeFilterObject(cFilter);
                oThis.addFilterObject(oFilter);

                if (oFilterGrid)
                  oFilterGrid.dataSource.openQuery();
                else
                  oThis.getDynSelectFilter().setFilter(cFilter, cName);

                akioma.notification({ type: 'info', text: akioma.tran('filterManager.filterMessages.success.rename', { defaultValue: 'Filter has been renamed successfully.' }) });
              }).fail(() => {
                akioma.notification({ type: 'error', text: akioma.tran('filterManager.filterMessages.error.rename', { defaultValue: 'Error renaming selected filter.' }) });
              });
            }
          } else
            return;
        }
      });
    }
  }

  /**
     * Method for setting the selected filter as default
     * @memberOf FilterManager
     */
  setDefault() {
    const oThis = this,
      oSelf = this._oGrid,
      oFilterGrid = this._oFilterGrid,
      oSource = oSelf.dataSource;

    const cFilter = (oFilterGrid) ? oFilterGrid.dataSource.getSelectedRecord()['filterhdl'] : oThis.getCurrentFilter();
    if (cFilter) {
      const oData = {
        SDO: oSource.opt.name.toLowerCase(),
        Grid: oSelf.opt.gridName,
        FilterHdl: cFilter
      };
      return akioma.invokeServerTask({
        name: 'Akioma.Swat.FilterBT',
        methodName: 'SetDefaultFilter',
        paramObj: { plcParameter: oData }
      }).done(oResult => {
        // set filter as default filter
        oThis.setDefaultFilterObject(oResult.plcParameter.FilterHdl);

        if (oFilterGrid)
          oFilterGrid.dataSource.openQuery({ repositionTo: oResult.plcParameter.FilterHdl });
        else
          oThis.setDynSelectOption(oResult.plcParameter.FilterHdl);

        akioma.notification({ type: 'info', text: akioma.tran('filterManager.filterMessages.success.default', { defaultValue: 'Selected filter has been set as default filter.' }) });
      }).fail(() => {
        akioma.notification({ type: 'error', text: akioma.tran('filterManager.filterMessages.error.default', { defaultValue: 'Could not set filter as default.' }) });
      });

    }
  }

  /**
     * Method to use a specific filter for filtering
     * @param {string} cFilterHdl The filter handle that will be used
     * @memberOf FilterManager
     */
  useFilter(cFilterHdl) {
    const oThis = this,
      oSelf = this._oGrid;

    const aFilterDefinitions = oThis._aFilterDefinition,
      aFilters = oThis._aFilters,
      oOpen = { filterGo: 1 };

    oSelf.FilterClear();
    oSelf.cSelectedFilterHdl = cFilterHdl;
    oThis.setCurrentFilter(cFilterHdl);

    const oQuery = oSelf.dataSource.getQuery();
    oQuery.clearSort();

    delete oSelf.oSortState;

    // now set filterfields in header
    if (aFilterDefinitions[cFilterHdl])
      oSelf.setFilterFields(aFilterDefinitions[cFilterHdl]);


    const oFilterObj = _.find(aFilters, { FilterHdl: cFilterHdl });
    // set filter batch size and sorting
    if (oFilterObj) {

      if (oFilterObj.Sort.length > 0) {
        oSelf.sort = {
          col: oSelf.dhx.getColIndexById(oFilterObj.Sort.split(',')[0]),
          name: oFilterObj.Sort.split(',')[0],
          dir: oFilterObj.Sort.split(',')[1]
        };

        oSelf.oSortState = {
          state: true,
          iCol: oSelf.sort.col,
          order: oSelf.sort.dir
        };

        oQuery.setSorting([{ field: oSelf.sort.name, direction: oSelf.sort.dir }]);
      }

    }

    oSelf.dataSource.setFilter(oSelf.filter, oSelf);
    oThis.applyPanelFilters();

    oSelf.dataSource.addAfterCatalogAdd(() => {
      if (oSelf.opt.batchingMode) {

        oSelf.dataSource.stop = false;
        oSelf.dataSource.setBatch(0, parseInt(oSelf.iBatchSize));
        oSelf.dataSource.urlQuery.skip = 0;
        oSelf.dataSource.urlQuery.top = parseInt(oSelf.iBatchSize);
        if (oSelf.dataSource.opt.initialFetch !== '#none' && !oSelf.dataSource.bInitialFetchedReq)
          oSelf.dataSource.openQuery({});
        else
          oSelf.dataSource.bInitialFetchedReq = false;

      } else {
        oSelf.dataSource.setBatch(0, parseInt(oSelf.iBatchSize));
        oSelf.dataSource.openQuery(oOpen);
      }
      oSelf._renderMultiSelectStateAfterFill();
    });

    // remove dirty state
    oSelf._dispatch('decrementHasChanges', 1);

    oSelf._commit('CLEAR_CHANGED_ROWS');

  }

  /**
     * Method to apply checkbox filters on the Datasource
     * @memberOf FilterManager
     */
  applyPanelFilters() {
    const oSelf = this._oGrid,
      panelFilters = oSelf.FilterManager.getPanelFilter(true),
      oQuery = oSelf.dataSource.getQuery();

    if (oSelf.aCheckboxFilters) {
      oSelf.aCheckboxFilters.forEach(filter => {
        oQuery.removeCondition(filter.field, filter.operator, filter.value);
      });
    }

    oSelf.aCheckboxFilters = [];
    for (const el in panelFilters) {
      for (const filter in panelFilters[el]) {
        const oFilter = panelFilters[el][filter];
        oSelf.aCheckboxFilters.push(oFilter);
        if (oSelf._checkIfDynSelectFilter(oFilter.field)) {
          if (!oQuery.hasCondition(oFilter.field, oFilter.operator, oFilter.value))
            oQuery.addCondition(oFilter.field, oFilter.operator, oFilter.value);
        } else
          oQuery.addUniqueCondition(oFilter.field, oFilter.operator, oFilter.value);
      }
    }
  }

  getFilterObject(cFilterHdl) {
    for (const filter of this._aFilters) {
      if (filter.FilterHdl == cFilterHdl)
        return filter;
    }
    return null;
  }

  addFilterObject(oFilter) {
    this._aFilters.push(oFilter);
  }

  removeFilterObject(cFilterHdl) {
    for (let i = 0; i < this._aFilters.length; i++) {
      if (this._aFilters[i].FilterHdl == cFilterHdl)
        this._aFilters.splice(i, 1);
    }
  }

  setDefaultFilterObject(cFilterHdl) {

    for (const filter of this._aFilters) {
      if (filter.FilterHdl == this._cDefaultFilterHdl) {
        filter.IsDefaultFilter = false;
        filter.Description = filter.Description.replace(/^#+/g, '').trim();
      }
      if (filter.FilterHdl == cFilterHdl) {
        filter.IsDefaultFilter = true;
        filter.Description = `# ${filter.Description}`;
      }
    }
    this.setDefaultFilter(cFilterHdl);
  }

  addFilterDefinition(cFilterHdl, oFilter) {
    if (!this._aFilterDefinition[cFilterHdl])
      this._aFilterDefinition[cFilterHdl] = [];
    this._aFilterDefinition[cFilterHdl].push(oFilter);
  }

  removeFilterDefinition(cFilterHdl) {
    if (this._aFilterDefinition[cFilterHdl])
      delete this._aFilterDefinition[cFilterHdl];
  }

  /**
     * Helper function for panel filters
     * @param {string}cName cName The name of required panel filter
     * @param {array}aFilters aFilters The array that needs to be filled
     * @private
     * @memberOf FilterManager
     */
  _addFiltersHelper(cName, aFilters) {
    for (const filter in this._aPanelFilters[cName].filters) {
      if (!aFilters[filter])
        aFilters[filter] = this._aPanelFilters[cName].filters[filter].slice();
      else {
        for (const filterObj of this._aPanelFilters[cName].filters[filter])
          aFilters[filter].push(filterObj);

      }
    }
  }

  destroy() {
  //       this.off(eventName, callbacks[eventName])
  //       let externalScreens = akioma.ExternalScreen.externalScreens;

  //       const indexExternal = externalScreens.indexOf(this);
  //       externalScreens.splice(indexExternal, 1);
    // this._listener = null;
    // this._emitter = null;
  }
};

