/**
 * AK_Controller class
 * @class  AK_Controller
 */
// eslint-disable-next-line no-unused-vars
const AK_Controller = function() {
  this.trace = false;
  this.aContainersOpened = [];
  this.enableLoadContainer = false; // used to stop loading
  this.aInsOpened = [];
  this.aInsWinObjects = {};
  this.aDataSources = [];
  this.aRepoObjects = [];

  this.getContainersOpened = function() {
    return this.aContainersOpened;
  };

  this.setObjectAutoAdd = function(self) {
    const oSelf = self;
    // create new business entity record if autoadd option is true
    const BE = oSelf.dynObject.getLink('PRIMARYSDO:TARGET').controller;

    const addNewRecord = function(BE) {
      // create default object from businessentity schema
      const newRecordObj = {};

      if (akioma.accordionCopyRecID)
        newRecordObj.SmartCopiedFrom = akioma.accordionCopyRecID;

      // add new record
      const iIndex = BE.dhx.add(newRecordObj);
      BE.dhx.setCursor(iIndex);

      if (akioma.accordionCopyRecID)
        delete akioma.accordionCopyRecID;

    };

    // add if already loaded or add after load
    if (BE.jsdo == undefined) {
      BE.addAfterCatalogAdd(() => {
        addNewRecord(BE);
      });
    } else
      addNewRecord(BE);

  };

  this._replaceLinks = function(data) {
    try {
      const newid = dhtmlx.uid();
      const findAndReplace = function(data) {
        // search for channel target or channelSrc attribute
        if (data.att.dataSource != undefined)
          data.att.linkid = data.att.dataSource + newid;

        if (data.att.channelSrc != undefined || data.att.channelTrg != undefined) {
          // if present replace with uid generated and save pointer for new name with uid

          // replace name with newid append
          if (data.att.linkid == undefined)
            data.att.linkid = data.att.name + newid;

          const objChannelSrc = data.att.channelSrc;
          const objChannelTrg = data.att.channelTrg;
          if (objChannelSrc) { // replace channelSrc attributes with newid append
            const aMultiChannels = objChannelSrc.split(',');
            let cNewFinalChanSrc = '';
            const aNewFinalChanSrc = [];
            for (const i in aMultiChannels) {
              const cCurChannelSrc = aMultiChannels[i];
              const aCurrChannelParts = cCurChannelSrc.split(':');
              aCurrChannelParts[2] = aCurrChannelParts[2] + newid;
              aNewFinalChanSrc.push(aCurrChannelParts.join(':'));
            }
            cNewFinalChanSrc = aNewFinalChanSrc.join(',');
            data.att.channelSrc = cNewFinalChanSrc;
          }

          if (objChannelTrg) { // replace channelTrg attributes with newid append
            const aMultiChannels = objChannelTrg.split(',');
            let cNewFinalChanTrg = '';
            const aNewFinalChanTrg = [];
            for (const i in aMultiChannels) {
              const cCurChannelSrc = aMultiChannels[i];
              const aCurrChannelParts = cCurChannelSrc.split(':');
              aCurrChannelParts[2] = aCurrChannelParts[2] + newid;
              aNewFinalChanTrg.push(aCurrChannelParts.join(':'));
            }
            cNewFinalChanTrg = aNewFinalChanTrg.join(',');
            data.att.channelTrg = cNewFinalChanTrg;
          }
        }
        if (data.sub.length > 0) {
          for (const j in data.sub)
            findAndReplace(data.sub[j]);

        }
      };
      findAndReplace(data);
    } catch (e) {
      console.warn('Find and replace links error', e);
    }
  };

  /**
   * Method launchContainer will request for repository objects
   * @memberOf AK_Controller
   * @param  {object} oElm List of launchContainer options
   * @param {string} oElm.containerName The name of the object from the repository to load. </br>
   The initial page can be specified using a '|' after the containerName, like this: 'customerScreen|20' or 'customerScreen|pagekey20'. Check startingPage attribute for more details. </br>
  The containerName can also be a function. In that function, the actual containerName will need to be set. StartingPage attribute can also be set in the function.
  * @param {string} oElm.pages The initial pages loading interval, eg. <code>0,1</code>
  * @param {boolean} oElm.dynGuid Use the dynamic guid. </br>
  * Useful when loading a tab for eg, where you could load the same repository object multiple times and you need the links to be unique
  * @param {object} oElm.parentControl The dynObject or controller in which the launched container will be added
  * @param {string} oElm.containerName The name of the container, used for displaying in different views inside panels
  * @param {object} oElm.caller The caller object, from where the launchContainer is runned
  * @param {object} oElm.launchedFrom The object from where the launchContainer is runned (used mostly for buttons in Ribbon/Toolbar).
  * @param {boolean} oElm.autoAdd Automatically add a new record on the resulting screen PrimarySDO:SRC BusinessEntity
  * @param {boolean} oElm.fetchOnInit If the new repository screen should load its data or not, calls BE openQuery.
  * @param {string} oElm.view The name of the view to use to load the new repository object in. Requires target also.</br>
  * @param {object} oElm.params The list of parameters for launchContainer request
  * @param {boolean} oElm.activation Default value is "true", that means that when opening the new repository object the user will be switched to Non-Desktops mode.
  * if specified "false" then it will not switch to the Non-Desktop mode but will open the corresponding object window in Non-Desktop.
  * @param {boolean} oElm.noContextSwitch Default value is "false", that means the repository object will be opened in the current active view, desktop or non-desktop if it
  * is modal and if it is not modal then it will always get opened in the non-desktop mode, unless we specify noContextSwitch to be "true". In this case it will open in the
  * current active context when launched, desktop or non-desktop.
  * @param {boolean} oElm.allowMultipleInstances If set to "true", multiple instances will be allowed. If set to "false", only one instance will be allowed to be opened at one moment.
  * @param {string} oElm.params.TypeKey
  * @param {string} oElm.params.SelfHdl
  * @param {string} oElm.params.Datasource
  * @param {string} oElm.params.TargetId
  * @param {string} oElm.repositionTo
  * Automatically position the PRIMARYSDO:SRC businessEntity to the SelfHdl given value. </br>
  * The value can also be a method that returns the repositionTo value. for eg. <code>$ akioma.getSelfHdlToPosition(self) </code>
  * @example
  * //Where self will represent the businessEntity
  * akioma.getSelfHdlToPosition = function(self){
  *
  * var oWin = self.controller.getDescendant('window');
  * if(oWin.opt.name == 'TestWindow'){
  * return '123131';
  * }
  *
  *
  * }
  *
  * @param {string|object} oElm.customData Contains the information for repositioning in a businessEntity. If it starts with '$' it will be interpreted as a function which will return the actual value of customData.
  * <br> <a href="/sports-webui/docs/Order.js.html#line39" target="_blank">CustomData example </a> </br>
  * @param {string} oElm.foreignKeyProvider Used for setting the foreign keys on the PRIMARYSDO BusinessEntity after loading the repository object. </br>
  * <a href="/sports-webui/docs/Order_getOrderForeignFields.ts.html" target="_blank">ForeignKeys Example</a> </br>
  * This Sample can be tested in the Customer-Desktop, in the FAB select "+" menu item
  * @param {integer|string} oElm.startingPage Specifies the initial page selected after launching a container. It can be the position of page (index starting from 1) OR PageKey attribute
  * @return {promise}      Returns a Promise
  */
  this.launchContainer = function(oElm, cPosToRecord) {
    if (typeof (oElm) == 'string') {
      oElm = { containerName: oElm };

      if (cPosToRecord && typeof (cPosToRecord) == 'string')
        oElm.positionToRecord = cPosToRecord;

    } else if (oElm.proc)
      oElm.containerName = oElm.proc;

    this.enableLoadContainer = true;

    // launchContainer support for JS Function
    if (oElm.containerName && oElm.containerName.charAt(0) == '$')
      this.callAkiomaCode(oElm, oElm.containerName);


    // check for starting page
    if (oElm.containerName && oElm.containerName.indexOf('|') > -1) {
      const cParts = oElm.containerName.split('|');
      const cStartingPage = Number(cParts[1]);
      oElm.startingPage = (isNaN(cStartingPage)) ? cParts[1] : cStartingPage;
      oElm.containerName = cParts[0];
    }

    // if old para read and check for protected
    let oParams;
    if (oElm.para) {
      oParams = oElm.para.split('&').reduce((prev, curr) => {
        const p = curr.split('=');
        prev[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
        return prev;
      }, {});
    }

    if (oElm.proc != undefined)
      oElm.proc = oElm.proc.replace('.r', '');

    if (oElm.parentControl)
      oElm.target = oElm.parentControl;

    // set def multipleInstances
    if (oElm.allowMultipleInstances == undefined)
      oElm.allowMultipleInstances = false;

    // block launchContainer if disallowed Multiple Instances
    if (!oElm.allowMultipleInstances) {

      let uuid = '';

      // if container name from proc not an include
      if (oElm.proc && oElm.proc !== 'include' && oElm.target == undefined)
        uuid += oElm.proc;


      // if container name and not included in target
      if (oElm.containerName && oElm.proc !== 'include' && oElm.target == undefined) {
        uuid = '';
        uuid += oElm.containerName.replace('.r', '');
      }

      if (oElm.repositionTo && uuid !== '') {
        uuid += oElm.repositionTo;
        // might have dynamic repositionTo
        if (oElm.repositionTo.includes('$'))
          uuid = '';
      } else if (typeof (oElm.customData) !== 'undefined' && typeof (oElm.customData.childHdl) !== 'undefined'
          && oElm.customData.childHdl !== '' && oElm.customData.childHdl !== null && uuid !== '')
        uuid += oElm.customData.childHdl;
      else if (typeof (oParams) !== 'undefined' && uuid !== '') {
        if (oParams.SelfHdl !== undefined && oParams.SelfHdl.length > 0)
          uuid += oParams.SelfHdl;

      } else if (oElm.extLink !== undefined && oElm.extLink.length > 0 && uuid !== '')
        uuid += oElm.extLink;


      if (uuid !== '') {

        try {
          if (this.aInsOpened.indexOf(uuid) > -1) {

            if (this.aInsWinObjects[uuid]) {
              const oWin = this.aInsWinObjects[uuid];

              const oWinsFrame = $(oWin.parent.dhx.vp);

              // toggle only if window is from the non-desktop mode and hidden
              if (oWinsFrame.attr('id') == 'mw-wins-container' && oWinsFrame.css('opacity') == '0')
                akioma.toggleAkMultiWindow(false);


              oWin.dhx.bringToTop();
            }

            return false; // stop
          }
        } catch (e) {
          console.warn(e);
        }

        // new instance added
        this.aInsOpened.push(uuid);

        oElm.ContainerUUID = uuid;
      }

    }

    if ((this.enableLoadContainer || oElm.newRepoLoad == true)) { // if not protected
      // load from SmartRepository
      return this.loadContainer(oElm); // load from the new repo format
    }

  };

  /**
   * Method to return new object container type based on target/container
   * @memberof AK_Controller
   * @param   {object}  target  Any possible akioma control
   * @private
   * @instance
   * @return  {string}          Type of container, window or frame
   */
  this._getObjectTypeByContainer = function(target, originalType) {
    if (isNull(target) || target.windowContainer)
      return 'window';

    // if container is frame container
    if (target.frameContainer)
      return 'frame';

    return originalType;
  };
  /**
   * Method loadContainer loads layout from SmartRepository
   * @private
   * @param  {object} oElm
   * @memberOf AK_Controller
   * @return {promise}      Returns a Promise
   */
  this.loadContainer = function(oElm) {
    const oSelf = app.controller,
      deferred = $.Deferred();

    if (!oElm.akEvent)
      oElm.akEvent = {};

    // get target
    let oTarget;
    let oTargetWins;
    if (oElm.target)
      oTarget = oElm.target;
    else if (akioma.cWindowParentCell) {
      // check and set main area viewport for layout windows

      // if there is no main content area defined yet,
      // create a new winframe for the windows parent cell  -> for OSIV UX
      // and create a second winframe for the multiwindow UX Akioma
      if (akioma.oWindowParentCell == undefined && akioma.loadedMultiWindows == undefined) {
        akioma.loadedMultiWindows = true;

        const oCell = akioma.root.getDescendant('panelset').dhx.cells(akioma.cWindowParentCell).cell;

        const cId = `main-content-${dhtmlx.uid()}`;
        $(oCell).attr('id', cId);
        let data = {
          view: 'winframe',
          att: {
            id: 'contentmain',
            name: 'contentmain'
          },
          parent: akioma.windowParentCell,
          parentDOMID: 'mw-main-win-container'
        };

        oTarget = $.dhxObject(data);
        akioma.oWindowParentCell = oTarget;

        data = {
          view: 'winframe',
          att: {
            id: 'contentmainwins',
            name: 'contentmainwins'
          },
          parent: akioma.windowParentCell,
          parentDOMID: 'mw-wins-container'
        };
        oTargetWins = $.dhxObject(data);
        akioma.oWindowsParentCell = oTargetWins;
      } else if (akioma.oWindowParentCell.childs.length <= 0)
        oTarget = akioma.oWindowParentCell;
      else
        oTarget = akioma.oWindowsParentCell;

      oTarget.parent = akioma.windowParentCell;

      try { // this happens only when first initializing contentmainwins
        if (oTargetWins != undefined)
          oTargetWins.parent = akioma.windowParentCell;
      } catch (e) {
        akioma.log.error(e);
      }
    } else
      oTarget = oDhxTree;


    try {
      // check if we have to delete target
      // this is used when there are existing elements inside target and we want to clean it up
      if (oElm.view == undefined) {
        if (oTarget != akioma.mainWindows.akElm && oTarget.children) {
          const iInd = oTarget.childs.length;
          const childs = oTarget.childs.slice();
          for (let o = 0; o < iInd; o++) {
            const el = childs[o];
            if (el && el.destruct)
              el.destruct();
          }

        }
      }

      // if view options specified then show that view
      if (oElm.view != undefined)
        oElm.target.showView(oElm.view);


      if (oTargetWins != undefined) {
        // check if we have to delete target
        if (oTargetWins != oDhxTree && oTargetWins.children) {
          oTargetWins.children(function() {
            this.destruct();
          });
        }
      }
    } catch (e) {
      akioma.log.error(e);
    }

    // if parsed like the old style
    let cRun = oElm.containerName;
    if (oElm.para && oElm.para.indexOf('RunFile=') > -1)
      cRun = oElm.para.split('&')[0].split('RunFile=')[1];

    if (cRun)
      cRun = cRun.replace('.r', '');
    else
      cRun = oElm.containerName;

    let cPages = oElm.pages;
    const oParams = {};
    // check for old params sent via oElm.para
    if (oElm.para) {

      const oElmParams = oElm.para.split('&').reduce((prev, curr) => {
        const p = curr.split('=');
        prev[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
        return prev;
      }, {});

      if (oElmParams.Page)
        cPages = oElmParams.Page;


      if (!oElm.params)
        oElm.params = {};

      if (oElmParams.TypeKey)
        oElm.params.TypeKey = oElmParams.TypeKey;

      if (oElmParams.Datasource)
        oElm.params.Datasource = `${oElmParams.Datasource}|${oElm.self.opt._ContainerInstanceGuid}`;

      if (oElmParams.selfHdl)
        oElm.params.SelfHdl = oElmParams.selfHdl;

      if (oElmParams.TargetId)
        oElm.params.TargetId = oElmParams.TargetId;
    }

    if (oElm.params) {

      if (oElm.params.TypeKey)
        oParams.TypeKey = oElm.params.TypeKey;

      if (oElm.params.SelfHdl)
        oParams.SelfHdl = oElm.params.SelfHdl;

      if (oElm.params.Datasource)
        oParams.Datasource = oElm.params.Datasource;

      if (oElm.params.TargetId)
        oElm.TargetId = oElm.params.TargetId;
    }

    // setup mapping to fetchOnInit
    if (oElm.data)
      oElm.fetchOnInit = oElm.data;

    // setup mapping to caller
    if (oElm.self) {
      oElm.caller = oElm.self;
      oElm.launchedFrom = oElm.launchedFrom || oElm.self;
    }

    // check if pages otherwise set default
    if (!cPages)
      cPages = '0,1';

    // for view mode, when showView from panel and target/containerControl specified
    if (oElm.target && oElm.target.view == 'panel')
      oElm.containerinsguid = oElm.target.getAncestor('frame').opt._ContainerInstanceGuid;


    if (oElm.proc == 'include' || oElm.containerinsguid) {
      const oWinParent = oTarget.getAncestor('window');
      if (oWinParent)
        oParams.ParentObjectName = oTarget.getAncestor('window').opt.name;
      else
        oParams.ParentObjectName = 'root';
    }

    // send context here
    if (oElm.containerinsguid)
      oParams.ContainerInstanceGuid = encodeURI(oElm.containerinsguid);
    else if (oElm.caller && oElm.caller.opt) // setup the containerinsguid from caller
      oElm.containerinsguid = oElm.caller.opt._ContainerInstanceGuid;

    if (oElm.dynGuid)
      oParams.DynamicGuid = encodeURI(dhtmlx.uid());


    // check for foreignKeyProvider
    if (oElm.foreignKeyProvider) {
      try {
        let self = oElm.self || oElm.caller;

        if (self && self.dynObject)
          self = self.dynObject;

        oElm.akEvent.foreignKey = akioma.swat.evaluateCode({ code: oElm.foreignKeyProvider.substr(1), controller: self.controller });
      } catch (e) {
        akioma.notification({ type: 'error', text: `Error when calling foreignKeyProvider method call: ${oElm.foreignKeyProvider}` });
      }
    }

    // hardcode pages for offerw
    //
    if (oElm.containerName && oElm.containerName.toLowerCase() == 'offerw')
      cPages = '0,1,2';


    if (oElm.containerName && oElm.containerName.toLowerCase() == 'salesconfmaintw.r')
      cPages = '*';


    if (cRun !== undefined && cRun.toLowerCase() == 'layoutdesignerw')
      cPages = '*';


    if (cRun !== undefined && cRun.toLowerCase() == 'menufunctiondetailwindow')
      cPages = '*';


    if (cRun !== undefined && cRun.toLowerCase() == 'menuitemf')
      cPages = '*';


    // now make the request
    const loadRepoPromise = $.ajax({
      type: 'GET',
      url: `/web/Repository/Container/${oElm.extLink || cRun}/${cPages}`,
      data: oParams,
      dataType: 'json',
      error: (xhr, textStatus, errorThrown) => {
        if (!isNull(oElm.ContainerUUID) && this.aInsOpened.indexOf(oElm.ContainerUUID) > -1)
          this.aInsOpened.splice(this.aInsOpened.indexOf(oElm.ContainerUUID), 1);
        console.error(`Error loading html from '${cRun}': ${textStatus} -> ${errorThrown}`);
        deferred.reject();
      }
    });


    $('body').addClass('cursor-progress');
    try {
      if (oElm.target && oElm.target.dhx.progressOn)
        oElm.target.dhx.progressOn();
    } catch (e) {
      console.warn('Could not set progress state for target: ', oElm.target);
    }

    // if repo object loaded successful or in case of error, in target set progress state off
    loadRepoPromise.always(() => {
      $('body').removeClass('cursor-progress');
      try {
        if (oElm.target && oElm.target.dhx.progressOff)
          oElm.target.dhx.progressOff();
      } catch (e) {
        console.warn('Could not set progress state for target: ', oElm.target);
      }
    });

    // after loading
    loadRepoPromise.done(data => {
      const oPromiseTransformStruct = oSelf._onLoadContainerSuccess(data, oElm, cPages);
      oPromiseTransformStruct.then(res => {
        oSelf.waitForData(res, oElm);
        deferred.resolve(res);
      });
    }).fail((data, textStatus) => {

      if (data.status === 500)
        data.statusText = 'Internal server error';

      if (textStatus !== 'abort')
        oSelf._onLoadContainerError(data, cRun);

      deferred.reject();
    });

    return deferred.promise();
  };

  /**
   * Method used for showing wait state until the fill of the JSDO
   * @instance
   * @memberof AK_Controller
   * @param {object} res
   */
  this.waitForData = function(res, oElm) {
    try {
      const businessEntity = res.dynObject.getLink('PRIMARYSDO:TARGET');
      if (businessEntity && businessEntity.controller.opt.initialFetch.toLowerCase() != '#none'
&& !oElm.autoAdd && !businessEntity.controller.stop && !businessEntity.controller._pendingrequest) {

        akioma.WaitCursor.showWaitState(res.dynObject);

        businessEntity.controller.callAfterPendingRequest(() => {
          try {
            akioma.WaitCursor.hideWaitState(res.dynObject);
          } catch (e) {
            console.warn('Could not set wait state for newly loaded repository object: ', e, res);
          }
        });
      }
    } catch (e) {
      akioma.WaitCursor.hideWaitState(res.dynObject);
      console.warn('Could not set wait state for newly loaded repository object: ', e, res);
    }
  };

  /**
   * Method _onLoadContainerError called when an error occurs when requesting a repository object
   * @private
   * @memberOf AK_Controller
   * @param  {string} reponame The name of the repository that failed to load
   */

  this._onLoadContainerError = function(data, cRepository) {
    const cText = `Could not load "${cRepository}"!`;
    akioma.notification({ type: 'error', text: cText });
  };

  // creates mapping of links
  this._addLinks = function(oLinks, cMasterGuid) {
    const aSourceToLink = [];
    const aTrgToLink = [];
    const oNewLinks = [];
    for (const l in oLinks) {
      const oLink = oLinks[l];

      // add link source
      // if(oLink.SourceObjectInstanceGuid){
      if (oLink.SourceObjectInstanceGuid == '')
        oLink.SourceObjectInstanceGuid = cMasterGuid;

      if (aSourceToLink[oLink.SourceObjectInstanceGuid] == undefined)
        aSourceToLink[oLink.SourceObjectInstanceGuid] = [];

      aSourceToLink[oLink.SourceObjectInstanceGuid].push(oLink.LinkGuid);
      // }

      // add link target
      if (oLink.TargetObjectInstanceGuid) {
        if (aTrgToLink[oLink.TargetObjectInstanceGuid] == undefined)
          aTrgToLink[oLink.TargetObjectInstanceGuid] = [];

        aTrgToLink[oLink.TargetObjectInstanceGuid].push(oLink.LinkGuid);
      }

      // add link guid
      const guid = oLink.LinkGuid;

      oNewLinks[guid] = oLink;

    }
    app.controller.oLinks = {
      'master': cMasterGuid,
      'sources': aSourceToLink,
      'targets': aTrgToLink,
      'links': oNewLinks
    };
  };

  /**
   * Method for retrieving the objects unique by type, from a given repository structure
   * @private
   * @memberof AkController
   * @param {object} oRepositoryData Repository data
   * @param {array<string>} objectTypes Object types, optional
   * @returns {array<ak_object>}
   */
  this._getObjectTypes = function(oRepositoryData, objectTypes = []) {

    if (!objectTypes.find(obj => obj.objectType === oRepositoryData.objectType))
      objectTypes.push(oRepositoryData);

    if (oRepositoryData.children) {
      oRepositoryData.children.forEach(repoObj => {
        if (!objectTypes.find(obj => obj.objectType === repoObj.objectType))
          objectTypes.push(repoObj);

        if (repoObj.children) {
          repoObj.children.forEach(childRepo => {
            objectTypes = this._getObjectTypes(childRepo, objectTypes);
          });
        }
      });
    }

    return objectTypes;
  };

  /**
   * Method called on successful request for loading of a repository object.
   * @private
   * @memberOf AK_Controller
   * @param  {object} oData JSON data format
   * @param  {object} oElm
   * @param  {string} cPages pages sent in request
   * @return {any}
   */
  this._onLoadContainerSuccess = async function(oData, oElm, cPages) {
    const deferred = $.Deferred();
    const oAKController = this;

    // replace __ID__ occurences with guid
    const res = JSON.stringify(oData);
    const cRes = res.replace(new RegExp('__id__', 'g'), () => dhtmlx.uid());
    oData = JSON.parse(cRes);

    oData.objectType = this._getObjectTypeByContainer(oElm.target, oData.objectType);
    const objectTypes = this._getObjectTypes(oData);
    await akioma.ScriptsLoader.loadObjectScriptsByType(objectTypes);
    const cObjRenderType = oData.objectType;
    // root object here
    const oStrucObjs = {
      'view': cObjRenderType,
      'att': {
        'name': oData.name,
        'id': oData.name
      },
      'sub': []
    };

    if (oData.securityRestrictions)
      oStrucObjs.securityRestrictions = oData.securityRestrictions;

    // apply links using attributes, our safest bet for demo
    // extend root with attributes
    let oAttrs = {};
    if (oElm.caller && oElm.proc == 'include') {
      const oContainer = oElm.caller.dynObject.container.controller;
      const oContainerAttrs = {};

      if (oContainer) {
        for (const key of Object.keys(oContainer.opt)) {
          if (key.indexOf('_') === 0 || key === 'hasChangesStyle')
            oContainerAttrs[key] = oContainer.opt[key];
        }
        oAttrs = $.extend(oStrucObjs.att, oContainerAttrs, oData.attributes);
      }
    } else
      oAttrs = $.extend(oStrucObjs.att, oData.attributes);

    oStrucObjs.att = oAttrs;
    if (oStrucObjs.att._ContainerInstanceGuid) {

      oStrucObjs.att.linkid = `|${oStrucObjs.att._ContainerInstanceGuid}`;
      if (oStrucObjs.att._DynamicGuid != undefined && oStrucObjs.att._DynamicGuid != '')
        oStrucObjs.att.linkid += `|${oStrucObjs.att._DynamicGuid}`;
    }

    let oParent = oStrucObjs;

    // in special case, window, add panelset
    // this might be needed for frame too
    if (cObjRenderType == 'window' || cObjRenderType == 'frame') {
      const oPanelSet = {
        'view': 'panelset',
        'att': {
          'name': `${oData.name}_panelset`,
          'layout': oData.attributes.Layout
        },
        'sub': []
      };
      oStrucObjs.sub.push(oPanelSet);

      oParent = oStrucObjs.sub[oStrucObjs.sub.indexOf(oPanelSet)];
    }

    const aDataSources = [];
    const aPanelCellLayouts = 'a,b,c,d,e,f,g,h,i,j,k,l,m,n,o,p,q,r,s,t,u,v,w,x,y,z'.split(',');
    function addChildren(oData, oParent) {

      const aRoots = [];
      let iPanelCells = 0;
      for (const i in oData.children) {
        const oChildObj = oData.children[i];

        const cChildType = oChildObj.objectType;
        let oChildStruct = null;


        if (cChildType == 'fieldset' || cChildType == 'block')
          oChildObj.attributes.instanceRestrictions = oParent.att.instanceRestrictions;
        if (oParent.att.instanceRestrictions && oChildObj.attributes.InstanceName) {
          if (akioma.canDo(oParent.att.instanceRestrictions, oChildObj.attributes.InstanceName))
            continue;
        }

        if (akioma.getSessionProperty('restrictedObjects') && oChildObj.attributes.ObjectMasterName) {
          if (akioma.canDo(akioma.getSessionProperty('restrictedObjects'), oChildObj.attributes.ObjectMasterName))
            continue;
        }

        if (cChildType == 'tab') {

          const cObjectPages = cPages || '';
          if (oChildObj.children.length > 0) {
            oChildStruct = {
              'view': cChildType,
              'att': {
                'name': oChildObj.name,
                'objectName': oChildObj.objectName
              },
              'sub': [
                {
                  'view': 'panelset',
                  'att': {
                    'id': `${oChildObj.guid}_panelset`,
                    'name': `${oChildObj.name}_panelset`,
                    'layout': oChildObj.attributes.Layout
                  }
                }
              ]
            };

            oChildStruct.init = true;
          } else {
            oChildStruct = {
              'view': cChildType,
              'att': {
                'name': oChildObj.name,
                'objectName': oChildObj.objectName
              },
              'sub': []
            };

            oChildStruct.init = cObjectPages.includes(oChildObj.attributes.page) || cObjectPages == '*' ? true : false;
          }

          const oAttrs = $.extend(oChildStruct.att, oChildObj.attributes);
          oChildStruct.att = oAttrs;

          let children = null;
          if (oData.children.length > 0)
            children = addChildren(oChildObj, oChildStruct);


          if (children.length)
            oChildStruct.sub[0].sub = children;


        } else if (cChildType == 'frame') {
          oChildStruct = {
            'view': cChildType,
            'att': {
              'name': oChildObj.name,
              'objectName': oChildObj.objectName
            },
            'sub': [
              {
                'view': 'panelset',
                'att': {
                  'id': `${oChildObj.guid}_panelset`,
                  'name': `${oChildObj.name}_panelset`,
                  'layout': oChildObj.attributes.Layout
                }
              }
            ]
          };

          const oAttrs = $.extend(oChildStruct.att, oChildObj.attributes);
          oChildStruct.att = oAttrs;

          let children = null;
          if (oData.children.length > 0)
            children = addChildren(oChildObj, oChildStruct);


          if (children)
            oChildStruct.sub[0].sub = children;

        } else {
          oChildStruct = {
            'view': cChildType,
            'att': {
              'name': oChildObj.attributes.InstanceName,
              'objectName': oChildObj.objectName
            },
            'sub': []
          };

          if (cChildType == 'panel') {
            oChildStruct.att.layout = aPanelCellLayouts[iPanelCells];
            iPanelCells++;
          }

          const oAttrs = $.extend(oChildStruct.att, oChildObj.attributes);
          oChildStruct.att = oAttrs;

          let children = null;
          if (oData.children.length > 0)
            children = addChildren(oChildObj, oChildStruct);

          if (children)
            oChildStruct.sub = children;

        }

        if (oChildStruct.att._ContainerInstanceGuid) {
          oChildStruct.att.linkid = `|${oChildStruct.att._ContainerInstanceGuid}`;
          if (oChildStruct.att._DynamicGuid != undefined && oChildStruct.att._DynamicGuid != '')
            oChildStruct.att.linkid += `|${oChildStruct.att._DynamicGuid}`;
        }

        if (cChildType == 'datasource' || cChildType.toLowerCase() == 'businessentity' || cChildType.toLowerCase() == 'businessentity2')
          aDataSources.push(oChildStruct);
        else
          aRoots.push(oChildStruct);

      }

      return aRoots;
    }

    const struct = addChildren(oData, oParent);
    oParent.sub = struct;
    aDataSources.push(...oStrucObjs.sub);

    oStrucObjs.sub = aDataSources;

    // get target, context switching for desktop or non desktop views
    const bDesktopsMode = !akioma.toggleFlag;
    const bNoContextSwitch = oElm.noContextSwitch;

    let oTarget;
    if (akioma.oWindowParentCell) {
      if (bNoContextSwitch) {
        if (bDesktopsMode)
          oTarget = akioma.oWindowParentCell;
        else
          oTarget = akioma.oWindowsParentCell;

        oElm.activation = false;
      } else if (akioma.oWindowParentCell.childs.length <= 0)
        oTarget = akioma.oWindowParentCell;
      else
        oTarget = akioma.oWindowsParentCell;
    } else
      oTarget = akioma.oWindowParentCell;


    const bToggleEnabled = (oElm.skipDesktopToggle == undefined ? true : !oElm.skipDesktopToggle);

    if (oElm.containerUserData && oElm.containerUserData.type == 'widget')
      oStrucObjs.att.bOpenInHomeView = false;
    else if (oElm.proc != 'include.r' && oElm.proc != 'popup.r' && bToggleEnabled)
      oStrucObjs.att.bOpenInHomeView = true;


    akioma.makeActiveOpenedWindow = oElm.activation;
    if (akioma.makeActiveOpenedWindow == undefined)
      akioma.makeActiveOpenedWindow = true;


    // parse struct
    let oNew;
    if (oElm.untilParent != undefined)
      oNew = app.controller.parseProc(oStrucObjs, oTarget);
    else {
      if (oStrucObjs.view == 'window' && oStrucObjs.att.modal) {
        if (!akioma.toggleFlag)
          oTarget = akioma.oWindowParentCell;
        else
          oTarget = akioma.oWindowsParentCell;
      }

      if (oElm.target)
        oTarget = oElm.target;

      oNew = app.controller.parseProc(oStrucObjs, oTarget, false, oElm);
    }


    // setup tree targetId for drop operation
    if (oElm.TargetId)
      oNew.opt.targetId = oElm.TargetId;


    if (oElm.view)
      oNew.inParentView = oElm.view;


    const aDynLinks = oElm.dynamicLinks;
    if (aDynLinks && aDynLinks.length > 0) {

      for (const d in aDynLinks) {
        const oDynLink = aDynLinks[d];
        const cLinkSrc = oDynLink.src;
        const cLinkTrg = oDynLink.trg;
        const oLinkSrc = oNew.dynObject.container.getObject(cLinkSrc);
        const cLinkName = cLinkSrc + oLinkSrc.controller.opt.linkid;
        const oLinkTrg = oNew.dynObject.container.getObject(cLinkTrg);

        if (oLinkTrg) {
          oLinkSrc.dynObjName = cLinkName;

          // app.messenger.subscribe( oLinkTrg, 'DATA:SRC:' + cLinkName );
          oLinkTrg.controller.dataSource = oLinkSrc.controller;
          oLinkSrc.controller.addObserver(oLinkTrg.controller);
          app.messenger.subscribe(oLinkTrg, `DATA:TRG:${cLinkName}`);
          delete aDynLinks[d];// remove so we don't add it
        }

      }

    }

    // check for caller
    if (oElm.caller && oNew.dynObject) {
      oNew.caller = oElm.caller;
      if (oNew.caller.view == 'panel')
        oNew.caller = oNew.caller.parent;
      if (oNew.caller.view == 'panelset')
        oNew.caller = oNew.caller.parent;

      if (oElm.caller.dynObject)
        oNew.dynObject.caller = oElm.caller.dynObject;
      else
        oNew.dynObject.caller = oElm.caller;
    }

    if (oElm.launchedFrom && oNew.dynObject) {
      oNew.launchedFrom = oElm.launchedFrom;
      oNew.dynObject.launchedFrom = (oElm.launchedFrom.dynObject) ? oElm.launchedFrom.dynObject : oElm.launchedFrom;
    }

    // check if still no caller then automatically set same object as the caller
    if (oElm.caller == undefined) {
      if (oNew.dynObject)
        oNew.caller = oNew.dynObject;
      else
        oNew.caller = oNew;
    }

    // reposition selfhdl/identifier of primarysdo
    if (oElm.repositionTo) {

      oNew.children('businessEntity', function() {
        const oPrimarySrcLink = this.dynObject.getLink('PRIMARYSDO:SRC');
        if (oPrimarySrcLink) {

          if (oPrimarySrcLink && oPrimarySrcLink.controller == oNew) {
            let cRepositionVal = oElm.repositionTo;
            try {
              // if function to reposition execute method
              if (oElm.repositionTo.substr(0, 1) == '$') {
                const self = oPrimarySrcLink;
                cRepositionVal = akioma.swat.evaluateCode({ code: `(${cRepositionVal.substr(1)})`, dynObj: self });
              }
              this.query.addCondition(this.opt.identifier || 'SelfHdl', 'eq', cRepositionVal);
            } catch (e) {
              akioma.notification({ type: 'error', text: `Could not reposition PRIMARYSDO:SRC to: ${cRepositionVal}`, expire: 8000 });
            }

          }
        }


      });
    }

    // check for foreignKey Provider and set the foreignKeys on the BE if found.
    if (oElm.foreignKeyProvider) {
      oNew.children('businessEntity', function() {

        const oPrimarySrcLink = this.dynObject.getLink('PRIMARYSDO:SRC');
        if (oPrimarySrcLink) {

          if (oPrimarySrcLink && oPrimarySrcLink.controller == oNew) {
            try {
              if (oElm.akEvent && oElm.akEvent.foreignKey) {
                this.stop = true;
                this.setForeignKeys(oElm.akEvent.foreignKey);
              }

            } catch (e) {
              akioma.notification({ type: 'error', text: `Could not set foreignKeyProvider to: ${oElm.akEvent.foreignKey}`, expire: 8000 });
            }

          }
        }
      });
    }

    // check for position
    if (oNew && oElm.position) {
      switch (oElm.position) {
        case 'fitToLeft':
          oNew.posWindow('left');
          break;
        case 'fitToRight':
          oNew.posWindow('right');
          break;
      }
    }

    // check for initial size
    if (oNew.view == 'window' && oNew.opt.modal == false) {
      if (oElm.initialWidth && oElm.initialHeight)
        oNew.dhx.setDimension(oElm.initialWidth, oElm.initialHeight);
    }

    if (oElm.containerUserData)
      oNew.userData = oElm.containerUserData;


    if (oElm.startingPage)
      oNew.getDescendant('tabbar').setActivePage(oElm.startingPage);

    // event pre init
    if (oNew.opt.onInitPre)
      app.controller.callAkiomaCode(oNew, oNew.opt.onInitPre);

    // add containerUUID to block multiple instances
    if (oElm.ContainerUUID !== undefined) {
      app.controller.aInsWinObjects[oElm.ContainerUUID] = oNew;
      oNew.ContainerUUID = oElm.ContainerUUID;
    }


    // check if we have to add
    if (oElm.add)
      oNew.addRecord(oElm);

    oNew.setQueryParam(oElm);

    // check if we have a debug window
    if (oDebugWindow)
      oDebugWindow.loadDebugTree();


    if (oElm.customData) {
      try {
        const cChildHdl = oElm.customData.childHdl;
        if (cChildHdl && cChildHdl.charAt(0) == '$')
          oElm.customData.childHdl = eval(cChildHdl.substr(1));

        oNew.customData = {};
        for (const k in oElm.customData)
          oNew.customData[k] = oElm.customData[k];

      } catch (e) {
        akioma.notification({ type: 'error', text: `Error on customData attribute: ${oElm.customData}` });
      }
    }

    // auto add record to BE
    if (oElm.autoAdd)
      oAKController.setObjectAutoAdd(oNew);

    // onInit event
    if (oNew.opt.onInit && oNew.view != 'window')
      app.controller.callAkiomaCode(oNew, oNew.opt.onInit);

    try {
      if (oNew.dhx.progressOff)
        oNew.dhx.progressOff();
    } catch (e) {
      console.warn('Could not set progress state for newly loaded repository object: ', oNew);
    }
    try {
      if (oNew.afterStructParsed)
        oNew.afterStructParsed();

    } catch (e) {
      console.warn('Error calling afterStructParsed', oNew);
    }


    deferred.resolve(oNew);

    akioma.eventEmitter.emit([ 'LaunchContainer', 'AfterLoad' ], oNew);

    return deferred.promise();
  };

  // call server method
  /**
 * Method for calling a server method, POST request to /akioma/runRoutine.r
 * @memberOf AK_Controller
 * @param  {string} cProc
 * @param  {array} aPar
 * @param  {boolean} plAsync
 * @param  {function} callbackFunc
 * @return {object}
 */
  this.callServerMethod = function(cProc, aPar, plAsync, callbackFunc) {
    const oData = {},
      cParType = new Array(),
      cDataType = new Array();

    if (!plAsync)
      plAsync = false;

    for (const i in aPar) {
      const oPar = aPar[i];
      const cType = oPar.type.substr(0, 1);
      cParType.push(cType);
      cDataType.push(oPar.type.substr(1));
      const iNum = Number(i) + 1;
      if (cType != 'o') {
        let cValue;
        if ((typeof oPar.value) == 'object') {
          const aArray = [];
          if (oPar.value instanceof Object) {
            for (const i in oPar.value)
              aArray.push(`${i}__#2#__${oPar.value[i]}`);
          } else {
            for (const i in oPar.value) {
              if (oPar.value[i] instanceof Array)
                aArray.push(oPar.value[i].join('__#2#__'));
              else
                aArray.push(oPar.value[i]);
            }
          }
          cValue = aArray.join('__#1#__');
        } else
          cValue = oPar.value;

        oData[`Par${iNum}`] = cValue;
      }
      if (cType != 'i')
        oData[`Name${iNum}`] = oPar.name;

      // check for delimiter
      if (oPar.deli1)
        oData[`Deli1-${iNum}`] = oPar.deli1;
      if (oPar.deli2)
        oData[`Deli2-${iNum}`] = oPar.deli2;
    }

    oData.RunFile = cProc;
    oData.ParType = cParType.join(',');
    oData.ParData = cDataType.join(',');

    let oReturn = {};
    const promise = $.ajax({
      async: plAsync,
      type: 'POST',
      url: '/akioma/runRoutine.r',
      dataType: 'json',
      data: oData,
      success: function(data) {
        oReturn = data;
        if (callbackFunc != undefined)
          callbackFunc(oReturn);
        if (data.errorStatus)
          akioma.notification({ type: 'error', text: data.errorStatus });
      }
    });

    if (plAsync)
      return promise;
    else
      return oReturn;
  };

  // call action ****************************
  /**
 * Method calls Action(PUBLISH,LAUNCH or RUN type) for object
 * @memberOf AK_Controller
 * @param  {object} oOptions The Action object
 * @param  {object} oSelf    The caller object
 */
  this.callAction = function(oOptions, oSelf) {
    // publish
    switch (oOptions.ActionType) {
      case 'PUBLISH': { // fetch messages are different -> caller pulls action from caller
        const cObjectID = oSelf.opt.name + oSelf.opt.linkid;
        if (oOptions.Action.substring(0, 3) == 'nav') {
          app.messenger.publish({
            link: {
              LinkName: oOptions.Category,
              LinkType: 'TRG',
              ObjName: cObjectID
            },
            method: 'fetch',
            caller: oSelf,
            direction: oOptions.Action.substring(3)
          });
        } else {
          app.messenger.publish({
            link: {
              LinkName: oOptions.Category,
              LinkType: 'TRG',
              ObjName: cObjectID
            },
            clickId: oOptions.click,
            method: oOptions.Action,
            caller: oSelf
          });
        }
        break;
      }
      case 'LAUNCH': { // launch program
        let cProc = 'launchContainer.p',
          cPar = '';
        // try to get parameter resolved from parent
        if (oOptions.RunPar) {

          cPar = oOptions.RunPar;
          const aPar = oOptions.RunPar.split('&');

          if (aPar.length > 0) {
            replaceRunTokens(aPar, oSelf);

            // check for runfile
            const i = app.lookup('RunFile', cPar, '&', '=');
            if (i > -1) {
              cProc = aPar[i].split('=')[1];
              if (cProc.split('.').length == 1)
                cProc = `${cProc}.r`;
              aPar.splice(i, 1);
            }
          }
          cPar = aPar.join('&');
        }

        if (oOptions.eventPre) {
          try {
            let isLaunchPrevent = false;
            const eventPreResult = callReturnAkiomaCode(oSelf, oOptions.eventPre);

            if (eventPreResult instanceof Promise) {
              eventPreResult.then(result => {
                if (result && result.preventLaunch)
                  isLaunchPrevent = true;

                if (!isLaunchPrevent)
                  this.openContainer(oOptions, cProc, cPar, oSelf);

              });
            } else if (eventPreResult && eventPreResult.preventLaunch) {
              isLaunchPrevent = true;

              if (!isLaunchPrevent)
                this.openContainer(oOptions, cProc, cPar, oSelf);

            } else
              this.openContainer(oOptions, cProc, cPar, oSelf);


          } catch (e) {
            console.error(e);
          }
        } else
          this.openContainer(oOptions, cProc, cPar, oSelf);


        break;
      }
      // run internal routine (javascript)
      case 'INVOKE':
      case 'RUN':
        app.controller.callAkiomaCode(oSelf, oOptions.Action, oOptions.launchedFrom);
        break;
    }
  };


  /**
   * Open container
   * @param {Object} oOptions
   * @param {String} cProc
   * @param {String} cPar
   * @param {ak_dynselect} oSelf
   */
  this.openContainer = function(oOptions, cProc, cPar, oSelf) {
    // if program is OpenContainer -> launchContainer
    let oParams = {
      proc: cProc,
      para: cPar,
      self: oSelf,
      caller: oSelf
    };
    if (oOptions.Action != 'OpenContainer') {
      // check for Menu ActionOptions JSON settings parse
      let oActionOptions = '';
      try {
        if (oOptions.actionOptions && oOptions.actionOptions.indexOf('{') != -1)
          oActionOptions = JSON.parse(oOptions.actionOptions.replace(/'/g, '"'));

      } catch (e) {
        akioma.log.error(e);
      }

      oParams.proc = `${oOptions.CallObject}.r`;
      oParams.data = true;
      oParams.launchedFrom = oOptions.launchedFrom;

      if (oActionOptions) {
        oParams.customData = oActionOptions;
        oParams = Object.assign(oParams, oActionOptions);
      }
    }
    app.controller.launchContainer(oParams);
  };

  // ******** check for routine finishConstruct in all children ***********
  this.finishConstruct = function(oElm) {
    const cSrcList = [ 'datasource', 'businessEntity', 'businessEntity2', 'frame', 'treegrid' ];

    // check for dataSource
    try {
      if (oElm.opt.dataSource) {
        const cObjectID = oElm.opt.dataSource;
        const aData = app.messenger.getObjects(oElm.dynObject, `DATA:SRC:${cObjectID}`);
        if (aData && aData.length > 0 && cSrcList.indexOf(aData[0].type) > -1)
          aData[0].controller.addObserver(oElm);
        else
          akioma.log.error([ 'error adding observer', oElm, aData ]);
      }
    } catch (e) {
      akioma.message({ type: 'error', text: `Error in finishConstruct: addobserver ${e.message}` });
    }

    // create passthrough links
    try {
      this._createPassthroughLinks(oElm);
    } catch (e) {
      console.error(e);
      akioma.message({ type: 'error', text: `Error creating passthrough links: ${e.message}` });
    }

    // check if method exists
    try {
      if (typeof oElm.finishConstruct == 'function')
        oElm.finishConstruct();
    } catch (e) {
      console.error([ 'Error in finishConstruct', e, oElm ]);
      akioma.message({ type: 'error', text: `Error in finishConstruct: ${e.message}` });
    }

    // run finish for all children
    for (const i in oElm.childs) {
      if (oElm.stopBubbling == undefined)
        app.controller.finishConstruct(oElm.childs[i]);
    }

    try {
      // check if we have to call the create event
      if (oElm.opt.createEvent)
        app.controller.callAkiomaCode(oElm, oElm.opt.createEvent);
    } catch (e) {
      akioma.message({ type: 'error', text: `Error in finishConstruct: createevent${e.message}` });
    }

    this._checkFixedPanelSizes(oElm);
  };

  this._createPassthroughLinks = function(oElm) {
    // create passthrough link
    try {
      if (oElm.dynObject)
        this._registerLink(oElm, 'DATA');


    } catch (e) {
      console.error(`Error creating passthrough link ${this.opt.name}`);
    }
  };

  this._registerLink = function(oElm, type) {
    const oDATASRC = oElm.dynObject.getLink(`${type}:SRC`);
    if (oDATASRC && oDATASRC.controller.view == 'frame') {
      app.messenger.unSubscribe(oElm.dynObject, `${type}:TRG:${oElm.dynObject.getLink(`${type}:SRC`).controller.opt.linkid}`);
      let oPassThroughDATASRC = oDATASRC.controller.dynObject.getLink(`${type}:SRC`);

      if (oPassThroughDATASRC == null && oDATASRC.controller.parent.view == 'frame')
        oPassThroughDATASRC = oDATASRC.controller.parent.dynObject.getLink(`${type}:SRC`);


      if (oPassThroughDATASRC == null && oDATASRC.controller.parent.view == 'panelSwitcher')
        oPassThroughDATASRC = oDATASRC.controller.getAncestor('panelSwitcher').dynObject.getLink(`${type}:TARGET`);


      if (oPassThroughDATASRC) {
        const cLinkName = (oPassThroughDATASRC.controller.opt.name + oPassThroughDATASRC.controller.opt.linkid);
        app.messenger.subscribe(oElm.dynObject, `${type}:TRG:${cLinkName}`);
        oElm.dataSource = oPassThroughDATASRC.controller;
        oPassThroughDATASRC.controller.observers.push(oElm);
      }
    }
  };

  // checkes dhtmlx panels fixSize and minDimension
  this._checkFixedPanelSizes = function(oElm) {
    const fixWidth = oElm.opt.fixWidth,
      fixHeight = oElm.opt.fixHeight,
      minDimension = oElm.opt.minDimension;

    let oPanel;
    if (minDimension) {
      oPanel = oElm.getAncestor('panel');
      oPanel.opt.minDimension = minDimension;
    }

    if (fixWidth || fixHeight) {
      oPanel = oElm.getAncestor('panel');
      oPanel.opt.fixWidth = fixWidth;
      oPanel.opt.fixHeight = fixHeight;

      if (!isNaN(oElm.opt.width) && fixWidth)
        oPanel.dhx.setWidth(parseInt(oElm.opt.width));


      if (!isNaN(oElm.opt.height) && fixHeight)
        oPanel.dhx.setHeight(parseInt(oElm.opt.height));


      oPanel.dhx.fixSize(fixWidth, fixHeight);

    } else if (oPanel && oPanel.opt) {


      if (oPanel.opt.minDimension == undefined || oPanel.opt.minDimension != false) {
        if (oPanel.opt.height < 150)
          oPanel.opt.height = 300;


        if (oPanel.opt.width < 150)
          oPanel.opt.width = 500;


        oPanel.dhx.setWidth(parseInt(oPanel.opt.width));
        oPanel.dhx.setHeight(parseInt(oPanel.opt.height));
      }

    }
  };

  // ******** parse a configuration ****************
  /**
 * Parse a configuration for generating the layout
 * @memberOf AK_Controller
 * @param  {object} oData        Config file, layout structure
 * @param  {object} oElm
 * @param  {boolean} stopBubbling
 */
  this.parseProc = function(oData, oElm, stopBubbling) {

    function resolveElm(oData, oParent) {
      let oNew,
        i;

      // the view is the element which should be created
      if (!oData.view)
        return;

      if (oData.view == 'fieldset' || oData.view == 'block')
        oData.att.instanceRestrictions = oParent.opt.instanceRestrictions;
      if (oParent && oParent.opt.instanceRestrictions && oData.att.InstanceName) {
        if (akioma.canDo(oParent.opt.instanceRestrictions, oData.att.InstanceName))
          return;
      }

      if (akioma.getSessionProperty('restrictedObjects') && oData.att.ObjectMasterName) {
        if (akioma.canDo(akioma.getSessionProperty('restrictedObjects'), oData.att.ObjectMasterName))
          return;
      }

      // create the element
      try {
        oData.parent = oParent;
        oNew = $.dhxObject(oData);

        if (oNew.opt.linkid == undefined)
          oNew.opt.linkid = '';
      } catch (e) {
        !_isIE && console.error([ 'Error creating object', oData, oNew, e ]);
      }

      for (i in oData.sub) {
        if (oData.sub[i].stopBubbling == undefined)
          resolveElm(oData.sub[i], oNew);
      }

      return oNew;
    }

    let i, oNew;
    if (oData instanceof Array) {
      for (i in oData) {

        // check for element if it was already added/ case for businessEntity until catalog loading
        if (oElm.childs.indexOf(oData[i]) == -1)
          oNew = resolveElm(oData[i], oElm);
        else
          oNew = oData[i];


        app.controller.finishConstruct(oNew);

        // check for security
        if (oElm)
          oNew.callInChildren('setProperty', { security: oElm.security });

        // run end construct
        try {
          oNew.callInChildren('endConstruct');
        } catch (e) {
          akioma.log.error(`Error in endConstruct: ${e.message}`);
        }

      }
    } else {
      oNew = resolveElm(oData, oElm);

      const callObjectFinishConstruct = () => {
        // now finalize construct
        app.controller.finishConstruct(oNew);

        if (stopBubbling)
          oNew.stopBubbling = stopBubbling;

        // check for security
        if (oElm)
          oNew.callInChildren('setProperty', { security: oElm.security });


        try { // run end construct
          oNew.callInChildren('endConstruct');
        } catch (e) {
          akioma.log.error(`Error in endConstruct: ${e.message}`);
        }
      };

      if (oNew.view.toLowerCase() == 'businessentity' || oNew.view.toLowerCase() == 'businessentity2') {
        app.controller.finishConstruct(oNew);
        callObjectFinishConstruct();
      } else
        callObjectFinishConstruct();

    }

    return oNew;
  };

  // *********** call code in akioma ***************
  /**
 * Method used for executing javascript code on the UI
 * @instance
 * @memberOf AK_Controller
 * @param  {object} oSelf         The caller object
 * @param  {string} cCode         The string starting with $ and then calling the method
 * @param  {object} oLaunchedFrom The caller object (used mostly for buttons in Ribbon/Toolbar)
 */
  this.callAkiomaCode = function(oSelf, cCode, oLaunchedFrom) {
    let self,
      aCode;

    // only call action if we have valid code
    if (cCode) {

      // decide if it's server or client code
      if (cCode.substr(0, 1) == '$') {
        // console.log("exec code: "+cCode)
        cCode = cCode.substr(1);
        if (self == undefined)
          self = oSelf;
        // get variable for dynObject
        if (oSelf != undefined && oSelf.dynObject != undefined)
          self = oSelf.dynObject;
        akioma.swat.evaluateCode({ code: cCode, controller: oSelf, dynObj: self, launchedFrom: oLaunchedFrom, catchError: true });
      } else {
        try {
          aCode = eval(`[${cCode}]`);
          app.controller.callServerMethod(aCode[0], aCode[1]);
        } catch (e) {
          console.error(`Error executing akioma code: ${e}`);
        }
      }
    }
  };

  // *********** execute backend code ***************
  /**
 * Method used for executing ABL code on the backend
 * @instance
 * @memberOf AK_Controller
 * @param  {string} cCode The string containing ABL code to execute.
 * @return {Promise}
 */
  this.executeServerCode = function(cCode) {
    return akioma.invokeServerTask({
      name: 'Akioma.Swat.UrmelBT',
      methodName: 'ExecCode',
      paramObj: { plcParameter: { Value: cCode } }
    });
  };

};
