/**
 * Log debug messages using akioma.log object
 * @namespace akioma
 */
akioma.log = new akioma.Logger(log);

// setup logger for production
akioma.log.setLevel('warn');

// setup default level for Menu.FunctionCode logger used in toolbar and ribbons
const functionCodelogger = akioma.log.getLogger('Menu.FunctionCode');
functionCodelogger.log.setLevel('warn');
