/**
 * SwatPanelSwitcher Control
 * @class ak_panelswitcher
 * @param {Object} options Repository attributes for SwatFileSelector.
 */
$.ak_panelSwitcher = class extends akioma.BasePanelSwitcher {
  constructor(options) {
    super();
    const defaults = {};

    this.registerVuexModule = true;
    this.useParentDynObjectLink = true;
    this.registerDynObject = true;
    this.frameContainer = true;

    this.opt		= Object.assign({}, defaults, options.att);
    this.parent		= options.parent;
    this.view = options.view;
    this.childs = [];

    // get parent
    const oParent	= this.parent;

    if (oParent) {
      if (!this.opt.customStyle)
        this.opt.customStyle = this.view;

      this.dhx = this.parent.dhx;
      akioma.setPanelHeader(this);

      if (this.opt.viewName)
        this.switchView(this.opt.viewName);
    }
  }
};
