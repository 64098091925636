

/**
 * Initialize kinvey backend.
 * @private
 * @param {Object} appSettings Kinvey app settings.
 * @param {string} appKey App key of the kinvey backend.
 * @param {string} appSecret App secret of the kinvey backend.
 */
const kinveyInit = function({ appKey, appSecret, instanceId }) {
  Kinvey.init({
    storage: Kinvey.StorageProvider.Memory,
    appKey,
    appSecret,
    instanceId
  });
};

/**
 * Checks the kinvey settings if they are valid.
 * @private
 * @param {Object} appSettings Kinvey application settings.
 * @param {string} appSettings.appKey App key of the kinvey backend.
 * @param {string} appSettings.appSecret App secret of the kinvey backend.
 * @returns {boolean}
 */
const checkKinveySettings = function({ appKey, appSecret }) {
  return !isNull(appKey) && appKey !== '' && !isNull(appSecret) && appSecret !== '';
};

/**
 * Obtain kinvey settings from the current session;
 * @private
 * @returns {Object}
 */
const getKinveySettingsFromSession = function() {
  return {
    appKey: akioma.getSessionProperty('kinveyAppKey'),
    appSecret: akioma.getSessionProperty('kinveyAppSecret'),
    instanceId: akioma.getSessionProperty('kinveyInstanceId')
  };
};

if (!akioma)
  akioma = {};

/**
 * Checks if Kinvey is enabled for the current session.
 * @returns {boolean}
 */
akioma.KinveyIsEnabled = function() {
  return checkKinveySettings(
    getKinveySettingsFromSession()
  );
};

/**
 * Initialize kinvey with the current session config settings.
 */
akioma.KinveyInitialize = function() {
  kinveyInit(
    getKinveySettingsFromSession()
  );
};

/**
 * Login on the initialized kinvey backend.
 * @param {Object} appSettings Kinvey application settings.
 * @param {string} appSettings.appKey App key of the kinvey backend.
 * @param {string} appSettings.appSecret App secret of the kinvey backend.
 * @param {Object} credentials Credentials used for login.
 * @param {string} credentials.username Username used for login.
 * @param {string} credentials.password Password used for login.
 * @returns {Promise}
 */
akioma.KinveyLogin = function({ username, password }) {
  const promise = Kinvey.User.getActiveUser() ? Kinvey.User.logout() : Promise.resolve();
  promise.then(() => {
    Kinvey.User.login(username, password);
  });
  return promise;
};

/**
 * Invokes a server task on the kinvey backend.
 * @param {Object} opts Options of the invoke server task call
 * @returns {Promise}
 */
akioma.KinveyInvokeServerTask = function(opts) {
  const endpoint = opts.name;
  const args = opts.paramObj;

  return Kinvey.CustomEndpoint.execute(endpoint, args);
};
