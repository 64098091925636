// start routines after load
function onLoad() {
  const deferred = $.Deferred();
  const deferredLoaded = $.Deferred();
  // force cache disable for IE
  if (_isIE)
    window.dhx.ajax.cache = false;

  jQuery.ajaxSetup({ cache: true });

  // init winframe for login
  const data = {
    view: 'winframe',
    att: {
      id: 'content',
      name: 'content'
    }
  };

  oDhxTree = $.dhxObject(data);

  oDhxTree.dhx.setSkin('material');
  window.dhx4.skin = 'material';

  dhtmlx.image_path = '/imgs/';

  resizeMainWindow();

  // enable drag and drop
  dhtmlx.compat('dnd');

  // load menu
  startLinks = new dhtmlXDataView({
    container: 'startLinks',
    height: 'auto',
    type: {
      template: '<span class=\'linklist\'>#name#</span>',
      height: 15,
      width: 120,
      margin: 0,
      padding: 5
    }
  });
  startLinks.load('/data/startlink.xml');
  startLinks.filter(obj => {
    // check state of login
    if (obj.group == 'login')
      return false;
    return true;
  });
  startLinks.attachEvent('onItemClick', id => {

    // eval function
    const record = startLinks.get(id);
    eval(record.func);
    return false;
  });

  startLinks.attachEvent('onXLE', () => {
    deferred.resolve();
  });

  akioma.ExternalScreen.initBrowserSessionId();

  // loads instances factory class before
  akioma.loadVueInstancesFactory().then(() => {
    const layoutCreated = login();

    $.when(
      layoutCreated,
      deferred
    ).then(() => {
      deferredLoaded.resolve();
    });
  });


  // check if debugger has to be opened
  if ($.cookie('debugOpen') == 'true') {
    oDebugWindow = window.open('/debug/debug.html', 'akiomaDebug', 'width=1000,height=600,location=no,menubar=no');
    oDebugWindow.parwin = window;
  }

  return deferredLoaded.promise();
}

// global context function
// eslint-disable-next-line no-unused-vars
function sitefinityTest() {
  // init winframe for login
  const data = {
    view: 'root',
    att: {
      id: 'maincontainer',
      name: 'maincontainer'
    }
  };

  oDhxTree = $.dhxObject(data);

  akioma.root = oDhxTree;


  app.controller.launchContainer({
    target: oDhxTree,
    proc: 'include.r',
    para: 'RunFile=sitefinityDemo&Page=*',
    data: true,
    async: true
  });
}
// resize bubbling beginning from window
function resizeMainWindow() {
  const iHeight = window.innerHeight || jQuery(window).height();
  const iWidth = window.innerWidth || jQuery(window).width();
  $('body')
    .css({
      height: `${iHeight}px`,
      width: `${iWidth}px`
    });

  const oContent = jQuery('#mainLayout');
  if (oContent) {
    const oContOffset = oContent.offset();
    oContent.css({
      height: iHeight - (oContOffset ? oContOffset.top : 0) - 0,
      width: iWidth
    });
  }
}

// create a session cookie
// @todo make akioma easier to embed
if (typeof (mocha) === 'undefined') {

  $.cookie('sessionid', $.Guid.Value());

  // Events for window
  $(window).resize(() => {
    resizeMainWindow();
  }).on('load', () => {
    if (typeof (mocha) == 'undefined')
      onLoad();
  });
}

// get indexes and list of ids
// global context function
// eslint-disable-next-line no-unused-vars
function prepareData(oData, cKey) {
  const aKey = cKey.split(',');

  const buildKey = oRec => {
    let cPrim = '';
    for (const i in aKey) {
      cPrim += (cPrim ? ',' : '')
              + oRec[aKey[i]];
    }
    return cPrim;
  };

  // check if records are available
  if (!oData.records.RowObject || !oData.records.totalRecords && oData.records.totalRecords == 0) {
    oData.records.RowObject = new Array();
    return;
  }

  // get root for records
  const aRec = oData.records.RowObject;

  // get through data and make list of indexes and reference for records
  for (const i in aRec) {
    if (typeof aRec[i] == 'object') {
      const cPrim = buildKey(aRec[i]);
      oData.indexes.push(cPrim);
      oData.reflist[cPrim] = aRec[i];
    }
  }
}

// routine for calling functions with timeout
const oTimeout = {};

// global context function
// eslint-disable-next-line no-unused-vars
function callTimeout(index) {
  if (oTimeout[index]) {
    const oSelf = oTimeout[index].self;
    try {
      oSelf[oTimeout[index].func](oTimeout[index]);
    } catch (e) {
      akioma.log.error(`Error executing routine '${oTimeout[index].func}': ${e.messages}`);
    }
    delete oTimeout[index];
  }
}

// global context function
// eslint-disable-next-line no-unused-vars
function getNodeTypes() {
  // now get to the server to initialize the new node
  return $.ajax({
    async: true,
    url: '/akioma/runRoutine.r',
    dataType: 'json',
    data: 'RunFile=akioma/getNodeTypes.p&ParData=CHAR&ParType=i&Par1=pos.',
    success: function(data) {
      app.nodeTypes = data.nodeTypes;
    },
    error: function(xhr, textStatus, errorThrown) {
      akioma.notification({ type: 'error', text: `Error getting data from 'getNodeTypes': ${textStatus}` });
      akioma.log.error(errorThrown);
    }
  });
}

// Avoid `console` errors in browsers that lack a console.
(function() {
  let method;
  const noop = function() {};
  const methods = [
    'assert',
    'clear',
    'count',
    'debug',
    'dir',
    'dirxml',
    'error',
    'exception',
    'group',
    'groupCollapsed',
    'groupEnd',
    'info',
    'log',
    'markTimeline',
    'profile',
    'profileEnd',
    'table',
    'time',
    'timeEnd',
    'timeStamp',
    'trace',
    'warn'
  ];
  let length = methods.length;
  const console = (window.console = window.console || {});

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method])
      console[method] = noop;

  }
}());
