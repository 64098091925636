/**
 * @namespace UserProfile
 * @property {String} cSelectedMode The mode selected, default is set to be 'default'
 * @property {object} oData The local loaded user settings
 * @property {string} currentVersion The currently active version
 * @type {Object}
 */
const UserProfile = {
  cSelectedMode: '',
  oData: null,
  currentVersion: 1,
  profileAutoStore: 'onLogout', // or 'onChange', ''
  panelSize: 320,
  homeButtonWidth: 101
};

/**
 * Saves to localStorage the specified object
 * @param  {object} oObj      The Akioma Control
 * @param  {object} oSettings User Profile Settings
 * @memberOf UserProfile
 * @return {void}
 */
UserProfile.saveLocalProfileData = function(oObj, oSettings) {
  akioma.VuexStore._dispatch('swat/userprofile/setGridObjectSettings', oSettings);
  if (UserProfile.profileAutoStore == 'onChange')
    UserProfile.saveToServer(false);
};

UserProfile.saveGridLocalProfileData = function(oObj, oSettings) {
  oSettings.id = `ak_${oObj.view}_${oObj.opt.name}`;
  akioma.VuexStore.dispatch('swat/userprofile/setGridObjectSettings', oSettings);
  if (UserProfile.profileAutoStore == 'onChange')
    UserProfile.saveToServer(false);
};


UserProfile.saveFormLocalProfileData = function(oObj, oSettings) {
  oSettings.id = `ak_${oObj.view}_${oObj.opt.name}`;
  akioma.VuexStore.dispatch('swat/userprofile/setFormObjectSettings', oSettings);
  if (UserProfile.profileAutoStore == 'onChange')
    UserProfile.saveToServer(false);
};

/**
 * Sets the profileAutoStore strategy to the given value, which handles when savings should occur
 * @memberOf UserProfile
 * @param {string} cProfileAutoStore Type of profileAutoStore, can be "onChange" or "onLogout" or "" (empty)
 * @return {void}
 */
UserProfile.setProfileAutoStore = function(cProfileAutoStore) {
  UserProfile.profileAutoStore = cProfileAutoStore;
};

/**
 * Returns the current ProfileAutoStore type
 * @memberOf UserProfile
 * @return {string}    The profileAutoStore type
 */
UserProfile.getProfileAutoStore = function() {
  return UserProfile.profileAutoStore;
};

// Loads settings for a given object
/**
 * Loads the User Profile settings for a given object
 * @param  {object} oObj The Akioma Control
 * @memberOf UserProfile
 * @return {object}      The user profile settings
 */
UserProfile.loadLocalProfileData = function(oObj) {
  let oData = this.oData[this.cSelectedMode][`ak_${oObj.view}_${oObj.opt.name}`];
  if (oData != undefined)
    return oData;
  else {
    oData = UserProfile.getDefaultData(oObj);
    return oData;
  }
};

UserProfile.loadGridLocalProfileData = function(oObj) {
  const cGridId = `ak_${oObj.view}_${oObj.opt.name}`;
  let oData = akioma.VuexStore.getters['swat/userprofile/getGridObjectSetting'](cGridId);
  if (oData != undefined)
    return oData;
  else {
    oData = UserProfile.getDefaultData(oObj);
    return oData;
  }
};

UserProfile.loadFormLocalProfileData = function(oObj) {
  const cFormId = `ak_${oObj.view}_${oObj.opt.name}`;
  let oData = akioma.VuexStore.getters['swat/userprofile/getFormObjectSetting'](cFormId);
  if (oData != undefined)
    return oData;
  else {
    oData = UserProfile.getDefaultData(oObj);
    return oData;
  }
};

// Removes an objects settings from storage
/**
 * Clear up local profile data.
 * @param  {object} oObj The Akioma Control
 * @memberOf UserProfile
 * @return {void}
 */
UserProfile.clearLocalProfileData = function(oObj) {
  delete this.oData[this.cSelectedMode][`ak_${oObj.view}_${oObj.opt.name}`];
  localStorage.setItem('ak_userprofile', JSON.stringify(this.oData));
};


// Loads existing profile data from localStorage
/**
 * Method usef or loading existing profile data from localStorage
 * @memberOf UserProfile
 * @return {void}
 */
UserProfile.loadLocalDataToMemory = function() {
  const oUserProfile = UserProfile.getLocalProfileData();

  if (oUserProfile)
    this.oData = (typeof (oUserProfile) == 'string') ? JSON.parse(oUserProfile) : oUserProfile;
  else
    console.warn('No user profile data available.');

  if (this.oData) {
    const oProfile = this.oData;
    akioma.VuexStore.commit('swat/userprofile/LOAD_SETTINGS', oProfile);
  }
};

/**
 * Method used for cleanup of older localStorage versions
 * @memberOf UserProfile
 * @return {void}
 */
UserProfile.removePreviousVersion = function() {
  try {
    const oUserProfile = JSON.parse(localStorage.getItem('ak_userprofile'));
    if (oUserProfile != null && (UserProfile.currentVersion > oUserProfile.version || oUserProfile.version == undefined)) {
      UserProfile.oData = null;
      localStorage.removeItem('ak_userprofile');
      akioma.log.info(`removed version ${oUserProfile.version} from user profile settings. User profile new version:${UserProfile.currentVersion}`);
    }
  } catch (e) {
    console.warn('Could not remove previous UserProfile settings', e);
  }
};


// Saves to localStorage the specified key
/**
 * Saves settings to localStorage on the specified key
 * @param  {string} sKey      The key to save on
 * @param  {object} oSettings The UserProfile settings to save
 * @memberOf UserProfile
 * @return {void}
 */
UserProfile.saveLocalProfileDataSession = function(sKey, oSettings) {
  this.oData[this.cSelectedMode][sKey] = oSettings;
  this.oData.time = Date.now();
  localStorage.setItem('ak_userprofile', JSON.stringify(this.oData));

  if (UserProfile.profileAutoStore == 'onChange')
    UserProfile.saveToServer(false);
};


// Loads settings for a given key
/**
 * Loads settings for a given key
 * @param  {string} sKey The key to save
 * @return {void}
 */
UserProfile.loadLocalProfileDataSession = function(sKey) {
  try {
    const oData = this.oData[this.cSelectedMode][sKey];
    if (oData != undefined)
      return oData;
    else
      return null;

  } catch (e) {
    console.warn(e);
  }

};


/**
 * Method to get the LocalStorage data of the userProfile.
 * @memberOf UserProfile
 * @return {Object}
 */
UserProfile.getLocalProfileData = function() {
  const cUserProfile = localStorage.getItem('ak_userprofile_v2');
  if (cUserProfile && cUserProfile != 'undefined')
    return JSON.parse(cUserProfile);
  else
    return null;
};

/**
 * Method to set the LocalStorage data for userProfile
 * @param  {string} cUserProfile UserProfile settings
 * @memberOf UserProfile
 */
UserProfile.setLocalProfileData = function(cUserProfile) {
  localStorage.setItem('ak_userprofile_v2', JSON.stringify(cUserProfile));
};


// Updates settings on the fly
/**
 * Updates settings on the fly
 * @param  {object} oSelf The Akioma Control
 * @memberOf UserProfile
 * @return {void}
 */
UserProfile.updateSettings = function(oSelf) {
  const cSelectedRow = oSelf.dhx.getSelectedRowId();
  const businessEntity = oSelf.dynObject.getLink('DATA:SRC');

  // fetch selected row from grid
  const oItem = businessEntity.controller.dhx.item(cSelectedRow);
  if (isNull(oItem)) {
    akioma.notification({ type: 'error', text: 'Could not identify selected profile!' });
    return;
  }

  // set selected index in datasource
  businessEntity.controller.setIndex(cSelectedRow);

  // check both in bindata and content for backward compatibility
  const profile = (oItem.bindata > '') ? oItem.bindata : oItem.content;
  if (profile === null || profile === '') {
    akioma.notification({ type: 'error', text: 'Selected profile is empty!' });
    return;
  }

  try { // update profile data
    UserProfile.oData = JSON.parse(profile);
    try {
      // trigger update in vuex store
      localStorage.setItem('ak_userprofile_v2', profile);
      akioma.VuexStore.commit('swat/userprofile/LOAD_SETTINGS', JSON.parse(profile));
      akioma.notification({ type: 'info', text: 'Settings loaded successfully!' });
    } catch (e) {
      akioma.notification({ type: 'error', text: 'Settings could not load successfully!' });
    }
  } catch (e) {
    akioma.notification({ type: 'error', text: 'The user profile is corrupted, it cannot be loaded.' });
  }
};

/**
 * Set the user profile mode, default value is 'default'
 * @memberOf UserProfile
 * @param {string} cMode The mode to use
 */
UserProfile.setMode = function(cMode) {
  this.cSelectedMode = cMode;
  if (this.oData == null) {
    this.oData = {};
    this.oData[cMode] = {};
  }
  if (this.oData[cMode] == undefined)
    this.oData[cMode] = {};
};

/**
 * Returns the user profile mode
 * @memberOf UserProfile
 * @return {string} The selected mode
 */
UserProfile.getSelectedMode = function() {
  return this.cSelectedMode;
};

/**
 * Returns the default settings data
 * @param  {object} oObj The Akioma Control
 * @memberOf UserProfile
 * @return {object}      The user profile settings
 */
UserProfile.getDefaultData = function(oObj) {
  let oData = null;
  if (oObj.view == 'treegrid' && oObj.opt.objname == 'offerw') {
    oData = [
      { 'hidden': false, 'size': 300, 'id': 'datarecdesc' },
      { 'hidden': false, 'size': 140, 'id': 'selfkey' },
      { 'hidden': false, 'size': 113, 'id': 'total' },
      { 'hidden': false, 'size': 70, 'id': 'quantity' },
      { 'hidden': false, 'size': 107, 'id': 'price' },
      { 'hidden': false, 'size': 100, 'id': 'amount' },
      { 'hidden': false, 'size': 53, 'id': 'summaryflag' }
    ];
  } else if (oObj.view == 'treegrid' && oObj.opt.objname == 'bomw') {
    oData = [
      { 'hidden': false, 'size': 410, 'id': 'datarecdesc' },
      { 'hidden': false, 'size': 285, 'id': 'selfkey' },
      { 'hidden': false, 'size': 196, 'id': 'quantity' },
      { 'hidden': false, 'size': 154, 'id': 'useinsums' }
    ];
  } else if (oObj.view == 'treegrid') {
    oData = [
      { 'hidden': false, 'size': 410, 'id': 'datarecdesc' },
      { 'hidden': false, 'size': 285, 'id': 'selfkey' }
    ];
  }

  return oData;
};

/**
 * Method for triggering the user profile copy in the create dialog
 * @memberOf UserProfile
 * @param {object} oSelf The object where the method is called
 */
UserProfile.copyProfile = function(oSelf) {
  const screen = oSelf.dynObject.screen;

  const sourceHdl = screen.caller.screen.getLink('PRIMARYSDO:TARGET').controller.getIndex();
  const userHdl = screen.getObject('UserDynSelect').getValue();

  akioma.invokeServerTask({
    name: 'Akioma.Swat.UiInteraction.UserProfileBT',
    methodName: 'CopyUserProfile',
    paramObj: {
      plcParameter: {
        UserHdl: userHdl,
        SourceHdl: sourceHdl
      }
    }
  }).always(() => {
    screen.controller.close();
  });
};

/**
 * Method to save the UserProfile settings on the backend side
 * @param  {boolean} bShowSuccessMsg Flag for displaying the success message or not, default will show if not specified
 * @memberOf UserProfile
 * @return {Promise}
 */
UserProfile.saveToServer = function(bShowSuccessMsg) {
  if (bShowSuccessMsg == undefined)
    bShowSuccessMsg = true;

  const oData = {
    User: '',
    Context: '',
    Profile: JSON.stringify(akioma.VuexStore.state.swat.userprofile)
  };

  akioma.invokeServerTask({
    name: 'Akioma.Swat.UiInteraction.UserProfileBT',
    methodName: 'SaveUserProfile',
    paramObj: { plcParameter: oData }
  }).done(function(oResult) {
    const profile = JSON.parse(oResult.plcParameter.Profile);
    akioma.Themes.setTheme(profile.theme);

    if (bShowSuccessMsg) {
      akioma.notification({
        type: 'success',
        text: akioma.tran('User.profileSaveSuccess', { defaultValue: 'User Profile has been saved successfully!' }),
        expire: 2000
      });
    }
    if (UserProfile.oPromise)
      UserProfile.oPromise.resolve(arguments);
  }).fail(function() {
    if (bShowSuccessMsg)
      akioma.notification({ type: 'success', text: 'Error saving User Profile!' });
    if (UserProfile.oPromise)
      UserProfile.oPromise.reject(arguments);
  });

};

/**
 * Method to load the UserProfile settings from the backend
 * @memberOf UserProfile
 */
UserProfile.loadFromServer = function() {

  const deferred = $.Deferred();

  if (app.sessionData.userHdl) {
    akioma.invokeServerTask({
      name: 'Akioma.Swat.UiInteraction.UserProfileBT',
      methodName: 'LoadUserProfile',
      paramObj: {
        plcParameter: {
          User: '',
          Context: '',
          Profile: ''
        }
      }
    }).done(oResult => {
      const oReturn = oResult.plcParameter;
      let themeLoadPromise;
      if (oReturn.Profile) {
        UserProfile.setLocalProfileData(oReturn.Profile);
        oReturn.Profile = JSON.parse(oReturn.Profile);
        if (oReturn.Profile.theme && oReturn.Profile.theme.key !== '')
          themeLoadPromise = akioma.Themes.setTheme(oReturn.Profile.theme);
        else if (akioma.Themes.defaultTheme.key)
          themeLoadPromise = akioma.Themes.setTheme(akioma.Themes.defaultTheme);

      } else if (akioma.Themes.defaultTheme.key)
        themeLoadPromise = akioma.Themes.setTheme(akioma.Themes.defaultTheme);

      UserProfile.loadLocalDataToMemory();
      UserProfile.setMode('default');

      themeLoadPromise.then(() => {
        deferred.resolve(oResult);
      }).catch(() => {
        deferred.reject();
      });
    }).fail(e => {
      console.warn(e);
      deferred.reject();
    });
  } else {
    UserProfile.loadLocalDataToMemory();
    UserProfile.setMode('default');
    deferred.resolve();
  }

  return deferred.promise();

};

UserProfile.createSettingsWindow = function() {
  const dhxSettingsWins = new dhtmlXWindows();
  const win = dhxSettingsWins.createWindow('usersettings', 100, 300, 600, 900);
  win.setText(`Layour Settings for user: ${app.sessionData.userKey}`);
  const myLayout = win.attachLayout({
    pattern: '2E',
    cells: [
      { id: 'a', text: 'Controls' },
      { id: 'b', text: 'Settings' }
    ]
  });

  const conf = [
    {
      type: 'upload',
      url: 'https://slu116.build.one:8888/upload/settings',
      name: 'uploadform',
      inputWidth: 300,
      offsetTop: 100,
      offsetLeft: 100,
      autoStart: true,
      titleScreen: true,
      autoRemove: true,
      titleText: 'drag-n-drop files here or click to select files.'

    }
  ];

  myLayout.cells('a').attachForm(conf);

  const oGrid = myLayout.cells('b').attachGrid();
  oGrid.setHeader('User,SelfHdl,SelfKey,Content');
  oGrid.setColumnIds('userselfkey,selfhdl,selfkey,content');
  oGrid.setColAlign('right,left,left,right,center,left,center,center');
  oGrid.init();

  const oSource = UserProfile.businessEntity.dhx;
  oGrid.sync(oSource);
};

/**
 * Method used for redirecting to the login screen
 * @memberOf UserProfile
 */
UserProfile.webLogout = function() {
  const userLogout = function(cLogoutUrl) {
    akioma.restSession.logout().then((session, result) => {
      akioma.onLogout(session, result, (cLogoutUrl || '/'));
    });
  };

  $.ajax({
    async: true,
    url: '/web/Login/Logout',
    dataType: 'json',
    global: false
  }).done(data => {
    const urlLogout = window.sessionStorage.getItem('logoutRedirectUrl') || data.logoutURLRequest;
    userLogout(urlLogout);
  }).fail(() => {
    userLogout();
  });
};

/**
 * Method used for logging out the current user.
 * @memberOf UserProfile
 */
UserProfile.logoutUserProfile = function() {
  akioma.message({
    type: 'confirm',
    title: akioma.tran('messageBox.title.logout', { defaultValue: 'Abmelden' }),
    text: akioma.tran('messageBox.text.logout', { defaultValue: 'Wollen Sie die Applikation beenden?' }),
    buttonText: akioma.tran('messageBox.button.logout', { defaultValue: 'Abmelden' }),

    /* title:      "Logout Screen",
        text:       "Are you sure you want to logout?",
        */
    ok: 'Yes',
    cancel: 'Cancel',
    callback: function(lOk) {
      if (lOk) {

        if (UserProfile.profileAutoStore == 'onLogout') {

          UserProfile.oPromise = new jQuery.Deferred;
          UserProfile.oPromise.promise();
          UserProfile.saveToServer();
          UserProfile.oPromise.then(() => {
            UserProfile.webLogout();
          }).fail(() => {
            UserProfile.webLogout();
          });

        } else
          UserProfile.webLogout();


      }
    }
  });


};

/**
 * Method used for saving the user profile data
 * @memberOf UserProfile
 */
UserProfile.saveUserProfile = function() {
  UserProfile.saveToServer();
};


// some init for testing
UserProfile.removePreviousVersion();
// UserProfile.loadFromServer();
// UserProfile.loadLocalDataToMemory();
UserProfile.setMode('default');


