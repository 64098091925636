// ************************* ribboncombo ********************************
$.extend({
  ak_ribboncombo: function(options) {
    const defaults = {
      id: '',
      title: '',
      disabled: false,
      image: '',
      image2: ''
    };

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;

    this.registerDynObject = true;
    this.useParentDynObjectLink = true;

    // get new element
    const cName = this.opt.name;
    let cImage = this.opt.image;
    const aOpts = [];
    const cOptions = this.opt.options;

    if (cOptions && this.opt.ListItemPairs == undefined) {
      const aOptRec = cOptions.split('|');
      for (const i in aOptRec) {
        const aOptVal = aOptRec[i].split(',');
        aOpts.push([ aOptVal[0], 'obj', aOptVal[1] ]);
      }

    }

    const iPos = this.parent.opt.pos + 1;
    this.parent.opt.pos = iPos;

    cImage = (cImage == '') ? null : cImage;

    this.parent.prop.fields.push({
      'width': 200,
      'id': cName,
      'mode': this.opt.mode,
      'text': '',
      'img': cImage,
      'disable': false,
      'isbig': false,
      'type': 'buttonCombo'
    });
  }
});


// methods for ribbon combo
$.ak_ribboncombo.prototype = {

  getComboCtl: function() {
    const oRibbonCtl = this.getAncestor('ribbon').dhx;

    let dhxComboCtl = null;
    dhxComboCtl = oRibbonCtl._items[this.opt.name].combo;
    return dhxComboCtl;

  },

  // finalize construct *************
  finishConstruct: function() {
    const oSelf = this;
    const cName = this.opt.name;
    const cTitle = this.opt.title;
    const oRibbon = akioma.getRibbon(this).dhx;
    const cTooltip = (this.opt.tooltip) ? this.opt.tooltip : cTitle;

    // check for dyncombo entry or loading from BE
    if (cTooltip)
      oRibbon.setItemToolTip(cName, cTooltip);
    if (this.opt.objectName || this.opt.ListItemPairs)
      app.toolbar.getDynCombo.apply(this);
    this.getComboCtl().readonly(true);
    this.getComboCtl().setImagePath(oDhx.imagePath);

    this.getComboCtl().attachEvent('onChange', cId => {
      // call action
      app.controller.callAction({
        ActionType: oSelf.opt.actiontype,
        Action: oSelf.opt.action,
        Category: oSelf.opt.category,
        RunPar: oSelf.opt.RunPar,
        CallObject: oSelf.opt.logicalObject,
        caller: oSelf,
        click: cId
      },
      oSelf.parent);
    });

    // check visible attribute for combo ribbon
    try {
      if (this.opt.hidden)
        this.getAncestor('ribbon').dhx.hide(this.opt.id);
    } catch (e) {
      akioma.log.error(`could not hide ribbon ${this.opt.type}`, e);
    }

    try {
      if (this.opt.disabled)
        this.getAncestor('ribbon').dhx.disable(this.opt.id);
    } catch (e) {
      akioma.log.error(`could not disable ribbon ${this.opt.type}`, e);
    }

    this.parent.parent.aItems.push(this.opt.id);

  },

  // finalize construct *************
  endConstruct: function() {
    // check for autoselect
    if (this.opt.autoselect || this.opt.name == 'tbNodeType') {
      const oribbon = this.parent.parent.parent.dhx,
        aOptions = oribbon.getAllListOptions(this.opt.name),
        oSelf = this;

      if (aOptions && aOptions.length > 0) {
        dhtmlx.delay(() => {
          oSelf.click(aOptions[0]);
        });
      }
    }
  },

  // set combo options ***************
  setComboOptions: function(oElm) {
    if (oElm) {
      let iNum = 0;
      const dhxCombo = this.getComboCtl();
      this.options = [];

      for (const i in oElm) {
        iNum += 1;
        if (oElm[i].key)
          oElm[i].value = akioma.tran(`toolbar.${this.opt.name}_Entries.${oElm[i].key}`, { defaultValue: oElm[i].value });
        dhxCombo.addOption(oElm[i].hdl, oElm[i].value, null /* CSS */, oElm[i].image, iNum === 1 ? true : false);
        this.options[oElm[i].hdl] = oElm[i];
      }

      $(dhxCombo.list).addClass('ribbon_combolist');
    }
  },

  // gets selected options
  getSelectedOption: function() {
    const hdl = this.getFieldValue();
    return this.options[hdl];
  },

  // get field value *********
  getFieldValue: function() {
    const dhxCombo = this.getComboCtl();
    let cValue = '';
    cValue = dhxCombo.getSelectedValue();
    return cValue;
  },

  // set field value ********
  setFieldValue: function(cId) {
    const dhxCombo = this.getComboCtl();
    dhxCombo.setComboValue(cId);

    return;
  },

  optionSelected: function(cId) {
    if (akioma.stringMatches(this.opt.name, '%tbNodeType%')) {
      const oTree = this.dynObject.screen.getFirstChildByType('treegrid');
      oTree.setTypeOfAdd(cId);
    }

    if (this.opt.selectEvent)
      app.controller.callAkiomaCode(this, this.opt.selectEvent);

  },

  // click *****************
  click: function(cId) {
    this.setFieldValue(cId);

    const oRibbon = akioma.getRibbon(this);

    if (oRibbon.ribbonMenuStructure != undefined) {
      const oMenu = oRibbon.ribbonMenuStructure.getMenuItemByCode(this.opt.id);
      const oMenuAction = oRibbon.ribbonMenuStructure.aMenuActions[oMenu.id];
      this.opt.actiontype = oMenuAction.ActionType;
      this.opt.action = oMenuAction.Action;
      this.opt.category = oMenuAction.Category;
    }

    // call action
    app.controller.callAction({
      ActionType: this.opt.actiontype,
      Action: this.opt.action,
      Category: this.opt.category,
      RunPar: this.opt.RunPar,
      CallObject: this.opt.logicalObject,
      caller: this,
      click: cId
    },
    this.parent);
    return true;
  }
};
