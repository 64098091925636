/**
 * The Translation class handles translations.
 * @class Translation
 *
 */
akioma.Translation = class {

  constructor() {
    // create i18next instance
    this.i18n = i18next.createInstance();

    // set up initial values
    this.config = {
      ns: ['akioma.common'],
      defaultNS: ['akioma.common'],
      fallbackLng: ['de'],
      debug: false,
      interpolation: {
        prefix: '__',
        suffix: '__'
      },
      backend: {
        backends: [i18nextXHRBackend],
        backendOptions: [
          {
            loadPath: '../locales/__lng__/__ns__.json',
            crossDomain: true
          }
        ]
      }
    };
  }

  /**
   * Method for initializing
   * @param {object} config Configuration
   * @memberOf Translation
   * @return {promise} Returns a Promise
   */
  initialize() {
    if (isNull(akioma.devTools.getParameterByName('setLng')))
      this.setLanguage();
    return this.i18n.use(i18nextChainedBackend).init(this.config);
  }

  /**
   * Method for getting the configuration
   * @memberOf Translation
   * @return {object} Returns configuration
   */
  getConfig() {
    return this.config;
  }

  /**
   * Method for setting the configuration
   * @param {object} config Configuration
   * @memberOf Translation
   */
  setConfig(config) {
    this.config = config;
  }

  /**
   * Method for getting a configuration property (e.g. language, namespace)
   * @param {string} name Property name
   * @memberOf Translation
   * @return {*} Returns configuration property
   */
  getProperty(name) {
    return this.config[name];
  }

  /**
   * Method for setting a configuration property (e.g. language, namespace)
   * @param {string} name Property name
   * @param {*} value Property value
   * @memberOf Translation
   */
  setProperty(name, value) {
    this.config[name] = value;
  }

  /**
   * Method for getting the language
   * @memberOf Translation
   * @return {string} Returns language
   */
  getLanguage() {
    return this.config['lng'];
  }

  /**
   * Method for setting the language
   * @param {string} [value] Language
   * @memberOf Translation
   */
  setLanguage(value) {
    let language = $.cookie('i18next') || this.getProperty('fallbackLng')[0];
    if (value) {
      language = value;
      $.cookie('i18next', language);
    }
    this.setProperty('lng', language);
  }

  /**
   * Method for checking if translate function is available
   * @memberOf Translation
   * @return {boolean} True if available, false otherwise
   */
  translateAvailable() {
    return (!isNull(this.i18n.t)) ? true : false;
  }

  /**
   * Method for translating
   * @param {string} key Key for identifying the translation (namespace:property)
   * @param {object} options Options
   * @memberOf Translation
   * @return {string} Translated value
   */
  translate(key, options) {
    return this.i18n.t (key, options);
  }

  /**
   * Method for loading namespaces
   * @param {string|{Array.<string>}} namespaces Namespace or array of namespaces
   * @memberOf Translation
   * @return {promise} Returns a Promise
   */
  loadNamespaces(namespaces) {
    return this.i18n.loadNamespaces(namespaces);
  }

  /**
   * Initializes the translation configuration from the server.
   *
   * @return {Promise<Object>}
   */
  loadFromServer() {
    const deffered = $.Deferred();

    akioma.invokeServerTask({
      name: 'Akioma.Crm.MasterData.System.ApplicationSettingsBT',
      methodName: 'GetTranslationSettings',
      paramObj: { plcParameter: {} }
    }).then(response => {
      let settings = response.plcParameter.ApplicationSettings;

      // validate
      if (isNull(settings.translation)) {
        akioma.log.info('Invalid translation settings format! Missing \'translation\' main property!');
        return deffered.resolve();
      }
      settings = settings.translation;
      if (isNull(settings.backends)) {
        akioma.log.error('Invalid translation settings format! Missing \'backends\' property!');
        return deffered.resolve();
      }
      if (isNull(settings.backendOptions)) {
        akioma.log.error('Invalid translation settings format! Missing \'backendOptions\' main property!');
        return deffered.resolve();
      }

      const newConfig = { backends: [], backendOptions: [] };
      settings.backends.split(',').forEach(entry => {
        try {
          // get backend function and its options
          const entryFunction = eval(entry);
          const entryOptions = settings.backendOptions[entry] || {};

          // validate backends function
          if (typeof (entry) === 'function') throw `Could not find translation function for '${entry}'!`;

          // add backends and options to new configuration
          newConfig.backends.push(entryFunction);
          newConfig.backendOptions.push(entryOptions);
        } catch (e) {
          akioma.log.error(`Could not process translation config backends entry '${entry}'!`, e);
        }
      });

      // apply new configuration, create new instance and initialize it
      this.config.backend = newConfig;
      this.i18n = i18next.createInstance();
      this.initialize().catch(error => akioma.log.error(error)).finally(deffered.resolve);
    }).fail(error => {
      akioma.log.error(error);
      return deffered.resolve();
    });

    return deffered.promise();
  }

};
