$.extend({
  ak_ribbonblock: function(options) {
    const defaults = {};

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;
    this.dhx = this.parent.dhx._items[this.opt.id].base;

    this.registerDynObject = true;

    $(this.dhx).addClass(`ribbonBlock_${this.opt.id}`);

    // get parent
    const oParent = this.parent;
    if (oParent) {

      this.prop = { fields: [] };

      oParent.prop.fields.push({
        'id': this.opt.id,
        'type': 'block',
        'text_pos': this.opt.textPos,
        'mode': 'cols',
        'text': this.opt.title,
        'list': this.prop.fields
      });

      // append to elements in ribbon
      if (this.parent.elements)
        this.parent.elements.push(this);

    }
  }
});

$.ak_ribbonblock.prototype = {
  // finish construct **********
  finishConstruct: function() {
    this.dhx = null;
    if (this.opt.hidden)
      this.getAncestor('ribbon').dhx.hide(this.opt.name);
    if (this.opt.disabled)
      this.getAncestor('ribbon').dhx.disable(this.opt.name);
  },

  endConstruct: function() {

    if (this.opt.hidden == true && this.getAncestor('ribbon').dhx)
      this.getAncestor('ribbon').dhx.hide(this.opt.name);
    if (this.opt.disabled == true && this.getAncestor('ribbon').dhx)
      this.getAncestor('ribbon').dhx.disable(this.opt.name);
  },
  getValueCombo: function(cId) {
    const oRibbonCtl = this.getAncestor('ribbon').dhx;

    let dhxComboCtl = null;
    dhxComboCtl = oRibbonCtl._items[cId].combo;
    return dhxComboCtl.getSelectedValue();
  }
};
