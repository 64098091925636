(function($) {

  // ***************** panelset ******************
  $.extend({
    ak_designlayout: function(options) {
      const defaults = {};

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;

      if (!this.opt.layout)
        this.opt.layout = '1C';

      this.applyLayout(this.opt.layout);
    }
  });

  $.ak_designlayout.prototype = {
    endConstruct: function() {
      this.dhx.setSizes();
    },

    applyLayout: function(cLayout) {
      this.parent.dhx.detachObject();
      this.dhx = this.parent.dhx.attachLayout(cLayout);

      this.dhx.forEachItem(item => {
        $(item)
          .find('div[ida=dhxMainCont]')
          .droppable({ drop: function() {} });
      });
    },

    destroy: function() {

      // check if dhtmlx element exists -> destroy all attached elements
      if (this.dhx) {
        try {
          // check if we are attached in a dhx element
          if (this.dhx.detachMenu) this.dhx.detachMenu();
          if (this.dhx.detachToolbar) this.dhx.detachToolbar();

          this.dhx.unload();
          this.dhx = null;
        } catch (e) {
          !_isIE && console.error([ 'Error destroying', this.view, this.opt.name, e.message ]);
        }
      }
    }
  };

  // *************** panel ***********************
  $.extend({
    ak_designpanel: function(options) {
      const defaults = {
        label: '',
        height: 300,
        width: 500,
        collapsed: false
      };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;

      // get panel
      const oPanelset = this.parent.dhx;
      if (oPanelset) {

        try {
          // get panel
          const oPanel = oPanelset.cells(this.opt.layout);

          // check for header
          if (this.opt.label) {
            oPanel.setText(this.opt.label);
            oPanelset.setCollapsedText(this.opt.layout, this.opt.label);
          } else
            oPanel.hideHeader();


          $.extend(this, { dhx: oPanel });
        } catch (e) {
          !_isIE && console.error(`Error creating designpanel ${this.opt.label} (${this.opt.layout})`, e.message);
          akioma.message({ type: 'alert-error', title: `Create designpanel ${this.opt.layout}`, text: `Error creating panel:<br />Name: ${this.parent.opt.name}<br />${e.message}` });
          this.cancel = true;
        }
      } else {
        !_isIE && console.error(`No valid parent for designpanel ${this.opt.label} (${this.opt.layout})`);
        this.cancel = true;
      }
    }
  });

  // methods for panel
  $.ak_designpanel.prototype = {
    finishConstruct: function() {

      const oPanel = this.dhx;

      // if height or width is available -> set it
      if (this.opt.collapsed)
        oPanel.collapse();
      else {
        if (this.opt.width)
          oPanel.setWidth(parseInt(this.opt.width));
        if (this.opt.height)
          oPanel.setHeight(parseInt(this.opt.height));
      }
    },

    setOption: function(option, value) {

      this.opt[option] = value;

      const oPanel = this.dhx;
      const oPanelset = this.parent.dhx;

      if (oPanel && oPanelset) {
        switch (option) {
          case 'title':
            oPanel.showHeader();
            oPanel.setText(value);
            oPanelset.setCollapsedText(this.opt.panelId, value);
            break;
          case 'height':
            if (value)
              oPanel.setHeight(parseInt(value));
            break;
          case 'width':
            if (value)
              oPanel.setWidth(parseInt(value));
            break;
          case 'attachObject':
            oPanel.attachObject(value);
            break;
        }
      }
      return this;
    },

    // hide header
    hideHeader: function() {
      this.dhx.hideHeader();
    },

    // show header
    showHeader: function() {
      this.dhx.showHeader();
    },

    // attach object
    attachObject: function(oNode) {
      // get element
      const oPanel = this.dhx;
      if (oPanel)
        oPanel.attachObject(oNode);
    },

    // attach context menu
    attachContextMenu: function() {

      // add id to header
      $(this.dhx).find('div > div:first').attr('id', `Container${this.opt.id}`);

      // menu on header
      const oSelf = this,
        oMenu = new dhtmlXMenuObject();
      oMenu.renderAsContextMenu();
      oMenu.addContextZone(`Container${this.opt.id}`);
      oMenu.addNewChild(oMenu.topId, 0, 'saveProfile', 'Save Detail profile');
      oMenu.attachEvent('onClick', id => {
        oSelf.contextMenu(id);
      });
    },

    // context menu
    contextMenu: function() {
      // check for first frame
      if (this.childs[0] && this.childs[0].view == 'frame')
        this.childs[0].saveProfile();

    },

    toggleShowHide: function() {
      // get element
      const oPanel = this.dhx;
      if (oPanel) {
        if (oPanel.isCollapsed())
          oPanel.expand();
        else
          oPanel.collapse();
      }
    }

  };
})(jQuery, jQuery);
