// ************************* ribbonbutton ********************************
$.extend({
  ak_ribbonbutton: function(options) {
    const oSelf = this,
      defaults = {
        id: '',
        title: '',
        image: '',
        image2: '',
        isBig: false
      };

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;
    this.ribbon = akioma.getRibbon(oSelf);

    this.registerDynObject = true;
    this.useParentDynObjectLink = true;

    // get new element
    let cName = this.opt.name,
      cTitle = this.opt.title,
      cImage = this.opt.image,
      cText = (this.opt.imageOnly) ? '' : cTitle;

    if (this.ribbon.ribbonMenuStructure != undefined)
      cName = this.opt.id;


    cText = akioma.stringReplace(cText, '&', '');
    cTitle = akioma.stringReplace(cTitle, '&', '');

    const iPos = this.parent.opt.pos + 1;
    this.parent.opt.pos = iPos;

    cImage = (cImage == '') ? null : cImage;

    let cBtnType = 'button';
    if (this.opt.type == 'toggle')
      cBtnType = 'buttonTwoState';

    const oRibbon = this.getAncestor('ribbon');
    if (this.opt.defaultGoBtn)
      oRibbon.defaultGoBtn = this;

    this.parent.prop.fields.push({
      'id': cName,
      'text': cText,
      'img': cImage,
      'disable': this.opt.disable,
      'isbig': this.opt.isBig,
      'type': cBtnType
    });

  }

});

// methods for ribbon
$.ak_ribbonbutton.prototype = {
  // finish ****************
  finishConstruct: function() {
    const oSelf = this;

    const cTitle = this.opt.title;
    let cTooltip = (this.opt.tooltip) ? this.opt.tooltip : cTitle;
    if (cTooltip) {
      const cName = this.opt.name;
      if (this.opt.shortCut)
        cTooltip += ` (${this.opt.shortCut})`;

      const oRibbon = akioma.getRibbon(this).dhx;
      oRibbon.setItemToolTip(cName, cTooltip);
    }
    // check visible attribute for btn
    try {
      if (this.opt.hidden)
        this.getAncestor('ribbon').dhx.hide(this.opt.id);
    } catch (e) {
      akioma.log.error(`could not hide ribbon ${this.opt.type}`, e);
    }

    try {
      if (this.opt.disabled)
        this.getAncestor('ribbon').dhx.disable(this.opt.id);
    } catch (e) {
      akioma.log.error(`could not disable ribbon ${this.opt.type}`, e);
    }

    oSelf.parent.parent.aItems.push(this.opt.id);


    // check for key event
    if (this.opt.shortCut) {
      const oCont = this.getAncestor('ribbon'),
        aShort = this.opt.shortCut.split('-'),
        oKey = {
          altKey: false,
          ctrlKey: false,
          shiftKey: false,
          key: aShort[1]
        };

      switch (aShort[0]) {
        case 'ALT':
          oKey.altKey = true;
          break;
        case 'CTRL':
          oKey.ctrlKey = true;
          break;
        case 'SHIFT':
          oKey.shiftKey = true;
          break;
      }
      if (oCont.aKeys == undefined) {
        oCont.aKeys = [];
        oCont.oKeyBtns = [];
      }
      oCont.aKeys.push(oKey);
      oCont.oKeyBtns.push(oSelf);

      if (oCont) {
        const oWindow = oSelf.dynObject.container.controller,
          mousetrap = oWindow.oMouseTrap;

        // build key combo for binding
        const aCom = [];
        oKey.cCommand = '';
        if (oKey.altKey)
          aCom.push('alt');
        if (oKey.ctrlKey)
          aCom.push('ctrl');
        if (oKey.shiftKey)
          aCom.push('shift');
        aCom.push(oKey.key);

        oKey.cCommand = aCom.join('+').toLowerCase();

        // bind key shortcut on window mousetrap object
        mousetrap.bind(oKey.cCommand, () => {
          const bActiveWindow = oWindow.dhx.isOnTop();
          if (bActiveWindow) {
            try {
              if (document.activeElement)
                document.activeElement.blur();

            } catch (e) {
              console.warn(e);
            }

            const $Btn = $(oCont.dhx._items[oSelf.opt.id].base),
              $BtnBlock = $Btn.parents('.dhxrb_block_base');

            let cBtnVisibility;
            if ($BtnBlock.length > 0)
              cBtnVisibility = $BtnBlock.css('visibility');

            let bBtnVisible;
            if (cBtnVisibility)
              bBtnVisible = (cBtnVisibility != 'hidden') && !($BtnBlock.hasClass('dhxrb_item_hide'));
            else
              bBtnVisible = $Btn.is(':visible');
            if (bBtnVisible) {
              const oRibbon = akioma.getRibbon(oSelf);

              if (oRibbon.ribbonMenuStructure != undefined)
                oSelf.click(oSelf.opt.id);
              else
                oSelf.click();
            }
          }
        });
      }
    }
  },

  // click *****************
  click: function(cId) {

    const oRibbon = akioma.getRibbon(this);

    if (oRibbon.ribbonMenuStructure != undefined) {
      const oMenu = oRibbon.ribbonMenuStructure.getMenuItemByCode(cId);
      oRibbon.ribbonMenuStructure.applyAction(oMenu.id, oRibbon, this);
      return true;
    }


    // call action
    app.controller.callAction({
      ActionType: this.opt.actiontype,
      Action: this.opt.action,
      Category: this.opt.category,
      RunPar: this.opt.RunPar,
      CallObject: this.opt.logicalObject,
      caller: this
    },
    this.getAncestor('ribbon'));
    return true;
  },


  // state change *****************
  stateChange: function() {
    app.controller.callAkiomaCode(this, this.opt.action);
    return true;
  },

  // set property *********
  setProperty: function(oElm) {
    setProperty(this, oElm);
  },

  destroy: function() {
    const oCont = this.getAncestor('ribbon'),
      oWindow = this.getAncestor('window'),
      mousetrap = oWindow.oMouseTrap;
    if (oCont.aKeys) {
      for (let i = 0; i < oCont.aKeys.length; i++) {
        if (oCont.aKeys[i].cCommand != undefined)
          mousetrap.unbind(oCont.aKeys[i].cCommand);

      }
    }
  }
};
