// minicalendar for quick view
// global context function
// eslint-disable-next-line no-unused-vars
function show_minical(self) {
  const $schedulerMiniCalc = $(self),
    id = $schedulerMiniCalc.attr('data-id');

  if (schedulers[id].isCalendarVisible())
    schedulers[id].destroyCalendar();
  else {
    const iDifHeight = $schedulerMiniCalc.height(),
      iPosLeft = $schedulerMiniCalc.offset().left,
      iPosTop = $schedulerMiniCalc.offset().top + iDifHeight;

    schedulers[id].renderCalendar({
      position: { left: iPosLeft, top: iPosTop },
      navigation: true,
      date: schedulers[id]._date,
      handler: function(date) {
        schedulers[id].setCurrentView(date);
        schedulers[id].destroyCalendar();
      }
    });
  }
}


// sample on date range created
akioma.onRangeSelected = function(self) {
  const oEvent = self.controller.getCurrentlyCreatedEvent();
  akioma.log.info(oEvent.start_date, oEvent.end_date);
  akioma.log.info(moment(oEvent.start_date).format('YYYY-MM-DD HH:mm:ss'), moment(oEvent.end_date).format('YYYY-MM-DD HH:mm:ss'));
};


(function($) {

  // ******************* akioma appointment ************************
  $.extend({
    /**
 * SwatScheduler Control
 * @class ak_appointment
 * @augments ak_global
 * @tutorial appointment-desc
 * @param {Object} options Repository attributes for SwatScheduler.
 * @param {string} options.BorderTitle The Title of a dynamic Viewer or Browser
 * @param {string} options.EventOnInitialize client side code to run when Container has been initialized
 * @param {string} options.contextMenu the id of a menuStructure which will be used as a context-menu
 * @param {string} options.titleHeader specifies which panelHeader to use. when empty, then uses the header of its own panel. if "none" then no header at all. if "parent" it uses the header of the parent panel
 * @param {string} options.floatingActionButton the id of a menustructure, which will be rendered as a FAB
 * @param {string} options.LayoutOptions List of multi-layout options for the object.
 * @param {string} options.panelMenu comma separated list of menu-structures which will be shown as panelHeader-Buttons. </br>
 * Can also contain the flag #NoDropDown that specifies that the menu should not be loaded as a dropdown but each menu item should be added in the panel-Header. </br>
 * For example: </br>
 * <code>menuStructSave,menuSettings#NoDropDown,menuLookup</code> </br>
 * The buttons support font icons with the following attributes: </br>
 * 1. Css attributes, defined like this: fa fa-user#color:red </br>
         * 2. Css classes, defined like this: fa fa-user#_style:module_prod
         * 3. Stacked font icons, defined like this: fas fa-circle$fas fa-flag. Both icons also support Css attributes or Css classes, like this: fas fa-circle#color:red$fas fa-flag#_style:module_prod </br>
 * @param {string} options.LABEL WidgetAttributes Label
 * @param {string} options.addRecordContainer Specify the name of the repository object to load when adding a new appointment, updateRecordContainer will be used if not specified
 * @param {string} options.updateRecordContainer Specify the name of the repository object to load when updating a new appointment
 * @param {string} options.VisualizationType Specifies the initial viewmode for the calendar. Default value is 'weekView'. Other possible values are 'dayView', 'monthView', 'yearView'
 * @param {number} options.firstHour The first hour to be displayed in the scheduler. Values between 0 and 23. Default value is 0.
 * @param {number} options.lastHour The last hour to be displayed in the scheduler. Values between 0 and 24. Default value is 24.
 * @param {number} options.scrollHour The initial hour in the timeline scheduler scroll. Values between firstHour + 1 and lastHour. Default value is 0. If current hour < scrollHour then scroll to current time instead.
 * @param {boolean} options.hideWeekends True for hiding Saturday and Sunday, false for showing them. Default value is false.
 * @param {boolean} options.allowOverlapping True for allowing events to overlap, false for preventing events to overlap. Default value is true.
 * @param {boolean} options.markNow True for highlighting the current time, false otherwise. Default value is false.
 * @param {number} options.timeStep Specifies the minimum step (in minutes) for events time values. Default value is 60.
 * @param {string} options.FilterFields String representation of JSON object for setting up the NamedQuery filter based on the given field names
 * In the bellow example the starttime, endtime, subject, location, appallday, bgcolor and selfdesc are the business entity field names.
 * for eg.
 * <code>
 * {
 * "startTime": "starttime",
 * "endTime":"endtime",
 * "subject":"subject",
 * "location":"location",
 * "wholeDay":"appallday",
 * "bgColor":"bgcolor",
 * "notes":"selfdesc",
 * "readonly": "isreadonly"
 * }
 * </code>
 <br> The following attributes (subject, wholeDay, bgColor, notes, readonly) also support constant values, instead of BE fields. A constant value will be used if the value starts with #. Example: "bgColor":"#red"
 <br> Default values if these attributes are not set at all:
 * <code>
 * {
 * "subject":"",
 * "wholeDay":"false",
 * "bgColor":"",
 * "notes":"",
 * "readonly": "false"
 * }
 * </code>
 */

    ak_appointment: function(options) {
      const oSelf = this,
        defaults = {
          rows: 50,
          filterValues: '{"startTime":"starttime","endTime":"endtime","subject":"subject","location":"location","wholeDay":"appallday","bgColor":"bgcolor","notes":"selfdesc"}',
          type: 'time' // time(week,day,month,year), timeline(timeline), unit(unit)
        };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;
      this.view = options.view;

      this.registerVuexModule = true;
      this.registerDynObject = true;
      this.useParentDynObjectLink = true;

      if (!this.opt.customStyle)
        this.opt.customStyle = this.view;

      this.templateTypes = {
        time: [
          {
            value: 'day_tab',
            text: 'Day',
            right: 204
          },
          {
            value: 'week_tab',
            text: 'Week',
            right: 140
          },
          {
            value: 'month_tab',
            text: 'Month',
            right: 280
          },
          {
            value: 'year_tab',
            text: 'Year',
            right: 280
          }
        ],
        unit: [
          {
            value: 'unit_tab',
            name: 'unit'
          }
        ],
        timeline: [
          {
            value: 'timeline_tab',
            name: 'Timeline'
          }
        ]
      };

      const oModes = {
        'dayview': 'day',
        'weekview': 'week',
        'monthview': 'month',
        'yearview': 'year'
      };

      const cVisualization = (this.opt.visualizationType) ? this.opt.visualizationType.toLowerCase() : 'weekview';
      this.initialView = (oModes[cVisualization]) ? oModes[cVisualization] : 'week';

      // initialize scheduler
      const oParent = this.parent.dhx;
      if (oParent) {
        try {
          const cLang = akioma.entry(1, akioma.translation.getLanguage(), '-').toUpperCase();
          const oSched = Scheduler.getSchedulerInstance();
          oSched.skin = 'material';
          oSched.config.details_on_dblclick = true;
          if (cLang != 'EN' && akioma.appointment[cLang])
            oSched.locale = akioma.appointment[cLang];

          // build scheduler template
          const sTabs = oSelf.buildSchedulerTemplate();

          oSelf._customizeScheduler(oSched);

          oParent.attachScheduler(new Date(), this.initialView, sTabs, oSched);

          // attach timeline combo / responsive scheduler
          this.attachTimelineCombo();
          this.bindTimelineComboChangeEvent();

          // set obj dhtmlx
          this.dhx = oSched;
          window.schedulers[oSelf.opt.id] = oSched;
          const units = [
            { key: 1, label: 'Unit A' },
            { key: 2, label: 'Unit B' },
            { key: 3, label: 'Unit C' },
            { key: 4, label: 'Unit D' }
          ];

          const treeUnits = [
            {
              key: 1, label: 'Service A', open: true, children: [
                { key: 20, label: 'Zeisel-Preisel' },
                {
                  key: 30, label: 'Schnick', children: [
                    { key: 40, label: 'Schnack' },
                    { key: 50, label: 'Akioma' }
                  ]
                }
              ]
            },
            { key: 2, label: 'Service B' },
            { key: 3, label: 'Service C' },
            { key: 4, label: 'Service D' }
          ];
          oSched.locale.labels.unit_tab = 'Unit';
          oSched.locale.labels.section_custom = 'Section';

          oSched.createUnitsView({
            name: 'unit',
            property: 'section_id',
            list: units
          });
          oSched.config.full_day = true;
          oSched.config.multi_day = true;


          oSched.createTimelineView({
            name: 'timeline',
            x_unit: 'day', // measuring unit of the X-Axis.
            x_date: '%y-%m-%d', // date format of the X-Axis
            x_step: 1, // X-Axis step in 'x_unit's
            x_size: 31, // X-Axis length specified as the total number of 'x_step's
            x_start: 1, // X-Axis offset in 'x_unit's
            x_length: 31, // number of 'x_step's that will be scrolled at a time
            y_unit: // sections of the view (titles of Y-Axis)
            treeUnits,
            y_property: 'section_id', // mapped data property
            render: 'bar' // view mode
          });

          oSched.attachEvent('onViewChange', mode => {
            const BE = this.dataSource;
            const itemlineTemplate = this.templateTypes[this.opt.type];
            if (typeof (itemlineTemplate) !== 'undefined' && itemlineTemplate !== null) {
              const foundItem = itemlineTemplate.find(item => item.value === (`${mode}_tab`));
              const index = itemlineTemplate.indexOf(foundItem);
              this.timelineCombo.selectOption(index);
            }

            oSelf._setNamedQueryParams(BE, mode);
            BE.openQuery({});
          });

          if (this.opt.filterValues)
            this.oFilterVals = JSON.parse(this.opt.filterValues.replace(/'/g, '"'));
          else
            this.oFilterVals = {};

          // set title in panel
          if (!this.opt.title)
            this.opt.title = 'Termine';
          this.opt.title = akioma.tran(`${this.opt.name}._title`, { defaultValue: this.opt.title });

          // set title and panelMenu buttons in panel header
          akioma.setPanelHeader(oSelf);

          $.extend(this, { dhx: oSched });
        } catch (e) {
          !_isIE && console.error([ 'Error creating scheduler', oSelf, e.message ]);
          akioma.message({ type: 'error', title: 'Error creating scheduler', text: e.message });
        }

      } else
        !_isIE && console.error(`No valid parent for appointment ${this.opt.name}`);

    }
  });

  // methods for appointment
  $.ak_appointment.prototype = {

    // finish construct *****************
    finishConstruct: function() {
      const oSelf = this,
        oSched = this.dhx,
        oSource = this.dataSource.dynObject;

      // set akStyle in appointment
      $(oSelf.dhx._obj).attr('akStyle', oSelf.opt.customStyle);

      let BE = null;
      if (oSource) {
        BE = oSource.controller;
        oSelf.businessEntity = BE;

        // load business entity data
        if (BE.view == 'businessEntity') {

          // setting initial named query params
          oSelf._setNamedQueryParams(BE);

          // create store for events
          oSelf.store = new dhtmlXDataStore();
          oSched.sync(oSelf.store);

          // on first load parse values
          BE.dhx.attachEvent('onXLE', () => {
            // load events into scheduler
            const oData = BE.dhx.data.getIndexRange(),
              aVals = [];

            oSched.clearAll();
            oSelf.store.clearAll();

            let i = 0;

            function sameDay(d1, d2) {
              return d1.getFullYear() === d2.getFullYear() &&
                d1.getMonth() === d2.getMonth() && d1.getDate() === d2.getDate();
            }

            oData.each(() => {
              let oStartTime = new Date(oData[i][oSelf.oFilterVals.startTime.toLowerCase()]),
                oEndTime = new Date(oData[i][oSelf.oFilterVals.endTime.toLowerCase()]),
                bReadOnly = false,
                bWholeDay = false,
                cSubject = '',
                cBgColor = '',
                cNotes = '';

              // if full-day show as fullday
              if (oSelf.oFilterVals.wholeDay) {
                const wholeDay = oSelf.oFilterVals.wholeDay.toLowerCase();
                if (oData[i][wholeDay] == true || wholeDay == '#true') {
                  oStartTime = oSched.date.day_start(oStartTime);
                  oStartTime.setHours(0);
                  oEndTime = new Date(oStartTime);
                  oEndTime.setHours(24);
                  bWholeDay = true;
                }
              }

              if (oSelf.oFilterVals.readonly) {
                const readOnly = oSelf.oFilterVals.readonly.toLowerCase();
                if (oData[i][readOnly] == true || readOnly == '#true')
                  bReadOnly = true;
              }

              if (oSelf.oFilterVals.subject) {
                const subject = oSelf.oFilterVals.subject.toLowerCase();
                cSubject = (oData[i][subject] !== undefined) ? oData[i][subject] : subject.substr(1);
              }

              if (oSelf.oFilterVals.bgColor) {
                const bgColor = oSelf.oFilterVals.bgColor.toLowerCase();
                cBgColor = (oData[i][bgColor] !== undefined) ? oData[i][bgColor] : bgColor.substr(1);
              }

              if (oSelf.oFilterVals.notes) {
                const notes = oSelf.oFilterVals.notes.toLowerCase();
                cNotes = (oData[i][notes] !== undefined) ? oData[i][notes] : notes.substr(1);
              }


              // fix for one day events color
              const cMode = oSelf.dhx.getState().mode;
              if ((cMode == 'year' || cMode == 'month') && sameDay(oStartTime, oEndTime) && bWholeDay == false)
                cBgColor = 'white';


              // add event to scheduler
              oSelf.store.add({
                index: oData[i].id, // datastore id
                id: oData[i]._id, // jsdo id
                start_date: oStartTime,
                end_date: oEndTime,
                text: cSubject,
                color: cBgColor,
                notes: cNotes,
                readonly: bReadOnly
              });

              i++;
            });

            oSelf.aVals = aVals;
          });
        }
      }


      oSched.setCurrentView(new Date(), oSched._mode);

      // init event
      if (this.opt.onInit)
        app.controller.callAkiomaCode(this, this.opt.onInit);

      // select row
      oSched.attachEvent('onItemClick', id => {
        oSelf.rowSelect(id);
      });

      // dblclick
      oSched.attachEvent('onItemDblClick', id => {
        oSelf.rowDblClick(id);
      });

      // on before event rendering
      oSched.attachEvent('onBeforeEventDisplay', () => {});

      function block_readonly(id) {
        if (!id) return true;
        return !this.getEvent(id).readonly;
      }
      oSched.attachEvent('onBeforeDrag', block_readonly);
      oSched.attachEvent('onClick', block_readonly);


      oSched.attachEvent('onEventCreated', id => {
        oSelf.cIdEventCreated = id;


      });
      oSched.attachEvent('onDragEnd', () => {
        if (oSelf.cIdEventCreated) {
          oSelf.prevDateRangeSelected = oSched.getEvent(oSelf.cIdEventCreated);
          // your custom logic
          oSelf.cIdEventCreated = null;
          if (oSelf.opt.eventDateRangeSelected)
            app.controller.callAkiomaCode(oSelf, oSelf.opt.eventDateRangeSelected);

        }

      });
      // on event update
      oSched.attachEvent('onEventChanged', (event_id, event_object) => {

        const BE = oSelf.businessEntity;
        // update old here
        if (BE.view == 'businessEntity') {

          const oEvent = {};
          oEvent[oSelf.oFilterVals.startTime] = event_object.start_date.toISOString();// starttime
          oEvent[oSelf.oFilterVals.endTime] = event_object.end_date.toISOString();// endtime
          oEvent[oSelf.oFilterVals.subject] = event_object.text;// subject
          oEvent[oSelf.oFilterVals.location] = event_object.location;// location


          // BE.dc.updatedRows = [];
          // this is for existing events
          if (event_object.index != undefined) {
            BE.dhx.setCursor(event_object.index);

            oEvent.index = event_object.index;
            oEvent._id = event_object._id;
            BE.dhx.update(event_object.index, oEvent);
            oSelf.store.update(event_object.id);

            // this is for recently added events, via ui
          } else {
            BE.dhx.setCursor(event_object.idd);
            const cObjId = BE.dhx.data.item(event_object.idd)._id;
            oEvent.index = event_object.idd;
            oEvent._id = cObjId;
            BE.dhx.update(event_object.idd, oEvent);
          }

          akioma.skipQuery = true;
          BE.dc.sendData();
          akioma.skipQuery = false;

        }
      });


      // on event remove from business entity
      oSched.attachEvent('onEventDeleted', (id, e) => {
        const BE = oSelf.businessEntity;
        if (BE.view == 'businessEntity') {
          BE.dhx.setCursor(e.index);

          // BE.dc.updatedRows = [];
          if (e.index != undefined)
            BE.dhx.remove(e.index);
          else {

            const itm = BE.dhx.item(e.idd);

            if (itm)
              BE.dhx.remove(itm._id);


            BE.dhx.remove(e.idd);
          }

          // BE.dhx.remove(e.index);
          akioma.skipQuery = true;
          BE.dc.sendData();
          akioma.skipQuery = false;

        }
      });

      // create new record in businesss entity
      oSched.attachEvent('onEventAdded', (event_id, event_object) => {


        const BE = oSelf.businessEntity;
        if (BE.view == 'businessEntity') {
          // create new record and assign it event values
          const cID = BE.dhx.add({});
          BE.dhx.setCursor(cID);
          BE.setFieldValue({ name: oSelf.oFilterVals.startTime, value: event_object.start_date.toISOString() });
          BE.setFieldValue({ name: oSelf.oFilterVals.endTime, value: event_object.end_date.toISOString() });
          BE.setFieldValue({ name: oSelf.oFilterVals.subject, value: event_object.text });
          BE.setFieldValue({ name: oSelf.oFilterVals.location, value: event_object.location });
          BE.dc.sendData();

          // update scheduler
          oSched.getEvent(event_object.id).idd = cID;
          oSched.updateEvent(event_object.id);
        }

      });


      // replacing the default scheduler edit form
      oSched.showLightbox = function(id) {

        const bReadOnly = oSched.getEvent(id).readonly;
        if (bReadOnly)
          return bReadOnly;

        const oItem = oSelf.businessEntity.dhx.item(id),
          cType = oItem == undefined ? 'add' : 'update';


        $('.dhx_year_tooltip').hide();
        if (cType == 'add') {
          if (oSelf.opt.addRecordContainer || oSelf.opt.updateRecordContainer) {

            // $(oSched._obj).find('div[event_id="' + id + '"]').remove();
            const cStartDate = moment(oSched.getEvent(id).start_date).format('DD-MM-YYYYTHH:mm:ss.SSS');
            oSched.deleteEvent(id);
            // call edit dialog
            const cEditRepoScreen = (oSelf.opt.addRecordContainer || oSelf.opt.updateRecordContainer);
            const oCreatePromise = app.controller.launchContainer({
              proc: `${cEditRepoScreen}.r`,
              self: oSelf,
              // data: true,
              autoAdd: true,
              pages: '0,1'
              // containerUserData: {type : 'widget'}
            });

            oCreatePromise.done(res => {
              const oBE = res.dynObject.getLink('PRIMARYSDO:TARGET').controller;
              oBE.stop = true;
              oBE.setForeignKeys([
                {
                  name: 'startTime',
                  value: `${cStartDate}`

                }, {
                  name: 'endTime',
                  value: `${cStartDate}`
                }
              ]);
            });

            return true;
          }
        } else if (cType == 'update') {
          if (oSelf.opt.updateRecordContainer) {
            // call edit dialog
            app.controller.launchContainer({
              proc: `${oSelf.opt.updateRecordContainer}.r`,
              self: oSelf,
              data: true,
              repositionTo: oItem.selfhdl,
              pages: '0,1'
            });
            return true;
          }
        }
      };
    },

    /**
     * Method for setting initial Scheduler customization
     * @param  {Object} oSched DhxmlXScheduler instance
     * @private
     * @instance
     * @memberof ak_appointment
     */
    _customizeScheduler: function(oSched) {

      if (this.opt.firstHour >= 0 && this.opt.firstHour <= 23)
        oSched.config.first_hour = this.opt.firstHour;

      if (this.opt.lastHour >= 0 && this.opt.lastHour <= 24 && this.opt.lastHour > oSched.config.first_hour)
        oSched.config.last_hour = this.opt.lastHour;

      if (this.opt.scrollHour > this.opt.firstHour && this.opt.scrollHour <= this.opt.lastHour)
        oSched.config.scroll_hour = Math.min(new Date().getHours(), this.opt.scrollHour);


      if (!this.opt.allowOverlapping)
        oSched.config.separate_short_events = true;

      if (this.opt.markNow) {
        oSched.config.mark_now = true;
        oSched.config.now_date = new Date();
      }

      oSched.config.time_step = 60;
      if (this.opt.timeStep > 0 && this.opt.timeStep <= 30) {
        const format = oSched.date.date_to_str('%H:%i');
        const step = this.opt.timeStep;
        oSched.config.time_step = this.opt.timeStep;
        oSched.config.hour_size_px = (60 / step) * 22;

        oSched.attachEvent('onTemplatesReady', () => {
          oSched.templates.hour_scale = function(date) {
            let html = '';
            for (let i = 0; i < 60 / step; i++) {
              html += `<div style='height:22px;line-height:22px;'>${format(date)}</div>`;
              date = oSched.date.add(date, step, 'minute');
            }
            return html;
          };
        });
      }

      if (this.opt.hideWeekends) {
        oSched.ignore_month = function(date) {
          if (date.getDay() == 6 || date.getDay() == 0)
            return true;
        };
        oSched.ignore_week = function(date) {
          if (date.getDay() == 6 || date.getDay() == 0)
            return true;
        };
        oSched.ignore_day = function(date) {
          if (date.getDay() == 6 || date.getDay() == 0)
            return true;
        };
      }
    },

    getCurrentlyCreatedEvent: function() {
      return this.prevDateRangeSelected;
    },

    LastDayOfMonth: function(Year, Month) {
      return (new Date((new Date(Year, Month + 1, 1)) - 1)).getDate();
    },
    _getDateString: function(oDate) {
      const month = oDate.getUTCMonth() + 1, // months from 1-12
        day = oDate.getUTCDate(),
        year = oDate.getUTCFullYear();

      return `${year}/${month}/${day}`;
    },
    _setNamedQueryParams: function(BE, mode) {
      const oSelf = this,
        oSched = oSelf.dhx,
        oMinDate = oSched.getState().min_date,
        oMaxDate = oSched.getState().max_date,
        cMinDate = oSelf._getDateString(oMinDate),
        cMaxDate = oSelf._getDateString(oMaxDate);


      if (mode == 'day') {
        BE.setNamedQueryParam('DateRange', 'DateFrom', cMaxDate, 'date');

        const nextDay = new Date(oMaxDate);
        nextDay.setDate(oMaxDate.getDate() + 1);

        BE.setNamedQueryParam('DateRange', 'DateTo', oSelf._getDateString(nextDay), 'date');
      } else {
        // set Named query params
        BE.setNamedQueryParam('DateRange', 'DateFrom', cMinDate, 'date');
        BE.setNamedQueryParam('DateRange', 'DateTo', cMaxDate, 'date');
      }


    },


    block_readonly: function(id) {
      if (!id) return true;
      return !this.getEvent(id).readonly;
    },


    /**
     * Method for building the scheduler timeline tabs template
     * @private
     * @instance
     * @memberof ak_appointment
     * @returns {HTML}
     */
    buildSchedulerTemplate: function() {
      let sTabs = '';

      const timelineTemplate = this.templateTypes[this.opt.type];

      if (this.opt.type == 'time')
        sTabs += `<div id="timeline_combo_zone_${this.opt.id}" style="width: 120px;"></div>`;


      // add each timeline tab based on template type
      sTabs += timelineTemplate.map(tab => `<div class="dhx_cal_tab" name="${tab.value}" style="right:${tab.right || 0}px;"></div>`).join('');

      return sTabs;
    },
    /**
     * Method for attaching timline combo used for resposive view
     * @private
     * @instance
     * @memberof ak_appointment
     * @returns {void}
     */
    attachTimelineCombo: function() {
      const timelineTemplate = this.templateTypes[this.opt.type];

      this.timelineCombo = new dhtmlXCombo({
        parent: `timeline_combo_zone_${this.opt.id}`,
        width: 180,
        className: 'timeline_combo',
        filter: false,
        name: 'combo',
        items: timelineTemplate
      });


      this.timelineCombo.selectOption(1);
      this.timelineCombo.readonly(true);
      $(this.timelineCombo.cont).addClass('timeline_combo');


    },
    /**
     * Method for binding combobox change event in scheduler timeline tab
     * @instance
     * @private
     * @memberof ak_appointment
     * @returns {void}
     */
    bindTimelineComboChangeEvent: function() {
      this.timelineCombo.attachEvent('onChange', value => {
        const tab = $(this.dhx.$container).find(`.dhx_cal_tab[name="${value}"]`);
        if (typeof (tab) !== 'undefined')
          tab.click();
      });
    },


    // get value *****************
    getValue: function() {
      return this.filterMask;
    },

    // set value ********************
    setValue: function(cValue) {
      if (akioma.numEntries(cValue, ':') > 1)
        this.dhx.define('type', akioma.entry(2, cValue, ':'));
      else {
        this.filterMask = cValue;
        this.filterSearch(null, cValue);

      }
    },

    // get field value *******************
    getFieldValue: function(cName) {

      // get grid and handle
      const oGrid = this.dhx,
        cHdl = oGrid.getSelectedRowId(),
        iCol = oGrid.getColIndexById(cName);

      // get value
      const cValue = oGrid.cellById(cHdl, iCol).getValue();
      return cValue;
    },

    // set field value ********************
    setFieldValue: function(cName, cValue) {

      // get grid and handle
      const oGrid = this.dhx,
        cHdl = oGrid.getSelectedRowId(),
        iCol = oGrid.getColIndexById(cName),
        oCell = oGrid.cellById(cHdl, iCol);

      // set value
      if (oCell)
        oCell.setValue(cValue);
      else
        !_isIE && console.error([ 'setFieldValue: no cell available', cName, cValue, cHdl, iCol ]);
    },

    // row select ******************************
    rowSelect: function(cRowId) {

      // set cursor in datasource
      this.dataSource.dhx.setCursor(cRowId);

      // return true;
    },

    // row dblclick *******************************
    rowDblClick: function() {
      app.controller.callAkiomaCode(this, this.opt.eventClick);
    },

    fileDelete: function() {
      const oSrc = this.dataSource;

      akioma.message({
        type: 'confirm',
        title: 'Datensatz löschen',
        text: 'Sollen die Daten wirklich gelöscht werden?',
        callback: function(result) {
          if (result) { // set rowmod
            oSrc.setChanged(true, 'deleted');
            oSrc.setFieldValue({ name: 'rowmod', value: 'D' });

            const cId = oSrc.dhx.getCursor();

            // update record sends record to server to execute delete
            oSrc.updateRecord({});
            oSrc.dhx.remove(cId);
          }
        }
      });
    },

    // open file ****************************
    fileOpen: function() {
      // get actual element
      const oSrc = this.dataSource;
      const cProc = 'articlew.r';

      const cHdl = oSrc.dynObject.getValue('itemhdl');
      if (!isValidHdl(cHdl))
        return;

      app.controller.launchContainer({
        self: this,
        proc: cProc,
        para: `SelfHdl=${cHdl}&Page=0,1`,
        extLink: cHdl,
        data: true
      });

    },
    applyFilter: function() {
      return true;
    },

    filterSearch: function(cField, cMask) {
      if (!cField)
        cField = '#name#';
      if (!cMask)
        this.dhx.filter();
      else
        this.dhx.filter(cField, cMask);
    },

    // add record ********************
    fileAdd: function() {

      // check if we have to call a dialog
      if (this.opt.recordCreate) {
        // call dialog
        app.controller.launchContainer({
          proc: `${this.opt.recordCreate}.r`,
          data: true,
          self: this
        });
      }

    },

    /**
         * Method for activating/deactivating read-only mode for the scheduler
         * @param  {bool} bReadonly True for setting the scheduler as read-only, false for deactivating this setting (default value)
         * @instance
         * @memberOf ak_appointment
         */
    setReadonly: function(bReadonly) {
      const oSelf = this;
      oSelf.dhx.config.readonly = bReadonly;
    },


    // destroy *********************************
    destroy: function() {
    }
  };

})(jQuery, jQuery);
