// ********************* new column ********************
$.extend({
  /**
     * SwatNewColumn Control
     * @class ak_newcolumn
     * @param {Object} options Repository attributes for SwatNewColumn.
     * @param {boolean} options.ENABLED WidgetAttributes Enabled
     * @param {number} options.COLUMN Column position. This may currently be used when rendering some objects. There is no getColumns function, use getCol to retrieve the realized value from an object.
     * @param {string} options.HELP WidgetAttributes Help
     * @param {number} options.WIDTH-CHARS Width in characters. This may currently be used when rendering some objects. There is no get function, use getWidth to retrieve the realized value from an object.
     * @param {number} options.ROW Row position.
     * @param {string} options.LABEL WidgetAttributes Label
     * @param {string} options.EventClick Client side validation code (JavaScript)
     */
  ak_newcolumn: function(options) {
    const defaults = {};

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;


    // get parent
    const oParent = this.parent;
    if (oParent) {
      oParent.prop.fields.push({ type: 'newcolumn' });

      // append to elements in form
      if (this.parent.view == 'form')
        this.parent.elements.push(this);
    }
  }
});


$.ak_newcolumn.prototype = {
  // finish construct **********
  finishConstruct: function() {
    // get field
    this.form = akioma.getForm(this).dhx;
    this.dhx = null;
  },

  endConstruct: function() { }
};
