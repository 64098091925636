(function($) {

  // ********************* listbargroup ******************
  $.extend({
    ak_propsheet: function(options) {
      const defaults = {};

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;

      // get parent
      const oParent = this.parent.dhx;
      if (oParent) {

        // attach data view
        const oData = oParent.attachPropertyGrid();

        $.extend(this, { dhx: oData });
      } else
        akioma.log.error(`No valid parent for propsheet ${this.opt.id}`);

    }
  });

  // methods for listbargroup
  $.ak_propsheet.prototype = {

    destroy: function() {
      // check if dhtmlx element exists -> destroy all attached elements
      if (this.dhx) {
        try {
          // check if we are attached in a dhx element
          this.dhx.detachObject();
          this.dhx = null;
        } catch (e) {
          akioma.log.error([ 'Error destroying', this.view, this.opt.name, e.message ]);
        }
      }
    }
  };

})(jQuery, jQuery);
