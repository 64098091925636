/**
 * Returns mapped color if exists
 * @param  {String} color Example: 'COLOR_WHITE'
 * @return {String} Mapped color. Example: 'white'
 * @instance
 * @memberOf akioma
 */
akioma.getMappedColor = function(color) {
  const cValueBG = color.toUpperCase();
  if (akioma.custom.uiStyleMap != undefined && akioma.custom.uiStyleMap[cValueBG] != undefined)
    return akioma.custom.uiStyleMap[cValueBG];

  return color;
};

// global context variable
// eslint-disable-next-line no-unused-vars
const docCookies = {
  getItem: function(sKey) {
    return decodeURIComponent(document.cookie.replace(new RegExp(`(?:(?:^|.*;)\\s*${encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&')}\\s*\\=\\s*([^;]*).*$)|^.*$`), '$1')) || null;
  },
  setItem: function(sKey, sValue, vEnd, sPath, sDomain, bSecure) {
    if (!sKey || /^(?:expires|max-age|path|domain|secure)$/i.test(sKey))
      return false;

    let sExpires = '';
    if (vEnd) {
      switch (vEnd.constructor) {
        case Number:
          sExpires = vEnd === Infinity ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT' : `; max-age=${vEnd}`;
          break;
        case String:
          sExpires = `; expires=${vEnd}`;
          break;
        case Date:
          sExpires = `; expires=${vEnd.toUTCString()}`;
          break;
      }
    }
    document.cookie = `${encodeURIComponent(sKey)}=${encodeURIComponent(sValue)}${sExpires}${sDomain ? `; domain=${sDomain}` : ''}${sPath ? `; path=${sPath}` : ''}${bSecure ? '; secure' : ''}`;
    return true;
  },
  removeItem: function(sKey, sPath, sDomain) {
    if (!sKey || !this.hasItem(sKey))
      return false;

    document.cookie = `${encodeURIComponent(sKey)}=; expires=Thu, 01 Jan 1970 00:00:00 GMT${sDomain ? `; domain=${sDomain}` : ''}${sPath ? `; path=${sPath}` : ''}`;
    return true;
  },
  hasItem: function(sKey) {
    return (new RegExp(`(?:^|;\\s*)${encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&')}\\s*\\=`)).test(document.cookie);
  }
};

// global context function
// eslint-disable-next-line no-unused-vars
function deleteCookieValue(cookieName) {
  const d = new Date();
  d.setTime(d.getTime());
  const cookieExpireDate = `expires=${d.toString()}`;
  document.cookie = `${cookieName}=expired;${cookieExpireDate}`;
}
