/**
* Helper functions for converting JSDO specifics (filters, parameters) to/from ElasticSearch.
* @namespace {akioma}
*/
class ElasticSearchAdapterHelper {

  /**
  * Convert akQuery filters to ElasticSearch equivalent.
  * @private
  * @param {object} filters AkQuery filters to convert.
  * @returns {object}
  */
  static convertFiltersToElasticSearch(filters) {
    const akFiltersArray = filters.filters || [];
    const elasticLogicalOperator = filters.logic === 'and' ? 'must' : 'should';
    const elasticFilter = {};
    if (akFiltersArray.length > 0) {
      elasticFilter.query = { bool: { [elasticLogicalOperator]: [] } };
      akFiltersArray.forEach(filterObj => {
        const filterObjs = filterObj.filters || [filterObj];

        filterObjs.forEach(fieldFilter => {
          const value = fieldFilter.value;
          let elasticSearchFilter = 'match_bool_prefix';
          switch (fieldFilter.operator) {
            case 'beginsmatches':
              elasticSearchFilter = 'match_bool_prefix';
              break;
            case '=':
            case 'eq':
              elasticSearchFilter = 'match';
              break;
            default:
              console.error(`Unsupported filtering operator: ${fieldFilter.operator}`);
          }
          elasticFilter.query.bool[elasticLogicalOperator].push({ [elasticSearchFilter]: { [fieldFilter.field]: value } });
        });

      });
    }

    return elasticFilter;
  }

  /**
   * Converts AkQuery parameter object to ElasticSearch query.
   * @memberOf ElasticSearchAdapterHelper
   * @param {Object} options AkQuery parameter object.
   * @returns {Object}
   */
  static convertAkQueryToElasticSearch(options) {
    let elasticQuery = {};

    if (!isNull(options.NamedQuery)) {
      if (!isNull(options.NamedQuery.name === 'AutoCompleteSearch')) {
        const query = options.NamedQuery.parameters[0].value ? `${options.NamedQuery.parameters[0].value}~` : options.NamedQuery.parameters[0].value;
        elasticQuery.query = { bool: { must: [{ query_string: { query } }] } };
      }
    }

    if (!isNull(options.akQuery.filters))
      elasticQuery = Object.assign(elasticQuery, akioma.ElasticSearchAdapterHelper.convertFiltersToElasticSearch(options.akQuery.filters));

    return elasticQuery;
  }
}

akioma.ElasticSearchAdapterHelper = ElasticSearchAdapterHelper;
