// ************************ menubar ***********************
$.extend({
  ak_menubar: function(options) {
    const oSelf = this,
      defaults = {
        id: '',
        name: ''
      };

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;

    this.registerDynObject = true;

    // get panelset
    let oParent;
    if (this.opt.containerObject && this.opt.containerObject == 'win')
      oParent = this.parent.parent.dhx;
    else
      oParent = this.parent.dhx;

    if (oParent) {
      const oMenubar = oParent.attachMenu();

      // initialize menubar
      oMenubar.setIconsPath(oDhx.imagePath);
      oMenubar.setSkin(oDhx.skin);

      oMenubar.attachEvent('onClick', cId => oSelf.click(cId));
      oMenubar.setOpenMode('win');

      $.extend(this, {
        dhx: oMenubar,
        pos: -1
      });
    } else
      !_isIE && console.error(`No valid parent for menubar ${this.opt.name}`);


  }
});

// methods for menubar
$.ak_menubar.prototype = {
  // click
  click: function(cId) {
    const oElm = $.getObjectByName({ id: cId });
    if (oElm)
      return oElm.click();
    return false;
  },

  destroy: function() { }
};
