/**
 * Logout callback function
 *
 * @param {Object} pdsession Session Object
 * @param {Object} errorObj Error object
 * @param {String} cRedUrl Redirection url
 */
akioma.onLogout = function(pdsession, errorObj, cRedURL) {
  akioma.socketConnection.disconnect();

  document.cookie = `JSESSIONID=;domain=.${window.location.hostname};expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  document.cookie = `ssoUserId=;domain=.${window.location.hostname};expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  document.cookie = `ssoSessionId=;domain=.${window.location.hostname};expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  document.cookie = `logoutURLRequest=;domain=.${window.location.hostname};expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;

  if (pdsession.lastSessionXHR === null) {
    akioma.notification({ type: 'info', text: 'Logout has been successful.' });

    window.location.href = cRedURL;
  } else if (cRedURL != 'null')
    window.location.href = cRedURL;
  else
    window.location = '/';
};

/**
 * Method for loading dynamic google maps script based on given API KEY
 * @param {string} apiKey Google Maps API KEY
 */
const loadDynamicGoogleMapsScript = function(apiKey) {
  // Create the script tag, set the appropriate attributes
  const script = document.createElement('script');
  script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
  script.defer = true;

  // Append the 'script' element to 'head'
  document.head.appendChild(script);
};

// global context function
// eslint-disable-next-line no-unused-vars
function AkiomaLoginInit(oSelf) {
  let cTimeZone = '<unknown>';
  const oLang = oSelf.dynObject.getObject('loginLang');

  const timezone = jstz.determine();
  let cLang = '<unknown>';

  cLang = akioma.translation.getLanguage();
  akioma.translation.setProperty('debug', false);

  const oMoment = moment(new Date()),
    tzOffset = oMoment.format('Z');
  cTimeZone = `${timezone.name()} (${tzOffset})`;
  akioma.tzOffset = tzOffset;

  if (oMoment.isDST())
    cTimeZone += ' DST';
  cTimeZone = akioma.tran('app.yourTZ', { defaultValue: 'Timezone: ', tz: cTimeZone });
  cLang = akioma.tran('app.yourLang', { defaultValue: 'Language: ', lang: cLang });

  oSelf.dynObject.container.controller.setTitle(`${cTimeZone}  -  ${cLang}`);

  try {
    if (oLang)
      oLang.setValue(akioma.entry(1, akioma.translation.getLanguage(), '-'));
  } catch (err) {
    akioma.log.warn(err);
  }

  akioma.makeTransparent(oSelf.dynObject.container, 0.7);
  // WCAG general accessibility recommendation is that media such as background video play through only once.
  // Loop turned on for the purposes of illustration; if removed, the end of the video will fade in the same way created by pressing the "Pause" button
  // set login screen background
  setAkiomaLoginBackground();

  try {
    (oSelf.dynObject.getValue('login_name')) ? oSelf.dhx.setItemFocus('login_password') : oSelf.dhx.setItemFocus('login_name');
  } catch (oErr) {
    akioma.log.error([ 'error applying focus', oErr ]);
  }
}

function setAkiomaLoginBackground() {
  const cBgFile = app.sessionData.loginBackground || '/imgs/bgs/akiomanewbg.png',
    aImgExts = [ 'jpg', 'jpeg', 'png', 'svg' ],
    reExtension = /(?:\.([^.]+))?$/,
    cExt = reExtension.exec(cBgFile)[1].toLowerCase(),
    $body = $('body');

  if (cExt == 'mp4') {
    if (!_isIE) {
      const cVideo = `${'<video poster="" id="bg-login" playsinline autoplay muted>' +
                      '<source src="'}${cBgFile}" type="video/mp4">` +
                    '</video>';
      $body.append(cVideo);
    }
  } else if ($.inArray(cExt, aImgExts) != -1) {

    $body.append('<div id="bg-login"></div>');
    $body.find('#bg-login').css('background-image', `url(${cBgFile})`);

  }
}

// global context function
// eslint-disable-next-line no-unused-vars
function logout() {
  $.ajax({
    type: 'POST',
    async: false,
    url: '/akioma/logout.r',
    data: {},
    dataType: 'json',
    success: function() {
      akioma.message({ type: 'info', title: 'Logout', text: 'Sie haben Sich erfolgreich abgemeldet.' });
    },
    error: function(xhr, textStatus, errorThrown) {
      akioma.log.error(`Error during logout '${errorThrown}`, 3);
    }
  });


  // close all windows
  while (oDhxTree.childs.length > 0)
    oDhxTree.childs[0].close();


  try {
    dhtmlx.delay(() => {
      startLinks.filter(obj => {
        // check state of login
        if (obj.group == 'logout')
          return false;
        return true;
      });
    });
  } catch (e) {
    akioma.message({ type: 'alert-error', title: 'Logout', text: `Error executing filter in logout:<br />${e.message}` });
  }

  // now open login
  login();

  $('#startMenuButton').removeClass('active');
  $('#startMenu').css({ visibility: 'hidden' });
}

function login() {
  const deferred = $.Deferred();

  const logoutURL = akioma.devTools.getParameterByName('logoutURLRequest');

  let cDataParams = 'action=sendLogin';

  const cQueryAutoStart = akioma.devTools.getParameterByName('autostartObjects');
  const cQueryBaseLayout = akioma.devTools.getParameterByName('baseLayoutObject');
  const cQueryLaunchContainer = akioma.devTools.getParameterByName('launchContainer');

  const cCustom = akioma.devTools.getParameterByName('custom');
  const cSsoUserId = akioma.devTools.getParameterByName('ssoUserId');
  const cSsoSessionId = akioma.devTools.getParameterByName('ssoSessionId');

  // set external screen temporary launchContainer repositionTo
  if (cCustom) {
    const oExternalData = akioma.devTools.getParams(cCustom);
    akioma.ExternalScreen.setExternalData(oExternalData);
  }

  akioma.ExternalScreen.loadExternalDesignerNamespace();

  // set external screen settings/margins for layout
  const cQueryExternalSettings = akioma.devTools.getParameterByName('screenSettings');

  if (cQueryExternalSettings) {
    try {
      const currentsettings = containerMarginSettings[AkDhxLayoutOffsetsManager._selected];
      const cSettingsNamespace = cQueryExternalSettings.split('=')[0];
      const iVal = Number(cQueryExternalSettings.split('=')[1]);

      if (cSettingsNamespace == 'dhtmlXCellTop.base')
        currentsettings['dhtmlXCellTop']['base'] = { t: iVal, l: iVal, r: iVal, b: iVal };

      if (cSettingsNamespace == 'dhtmlXCellObject.attachLayout.dhtmlXWindowsCell')
        currentsettings['dhtmlXCellObject']['attachLayout']['dhtmlXWindowsCell'] = { t: iVal, l: iVal, r: iVal, b: iVal };


    } catch (e) {
      console.warn(e);
    }
  }

  if (cQueryAutoStart)
    cDataParams += (`&AutostartObjects=${cQueryAutoStart}`);
  if (cQueryBaseLayout)
    cDataParams += (`&BaseLayoutObject=${cQueryBaseLayout}`);

  // setup logout url for appdirect marketplace integration
  if (logoutURL == 'null')
    cDataParams = `${cDataParams}&logoutURLRequest=${logoutURL}`;
  else if (logoutURL)
    window.sessionStorage.setItem('logoutRedirectUrl', logoutURL);


  const webLoginReq = $.ajax({
    async: true,
    url: '/web/Login/Init',
    dataType: 'json',
    data: cDataParams
  });

  const promiseUISettings = loadUiSettings();
  const promiseSessionContext = getSessionContext();

  const aRequests = [
    webLoginReq,
    promiseUISettings,
    promiseSessionContext
  ];

  $.when.all(aRequests).then(aResults => {
    const data = aResults[0][0],
      statusLogin = aResults[0][1],
      uiSettingsData = aResults[1][0],
      statusUiSettings = aResults[1][1],
      oSessionContext = aResults[2][0];

    if (statusLogin == 'success') {
      akioma.sessionContext = (typeof (oSessionContext) == 'object') ? oSessionContext : undefined;
      akioma._sealSessionContext();

      akioma.devTools.loadParams();

      if (data.initialSettings.translationNamespaces) {
        const namespaces = data.initialSettings.translationNamespaces.split(',');
        if (namespaces.length > 1) {
          const fallbackNS = namespaces.slice();
          fallbackNS.shift();
          akioma.translation.setProperty('fallbackNS', fallbackNS);
        }

        akioma.translation.setProperty('ns', namespaces);
        akioma.translation.setProperty('defaultNS', namespaces[0]);
        app.sessionData.translationNamespace = namespaces[0];
      }

      if (cQueryLaunchContainer)
        akioma.ExternalScreen.setContainer(cQueryLaunchContainer);


      // extend current sessionData with initialSettings backend sessionData
      if (statusUiSettings == 'success' && typeof (data.initialSettings.sessionData) == 'object')
        $.extend(app.sessionData, data.initialSettings.sessionData);
      else
        akioma.notification({ type: 'error', text: 'Could not load uisettings.json file.', expire: 3000 });

      if (typeof (uiSettingsData) == 'object')
        $.extend(app.sessionData, uiSettingsData);

      akioma.setServerDescription();

      akioma.Themes.defaultTheme.key = app.sessionData.defaultUiTheme;

      if (akioma.applicationSettings && akioma.applicationSettings.googleAPIKey)
        loadDynamicGoogleMapsScript(akioma.applicationSettings.googleAPIKey);

      // if already has valid session
      // login using sso
      if (cSsoSessionId && cSsoUserId) {
        // Replace web/Login URL with /
        window.history.replaceState({}, '', '/');

        akioma.translation.initialize().then(() => {
          akioma.aLoadedTGF = []; // use to point to existing treegrid, grid and form objects
          data.initialSettings.sso = true;
          data.initialSettings.ssoSessionId = cSsoSessionId;
          data.initialSettings.ssoUserId = cSsoUserId;

          sendLogin({
            ssoSessionId: data.initialSettings.ssoSessionId,
            ssoUserId: data.initialSettings.ssoUserId
          });

        });
      // if already has valid session
      } else if (data.validSession) {
        deferred.resolve();
        akioma.translation.initialize().then(() => {
          akioma.aLoadedTGF = []; // use to point to existing treegrid, grid and form objects
          akioma.sessionEstablishedPromise = establishSession (data.initialSettings);

          // after all custom file are loaded.. if undefined returns true,
          // and after we established a correct session
          $.when(
            akioma.CustomFilePromise,
            akioma.sessionEstablishedPromise

          ).then(() => {
            // the base layout repository object. rendered as fullscreen layout in the browser
            app.sessionData.baseLayoutObject = data.initialSettings.baseLayoutObject;
            // the repository objects to load after login. usually some kind of main menu
            app.sessionData.autostartObjects = data.initialSettings.autostartObjects;

            akioma.Themes.defaultTheme.key = app.sessionData.defaultUiTheme;
            akioma.onSuccessLoginInitialize().always(() => {
              buildLayouts();
            });
          });
        });
      } else {
        akioma.Themes.setTheme({ key: akioma.Themes.defaultTheme.key }).then(() => {
          // else show form for login
          akioma.translation.initialize().then(() => {
            // parse login
            akioma.loginPopUp = data.loginScreen;
            oDhxTree.target = oDhxTree;
            let oForm;
            app.controller._onLoadContainerSuccess(data.loginScreen, oDhxTree).then(window => {

              oForm = (window.getDescendant('form'));

              // If the browser is not firefox
              checkAutoCompleteOnLogin(oForm);

              // default values
              const cDef = $.cookie('defLogin');
              if (cDef) {
                const aDef = atob(cDef).split('|');
                aDef[0] && oForm.dhx.setItemValue('login_name', aDef[0]);
                aDef[1] && oForm.dhx.setItemValue('login_password', aDef[1]);

                const aIDs = [ 'login_name', 'login_password' ];
                for (const i in aIDs) {
                  if (aDef[i].length > 0) {
                    const oCompInp = oForm.dhx.getInput(aIDs[i]),
                      $cont = $(oCompInp).parent().parent();
                    $cont.addClass('active');
                  }
                }
              }

              $('#startMenuButton').removeClass('active');
              $('#startMenu').css({ visibility: 'hidden' });

              try {
                dhtmlx.delay(() => {
                  startLinks.filter(obj => {
                    // check state of login
                    if (obj.group == 'login')
                      return false;
                    return true;
                  });
                });
              } catch (e) {
                akioma.message({ type: 'alert-error', title: 'Login', text: `Error executing filter for in login<br />${e.message}` });
              }
            });
          });
        });
      }
    } else
      akioma.message({ type: 'alert-error', title: 'Login', text: `Error executing filter for in login<br />${statusLogin}` });

  }).fail(e => {
    let errorMsg = '';
    if (e[0].responseJSON && e[0].responseJSON.message)
      errorMsg = e[0].responseJSON.message;

    if (e[2])
      errorMsg = e[2];

    akioma.message({ type: 'error', title: 'Login', text: `Error loading login screen <br />${errorMsg}` });
  });

  return deferred.promise();
}

/**
 * Executed during login process, after authentication is successful.
 * All after authentication initialization (Eg. userprofile, config fetching) should be included here.
 *
 * @return {Promise} Note: a jQuery promise is returned
 */
akioma.onSuccessLoginInitialize = function() {
  const jQueryPromises = [
    UserProfile.loadFromServer(),
    akioma.ckEditorConfig.loadFromServer(),
    akioma.translation.loadFromServer(),
    loadApplicationSettings(),
    loadApplicationSettingsByDomain('uiStyle')
  ];
  return $.when.all(jQueryPromises);
};

/**
 * Global method for loading the settings JSON file for sessionData, static file under swat-webui/akioma/settings/uisettings.js
 */
function loadUiSettings() {
  return $.ajax({
    url: '/settings/uisettings.json',
    dataType: 'json'
  });
}

/**
 * Method to check for autocomplete in form, chrome bugfix
 * @param {ak_object} oForm Form, block or fieldset
 */
function checkAutoCompleteOnLogin(oForm) {
  // If the browser is not firefox
  if (navigator.userAgent.toLowerCase().indexOf('firefox') === -1) {
    let autofiledFields = 0;
    let tries = 10;
    for (let index = 0; index < oForm.childs.length; index++) {
      const type = oForm.childs[index].view;
      if (type === 'block' || type === 'fieldset')
        checkAutoCompleteOnLogin(oForm.childs[index]);
      else if (oForm.childs[index].dhx) {
        const input = $(`#${oForm.childs[index].dhx.id}`);
        const infunc = () => {
          if (input[0] && input.is(':-webkit-autofill')) {
            input[0].parentNode.parentNode.isAutofill = true;
            autofiledFields++;
            const $itemcont = $(input[0]).parent().parent();
            $itemcont.addClass('active').addClass('focusedinp');
            dhtmlXForm.prototype.items.input._validate(oForm.childs[index].dhx.parentNode.parentNode);
          } else
            tries--;

          if (autofiledFields < 2 && tries > 0)
            setTimeout(infunc, 300);

        };
        if (autofiledFields < 2 && tries > 0)
          setTimeout(infunc, 300);
      }
    }
  }
}

// global context function
// eslint-disable-next-line no-unused-vars
function sendOTP(oSelf) {
  const oForm = oSelf.form;
  $.ajax({
    async: false,
    type: 'POST',
    url: '/akioma/ak_login.p',
    dataType: 'json',
    data: `action=sendOTP&${$.param(oForm.getFormData())}&tzOffset=${akioma.tzOffset}&uiLang=${akioma.translation.getLanguage()}`,
    success: function(data) {
      // if login is valid
      if (data.valid)
        dhtmlx.message({ text: `${data.result}<br />`, lifetime: 10000, type: 'info' });
      else
        dhtmlx.message({ text: `Fehler.<br />${data.errorString}<br />`, lifetime: 10000, type: 'error' });
    },
    error: function(xhr, textStatus, errorThrown) {
      akioma.log.error(`Error loading login: ${textStatus} -> ${errorThrown}`);
    }
  });
}

function setupJSDOSession(AUTH_TYPE) {
  const serviceURI = window.location.origin,
    jsdoSettings = {
      serviceURI: serviceURI,
      authenticationModel: AUTH_TYPE || progress.data.Session.AUTH_TYPE_FORM,
      name: 'akSessionKey'
    };
  // create a new session object
  akioma.restSession = new progress.data.JSDOSession(jsdoSettings);
}

function establishSession(data, oForm, AUTH_TYPE) {
  const uri = window.location.toString();
  if (uri.indexOf('?') > 0) {
    const clean_uri = uri.substring(0, uri.indexOf('?'));
    window.history.replaceState({}, document.title, clean_uri);
  }

  const deferred = $.Deferred();

  const logSessionManager = akioma.log.getLogger('SessionManager');
  logSessionManager.debug([ 'establishSession', data, oForm ]);

  if (oForm && oForm.ssoSessionId == undefined) {
    // call autoexec
    const oAuto = app.controller.callServerMethod('akioma/autoexec.r', []);
    if (oAuto && oAuto.sessionData)
      app.sessionData = $.extend({}, oAuto.sessionData, app.sessionData);
  } else
    app.sessionData = $.extend({}, data.sessionData, app.sessionData);


  synchronizeSession();

  if (akioma.sessionContext && akioma.sessionContext.eSwatSessionContext.translationNamespaces) {
    const aNamespaces = akioma.sessionContext.eSwatSessionContext.translationNamespaces.split(',');
    app.sessionData.translationNamespace = aNamespaces[0];
  }

  // set the userprofile profileAutoStore
  if (app.sessionData.profileAutoStore)
    UserProfile.setProfileAutoStore(app.sessionData.profileAutoStore);

  if (app.sessionData.translationNamespace)
    akioma.translation.loadNamespaces(app.sessionData.translationNamespace);

  // set session data attribute as global date format
  if (app.sessionData.dateFormat) {
    window.dhx.dateLang = app.sessionData.masterLanguageKey.toLowerCase();
    window.dhx.dateFormat[window.dhx.dateLang] = app.sessionData.dateFormat;
  }

  // Kinvey init
  if (akioma.KinveyIsEnabled()) {
    akioma.KinveyInitialize();
    if (!isNull(oForm) && !isNull(oForm.login_name) && !isNull(oForm.login_password)) {
      akioma.KinveyLogin({
        username: oForm.login_name.split('@')[0],
        password: oForm.login_password
      });
    }
  }

  const oPromiseGetNodes = getNodeTypes(); // get node types next

  oPromiseGetNodes.always(() => {
    akioma.aLoadedTGF = []; // use to point to existing treegrid, grid and form objects

    /* log into rest service */
    let cUser, cPass;
    if (oForm && oForm.ssoSessionId == undefined) {
      cUser = oForm.login_name;
      cPass = oForm.login_password;
    } else if (data.sso) {
      cUser = data.ssoUserId;
      cPass = data.ssoSessionId;
    } else if (data.customLogin) {
      cUser = data.customLogin.user;
      cPass = data.customLogin.password;
    }

    if (!akioma.restSession) {
      setupJSDOSession(AUTH_TYPE);
      // Check whether there had been a login and we are still authorized
      akioma.restSession
        .isAuthorized()
        .then(() => {
          // start app without login, already in buildLayouts();
          akioma.NotificationMessage.clearAllMessages();
          deferred.resolve();
        }).catch(({ result, details }) => {
          switch (result) {
            case progress.data.Session.LOGIN_AUTHENTICATION_REQUIRED:
            case progress.data.Session.AUTHENTICATION_FAILURE:
            // perform login, then start web app // first logout because of validSession needs to expire with jsdoSession
              if (akioma.restSession.connected)
                akioma.restSession.logout().then(establishAfterRestLogin);
              else {
                establishAfterRestLogin(cUser, cPass).then(() => {
                  deferred.resolve();
                });
              }
              break;
            case progress.data.Session.GENERAL_FAILURE:
            // possibly some sort of offline problem
              if (details.offlineReason)
                deferred.reject();

              break;
          }
        });
    } else {
      akioma.NotificationMessage.clearAllMessages();
      deferred.resolve();
    }
  });

  return deferred.promise();
}

function establishAfterRestLogin(cUser, cPass) {
  const promise = akioma.restSession.login(cUser, cPass);
  const deferred = $.Deferred();

  promise.then(() => {
    akioma.NotificationMessage.clearAllMessages();
    deferred.resolve();
  }).catch(() => {
    akioma.notification({ type: 'error', text: akioma.tran('Authentication.failed', { defaultValue: 'Authentication failed. Please check your user and password and try again.' }) });
    deferred.reject();
  });

  return deferred.promise();
}

/**
 * Method for loading any given domain application settings
 * @param {string} domainName The domain to load
 */
// global context function
function loadApplicationSettingsByDomain(domainName) {
  const deferred = $.Deferred();
  akioma.invokeServerTask({
    name: 'Akioma.Crm.MasterData.System.ApplicationSettingsBT',
    methodName: 'GetApplicationSettingsByDomain',
    paramObj: { plcParameter: { ApplicationSettingsDomain: domainName } }
  }).then(response => {
    const applicationSettings = response.plcParameter.ApplicationSettings[domainName];
    if (!akioma.applicationSettings)
      akioma.applicationSettings = {};

    akioma.applicationSettings[domainName] = applicationSettings;
    deferred.resolve(applicationSettings);
  }).fail(deferred.reject);

  return deferred.promise();
}

/**
 * Loads application settings from backend.
 * @return {Promise}
 */
function loadApplicationSettings() {
  return akioma.invokeServerTask({
    name: 'Akioma.Crm.MasterData.System.ApplicationSettingsBT',
    methodName: 'GetApplicationSettings',
    paramObj: { plcParameter: {} }
  }).then(response => {
    if (akioma.applicationSettings)
      Object.assign(akioma.applicationSettings, response.plcParameter.ApplicationSettings);
    else
      akioma.applicationSettings = response.plcParameter.ApplicationSettings;
  });
}

// Extend old sessionData with SwatSessionContext
function synchronizeSession() {
  if (akioma.sessionContext)
    $.extend(app.sessionData, akioma.sessionContext.eSwatSessionContext);
}

/**
 * Show allowed authentication buttons based on sessionData allowedAuthentication
 * @param   {object}  oSelf
 * @return  {void}
 */
// global context function
// eslint-disable-next-line no-unused-vars
function GetAllowedAuthentication(self) {
  const aAllowAuthentications = app.sessionData.allowedAuthentication.split(',');
  const oSelf = self.controller;

  // if not-allowed AkiomaUser hide button
  if (aAllowAuthentications.indexOf('AkiomaUser') == -1)
    oSelf.getDescendant('form').hideFormField('login');


  // if not-allowed ActiveDirectory hide button
  if (aAllowAuthentications.indexOf('ActiveDirectory') == -1)
    oSelf.getDescendant('form').hideFormField('login_ad');


  // if not-allowed AzureActiveDirectory hide button
  if (aAllowAuthentications.indexOf('AzureActiveDirectory') == -1)
    oSelf.getDescendant('form').hideFormField('login_azure_ad');


  // if not-allowed AppDirect hide button
  if (aAllowAuthentications.indexOf('AppDirect') == -1)
    oSelf.getDescendant('form').hideFormField('login_appdirect');

}

/**
 * Send Login request to backend via configurable authentication strategies
 * @param   {dynObject}  self  The button clicked
 * @return  {Promise}
 */
// global context function
// eslint-disable-next-line no-unused-vars
function sendLoginAuth(self) {
  return new Promise((resolve, reject) => {

    let authUrl = app.sessionData.ssoAuthenticationURI;
    const loginMethod = self.controller.opt.name;
    const oSelf = self.controller;
    const form = oSelf.parent.form;

    if (akioma.WaitCursor.isWaitStateActive(self))
      return;

    let sendLoginPromise;
    let isValidForm = true;

    // for azure no validation is required because of redirect, so isValidForm always true!
    if (loginMethod !== 'login_azure_ad') {
      $(document.activeElement).blur(); // fix for autocomplete user/pass input validation check
      isValidForm = form.validate();
    }

    if (isValidForm) {

      akioma.WaitCursor.showWaitState(self);

      switch (loginMethod) {
        case 'login':
          sendLoginPromise = sendLogin(oSelf);
          break;
        case 'login_ad':
          sendLoginPromise = sendLoginRequestAD(oSelf);
          break;
        case 'login_azure_ad':
          authUrl += 'azure/login';
          sendLoginPromise = sendLoginAzure(authUrl);
          break;
        default:
          sendLoginPromise = sendLogin(oSelf);
          break;
      }

      sendLoginPromise.then(() => {
        // hide progress state
        akioma.WaitCursor.hideWaitState(self);
        // on success close login screen or any other windows
        closeLoginScreenAfterLogin();
        resolve();
      }).catch(() => {
        akioma.WaitCursor.hideWaitState(self);
        reject();
      });
    } else
      reject();


  });
}

/**
 * Method executed for closing login screen or any other exisint opened window
 * @returns {void}
 */
function closeLoginScreenAfterLogin() {
  // destroy login window
  if (!isNull(akioma.mainWindows) && !isNull(akioma.mainWindows.unload))
    akioma.mainWindows.unload();

  // close login screen or any other modals
  if (!isNull(akioma.oWindowsModals)) {
    for (const wi in akioma.oWindowsModals.w) {
      const oWinCell = akioma.oWindowsModals.w[wi].cell;
      oWinCell.close();
    }
  }
}

/**
 * Send Login request for AppDirect
 * @return {void}
 */
// global context function
// eslint-disable-next-line no-unused-vars
function sendLoginAppDirect() {
  const authUrl = app.sessionData.ssoAuthenticationURI;
  const host = `${window.location.origin}/`;
  window.location.replace(`${authUrl}saml/login?host=${host}`);
}

/**
 * Send login request for Azure
 * @param {string} authUrl authentication url
 * @returns {Promise}
 */
function sendLoginAzure(authUrl) {
  return new Promise(resolve => {
    const host = `${window.location.origin}/`;
    window.location.replace(`${authUrl}?host=${host}`);
    resolve();
  });
}

/**
 * Sends request for ad authentication
 * @param {object} oSelf
 * @param {boolean} closeLogin
 */
function sendLoginRequestAD(oSelf, closeLogin = false) {
  return new Promise((resolve, reject) => {
    const oForm = akioma.getForm(oSelf).dhx;
    const url = '/web/Login/Login.html';

    // will ignore 4xx errors in global HttpClient error handling for given url
    akioma.HttpClient.display500ErrorsOnly(url);

    $.ajax({
      url: url,
      type: 'post',
      dataType: 'json',
      headers: { 'Content-Type': 'application/json' },
      data: JSON.stringify({ username: oForm.getItemValue('login_name'), password: oForm.getItemValue('login_password') }),
      success: function(returnedData) {
        if (returnedData.status === 'ok') {
          sendLogin({ ssoSessionId: returnedData.ssoSessionId, ssoUserId: returnedData.ssoUserId }).then(() => {
            if (closeLogin)
              closeLoginScreenAfterLogin();

            resolve();
          }).catch(reject);
        }
      }
    }).fail((jqXHR, textStatus, errorThrown) => {
      // display 401 error messages
      if (String(jqXHR.status).startsWith('4')) {
        if (jqXHR.responseJSON) {
          const aParsedMsg = jqXHR.responseJSON.message.split(':');
          const errorTxt = aParsedMsg[1];
          const errorCode = aParsedMsg[0];
          akioma.notification({ type: 'error', text: akioma.tran(`Authentication.${errorCode}`, { defaultValue: errorTxt }) });
        } else
          akioma.notification({ type: 'error', text: `${String(jqXHR.status)} - Error on request.` });
      }
      reject(errorThrown);
    });
  });
}

/**
 * Sends request for ad authentication and closes login screen afterwards
 * @param {object} oSelf
 * @returns {void}
 */

// global context function
// eslint-disable-next-line no-unused-vars
function sendLoginAD(oSelf) {
  return sendLoginRequestAD(oSelf, true);
}

/**
 * Method executed on submit of form or SSO login
 * @param {ak_form} oSelf
 * @returns {Promise}
 */
function sendLogin(oSelf) {

  return new Promise((resolve, reject) => {
    let oForm;
    if (oSelf)
      oForm = oSelf.form;

    const serviceURI = window.location.origin,
      jsdoSettings = {
        serviceURI,
        authenticationModel: progress.data.Session.AUTH_TYPE_FORM,
        name: 'akSessionKey'
      };

    // create a new session object
    akioma.restSession = new progress.data.JSDOSession(jsdoSettings);

    let loginname;
    let loginpw;
    let user;
    if (oForm) {
      loginname = oForm.getItemValue('login_name');
      loginpw = oForm.getItemValue('login_password');
      user = {};
      user.login_name = loginname;
      user.login_password = loginpw;
    }

    const ssoSession = {
      ssoSessionId: oSelf.ssoSessionId,
      ssoUserId: oSelf.ssoUserId
    };

    akioma.restSession
      .isAuthorized()
      .then(({ result }) => {
        // start app without login, already in
        let webLoginPromise;
        if (oSelf.form)
          webLogin(result, user);
        else
          webLogin(result, ssoSession);
        webLoginPromise.finally(resolve);

      }).catch(({ result, info }) => {
        switch (result) {
          case progress.data.Session.LOGIN_AUTHENTICATION_REQUIRED:
          case progress.data.Session.AUTHENTICATION_FAILURE: {
          // cleanup all sessionstorage
            let i = sessionStorage.length;
            while (i--) {
              const key = sessionStorage.key(i);
              if (/akSessionKey/.test(key))
                sessionStorage.removeItem(key);

            }

            // perform login, then start web app
            if (akioma.restSession.connected) {
              akioma.restSession.logout().then(() => {
                afterRestLogin(user, ssoSession).then(resolve).catch(reject);
              });
            } else
              afterRestLogin(user, ssoSession).then(resolve).catch(reject);

            break;
          }
          case progress.data.Session.GENERAL_FAILURE:
            reject();
            // possibly some sort of offline problem
            if (info.offlineReason)
              akioma.notification({ type: 'error', text: `Offline: ${info.offlineReason}` });

            break;
        }
      });
  });
}

/**
 * Method to call after a rest login request
 * @param {object} user Object representing user name and password
 * @param {JSDOSession} session
 * @returns {Promise}
 */
function afterRestLogin(user, session) {
  return new Promise((resolve, reject) => {
    let cUser = '';
    let cPass = '';
    if (user) {
      cUser = user.login_name;
      cPass = user.login_password;
    } else {
      cUser = session.ssoUserId;
      cPass = session.ssoSessionId;
    }
    const promise = akioma.restSession.login(cUser, cPass);
    promise.then(({ result }) => {
      let webLoginPromise;
      if (user)
        webLoginPromise = webLogin(result, user);
      else
        webLoginPromise = webLogin(result, session);
      webLoginPromise.then(resolve).catch(reject);
    }).catch(() => {
      akioma.notification({ type: 'error', text: akioma.tran('Authentication.failed', { defaultValue: 'User Authentication Failed. Please enter correct username and password and try again.' }) });
      reject();
    });
  });
}

function webLogin(iResult, oForm) {
  return new Promise((resolve, reject) => {
    $.ajax({
      async: false,
      url: `/web/Login/getMain&restSession=${iResult}&tzOffset=${akioma.tzOffset}&uiLang=${akioma.translation.getLanguage()}`,
      dataType: 'json',
      data: `action=getLogin&restSession=${iResult}&tzOffset=${akioma.tzOffset}&uiLang=${akioma.translation.getLanguage()}`,
      success: function(data) {
        // if login is valid
        if (data.valid) {
          // save login
          let aDef;
          try {
            aDef = [];

            if (oForm.login_name) {
              aDef.push(oForm.login_name);
              aDef.push('');
              $.cookie('defLogin', btoa(aDef.join('|')), { expires: 30, path: '/' });
            } else {
              aDef.push(oForm.ssoUserId);
              aDef.push(oForm.ssoSessionId);
              aDef.push('');
              aDef.push('');
              aDef.push('');
            }
          } catch (e) {
            akioma.log.error(`Error setting cookie: ${e.message}`);
          }

          const promiseSessionContext = getSessionContext();
          $.when(
            promiseSessionContext
          ).then(oResult => {
            akioma.sessionContext = (typeof (oResult) == 'object') ? oResult : undefined;
            akioma._sealSessionContext();

            akioma.devTools.loadParams();

            akioma.sessionEstablishedPromise = establishSession (data, oForm);
            // after all custom file are loaded.. if undefined returns true,
            // and after we established a correct session
            $.when(
              akioma.CustomFilePromise,
              akioma.sessionEstablishedPromise
            ).then(() => {
              try {

                if (akioma.stopBuidlingLayout == undefined) {
                  akioma.Themes.defaultTheme.key = app.sessionData.defaultUiTheme;
                  akioma.onSuccessLoginInitialize().always(() => {
                    resolve();
                    buildLayouts();
                  });
                } else {
                  reject();
                  delete akioma.stopBuidlingLayout;
                }
              } catch (e) {
                console.error(e);
              }
            });
          });
        } else {
          dhtmlx.message({
            text: akioma.tran('msg.loginErr', { defaultValue: 'Anmeldung fehlerhaft.<br/> __errMsg__<br />Bitte versuchen Sie es erneut.', errMsg: data.errorString }),
            lifetime: 10000, type: 'error'
          });
        }
      },
      error: function(xhr, textStatus, errorThrown) {
        akioma.log.error(`Error loading login: ${textStatus} -> ${errorThrown}`);
      }
    });
  });
}

// global context function
// eslint-disable-next-line no-unused-vars
function doLogin(oSelf) {
  const oForm = oSelf.form;
  let iResult;

  akioma.restSession = new progress.data.JSDOSession();
  akioma.restSession.authenticationModel = progress.data.Session.AUTH_TYPE_FORM;
  try {
    iResult = akioma.restSession.login(
      window.location.origin, encodeURIComponent(oForm.getItemValue('login_name')), encodeURIComponent(oForm.getItemValue('login_password')), '');
  } catch (e) {
    akioma.log.error('connecdt with basic auth failed, trying again with anon', iResult);
  }

  $.ajax({
    async: false,
    url: '/web/Login/Init',
    dataType: 'json',
    data: `action=getLogin&${$.param(oForm.getFormData())}&restSession=${iResult}&tzOffset=${akioma.tzOffset}&uiLang=${akioma.translation.getLanguage()}`,
    success: function(data) {
      // if login is valid
      if (data.valid) {
        let aDef;
        // save login
        try {
          aDef = [];
          aDef.push(oForm.getItemValue('login_name'));
          aDef.push('');

          $.cookie('defLogin', btoa(aDef.join('|')), { expires: 30, path: '/' });
        } catch (e) {
          akioma.log.error(`Error setting cookie: ${e.message}`);
        }

        akioma.sessionEstablishedPromise = establishSession (data, {
          login_name: oForm.getItemValue('login_name'),
          login_password: oForm.getItemValue('login_password')
        });

        // after all custom file are loaded.. if undefined returns true,
        // and after we established a correct session
        $.when(
          akioma.CustomFilePromise,
          akioma.sessionEstablishedPromise
        ).then(() => {
          buildLayouts();
        });

        // destroy login window
        akioma.mainWindows.unload();

        // close login screen or any other modals
        for (const wi in akioma.oWindowsModals.w) {
          const oWinCell = akioma.oWindowsModals.w[wi].cell;
          oWinCell.close();
        }
      } else {
        dhtmlx.message({
          text: akioma.tran('msg.loginErr', { defaultValue: 'Anmeldung fehlerhaft.<br/> __errMsg__<br />Bitte versuchen Sie es erneut.', errMsg: data.errorString }),
          lifetime: 10000, type: 'error'
        });
      }
    },
    error: function(xhr, textStatus, errorThrown) {
      akioma.log.error(`Error loading login: ${textStatus} -> ${errorThrown}`);
    }
  });
}

function buildLayouts() {
  function pingComplete({ info }) {
    if (info.xhr.status === 401 &&
      akioma.restSession.loginResult === progress.data.Session.SUCCESS) {
      // session expired
      window.location.reload();
    }
  }

  function intervalFunction() {
    akioma.restSession.ping().then(pingComplete);
  }

  const sessionTimeout = app.sessionData.sessionTimeout; // value in minutes
  let pingTimeout = 120 * 60 * 1000; // default if not specified 2h
  if (sessionTimeout !== undefined)
    pingTimeout = sessionTimeout * 60 * 1000;

  setInterval(intervalFunction, pingTimeout); // check for user authenticated 2h

  akioma.aNonDesktopWindows = [];
  akioma.aNonDesktopWindowsKeys = {};
  akioma.aDesktopWindows = [];

  akioma.aWindows = [];

  // setup google dynamic map
  if (akioma.applicationSettings && akioma.applicationSettings.googleAPIKey)
    loadDynamicGoogleMapsScript(akioma.applicationSettings.googleAPIKey);


  // remove login bg
  if (!_isIE)
    $('#bg-login').remove();
  $('#bg-login').addClass('user-loggedin');

  const initSocketIO = function() {
    akioma.socketConnection.setupProperties();
    akioma.socketConnection.connect();
    akioma.messaging.bindEvents();
  };
  initSocketIO();

  return akioma.buildMultiWindowLayout();
}

function getSessionContext() {
  return $.ajax({ url: '/web/SessionContext/*' });
}

akioma.login.register = function(oRegisterButton) {
  const oForm = oRegisterButton.controller.parent.dynObject;

  const cPassword = oForm.getValue('password');
  const cPasswordConfirmation = oForm.getValue('password_confirmation');
  if (cPassword !== cPasswordConfirmation) {
    akioma.message({ text: 'Passwords do not match!', type: 'error' });
    return;
  }

  $.ajax({
    type: 'POST',
    async: false,
    url: '/web/Login/Register',
    dataType: 'json',
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify(oForm.getFieldValues()),
    success: function(data) {
      if (data.error)
        akioma.message({ text: data.error, type: 'error' });
      if (data.info)
        akioma.message({ text: data.info, type: 'info' });
      if (data.success)
        akioma.message({ text: data.success, type: 'success' });
    },
    error: function(xhr, textStatus, errorThrown) {
      akioma.log.error(`Error loading login: ${textStatus} -> ${errorThrown}`);
    }
  });
};
