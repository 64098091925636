(function($) {

  // ********************* frame ******************
  $.extend({
    ak_root: function(options) {
      this.opt = $.extend({}, options.att);

      this.registerDynObject = true;
      this.useParentDynObjectLink = true;

      $.extend(this, { dhx: $('body') });

    }
  });

  // methods for frame
  $.ak_root.prototype = {};

})(jQuery, jQuery);
