

// htmlContainer custom form element
dhtmlXForm.prototype.items.htmlContainer = {
  render: function(item, data) {

    item._type = 'htmlcontainer';
    item._hcId = `htmlcontainer${window.dhx4.newId()}`;
    const self = $.getObjectByName({ id: data.userdata.id });

    item._data = data;

    try {
      this.renderVueInstance(item, self);

    } catch (e) {
      akioma.log.warn(e);
      akioma.notification({ type: 'error', text: `There was an error rendering the htmlContainer ${self.opt.name}` });
    }

    return this;
  },
  renderVueInstance: function(item, self) {
    // render vue instance / template
    const dataSource = self.form.dataSource;
    const datasourceNamespace = dataSource.getFilteredNamespace();
    if (dataSource) {
      const namespace = self.form.getFilteredNamespace();
      let template = '';

      // if #FORM# get form attribute template
      if (self.opt.template == '#FORM#')
        template = self.form.opt.Template;
      else
        template = self.opt.Template;

      $(item).append('<div class="vue-template-container"></div>');


      self.$domElement = $(item);
      self.setResponsiveSizes();

      self.vueTemplateInstance = new akioma.VueInstancesFactory('template', {
        namespace,
        template,
        controller: self,
        datasourceNamespace,
        dataSource
      });
      self.vueTemplateInstance.mount($(item).find('.vue-template-container')[0]);
    }

  },

  setValue: function(item, value) {
    item._value = value;
  },

  getValue: function(item) {
    return item._value;
  },

  destruct: function(item) {
    item._unloading = true;
    // this.d2(item);
    item = null;
  }
};

(function() {
  for (const a in { doAddLabel: 1, doAddInput: 1, setText: 1, getText: 1, isEnabled: 1, doUnloadNestedLists: 1 })
    dhtmlXForm.prototype.items.htmlContainer[a] = dhtmlXForm.prototype.items.input[a];
})();

/**
 * The HTML Container Akioma Control.
 * @class ak_htmlContainer
 * @param  {object} options The Repository Attributes
 * @param {boolean} options.CanSort Set to FALSE if this field should not be used to sort the data object query.
 * @param {number} options.WIDTH-CHARS Width in characters. This may currently be used when rendering some objects. There is no get function, use getWidth to retrieve the realized value from an object.
 * @param {string} options.align alignment of column. can be left, right or centered
 * @param {string} options.HELP WidgetAttributes Help
 * @param {number} options.ROW Row position.
 * @param {boolean} options.Mandatory WidgetAttributes Mandatory
 * @param {string} options.VisualizationType WidgetAttributes VisualizationType
 * @param {string} options.LABEL WidgetAttributes Label
 * @param {string} options.EventEntryType Language of "entry" trigger
 * @param {number} options.HEIGHT-CHARS Height in characters. This may currently be used when rendering some objects. There is no get function, use getHeight to retrieve the realized value from an object.
 * @param {boolean} options.VISIBLE WidgetAttributes Visible
 * @param {boolean} options.CHECKED
 * @param {string} options.TemplateFile The relative path and filename of the static object used as the template at design time
 * @param {string} options.InitialValue WidgetAttributes InitialValue
 * @param {string} options.EventEntry Event which should run on entry of a field/focus in field.
 * @param {string} options.LIST-ITEMS
 * @param {boolean} options.CanFilter Set to FALSE if this field should not be used to filter the data object query.
 * @param {string} options.ViewAs The 'ViewAs' definition  of the selection.&#10;- combo-box,radio-set,selection-list OR browse &#10;- Uses colon as separator to define SUB-TYPE for combo-box or &#10;horizontal/vertical radio-set,
 * @param {string} options.FolderWindowToLaunch If Dynamics is running, this property specifies a window to launch upon the occurence of toolbar events "View", "Copy", "Modify" or "Add".
 * @param {string} options.EventLeaveType Language, the event is written in.
 * @param {string} options.TableName WidgetAttributes TableName
 * @param {string} options.FORMAT WidgetAttributes Format
 * @param {string} options.LIST-ITEM-PAIRS
 * @param {string} options.EventAkValidate Client side validation code (JavaScript)
 * @param {string} options.DATA-TYPE WidgetAttributes Data-Type
 * @param {string} options.Validation Validation-Rules, e.g. ValidInteger,ValidEMail...
 * @param {string} options.FilterOperator
 * @param {boolean} options.LABELS If false then NO-LABEL is used.  This attribute applies to most field level widgets and frames
 * @param {string} options.FieldName The name of the associated SDO field this SmartDataField maps to. This is usually 'set' from the containing SmartDataViewer.
 * @param {boolean} options.ENABLED WidgetAttributes Enabled
 * @param {boolean} options.CreateField
 * @param {string} options.EventLeave Event when leaving a field
 * @param {string} options.SUBTYPE
 * @param {boolean} options.MULTIPLE
 * @param {string} options.Width A widget's width. The unit of measurement is determined by another&#10;parameter.
 * @param {number} options.COLUMN Column position. This may currently be used when rendering some objects. There is no getColumns function, use getCol to retrieve the realized value from an object.
 * @param {string} options.EventUploadComplete
 * @param {boolean} options.uploadShowTitle
 * @param {boolean} options.uploadAutoStart
 * @param {boolean} options.uploadAutoRemove
 * @param {string} options.Template
 * @param {string} options.uploadValidFileTypes
 * @param {string} options.note field description displayed below the field
 * @param {string} options.uploadURL
 * @param {string} options.uploadServerCallbackObject
 * @param {string} options.uploadTargetDir
 * @param {string} options.EventUploadFileAdd
 * @param {string} options.EventClick Client side validation code (JavaScript)
 * @param {string} options.filter
 */
$.ak_htmlContainer = class extends akioma.BaseFormDataField {
  constructor(options) {
    super(options);
    const defaults = { template: '' };
    this.opt = Object.assign({}, defaults, options.att);
    this.parent = options.parent;

    this.oData = {};
    this.registerVuexWatcher = true;
    this.registerDynObject = true;

    this.opt.id += dhtmlx.uid();

    // get parent
    const oParent = this.parent;

    if (oParent) {

      oParent.prop.fields.push({
        type: 'htmlContainer',
        inputTop: parseInt(this.opt.top),
        inputLeft: parseInt(this.opt.left),
        inputWidth: parseInt(this.opt.width),
        label: this.opt.label,
        labelTop: parseInt(this.opt.top),
        labelLeft: oParent.labelLeft(this),
        name: this.opt.name,
        position: (this.opt.positionMode != undefined) ? this.opt.positionMode : oParent.opt.positionMode,
        className: 'w4-formField',
        userdata: { id: this.opt.id }
      });

      this.form = akioma.getForm(this);

      // append to elements in form
      if (this.parent.view == 'form')
        this.parent.elements.push(this);

      Object.assign(this, { security: {} });
    }
  }

  componentOptions() {
    const oSelf = this;
    return {
      watch: {
        'getters.getCustomStates': {
          fn: function(customStates) {
            oSelf._customStatesWatcher(customStates);
          },
          params: [this.opt.id]
        }
      }
    };
  }

  /**
     * Method for getting the value of the control
     * @memberof ak_htmlContainer
     * @instance
     * @return  {any}
     */
  getValue() {
    const oForm = akioma.getForm(this);
    return oForm.getItemValue(this.opt.name);
  }

  /**
     * Method for setting value of control
     * @memberof ak_htmlContainer
     * @param   {any}  cValue
     * @instance
     * @return  {void}
     */
  setValue(cValue) {
    const oForm = akioma.getForm(this);
    oForm.dhx.setItemValue(this.opt.name, cValue);
  }
};
