

(function($) {

  // ********************* test screen: !testQuery ********************
  $.extend({
    ak_queryBuilder: function(options) {
      const oSelf = this,
        defaults = { };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;

      this.useParentDynObjectLink = true;
      this.registerDynObject = true;

      // get parent
      const oParent = this.parent;
      if (oParent) {

        const html = '<div class="header-panel">' +
                      '<div class="central-block">' +
                        '<div class="conditions-block">' +
                          '<hr class="conditions-hr hr"/>' +
                          '<div class="conditions-title"></div>' +
                          '<div class="query-panel-container">' +
                            '<div id="QueryPanel"></div>' +
                          '</div>' +
                        '</div>' +
                      '</div>' +
                    '</div>';

        // default operators
        const opTranslated = {
          eqTrans: akioma.tran('eq_operators.eq', { defaultValue: 'is equal to' }),
          neqTrans: akioma.tran('eq_operators.neq', { defaultValue: 'is not equal to' }),
          ltTrans: akioma.tran('eq_operators.lt', { defaultValue: 'is less than' }),
          lteTrans: akioma.tran('eq_operators.lte', { defaultValue: 'is less than or equal' }),
          gtTrans: akioma.tran('eq_operators.gt', { defaultValue: 'is greater than' }),
          gteTrans: akioma.tran('eq_operators.gte', { defaultValue: 'is greater than or equal' }),
          swTrans: akioma.tran('eq_operators.startswith', { defaultValue: 'starts with' }),
          contTrans: akioma.tran('eq_operators.contains', { defaultValue: 'contains' }),
          matchTrans: akioma.tran('eq_operators.matches', { defaultValue: 'matches' }),
          endsTrans: akioma.tran('eq_operators.endswith', { defaultValue: 'endswith' })
        };

        this.defOperators = [
          {
            'id': 'eq',
            'caption': opTranslated.eqTrans,
            'displayFormat': `{expr1} [[${opTranslated.eqTrans}]] {expr2}`,
            'isRange': false,
            'caseIns': false,
            'paramCount': 2,
            'defaultOperand': {
              'kind': 'Scalar',
              'dataType': 'Unknown'
            }
          },
          {
            'id': 'neq',
            'caption': opTranslated.neqTrans,
            'displayFormat': `{expr1} [[${opTranslated.neqTrans}]] {expr2}`,
            'isRange': false,
            'caseIns': false,
            'paramCount': 2,
            'defaultOperand': {
              'kind': 'Scalar',
              'dataType': 'Unknown'
            }
          },
          {
            'id': 'lt',
            'caption': opTranslated.ltTrans,
            'displayFormat': `{expr1} [[${opTranslated.ltTrans}]] {expr2}`,
            'isRange': false,
            'caseIns': false,
            'paramCount': 2,
            'defaultOperand': {
              'kind': 'Scalar',
              'dataType': 'Unknown'
            }
          },
          {
            'id': 'lte',
            'caption': opTranslated.lteTrans,
            'displayFormat': `{expr1} [[${opTranslated.lteTrans}]] {expr2}`,
            'isRange': false,
            'caseIns': false,
            'paramCount': 2,
            'defaultOperand': {
              'kind': 'Scalar',
              'dataType': 'Unknown'
            }
          },
          {
            'id': 'gt',
            'caption': opTranslated.gtTrans,
            'displayFormat': `{expr1} [[${opTranslated.gtTrans}]] {expr2}`,
            'isRange': false,
            'caseIns': false,
            'paramCount': 2,
            'defaultOperand': {
              'kind': 'Scalar',
              'dataType': 'Unknown'
            }
          },
          {
            'id': 'gte',
            'caption': opTranslated.gteTrans,
            'displayFormat': `{expr1} [[${opTranslated.gteTrans}]] {expr2}`,
            'isRange': false,
            'caseIns': false,
            'paramCount': 2,
            'defaultOperand': {
              'kind': 'Scalar',
              'dataType': 'Unknown'
            }
          },
          {
            'id': 'startswith',
            'caption': opTranslated.swTrans,
            'displayFormat': `{expr1} [[${opTranslated.swTrans}]] {expr2}`,
            'isRange': false,
            'caseIns': false,
            'paramCount': 2,
            'defaultOperand': {
              'kind': 'Scalar',
              'dataType': 'Unknown',
              'editor': {
                'id': 'TVE12',
                'type': 'EDIT',
                'resType': 'String',
                'defValue': ''
              }
            }
          },
          {
            'id': 'contains',
            'caption': opTranslated.contTrans,
            'displayFormat': `{expr1} [[${opTranslated.contTrans}]] {expr2}`,
            'isRange': false,
            'caseIns': false,
            'paramCount': 2,
            'defaultOperand': {
              'kind': 'Scalar',
              'dataType': 'Unknown',
              'editor': {
                'id': 'TVE20',
                'type': 'EDIT',
                'resType': 'String',
                'defValue': ''
              }
            }
          },
          {
            'id': 'matches',
            'caption': opTranslated.matchTrans,
            'displayFormat': `{expr1} [[${opTranslated.matchTrans}]] {expr2}`,
            'isRange': false,
            'caseIns': false,
            'paramCount': 2,
            'defaultOperand': {
              'kind': 'Scalar',
              'dataType': 'Unknown',
              'editor': {
                'id': 'TVE20',
                'type': 'EDIT',
                'resType': 'String',
                'defValue': ''
              }
            }
          },
          {
            'id': 'endswith',
            'caption': opTranslated.endsTrans,
            'displayFormat': `{expr1} [[${opTranslated.endsTrans}]] {expr2}`,
            'isRange': false,
            'caseIns': false,
            'paramCount': 2,
            'defaultOperand': {
              'kind': 'Scalar',
              'dataType': 'Unknown',
              'editor': {
                'id': 'TVE20',
                'type': 'EDIT',
                'resType': 'String',
                'defValue': ''
              }
            }
          }
        ];

        // attach in DOM elm
        if (this.opt.attachmentID != undefined)
          $(`#${this.opt.attachmentID}`).append(html);
        else if (oParent.dhx.cont != undefined)
          $(oParent.dhx.cont).find('.dhx_cell_cont_layout').append(html);
        else if (this.opt.attachmentID == undefined)
          $(oParent.dhx.cell).find('.dhx_cell_cont_layout').append(html);

        EQ.client.init({
          loadDefaultModel: false,
          loadQueryOnStart: false,
          queryPanel: {
            attrElementFormat: '{attr}',
            autoEditNewCondition: false, // this can be updated for auto focus in input, could be extended to a method call that specifies when auto focus
            showPoweredBy: false, // not working??,
            listRequestHandler: function(params, onResult) {
              const oColumnComboElements = oSelf.parent.dhx.comboValues[params.listName.replace('editor', '')];
              const aRes = [];
              for (const i in oColumnComboElements) {
                aRes.push({
                  text: oColumnComboElements[i].text,
                  id: oColumnComboElements[i].value
                });
              }
              onResult(aRes);
            }
          }
        });
        $.extend(this, { security: {} });
      }
    }
  });

  // methods for multilist
  $.ak_queryBuilder.prototype = {
    // finish construct **********
    finishConstruct: function() {
      const oSelf = this;

      // load data model from schema object, datasource
      if (oSelf.parent.dataSource.jsdo)
        oSelf.loadDataModel(oSelf.parent.dataSource.jsdo[oSelf.parent.dataSource.opt.entityName].getSchema(), oSelf.parent.dataSource.opt.entityName);

      const cLang = akioma.entry(1, akioma.translation.getLanguage(), '-');
      switch (cLang) {
        case 'de':
          if (EQ.core.texts.German)
            EQ.core.texts = EQ.core.texts.German;
          break;
        case 'es':
          if (EQ.core.texts.Spanish)
            EQ.core.texts = EQ.core.texts.Spanish;
          break;
        case 'ro':
          if (EQ.core.texts.Romanian)
            EQ.core.texts = EQ.core.texts.Romanian;
          break;
      }


      // load generated model in EasyQuery from code, refresh Entities panel if present
      if (EQ.client.controls.EPWidget)
        EQ.client.controls.EPWidget.EntitiesPanel('startLoading');


      EQ.client.defaultQuery.loadModelObject(this.model);
      EQ.client.refreshWidgets(true);

      if (EQ.client.controls.EPWidget)
        EQ.client.controls.EPWidget.EntitiesPanel('finishLoading');

    },

    // load EasyQuery Data Model from BusinessEntity Schema
    loadDataModel: function(oSchema, cModelName) {
      this.addEntity(cModelName, oSchema);

      this.modelID = dhtmlx.uid();
      const oDataModel = {
        id: this.modelID,
        name: cModelName,
        version: 1,
        operators: this.defOperators,
        rootEntity: this.rootEntity,
        aggrFunctions: []
      };
      this.model = oDataModel;
    },

    addEntity: function(cBEName, oSchema) {
      this.EntityName = cBEName;
      this.rootEntity = {
        name: '',
        caption: '',
        description: '',
        uic: true,
        uir: true,
        uis: true,
        subEntities: [
          {
            name: cBEName,
            caption: cBEName,
            description: '',
            uic: true,
            uir: true,
            uis: true,
            attributes: this.loadFieldsFromSchema(cBEName, oSchema)
          }
        ]
      };
    },

    loadFieldsFromSchema: function(cBEName, aSchema) {
      const aAttributes = [];
      for (let i = 0; i < aSchema.length; i++) {
        if (aSchema[i].name != '_errorString') {
          // set datatype of field
          const cSchemaDataType = aSchema[i].type.toLowerCase();

          let cDataType = 'String';
          if (cSchemaDataType == 'integer' || cSchemaDataType == 'number')
            cDataType = 'Int';
          else if (cSchemaDataType == 'boolean')
            cDataType = 'Boolean';


          // chech for date and datetime
          if (aSchema[i].format != undefined) {
            const cFieldFormat = aSchema[i].format.toLowerCase();
            if (cFieldFormat == 'date')
              cDataType = 'Date';
            else if (cFieldFormat == 'date-time')
              cDataType = 'DateTime';
            else if (cFieldFormat == 'time')
              cDataType = 'Time';
          }

          // build field/attribute
          let cDefOperator = 'eq';
          if (aSchema[i].ablType == 'CHARACTER')
            cDefOperator = 'startswith';


          // for testing purposes
          let aTestOperators;
          if (this.parent.dhx.getColTypeById(aSchema[i].name.toLowerCase()) == 'combo') {
            cDefOperator = 'eq';
            aTestOperators = [ 'eq', 'neq' ];
          }
          const oAttr = {
            id: `${cBEName}.${aSchema[i].name.toLowerCase()}`,
            caption: akioma.tran(`${this.parent.opt.name}.${aSchema[i].name.toLowerCase()}`, { defaultValue: aSchema[i].title }),
            //  “string”, “integer”, “number”, “boolean” to "String" "Int" "Boolean" or by format date,time,date-time?
            dataType: cDataType,
            size: 50,
            uic: true,
            uir: true,
            uis: true,
            defaultOperator: cDefOperator,
            operators: aTestOperators || this.getAllowedOperators(aSchema[i].type),
            sqlExpr: `${cBEName}.${aSchema[i].name}`

          };

          // for testing
          if (this.parent.dhx.getColTypeById(aSchema[i].name.toLowerCase()) == 'combo') {
            oAttr.defaultEditor = {
              defValue: '',
              id: `${aSchema[i].name.toLowerCase()}editor`,
              name: `${aSchema[i].name.toLowerCase()}editor`,
              resType: 'Unknown',
              type: 'CUSTOMLIST'
            };
          }

          aAttributes.push(oAttr);
        }
      }

      return aAttributes;
    },
    // updates businessEntity filter from EQ Query object
    updateAkFilter: function(oFilter) {
      oFilter.clearAll();

      const query = EQ.client.getQuery();
      const queryFilters = query.query.root;

      const aConditions = queryFilters.conditions;

      // set filter main operator
      let cLinkType = queryFilters.linkType.toLowerCase();
      if (this.opt.mainOperator)

        oFilter.mainOperator = this.opt.mainOperator;


      if (cLinkType == 'all') {
        EQ.client.defaultQuery.query.root.linkType = 'All';

        oFilter.mainOperator = 'and';
      } else {
        EQ.client.defaultQuery.query.root.linkType = 'Any';
        oFilter.mainOperator = 'or';
      }


      // load conditions in filter
      for (let i = 0; i < aConditions.length; i++) {
        // check first for nested conditions
        if (aConditions[i].conditions != undefined) {
          // for each nested condition, add as nested condition in akQuery filter
          for (let j = 0; j < aConditions[i].conditions.length; j++) {
            let cFieldName, cOperator, cValue;

            const oCurrentCondition = aConditions[i].conditions[j];
            // add as simple new condition
            if (oCurrentCondition.enabled) {
              cFieldName = oCurrentCondition.expressions[0].id.split('.')[1];
              cOperator = oCurrentCondition.operatorID;
              cValue = oCurrentCondition.expressions[1].value;
            }
            if (j == 0) {
              cLinkType = aConditions[i].linkType.toLowerCase();
              let cSubOperator = 'or';
              if (cLinkType == 'all')
                cSubOperator = 'and';

              oFilter.setSubOperator(cFieldName, cSubOperator);
              oFilter.addCondition(cFieldName, cOperator, cValue);
            } else
              oFilter.addCondition(cFieldName, cOperator, cValue, true);
          }
        } else {
          const oCurrentCondition = aConditions[i];
          // add as simple new condition
          if (oCurrentCondition.enabled) {
            const cFieldName = oCurrentCondition.expressions[0].id.split('.')[1];
            const cOperator = oCurrentCondition.operatorID;
            const cValue = oCurrentCondition.expressions[1].value;
            // now add condition from EQ Query
            oFilter.addCondition(cFieldName, cOperator, cValue);
          }
        }
      }

      // load generated model in EasyQuery from code, refresh Entities panel if present
      if (EQ.client.controls.EPWidget)
        EQ.client.controls.EPWidget.EntitiesPanel('startLoading');


      EQ.client.defaultQuery.loadModelObject(this.model);
      EQ.client.refreshWidgets(true);

      if (EQ.client.controls.EPWidget)
        EQ.client.controls.EPWidget.EntitiesPanel('finishLoading');

    },

    getAllowedOperators: function() {
      const aAllowOperators = [
        'eq',
        'neq',
        'lt',
        'lte',
        'gt',
        'gte',
        'startswith',
        'matches',
        'endswith',
        'contains' // only for strings
      ];
      return aAllowOperators;
    },

    // load EasyQuery filters/conditions from akQuery
    // {"SDO":"dbom","ui_context":{"controlName":"bomb","controlType":"","container":""},"filters":{"field":"selfno","operator":"startswith","value":"ax"}}
    loadQuery: function(oFilterObj) {
      this.queryID = dhtmlx.uid();
      const oQueryDef = {
        'id': this.queryID,
        'name': '',
        'description': '',
        'modelId': this.modelID,
        'root': {
          'linkType': 'Any',
          'conditions': []
        },
        'columns': [],
        'justsorted': [],
        'formats': {
          'AlphaAlias': 'False',
          'DateFormat': 'yyyy-MM-dd',
          'DateTimeFormat': 'yyyy-MM-dd HH:mm',
          'FalseValue': '0',
          'FilterMode': 'False',
          'LowerFuncName': 'LOWER',
          'OrderByStyle': 'Aliases',
          'QuoteBool': 'False',
          'QuoteColumnAlias': 'False',
          'QuoteTime': 'True',
          'QuotedTypes': 'String, FixedChar, Memo, Date, Time, DateTime',
          'TimeFormat': 'HH:mm',
          'TrueValue': '1',
          'UseColumnAliases': 'IfNecessary',
          'BracketJoins': 'True',
          'DefaultSchemaName': '',
          'GroupByCalcColumns': 'True',
          'SqlQuote1': '"',
          'SqlQuote2': '"',
          'SqlSyntax': 'SQL2',
          'SupportPaging': 'False',
          'UseAsInFrom': 'True',
          'UseDbName': 'False',
          'UseEntityContainerName': 'False',
          'UseSchema': 'True'
        }
      };

      EQ.client.defaultQuery.setObject(oQueryDef, true);
      EQ.client.refreshWidgets();

      // add a condition into existing query
      // now read oFilterObj and add expressions
      // query.addSimpleCondition({ attr: "Customers.CompanyName", operator: "StartsWith", value: "A" });
      const query = EQ.client.getQuery();
      // only one condition
      if (oFilterObj.filters.length == undefined)
        query.addSimpleCondition({ attr: `${this.EntityName}.${oFilterObj.filters.field}`, operator: oFilterObj.filters.operator, value: oFilterObj.filters.value });
      else if (oFilterObj.filters.length >= 2) {
        for (let j = 0; j < oFilterObj.filters.length; j++)
          query.addSimpleCondition({ attr: `${this.EntityName}.${oFilterObj.filters[j].field}`, operator: oFilterObj.filters[j].operator, value: oFilterObj.filters[j].value });

      }
    }
  };

})(jQuery, jQuery);
