dhtmlXForm.prototype.items.button.getInput = function(item) {
  return item.childNodes[0];
};

// ******* Button *******
$.extend({
  /**
      * SwatButton Control
      * @class ak_button
      * @param {Object} options Repository attributes for SwatButton.
      * @param {boolean} options.ENABLED WidgetAttributes Enabled
      * @param {number} options.COLUMN Column position. This may currently be used when rendering some objects. There is no getColumns function, use getCol to retrieve the realized value from an object.
      * @param {string} options.HELP WidgetAttributes Help
      * @param {number} options.WIDTH-CHARS Width in characters. This may currently be used when rendering some objects. There is no get function, use getWidth to retrieve the realized value from an object.
      * @param {number} options.ROW Row position.
      * @param {string} options.LABEL WidgetAttributes Label
      * @param {string} options.EventClick Client side validation code (JavaScript)
      */
  ak_button: function(options) {

    akioma.BaseFormDataField.call(this, options);

    const defaults = {};

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;
    this.view = options.view;

    this.registerDynObject = true;
    this.registerVuexWatcher = true;
    this.useParentDynObjectLink = true;

    // get parent
    const oParent = this.parent;

    if (oParent) {
      if (!this.opt.customStyle)
        this.opt.customStyle = this.view;

      const oForm = akioma.getForm(this);

      let logginBtn;
      if (app.sessionData.allowedAuthentication) {
        const authentication = app.sessionData.allowedAuthentication.split(',')[0];
        logginBtn = '';
        switch (authentication) {
          case 'AkiomaUser':
            logginBtn = 'login';
            break;
          case 'ActiveDirectory':
            logginBtn = 'login_ad';
            break;
          case 'AzureActiveDirectory':
            logginBtn = 'login_azure_ad';
            break;
          case 'AppDirect':
            logginBtn = 'login_appdirect';
            break;
          default:
            break;
        }
      }

      if (logginBtn === this.opt.InstanceName)
        this.opt.defaultGo = true;


      // check for defaultGo/defaultCancel attributes
      if (this.opt.defaultGo)
        oForm.defaultGoBtn = this;
      else if (this.opt.defaultCancel)
        oForm.defaultCancelBtn = this;

      this.opt.label = akioma.tran(`${akioma.getForm(this).opt.name}.${this.opt.name}`, { defaultValue: this.opt.label });

      // button form field options here
      const oOptions = {
        type: 'button',
        position: (this.opt.positionMode != undefined) ? this.opt.positionMode : oParent.opt.positionMode,
        width: parseInt(this.opt.width),
        name: this.opt.name,
        disabled: this.opt.disabled,
        className: 'w4-formField w4-inputField',
        value: (this.opt.label) ? this.opt.label : '',
        enabled: this.opt.ENABLED,
        userdata: { id: this.opt.id }
      };
        // reset top, left pos if not absolute to zero
      if (this.opt.positionMode != undefined && this.opt.positionMode == 'absolute') {
        oOptions.inputTop = parseInt(this.opt.top);
        oOptions.inputLeft = parseInt(this.opt.left);
      }

      oParent.prop.fields.push(oOptions);


      // append to elements in form
      if (this.parent.view == 'form')
        this.parent.elements.push(this);

    }
  }
});


// methods for form
Object.assign($.ak_button.prototype, akioma.BaseFormDataField.prototype, {
  componentOptions: function() {
    const oSelf = this;
    return {
      watch: {
        'getters.getFormFieldEnabled': {
          fn: function(bEnabled) {
            oSelf._enabledFormFieldWatcher(bEnabled);
          },
          params: [this.opt.id]
        },
        'getters.getCustomStates': {
          fn: function(customStates) {
            oSelf._customStatesWatcher(customStates);
          },
          params: [this.opt.id]
        }
      }
    };
  },
  _enabledFormFieldWatcher: function(bEnabled) {
    const oForm = akioma.getForm(this);
    const cName = this.opt.name;

    if (oForm.dhx) {
      if (bEnabled)
        oForm.dhx.enableItem(cName);
      else
        oForm.dhx.disableItem(cName);

    }
  },
  // finish construct **********
  finishConstruct: function() {
    // get field
    const parentForm = akioma.getForm(this);
    const formAkId = parentForm.akId;
    this.form = parentForm.dhx;
    const buttonClass = akioma.getDhxFormElement(this.form, this.opt.name);
    const buttonFirstChild = $(buttonClass.children[0]);
    buttonFirstChild.attr('akId', `${formAkId}-${this.opt.name}`); // set akId in button
    buttonFirstChild.attr('akStyle', this.opt.customStyle); // set akStyle in button

    if (akioma.getAkIdMode() == 'extended')
      $(buttonFirstChild).children().eq(1).attr('akId', `${formAkId}-${this.opt.name}-${this.view}`);
    this.dhx = buttonClass; // set dhx in button
    this.$domElement = $(buttonClass);
    this.domTag = this.$domElement.children()[0];
    this._setTabIndex(this.domTag);
    this.setResponsiveSizes();
  },
  click: function() {
    this.form.clickButton(this.opt.name);
  },
  // set input bg color
  setBackgroundColor: function(cColor) {
    try {
      const oInp = this.form.getInput(this.opt.name);
      $(oInp).css('background', cColor);
    } catch (e) {
      akioma.log.error('Error, could not set button bg color', e);
    }
  },

  endConstruct: function() {
    if (this.opt.visible == false)
      this.form.hideItem (this.opt.name);
  },

  // get value *****************
  getValue: function() {
    return this.form.getItemValue(this.opt.name);
  },

  // set value ********************
  setValue: function(cValue) {
    this.form.setItemValue(this.opt.name, cValue);
  },
  setLabel: function(cValue) {
    this.form.setItemLabel(this.opt.name, cValue);
  },

  // event leave *******************
  eventClick: function() {
    // check if we have to call the create event
    if (this.opt.eventClick)
      app.controller.callAkiomaCode(this, this.opt.eventClick);
  }
});
