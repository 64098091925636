akioma.setPanelHeader = function(self) {
  function setTitle_Menu(panel, accItem) {
    // set title and panelMenu buttons in accordion
    if (accItem) {
      if (self.opt.title)
        accItem.setOption('title', self.opt.title);
      if (self.opt.panelMenu)
        accItem.setOption('panelMenu', self.opt.panelMenu, self);
    } else { // set title and panelMenu buttons in panel
      if (self.opt.hideHeader)
        panel.setOption('hideHeader', self.opt.hideHeader);

      let layoutSettings;
      if (self.dynObject)
        layoutSettings = self.dynObject.readUserProfileSettings();

      if (Number(self.opt.height) > 0 && !layoutSettings)
        panel.setOption('height', self.opt.height);

      if (self.opt.title && self.parent.opt.hideHeader != true && panel.setOption !== undefined)
        panel.setOption('title', self.opt.title);

      if (self.opt.panelMenu && panel.setOption)
        panel.setOption('panelMenu', self.opt.panelMenu, self);

    }
  }

  if (akioma.getSessionProperty('objectNamesInTitles')) {
    let panelMenus = [],
      addDevTools = true;

    const objectName = self.opt.SubObjectName || self.opt.ObjectMasterName || self.opt.name;
    if (akioma.DevTools.devToolObjectsToSkip.includes(objectName))
      addDevTools = false;

    if (addDevTools) {
      let oParent = self.parent;
      while (oParent != undefined) {

        const parentName = oParent.opt.SubObjectName || oParent.opt.ObjectMasterName || oParent.opt.name;
        if (akioma.DevTools.devToolObjectsToSkip.includes(parentName)) {
          addDevTools = false;
          break;
        }

        oParent = oParent.parent;
      }
    }

    if (addDevTools) {
      if (self.opt.panelMenu)
        panelMenus = self.opt.panelMenu.split(',');
      panelMenus.push('SwatDevTools');
      self.opt.panelMenu = panelMenus.join(',');
    }
  }

  if (self.view == 'form' && self.parent.view == 'frame')
    return true;
  if (self.opt.titleHeader) {
    switch (self.opt.titleHeader) {

      case 'none':
        self.parent.dhx.hideHeader();
        break;
      case 'parent': {
        self.parent.dhx.showHeader();
        const accordionAncestor = self.getAncestor('accordionItem');
        const panelAncestor = self.parent.parent.getAncestor('panel');
        if (accordionAncestor)
          setTitle_Menu(panelAncestor, accordionAncestor);
        else if (panelAncestor)
          setTitle_Menu(panelAncestor);
        break;
      }
      case 'parent-only': {
        self.parent.dhx.hideHeader();
        const accordionAncestor = self.getAncestor('accordionItem');
        const panelAncestor = self.parent.parent.getAncestor('panel');
        if (accordionAncestor)
          setTitle_Menu(panelAncestor, accordionAncestor);
        else if (panelAncestor)
          setTitle_Menu(panelAncestor);
        break;
      }
    }
  } else
    setTitle_Menu(self.parent);
};

/**
 * Creates the object representation for a panel header menu icon
 * @param  {Menu} oMenu The SCL Menu item
 * @return {object}   The icon as on object representation
 */
akioma.getPanelHeaderMenuIcon = function(oMenu, visualisationType) {
  let menuStyle = oMenu.style;
  let menuImage = oMenu.icon;

  if (!menuStyle)
    menuStyle = 'CONTEXTMENU';
  if (!menuImage)
    menuImage = 'imgs/akioma/menu_popup.png';
  if (visualisationType == 'CHECKBOX' || visualisationType == 'CHECK-BOX')
    menuImage = 'far fa-square';

  let btnConf_popover;
  if (menuImage) {
    const iconObject = akioma.icons.getIconType(menuImage);
    if (iconObject.type == 'normal-icon')
      btnConf_popover = { className: 'class-headerPanel-btn', width: 16, height: 16, backgroundImg: `url(${menuImage})`, label: oMenu.label };
    else {
      let cTemplate = '<span class="fa-stack #stackedIcon#"><i class="#icon1# #size# fa-fw #moduleClass1#" style="#iconStyle1#"></i><i class="#icon2# fa-stack-1x fa-inverse #moduleClass2#" style="#iconStyle2#"></i></span>';
      cTemplate = akioma.icons.replaceTemplate(cTemplate, iconObject);
      btnConf_popover = { className: 'class-headerPanelFontIcon-btn', width: 16, height: 16, content: cTemplate, label: oMenu.label };
    }
    // buttonmenuimg

  }
  return btnConf_popover;
};

/**
 * Function for creating a new panel header menu from a menustructure
 * @param  {object} repoObject [description]
 * @param  {object} oTarget    [description]
 * @param  {object} oPanel     [description]
 */
akioma.createPanelMenu = function(repoObject, oTarget, oPanel) {
  const oSelf = oPanel;
  const oHeader = oPanel.dhx;
  const menuStructures = repoObject.split(',');
  const aMenuStructures = [];
  const aMainMenus = [];
  const aMenuPromises = [];
  const aButtonPromises = [];
  const aDropdownSettings = [];
  oPanel.aPanelMenuButtons = [];

  const sizeContainer = { size: 40 };

  // go over each menustructure and load it
  menuStructures.forEach(structure => {

    // default dropdownmode
    let bDropDownMode = true;
    if (structure.indexOf('#NoDropDown') !== -1) {
      structure = structure.replace('#NoDropDown', '');
      bDropDownMode = false;
    }

    if (structure == 'gridFilterDynSelect' && oTarget.FilterManager)
      oTarget.FilterManager.loadFilters();

    if (structure == 'filterListBT') {
      const oFilterItem = { icon: 'fa fa-filter' };
      const btnConf_popover = akioma.getPanelHeaderMenuIcon(oFilterItem);
      btnConf_popover.label = 'Filters';
      const popover = oHeader.attachHeaderButton(btnConf_popover, '', undefined, `${sizeContainer.size}px`, oPanel);
      oSelf.createContextMenuButton(popover, { id: structure }, oTarget);
    } else {
      const mainMenu = app.controller.parseProc({
        view: 'menustructure',
        att: { id: structure },
        sub: []
      }, oTarget);
      const oPromise = mainMenu.loadMenuElements(null, bDropDownMode);
      aMenuStructures.push(structure);
      aMainMenus.push(mainMenu);
      aMenuPromises.push(oPromise);
      aDropdownSettings.push(bDropDownMode);
    }
  });

  // wait until all menus are loaded and then call finishLoading callback
  $.when.apply(null, aMenuPromises).then(function() {
    for (const i in arguments)
      akioma.onFinishLoadingPanelMenuItems(arguments[i], aDropdownSettings[i], aMainMenus[i], aMenuStructures[i], aButtonPromises, oPanel, oTarget, repoObject, sizeContainer);

    // Visibility rules on buttons can only be applied after all the buttons have been loaded
    Promise.all(aButtonPromises).then(() => {
      for (const button of oPanel.aPanelMenuButtons) {
        if (button.visibilityRules)
          VisibilityRules.checkPanelMenuVisibilityRules(button.menu, button);
      }
      if (oTarget.FilterManager)
        oTarget.FilterManager.panelMenuLoaded.resolve();
    }).catch(error => {
      console.error(error.message);
    });
  });
};

/**
 * Method executed after loading panel menu items
 * @param {array} items Menu items
 * @param {boolean} bUseDropDown If it is using drop down
 * @param {ak_menustructure} mainMenu Menu structure
 * @param {string} structure Menu name
 * @param {array} aButtonPromises Array of button promises
 * @param {ak_panel} oPanel The panel container of the menu item
 * @param {ak_object} oTarget The target control
 * @param {object} repoObject The repository object
 * @param {object} sizeContainer The item size
 */
akioma.onFinishLoadingPanelMenuItems = function(items, bUseDropDown, mainMenu, structure, aButtonPromises, oPanel, oTarget, repoObject, sizeContainer) {
  const oMenu = mainMenu.getMenuStructure();
  const oHeader = oPanel.dhx;

  if (!oMenu)
    return;

  let menuStyle = oMenu.style;
  let menuImage = oMenu.icon;
  if (bUseDropDown == undefined)
    bUseDropDown = true;

  let visualizationType = '';
  for (const item in items) {
    if (mainMenu.aMenuActions[item].visualisationType == 'CHECK-BOX' || mainMenu.aMenuActions[item].visualisationType == 'CHECKBOX') {
      const filterHelper = JSON.parse(mainMenu.aMenuActions[item].actionOptions);

      const panelFilterId = mainMenu.aMenuStructure.code || mainMenu.aMenuStructure.name;

      for (const filter in filterHelper.FilterExtension) {
        const { field, operator, value } = filterHelper.FilterExtension[filter];

        oTarget.FilterManager.setPanelFilter(panelFilterId, field, { field, operator, value });
      }
      visualizationType = 'CHECK-BOX';
    }
  }

  let btnConf_popover = akioma.getPanelHeaderMenuIcon(oMenu, visualizationType);

  if (!bUseDropDown) {
    for (const x in items) {
      const buttonPromise = $.Deferred();
      aButtonPromises.push(buttonPromise);
      buttonPromise.promise();

      const item = items[x];
      btnConf_popover = akioma.getPanelHeaderMenuIcon(item, visualizationType);
      const menuId = oMenu.code || oMenu.name;
      const btn = oHeader.attachHeaderButton(btnConf_popover, '', null, `${sizeContainer.size}px`, oPanel, false, { menuId, parentName: oMenu.label, tooltip: item.tooltip, visualizationType: visualizationType });
      item.dhx = btn;
      item.menu = mainMenu;

      if (!isNull(item.visibilityRules)) {
        item.opt = {};
        item.opt.id = item.code;
        item.opt.visibilityRules = item.visibilityRules;
      }

      oPanel.aPanelMenuButtons.push(item);

      buttonPromise.resolve(arguments);

      if (akioma.getAkIdMode() == 'extended') {
        $(btn).attr('akId', `${oTarget.opt.name}-${item.code}`);
        $(btn).find('i:first-child').attr('akId', `${oTarget.opt.name}-${item.code}-icon`);
      }

      const attachClickEvent = (item, mainMenu) => {
        $(btn).on('click', () => {
          mainMenu.applyAction(item.id, oTarget);
          return false;
        });
      };
      attachClickEvent(item, mainMenu);
    }
  } else {
    let popover = null;

    if (!menuStyle)
      menuStyle = 'CONTEXTMENU';
    if (!menuImage)
      menuImage = 'imgs/akioma/menu_popup.png';
    if (mainMenu.id == 'gridFilterDynSelect')
      menuStyle = 'DYNSELECT';

    switch (menuStyle) {
      case 'CONTEXTMENU':
        popover = oHeader.attachHeaderButton(btnConf_popover, '', undefined, `${sizeContainer.size}px`, oPanel);
        oPanel.createContextMenuButton(popover, mainMenu, oTarget);
        break;
      case 'RIBBON': {
        const btnStruct_popover_Ribbon = {
          repoObject: structure,
          displayType: 'RIBBON'
        };
        popover = oHeader.attachHeaderButton(btnConf_popover, '', btnStruct_popover_Ribbon, `${sizeContainer.size}px`, oPanel);
        break;
      }
      case 'LAYOUT': {
        const btnStruct_popover_Layout = {
          repoObject: repoObject,
          displayType: 'LAYOUT'
        };
        popover = oHeader.attachHeaderButton(btnConf_popover, '', btnStruct_popover_Layout, `${sizeContainer.size}px`, oPanel);
        break;
      }
      case 'DYNSELECT':
        oHeader.attachHeaderDynSelect(`${sizeContainer.size}px`, oPanel, oTarget);
        sizeContainer.size += 130;
        break;
    }
    sizeContainer.size += 30;

    if (!isNull(popover)) {
      const menu = mainMenu.aMenuStructure;
      if (!isNull(menu.visibilityRules)) {
        menu.opt = {};
        menu.opt.id = menu.code;
        menu.opt.visibilityRules = menu.visibilityRules;
        menu.menu = mainMenu;
      }
      menu.dhx = popover.dhx;
      oPanel.aPanelMenuButtons.push(menu);
    }
  }
};
