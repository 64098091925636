// getScript("dhtmlx/dhtmlxGrid/codebase/ext/dhtmlxgrid_export.js");
dhtmlXGridObject.prototype._showHContext = function(menu, columns) {
  if (typeof menu == 'boolean')
    return true;

  if (typeof columns == 'string')
    columns = columns.split(this.delim);

  let true_ind = 0;
  let j = 0;
  menu.clearAll();

  for (let i = 0; i < this.hdr.rows[1].cells.length; i++) {
    const c = this.hdr.rows[1].cells[i];
    if (!columns || (columns[i] && columns[i] != 'false')) {
      let val;
      if (c.firstChild && c.firstChild.tagName == 'DIV') val = c.firstChild.innerHTML;
      else val = c.innerHTML;
      val = val.replace(/<[^>]*>/gi, '');
      const visible = !(this.isColumnHidden(i) || (this.getColWidth(i) == 0));
      menu.addCheckbox('child', menu.topId, j, true_ind, val, visible);
      j++;
    }
    true_ind += (c.colSpan || 1);
  }
};

// TODO - move handlebars template in handlebarsTemplates.js
Handlebars.registerHelper('ifSecondOrMore', function(index, options) {
  if (index >= 1)
    return options.fn(this);
  else
    return options.inverse(this);

});

// Extend original javascript Menu context menu render method to allow hidden attribute for items
const originalMenurenderSublevelItem = dhtmlXMenuObject.prototype._renderSublevelItem;
dhtmlXMenuObject.prototype._renderSublevelItem = function(id, pos) {
  originalMenurenderSublevelItem.call(this, id, pos);

  if (this.itemPull[id].hidden) {
    const itemId = id.replace(this.idPrefix, '');
    this.hideItem(itemId);
  }
};

// ********* Numeric editable cells in grid ***************
function eXcell_edn(cell) {
  if (cell) {
    this.cell = cell;
    this.grid = this.cell.parentNode.grid;
    const oGrid = this.grid.akElm,
      iCellIndex = oGrid._initColIds.indexOf(oGrid.dhx.getColumnId(this.cell.cellIndex)),
      oSelfCol = oGrid.childs[iCellIndex];

    // Allow only digits in a numeric field
    akioma.numeric.preventInput(oSelfCol, this.cell);
  }
  this.getValue = function() {
    // this.grid.editStop();
    if ((this.cell.firstChild) && (this.cell.firstChild.tagName == 'TEXTAREA'))
      return this.cell.firstChild.value;

    if (this.cell._orig_value == undefined)
      return null;

    if (this.cell._clearCell)
      return 0;

    return this.cell._orig_value || this.grid._aplNFb(this.cell.innerHTML.toString()._dhx_trim(), this.cell._cellIndex);
  };

  this.detach = function() {
    const tv = this.obj.value;
    this.setValue(tv);
    return this.val != this.getValue();
  };
}
eXcell_edn.prototype = new eXcell_ed;
eXcell_edn.prototype.setValue = function(val) {
  if (!val || val.toString()._dhx_trim() == '') {
    const iIndex = this.grid.akElm._initColIds.indexOf(this.grid.getColumnId(this.cell.cellIndex));
    const oSelfCol = this.grid.akElm.childs[iIndex];
    this.cell._clearCell = true;
    if (val === '' && this.cell._orig_value === undefined)
      this.cell._orig_value = undefined;
    else
      this.cell._orig_value = (val === 0 || val === '') ? 0 : undefined;
    return (oSelfCol.opt.showZeroAsEmpty || this.cell._orig_value === undefined) ? this.setCValue('&nbsp;', 0) : this.setCValue(0, '&nbsp;');
  } else {
    this.cell._clearCell = false;
    this.cell._orig_value = val;
  }
  this.setCValue(this.grid._aplNF(val, this.cell._cellIndex), val);
};

// ********* Numeric readonly cells in grid ***************
function eXcell_ron(cell) {
  this.cell = cell;
  this.grid = this.cell.parentNode.grid;
  this.edit = function() {
  };

  this.isDisabled = function() {
    return true;
  };
  this.getValue = function() {
    if (this.cell._orig_value == undefined)
      return null;
    return this.cell._clearCell ? 0 : this.cell._orig_value || this.grid._aplNFb(this.cell.innerHTML.toString()._dhx_trim(), this.cell._cellIndex).toString();
  };
}
eXcell_ron.prototype = new eXcell;
eXcell_ron.prototype.setValue = function(val) {
  if (!val || val.toString()._dhx_trim() == '') {
    const iIndex = this.grid.akElm._initColIds.indexOf(this.grid.getColumnId(this.cell.cellIndex));
    const oSelfCol = this.grid.akElm.childs[iIndex];
    this.cell._clearCell = true;
    this.cell._orig_value = (val === 0 || val === '') ? 0 : undefined;
    return (oSelfCol.opt.showZeroAsEmpty || this.cell._orig_value === undefined) ? this.setCValue('&nbsp;', 0) : this.setCValue(0, '&nbsp;');
  } else {
    this.cell._clearCell = false;
    this.cell._orig_value = val;
  }
  this.setCValue(this.grid._aplNF(val, this.cell._cellIndex), val);
};

// ********* handlebars template in grid ***************
// Allows custom content in a Grid cell using Handlebars template
function eXcell_handlebars(cell) {
  this.cell = cell;
  this.grid = this.cell.parentNode.grid;
  this.cell.className = 'excell_handlebars';


  this.getValue = function() {
    return this.cell.value;
  };

  this.setValue = function() {
    const oGrid = this.grid.akElm,
      iCellIndex = oGrid._initColIds.indexOf(oGrid.dhx.getColumnId(this.cell.cellIndex)),
      oCell = oGrid.childs[iCellIndex],
      oData = (oGrid.view == 'treegrid') ? oGrid.businessEntity.dhx.item(this.cell.parentNode._attrs.idd) : oGrid.dataSource.dhx.item(this.cell.parentNode.idd);

    // pass in template name and record data
    this.compileTemplate(oData, oCell);
  };

  this.compileTemplate = function(oData, oCell) {
    const self = this;

    if (oCell.templateResponse) {
      const template = Handlebars.compile(oCell.templateResponse);
      if (!$.isEmptyObject(oData)) {
        const result = template(oData);
        self.cell.innerHTML = result;
        self.cell.value = result;
        self.setCValue(result);
      }
    }
  };
}
eXcell_handlebars.prototype = new eXcell;


// function for showing ro
function eXcell_ro(cell) {
  if (cell) {
    this.cell = cell;
    this.grid = this.cell.parentNode.grid;
  }

  this.edit = function() {};
  this.isDisabled = function() {
    return true;
  };
  this.setValue = function(val) {
    $(this.cell).css({ color: '#666666' });
    if ((typeof (val) != 'number') && (!val || val.toString()._dhx_trim() == '')) {
      this.cell._clearCell = true;
      this.cell._orig_value = (val !== null) ? '&nbsp;' : undefined;
    } else {
      this.cell._clearCell = false;
      this.cell._orig_value = val;
    }

    this.setCValue(val);
  };
  this.getValue = function() {
    const iIndex = this.grid.akElm._initColIds.indexOf(this.grid.getColumnId(this.cell.cellIndex));
    const oCol = this.grid.akElm.childs[iIndex];
    if (oCol.opt.filter == '#dynselect_filter')
      return this.cell.value;

    if (this.cell._orig_value == undefined)
      return null;
    return this.cell._clearCell ? '' : akioma.decodeHTML(this.cell.innerHTML.toString()._dhx_trim());
  };
}
eXcell_ro.prototype = new eXcell;


// ************** combo in grid *****************
delete eXcell_combo.prototype.initCombo;
eXcell_combo.prototype.initCombo = function(index) {
  const container = document.createElement('DIV'),
    oGrid = this.grid._fake ? this.grid._fake : this.grid;

  container.className = 'dhxcombo_in_grid_parent';

  let combo;
  if (index) {
    combo = new dhtmlXCombo({
      parent: container,
      name: $.Guid.New(),
      width: 0,
      items: oGrid.comboValues[oGrid.getColumnId(index)],
      readonly: true
    });
    this.grid.defVal[arguments.length ? index : this.cell._cellIndex] = '';
  } else {
    combo = new dhtmlXCombo({
      parent: container,
      name: $.Guid.New(),
      width: 0,
      readonly: true
    });
  }

  combo.DOMelem.className += ' fake_editable';
  const grid = this.grid;
  combo.DOMelem.onselectstart = function() {
    return event.cancelBubble = true;
  };

  combo.attachEvent('onKeyPressed', ev => {
    if (ev == 13 || ev == 27) {
      grid.editStop();
      grid._fake && grid._fake.editStop();
    }
  });
  dhtmlxEvent(combo.DOMlist, 'click', () => {
    grid.editStop();
    grid._fake && grid._fake.editStop();
  });
  combo.DOMelem.style.border = '0px';
  combo.DOMelem.style.height = '18px';

  return combo;
};


//* **** Link **************
function eXcell_aklink(cell) {
  if (cell) {
    this.cell = cell;
    this.grid = this.cell.parentNode.grid;
  }
  this.isDisabled = function() {
    return true;
  };
  this.edit = function() {};
  this.getValue = function() {
    if (this.cell.firstChild.getAttribute)
      return akioma.decodeHTML(this.cell.firstChild.innerHTML); // +"^"+this.cell.firstChild.getAttribute("href")
    else
      return '';
  };
  this.setValue = function(val) {
    if ((typeof (val) != 'number') && (!val || val.toString()._dhx_trim() == '')) {
      this.setCValue('&nbsp;', null);
      return (this.cell._clearCell = true);
    }

    // Decoding is required before encoding because whenever any cell is updated, Dhtmlx calls setValue for all the other cells in the row.
    val = akioma.decodeHTML(val);
    val = akioma.encodeHTML(val);

    const valsAr = val.toString().split('^'),
      oGrid = this.grid.akElm,
      iCellIndex = oGrid._initColIds.indexOf(oGrid.dhx.getColumnId(this.cell.cellIndex)),
      oCell = oGrid.childs[iCellIndex];

    let cLinkTarget = '',
      cClick, cLink;
    if (oCell.opt.subType == 'HREF') {
      if (oCell.opt.keyField && oCell.opt.keyField != '') {
        cLinkTarget = oGrid.dataSource.dhx.item(this.cell.parentNode.idd)[oCell.opt.keyField];
        cLink = cLinkTarget;
      } else
        cLink = valsAr[0];
    } else {
      switch (oCell.opt.subType) {
        case 'LAUNCH':
          cLink = `javascript: app.grid.cellCall( event, "${oGrid.opt.id}", "${this.cell.parentNode.idd}", ${iCellIndex} );`;
          break;
        case 'RUN':
          cLink = `javascript: app.grid.cellRun( "${oGrid.opt.id}", "${this.cell.parentNode.idd}", ${iCellIndex} );`;
          break;
        default:
          cLink = `${(valsAr.length == 1) ? valsAr[0] : valsAr[1]};`;
          break;
      }

      if (_isIE)
        cClick = `${cLink} return false;`;
      else if (_isFF)
        cClick = `${cLink} return false;`;
      else
        cClick = `${cLink} return false;`;
      cLink = '';
    }

    let cValue = '';
    if (cLinkTarget == '')
      cValue = `<a class='akcelllink' href='${cLink}' onclick='${cClick}' target='${(valsAr.length == 2) ? valsAr[1] : '_blank'}'>${valsAr[0]}</a>`;
    else
      cValue = `<a class='akcelllink' href='${cLink}' onclick='${cClick}' target='${cLinkTarget}'>${valsAr[0]}</a>`;

    this.setCValue(cValue, valsAr);
  };
}

eXcell_aklink.prototype = new eXcell;
eXcell_aklink.prototype.getTitle = function() {
  const z = this.cell.firstChild;
  return ((z && z.tagName) ? z.getAttribute('href') : '');
};
eXcell_aklink.prototype.getContent = function() {
  const z = this.cell.firstChild;
  return ((z && z.tagName) ? z.innerHTML : '');
};

// ********* lookup in grid ***************
function eXcell_lookup(cell) {
  this.cell = cell;
  this.grid = this.cell.parentNode.grid;

  this.getValue = function() {
    return this.cell.value;
  };

  this.setValue = function(val) {
    this.cell.value = val;
    this.cell.key = this.grid.akElm.getLookupKey(this.cell.parentNode.idd, this.cell._cellIndex);
    this.setCValue(this.cell.key);
  };

  this.edit = function() {
    const cVal = this.cell.key,
      oSelf = this;

    this.val = cVal;

    this.obj = document.createElement('INPUT');
    this.obj.style.width = `${this.cell.offsetWidth - 22}px`;
    this.obj.style.height = `${this.cell.offsetHeight - 4}px`;
    this.obj.style.border = '0px';
    this.obj.style.margin = '0px';
    this.obj.style.padding = '0px';
    this.obj.style.overflow = 'hidden';
    this.obj.wrap = 'soft';
    this.obj.style.textAlign = this.cell.align;
    this.obj.onclick = function(e) {
      (e || event).cancelBubble = true;
    };
    this.cell.innerHTML = '';
    this.cell.appendChild(this.obj);

    this.obj.onselectstart = function(e) {
      if (!e)
        e = event;
      e.cancelBubble = true;
      return true;
    };
    this.obj.value = cVal;
    this.obj.focus();
    this.obj.focus();

    this.cell.appendChild(document.createTextNode(' ')); // Create space between text box and button

    // create button
    const butElem = document.createElement('input'); // This is the button DOM code
    if (_isIE) {
      butElem.style.height = `${this.cell.offsetHeight - (this.grid.multiLine ? 5 : 4)}px`;
      butElem.style.lineHeight = '5px';
    } else {
      butElem.style.fontSize = '8px';
      butElem.style.width = '14px';
      butElem.style.marginTop = '-5px';
    }
    butElem.style.backgroundImage = 'url(/imgs/akioma/zoom_in-26.png)';
    butElem.style.backgroundPosition = '-2px -1px';

    butElem.type = 'button';
    butElem.name = 'Lookup';

    butElem.onclick = function(e) {
      (e || event).cancelBubble = true;
      dhtmlx.delay(function(iCol) {
        this.grid.akElm.startLookup(iCol, 'col');
      }, oSelf, [oSelf.cell._cellIndex]);
    };
    this.cell.appendChild(butElem);
  };

  this.detach = function() {
    const cKey = this.obj.value,
      cOldHdl = this.cell.value;

    this.setValue(this.grid.akElm.filterLookup(this.cell._cellIndex, cKey, true));

    return this.cell.value != cOldHdl;
  };
}
eXcell_lookup.prototype = new eXcell;

// ********* dynSelect in grid cell***************
function eXcell_dynselect(cell) {
  this.cell = cell;
  this.grid = this.cell.parentNode.grid;
  const oGrid = this.grid.akElm,
    iCellIndex = oGrid._initColIds.indexOf(oGrid.dhx.getColumnId(this.cell.cellIndex)),
    oSelfCol = oGrid.childs[iCellIndex],
    cellid = dhtmlx.uid();

  this.oSelfCol = oSelfCol;
  this.cellid = cellid;

  // if not initialized, init as readonly cell
  if (this.cell.innerHTML == '') {
    // this.isReadOnly = true;
    this.renderReadOnlyCell();
  }

}
eXcell_dynselect.prototype = new eXcell;

eXcell_dynselect.prototype.getValue = function() {
  return this.cell.value;
};

// This is called for ENABLED dynSelect columns which allow editing. For READONLY dynSelect column, the DHTMLX setCValue is used instead.
eXcell_dynselect.prototype.setValue = function(val) {

  const oGrid = this.grid.akElm,
    iCellIndex = oGrid._initColIds.indexOf(oGrid.dhx.getColumnId(this.cell.cellIndex)),
    oSelfCol = oGrid.childs[iCellIndex],
    oData = oGrid.dataSource.getSelectedRecord();

  this.cell.value = val;
  this.cell.key = (oGrid.listItemPairs[oSelfCol.opt.dataField]) ? oGrid.listItemPairs[oSelfCol.opt.dataField][val] : this.grid.akElm.getLookupKey(this.cell.parentNode.idd, this.cell._cellIndex);

  if (this.isEditCell()) {
    const oOption = $(this.cell).find(`option[value="${oSelfCol.opt.name}"]`);
    if (oOption.length > 0)
      $(oOption).remove();


    const $select2Elm = $(this.cell).find('select');

    if (oSelfCol.isCellMultiple()) {
      if (!isNull(this.oSelfCell)) {
        this.oSelfCell.dynSelectControl._multipleSetValues(oData);
        this.oSelfCell.dynSelectControl._multipleDisplayValues($select2Elm, this.cell.key);
      }
    } else {
      const newOption = new Option(this.cell.key, oSelfCol.opt.name);
      $(newOption).data('bExtVal', true);
      $select2Elm.append(newOption);
      $select2Elm.val(oSelfCol.opt.name).trigger('change');
      if (oGrid.listItemPairs[oSelfCol.opt.dataField])
        $(newOption).data('listItemPairs', true);
    }

    $select2Elm[0].dataset.rowId = this.cell.parentNode.idd;
  } else
    $(this.cell).text(this.cell.key);

};
/**
 * Edit mode for cell
 */
eXcell_dynselect.prototype.edit = function() {
  this.renderEditCell();
  $(this.cell).find('select').focus();
};

eXcell_dynselect.prototype.detach = function() {
  return true;
};

/**
 * Renders readonly cell for dynselect type column cell
 */
eXcell_dynselect.prototype.renderReadOnlyCell = function() {
  this.cell.innerHTML = `<span id="${this.cellid}" class="dynSelect" akId="${this.oSelfCol.opt.dataField}"></span>`;
};

/**
 * Clears existing dynselect edit cells in grid
 */
eXcell_dynselect.prototype.clearEditCells = function() {
  const oGrid = this.grid.akElm;

  for (const c in oGrid.cellsCached) {
    const excellCached = oGrid.cellsCached[c];
    const cellCached = excellCached.cell;
    $(cellCached).find('select').select2('close').select2('destroy');
    $(cellCached).find('select').remove();
    excellCached.renderReadOnlyCell();
    excellCached.setValue(cellCached.value);
  }

};
eXcell_dynselect.prototype.isEditCell = function() {
  return $(this.cell).find('select').length > 0;
};

/**
 * Renders editable select2 cell for dynselect column
 */
eXcell_dynselect.prototype.renderEditCell = function() {
  const oGrid = this.grid.akElm,
    iCellIndex = oGrid._initColIds.indexOf(oGrid.dhx.getColumnId(this.cell.cellIndex)),
    oSelfCol = oGrid.childs[iCellIndex],
    cell = this.cell,
    cellid = dhtmlx.uid(),
    oSelfCell = jQuery.extend(true, {}, oSelfCol);

  let cMultiple = '><option></option>';

  // if it is not already a select here then
  if (!this.isEditCell()) {
    this.clearEditCells(); // remove any previously editMode cells

    oSelfCell.opt.dynSelect.closeOnSelect = false;
    if (oSelfCell.isCellMultiple())
      cMultiple = ' multiple';
    else
      oSelfCell.opt.dynSelect.closeOnSelect = true;

    cell.innerHTML = `<select id="${cellid}" class="dynSelect" akId="${oSelfCell.opt.dataField}"${cMultiple} </select>`;

    // ListItemPairs handling
    if (oGrid.dhx.comboValues[oSelfCol.opt.dataField]) {
      const aOptions = oGrid.dhx.comboValues[oSelfCol.opt.dataField];
      for (let i = 0; i < aOptions.length; i++) {
        $(cell).find('select').append($('<option>', {
          value: aOptions[i].value,
          text: aOptions[i].text
        }));
      }
    }

    // Flag for marking this dynSelect is part of cell. Used in the Select2 events (change, remove etc) to differentiate between cell and filter handling
    oSelfCell.bCell = true;
    oSelfCell.opt.dynSelect.allowClear = oSelfCol.opt.dynSelect.allowClearCell;
    oSelfCell.dynSelectControl = new akioma.DynSelect(oSelfCell, cell, oSelfCell.opt.dynSelect);

    this.oSelfCell = oSelfCell;

    this.setValue(this.cell.value);

    oGrid.cellsCached.push(this);
    $(this.cell).data('oCell', oSelfCell);
  }
};

// Excell checkbox - for logical filter
const extended_eXcell_ch_setValue = eXcell_ch.prototype.setValue;
eXcell_ch.prototype.setValue = function(val) {
  this.cell.style.verticalAlign = 'middle'; // nb:to center checkbox in line
  this.cell.style.textAlign = 'center'; // nb:to center checkbox in line
  // val can be int
  if (val) {
    val = val.toString()._dhx_trim();

    if ((val == 'false') || (val == '0'))
      val = '';
  }

  let oFilterIcon;
  if (this.grid.akElm && this.grid.akElm.view == 'propertygrid') {
    const extendedFormat = {
      'empty': { icon: 'fal fa-square' },
      'true': { icon: 'fal fa-check-square' },
      'false': { icon: 'fal fa-square' },
      'null': { icon: 'fal fa-question-square' },
      'filterTrue': { icon: 'fal fa-check-square' },
      'filterFalse': { icon: 'fal fa-times-square' }
    };
    if (val) {
      this.cell.chstate = '1';
      oFilterIcon = extendedFormat['true'];
    } else if (val == null) {
      this.cell.chstate = null;
      oFilterIcon = extendedFormat['null'];
    } else {
      this.cell.chstate = '0';
      oFilterIcon = extendedFormat['false'];
    }

  } else if (this.grid.akElm && this.grid.akElm.view !== 'propertygrid') {
    // var cColId = this.grid.akElm._initColIds[this.cell.cellIndex];
    const iIndex = this.grid.akElm._initColIds.indexOf(this.grid.getColumnId(this.cell.cellIndex));
    const aExtendedFormat = this.grid.akElm.aExtendedFormat[iIndex];
    if (val) {
      this.cell.chstate = '1';
      oFilterIcon = aExtendedFormat['true'];
    } else if (val == null) {
      this.cell.chstate = null;
      oFilterIcon = aExtendedFormat['null'];
    } else {
      this.cell.chstate = '0';
      oFilterIcon = aExtendedFormat['false'];
    }
  } else {
    extended_eXcell_ch_setValue.call(this, val);
    return;
  }

  let cTemplate = '<div class="fa-stack #stackedIcon#" onclick="new eXcell_ch(this.parentNode).changeState(true); (arguments[0]||event).cancelBubble=true; "><i class="#icon1# #size# fa-fw #moduleClass1#" style="font-size:17px;#iconStyle1#"></i>' +
    '<i class="#icon2# fa-stack-1x fa-inverse #moduleClass2#" style="#iconStyle2#"></i></div>';
  cTemplate = akioma.icons.replaceTemplate(cTemplate, oFilterIcon);
  this.cell.setAttribute('excell', 'ch');
  this.setCValue(cTemplate, this.cell.chstate);
};

//* ************* header for logical filter ***************
dhtmlXGridObject.prototype._in_header_logical_filter = function(tag, index) {
  const oSelf = this.akElm;
  index = oSelf._initColIds.indexOf(this.getColumnId(index));

  const oSelfCol = oSelf.childs[index];
  const cAlign = (oSelfCol.opt.align == 'default') ? '' : oSelfCol.opt.align;
  const newfilterid = `${this.getColumnId(index)}-${dhtmlx.uid()}`;
  const aExtendedFormat = oSelf.aExtendedFormat[index];

  const cEmptyIcon = aExtendedFormat['empty'];
  let cTemplate = `<div id="${newfilterid}" class="logicalFilter fa-stack #stackedIcon#" style="text-align: ${cAlign}"akId="${oSelfCol.opt.dataField}"><i class="#icon1# #size# fa-fw #moduleClass1#" style="#iconStyle1#"></i>` +
                        '<i class="#icon2# fa-stack-1x fa-inverse #moduleClass2#" style="#iconStyle2#"></i></div>';
  cTemplate = akioma.icons.replaceTemplate(cTemplate, cEmptyIcon);
  tag.innerHTML = cTemplate;


  oSelfCol.cLogicalState = '';
  tag.value = '';
  tag.index = index;

  tag.onclick = function(e) {
    (e || event).cancelBubble = true; return false;
  };
  this.makeFilter(tag.firstChild, index);

  $(tag.firstChild).click(e => {
    oSelfCol.changeLogicalFilterIcon(e.currentTarget, oSelfCol.cLogicalState, aExtendedFormat);
  });

  $(tag.firstChild).on('keydown', e => {
    const keyCode = e.keyCode || e.which;
    if (keyCode == 32) {
      oSelfCol.changeLogicalFilterIcon(e.currentTarget, oSelfCol.cLogicalState, aExtendedFormat);
      $(e.target).css('outline', 'none');
    }
  });

  $(tag.firstChild).click(e => {
    $(e.currentTarget).attr('tabindex', '0');
    $(e.currentTarget).focus();
    $(e.currentTarget).css('outline', 'none');
  });
};

dhtmlXGridObject.prototype._in_header_multiselect_filter = function(tag, index) {
  const newfilterid = `multiselect_filter_${this.getColumnId(index)}-${dhtmlx.uid()}`;
  const cHTML = (`<div id="${newfilterid}" class="multifiltergridinputelm"></div>`);
  tag.innerHTML = cHTML;

  this.akElm.aMultiSelectFilters.push({
    id: newfilterid,
    index: index,
    colname: this.getColumnId(index),
    elm: tag.innerHTML
  });

  tag.onclick = function(e) {
    (e || event).cancelBubble = true; return false;
  };
  this.makeFilter(tag.firstChild, index);
};

//* ************* header for dynSelect filter ***************
dhtmlXGridObject.prototype._in_header_dynselect_filter = function(tag, index) {
  const oSelf = this.akElm;
  const oSelfCol = oSelf.childs[index];
  const newfilterid = `${this.getColumnId(index)}-${dhtmlx.uid()}`;
  let cMultiple = '><option></option>';
  const oAttributes = (oSelfCol.opt.filterAttributes) ? oSelfCol.opt.filterAttributes : oSelfCol.opt;

  // Using filterAttributes if defined, otherwise use column dynSelect attributes
  oSelfCol.oAttributes = oAttributes;

  if ((oAttributes.multipleBehavior.toLowerCase() == 'standard' || oAttributes.multipleBehavior.toLowerCase() == 'filter+cell') && oAttributes.dynSelect.multiple)
    cMultiple = ' multiple';
  else
    oAttributes.dynSelect.closeOnSelect = true;

  tag.innerHTML = `<select id="${newfilterid}" class="dynSelect" akId="${oAttributes.dataField}"${cMultiple} </select>`;
  $(tag).addClass('akGridDynSelect');

  tag.onclick = function(e) {
    (e || event).cancelBubble = true; return false;
  };
  this.makeFilter(tag.firstChild, index);

  oSelf.aDynSelectFilters[index] = {
    id: newfilterid,
    colname: this.getColumnId(index),
    elm: tag.innerHTML,
    index: index
  };
  tag.index = index;

  if (!oSelfCol.businessEntity)
    akioma.createBusinessEntity(oSelfCol, oAttributes.dynSelect);

  // LisItemPairs handling
  if (oAttributes.comboText && oAttributes.comboValues) {
    oSelfCol.businessEntity = false;
    oSelfCol.setDefaultAttributes();
  }

  oSelfCol.bSelected = false;
  tag.firstChild.dataset.selectVal = '';
  oAttributes.dynSelect.allowClearCell = oAttributes.dynSelect.allowClear;
  oAttributes.dynSelect.allowClear = true;

  oSelfCol.dynSelectControl = new akioma.DynSelect(oSelfCol, tag, oAttributes.dynSelect);

  const select2Obj = oSelfCol.select2.data('select2');

  const onKeyPressDynselect = function(event) {
    const key = event.which;
    const ENTER_KEY = 13;
    if (ENTER_KEY === key && !select2Obj.isOpen() && select2Obj._wasOpenedFromDynselect)
      oSelfCol.parent.FilterGo();

  };

  select2Obj.listeners.keypress.unshift(onKeyPressDynselect);

  if (oAttributes.dynSelect.lookupDialog && oSelfCol.select2)
    oSelfCol.opt.lookupDialog = oAttributes.dynSelect.lookupDialog;

};


// ************** header for lookup filter ***************
dhtmlXGridObject.prototype._in_header_lookup_filter = function(tag, iindex) {
  const oSelf = this.akElm,
    iWidth = parseInt(oSelf.cols.width[iindex]);

  tag.innerHTML = '<input type="text" style="width:90%; font-size:8pt; font-family:Tahoma; -moz-user-select:text; " /><img src="/imgs/akioma/zoom_in-26.png" class="lookupImg" />';
  tag.onclick = tag.onmousedown = function(e) {
    (e || event).cancelBubble = true; return true;
  };
  tag.onselectstart = function() {
    return (event.cancelBubble = true);
  };
  this.makeFilter(tag.firstChild, iindex);

  tag.firstChild._filter = function() {
    if (tag.firstChild.value == '') {
      tag.firstChild.valhdl = null;
      $(tag.firstChild).css('border', '');
      return '';
    }
    tag.firstChild.valhdl = oSelf.filterLookup(iindex, tag.firstChild.value, false);

    $(tag.firstChild).css('border', tag.firstChild.valhdl ? '' : '1px solid red');

    return tag.firstChild.value;
  };
  this._filters_ready();

  $(tag)
    .find('input')
    .css({ width: `${iWidth - 40}px` })
    .end()
    .find('img')
    .css({
      height: '14px',
      width: '14px',
      cursor: 'pointer'
    })
    .click(e => {
      if ((e || event).preventDefault)
        (e || event).preventDefault();
      (e || event).cancelBubble = true;
      oSelf.startLookup(iindex, 'filter');
    });
};

//* ************* header for date filter ***************
dhtmlXGridObject.prototype._in_header_date_filter = function(tag, iindex) {
  const oSelf = this.akElm,
    oSelfCol = oSelf.childs[iindex],
    oGrid = this,
    cId = `${oSelf.opt.name}_${String(iindex)}head`,
    lTime = oSelf.opt.dataType == ('datetime-tz' || 'datetime'),
    cFormat = (lTime) ? `${window.dhx.dateFormat[window.dhx.dateLang]} %H:%i` : window.dhx.dateFormat[window.dhx.dateLang];

  tag.innerHTML = `<input type='text' id='${cId}' style='width:90%; font-size:8pt; font-family:Tahoma; -moz-user-select:text; '>`;
  tag.onclick = tag.onmousedown = function(e) {
    (e || event).cancelBubble = true;
    return true;
  };
  const $dateinput = $(tag.children[0]);
  $dateinput.inputmask('datetime', { inputFormat: 'dd.mm.yyyy' });

  oSelf.headerInputMasks.push($dateinput);

  tag.onselectstart = function() {
    return (event.cancelBubble = true);
  };
  this.makeFilter(tag.firstChild, iindex);

  dhtmlx.delay(() => {
    // check for object
    if (!oSelfCol.gridCalendar) {
      oSelfCol.gridCalendar = new dhtmlXCalendarObject(cId);
      oSelfCol.gridCalendar.setDateFormat(cFormat);
      oSelfCol.gridCalendar.attachEvent('onClick', () => {
        oGrid.filterByAll();
        return true;
      });
      oSelfCol.dhx = tag;
      tag.firstChild.oCalendar = oSelfCol.gridCalendar;
    } else
      oSelfCol.gridCalendar.attachObj(tag.firstChild);
  }, this);
};

//* ************* header for dateRange filter ***************
dhtmlXGridObject.prototype._in_header_daterange_filter = function(tag, iindex) {
  const oSelf = this.akElm,
    oSelfCol = oSelf.childs[iindex],
    oGrid = this,
    cId = `${oSelf.opt.name}_${String(iindex)}head`,
    lTime = oSelf.opt.dataType == ('datetime-tz' || 'datetime'),
    cFormat = (lTime) ? `${window.dhx.dateFormat[window.dhx.dateLang]} %H:%i` : window.dhx.dateFormat[window.dhx.dateLang];

  tag.innerHTML = `<input type='text' id='${cId}_from' class='daterangeFilter'/><input type='text' id='${cId}_to' class='daterangeFilter'/>`;
  $(tag.children[0]).data('calendarType', 'from');
  $(tag.children[1]).data('calendarType', 'to');

  const firstDateInput = $(tag.children[0]);
  const secondaryDateInput = $(tag.children[1]);
  firstDateInput.inputmask('datetime', { inputFormat: 'dd.mm.yyyy' });
  secondaryDateInput.inputmask('datetime', { inputFormat: 'dd.mm.yyyy' });

  oSelf.headerInputMasks.push(...[ firstDateInput, secondaryDateInput ]);

  $(tag).focusout(() => {
    $('div.dhtmlxcalendar_in_input').css('display', 'none');
  });

  tag.onclick = tag.onmousedown = function(e) {
    (e || event).cancelBubble = true;
    if ($(e.target).data('calendarType') == 'from') {
      const cRightVal = e.target.nextSibling.value;
      if (cRightVal)
        oSelfCol.gridCalendarFrom.setSensitiveRange(null, cRightVal);
      else
        oSelfCol.gridCalendarFrom.clearSensitiveRange();
      oSelfCol.bTo = 0;
      setTimeout(() => {
        oSelfCol.gridCalendarFrom.show();
      }, 200);
    }
    if ($(e.target).data('calendarType') == 'to') {
      const cLeftVal = e.target.previousSibling.value;
      if (cLeftVal)
        oSelfCol.gridCalendarTo.setSensitiveRange(cLeftVal, null);
      else
        oSelfCol.gridCalendarTo.clearSensitiveRange();
      oSelfCol.bTo = 1;
      setTimeout(() => {
        oSelfCol.gridCalendarTo.show();
      }, 200);
    }
    return true;
  };

  tag.onselectstart = function() {
    return (event.cancelBubble = true);
  };
  this.makeFilter(tag.firstChild, iindex);
  tag.firstChild.dataset.dateRange = true;

  oSelf.aDateRangeFilters[iindex] = {
    id: cId,
    colname: this.getColumnId(iindex),
    elm: tag.innerHTML,
    index: iindex
  };

  dhtmlx.delay(() => {
    // check for object
    if (!oSelfCol.gridCalendarFrom && !oSelfCol.gridCalendarTo) {
      oSelfCol.gridCalendarFrom = new dhtmlXCalendarObject(`${cId}_from`);
      oSelfCol.gridCalendarTo = new dhtmlXCalendarObject(`${cId}_to`);
      oSelfCol.gridCalendarFrom.setDateFormat(cFormat);
      oSelfCol.gridCalendarTo.setDateFormat(cFormat);
      oSelfCol.gridCalendarFrom.attachEvent('onClick', () => {
        oGrid.filterByAll();
        return true;
      });
      oSelfCol.gridCalendarTo.attachEvent('onClick', () => {
        oGrid.filterByAll();
        return true;
      });
      oSelfCol.dhx = tag;
      // oGrid.gridCalendar.show();
    }
  }, this);
};

(function($) {

  // ******************* data grid ************************
  $.extend({
    /**
     * SwatGrid Control
     * @class ak_datagrid
     * @augments ak_global
     * @tutorial grid-desc
     * @param {Object} options Repository attributes for SwatGrid.
     * @param {string} options.BorderTitle The Title of a dynamic Viewer or Browser
     * @param {string} options.EventOnInitialize client side code to run when Container has been initialized
     * @param {string} options.contextMenu the id of a menuStructure which will be used as a context-menu
     * @param {string} options.titleHeader specifies which panelHeader to use. when empty, then uses the header of its own panel. if "none" then no header at all. if "parent" it uses the header of the parent panel
     * @param {string} options.floatingActionButton the id of a menustructure, which will be rendered as a FAB
     * @param {string} options.LayoutOptions List of multi-layout options for the object.
     * @param {string} options.panelMenu comma separated list of menu-structures which will be shown as panelHeader-Buttons. </br>
     * Can also contain the flag #NoDropDown that specifies that the menu should not be loaded as a dropdown but each menu item should be added in the panel-Header. </br>
     * For example: </br>
     * <code>menuStructSave,menuSettings#NoDropDown,menuLookup</code> </br>
     * The buttons support font icons with the following attributes: </br>
     * 1. Css attributes, defined like this: fa fa-user#color:red </br>
     * 2. Css classes, defined like this: fa fa-user#_style:module_prod
     * 3. Stacked font icons, defined like this: fas fa-circle$fas fa-flag. Both icons also support Css attributes or Css classes, like this: fas fa-circle#color:red$fas fa-flag#_style:module_prod </br>
     * @param {string} options.zappingContainer container used for displaying in browsing/zapping
     * @param {string} options.SUBTYPE
     * @param {string} options.EntityName The Name of the Business Entity used by the component
     * @param {number} options.maxColumnCharacters limits the maximum number of displayed characters for grid-columns. Usefull if there are columns that could contain large strings, which would cause the rows to be too high in multiline-mode
     * @param {string} options.updateRecordContainer name of repository object to be called for updating records or #dynamic for opening it with Hdl as the container name.
     * @param {string} options.showGridFilter controls which filter-controls are shown for a grid.&#10;all,none,column-only
     * @param {boolean} options.batchingMode if true, then the grid will use batching mode
     * @param {boolean} options.ENABLED WidgetAttributes Enabled
     * @param {string} options.addRecordContainer container for adding new records
     * @param {string} options.copyRecordContainer optional name of repository object to use when copying a record. If not specified, updateContainer will be used
     * @param {string} options.EventRowChosen client side code executed when a row is chosen (selected by the user to perform an action on it), e.g. by double click
     * @param {boolean} options.filterOnLeave if true, then filter will automatically applied when leaving a filter-field
     * @param {string} options.EventBeforeFetch code to be executed before fetching data from the backend
     * @param {number} options.WIDTH-CHARS Width in characters. This may currently be used when rendering some objects. There is no get function, use getWidth to retrieve the realized value from an object.
     * @param {string} options.EventRowSelected client side code executed when a row is selected (becomes current row in a set of rows), e.g. by single click
     * @param {string} options.resourceName
     * @param {string} options.FolderWindowToLaunch If Dynamics is running, this property specifies a window to launch upon the occurence of toolbar events "View", "Copy", "Modify" or "Add".
     * @param {string} options.RecordCreate Axilon: Neuanlage-Dialog für komplexe Daten mit mehreren eindeutigen Schlüsselfeldern
     * @param {string} options.ToolBarKey Key of the used toolbar
     * @param {boolean} options.keepSelection Auto select previously selected grid row if the row is found, searched in the ak_businessEntity, by the identifier attribute. Default is true.
     * @param {boolean} options.Multiline If true, Grid will use  multiline cells in grid. If false, all cells will be single line. Default is false.</br>
     * This attribute will be overwritten if Multiline is defined in the UserProfile settings.
     * @fires ak_datagrid#EventBeforeFetch
     * @fires ak_datagrid#EventRowSelected
     * @fires ak_datagrid#EventBeforeSelected
     * @fires ak_datagrid#EventRowChosen
     * @fires ak_datagrid#EventOnInitialize
     * @fires ak_datagrid#EventOnContextMenuOpen
     */
    ak_datagrid2: function(options) {
      akioma.BaseDataGrid.call(this, options);
      const oSelf = this,
        defaults = {
          rowHeight: 23,
          filterShortcut: akioma.shortcutManager.get('GridResetFilter'),
          filterRefreshShortcut: akioma.shortcutManager.get('GridResetRefreshFilter')
        };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;
      this.view = options.view;
      this.isGridObject = true;

      this.registerVuexModule = true;

      this.options = options;


      // create logger on module DataSource
      this.log = akioma.log.getLogger('SwatGrid');

      this.log.setLevel('warn');

      this.cellsCached = [];
      this.headerInputMasks = [];

      this.columns = [];

      this.aFtrHdls = [];
      this.aSortPhrases = [];
      this.aPromises = [];
      this.oPreloadedDynselects = null;

      this.oKeepSelectionData = null;
      this.listItemPairs = [];
      this.errorCells = [];
      this.aPanelHdrDynselects = [];

      if (!this.opt.customStyle)
        this.opt.customStyle = this.view;

      this.lastScrollLeft = 0;

      if (this.opt.filterOnLeave)
        this.filterPrevValues = [];

      // if panelMenu contains "filterListBT" that means grid filter menu is added and will be async loaded
      if (this.opt.panelMenu.indexOf('filterListBT') !== -1)
        this.getFiltersPromise = $.Deferred();

      // initialize tab
      this.formViews = [ 'form', 'fieldset', 'block' ];
      if ($.inArray(this.parent.view, this.formViews) !== -1) {
        const formGrid = { type: 'container', name: `formGrid_${this.opt.name}`, inputWidth: 800, inputHeight: 150, position: 'label-top' };
        this.parent.prop.fields.push(formGrid);
      } else {
        const oParent = this.parent.dhx;
        if (oParent)
          this.attachGridToParent(oParent);
        else
          !_isIE && console.error(`No valid parent for datagrid ${this.opt.name}`);

      }

      oSelf.FilterManager = new akioma.FilterManager(oSelf);

    }
  });
  Object.assign($.ak_datagrid2.prototype, akioma.BaseDataGrid.prototype, {
    componentOptions: function() {
      const oSelf = this;
      return {
        watch: {
          'state.changedRows': {
            fn: function(newValue, oldValue) {
              oSelf._hasChangedRowsWatcher(newValue, oldValue);
            },
            watchOptions: { deep: true }
          },
          'state.attributes.hasChanges': function(newValue, oldValue) {
            oSelf._hasChangesWatcher(newValue, oldValue);
          },
          'state.attributes.customStates': {
            fn: function(customStates) {
              oSelf._hasCustomStates(customStates);
            },
            watchOptions: { deep: true }
          },
          'state.attributes.hasErrors': function(newValue, oldValue) {
            oSelf._hasErrorsWatcher(newValue, oldValue);
          }
        }
      };
    },

    _hasChangesWatcher: function(newValue) {
      const oSelf = this;

      const oPanelCell = oSelf.getAncestor('panel').dhx.cell;

      if (oSelf.oVuexState.attributes.hasChangesStyle !== '')
        $(oPanelCell).attr(oSelf.oVuexState.attributes.hasChangesStyle, newValue);

      this._callEventOnStateChanged({ state: 'hasChanges', value: newValue });
    },

    _hasChangedRowsWatcher: function(newValue) {
      const oSelf = this;

      if (newValue.length > 0) {
        if (!oSelf.oVuexState.attributes.hasChanges)
          oSelf._dispatch('incrementHasChanges', 1);
        oSelf._dispatch('setHasChanges', true);
      } else {
        oSelf._dispatch('decrementHasChanges', 1);
        if (oSelf.oVuexState.attributes.hasChanges)
          oSelf._dispatch('setHasChanges', false);
      }

      // apply haschanges style on each row
      if (oSelf.oVuexState.attributes.hasChangesStyle !== '') {
        // first cleanup existing
        $(oSelf.dhx.entBox).find('tr[akhaschanges="true"]').attr(oSelf.oVuexState.attributes.hasChangesStyle, false);

        // apply haschanges row highlighting
        for (const r in newValue) {
          const rowID = newValue[r];

          const rowEl = $(oSelf.dhx.entBox).find(`tr[akId="${rowID}"]`);

          if (rowEl)
            rowEl.attr(oSelf.oVuexState.attributes.hasChangesStyle, true);

        }
      }
    },

    _hasErrorsWatcher: function(newValue) {
      const oSelf = this;
      const oPanelCell = oSelf.getAncestor('panel').dhx.cell;

      if (oSelf.oVuexState.attributes.hasErrorsStyle !== '')
        $(oPanelCell).attr(oSelf.oVuexState.attributes.hasErrorsStyle, newValue);

      this._callEventOnStateChanged({ state: 'hasErrors', value: newValue });
    },

    /**
     * Watcher for the customState attribute
     * @param {array} customStates
     * @protected
     * @returns {void}
     */
    _hasCustomStates: function(customStates) {
      if (isNull(customStates)) return;

      const oPanelCell = this.getAncestor('panel').dhx.cell;

      let value = '';
      for (let index = 0; index < customStates.length; index++) {
        if (index == 0)
          value += customStates[index].name;
        else
          value += ` ${customStates[index].name}`;

      }

      if (value)
        $(oPanelCell).attr('akcustomstate', value);
      else
        $(oPanelCell).removeAttr('akcustomstate');


      const stateData = this._getCustomStateData(this.oVuexState.attributes);
      this._callEventOnStateChanged(stateData);
    },

    /**
     * Expose the cell of the grid by a given rowID and colIndex
     * @param {number} rowID
     * @param {number} colIndex
     * @returns {object}
     * @instance
     * @memberof ak_datagrid2
     */
    getCellByRowAndCol: function(rowID, colIndex) {
      return this.dhx.cells(rowID, colIndex);
    },

    /**
     * Method for getting the column index by id
     * @param {string} id
     * @returns {number}
     * @instance
     * @memberof ak_datagrid2
     */
    getColIndexById: function(id) {
      return this.dhx.getColIndexById(id);
    },

    /**
      * Method for handling a class, id or any propertie
      * @param {string} selector
      * @param {object} styles object where the key is property name and value is the value of the propertie.
      * in case where remove is true, property values should be empty string.
      * @param {boolean} remove true for remove, false or undefined for add
      * @returns {void}
      * @instance
      * @memberof ak_datagrid2
      */
    handleProperties(selector, styles, remove) {
      const objBox = $(this.dhx.objBox).find(selector);
      if (!remove) {
        for (const prop in styles)
          objBox.attr(prop, styles[prop]);

      } else {
        for (const prop in styles)
          objBox.removeAttr(prop);

      }
    },

    /**
      * Function used to show a column in the grid by the column index.
      * @memberof ak_datagridcol2
      * @instance
      * @param {Number} index
      */
    showColumnByIndex(index) {
      this.dhx.setColumnHidden(index, false);
    },

    /**
      * Function used to hide a column in the grid by the column index.
      * @memberof ak_datagridcol2
      * @instance
      * @param {Number} index
      */
    hideColumnByIndex(index) {
      this.dhx.setColumnHidden(index, true);
    },

    /**
      * Function used to show a column by name or index.
      * @memberof ak_datagridcol2
      * @instance
      * @param {Number | String} identifier
      */
    showColumn(identifier) {
      if (typeof identifier === 'string')
        identifier = this.getColIndexById(identifier);

      if (typeof identifier === 'number')
        this.showColumnByIndex(identifier);

    },

    /**
      * Function used to hide a column by name or index.
      * @memberof ak_datagridcol2
      * @instance
      * @param {Number | String} identifier
      */
    hideColumn(identifier) {
      if (typeof identifier == 'string')
        identifier = this.getColIndexById(identifier);

      if (typeof identifier === 'number')
        this.hideColumnByIndex(identifier);

    },

    /**
      * Adding classes while keeping the old ones
      * @param {string} selector
      * @param {array} classes array of class names
      * @returns {void}
      * @instance
      * @memberof ak_datagrid2
      */
    addClass(selector, classes) {
      const objBox = $(this.dhx.objBox).find(selector);
      for (let index = 0; index < classes.length; index++)
        objBox.addClass(classes[index]);

    },

    /**
      * Removing classes by class names
      * @param {string} selector
      * @param {array} classes array of class names
      * @returns {void}
      * @instance
      * @memberof ak_datagrid2
      */
    removeClass(selector, classes) {
      const objBox = $(this.dhx.objBox).find(selector);
      for (let index = 0; index < classes.length; index++)
        objBox.removeClass(classes[index]);

    },

    /**
      * Method for enabling or disabling the grid
      * @param {boolean} editable True for enabling the grid, false for disabling it
      * @returns {void}
      * @instance
      * @memberof ak_datagrid2
      */
    setEditable(editable) {
      this.dhx.setEditable(editable);
    },

    /**
      * Method for checking if the grid is enabled
      * @returns {boolean} True if the grid is enabled, false otherwise
      * @instance
      * @memberof ak_datagrid2
      */
    isEnabled() {
      return this.dhx.isEditable;
    },

    // finish construct *****************
    finishConstruct: function() {

      if ($.inArray(this.parent.view, this.formViews) !== -1) {
        const formParent = akioma.getForm(this);
        const oParent = formParent.dhx.getContainer(`formGrid_${this.opt.name}`);

        this.calculateWidth_inForm(this, oParent);

        this.attachGridToParent(oParent);

        if (this.opt.useBusinessEntity) {
          try {
            let cResourceName = '';
            if (this.opt.resourceName)
              cResourceName = this.opt.resourceName;
            else
              cResourceName = (this.opt.typeRange == 'pos.ofr.' ? 'Akioma.Crm.Offer.OfferStructEntity' : 'Akioma.Crm.Product.ProductStructEntity');

            const oBEoptions = {
              cacheLimit: 50,
              catalogURI: '',
              dataSource: '',
              entityName: (this.opt.entityName || 'eStruct'),
              extKey: 'selfhdl',
              foreignKey: 'ownerhdl',
              id: 'offerw45613645_businessEntity',
              identifier: 'selfhdl',
              name: 'businessEntity',
              rowsToBatch: 1000,
              resourceName: cResourceName,
              serviceURI: '',
              /**
                * Code executed on the client-side before a data fetch request.
                * @event ak_datagrid#EventBeforeFetch
                * @type {object}
                */
              onBeforeFetch: (this.opt.onBeforeFetch) ? this.opt.onBeforeFetch : '',
              maxColumnCharacters: (this.opt.maxColumnCharacters ? this.opt.maxColumnCharacters : '')
            };

            const oNew = app.controller.parseProc({
              view: 'businessEntity',
              att: oBEoptions,
              sub: []
            }, this);

            // crete DATA:LINK between grid and businessEntity, for grid2 in form
            if ($.inArray(this.parent.view, this.formViews) !== -1) {
              this.dataSource = oNew;
              const cUniqueLinkName = this.opt.entityName + dhtmlx.uid();
              this.dynObjName = cUniqueLinkName;
              this.dataSource.dynObjName = cUniqueLinkName;
              if (this.opt.entityName) {
                app.messenger.subscribe(this.dataSource.dynObject, `DATA:SRC:${cUniqueLinkName}`);
                app.messenger.subscribe(this.dynObject, `DATA:TRG:${cUniqueLinkName}`);
              }
            }

          } catch (oErr) {
            akioma.log.error('Error initializing BE', oErr);
          }
        }
      }

      const oSelf = this,
        oGrid = this.dhx,
        gridDiv = oGrid.entBox;


      // preloads dynselect data
      this.preloadDynselectColumnsData();

      this.iBatchSize = (this.dataSource ? this.dataSource.opt.RowsToBatch : 50);

      let oSource;
      if (this.dataSource && this.dataSource.view == 'businessEntity2') {
        this.dataSource.addAfterCatalogAdd(() => {
          oSource = oSelf.dataSource.getStore(oSelf.opt.entityName || oSelf.dataSource.entityName);
          oGrid.init();
          oGrid.sync(oSource);

          oGrid.BE = oSource;
        });

      } else if (this.dataSource)
        oSource = this.dataSource.dhx;


      if (this.parent.view == 'panel') {
        // set title and panelMenu buttons in panel header
        akioma.setPanelHeader(oSelf);
      }

      if (oSelf.akId)
        $(gridDiv).attr('akId', oSelf.akId);


      // set akStyle in grid
      $(gridDiv).attr('akStyle', oSelf.opt.customStyle);

      // batching records in grid
      if (this.opt.batchingMode) {
        this.dataSource.batchMode = true;
        oGrid.akSmartBatch = true;
        oGrid.aAkBatchedRows = [];

        let aPanelHdrMenus = oSelf.opt.panelMenu.split(',');
        aPanelHdrMenus = aPanelHdrMenus.map(val => val.toLowerCase());

        // if batch mode and no gridfilter query businessEntity
        // @todo dehardcode gilter menu check
        let hasFilterMenu = aPanelHdrMenus.indexOf('gridpanelfilter') > -1;
        if (!hasFilterMenu)
          hasFilterMenu = aPanelHdrMenus.indexOf('gridpanelfilter#nodropdown') > -1;

        if (hasFilterMenu)
          oSelf.dataSource.stop = true; // stop open query temporary
        else {
          this.dataSource.setBatch(0, parseInt(oSelf.iBatchSize));
          this.dataSource.setLastIndex(0);
        }
      }

      if (oSelf.opt.enabled)
        oGrid.setEditable(true);
      else
        oGrid.setEditable(false);

      // set multiSelect mode
      if (oSelf.opt.multiSelect)
        oGrid.enableMultiselect(oSelf.opt.multiSelect);
      else
        oGrid.enableMultiselect(false);

      // mercy drag, on drag&drop copy instead of move
      // Temporary quick fix: mercyDrag is disabled for all Grids, except for Designer.
      // @todo dehardcode drag drop fix
      if (oSelf.opt.name == 'ObjectInstanceGrid' || oSelf.opt.name == 'ObjectInstanceGrid1' || oSelf.opt.name == 'ObjectMasterGrid')
        oGrid.enableMercyDrag(true);

      if (oSelf.opt.name == 'ObjectMasterGrid')
        oGrid.attachEvent('onDragIn', () => false);

      // add margin to grid table
      if (oSelf.opt.floatingActionButton)
        $($(gridDiv).find('table')[1]).css('margin-bottom', '50px');


      if (oSelf.opt.contextMenu !== undefined && oSelf.opt.contextMenu !== '') {
        const cPointer = `${this.akId}-${this.opt.contextMenu}${dhtmlx.uid()}`;
        this.contextMenuId = cPointer;
        this.contextMenuObject = new akioma.GridContextMenu(oSelf, cPointer);
      }

      // get column settings and set multiline
      const oColSettings = UserProfile.loadGridLocalProfileData(oSelf);
      if (oColSettings && oColSettings.multiline != undefined)
        oSelf.bMultiline = oColSettings.multiline;

      oGrid.enableMultiline(oSelf.bMultiline);

      // Wait for dynSelect preload data
      if (oSelf.aPromises.length > 0) {
        if (oSelf.parent)
          oSelf.parent.dhx.progressOn();

        const promises = [];
        for (let i = 0; i < oSelf.aPromises.length; i++)
          promises.push(oSelf.aPromises[i]);

        Promise.all(promises).then(() => {
          oSelf.syncSource();
          if (oSelf.parent)
            oSelf.parent.dhx.progressOff();
        });
      } else if (oSelf.dataSource.view !== 'businessEntity2') {
        oGrid.init();
        oGrid.sync(oSource);
        oGrid.BE = oSource;
        oSelf.attachNavigationEvents();
      }

      // init multiselect and dynSelect filters
      oSelf.aMultiSelectStatus = {
        aSelectedValues: [],
        aSelectedValuesKeys: [],
        aSelectedComboResult: []
      };

      // Used for keeping trag of dynSelect filter values
      oSelf.aDynSelectStatus = {
        aSelectedItems: [],
        aSelectedValues: []
      };

      const aSelectedValues = oSelf.aMultiSelectStatus.aSelectedValues; // values of multiselect filter
      const aSelectedValuesKeys = oSelf.aMultiSelectStatus.aSelectedValuesKeys; // keys of multiselect filter
      const aSelectedComboResult = oSelf.aMultiSelectStatus.aSelectedComboResult;
      for (const i in oSelf.aMultiSelectFilters) {
        const cCurMultiFilterColName = oSelf.aMultiSelectFilters[i].colname;
        aSelectedValues[cCurMultiFilterColName] = [];
        aSelectedValuesKeys[cCurMultiFilterColName] = [];
        aSelectedComboResult[cCurMultiFilterColName] = []; // will be used for value display in combo multifilter

        const oMultiFilterElm = oGrid.getFilterElement(oSelf.aMultiSelectFilters[i].index);

        oSelf.aMultiSelectFilters[i].combo = oMultiFilterElm;
        oMultiFilterElm.stopSelectionOfOptions = true;
        $(oMultiFilterElm.DOMelem_input).attr('readonly', 'readonly');

        const aTextInputs = [];
        oMultiFilterElm.attachEvent('onCheck', (value, state) => {
          const cMultiFilterElmVal = oMultiFilterElm.getOption(value).text_input;
          aTextInputs.push(oMultiFilterElm.getOption(value).text_input);
          if (state) {

            aSelectedComboResult[cCurMultiFilterColName].push(cMultiFilterElmVal.split(' / ')[1]);
            aSelectedValuesKeys[cCurMultiFilterColName].push(cMultiFilterElmVal);
            aSelectedValues[cCurMultiFilterColName].push(value);
          } else {
            aSelectedValuesKeys[cCurMultiFilterColName].splice(aSelectedValuesKeys[cCurMultiFilterColName].indexOf(cMultiFilterElmVal), 1);
            aSelectedValues[cCurMultiFilterColName].splice(aSelectedValues[cCurMultiFilterColName].indexOf(value), 1);
            aSelectedComboResult[cCurMultiFilterColName].splice(aSelectedValuesKeys[cCurMultiFilterColName].indexOf(cMultiFilterElmVal.split(' / ')[1]), 1);
          }
          oMultiFilterElm.cont.value = aSelectedValues[cCurMultiFilterColName].join('|');
          $(oMultiFilterElm.DOMelem_input).attr('placeholder', aSelectedComboResult[cCurMultiFilterColName].join(', '));
        });
      }

      // Attach focus events for header filters
      oSelf._onFocusInFilter();
      oSelf._onFocusOutFilter();

      // add akId to Grid's columns filter and set cursor for sortable columns
      const headerRows = $(oGrid.entBox).find('.xhdr').find('tr');
      const labels = $(headerRows[1]).find('td'); // get labels from header
      const filterInput = $(headerRows[2]).find('td'); // get all filter inputs

      for (let i = 0; i < filterInput.length; i++) {
        const columnLabel = oSelf.aAkId[i];

        $(filterInput[i]).attr('akId', `${oSelf.akId}-${columnLabel}`);
        $(filterInput[i]).addClass('akGridFilter');

        if (akioma.getAkIdMode() == 'extended')
          $(filterInput[i]).find('input').attr('akId', `${oSelf.akId}-${columnLabel}-input`);
      }

      for (let i = 0; i < labels.length; i++) {
        const sortType = oSelf.aColSort[i];
        if (sortType == 'server')
          $(labels[i]).children().attr('cursor', 'server');
        else
          $(labels[i]).children().attr('cursor', 'na');
      }

      const $GridHdrTr = $(oGrid.entBox).find('.xhdr table tr:last-of-type');
      oSelf.gridHdrFilterDOM = $GridHdrTr;
      oSelf.bGridFilterOpened = false;
      oSelf.bHdrVisible = false;

      // attach search input
      const $hdr = $(oSelf.dhx.entBox).find('.xhdr');

      // show hide easyquery filter icon// extendedColumnFilters
      let bShowExtendedColumnFilters = false;
      bShowExtendedColumnFilters = (oSelf.opt.extendedColumnFilters || app.sessionData.extendedColumnFilters || bShowExtendedColumnFilters);

      if (oSelf.opt.showGridFilter != 'none' && oSelf.opt.showGridFilter != 'column-only') {
        oSelf.cSearchInputID = `grid-eq-header-panels${dhtmlx.uid()}`;

        let cExtra = '';
        if (bShowExtendedColumnFilters)
          cExtra = '<i class="fa fa-filter filterforgridcolhdr"></i>';

        $(`<div id="${oSelf.cSearchInputID}" aktype="grid-eq-header-panels" style="height:40px;width: 100%;float:left;clear:both;"></div>${cExtra}`).insertBefore($hdr);

        const oLayout = new dhtmlXLayoutObject({
          parent: oSelf.cSearchInputID,
          pattern: '1C'
        });

        oLayout.cells('a').hideHeader();
        oSelf.buildGridSearchInput(oLayout.cells('a'));

        oLayout.cells('a').setWidth($(`#${oSelf.cSearchInputID}`).width());
      } else if (oSelf.opt.showGridFilter != 'none' && bShowExtendedColumnFilters) {
        // attach only filter icon, no input search
        $('<i class="fa fa-filter filterforgridcolhdr"></i>').insertBefore($hdr);
      }

      if (oSelf.dataSource) {
        const oSrc = oSelf.dataSource;
        oSrc.addAfterCatalogAdd(() => {
          let oSrcDhx;
          if (oSrc.view == 'businessEntity2')
            oSrcDhx = oSrc.getStore(oSrc.entityName);
          else
            oSrcDhx = oSrc.dhx;
          oSrcDhx.attachEvent('onXLE', () => {
            if (oSelf.bFirstGridLoad) {
              if (oColSettings)
                akioma.applyGridColumnSettings(oSelf, oColSettings);
            }
          });
        });
      }

      if (oColSettings)
        akioma.applyGridColumnSettings(oSelf, oColSettings);

      oGrid.attachEvent('onXLE', () => {
        const oData = oGrid.BE.data.getIndexRange();
        let i;

        oGrid.firstRendered = true;
        if (oSelf.bFirstGridLoad) {
          oGrid.selectRow(0, true, true, true);
          if (oSelf.dataSource.view == 'businessEntity') {
            const aNonSortableFields = oSelf.dataSource.aNonSortableFields;

            // set cursor for sortable columns
            const headerRows = $(oGrid.entBox.children[0]).find('tr');
            const labels = $(headerRows[1]).find('td'); // get labels from header
            for (const i in oSelf._initColIds) {
              const cCurrentColID = oSelf._initColIds[i];
              const cCurrentColInd = oSelf.dhx.getColIndexById(cCurrentColID);

              // check if nonSortable allow sorting
              if (aNonSortableFields.indexOf(cCurrentColID) == -1) {
                const sortType = oSelf.aColSort[i];
                if (sortType == 'server')
                  $(labels[cCurrentColInd]).children().attr('cursor', 'server');
                else
                  $(labels[cCurrentColInd]).children().attr('cursor', 'na');
              }
            }
          }
          oSelf.bFlagMoveC = false;
          oSelf.bFirstGridLoad = false;

          oSelf.bFlagMoveC = true;

        }

        if (oSelf.oSortState) {
          const sortstate = oSelf.oSortState;
          oGrid.setSortImgState(sortstate.state, sortstate.iCol, sortstate.order);
        } else
          oGrid.setSortImgState(false);


        // add akId to Grid's rows
        try {
          for (let i = 0; i < oGrid.getRowsNum(); i++) {
            const oCell = oGrid.cellByIndex(i, 0);
            if (oCell) {
              const row = oCell.cell.parentElement; // i - the index of a row in the grid
              if (oData[i].selfhdl) {
                const akId = oSelf.computeRowAkIdAttribute(oData[i]);
                $(row).attr('akId', akId);

                for (let colIndex = 0; colIndex < oGrid.getColumnCount(); colIndex++) {
                  const cell = oGrid.cellByIndex(i, colIndex);

                  if (akioma.getAkIdMode() == 'extended')
                    $(cell.cell).attr('akId', `${akId}-${oGrid.getColumnId(colIndex)}`);

                  // Add akEvent
                  cell.akEvent = {};
                  cell.akEvent.currentValue = cell.getValue();
                  cell.akEvent.lastValue = undefined;
                  $(cell.cell).data('akEvent', cell.akEvent);
                }
              }
            }
          }
        } catch (e) {
          console.warn('could not add akid for grid', e, i);
        }

        // If batching is active, keep fetching batches until there is a scrollbar in  the Grid
        const panel = oSelf.getAncestor('panel');
        const panelHdrCollapsed = panel.dhx.isCollapsed();
        const scrollBottomReached = oSelf.hasReachedScrollBottom();
        if (oSelf.opt.batchingMode &&
          oSelf.dataSource.hasRecordsToBatch &&
          oSelf.dataSource.dataLoaded &&
          scrollBottomReached && !panelHdrCollapsed) {

          panel.loadingBatches = true;
          oSelf.gridBatchHandler();
        } else
          panel.loadingBatches = false;

      });

      oGrid.attachEvent('onSyncApply', () => {
        if (oSelf.oSortState) {
          const sortstate = oSelf.oSortState;
          oGrid.setSortImgState(sortstate.state, sortstate.iCol, sortstate.order);
        } else
          oGrid.setSortImgState(false);

      });

      if ($.inArray(oSelf.parent.view, oSelf.formViews) !== -1) {
        oSelf.dataSource.bAfterAddCatalog.then(() => {
          oSelf.getFilterList();
          let height;
          const hdrPanel = $(oSelf.dhx.entBox).find('div[aktype="grid-eq-header-panels"]');

          if (oSelf.opt.rows)
            height = oSelf.opt.rows * oSelf.opt.rowHeight + $(oSelf.dhx.hdrBox).height();
          else
            height = 150 + $(oSelf.dhx.hdrBox).height();

          if (hdrPanel.length > 0)
            height += $(hdrPanel).height();
          $(oSelf.dhx.entBox).height(height);
        });
      } else
        this.getFilterList();

      const query = EQ.client.getQuery();
      oSelf.prevSavedQuery = []; // used for columns filters state control
      oSelf.aGridColumnOperators = [];

      $(oSelf.dhx.entBox).find('.xhdr tr:nth-child(4) td').css('pointer-events', 'none');

      const $headerLabels = $(oSelf.dhx.entBox).find('.xhdr tr:nth-child(3) td');
      const $headerEQFilterInfo = $(oSelf.dhx.entBox).find('.xhdr tr:nth-child(4)');
      const $paramCondBtn = $('.eqjs-qp-condition-button-parameterization').parent();
      const $addPredicateCondBtn = $('.eqjs-qp-condition-button-addPredicate').parent();
      const $addConditionCondBtn = $('.eqjs-qp-condition-button-addCondition').parent();
      const $filterGridIcon = $(oSelf.dhx.entBox).find('.filterforgridcolhdr');
      let lastHoveredCol = null;

      $headerLabels.find('.filterforgridcolhdr').on('click', function() {
        $(this).closest('td').trigger('dblclick');
      });

      $headerEQFilterInfo.hide();
      $filterGridIcon.on('click', () => {
        let cColumnId = oSelf.dhx.getColumnId(lastHoveredCol);

        const iColIndex = oSelf._initColIds.indexOf(cColumnId);

        const oFilterElm = oGrid.getFilterElement(iColIndex);

        let $td;
        if (oFilterElm.tagName == undefined)
          $td = $(oFilterElm.cont).closest('td');
        else
          $td = $(oFilterElm).closest('td');

        let cColFilterValue = oSelf.dhx.getFilterElement(iColIndex).value;

        let cExistingFilterOp = null;

        // check type of existing input filter
        if (cColFilterValue && cColFilterValue.length > 0) {
          const count = (cColFilterValue.match(/\*/g) || []).length;

          if (count == 1 && (cColFilterValue.indexOf('*') == cColFilterValue.length - 1))
            cExistingFilterOp = 'startswith';
          else if (count == 1 && (cColFilterValue.indexOf('*') == 0))
            cExistingFilterOp = 'endswith';
          else if (count == 2)
            cExistingFilterOp = 'contains';
          else if (count == 0)
            cExistingFilterOp = 'eq';

        }

        // stop filter if nonFilterable Column type
        if (oSelf.dataSource && oSelf.dataSource.aNonFilterableFields.indexOf(cColumnId) != -1)
          return false;

        oSelf.cColumnIdCurrentlyFiltered = cColumnId;

        // if grid EQ is not opened yet and use column filters
        if (!oSelf.bGridFilterOpened && oSelf.opt.useColumnFilterScreens) {
          akioma.gridFilterFields(oSelf, $td, 'popup');
          oSelf.bGridFilterOpened = true;
        } else if (akioma.gridFilterFieldsPopup) {
          const elm = $td;
          const $table = elm.closest('table');
          akioma.gridFilterFieldsPopup.p.style.top = `${$table.offset().top + elm.parent().parent().height()}px`;
          akioma.gridFilterFieldsPopup.p.style.left = `${elm.offset().left - 20}px`;
          akioma.gridFilterFieldsPopup.p.style.display = 'block';
          $(akioma.gridFilterFieldsPopup.p).find('.dhx_popup_arrow').find('.dhx_popup_arrow').css({ 'margin-left': '-39%' });

        }

        // if a EQ filter is already initialized
        if (oSelf.bGridFilterOpened) {
          cColumnId = oSelf.dhx.getColumnId($td.index());
          if (oSelf.aGridFilterFields[cColumnId] == undefined) {
            oSelf.aGridFilterFields[cColumnId] = [];

            EQ.client.defaultQuery.query.root.linkType = 'Any';
            EQ.client.refreshWidgets(true);

            // adds a new column filter if none has been defined already
            const id = `${oSelf.queryWindow.EntityName}.${cColumnId}`;
            const cDefaultOperator = akioma.getDefaultEQOperator(id);

            const cColumnDataType = oSelf.getEQDataType(`${oSelf.queryWindow.EntityName}.${cColumnId}`);
            if (cColumnDataType == 'Date' || cColumnDataType == 'Time') {
              const oFirstDefCol = { attr: `${oSelf.queryWindow.EntityName}.${cColumnId}`, operator: 'gte', value: new String(), enabled: true },
                oSecondDefCol = { attr: `${oSelf.queryWindow.EntityName}.${cColumnId}`, operator: 'lte', value: new String(), enabled: true };

              oSelf.aGridFilterFields[cColumnId].push(oFirstDefCol);
              oSelf.aGridFilterFields[cColumnId].push(oSecondDefCol);
            } else {
              let oColumnField;
              if (cExistingFilterOp == null)
                oColumnField = { attr: `${oSelf.queryWindow.EntityName}.${cColumnId}`, operator: cDefaultOperator, value: new String(), enabled: true };
              else {
                if (cExistingFilterOp != 'matches')
                  cColFilterValue = cColFilterValue.replace(/\*/g, '');

                oColumnField = { attr: `${oSelf.queryWindow.EntityName}.${cColumnId}`, operator: cExistingFilterOp, value: cColFilterValue, enabled: true };
              }
              oSelf.aGridFilterFields[cColumnId].push(oColumnField);
            }
          }

          // now clear EQ and add all the filters for the current column // this are saved on every value update in callbackOnValueUpdate
          // also reset linkType because clear will set default as 'Any'
          oSelf.bSkipValueUpdates = true;
          const cPrevEQLinkType = EQ.client.defaultQuery.query.root.linkType;
          query.clear();
          EQ.client.defaultQuery.query.root.linkType = cPrevEQLinkType;

          oSelf.bSkipValueUpdates = false;
          // if we have filters for the current column id add them to EQ filter
          if (oSelf.aGridFilterFields[cColumnId].length > 0) {
            oSelf.bSkipValueUpdates = true;
            for (let i = 0; i < oSelf.aGridFilterFields[cColumnId].length; i++) {
              const oColumnField = oSelf.aGridFilterFields[cColumnId][i];
              query.addSimpleCondition(oColumnField);
            }
            oSelf.bSkipValueUpdates = false;

            // now hide all conditional extra buttons
            $paramCondBtn.hide();
            $addPredicateCondBtn.hide();
            $addConditionCondBtn.hide();
          }

          const cCurrentSelectedColOperator = oSelf.aGridColumnOperators[oSelf.cColumnIdCurrentlyFiltered];
          const oEQuery = EQ.client.getQuery().query.root;
          if (cCurrentSelectedColOperator) {
            if (cCurrentSelectedColOperator == 'and')
              oEQuery.linkType = 'All';
            else
              oEQuery.linkType = 'Any';
            EQ.client.refreshWidgets();
          }
        }
      }).on('mouseenter', function() {
        $(this).show();
      });
      $headerLabels
        .hover(function() {
          const iLeft = $(this).position().left + $(this).width();

          lastHoveredCol = $(this).index();
          $filterGridIcon.css({
            top: ((oSelf.opt.showGridFilter != 'none' && oSelf.opt.showGridFilter != 'column-only') ? '65px' : '20px'),
            zIndex: 9999,
            left: `${iLeft - 10}px`,
            display: 'block'
          });

        }, () => {
          $filterGridIcon.css({ display: 'none' });
        });

      this.oMouseTrap = Mousetrap(oSelf.dhx.entBox);

      this.oMouseTrap.bind(oSelf.opt.filterShortcut, () => {
        oSelf.FilterClear();
      });
      this.oMouseTrap.bind(oSelf.opt.filterRefreshShortcut, () => {
        oSelf.resetAndRefresh();
      }, 'keyup');

      $(oSelf.dhx.entBox).attr('tabindex', '0');
      $(oSelf.dhx.entBox).focus();

      // init event
      if (this.opt.onInit) {
        /**
         * Client side code to run when Container has been initialized
         * @event ak_datagrid#EventOnInitialize
         * @type {object}
         */
        app.controller.callAkiomaCode(this, this.opt.onInit);
      }
    },

    updateBusinessEntityFilter: function(oGrid) {
      const oSelf = this;
      oSelf.dataSource.query.clearAll();
      const oEQuery = EQ.client.getQuery().query.root;

      const source =
            '{{#each filters}}' +
                '{{#if this.enabled}}' +
                    '<div class="filters-grid-hdr">' +
                '{{else}}' +
                    '<div class="filters-grid-hdr disabled">' +
                '{{/if}}' +
                    '{{#ifSecondOrMore @index}}' +
                        '<span>{{this.linktype}} </span>' +
                    '{{/ifSecondOrMore}}' +
                    '<span>{{this.operator}}</span>' +
                    '{{#if this.dateFormat}}' +
                        '<span> {{this.dateFormat}}</span>' +
                    '{{else}}' +
                        '<span> {{this.value}}</span>' +
                    '{{/if}}' +
                '</div>' +
            '{{else}}' +
                ' <span class="empty">None</span>' +
            '{{/each}}';

      let bAtLeastOneColHasMoreThanTwo = false;
      for (const i in oSelf.aGridFilterFields) {
        const aColumnSpecificFilters = oSelf.aGridFilterFields[i];

        if (aColumnSpecificFilters.length == 0)
          $(oGrid.entBox).find(`.${i}-cell`).empty();


        if (aColumnSpecificFilters.length >= 2)
          bAtLeastOneColHasMoreThanTwo = true;


        for (let j = 0; j < aColumnSpecificFilters.length; j++) {
          const oColumnFilter = aColumnSpecificFilters[j];
          const cColID = oColumnFilter.attr;
          const cOperator = oColumnFilter.operator;
          const cColVal = oColumnFilter.value;
          const bEnabled = oColumnFilter.enabled;

          let cLinkType;
          if (oEQuery.linkType)
            cLinkType = oEQuery.linkType.toLowerCase();

          if (oColumnFilter.value.length > 0 && bEnabled) {

            if (cLinkType) {

              if (cLinkType == 'any')
                cLinkType = 'or';
              else
                cLinkType = 'and';
            }
            if (cLinkType && oSelf.cColumnIdCurrentlyFiltered == cColID.substr(cColID.indexOf('.') + 1, cColID.length))
              oSelf.dataSource.query.setSubOperator(cColID.substr(cColID.indexOf('.') + 1, cColID.length), oSelf.aGridColumnOperators[oSelf.cColumnIdCurrentlyFiltered]);

            oSelf.dataSource.query.addCondition(cColID.substr(cColID.indexOf('.') + 1, cColID.length), cOperator, cColVal);
          }
          const template = Handlebars.compile(source);

          const oData = { filters: aColumnSpecificFilters };
          template(oData);
        }
      }

      // check if we should show column filter info
      const $headerEQFilterInfo = $(oSelf.dhx.entBox).find('.xhdr tr:nth-child(4)');
      if (bAtLeastOneColHasMoreThanTwo && $headerEQFilterInfo)
        $headerEQFilterInfo.show();
      else
        $headerEQFilterInfo.hide();


      oSelf._syncFilters();

      // check if changes have been made to query and only trigger the query changes then
      const condClone = $.extend(true, {}, oEQuery);
      for (const i in condClone.conditions)
        delete condClone.conditions[i].blockId;

      if (oSelf.prevSavedQuery[oSelf.cColumnIdCurrentlyFiltered] !== JSON.stringify(condClone)) {
        if (oSelf.popupform) {
          const cFullTextSearch = oSelf.popupform.getItemValue('searchInput');
          if (cFullTextSearch.length > 0)
            oSelf.dataSource.query.setFullTextSearch(cFullTextSearch);
        }

        oSelf._renderMultiSelectStateAfterFill(); // show multiselect checkboxes
        oSelf.dataSource.openQuery();
      }

      oSelf.prevSavedQuery[oSelf.cColumnIdCurrentlyFiltered] = JSON.stringify(condClone);
    },

    /**
     * Reset filters to default and refresh the grid
     * @returns {void}
     * @instance
     * @memberof ak_datagrid
     */
    resetAndRefresh: function() {
      const cDefaultFilter = this.FilterManager.getDefaultFilter();
      if (cDefaultFilter) {
        this.FilterManager.useFilter(cDefaultFilter);
        this.FilterManager.setDynSelectOption(cDefaultFilter);
      } else
        akioma.notification({ type: 'error', text: akioma.tran('filterManager.filterMessages.error.default', { defaultValue: 'There is no default filter!' }) });
    },
    /**
     * Enables the column filters in a grid
     * @returns {void}
     * @instance
     * @memberof ak_datagrid
     */
    enableColumnFilters: function() {
      this.dhx.setHeaderFilterStatus(true);
    },

    /**
     * Disables the column filters in a grid
     * @returns {void}
     * @instance
     * @memberof ak_datagrid
     */
    disableColumnFilters: function() {
      this.dhx.setHeaderFilterStatus(false);
    },

    /**
     * Preloads dynselect data
     * @instance
     * @private
     * @memberof ak_datagrid
     */
    preloadDynselectColumnsData: function() {
      const aDynSelects = [];
      const oGrid = this;

      for (const c in this.childs) {
        const oDyn = this.childs[c];
        const cacheStrategy = oDyn.opt.cacheStrategy;
        if (cacheStrategy === 'client' && oDyn.opt.dynSelect &&
                    oDyn.opt.dynSelect.preLoad == true && (!oDyn.businessEntity == false))
          aDynSelects.push(this.childs[c]);

      }

      const dynSelectFilterEntityNames = [];
      const dynselectEntityNames = [];

      for (const x in aDynSelects) {

        const oDyn = aDynSelects[x];
        if (oDyn.businessEntity !== undefined) {
          // set initial batch size max 500
          oDyn.businessEntity.batchSize = { top: 500 };
        }

        if (oDyn.opt.cacheStrategy === 'client')
          dynSelectFilterEntityNames.push(oDyn);
      }

      for (const y in dynSelectFilterEntityNames) {
        if (dynSelectFilterEntityNames[y].businessEntity)
          dynselectEntityNames.push(dynSelectFilterEntityNames[y].businessEntity.resourceName);
      }

      for (const d in aDynSelects) {
        const oDyn = aDynSelects[d];
        // cleanup if there is a record // no cache Strategy setup
        if (oDyn.opt.cacheStrategy === '') {
          const oPromiseCache = akCacheDb.getFromCache(`${oDyn.parent.opt._ObjectName}_${oDyn.opt._InstanceName}`);
          oPromiseCache.done(() => {
            // remove from cache
            akCacheDb.deleteFromCache(`${oDyn.parent.opt._ObjectName}_${oDyn.opt._InstanceName}`);
            // load no-cache mode
            const def = $.Deferred();
            oDyn._parseLoadedDynselect([], def);
            oGrid.aPromises.push(def);
          });
        }
      }


      // if there are dynselect EntityNames to check for new, and preload data..
      // cleanup if there is a record // no cache Strategy setup
      if (dynselectEntityNames.length > 0) {
        const oTimeLastChanged = akCacheDb.checkForNew(dynselectEntityNames.join(','));
        oTimeLastChanged.done(oResult => {
          // console.log(oResult.dsTimeLastChanged.dsTimeLastChanged.eTimeLastChanged);
          oGrid._dynSelectTimeLastChanged = oResult.dsTimeLastChanged.dsTimeLastChanged.eTimeLastChanged;

          for (const d in aDynSelects) {
            const oDyn = aDynSelects[d];

            if (oDyn.opt.dynSelect && oDyn.opt.dynSelect.preLoad == true && (!oDyn.businessEntity == false))
              continue;

            const oPromiseCache = akCacheDb.getFromCache(`${oGrid.opt._ObjectName}_${oDyn.opt._InstanceName}`);

            // get last timestamp for given EntityName
            const oTimeLastChanged = oGrid._dynSelectTimeLastChanged.find(item => {
              if (item.EntityName == oDyn.opt.EntityName)
                return item;

            });

            // check and display error messages
            if (oTimeLastChanged.ErrorMessage) {
              akioma.notification({
                type: 'error',
                text: `${oTimeLastChanged.ErrorMessage}`
              });
            }

            let resTimestamp = null;

            if (oTimeLastChanged.TimeLastChanged)
              resTimestamp = oTimeLastChanged.TimeLastChanged;

            const oDynSelectLoadPromise = $.Deferred();
            // check and load from akCacheDb - indexed db records
            oPromiseCache.always(res => {
              oDyn._parseLoadedDynselect(res, oDynSelectLoadPromise, resTimestamp);
            });

            oGrid.aPromises.push(oDynSelectLoadPromise);
          }
        });
      }
    },

    /**
     * Method for attaching navigation and focus events on filter fields
     * @instance
     * @memberOf ak_grid
     * @return {void}
     */
    attachNavigationEvents: function() {
      const oSelf = this;

      // Handles tab navigation in header
      $(oSelf.dhx.hdrBox).find('input,.select2-selection,.logicalFilter').each(function() {
        const oField = this;
        const oCellIndex = $(oField).closest('td')[0].cellIndex;
        const oColumnId = oSelf.dhx.getColumnId(oCellIndex);
        const iIndex = oSelf._initColIds.indexOf(oColumnId);
        const oColumn = oSelf.childs[iIndex];
        const oMouse = Mousetrap(this);
        oColumn.dhx = this;
        this.column = oColumn;

        oMouse.bind('tab', e => {
          e.preventDefault();
          const iIndex = $(oField).closest('td')[0].cellIndex;
          const iNextIndex = oSelf.getIndex_NextActiveFilter(iIndex);
          oSelf.setHeaderFieldFocus(iNextIndex);
          e.stopImmediatePropagation();
          e.stopPropagation();
        });
        oMouse.bind('shift+tab', e => {
          e.preventDefault();
          const iIndex = $(oField).closest('td')[0].cellIndex;
          const iPrevIndex = oSelf.getIndex_PrevActiveFilter(iIndex);
          if (iPrevIndex == null) {
            const oMouseTrap = oSelf.getAncestor('panelset').oMouseTrap || oSelf.getAncestor('frame').oMouseTrap || oSelf.getAncestor('window').oMouseTrap;
            if (oMouseTrap)
              oMouseTrap.trigger('alt+left');
          } else
            oSelf.setHeaderFieldFocus(iPrevIndex);
          e.stopImmediatePropagation();
          e.stopPropagation();
        });
      });

      $(oSelf.dhx.hdr).find('input,.akGridDynSelect,.logicalFilter').focusin(function(e) {
        try {
          let oField = $(this);
          if ($(oField).hasClass('akGridDynSelect'))
            oField = $(oField).find('.select2-selection');

          const oColumn = oField[0].column;

          oSelf.oFocused = e.target;
          const iIndex = $(oField).closest('td')[0].cellIndex;

          if (oColumn.opt.colType == 'ron' || oColumn.opt.colType == 'edn')
            akioma.numeric.preventInput(oColumn, oField);

          const oParentPanel = oSelf.getAncestor('panel');
          if (oParentPanel != null)
            oParentPanel.setActivePanelState();
          oParentPanel.oFocus.oActiveField = iIndex;
          oParentPanel.oFocus.bRowMode = false;

          // Bugfix for multiple selection dynSelect (for underline)
          if ($(e.target).hasClass('select2-search__field'))
            $(e.target).closest('span.select2-selection--multiple').addClass('dynSelectFocus');
        } catch (e) {
          akioma.log.error(e);
        }
      });

      $(oSelf.dhx.hdr).find('input').focusout(function() {
        try {
          const oColumn = this.column;
          switch (oColumn.opt.dataType) {
            case 'date': {
              const cValue = $(this).val();
              const bValid = oColumn._validateDate(cValue, true);
              oColumn.bFilterError = (!bValid) ? true : false;
              break;
            }
            default:
              break;
          }
        } catch (e) {
          akioma.log.error(e);
        }
      });

      $(oSelf.dhx.hdr).find('.akGridDynSelect').focusout(e => {
        // Bugfix for multiple selection dynSelect (for underline)
        if ($(e.target).hasClass('select2-search__field'))
          $(e.target).closest('.dynSelectFocus').removeClass('dynSelectFocus');
      });
    },

    /**
     * Method for toggling the multiline in grid, saved in localstorage
     * @instance
     * @memberOf ak_grid
     * @return {void}
     */
    toggleMultiline: function(bMultiline) {
      const oSelf = this;

      if (bMultiline)
        oSelf.bMultiline = true;
      else
        oSelf.bMultiline = !oSelf.bMultiline;


      const oTable = $(oSelf.dhx.obj);
      if (oSelf.bMultiline == true)
        oTable.removeClass('row20px');
      else
        oTable.addClass('row20px');

      UserProfile.saveGridLocalProfileData(oSelf, akioma.getGridColumnSettings(oSelf));

    },

    syncSource: function() {
      const oSelf = this;
      const oSource = oSelf.dataSource.dhx;
      const oGrid = oSelf.dhx;
      oGrid.init();
      oGrid.BE = oSource;
      oGrid.sync(oSource);
      oSelf.attachNavigationEvents();
    },

    attachGridToParent: function(parent) {
      const oSelf = this;
      const oParent = parent;

      // try {
      // set akId
      this.akId = ((this.opt.akId) ? this.opt.akId : this.opt.name);
      // set title in panel
      this.opt.title = akioma.tran(`${this.opt.name}._title`, { defaultValue: this.opt.title });
      this.addObjectDebugInfo();

      // set floatingActionButton in panel
      if (this.opt.floatingActionButton) {
        const oFloatingActionTarget = this;
        this.parent.setOption('floatingActionButton', this.opt.floatingActionButton, oFloatingActionTarget);
      }

      let oGrid;
      if ($.inArray(this.parent.view, this.formViews) !== -1)
        oGrid = new dhtmlXGridObject(parent);
      else {
        // -> bind grid to layout
        if (oParent.getAttachedObject())
          oParent.showView('alternative');
        oGrid = oParent.attachGrid();
      }

      oGrid.setImagePath(oDhx.imagePath);
      oGrid.setSkin(oDhx.skin);

      oGrid.i18n.decimal_separator = ',';
      oGrid.i18n.group_separator = '.';
      oGrid.comboValues = {};
      oGrid.calFormats = {};

      oGrid.i18n.paging = {
        results: 'Datensätze',
        records: 'Datensätze von ',
        to: ' bis ',
        page: 'Seite ',
        perpage: 'Sätze pro Seite',
        first: 'Zur ersten Seite',
        previous: 'vorherige Seite',
        found: 'Datensätze gefunden',
        next: 'Nächste Seite',
        last: 'Zur letzten Seite',
        of: ' von ',
        notfound: 'Keine Datensätze gefunden'
      };

      // get column definitions
      const aCells = [],
        aHead = [],
        aHeadAlign = [],
        aFilter = [],
        aEQFilter = [],
        aWidth = [],
        aAlign = [],
        aDataTypes = [],
        aColTypes = [],
        aColUISort = [],
        aColSort = [],
        aFolder = [],
        aExtendedFormat = [],
        aAkId = [];
      oSelf.aMultiSelectFilters = [];
      oSelf.aDynSelectFilters = [];
      oSelf.aDateRangeFilters = [];
      oSelf.iFilterError = 0;

      let oCol;
      for (const i in this.options.sub) {
        oCol = this.options.sub[i];
        if (oCol.view == 'datagridcol2') {

          oCol.att.label = akioma.tran(`${oSelf.opt.name}.${oCol.att.dataField}`, { defaultValue: oCol.att.label });

          if (app.sessionData.objectNamesInTitles)
            oCol.att.label = `${oCol.att.label} (${oCol.att.dataField})`;


          if (oCol.att.align == 'default') {
            switch (oCol.att.dataType) {
              case 'date':
              case 'integer':
              case 'decimal':
                oCol.att.align = 'right';
                break;
              case 'logical':
                oCol.att.align = 'center';
                break;
              default:
                oCol.att.align = 'left';
                break;
            }
          }

          if (oCol.att.colType == 'ch') {
            const aLogicalTypes = [ 'empty', 'true', 'false', 'null', 'filterTrue', 'filterFalse' ];
            const extendedFormat = {
              'empty': { icon: 'fal fa-square' },
              'true': { icon: 'fal fa-check-square' },
              'false': { icon: 'fal fa-square' },
              'null': { icon: 'fal fa-question-square' },
              'filterTrue': { icon: 'fal fa-check-square' },
              'filterFalse': { icon: 'fal fa-times-square' }
            };

            if (oCol.att.extendedFormat) {
              const customIcons = oCol.att.extendedFormat.split('|');
              for (let iCount = 0; iCount < aLogicalTypes.length; iCount++) {
                if (customIcons[iCount]) {
                  const iconObject = akioma.icons.getIconType(customIcons[iCount]);
                  extendedFormat[aLogicalTypes[iCount]] = iconObject;
                }
              }
            }
            aExtendedFormat[i] = extendedFormat;
          }

          if (oCol.att.colType == 'dynselect' && oSelf.opt.enabled == false)
            oCol.att.colType = 'ro';


          aHead.push((oCol.att.label) ? oCol.att.label : '');
          aHeadAlign.push((oCol.att.align) ? `text-align: ${oCol.att.align}` : '');
          if (oCol.att.filterAttributes)
            aFilter.push(oCol.att.filterAttributes.filter);
          else if (oCol.att.filter)
            aFilter.push(oCol.att.filter);
          else aFilter.push('');

          aEQFilter.push(`<div class="${oCol.att.dataField.toLowerCase()}-cell"></div>`);

          aWidth.push((oCol.att.width) ? parseInt(oCol.att.width) : '');
          aAlign.push((oCol.att.align) ? oCol.att.align : '');
          aColTypes.push((oCol.att.colType) ? oCol.att.colType : '');
          aColSort.push((oCol.att.sortable) ? 'server' : 'na');
          aColUISort.push(oCol.att.sortableType);
          aCells.push((oCol.att.dataField) ? oCol.att.dataField : '');
          aDataTypes.push((oCol.att.dataType) ? oCol.att.dataType : 'character');
          aFolder.push(oCol.att.folderWindow);
          aAkId.push((oCol.att.akId) ? oCol.att.akId : oCol.att.fieldName);


          switch (oCol.att.dataType) {
            case 'date':
              oGrid.calFormats[oCol.att.dataField] = '%Y-%m-%d';
              break;
            case 'datetime':
            case 'datetime-tz':
              oGrid.calFormats[oCol.att.dataField] = '%Y-%m-%dT%H:%i:%s.%u%P';
              break;
          }

          if (oCol.att.colType == 'clist') {
            oGrid.registerCList(i, {
              text: oCol.att.comboText,
              value: oCol.att.comboValues
            });
          }
        }
      }

      oSelf.aAkId = aAkId;
      oSelf.aColSort = aColSort;
      oSelf.aFilter = aFilter;
      oSelf._initColIds = aCells;
      oSelf.bMultiline = oSelf.opt.Multiline || false;
      oSelf.flagColumnValuesF = []; // this is a flag for filter in column header, will be used to refresh using context menu
      oSelf.bFirstGridLoad = true;
      oSelf.aRefreshColIDs = []; // to apply refresh context menu on column combo dyn
      oSelf.aExtendedFormat = aExtendedFormat;

      oGrid.setHeader(aHead, null, aHeadAlign);

      if (oSelf.opt.showGridFilter != 'none')
        oGrid.attachHeader(aFilter);

      oGrid.setInitWidths(aWidth.join(','));
      oGrid.setColAlign(aAlign.join(','));
      oGrid.setColTypes(aColTypes.join(','));


      if (oSelf.opt.filterMode !== 'client')
        oGrid.setColSorting(aColSort.join(','));
      else
        oGrid.setColSorting(aColUISort.join(','));
      oGrid.setColumnIds(aCells.join(','));
      oGrid.enableKeyboardSupport(true);

      oGrid.setDateFormat('%Y-%m-%dT%H:%i:%s.%u%P');
      oGrid.enableAutoHeight(false);
      oGrid.enableAutoWidth(false);
      oGrid.enableDragAndDrop(true);
      oGrid.enableColumnMove(true);
      oGrid.enableHeaderMenu(true);
      oGrid.enableAccessKeyMap();

      this.aGridFilterFields = []; // this array keeps the filters
      this.aSortPhraes = []; // this array keeps the sortPhrases

      oGrid.enableEditEvents(false, true, true); /* set editmode */
      oGrid.enableLightMouseNavigation(false);
      oGrid.enableEditTabOnly(true);
      oGrid.setSerializationLevel(true, false, false, true, true, false);

      oGrid.attachEvent('onMouseOver', (id, ind) => oSelf.mouseOver(id, ind));
      oGrid.attachEvent('onEditCell', (iStage, rId, cIndex, nValue, oValue) => oSelf.editCell(iStage, rId, cIndex, nValue, oValue));
      oGrid.attachEvent('onCheck', (rId, cIndex, nValue) => oSelf.cellChanged(rId, cIndex, nValue));
      oGrid.attachEvent('onDragIn', (cSrcId, cTrgId, sGrid, tGrid) => oSelf.dragIn(cSrcId, cTrgId, sGrid, tGrid));
      oGrid.attachEvent('onDragOut', (cSrcId, cTrgId, sGrid, tGrid) => oSelf.dragOut(cSrcId, cTrgId, sGrid, tGrid));

      if (oSelf.opt.filterMode !== 'client')
        oGrid.attachEvent('onFilterStart', (aFiltIdx, aFiltVal) => oSelf.filterStart(aFiltIdx, aFiltVal));
      oGrid.attachEvent('onRowSelectGrid', (rowId, colNr) => oSelf.rowSelect(rowId, colNr));
      oGrid.attachEvent('onBeforeSelect', (newRow, oldRowId, newColIndex) => oSelf.rowBeforeSelect(newRow, oldRowId, newColIndex));

      oGrid.attachEvent('onCollectValues', colNr => oSelf.collectValues(colNr));
      oGrid.attachEvent('onBeforePageChanged', (index, count) => oSelf.pageChanged('before', index, count));
      oGrid.attachEvent('onPageChanged', (index, first, last) => oSelf.pageChanged('after', index, first, last));
      if (oSelf.opt.filterMode !== 'client') {
        oGrid.attachEvent('onBeforeSorting', (col, type, direction) => {
          const cColID = oSelf.dhx.getColumnId(col);

          if (oSelf.dataSource.aNonSortableFields.indexOf(cColID) == -1)
            return oSelf.sorting(col, type, direction);
        });
      }

      let cPrevColSortID = '';
      oSelf.bFlagMoveC = true;
      oGrid.attachEvent('onBeforeCMove', () => {
        oSelf.bFlagMoveC = true;
        if (oSelf.oSortState)
          cPrevColSortID = oGrid.getColumnId(oSelf.oSortState.iCol);

        return true;
      });

      oGrid.attachEvent('onAfterCMove', () => {
        // when moving grid column, reset sort state to correct col index
        if (oSelf.oSortState != undefined && oSelf.bFlagMoveC) {
          const sortInd = oGrid.getColIndexById(cPrevColSortID);
          if (sortInd != undefined) {

            oSelf.oSortState.iCol = sortInd;
            oGrid.setSortImgState(oSelf.oSortState.state, oSelf.oSortState.iCol, oSelf.oSortState.order);
          }
        }
        UserProfile.saveGridLocalProfileData(oSelf, akioma.getGridColumnSettings(oSelf));
        oSelf.bFlagMoveC = false;
      });
      oGrid.attachEvent('onResizeEnd', () => {
        UserProfile.saveGridLocalProfileData(oSelf, akioma.getGridColumnSettings(oSelf));
      });

      // DBLCLICK
      oGrid.attachEvent('onRowDblClicked', iRow => {
        /**
         * Client side code executed when a row is chosen (selected by the user to perform an action on it), e.g. by double click
         * @event ak_datagrid#EventRowChosen
         * @type {object}
         */
        if (oSelf.dhx) {
          clearTimeout(oSelf.dhx.onRowSelectGridTime);
          oSelf.dhx.onRowSelectGridTime = null;
        }

        if (oSelf.dataSource.view.toLowerCase() !== 'businessentity2')
          oSelf.dataSource.setIndex(iRow);
        else
          oSelf.dataSource.setIndex(iRow, oSelf.opt.entityName);

        app.controller.callAkiomaCode(oSelf, oSelf.opt.onRowChosen);

        return true;
      });

      oGrid.attachEvent('onKeyPress', (code, cFlag, sFlag) => {
        if (code == 83 && cFlag && sFlag) {
          if (!securityIsRestricted('CanUseDeveloperTools')) {
            akioma.message({
              type: 'confirm',
              title: 'Set column-order/width',
              text: 'Are you sure you want to Change the repository-settings for this grid? This will affect Default for ALL users!',
              buttonText: 'Yes',
              callback: function(result) {
                if (result)
                  oSelf.saveColumnsOrder();

              }
            });
          }

          return false;
        }
        // on enter trigger onRowChosen event
        if (code == 13) {
          /**
           * Client side code executed when a row is chosen (selected by the user to perform an action on it), e.g. by pressing enter
           * @event ak_datagrid#EventRowChosen
           * @type {object}
           */
          if (oSelf.opt.onRowChosen)
            app.controller.callAkiomaCode(oSelf, oSelf.opt.onRowChosen);
        }
        return true;
      });

      oGrid.attachEvent('onSelectStateChanged', function(cRows) {
        // save Selection if required
        let bBE2 = false;
        if (oSelf.dataSource) {
          if (oSelf.dataSource.view.toLowerCase() == 'businessentity2')
            bBE2 = true;
        }

        if (oSelf.dataSource && oSelf.opt.keepSelection && !bBE2) {
          if (cRows == null) {
            this.oKeepSelectionData = null;
            return;
          }

          const aRowIds = cRows.split(',');

          const aVals = [];
          let value = '';

          for (const r in aRowIds) {
            const cFieldVal = oSelf.dataSource.getFieldFromId(oSelf.dataSource.opt.identifier, aRowIds[r]);
            aVals.push(cFieldVal);
          }
          value = aVals.join(',');

          if (value != '')
            oSelf.setKeepSelection({ identifier: oSelf.dataSource.opt.identifier, value: value });

        }
      });

      oGrid.akElm = this;

      oSelf._registerFilterEnterKeypress();

      $.extend(this, {
        dhx: oGrid,
        cols: {
          cells: aCells,
          dataTypes: aDataTypes,
          folder: aFolder,
          width: aWidth
        },
        actCol: -1,
        security: {},
        filter: {},
        sort: {}
      });

    },

    gridBatchHandler() {
      let bScrollingHorz = false;

      if (this.pauseGridBatchHandler == true) {
        delete this.pauseGridBatchHandler;
        return false;
      }

      // check if batching for more records is allowed, if not block it
      if (!this.dataSource.hasRecordsToBatch)
        return false;


      if (this.dhx.objBox.scrollLeft !== this.lastScrollLeft)
        bScrollingHorz = true;

      const scrollBottomReached = this.hasReachedScrollBottom();
      if (scrollBottomReached && !this.dataSource._pendingrequest && !bScrollingHorz && this.dataSource && this.dataSource.batchMode) {
        const iSkip = this.dhx.getRowsNum();
        const iBatchSize = parseInt(this.iBatchSize);

        // var iSkip = Math.floor(iStartIndex / iNoOfRecrsToBatch);
        this.dataSource.setBatch(iSkip, iBatchSize);
        this.dataSource.setLastIndex(iSkip);

        // reached end request next batch
        this._renderMultiSelectStateAfterFill();

        this.dataSource.openQuery({
          skipClear: true,
          skipProgress: true,
          mergeMode: progress.data.JSDO.MODE_APPEND
        });
      }

      this.lastScrollLeft = this.dhx.objBox.scrollLeft;
    },

    /**
     * Method for stopping the batching
     * @instance
     * @memberOf ak_datagrid2
     */
    stopBatching: function() {
      this.getAncestor('panel').loadingBatches = false;
      this.dataSource.hasRecordsToBatch = false;
      this.dataSource.jsdo._fillMergeMode = undefined;
    },

    // this update filters stored per column skipped when adding a new filter
    fnAfterChangedCallback: function(oSelf) {
      const oGrid = oSelf.dhx;

      // sync values from EQ column popup for multiselect combo
      if (oSelf._checkIfMultiSelectFilter(oSelf.cColumnIdCurrentlyFiltered) && $(akioma.gridFilterFieldsPopup.p).css('display') == 'block' && !oSelf.bSkipValueUpdates) {
        const oHdrElm = oSelf.dhx.getFilterElement(oSelf._initColIds.indexOf(oSelf.cColumnIdCurrentlyFiltered));
        if (oSelf.aGridFilterFields[oSelf.cColumnIdCurrentlyFiltered] != undefined) {
          const EQconditions = EQ.client.getQuery().query.root.conditions;

          const aResVals = [];
          const aResKeys = [];
          const aResComboRes = [];
          for (const q in EQconditions) {

            aResVals.push(EQconditions[q].expressions[1].value);
            aResKeys.push(EQconditions[q].expressions[1].text);
            aResComboRes.push(EQconditions[q].expressions[1].text.split(' / ')[1]);
          }
          oSelf.filter[oSelf.cColumnIdCurrentlyFiltered] = aResVals;

          oHdrElm.cont.value = aResVals.join('|');
          $(oHdrElm.DOMelem_input).attr('placeholder', aResComboRes.join(', '));

          if (oSelf.aMultiSelectStatus.aSelectedValuesKeys && oSelf.aMultiSelectStatus.aSelectedValuesKeys[oSelf.cColumnIdCurrentlyFiltered]) {
            oSelf.aMultiSelectStatus.aSelectedComboResult[oSelf.cColumnIdCurrentlyFiltered] = aResComboRes;
            oSelf.aMultiSelectStatus.aSelectedValuesKeys[oSelf.cColumnIdCurrentlyFiltered] = aResKeys;
          }

          if (oSelf.aMultiSelectStatus.aSelectedValues && oSelf.aMultiSelectStatus.aSelectedValues[oSelf.cColumnIdCurrentlyFiltered])
            oSelf.aMultiSelectStatus.aSelectedValues[oSelf.cColumnIdCurrentlyFiltered] = aResVals;
        }
      }

      if (!oSelf.bSkipValueUpdates) {
        // for each condition
        oSelf.dataSource.query.clearAll();
        const oEQuery = EQ.client.getQuery().query.root;
        const aConditions = oEQuery.conditions;
        let cLinkType = oEQuery.linkType.toLowerCase();

        if (cLinkType == 'any')
          cLinkType = 'or';
        else
          cLinkType = 'and';

        if (oSelf.cColumnIdCurrentlyFiltered != undefined) {
          const iColIndex = oSelf._initColIds.indexOf(oSelf.cColumnIdCurrentlyFiltered);
          oSelf.aGridFilterFields[oSelf.cColumnIdCurrentlyFiltered] = [];
          oSelf.aGridColumnOperators[oSelf.cColumnIdCurrentlyFiltered] = cLinkType || 'Any';
          for (let i = 0; i < aConditions.length; i++) {

            if (aConditions[i].expressions.length == 2) {
              let cColVal = aConditions[i].expressions[1].value;
              const cColID = aConditions[i].expressions[0].id;
              const cOperator = aConditions[i].operatorID;
              try {
                const cColDataType = oSelf.getEQDataType(cColID);
                let oColDate;

                let cDateFormated;
                if (cColDataType == 'Date') {
                  oColDate = EQ.client.getQuery().model.getDateOrMacroDateValue(cColVal);
                  cColVal = moment(oColDate).format('DD-MM-YYYY');
                  cDateFormated = moment(oColDate).format('YYYY.MM.DD');
                } else if (cColDataType == 'Time') {
                  oColDate = EQ.client.getQuery().model.getTimeOrMacroTimeValue(cColVal);
                  cColVal = moment(oColDate).format('DD-MM-YYYY HH:mm');
                  cDateFormated = moment(oColDate).format('YYYY.MM.DD HH:mm');
                }

                // only for the first condition do we display the value in the column input field also
                // this update grid header filter input value
                if (i == 0 && cColVal.length > 0) {
                  const oFilterElm = oSelf.dhx.getFilterElement(iColIndex);
                  let cNewVal = cColVal;
                  if (cOperator == 'startswith')
                    cNewVal = `${cNewVal}*`;
                  else if (cOperator == 'endswith')
                    cNewVal = `*${cNewVal}`;
                  else if (cOperator == 'contains')
                    cNewVal = `*${cNewVal}*`;

                  oFilterElm.value = cNewVal;

                  if (!aConditions[i].enabled)
                    $(oFilterElm).attr('disabled', 'disabled');
                  else
                    $(oFilterElm).removeAttr('disabled');
                }
                oSelf.aGridFilterFields[oSelf.cColumnIdCurrentlyFiltered][i] = { attr: cColID, operator: cOperator, value: new String(cColVal), enabled: aConditions[i].enabled, linktype: cLinkType, dateFormat: cDateFormated };
              } catch (e) {
                akioma.log.error(e);
              }
            }
          }

          // if no conditions clear grid header filters
          if (aConditions.length == 0) {
            const oFilterField = oGrid.getFilterElement(oGrid.getColIndexById(oSelf.cColumnIdCurrentlyFiltered));
            $(oFilterField).val('');
            $(oFilterField).removeAttr('disabled');
            oSelf.filter[oSelf.cColumnIdCurrentlyFiltered] = '';
          }

          oSelf.updateBusinessEntityFilter(oGrid);
        }
      }

    },

    // gets EQ dataType of a field
    getEQDataType: function(cField) {
      const aEQAttrs = EQ.client.getQuery().model.model.rootEntity.subEntities[0].attributes;
      for (let i = 0; i < aEQAttrs.length; i++) {
        if (aEQAttrs[i].id == cField)
          return aEQAttrs[i].dataType;
      }
    },

    // deprecated
    toggleGridFilterHead: function() {
      const oSelf = this;
      oSelf.bHdrVisible = !oSelf.bHdrVisible;

      if (oSelf.bHdrVisible) {
        oSelf.gridHdrFilterDOM.show();

        const $hdr = $(oSelf.dhx.entBox).find('.xhdr');
        $('<div id="grid-eq-header-panels" style="height:40px;width: 100%;float:left;clear:both;"></div><i class="fa fa-icon"></i>').insertBefore($hdr);

        const oLayout = new dhtmlXLayoutObject({
          parent: 'grid-eq-header-panels',
          pattern: '1C'
        });

        oLayout.cells('a').hideHeader();
        oSelf.buildGridSearchInput(oLayout.cells('a'));
      } else {
        oSelf.gridHdrFilterDOM.hide();
        $('#grid-eq-filter, #grid-eq-header-panels').remove();

        oSelf.bGridFilterOpened = false;
        akioma.gridFilterFieldsPopup.unload();
        delete oSelf.queryWindow;
      }

      oSelf.dhx.hdr.parentElement.style.height = oSelf.dhx.hdr.style.height; // update header height
    },

    calculateWidth_inForm: function(oSelf, oParent) {
      $(oParent).closest('.dhxform_control').css('position', 'relative');
      // fix get parent of cell width and set that as  width
      let iWidth = 800;
      try {
        iWidth = parseInt(oSelf.getAncestor('panelset').dhx.cells(oSelf.getAncestor('panel').opt.layout).getWidth() - 50);
      } catch (e) {
        akioma.log.error('Could not get parent cell for size of grid2 in form');
      }
      $(oParent).closest('.dhxform_control').width(iWidth);
      return iWidth;
    },

    buildGridSearchInput: function(cell) {
      const oSelf = this;

      if (oSelf.opt.resultListMenuCode)
        oSelf.createMenuLookup(oSelf.opt.resultListMenuCode);

      const myForm = cell.attachForm([{ type: 'input', name: 'searchInput', label: '', value: '' }]);

      oSelf.gridSearchInputPopup = myPop;

      myForm.attachEvent('onFocus', () => { });

      oSelf.popupform = myForm;
      const oGrid = oSelf.dhx;
      oSelf.dhx.attachEvent('onSetSizes', () => {
        $(oGrid.entBox).find('.objbox').height($(oGrid.entBox).find('.objbox').height() - 43);

        if ($.inArray(oSelf.parent.view, oSelf.formViews) !== -1) {
          const formParent = akioma.getForm(oSelf);
          const oParent = formParent.dhx.getContainer(`formGrid_${oSelf.opt.name}`);

          const iWidth = oSelf.calculateWidth_inForm(oSelf, oParent);
          $(oGrid.entBox).width(iWidth);
        }
      });

      myForm.attachEvent('onKeyUp', () => { });

      let inputTimeout = null;

      (function(oSelf, myForm) {
        myForm.attachEvent('onInputChange', () => {
          const text = myForm.getItemValue('searchInput');
          if (inputTimeout)
            clearTimeout(inputTimeout);
          inputTimeout = setTimeout(() => {
            oSelf.dataSource.setNamedQueryParam('AutoCompleteSearch', 'SearchKey', text, 'character');
            oSelf.dataSource.openQuery({});
          }, 400);
        });
      })(oSelf, myForm);
    },

    // deprecated
    autoCompleteSearchPopup: function(cInput) {
      const oGrid = this.dhx;
      const oSelf = this;
      if (cInput.length > 0) {
        oSelf.getAncestor('panel').dhx.progressOn();
        akioma.invokeServerTask({
          name: 'Akioma.Swat.AutoCompleteSearchBT',
          methodName: 'GetSpecificMatchingResultSet',
          paramObj: {
            plcParameter: {
              name: 'Akioma.Swat.AutoCompleteSearchBT',
              key: cInput,
              pos: ''
            }
          }
        }).done(odata => {
          function keysToLowerCase(obj) {
            const newObj = {};
            for (const i in obj) {
              if (Object.prototype.hasOwnProperty.call(obj, i))
                newObj[i.toLocaleLowerCase()] = obj[i];
            }
            return newObj;
          }

          oGrid.clearAll();
          for (const i in odata.dsStamm.dsStamm.eStamm) {
            odata.dsStamm.dsStamm.eStamm[i].id = i;
            odata.dsStamm.dsStamm.eStamm[i] = keysToLowerCase(odata.dsStamm.dsStamm.eStamm[i]);
          }
          oSelf.dataSource.dhx.parse({ data: odata.dsStamm.dsStamm.eStamm });
          oSelf.getAncestor('panel').dhx.progressOff();
        });
      } else
        oSelf.dataSource.openQuery();

    },

    // deprecated
    autoCompleteSearch: function(cInput) {
      const serviceURI = window.location.origin,
        jsdoSettings = {
          serviceURI: serviceURI,
          catalogURIs: `${serviceURI}/web/Catalog/Akioma.Swat.AutoCompleteSearchBT`,
          authenticationModel: progress.data.Session.AUTH_TYPE_FORM
        },
        oSelf = this;

      const onAfterAddCatalog = res => {
        // check for error type object
        if (akioma.isObjOfTypeError(res)) {
          akioma.log.error(res);
          return;
        }

        const dsTasks = new progress.data.JSDO ({ name: 'Akioma.Swat.AutoCompleteSearchBT' });

        // call param with input search term
        const oParameter = {
          plcParameter: {
            name: 'omniSearch',
            key: cInput,
            pos: ''
          }
        };

        // Perform the asynchronous call
        // true = asynchronously
        const call = dsTasks.GetMatchingResultSet (oParameter, true).deferred;
        call.then ((method, jsdo, success) => {
          // Retrieve JavaScript object from ABL serializable parameter
          const oOutput = success.response;
          const templateResults = Handlebars.compile(akioma.handlebarsTemplates.autocompleteSearchBT.templateResults);
          const oData = { results: oOutput.dsAutoCompleteSearch.autoCompleteSearch.searchResult };

          let result = templateResults(oData);
          if (oSelf.menuLookup)
            result += oSelf.menuLookup.result;
          oSelf.gridSearchInputPopup.attachHTML(result);

          $('.footer-add').on('click', function() {
            const id = $(this).attr('id');
            oSelf.menuLookup.applyAction(id, oSelf);
            oSelf.gridSearchInputPopup.hide();
            $('#grid-eq-filter, #grid-eq-header-panels').remove();
            return true;
          });

          const oWindow = oSelf.dynObject.container.controller;
          const mousetrap = oWindow.oMouseTrap;

          let $current;
          $('.searchItem:first-child').addClass('selected');
          $('.searchItem').mouseover(function() {
            $('.searchItem').removeClass('selected');
            $current = $(this);
            $current.addClass('selected');
          });
          $('.searchItem').mouseout(() => {
            $current.removeClass('selected');
          });

          mousetrap.bind([ 'up', 'down' ], event => {
            const $items = $('.searchItem'),
              $selected = $items.filter('.selected');

            event = event || window.event;
            switch (event.keyCode) {
              case 38: // up
                if (!$selected.length || $selected.is(':first-child'))
                  $current = $items.last();
                else
                  $current = $selected.prev();
                break;
              case 40: // down
                if (!$selected.length || $selected.is(':last-child'))
                  $current = $items.eq(0);
                else
                  $current = $selected.next();
                break;
            }

            const elemPos = isInViewport('.results-container', $current);
            const scrol_pos = $('.results-container').scrollTop();
            switch (elemPos) {
              case 0:
                break;
              case 1:
                $('.results-container').scrollTop(scrol_pos - 150);
                break;
              case -1:
                $('.results-container').scrollTop(scrol_pos + 150);
                break;
            }

            $items.removeClass('selected');
            $current.addClass('selected');
          });

          mousetrap.bind('enter', () => {
            oSelf.popupform.setItemValue('searchInput', $current.context.textContent);
          });

        }); // call.done

        function isInViewport(container, element) {
          // returns
          //      0 - when element is in viewport
          //      1 - when element is up
          //     -1 - when element is down

          const win = $(container);

          const viewport = {
            top: win.scrollTop(),
            left: win.scrollLeft()
          };
          viewport.right = viewport.left + win.width();
          viewport.bottom = viewport.top + win.height();

          const bounds = element.offset();
          bounds.right = bounds.left + element.outerWidth();
          bounds.bottom = bounds.top + element.outerHeight();

          if (viewport.bottom < bounds.top)
            return -1;
          if (viewport.top > bounds.bottom)
            return 1;
          if (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom))
            return 0;
        }
      }; // addCatalog.done

      // add catalog first
      akioma.restSession.addCatalog(jsdoSettings.catalogURIs).then(res => {
        onAfterAddCatalog(res);
      }).catch(res => {
        onAfterAddCatalog(res);
      });
      // after addCatalog perform a business task method call
    },

    endConstruct: function() {
      const oSelf = this,
        oGrid = this.dhx;

      // apply context menu on combo dyns
      for (const i in oSelf.aRefreshColIDs) {
        (function createMenu(i) {
          const cColID = oSelf.aRefreshColIDs[i].dataField,
            iColNr = oSelf.aRefreshColIDs[i].iColNr,
            cName = oSelf.aRefreshColIDs[i].cName,
            cObjName = oSelf.aRefreshColIDs[i].cObjName,
            iColIndex = oGrid.getColIndexById(cColID),
            filterElm = oGrid.getFilterElement(iColIndex);

          // add combobox refresh entries menu
          const oReloadMenu = new dhtmlXMenuObject({
            parent: $(filterElm).parent()[0],
            context: true
          });

          oReloadMenu.addNewChild(oReloadMenu.topId, 1, 'clear', 'Clear cache', false);
          oReloadMenu.addNewChild(oReloadMenu.topId, 2, 'reload', 'Refresh', false);
          oReloadMenu.attachEvent('onClick', id => {
            if (id == 'clear') {
              // remove values from storage
              localStorage && localStorage.removeItem(`ak_combogrid_${cObjName}`);
            } else if (id == 'reload') {
              localStorage.removeItem(`ak_combogrid_${cObjName}`);
              oSelf._loadFilterDynComboEntries(cName, cObjName, iColNr);
            }
          });
        })(i);

      }
      oSelf.aRefreshColIDs = [];

      const oNewKey = akioma.aLoadedTGF[`ak_${oSelf.view}_${oSelf.opt.name}`];
      if (oNewKey == undefined)
        akioma.aLoadedTGF[`ak_${oSelf.view}_${oSelf.opt.name}`] = [];
      akioma.aLoadedTGF[`ak_${oSelf.view}_${oSelf.opt.name}`].push(oSelf);
    },

    // calls businessTask to save column order
    saveColumnsOrder: function() {
      const oSelf = this;
      const aColIds = oSelf._initColIds;

      const oParam = {
        plcParameter: { Value: oSelf.opt._ObjectName },
        dsGridColumnOrderMaintenance: { eGridColumnOrderMaintenance: [] }
      };

      const aGridColumns = oParam.dsGridColumnOrderMaintenance.eGridColumnOrderMaintenance;

      let iExtraRow = 0;

      if (aColIds.indexOf('rowmod') > -1)
        iExtraRow = 1;

      for (const i in aColIds) {
        if (aColIds[i].toLowerCase() != 'rowmod') {
          aGridColumns.push({
            ColumnName: aColIds[i],
            ColumnOrder: oSelf.dhx.getColIndexById(aColIds[i]) + 1 - iExtraRow,
            ColumnWidth: oSelf.dhx.getColWidth(oSelf.dhx.getColIndexById(aColIds[i]))
          });
        }
      }

      akioma.invokeServerTask({
        name: 'Akioma.Swat.Repository.RuntimeMaintenance',
        methodName: 'SetGridColumnProperties',
        paramObj: oParam
      }).done(() => {
        akioma.notification({ type: 'info', text: `New columns order for "${oSelf.opt.name}" has been saved successfully!` });
      });
    },

    // calls dataSource BE count method for returning the number of records in businessEntity
    countRecords: function() {
      const oSelf = this;
      try {
        const promiseCount = oSelf.dataSource.jsdo.count({ plcParameter: { akQuery: oSelf.dataSource.urlQuery.akQuery } }, true).deferred.promise();
        promiseCount.then(({ request }) => {
          const iNoRec = request.response.piNumRecs;
          if (parseInt(iNoRec) == 9999)
            akioma.notification({ type: 'info', text: 'Das Zählen dauerte zu lange und wurde abgebrochen.' });
          else
            akioma.notification({ type: 'info', text: `${iNoRec} records in "${oSelf.opt.title}".` });
        });
      } catch (e) {
        if (oSelf.dataSource.opt.name)
          akioma.notification({ type: 'error', text: `Could not return number of records for ${oSelf.dataSource.opt.name}` });
        else
          akioma.log.error('Error returning number of records for BusinessEntity', e);
      }
    },

    // validate grid
    validate: function() {
      const changedRows = this.dhx.getChangedRows(false).split(',');
      if (changedRows[0] === '') return true;

      let valid = true;

      for (let i = 0; i < changedRows.length; i++) {
        for (let j = 0; j < this.dhx.columnIds.length; j++)
          if (this.validateCell(j, changedRows[i]) === false) valid = false;
      }
      return valid;
    },

    /* validatesCells */
    validateCell: function(iCol, rId, nValue) {
      const oCell = this.childs[iCol];
      const gridCell = this.dhx.cells(rId, iCol);
      const val = (nValue === undefined) ? gridCell.getValue() : nValue;

      if (oCell.numeric) {

        const cellHasError = this.errorCells.includes(`${iCol}|${rId}`);
        const cellLowerThanMinVal = (oCell.numeric.minVal != null && oCell.numeric.minVal > val);
        const cellHigherThanMaxVal = (oCell.numeric.maxVal != null && oCell.numeric.maxVal < val);

        if (cellLowerThanMinVal || cellHigherThanMaxVal) {
          if (!cellHasError) {
            this.errorCells.push(`${iCol}|${rId}`);
            $(gridCell.cell).addClass('invalid');

            if (!this.oVuexState.attributes.hasErrors)
              this._dispatch('incrementHasErrors', 1);
            this._dispatch('setHasErrors', true);
          }

          /* if editing cells, show the error message */
          if (nValue !== undefined) {
            if (cellLowerThanMinVal)
              akioma.notification({ type: 'error', text: `${akioma.tran('InputnumMessages.numLessThanMin', { defaultValue: 'Number is less than' })} ${oCell.numeric.minVal}` });
            else
              akioma.notification({ type: 'error', text: `${akioma.tran('InputnumMessages.numGreaterThanMax', { defaultValue: 'Number is greater than' })} ${oCell.numeric.maxVal}` });
          }
          return false;
        } else if (cellHasError && !cellLowerThanMinVal && !cellHigherThanMaxVal) {
          // if column is present in errorCells, remove it from there
          if (this.errorCells.indexOf(`${iCol}|${rId}`) !== -1) this.errorCells.splice(this.errorCells.indexOf(`${iCol}|${rId}`), 1);
          $(gridCell.cell).removeClass('invalid');

          if (this.errorCells.length === 0) {
            if (this.oVuexState.attributes.hasErrors)
              this._dispatch('decrementHasErrors', 1);
            this._dispatch('setHasErrors', false);
          }
        }
      }
      return true;
    },

    createMenuLookup: function(structureCode) {
      const oSelf = this;
      const aMainMenuOptions = [];
      let result;

      const mainMenu = app.controller.parseProc({
        view: 'menustructure',
        att: { id: structureCode },
        sub: []
      }, oSelf);

      const onAfterContextMenuCreate = () => {
        mainMenu.scan((itemid, label, icon) => {
          aMainMenuOptions.push({ id: itemid, text: label, img: icon });
        });

        const templateMenu = Handlebars.compile(akioma.handlebarsTemplates.autocompleteSearchBT.templateMenu);
        const oData = { results: aMainMenuOptions };
        result = templateMenu(oData);
        mainMenu.result = result;
        oSelf.menuLookup = mainMenu;
      };
      mainMenu.loadMenuElements(onAfterContextMenuCreate);
    },


    // save profile ********************
    saveProfile: function(cType) {
      const oSelf = this,
        aField = [],
        aWidth = [],
        iCols = this.dhx.getColumnsNum();

      if (cType == 'clear') {
        // clear profile
      } else {
        for (let i = 0; i < iCols; i++) {
          aField.push(this.dhx.getColumnId(i));
          aWidth.push(this.dhx.getColWidth(i));
        }
      }

      $.ajax({
        type: 'POST',
        url: '/akioma/saveGridProfile.p',
        dataType: 'json',
        data: `Proc=${this.opt.gridName}&Container=${this.dynObject.container.name}&Fields=${aField.join(',')}&Width=${aWidth.join(',')}`,
        success: function() {},
        error: function(xhr, textStatus, errorThrown) {
          akioma.log.error(`Error getting data from ${oSelf.opt.SDO}: ${textStatus} -> ${errorThrown}`);
        }
      });
    },

    // sorting *********************
    sorting: function(iCol, cType, cDir) {
      const oGrid = this.dhx,
        cName = oGrid.getColumnId(iCol),
        oSelf = this;

      // check for old
      if (this.sort.col != undefined && this.sort.col == iCol) {
        // change direction
        cDir = (this.sort.dir == 'asc') ? 'desc' : 'asc';
      }

      this.setupFilters(); // when adding new filters we want them to update on sorting initially also

      // if in batch mode and sort then reset skip and top
      if (oSelf.opt.batchingMode) {
        oSelf.dataSource.setBatch(0, parseInt(oSelf.iBatchSize));

        oSelf.dataSource.urlQuery.skip = 0;
        oSelf.dataSource.urlQuery.top = parseInt(oSelf.iBatchSize);

      }

      // save sort
      this.sort = {
        col: iCol,
        dir: cDir,
        name: cName
      };

      this.dataSource.query.setSorting([{ field: cName, direction: cDir }]);
      let cColSortPhrase = this.getColSortPhrase(cName);
      if (cColSortPhrase) {
        if (cColSortPhrase.indexOf('$Order') == -1)
          cColSortPhrase += '$Order';

        cColSortPhrase = cColSortPhrase.replace('$Order', ((oSelf.sort.dir == 'asc') ? '' : oSelf.sort.dir));
        this.dataSource.query.setStringSorting(cColSortPhrase);
        this.dataSource.query.buildQuery();
      }

      if (!oSelf.bFilterError) {
        this.dataSource.openQuery({});
        this._renderMultiSelectStateAfterFill(); // show multiselect filters
      }

      // save sort arrow to apply after grid loads
      this.oSortState = {
        state: true,
        iCol: iCol,
        order: cDir
      };

      this.dataSource.oSortState = this.sort;

      return false;
    },

    getColSortPhrase: function(cName) {
      return this.aSortPhrases[cName];
    },

    // collect values (filter) ************
    collectValues: function(colNr) {
      const oSelf = this,
        oGrid = this.dhx;
      // get correct column id
      if (this._initColIds)
        colNr = this.dhx.getColIndexById(this._initColIds[colNr]);

      // check for logical datatype
      if (this.cols.dataTypes[colNr] == 'logical')
        return [ 'yes', 'no' ];

      // check for combo
      let cName, oCol;
      if ((oSelf.flagColumnValuesF[colNr] == undefined || oSelf.flagColumnValuesF[colNr] == false) && oSelf.flagColumnValuesF[colNr] != true) {
        cName = oGrid.columnIds[colNr];

        // check for col
        oCol = $.grep(this.childs, oElm => (oElm.opt.dataField == cName));
        if (oCol.length < 0) {
          akioma.message({ type: 'alert-error', title: 'Getting combo values', text: `Column in datagrid for '${cName}' not available!` });
          return null;
        }
        const cObjName = oCol[0].opt.comboName;

        // dyncombo - add to refresh column id array, to apply context menu refresh later
        if (oCol[0].opt.dyncombo) {
          oSelf.aRefreshColIDs.push({
            dataField: oCol[0].opt.dataField,
            cName: cName,
            cObjName: cObjName,
            colNr: colNr
          });
        }

        // check for localStorage
        const cData = (localStorage ? localStorage.getItem(`ak_combogrid_${cObjName}`) : null);
        if (cData && app.sessionData.useComboCache)
          oGrid.comboValues[cName] = JSON.parse(cData);
        else { // load from JSDO
          if (oCol[0].opt.ListItemPairs && oCol[0].opt.ListItemPairs[0] == '$' && !oCol[0].bCollectStop) {
            LoadDataHelper.loadData(oCol[0].opt.ListItemPairs, oCol[0], data => {
              const aData = data,
                aOpt = [];

              for (let i = 0; i < aData.length; i++) {
                aOpt.push({
                  value: aData[i].selfhdl,
                  text: aData[i].selfdesc
                });
              }

              if (aOpt.length > 0) {
                oGrid.comboValues[cName] = aOpt;
                // save combo values in storage
                localStorage && localStorage.setItem(`ak_combogrid_${cObjName}`, JSON.stringify(aOpt));

                oCol[0].bCollectStop = true;
                oGrid.collectValues(colNr);
              }
            });
          } else if (oCol[0].opt.dyncombo && oCol[0].opt.ListItemPairs == undefined) // check for dyn combo
            oSelf._loadFilterDynComboEntries(cName, cObjName);
          else if (oCol[0].businessEntity && oCol[0].rows) {
            const oData = [];
            for (let i = 0; i < oCol[0].rows.length; i++) {
              try {
                oData.push({
                  value: oCol[0].rows[i][oCol[0].oAttributes.dynSelect.lookupKeyValueColumn],
                  text: oCol[0].rows[i][oCol[0].oAttributes.dynSelect.lookupKeyField].toString()
                });
              } catch (e) {
                akioma.log.error(e);
              }
            }
            oGrid.comboValues[cName] = oData;
          } else if (oCol[0].oAttributes) { // static combo
            if (oCol[0].oAttributes.ListItemPairs == undefined && (oCol[0].oAttributes.dyncombo == undefined || !oCol[0].oAttributes.dyncombo) && oCol[0].oAttributes.comboText && oCol[0].oAttributes.comboValues) {
              const oData = [],
                oDataMap = [],
                oComboVal = oCol[0].oAttributes.comboValues.split(','),
                oComboText = oCol[0].oAttributes.comboText.split(',');
              for (const i in oComboVal) {
                oData.push({
                  value: oComboVal[i],
                  text: oComboText[i]
                });
                oDataMap[oComboVal[i]] = oComboText[i];
              }
              oGrid.comboValues[cName] = oData;
              oGrid.akElm.listItemPairs[cName] = oDataMap;
            } else
              oGrid.comboValues[cName] = [];
          }


          if (oCol[0].bCollectStop)
            oCol[0].bCollectStop = false;
        }
      }

      const aOptions = [];
      for (const i in oGrid.comboValues[cName]) {
        if (!isValidHdl(oGrid.comboValues[cName][i].value)) // there is no sense in using a handle as a literal key
          oGrid.comboValues[cName][i].text = akioma.tran(`${this.opt.name}.${cName}_Entries.${oGrid.comboValues[cName][i].value}`, { defaultValue: oGrid.comboValues[cName][i].text });

        if (oCol[0].opt.filter == '#multiselect_filter' || (oCol[0].opt.filter == '#dynselect_filter'))
          aOptions.push([ oGrid.comboValues[cName][i].value, oGrid.comboValues[cName][i].text ]);
        else
          aOptions.push(oGrid.comboValues[cName][i].text);
      }

      if (oCol[0].opt.enabled == false && oCol[0].opt.colType == 'dynselect')
        oGrid.setColumnExcellType(colNr, 'ro');


      oSelf.flagColumnValuesF[colNr] = false;


      return aOptions;
    },
    // loads dyn combo filter options
    _loadFilterDynComboEntries: function() {
      return false;
    },

    /**
     * Method for getting the index of the first active filter
     * @return {HtmlElement} Index for first active filter or null if not found
     * @instance
     * @memberOf ak_datagrid2
     */
    getIndex_FirstActiveFilter: function() {
      const oSelf = this;
      for (let i = 0; i < oSelf.dhx.getColumnsNum(); i++) {
        if (oSelf.dhx.getFilterElement(i))
          return i;
      }
      return null;
    },

    /**
     * Method for getting the index of the next active filter
     * param   {integer} cCurrentIndex The index
     * @return {HtmlElement} Index of the next active filter or null if not found
     * @instance
     * @memberOf ak_datagrid2
     */
    getIndex_NextActiveFilter: function(cCurrentIndex) {
      const oSelf = this;
      cCurrentIndex++;
      for (let i = cCurrentIndex; i < oSelf.dhx.getColumnsNum(); i++) {
        const iInitIndex = oSelf._initColIds.indexOf(oSelf.dhx.getColumnId(i));
        if (oSelf.dhx.getFilterElement(iInitIndex))
          return i;
      }
      return null;
    },

    /**
     * Method for getting the index of the previous active filter
     * param   {integer} cCurrentIndex The index
     * @return {HtmlElement} Index of the previous active filter or null if not found
     * @instance
     * @memberOf ak_datagrid2
     */
    getIndex_PrevActiveFilter: function(cCurrentIndex) {
      const oSelf = this;
      cCurrentIndex--;
      for (let i = cCurrentIndex; i >= 0; i--) {
        const iInitIndex = oSelf._initColIds.indexOf(oSelf.dhx.getColumnId(i));
        if (oSelf.dhx.getFilterElement(iInitIndex))
          return i;
      }
      return null;
    },

    /**
     * Method for setting/removing the focus of a header filter
     * @param  {integer} iIndex Index of the column
     * @param  {bool} bFocus True for setting focus, false for removing focus
     * @instance
     * @memberOf ak_datagrid2
     */
    setHeaderFieldFocus: function(iIndex) {
      const oSelf = this;
      const iInitIndex = oSelf._initColIds.indexOf(oSelf.dhx.getColumnId(iIndex));
      const oHeader = oSelf.dhx.getFilterElement(iInitIndex);
      const oPanel = oSelf.getAncestor('panel');
      if (oPanel != null)
        oPanel.setActivePanelState();

      if ($(oHeader).hasClass('dynSelect')) {
        const oParent = $(oHeader).parent();
        const oSelect = $(oParent).find('.select2-selection');
        $(oSelect).focus();

      } else if ($(oHeader).hasClass('logicalFilter')) {
        const oCheckbox = $(oHeader).children(':first');
        $(oCheckbox).attr('tabindex', '0');
        $(oCheckbox).focus();
        $(oCheckbox).css('outline', 'none');
      } else {
        $(oHeader).attr('tabindex', '0');
        $(oHeader).focus();

      }
      oPanel.oFocus.oActiveField = iIndex;
      oPanel.oFocus.bRowMode = false;
    },

    /**
     * Method for selecting a row in the Grid
     * @param  {string} cRowId Row id
     * @instance
     * @memberOf ak_datagrid2
     */
    setRowFocus: function(cRowId, bMode) {
      const oSelf = this;
      const oPanel = oSelf.getAncestor('panel');
      if (!cRowId || oSelf.dhx.getRowIndex(cRowId) == -1)
        cRowId = oSelf.dhx.getSelectedRowId();

      if (bMode)
        oSelf.dhx.selectRowById(cRowId);
      const oRow = oSelf.dhx.getRowById(cRowId);
      $(oRow).attr('tabindex', '0');
      $(oRow).focus();
      $(oRow).css('outline', 'none');
      oPanel.oFocus.oActiveRow = cRowId;
      oPanel.oFocus.bRowMode = true;
      oSelf.oFocused = oRow;
      if (oPanel != null)
        oPanel.setActivePanelState();
    },

    // get grid col *******************
    getComboValues: function(cName) {
      const aRet = [],
        oCombo = this.dhx.comboValues[cName];

      if (oCombo) {
        for (const i in oCombo)
          aRet.push(`${oCombo[i].value}|${oCombo[i].text}`);
      }

      return aRet.join(';');
    },

    // get grid col *******************
    getGridCol: function(cName) {
      // get column
      const oElm = $.grep(this.childs, oCol => oCol.opt.dataField == cName);
      return oElm ? oElm[0] : null;
    },

    /**
     * Method for getting a Grid cell
     * @param {string} row    The row index
     * @param {string} column The column name
     * @instance
     * @memberof ak_datagrid2
     * @returns {object|null}
     */
    getCell: function(row, column) {
      if (row >= 0) {
        const colIndex = this.getColIndexById(column);
        if (colIndex !== undefined && colIndex !== null) {
          const cellGrid = this.dhx.cells2(row, colIndex);
          return cellGrid || null;
        }
      }
      return null;
    },

    // get field value *******************
    getFieldValue: function(cName) {
      // get grid and handle
      const oGrid = this.dhx;
      const cHdl = oGrid.getSelectedRowId();

      let cValue;
      if (this.opt.multiSelect == false) {
        const iCol = oGrid.getColIndexById(cName);
        cValue = oGrid.cellById(cHdl, iCol).getValue();
      } else {
        const aIds = cHdl.split(',');
        const aResult = [];
        if (cName == undefined)
          cName = 'selfhdl';

        for (const i in aIds) {
          const id = aIds[i];
          const oItem = this.dataSource.dhx.item(id);

          if (oItem)
            aResult.push(oItem[cName.toLowerCase()]);
        }
        cValue = aResult.join(',');
      }
      return cValue;
    },

    // set field value ********************
    setFieldValue: function(cName, cValue) {
      // get grid and handle
      const oGrid = this.dhx,
        cHdl = oGrid.getSelectedRowId(),
        iCol = oGrid.getColIndexById(cName),
        oCell = oGrid.cellById(cHdl, iCol);

      // set value
      if (oCell)
        oCell.setValue(cValue);
      else
        !_isIE && console.error([ 'setFieldValue: no cell available', cName, cValue, cHdl, iCol ]);
    },

    // get lookup key
    getLookupKey: function(cRow, iCol) {
      const oGrid = this.dhx,
        cName = oGrid.getColumnId(iCol),
        oCol = this.getGridCol(cName);

      return oCol.getLookupKey(cRow);
    },

    // page changed
    pageChanged: function() {
      return true;
    },

    // mouse over
    mouseOver: function(id, ind) {
      const oGrid = this.dhx;
      const cellGrid = oGrid.cellById(id, ind);
      if ($(cellGrid.cell).hasClass('anonymizedCell'))
        cellGrid.setAttribute('title', 'Access denied');
      return true;
    },

    // start lookup ***************************
    startLookup: function(iCol, cType) {
      const cName = this.dhx.getColumnId(iCol),
        oCol = this.getGridCol(cName);

      this.prop.colid = iCol;

      // check for lookup and get parameter for lookup
      if (oCol && oCol.opt.lookup_objectName) {
        // call dialog
        app.controller.launchContainer({
          async: true,
          proc: 'popup.r',
          para: `RunFile=${oCol.opt.lookup_lookupDialog}&FieldName=${oCol.opt.dataField}&TargetId=${this.opt.id},${cType}`,
          self: oCol,
          data: true
        });
      }
    },

    // get value from server ******************
    getValueFromServer: function(cValue, oSelf) {
      // now get appropriate column
      const iCol = oSelf.cell.cellIndex;

      // get lookup
      const oCol = this.childs[iCol];
      if (oCol) {

        // run method to get value from server
        oCol.getValueFromServer(cValue, oSelf, 'cell');
      }
    },

    /**
     * Enable/disable toggle filter panel.
     * @param {string} panelFilterId Id (menu function code) of the panel filter.
     * @param {boolean} state State to set.
     */
    setToggleFilterPanel: function(panelFilterId, state) {
      const oSelf = this;
      oSelf.FilterManager.panelMenuLoaded.then(() => {
        const currentState = oSelf.FilterManager.getPanelFilterState(panelFilterId);
        if (currentState === state) return;

        const filterTogglebox = $(oSelf.getAncestor('panel').dhx.cell).find(`[menu-name="${panelFilterId}"]`)[0];
        akioma.toggleFilter(oSelf.dynObject, { currentTarget: filterTogglebox });
      });
    },

    // set filter fields in header ***********
    setFilterFields: function(aFilterFields) {
      const aTypes = this.cols.dataTypes,
        oGridAk = this;
      this.filter = {};

      for (const i in aFilterFields) {
        const oFilter = aFilterFields[i];
        const cField = oFilter.fieldname.toLowerCase();

        const iNum = $.inArray(cField, this._initColIds);

        // now it has to be a valid filter
        if (iNum > -1) {
          let oHeaderElm = this.dhx.getFilterElement(iNum);
          if (oHeaderElm) {
            if (this.childs[iNum].oAttributes && aTypes[iNum].toLowerCase() == 'character')
              aTypes[iNum] = 'dynselect';

            switch (aTypes[iNum]) {
              case 'combo': { // check for value
                const aValues = this.dhx.comboValues[cField];
                for (const i in aValues) {
                  if (aValues[i].value == oFilter.value)
                    oHeaderElm.dataset.selectVal = aValues[i].text;
                }
                const shortKey = (aValues[i].text.split(' / ')[1]) ? aValues[i].text.split(' / ')[1] : aValues[i].text;
                const oSelectedItem = {
                  'id': aValues[i].value,
                  'key': aValues[i].text,
                  'shortKey': shortKey
                };
                this.setDynSelectFilters(cField, oSelectedItem);
                break;
              }
              case 'logical':
                oHeaderElm.value = oFilter.value;
                if (this.aFilter[iNum] == '#logical_filter') {
                  const oSelfCol = this.childs[iNum];
                  const aExtendedFormat = this.aExtendedFormat[iNum];
                  let cFilter = '';
                  switch (oFilter.value) {
                    case '':
                      cFilter = 'no';
                      break;
                    case 'yes':
                    case 'TRUE':
                      cFilter = '';
                      break;
                    case 'no':
                    case 'FALSE':
                      cFilter = 'yes';
                      break;
                  }
                  oSelfCol.changeLogicalFilterIcon(oHeaderElm, cFilter, aExtendedFormat);
                }
                break;
              case 'dynselect': {
                const oSelf = this.childs[iNum];
                oSelf.businessEntity.oNamedQuery = null;
                const aFilterVals = oFilter.value.split(';');

                try {
                  $(oHeaderElm).empty();
                  oGridAk.aDynSelectStatus.aSelectedItems[oSelf.opt.name.toLowerCase()] = [];
                  oGridAk.aDynSelectStatus.aSelectedValues[oSelf.opt.name.toLowerCase()] = [];

                  for (const i in aFilterVals) {
                    const rows = JSON.parse(aFilterVals[i]);

                    for (const x in rows) {
                      const oRow = rows[x];
                      const newFilter = oRow;

                      oGridAk.setDynSelectFilters(oSelf.opt.name.toLowerCase(), newFilter);

                      const newOption = new Option(newFilter.desc, newFilter.id);
                      $(newOption).data('custom', newFilter.desc);
                      $(newOption).data('bExtVal', true);
                      oHeaderElm.append(newOption);

                    }
                    oHeaderElm.dataset.selectVal = aFilterVals[i];
                    oSelf.select2.val(oGridAk.aDynSelectStatus.aSelectedItems[cField]).trigger('change');
                  }
                  oGridAk._renderMultiSelectStateAfterFill();
                } catch (e) {
                  akioma.log.error(e);
                }
                break;
              }
              case 'lookup': {
                const oData = app.controller.callServerMethod('stubs/hdl2keyanddesc.p', [
                  { type: 'iCHAR', value: oFilter.value },
                  { type: 'oCHAR', name: 'key' },
                  { type: 'oCHAR', name: 'desc' }
                ]);

                oHeaderElm.valhdl = oFilter.value;
                oHeaderElm.value = oData.key;
                break;
              }
              case 'logicalpic':
                oHeaderElm.setComboValue(oFilter.value);
                break;
              case 'date': {
                const aDate = oFilter.value.split('_');
                if (aDate[0]) oHeaderElm.value = aDate[0];
                if (aDate[1]) {
                  oHeaderElm = oHeaderElm.parentElement.children[1];
                  oHeaderElm.value = aDate[1];
                }
                break;
              }
              default:
                oHeaderElm.value = oFilter.value;
                break;
            }
            this.filter[cField] = oFilter.value;
          }
        }
      }
    },

    getFiltersBT: function() {
      const oSelf = this;
      const oSource = this.dataSource;
      const promise = $.Deferred();
      const oData = { SDO: oSource.opt.name.toLowerCase(), Grid: oSelf.opt.gridName };

      akioma.invokeServerTask({
        name: 'Akioma.Swat.FilterBT',
        methodName: 'GetFilters',
        paramObj: { plcParameter: oData },
        showWaitCursor: true,
        uiContext: oSelf.dynObject
      }).done(oResult => {
        if (oResult.plcDataset.dsFilter.eFilter) {
          const aFilters = oResult.plcDataset.dsFilter.eFilter;
          const aFilterDefinitions = oResult.plcDataset.dsFilter.eFilterDefinition;
          const aDefinitions = [];

          for (const i in aFilterDefinitions) {
            const oCurrDef = aFilterDefinitions[i];
            if (aDefinitions[oCurrDef.FilterHdl] == undefined)
              aDefinitions[oCurrDef.FilterHdl] = [];
            const oDefinition = {
              fieldname: oCurrDef.FieldName,
              operator: oCurrDef.Operator,
              value: oCurrDef.FilterValue
            };
            aDefinitions[oCurrDef.FilterHdl].push(oDefinition);
          }

          oSelf.prop.filter = aFilters;
          oSelf.prop.filterDefinitions = aDefinitions;

          promise.resolve(aFilters, aDefinitions);
          // append new menuitems as children of clicked getFilter menu item
        }

        // loading frame finished, signal promise
        if (oSelf.getFiltersPromise)
          oSelf.getFiltersPromise.resolve();

        // add new filter next in header menu
      });

      return promise;
    },

    // get list of filters from server **********
    getFilterList: function() {
      const oSelf = this;

      // check for filter combo in toolbar
      const oFilter = this.dynObject.getLink('FILTER:SOURCE');
      if (!oFilter || oFilter.length == 0)
        return;


      let oCombo = oFilter.getField('tbfilterChooseFilter');
      if (!oCombo)
        return;
      oCombo = oCombo.controller;
      const oToolbar = oCombo.parent;

      // load from JSDO
      if (this.opt.ListItemPairs && this.opt.ListItemPairs[0] == '$') {
        LoadDataHelper.loadData(this.opt.ListItemPairs, this, data => {
          const aOptions = [];

          for (const i in data) {
            aOptions.push({
              hdl: data[i].selfhdl,
              value: data[i].selfdesc,
              image: null
            });
          }
          oCombo.setComboOptions(aOptions);

          oSelf.prop.filter = data;
        });
      } else { // get name of sdo
        const cSDO = this.dataSource.opt.SDO;

        // stop datasource openQuery until filters load
        oSelf.dataSource.stop = true;

        $.ajax({
          type: 'POST',
          url: '/akioma/getdata.xml',
          data: `Action=getFilterList&SDO=${cSDO}&Grid=${this.opt.gridName}`,
          dataType: 'json',
          success: function(data) {
            const aOptions = [];
            let defaultOption;

            for (const i in data.filter) {
              aOptions.push({
                hdl: data.filter[i].hdl,
                value: data.filter[i].desc,
                image: null
              });
              if (data.filter[i].isdefault)
                defaultOption = data.filter[i];
            }
            oCombo.setComboOptions(aOptions);

            oSelf.prop.filter = data.filter;

            // set default
            if (defaultOption) {
              const oDhxTool = oToolbar.dhx;

              oDhxTool.setListOptionSelected('tbfilterChooseFilter', defaultOption.hdl);
              oDhxTool.setItemText('tbfilterChooseFilter', defaultOption.desc);

              oSelf.setFilterFields(defaultOption.definition);

              // set rowstobatch
              oSelf.rowsToBatch(defaultOption.rowsToBatch);
              oDhxTool.setListOptionSelected('rowsToBatch', defaultOption.rowsToBatch);
              oDhxTool.setItemText('rowsToBatch', defaultOption.rowsToBatch);

              // set sorting
              if (defaultOption.sort) {
                oSelf.sort = {
                  col: oSelf.dhx.getColIndexById(defaultOption.sort.split(',')[0]),
                  name: defaultOption.sort.split(',')[0],
                  dir: defaultOption.sort.split(',')[1]
                };
                oSelf.dhx.setSortImgState(true, oSelf.sort.col, oSelf.sort.dir);
              }

              // write them to source
              oSelf.dataSource.setFilter(oSelf.filter);
            }
            // now get data after applying filters
            oSelf.dataSource.stop = false;
            oSelf.FilterGo({ filterGo: true });
          },
          error: function(xhr, textStatus, errorThrown) {
            akioma.log.error(`Error getting data from ${oSelf.opt.SDO}: ${textStatus} -> ${errorThrown}`);
          }
        });
      }
    },

    // filter start ****************************
    filterStart: function(aFiltIdx, aFiltVal) {
      const aTypes = this.cols.dataTypes,
        aFilter = this.aFilter,
        oFilter = {},
        oGrid = this.dhx;

      for (const i in aFiltIdx) {
        const iGridCol = aFiltIdx[i];
        const cName = oGrid.getColumnId(iGridCol);
        const iCol = $.inArray(cName, this.cols.cells);

        if (aTypes[iCol] == 'multicombo')
          oFilter[cName] = aFiltVal[i].split('|');
        else if (aFilter[iCol] == '#dynselect_filter')
          oFilter[cName] = aFiltVal[i].split('|');
        else
          oFilter[cName] = (aFiltVal[i] == '') ? '' : aFiltVal[i];

      }

      this.filter = oFilter;

      // prevent going to server
      return false;
    },

    getColTypeByName: function(cName) {
      const aTypes = this.cols.dataTypes,
        iCol = $.inArray(cName, this.cols.cells);

      return aTypes[iCol].toLowerCase();
    },

    /**
     * Gets the akEvent for a cell. The akEvent contains the currentValue and lastValue of the cell.
     * @param {string} rowId       The row id
     * @param {number} columnIndex The column index
     * @instance
     * @memberof ak_grid
     * @return {object|null}
     */
    getCellAkEvent: function(rowId, columnIndex) {
      const cell = this.dhx.cells(rowId, columnIndex);
      if (!isNull(cell)) {
        const cellHtml = cell.cell;
        const akEvent = $(cellHtml).data('akEvent');
        return (!isNull(akEvent)) ? akEvent : null;
      }
      return null;
    },

    /**
     * Return default operator based on column type
     * @param {Integer} iCol column index
     * @return {String} Operator based on col type
     */
    getColDefaultOperator: function(cName) {
      const cType = this.getColTypeByName(cName);
      let cOperatorResult = 'eq';

      switch (cType) {
        case 'logicalyes':
          cOperatorResult = 'eq';
          break;
        case 'lookup':
          cOperatorResult = 'eq';
          break;
        case 'date':
        case 'datetime':
        case 'datetime-tz':
          cOperatorResult = 'gte';
          break;
        case 'decimal':
        case 'integer':
          cOperatorResult = 'eq';
          break;
        case 'combo':
          cOperatorResult = 'eq';
          break;
      }

      return cOperatorResult;
    },

    // filter for lookup ***************************
    filterLookup: function(iIndex, cValue, lSave) {
      if (cValue) {
        const cName = this.dhx.getColumnId(iIndex);
        const oCol = this.getGridCol(cName);
        if (!oCol) {
          dhtmlx.message({
            type: 'alert-error',
            title: 'Lookup-Filter',
            text: `Error getting column for filter:<br />${iIndex}: ${cName}`
          });
          return;
        }

        const oData = app.controller.callServerMethod('stubs/getLookupResults.p',
          [
            { type: 'iCHAR', value: `${oCol.opt.lookup_tableName}.${oCol.opt.lookup_extKey}@${oCol.opt.lookup_objectName}` },
            { type: 'iCHAR', value: cValue },
            { type: 'iCHAR', value: '' },
            { type: 'oCHAR', name: 'hdl' },
            { type: 'oCHAR', name: 'desc' },
            { type: 'oCHAR', name: 'status' }
          ]);

        if (lSave) {
          this.dataSource.setFieldValue({ name: cName, value: oData.hdl });
          this.dataSource.setFieldValue({ name: oCol.opt.lookup_showKey, value: cValue });
        }
        return oData.hdl == '?' ? null : oData.hdl;
      }

      return;
    },

    setupFilters: function() {
      if (this.dhx.filters) {
        // refresh filter values
        this.dhx.filterByAll();
      }

      if (this.queryWindow)
        this.queryWindow.updateAkFilter(this.dataSource.query);


      this._syncFilters();
    },

    /**
     * Filters over dataSource.
     * @instance
     * @memberOf ak_grid
     * @private
     */
    FilterGo: function() {
      const oSelf = this;
      if (!isNull(oSelf.leaveEventPromise)) {
        oSelf.leaveEventPromise.then(() => {
          oSelf.leaveEventPromise = null;
          oSelf.filterGoTrigger();
        })
          .catch(error => {
            console.error(error);
            oSelf.filterGoTrigger();
          });
      } else
        oSelf.filterGoTrigger();
    },

    /**
     * Sets filters and triggers filtering over dataSource.
     * @instance
     * @memberOf ak_grid
     * @private
     */
    filterGoTrigger: function() {
      const oSelf = this;
      const deferred = $.Deferred();
      const oOpen = {
        filterGo: true,
        caller: this
      };

      if (this.sort) {
        oOpen.sort = this.sort.name;
        oOpen.dir = this.sort.dir;
      }

      this.setupFilters();

      if (this.popupform) {
        const cFullTextSearch = this.popupform.getItemValue('searchInput');
        if (cFullTextSearch.length > 0)
          this.dataSource.query.setFullTextSearch(cFullTextSearch);
      }

      // tell source to refresh
      if (this.opt.batchingMode)
        this.dataSource.setBatch(0, 50);

      if (this.dataSource.dhx && !this.bFilterError)
        this.dataSource.dhx.clearAll();

      this.dataSource.addAfterCatalogAdd(() => {

        const oQueryPromise = oSelf.filterGoQuery(oOpen);
        oQueryPromise.then(() => {
          deferred.resolve();
        }).fail(() => {
          deferred.reject();
        });

      });

      return deferred.promise();
    },

    /**
     * Sets selected record for filter grid based on filterHdl input.
     * @param   {string}  cFilterHdl
     * @instance
     * @memberOf ak_grid
     * @return {void}
     */
    setSelectedFilterRowByHdl: function(cFilterHdl) {
      const oSelf = this;

      oSelf.dataSource.callAfterPendingRequest(() => {
        oSelf.FilterManager.setCurrentFilter(cFilterHdl);

        const filterSelect = oSelf.dataSource.getIdFrom(oSelf.dataSource.opt.identifier, cFilterHdl);
        oSelf.dhx.setSelectedRow(filterSelect);
        oSelf.dataSource.dhx.setCursor(filterSelect);

        // set keep selection for first grid refresh
        oSelf.setKeepSelection({ identifier: oSelf.dataSource.opt.identifier, value: cFilterHdl });
      });
    },

    /**
     * Filters over dataSource by performing count operation if batchingMode is enabled or just a simple query otherwise
     * @param {Object} oOpen openQuery options
     * @instance
     * @memberOf ak_grid
     * @private
     */
    filterGoQuery: function(oOpen) {
      const oSelf = this;
      const oPanel = oSelf.getAncestor('panel');
      const deferred = $.Deferred();
      let oDataPromise;

      // set the batch size
      oSelf.dataSource.setBatch(0, parseInt(oSelf.iBatchSize));

      // first count number of records
      if (oSelf.opt.batchingMode) {
        oSelf.dataSource.stop = false;
        oSelf.dataSource.urlQuery.skip = 0;
        oSelf.dataSource.urlQuery.top = parseInt(oSelf.iBatchSize);
        oDataPromise = oSelf.dataSource.openQuery(oOpen);

        if (!oSelf.bFilterError)
          oSelf._renderMultiSelectStateAfterFill(); // show multiselect checkboxes


        oSelf.keepSelection();

        if (oDataPromise) {
          oDataPromise.then(() => {
            deferred.resolve();
          }).fail(() => {
            deferred.reject();
          });
        } else
          oPanel.dhx.progressOff();


      } else {
        if (!oSelf.bFilterError) {
          // no count just filter
          oSelf.bFilterMode = true;
          oDataPromise = oSelf.dataSource.openQuery(oOpen);
          oSelf._renderMultiSelectStateAfterFill(); // show multiselect checkboxes
        }

        oDataPromise.then(() => {
          deferred.resolve();
        }).fail(() => {
          deferred.reject();
        });
      }

      // remove dirty state
      oSelf._dispatch('decrementHasChanges', 1);

      oSelf._commit('CLEAR_CHANGED_ROWS');

      oSelf.errorCells = [];
      oSelf._dispatch('decrementHasErrors', 1);
      oSelf._dispatch('setHasErrors', false);

      if (oSelf.dataSource.dc)
        oSelf.dataSource.dc.updatedRows = [];

      return deferred.promise();
    },

    /**
     * Sets internally the dynSelect filter values
     * @param {string} filterName Name of the filter
     * @param {Object} oSelectedItem The dynSelect option
     * @instance
     * @memberOf ak_grid
     * @private
     */
    setDynSelectFilters: function(filterName, oSelectedItem) {

      function addFilter(list, filterName, filterObj) {
        if (list[filterName] == undefined)
          list[filterName] = [];
        list[filterName].push(filterObj);
      }

      const oSelf = this;
      addFilter(oSelf.aDynSelectStatus.aSelectedValues, filterName, oSelectedItem.id);
      addFilter(oSelf.aDynSelectStatus.aSelectedItems, filterName, oSelectedItem);
    },

    /**
     * Removes clear button in dynSelect
     * @instance
     * @memberOf ak_grid
     * @private
     */
    removeClear_dynSelect: function(elem) {
      if ($(elem).find('li.select2-selection__choice').length == 0)
        $(elem).find('span.select2-selection__clear').remove();
    },

    /**
     * Removes emtpy option tag in multiple selection dynSelect
     * @instance
     * @memberOf ak_grid
     * @private
     */
    removeEmptyTag_dynSelect: function(elem) {
      // removes empty tags and inline search bugs in dynSelect
      const tag = $(elem).find('li.select2-selection__choice[title=""] , li.select2-selection__choice:not([title])');
      if (tag.length > 0)
        tag.remove();
      if ($(elem.parentElement).find('select option:first').text() == '')
        $(elem.parentElement).find('select option:first').remove();
    },

    /**
     * Renders a filter dynSelect based on the existing values. This is used after each Grid refresh because Dhtmlx removes the filter options so we need to add them back again.
     * @instance
     * @memberOf ak_grid
     * @private
     */
    _renderMultiSelectStateAfterFill: function() {
      const oSelf = this;
      this.dataSource.aCallback['afterFill'] = function() {
        oSelf._renderMultiSelectState();
      };
    },

    /**
     * Renders a filter dynSelect based on the existing values.
     * @instance
     * @memberOf ak_grid
     * @private
     */
    _renderMultiSelectState: function() {
      const oSelf = this;
      for (const i in oSelf.aMultiSelectFilters) {
        const oCurrentFilter = oSelf.aMultiSelectFilters[i];
        const aSelectedValues = oCurrentFilter.combo.cont.value.split('|');
        for (const j in aSelectedValues) {
          const iInd = oCurrentFilter.combo.getIndexByValue(aSelectedValues[j]);
          oCurrentFilter.combo.setChecked(iInd, true);
        }
      }

      let $select2Elm, oSelfCol;
      if (oSelf.aDynSelectStatus.aSelectedItems.length == 0) {
        oSelf.aDynSelectFilters.forEach(entry => {
          oSelfCol = oSelf.childs[entry.index];
          $select2Elm = $(`#${entry.id}`);
          if (oSelfCol.oAttributes.dynSelect.multiple)
            $select2Elm.find('option[value=""]').remove();
          oSelf.removeClear_dynSelect($select2Elm[0].nextSibling);
        });
      }

      // reapply dynSelect filters
      for (const i in oSelf.aDynSelectStatus.aSelectedItems) {
        const oCurrentFilter = oSelf.aDynSelectStatus.aSelectedItems[i];
        const oCurrentValues = oSelf.aDynSelectStatus.aSelectedValues[i];
        oSelf.aDynSelectFilters.forEach(entry => {
          if (entry.colname == i) {
            $select2Elm = $(`#${entry.id}`);
            oSelfCol = oSelf.childs[entry.index];
          }
        });

        if (oSelfCol.businessEntity)
          $($select2Elm).find('option').remove();

        oCurrentFilter.forEach(entry => {
          if (oSelfCol.businessEntity) {
            const newOption = new Option(entry.desc, entry.id);
            $(newOption).data('custom', entry.desc);
            $(newOption).data('bExtVal', true);
            $select2Elm.append(newOption);
          } else {
            const elem = $select2Elm.find(`option:contains("${entry.id},${entry.listItemDesc}")`);
            if (oSelfCol.oAttributes.dynSelect.tags && elem.length == 0) {
              const newOption = new Option(entry.listItemKey, entry.id);
              $(newOption).data('custom', entry.listItemText);
              $select2Elm.append(newOption);
            } else {
              $(elem).val(entry.id);
              $(elem).data('custom', entry.listItemText);
            }
          }
        });

        $select2Elm.find('option[value=""]').remove();
        $select2Elm.val(oCurrentValues);
        $select2Elm.trigger('change'); // Notify any JS components that the value changed
        $select2Elm.data('bClose', false);
        oSelf.removeClear_dynSelect($select2Elm);
      }
    },

    _checkIfMultiSelectFilter: function(cColName) {
      for (const i in this.aMultiSelectFilters) {
        if (this.aMultiSelectFilters[i].colname == cColName)
          return true;
      }
      return false;
    },

    /**
     * Checks if a filter is a dynSelect filter
     * @param {string} cColName Name of the column
     * @instance
     * @memberOf ak_grid
     * @private
     * @return {boolean} True if DynSelect filter, false otherwise
     */
    _checkIfDynSelectFilter: function(cColName) {
      for (const i in this.aDynSelectFilters) {
        if (this.aDynSelectFilters[i].colname == cColName)
          return true;
      }
      return false;
    },

    /**
     * Checks if a filter is a Daterange filter
     * @param {string} cColName Name of the column
     * @instance
     * @memberOf ak_grid
     * @private
     * @return {boolean} True if Daterange filter, false otherwise
     */
    _checkIfDateRangeFilter: function(cColName) {
      for (const i in this.aDateRangeFilters) {
        if (this.aDateRangeFilters[i].colname == cColName)
          return true;
      }
      return false;
    },

    /**
     * Builds the query filter from the grid headers
     * @instance
     * @memberOf ak_grid
     * @private
     */
    _syncFilters: function() {
      const oSelf = this;
      const oHdrFilters = this.filter;
      const akFilters = oSelf.dataSource.query.aFilters;
      oSelf.bFilterError = false;
      oSelf.dataSource.query.mainOperator = 'and';

      for (const i in oHdrFilters) {
        const bMultiSelectFilter = oSelf._checkIfMultiSelectFilter(i);
        const bDynSelectFilter = oSelf._checkIfDynSelectFilter(i);
        const bDateRangeFilter = oSelf._checkIfDateRangeFilter(i);
        const iInd = oSelf.dhx.getColIndexById(i);
        const oHdrElm = oSelf.dhx.getFilterElement(oSelf._initColIds.indexOf(i));

        // updates filter grid hdr field values
        const cHdrFilterVal = oHdrFilters[i];
        const bEnabled = ($(oHdrElm).attr('disabled') != 'disabled');

        if (bDynSelectFilter && cHdrFilterVal[0] && cHdrFilterVal[0][0] == '[') {
          akFilters[i] = [];
          oSelf.aGridFilterFields[i] = [];
          oSelf.dataSource.query.setSubOperator(i, 'or');
          if (cHdrFilterVal[0] !== '') {
            const vals = JSON.parse(cHdrFilterVal[0]);
            for (const j in vals) {
              const val = vals[j];
              oSelf.aGridFilterFields[i].push({ attr: `${oSelf.dataSource.entityName}.${i}`, operator: 'eq', value: val.id, enabled: true });
              akFilters[i].push({ field: i, operator: 'eq', value: val.id });
            }
          }
        } else if (bMultiSelectFilter || bDynSelectFilter) { // for multi select combo
          akFilters[i] = [];
          oSelf.aGridFilterFields[i] = [];
          oSelf.dataSource.query.setSubOperator(i, 'or');
          for (const j in cHdrFilterVal) {
            if (cHdrFilterVal[j] != '') {
              oSelf.aGridFilterFields[i].push({ attr: `${oSelf.dataSource.entityName}.${i}`, operator: 'eq', value: cHdrFilterVal[j], enabled: true });
              akFilters[i].push({ field: i, operator: 'eq', value: cHdrFilterVal[j] });
            }
          }

          // for normal select inputs here
        } else if (cHdrFilterVal != undefined && cHdrFilterVal != '' && bEnabled) {
          const value = cHdrFilterVal.replace(/\*/g, '');
          const cColType = oSelf.getColTypeByName(i);

          let cOp;
          if (cColType == 'character')
            cOp = 'beginsmatches';
          else
            cOp = oSelf.getColDefaultOperator(i);

          // check for filterOp gridcol attribute for predefined filter operator
          let cColOp = this.columns[i].opt.filterOp;
          if (cColOp && cColOp != '') {
            if (cColOp.substr(0, 1) == '$') {
              try {
                cColOp = cColOp.substr(1);

                // remove semicolon at the end to prevent bug
                if (cColOp.indexOf(';') != -1)
                  cColOp = cColOp.replace(/;/g, '');


                const self = this.columns[i];
                // self is a controller, not a dynObject
                cColOp = akioma.swat.evaluateCode({ code: `(${cColOp.substr(1)})`, controller: self, dynObj: self });
              } catch (e) {
                akioma.notification({ type: 'error', text: `Error executing filterOp attribute method for column "${i}".` });
              }
            }
            cOp = cColOp;
          }

          if (akFilters[i] == undefined)
            akFilters[i] = [];
          if (oSelf.aGridFilterFields[i] == undefined)
            oSelf.aGridFilterFields[i] = [];

          // depending on operator send specific value
          let cHdrVal = value;
          if (cOp == 'beginsmatches')
            cHdrVal = cHdrFilterVal;
          else
            cHdrVal = value;

          let cLeftCal, cRightCal;
          if (bDateRangeFilter) {
            const aValues = cHdrVal.split('|');
            cLeftCal = aValues[0];
            cRightCal = aValues[1];
            akFilters[i] = [];
          }

          const iInitInd = oSelf._initColIds.indexOf(oSelf.dhx.getColumnId(iInd));
          const oColumn = oSelf.childs[iInitInd];
          if (oColumn.opt.dataType == 'date') {
            if (oColumn.bFilterError)
              oSelf.bFilterError = true;
          }

          if (akFilters[i][0] == undefined) {
            oSelf.aGridFilterFields[i] = [];
            akFilters[i] = [];

            if (bDateRangeFilter) {
              if (cLeftCal) {
                oSelf.aGridFilterFields[i].push({ attr: `${oSelf.dataSource.entityName}.${i}`, operator: 'gte', value: cLeftCal, enabled: true });
                akFilters[i].push({ field: i, operator: 'gte', value: cLeftCal });
              }
              if (cRightCal) {
                oSelf.aGridFilterFields[i].push({ attr: `${oSelf.dataSource.entityName}.${i}`, operator: 'lte', value: cRightCal, enabled: true });
                akFilters[i].push({ field: i, operator: 'lte', value: cRightCal });
              }
            } else {
              oSelf.aGridFilterFields[i].push({ attr: `${oSelf.dataSource.entityName}.${i}`, operator: cOp, value: cHdrVal, enabled: true });
              akFilters[i].push({ field: i, operator: cOp, value: cHdrVal });
            }

          } else {
            akFilters[i][0].operator = cOp;
            akFilters[i][0].value = cHdrVal;
            if (oSelf.aGridFilterFields[i][0]) {
              oSelf.aGridFilterFields[i][0].operator = cOp;
              oSelf.aGridFilterFields[i][0].value = cHdrVal;
            }
          }
          // when removing content of input filter in grid header clean up filters
        } else if (cHdrFilterVal != undefined && cHdrFilterVal == '' && oSelf.aGridFilterFields[i]) {
          if (oSelf.aGridFilterFields[i].length <= 2) {
            oSelf.aGridFilterFields[i] = [];
            akFilters[i] = [];
          } else if (oSelf.aGridFilterFields[i].length >= 3) {
            oSelf.aGridFilterFields[i].splice(0, 1);

            const cOrgVal = oSelf.aGridFilterFields[i][0].value;
            const cNewOp = oSelf.aGridFilterFields[i][0].operator;
            if (cNewOp == 'beginsmatches')
              oHdrElm.value = cHdrFilterVal;
            else if (cNewOp == 'equals')
              oHdrElm.value = cOrgVal;

            this.filter[i] = oHdrElm.value;
            if (!oSelf.aGridFilterFields[i][0].enabled)
              $(oHdrElm).attr('disabled', 'disabled');
            else
              $(oHdrElm).removeAttr('disabled');

          }

        } else if (cHdrFilterVal == '' && cHdrFilterVal == '')
          akFilters[i] = [];

      }

      oSelf.FilterManager.applyPanelFilters();
      oSelf.dataSource.query.buildQuery();
    },

    /**
     * Clears the filter sorting on the datasource
     * @instance
     * @memberof ak_datagrid
     * @returns {void}
     */
    FilterClearSort: function() {
      const oSelf = this,
        oQuery = oSelf.dataSource.getQuery();

      oQuery.clearSort();
      oQuery.buildQuery();
      oSelf.dhx.setSortImgState(false);
      delete oSelf.oSortState;
    },

    /**
     * Sets the filter sorting
     * @instance
     * @memberof ak_datagrid
     * @returns {void}
     */
    SetSorting: function(oSort) {
      const oSelf = this;
      oSelf.oSortState = (oSort) ? oSort : undefined;
    },

    /**
     * Method for clearing the sorting, clears filters and performs a new fetch
     * @instance
     * @memberof ak_datagrid
     * @returns {void}
     */
    FilterClearAllAndGo: function() {
      const oSelf = this;
      oSelf.FilterClear();
      oSelf.FilterGo();
    },

    // clear filters *********************************
    FilterClear: function() {
      const oGrid = this.dhx,
        aCells = this.dhx.columnIds;

      this.filter = {};

      // empty all filter fields
      for (const index in aCells) {
        const i = this._initColIds.indexOf(oGrid.getColumnId(index));
        const oFilter = oGrid.getFilterElement(i);
        if (oFilter) {
          if (this.aFilter[i] == '#logical_filter') {
            const oSelfCol = this.childs[i];
            const aExtendedFormat = this.aExtendedFormat[i];
            oSelfCol.changeLogicalFilterIcon(oFilter, 'no', aExtendedFormat);
            this.filter[aCells[index]] = '';
          }
          if (this.aFilter[i] == '#daterange_filter') {
            oFilter.value = '';
            oFilter.nextSibling.value = '';
          }
          if (oFilter.type == 'select-multiple' || oFilter.type == 'select-one') {
            $(oFilter).val('').trigger('change');
            const cCurMultiFilterColName = this.aDynSelectFilters[i].colname;
            this.aDynSelectStatus.aSelectedValues[cCurMultiFilterColName] = [];
            this.aDynSelectStatus.aSelectedItems[cCurMultiFilterColName] = [];
            this.filter[cCurMultiFilterColName] = '';
            const joinedVals = this.aDynSelectStatus.aSelectedValues[cCurMultiFilterColName].join('|');
            oFilter.dataset.selectVal = joinedVals;
          } else {
            if (Object.prototype.hasOwnProperty.call(oFilter, 'cont')) oFilter.unSelectOption(); // reset combo filters
            oFilter.value = '';
            this.filter[aCells[index]] = '';
          }
        }
      }

      this.FilterClearSort();
    },

    // get filter array ******************
    getFilterArray: function(plIncludeDetails) {
      const oFilter = this.filter,
        aFields = [],
        aValues = [],
        aOperators = [];
      if (plIncludeDetails == null)
        plIncludeDetails = false;

      let index = 0;
      for (const i in oFilter) {
        const ind = this._initColIds.indexOf(i),
          cFilterType = (this.childs[ind].oAttributes) ? this.childs[ind].oAttributes.filter : this.childs[ind].opt.filter; // supports inidividual dynSelect filter

        if (cFilterType == '#dynselect_filter') {
          const aFilterValues = this.aDynSelectStatus.aSelectedItems[i];
          if (aFilterValues && aFilterValues.length > 0) {
            aValues.push(JSON.stringify(aFilterValues));
            aOperators.push('=');
            aFields.push(i);
          }

        } else if (cFilterType == '#daterange_filter') {
          const cCurrFilter = oFilter[i];

          if (cCurrFilter.split && (cCurrFilter !== '')) {
            const aValue = cCurrFilter.split('|');
            const cDateRange = `${aValue[0]}_${aValue[1]}`;
            aFields.push(i);
            aValues.push(cDateRange);
            aOperators.push('=');
          }
        } else if (oFilter[i]) {
          let cCurrFilter = oFilter[i];
          if (Array.isArray(oFilter[i]) && this.childs[index].oAttributes.dynSelect)
            cCurrFilter = oFilter[i].join(';');


          if (cCurrFilter.split && (cCurrFilter !== '')) {
            const aValue = cCurrFilter.split('|');

            aFields.push(i);
            if (plIncludeDetails == false)
              aValues.push(aValue[0]);
            else
              aValues.push(cCurrFilter);

            if (aValue.length > 1)
              aOperators.push(aValue[1]);
            else if (aValue[0].substr(0, 1) == '*')
              aOperators.push('MATCHES');
            else if (aValue[0].substr(aValue[0].length - 1, 1) == '*' && aValue[0].split('*').length == 1)// last char and no other
              aOperators.push('BEGINS');
            else if (aValue[0].indexOf('*') > 0)
              aOperators.push('MATCHES');
            else
              aOperators.push('=');
          }
        }
        index++;
      }

      return {
        fields: aFields,
        values: aValues,
        operators: aOperators
      };
    },

    /**
     * Method to get filters
     * @deprecated
     * @param   {string}  cFilterHdl
     * @return  {void}
     */
    useFilterBT: function(cFilterHdl) {
      const aFilterDefinitions = this.prop.filterDefinitions,
        aFilters = this.prop.filter,
        oOpen = { filterGo: 1 };

      // clear filter fields before
      this.FilterClear();

      // set currently selected filter here
      this.cSelectedFilterHdl = cFilterHdl;

      const oQuery = this.dataSource.getQuery();
      oQuery.clearSort();

      delete this.oSortState;

      // now set filterfields in header
      aFilterDefinitions.forEach(() => {
        if (aFilterDefinitions[cFilterHdl]) {
          this.setFilterFields(aFilterDefinitions[cFilterHdl]);

          try {
            if (aFilters[cFilterHdl]) {
              const oFilterElm = this.dynObject.getLink('FILTER:SOURCE'),
                oCombo = oFilterElm ? oFilterElm.getField('rowsToBatch') : null,
                oRowsToBatch = oCombo ? oCombo.controller : null;
              oRowsToBatch.setFieldValue(aFilters[cFilterHdl].RowsToBatch);
            }
          } catch (e) {
            akioma.log.error('not found', e);
          }
        }
      });

      const oFilterObj = _.find(aFilters, { FilterHdl: cFilterHdl });
      // set filter batch size and sorting
      if (oFilterObj) {
        this.iBatchSize = oFilterObj.RowsToBatch;

        if (oFilterObj.Sort.length > 0) {
          this.sort = {
            col: this.dhx.getColIndexById(oFilterObj.Sort.split(',')[0]),
            name: oFilterObj.Sort.split(',')[0],
            dir: oFilterObj.Sort.split(',')[1]
          };

          this.oSortState = {
            state: true,
            iCol: this.sort.col,
            order: this.sort.dir
          };

          oQuery.setSorting([{ field: this.sort.name, direction: this.sort.dir }]);
        }
      }

      this.dataSource.setFilter(this.filter, this);
      const oSelf = this;
      oSelf.dataSource.addAfterCatalogAdd(() => {
        if (oSelf.opt.batchingMode) {
          oSelf.dataSource.stop = false;
          oSelf.dataSource.setBatch(0, parseInt(oSelf.iBatchSize));

          oSelf.dataSource.urlQuery.skip = 0;
          oSelf.dataSource.urlQuery.top = parseInt(oSelf.iBatchSize);

          oSelf.dataSource.openQuery({});
          oSelf._renderMultiSelectStateAfterFill();
        } else {
          oSelf.dataSource.setBatch(0, parseInt(oSelf.iBatchSize));
          oSelf.dataSource.openQuery(oOpen);
          oSelf._renderMultiSelectStateAfterFill();
        }
      });
    },

    // use filter ************************
    useFilter: function(oElm) {
      const oFilter = this.prop.filter,
        oOpen = { filterGo: 1 };

      // clear filter fields before
      this.FilterClear();

      this.aDynSelectStatus.aSelectedItems = [];
      this.aDynSelectStatus.aSelectedValues = [];

      // now set filterfields in header
      for (const i in oFilter) {
        if (oElm.clickId && oFilter[i].hdl == oElm.clickId) {
          this.setFilterFields(oFilter[i].definition);

          try {
            const oFilterElm = this.dynObject.getLink('FILTER:SOURCE'),
              oCombo = oFilterElm ? oFilterElm.getField('rowsToBatch') : null,
              oRowsToBatch = oCombo ? oCombo.controller : null;

            oRowsToBatch && oRowsToBatch.setFieldValue(oFilter[i].rowsToBatch);
            this.rowsToBatch(oFilter[i].rowsToBatch);
          } catch (e) {
            akioma.log.error('not found', e);
          }

          if (oFilter[i].sort) {

            this.sort = {
              col: this.dhx.getColIndexById(oFilter[i].sort.split(',')[0]),
              name: oFilter[i].sort.split(',')[0],
              dir: oFilter[i].sort.split(',')[1]
            };
            if (this.sort) {
              oOpen.sort = this.sort.name;
              oOpen.dir = this.sort.dir;

            }
            // save sort arrow to apply after grid loads
            this.oSortState = {
              state: true,
              iCol: this.sort.col,
              order: this.sort.dir
            };

          } else
            delete this.oSortState;

        }
      }

      this.dataSource.setFilter(this.filter);
      this.dataSource.openQuery(oOpen);
      this._renderMultiSelectStateAfterFill();
    },

    addFilterBTPromise: function() {
      const oSelf = this,
        oSource = this.dataSource,
        oFilter = this.getFilterArray(),
        deferred = $.Deferred();

      akioma.message({
        type: 'input',
        title: akioma.tran('filterManager.filterModal.add.title', { defaultValue: 'New filter' }),
        text: akioma.tran('filterManager.filterModal.add.text', { defaultValue: 'Filter name' }),
        callback: function(result) {
          if (result) {
            const cName = result;
            const oData = {
              SDO: oSource.opt.name, // 'offerd',
              Grid: oSelf.opt.gridName, // 'offer_largeb',
              Name: cName, // 'APoTest2',
              Fields: oFilter.fields.join(','), // 'selfno,selfdesc',
              Values: oFilter.values.join('|'), // 'ak*|test*',
              Operators: oFilter.operators.join(','), // 'MATCHES,MATCHES',
              Rows: oSelf.iBatchSize,
              Sorting: oSelf.sort.name ? `${oSelf.sort.name},${oSelf.sort.dir}` : '' // 'datecreated,des',
            };
            oSelf.opt.rowsToBatch && (oData.rows = oSelf.opt.rowsToBatch);
            akioma.invokeServerTask({
              name: 'Akioma.Swat.FilterBT',
              methodName: 'CreateFilter',
              paramObj: { plcParameter: oData }
            }).done(oResult => {
              oSelf.aFtrHdls.push(oResult.plcParameter.FilterHdl);
              if (oSelf.panelHdrContextMenu)
                oSelf.parent.loadFilterListInMenu(oSelf, oSelf.panelHdrContextMenu, false);

              akioma.notification({ type: 'info', text: `"${oResult.plcParameter.Name}" ${akioma.tran('filterManager.filterMessages.success.add', { defaultValue: 'Filter was added successfully.' })}` });
              deferred.resolve(oResult);
              // add new filter next in header menu
            }).fail(() => {
              akioma.notification({ type: 'error', text: `${akioma.tran('filterManager.filterMessages.error.add', { defaultValue: 'Could not add new filter' })} "${cName}".` });
              deferred.reject();
            });
          } else
            return;
        }
      });

      return deferred.promise();
    },

    // add from businessTask new filter filter
    addFilterBT: function() {
      const oSelf = this,
        oFilter = this.getFilterArray();

      const promiseAddFilter = oSelf.addFilterBTPromise();
      promiseAddFilter.done(oResult => {
        oSelf.aFtrHdls.push(oResult.plcParameter.FilterHdl);
        if (oSelf.panelHdrContextMenu)
          oSelf.panelHdrContextMenu.addRadioButton('child', oSelf.panelHdrContextMenuParentID, oSelf.aFtrHdls.length - 1, oResult.plcParameter.FilterHdl, oResult.plcParameter.Name, 'filtersgroup', false);

        // add new filter
        oSelf.prop.filter.push({
          FilterHdl: oResult.plcParameter.FilterHdl,
          Description: oResult.plcParameter.Name
        });

        // add the filter definitions
        const aDefFields = oFilter.fields;
        const aDefValues = oFilter.values;
        const aDefOperators = oFilter.operators;
        if (aDefFields.length > 0) {
          oSelf.prop.filterDefinitions[oResult.plcParameter.FilterHdl] = [];
          for (const i in aDefFields) {
            oSelf.prop.filterDefinitions[oResult.plcParameter.FilterHdl].push({
              fieldname: aDefFields[i],
              operator: aDefOperators[i],
              value: aDefValues[i]
            });
          }
        }
      });
    },

    // add new filter (save) *******************
    filterAdd: function(oElm) {
      const oSelf = this,
        oToolbar = oElm.caller,
        oSource = this.dataSource,
        oFilter = this.getFilterArray();

      const oBox = dhtmlx.modalbox({
        title: 'Neuer Filter',
        text: 'Name für Filter: <input type=\'text\' class=\'w4-inputField\' />',
        buttons: [ 'Ok', 'Abbruch' ],
        callback: function(result) {
          if (result == 0) {
            const cName = $(oBox).find('input').val();
            if (!cName)
              return;

            const oData = {
              action: 'addFilter',
              SDO: oSource.opt.SDO,
              grid: oSelf.opt.gridName,
              Name: cName,
              Fields: oFilter.fields.join(','),
              Values: oFilter.values.join('|'),
              Operators: oFilter.operators.join(','),
              Sorting: oSelf.sort.name ? `${oSelf.sort.name},${oSelf.sort.dir}` : ''
            };

            oSelf.opt.rowsToBatch && (oData.rows = oSelf.opt.rowsToBatch);

            // add filter
            $.ajax({
              type: 'POST',
              async: false,
              url: '/akioma/getdata.xml',
              data: oData,
              dataType: 'json',
              success: function(data) {
                // validate data
                if (!data.status || !data.addFilter || !data.filterhdl)
                  return;


                oSelf.prop.filter.push(app.grid.convertFilter(oSelf, {
                  definition: [],
                  desc: data.addFilter,
                  hdl: data.filterhdl,
                  isclear: false,
                  isdefault: false,
                  issystem: false
                }));

                // add new combooption
                if (oToolbar) {
                  const oDhxTool = oToolbar.dhx;
                  if (oToolbar) {
                    oDhxTool.addListOption('tbfilterChooseFilter', data.filterhdl, oSelf.prop.filter.length, 'button', data.addFilter, '');
                    oDhxTool.setListOptionSelected('tbfilterChooseFilter', data.filterhdl);
                    oDhxTool.setItemText('tbfilterChooseFilter', data.addFilter);
                  }
                }
              },
              error: function(xhr, textStatus, errorThrown) {
                akioma.log.error(`Error getting data for filter ${oSource.opt.SDO}: ${textStatus} -> ${errorThrown}`);
              }
            });
          }
        }
      });
    },

    // filter save in businessTask
    saveFilterBT: function() {
      const oSelf = this,
        oSource = this.dataSource;

      const cFilter = this.cSelectedFilterHdl;
      // get selected filter
      if (cFilter) {
        const oFilter = oSelf.getFilterArray(),
          oData = {
            SDO: oSource.opt.name.toLowerCase(),
            Grid: oSelf.opt.gridName,
            FilterHdl: cFilter,
            Fields: oFilter.fields.join(','),
            Values: oFilter.values.join('|'),
            Operators: oFilter.operators.join(','),
            Rows: oSelf.iBatchSize,
            Sorting: oSelf.sort.name ? `${oSelf.sort.name},${oSelf.sort.dir}` : ''
          };

        oSelf.opt.rowsToBatch && (oData.rows = oSelf.opt.rowsToBatch);

        akioma.invokeServerTask({
          name: 'Akioma.Swat.FilterBT',
          methodName: 'UpdateFilter',
          paramObj: { plcParameter: oData }
        }).done(oResult => {
          if (oSelf.panelHdrContextMenu)
            oSelf.parent.loadFilterListInMenu(oSelf, oSelf.panelHdrContextMenu, false);


          if (oResult.plcParameter.Status == 'error')
            akioma.notification({ type: 'error', text: oResult.plcParameter.Message });
          else
            akioma.notification({ type: 'info', text: akioma.tran('filterManager.filterMessages.success.save', { defaultValue: 'Selected filter saved.' }) });

          // update filter
        }).fail(e => {
          console.warn(e);
          akioma.notification({ type: 'error', text: akioma.tran('filterManager.filterMessages.error.save', { defaultValue: 'Error saving selected filter.' }) });
        });
      }
    },

    // filter save **************************
    filterSave: function(oElm) {
      // get button and toolbar
      const oToolbar = oElm.caller,
        oSource = this.dataSource,
        oSelf = this;

      // get actual filter handle
      const oDhxTool = oToolbar.dhx;

      const cFilter = oDhxTool.getListOptionSelected('tbfilterChooseFilter');
      if (cFilter) {
        const oFilter = this.getFilterArray(),
          oData = {
            action: 'setFilter',
            SDO: oSource.opt.name,
            grid: this.opt.gridName,
            Filter: cFilter,
            Fields: oFilter.fields.join(','),
            Values: oFilter.values.join('|'),
            Operators: oFilter.operators.join(','),
            Sorting: this.sort.name ? `${this.sort.name},${this.sort.dir}` : ''
          };

        oSelf.opt.rowsToBatch && (oData.rows = oSelf.opt.rowsToBatch);

        // save filter
        $.ajax({
          type: 'POST',
          url: '/akioma/getdata.xml',
          data: oData,
          dataType: 'json',
          success: function(data) {
            if (data.ok) {
              const oDef = $.grep(oSelf.prop.filter, oElm => oElm.hdl == cFilter);
              if (oDef[0]) {
                const oFilter = oDef[0];
                oFilter.definition = [];
                app.grid.convertFilter(oSelf, oFilter);
                oFilter.sort = oData.Sorting;
              }
            }
          },
          error: function(xhr, textStatus, errorThrown) {
            akioma.log.error(`Error getting data for filter ${oSource.opt.SDO}: ${textStatus} -> ${errorThrown}`);
          }
        });
      }
    },

    promiseRemoveFilterBT: function() {
      const oSelf = this,
        deferred = $.Deferred();

      // get selected filter
      const cFilter = this.cSelectedFilterHdl;
      if (cFilter) {
        akioma.message({
          type: 'confirm',
          title: akioma.tran('filterManager.filterModal.remove.title', { defaultValue: 'Remove filter' }),
          text: akioma.tran('filterManager.filterModal.remove.text', { defaultValue: 'Should the filter really be removed?' }),
          callback: function(result) {
            const oData = { FilterHdl: cFilter };
            if (result) {
              akioma.invokeServerTask({
                name: 'Akioma.Swat.FilterBT',
                methodName: 'DeleteFilter',
                paramObj: { plcParameter: oData }
              }).done(oResult => {
                if (cFilter)
                  oSelf.panelHdrContextMenu.removeItem(cFilter);

                if (cFilter == oSelf.cDefaultFilterHdl)
                  oSelf.cDefaultFilterHdl = '';

                deferred.resolve(oResult);
                // oSelf.panelHdrContextMenu.removeItem(cFilter);
                akioma.notification({ type: 'info', text: akioma.tran('filterManager.filterMessages.success.remove', { defaultValue: 'Selected filter removed.' }) });
                // delete filter
              }).fail(() => {
                deferred.reject();
                akioma.notification({ type: 'error', text: akioma.tran('filterManager.filterMessages.error.remove', { defaultValue: 'Could not remove selected filter.' }) });
              });
            }

          }
        });
      }
      return deferred.promise();
    },

    // remove filter using BT
    removeFilterBT: function() {
      const oSelf = this;
      const promiseRemoveFilter = oSelf.promiseRemoveFilterBT();

      promiseRemoveFilter.done(() => {
        const cFilter = oSelf.cSelectedFilterHdl;
        if (cFilter)
          oSelf.panelHdrContextMenu.removeItem(cFilter);
      });
    },

    // filter delete **************************
    filterDelete: function(oElm) {
      const oSelf = this;

      // get button and toolbar
      const oToolbar = oElm.caller;
      const oSource = this.dataSource;

      // get actual filter handle
      const oDhxTool = oToolbar.dhx;

      const cFilter = oDhxTool.getListOptionSelected('tbfilterChooseFilter');
      if (cFilter) {
        akioma.message({
          type: 'confirm',
          title: 'Datensatz löschen',
          text: 'Soll der Filter wirklich gelöscht werden?',
          callback: function(result) {
            if (result) {
              // save filter
              $.ajax({
                type: 'POST',
                async: false,
                url: '/akioma/getdata.xml',
                data: `${'Action=deleteFilter'
                  + '&SDO='}${oSource.opt.SDO
                }&grid=${oSelf.opt.gridName
                }&Filter=${cFilter}`,
                dataType: 'json',
                success: function() {
                  // remove filter view and definition
                  oDhxTool.removeListOption('tbfilterChooseFilter', cFilter);
                  let iNum;
                  for (iNum in oSelf.prop.filter) {
                    // do nothing
                  }
                  if (iNum > -1)
                    oSelf.prop.filter.splice(iNum, 1);

                  // select first filter
                  if (oSelf.prop.filter.length > 0) {
                    oDhxTool.setListOptionSelected('tbfilterChooseFilter', oSelf.prop.filter[0].hdl);
                    oDhxTool.setItemText('tbfilterChooseFilter', oSelf.prop.filter[0].desc);
                  }
                },
                error: function(xhr, textStatus, errorThrown) {
                  akioma.log.error(`Error getting data for filter ${oSource.opt.SDO}: ${textStatus} -> ${errorThrown}`);
                }
              });
            }
          }
        });
      }
    },

    // setts currently selected filter
    setDefFilterBT: function() {
      const oSelf = this,
        oSource = this.dataSource;

      const cFilter = this.cSelectedFilterHdl;

      if (cFilter) {
        //  {SDO: 'offerd', Grid: 'offer_largeb', FilterHdl: '<FilterHdl_from_create>'}
        const oData = {
          SDO: oSource.opt.name.toLowerCase(),
          Grid: oSelf.opt.gridName,
          FilterHdl: cFilter
        };
        return akioma.invokeServerTask({
          name: 'Akioma.Swat.FilterBT',
          methodName: 'SetDefaultFilter',
          paramObj: { plcParameter: oData }
        }).done(oResult => {
          // set filter as default filter
          oSelf.cDefaultFilterHdl = oResult.plcParameter.FilterHdl;
          akioma.notification({ type: 'info', text: akioma.tran('filterManager.filterMessages.success.default', { defaultValue: 'Selected filter has been set as default filter.' }) });
        });
      }
    },

    // filter standard ***********************
    filterStandard: function(oElm) {
      // get button and toolbar
      const oToolbar = oElm.caller,
        oSource = this.dataSource,
        oDhxTool = oToolbar.dhx,
        oSelf = this;

      const cFilter = oDhxTool.getListOptionSelected('tbfilterChooseFilter');

      if (cFilter) {
        // save filter
        $.ajax({
          type: 'POST',
          async: false,
          url: '/akioma/getdata.xml',
          data: {
            Action: 'defaultFilter',
            SDO: oSource.opt.SDO,
            grid: this.opt.gridName,
            Filter: cFilter
          },
          dataType: 'json',
          success: function(data) {
            if (data.ok) {
              const oDef = $.grep(oSelf.prop.filter, oElm => {
                if (oElm.isdefault) {
                  oElm.isdefault = false;
                  oElm.desc = oElm.desc.substring(2);
                  oDhxTool.setListOptionText('tbfilterChooseFilter', oElm.hdl, oElm.desc);
                }
                return oElm.hdl == cFilter;
              });
              if (oDef[0]) {
                oDef[0].isdefault = true;
                oDef[0].desc = `# ${oDef[0].desc}`;
                oDhxTool.setListOptionText('tbfilterChooseFilter', cFilter, oDef[0].desc);
                oDhxTool.setItemText('tbfilterChooseFilter', oDef[0].desc);
              }
            }
          },
          error: function(xhr, textStatus, errorThrown) {
            akioma.log.error(`Error getting data for filter ${oSource.opt.SDO}: ${textStatus} -> ${errorThrown}`);
          }
        });
      }
    },

    // setts currently selected filter
    promiseRenameFilterBT: function(cText) {
      const oSelf = this,
        deferred = $.Deferred();

      // get selected filter here
      const cFilter = this.cSelectedFilterHdl;

      // get button and toolbar
      if (cFilter) {
        let cCurrentText = cText || oSelf.panelHdrContextMenu.getItemText(cFilter);
        cCurrentText = (cCurrentText.charAt(0) == '#') ? cCurrentText.substring(1) : cCurrentText;
        akioma.message({
          type: 'input',
          title: akioma.tran('filterManager.filterModal.rename', { defaultValue: 'Rename filter' }),
          text: akioma.tran('filterManager.filterModal.add.text', { defaultValue: 'Filter name' }),
          inputVal: cCurrentText,
          callback: function(result) {
            if (result) {
              const cName = result;
              if (cFilter) {
                // save filter
                const oData = { FilterHdl: cFilter, Name: cName };
                akioma.invokeServerTask({
                  name: 'Akioma.Swat.FilterBT',
                  methodName: 'RenameFilter',
                  paramObj: { plcParameter: oData }
                }).done(oResult => {
                  if (oSelf.panelHdrContextMenu)
                    oSelf.panelHdrContextMenu.setItemText(cFilter, oResult.plcParameter.Name);
                  deferred.resolve(oResult);
                  akioma.notification({ type: 'info', text: akioma.tran('filterManager.filterMessages.success.rename', { defaultValue: 'Filter has been renamed successfully.' }) });
                  // rename filter
                }).fail(() => {
                  deferred.reject();
                  akioma.notification({ type: 'info', text: akioma.tran('filterManager.filterMessages.error.rename', { defaultValue: 'Error renaming slected filter.' }) });
                });
              }
            } else
              return;
          }
        });
      }

      return deferred.promise();
    },

    // setts currently selected filter
    renameFilterBT: function() {
      const oSelf = this;

      // get selected filter here
      const cFilter = this.cSelectedFilterHdl;

      // get button and toolbar
      if (cFilter) {
        const promiseRenameFilter = oSelf.promiseRenameFilterBT();
        promiseRenameFilter.done(oResult => {
          oSelf.panelHdrContextMenu.setItemText(cFilter, oResult.plcParameter.Name);
        });
      }
    },

    // filter rename ************************** deprecated
    filterRename: function(oElm) {
      const oSelf = this;
      // get button and toolbar
      const oBox = dhtmlx.modalbox({
        title: 'Filter umbenennen',
        text: 'Name für Filter: <input type=\'text\' class=\'w4-inputField\' />',
        buttons: [ 'Ok', 'Abbruch' ],
        callback: function(result) {
          const cName = $(oBox).find('input').val();
          if (result != 0 || !cName)
            return;

          const oToolbar = oElm.caller,
            oSource = oSelf.dataSource,
            oDhxTool = oToolbar.dhx,
            cFilter = oDhxTool.getListOptionSelected('tbfilterChooseFilter');

          if (cFilter) {
            // save filter
            $.ajax({
              type: 'POST',
              async: false,
              url: '/akioma/getdata.xml',
              data: `${'Action=renameFilter'
                + '&SDO='}${oSource.opt.SDO
              }&grid=${oSelf.opt.gridName
              }&Filter=${cFilter
              }&Name=${cName}`,
              dataType: 'json',
              success: function(data) {
                if (data.ok == true) {
                  const oFilters = oSelf.prop.filter;
                  let defaultOpt = false;
                  for (const i in oFilters) {
                    if (oFilters[i].hdl == cFilter)
                      defaultOpt = oFilters[i].isdefault;

                  }
                  // if cText from default
                  if (defaultOpt)
                    oDhxTool.setListOptionText('tbfilterChooseFilter', cFilter, `# ${cName}`);
                  else
                    oDhxTool.setListOptionText('tbfilterChooseFilter', cFilter, cName);

                  // if iText from default
                  if (defaultOpt)
                    oDhxTool.setItemText('tbfilterChooseFilter', `# ${cName}`);
                  else
                    oDhxTool.setItemText('tbfilterChooseFilter', cName);

                }
              },
              error: function(xhr, textStatus, errorThrown) {
                akioma.log.error(`Error getting data for filter ${oSource.opt.SDO}: ${textStatus} -> ${errorThrown}`);
              }
            });
          }
        }
      });
    },

    // filter info **************************
    WindowFilter: function() {},

    computeRowAkIdAttribute: function(oItem) {
      const itemId = !isNull(oItem.selfhdl) ? oItem.selfhdl.replace(/:/g, '-') : oItem._id;
      return `${this.akId}-${itemId}`;
    },

    /**
     * Method executed on grid cell edit, different stages of edit
     * @param {number} iStage 1-3
     * @param {string} rId Grid row id
     * @param {number} iCol
     * @param {string|boolean} nValue
     * @param {string|boolean} oValue
     * @memberof ak_datagrid
     * @instance
     * @returns {boolean}
     */
    editCell: function(iStage, rId, iCol, nValue, oValue) {
      // get correct column id
      const cId = this.dhx.getColumnId(iCol),
        oSelf = this;

      iCol = this._initColIds.indexOf(cId);
      const gridCell = this.dhx.cells(rId, iCol);

      const oCell = this.childs[iCol];

      // add to list of has changes rows
      // @todo add support for businessEntity 2 !! in designer
      const bCheckBoxEdit = (iStage == 1 && gridCell.isCheckbox());
      if (iStage === 2 || bCheckBoxEdit) {

        const cDataSourceType = oSelf.dataSource.view;
        let bChangedVal = false;

        if (typeof (oCell.opt.dynSelect) !== 'object' && !bCheckBoxEdit)
          bChangedVal = String(nValue) != String(oValue).replace(/^(&nbsp;)+/, '');
        else if (!bCheckBoxEdit)
          bChangedVal = false;
        else
          bChangedVal = true;


        if (cDataSourceType == 'businessEntity' && bChangedVal) {
          const oItem = oSelf.dataSource.dhx.item(rId);
          const cGeneratedRowID = oSelf.computeRowAkIdAttribute(oItem);

          if (oSelf.childs[iCol].opt.colType === 'datetime' || oSelf.childs[iCol].opt.colType === 'date') {
            const newFormat = akioma.date.convertDhtmlxFormat(oSelf.childs[iCol].oDate.cServerFormat);
            // Change format of nValue to match oValue format
            if ((moment(moment(nValue).toDate()).format(newFormat) != oValue || bCheckBoxEdit) && oValue !== null && oValue !== '')
              oSelf._commit('ADD_CHANGED_ROW', cGeneratedRowID);
          } else {
            if (nValue != oValue || bCheckBoxEdit)
              oSelf._commit('ADD_CHANGED_ROW', cGeneratedRowID);

            this.validateCell(iCol, rId, nValue);
          }
        }
      }

      let cEvent, lAdd;
      switch (iStage) {
        case 0: { // check if enabled
          if (!lAdd && oCell.opt.enabled == false)
            return false;

          const lOk = setProperty(this, $.extend({ mode: 'check' }, this.security));
          return lOk;
        }
        case 1:
          if (gridCell.isCheckbox()) {
            if (this.dhx.getSelectedRowId() != rId)
              this.dhx.selectRowById(rId, false, false);
          }
          break;
        case 2:
          cEvent = 'blur';
          break;
      }

      // if we have a valid event
      if (cEvent) {
        if (oCell)
          oCell.editCell(cEvent, rId, nValue, oValue);

      }

      // call cell changed for dynselect, calendar, input column types
      if (iStage === 2)
        this.cellChanged(rId, iCol, nValue);

      return true;
    },

    /**
     * Method called on value change of grid column
     * @param {string} rId Row id of grid
     * @param {number} iIndex Index of column
     * @param {string|boolean} nValue New value
     */
    cellChanged: function(rId, iIndex, nValue) {
      const oSrc = this.dataSource;
      const cell = this.dhx.cells(rId, iIndex);
      const iCellIndex = this._initColIds.indexOf(this.dhx.getColumnId(cell.cell.cellIndex));
      const oSelfCol = this.childs[iCellIndex];

      oSelfCol.cell = cell;
      oSelfCol.rowId = rId;
      oSelfCol.columnIndex = iIndex;


      const akEvent = $(cell.cell).data('akEvent');

      const nakEvent = {};
      nakEvent.currentValue = nValue;
      nakEvent.lastValue = akEvent ? akEvent.currentValue : undefined;

      cell.akEvent = nakEvent;
      $(cell.cell).data('akEvent', nakEvent);

      // select row
      this.dhx.selectRowById(rId, false, false, true);
      oSrc.setFieldValue({ index: rId, name: this.dhx.getColumnId(iIndex), value: nValue, state: 'update' });

      /**
       * Code executed on the client side when a cell value has been changed
       * @event ak_datagridcol#EventAkValidate
       */
      if (!isNull(oSelfCol.opt.validateEvent))
        app.controller.callAkiomaCode(oSelfCol, oSelfCol.opt.validateEvent);

      return true;
    },
    setMaxBatchSize: function(iBatchSize) {
      this.iBatchSize = iBatchSize;
    },
    batchSizeDialog: function() {
      const oSelf = this;
      let batchVal = 50;
      const selectHTML = `<select autofocus class="wf-batch-input" style="width:100px">
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="200">200</option>
                          <option value="500">500</option>
                          <option value="750">750</option>
                          <option value="1000">1000</option>
                        </select>`;

      const wrapper = document.createElement('div');
      wrapper.innerHTML = selectHTML;

      $(wrapper).find('select').change(function() {
        batchVal = this.value;
      });

      akioma.message({
        type: 'custom',
        title: 'Grid Batch Size',
        content: wrapper,
        callback: function(result) {
          if (result) {
            if (batchVal)
              oSelf.setMaxBatchSize(batchVal);

          }
        }
      });
    },

    /**
     * Delete a record in a grid.
     * @param {boolean} [dangerMode=false] Sets the focus on confirm button if it's set to true. Default it's false
     */
    DeleteDB: function(dangerMode = false) {
      const oSrc = this.dataSource,
        oGrid = this.dhx;
      akioma.message({
        type: 'confirm',
        title: akioma.tran('messageBox.title.deleteData', { defaultValue: 'Datensatz löschen' }),
        text: akioma.tran('messageBox.text.deleteData', { defaultValue: 'Sollen die Daten wirklich gelöscht werden?' }),
        dangerMode,
        callback: function(result) {
          // set
          if (result) {
            // update record sends record to server to execute delete
            if (oSrc.checkValidationObservers()) {
              oSrc.setChanged(true, 'deleted');

              const cId = oGrid.getSelectedRowId();

              oSrc.updateRecord({});

              oSrc.dhx.remove(cId);
              oGrid.deleteRow(cId);
            } else
              oSrc.showFormValidationError();

          }
        }
      });
    },

    /**
     * Method called before a row selection has been made,
     * blocks row select if in progress state active for container window.
     * @param   {string}  newRowId       New row id
     * @param   {string}  oldRowId     Old row id
     * @instance
     * @memberof ak_grid
     * @return  {boolean}
     */
    rowBeforeSelect: function(newRowId, oldRowId) {
      // In this event focus should remain on the old row
      if (this.oKeepFilter) {
        $(this.oKeepFilter).focus();
        this.oKeepFilter = false;
      } else
        this.setRowFocus(oldRowId);


      const isRowChanged = newRowId !== oldRowId;

      // Check if blocked by data changes
      try {
        if (isRowChanged && this.dataSource && this.dataSource.hasChanges()) {
          this.dataSource.promptDiscardHasChangesDialog().then(result => {
            if (result)
              this.dhx.selectRowById(newRowId, true, false, true);
          });
          return false;
        }
      } catch (e) {
        this.log.warn(e);
      }

      try {
        let payload = {};

        this.akEvent = {
          newRowId,
          oldRowId
        };

        if (this.beforeSelectBlock) {
          if (this.dynObject.container.controller.hasActiveProgressState)
            return false;

        }

        /**
         * Client side code executed before a row is selected (selected by the user to perform an action on it), e.g. by single click
         * @event ak_datagrid#EventBeforeSelected
         * @type {object}
         */
        if (this.opt.EventBeforeSelected)
          payload = akioma.swat.evaluateCode({ code: this.opt.EventBeforeSelected.replace('$', ''), dynObj: this.dynObject });

        // block selection if promptCursorChange is set in the beforeRowSelect payload
        if (payload && payload.promptCursorChange && !this._preventBeforeSelectPayload) {
          this.dataSource.promptDiscardHasChangesDialog().then(result => {
            if (result) {
              this._preventBeforeSelectPayload = true;
              this.dhx.selectRowById(newRowId, true, false, true);
            }
          });
          return false;
        }
        this._preventBeforeSelectPayload = false;

        return true;

      } catch (e) {
        this.log.warn(e);
        return true;
      }
    },

    // row select ******************************
    rowSelect: function(cRowId, cIndex) {
      const oSelf = this;

      $('div.dhtmlxcalendar_in_input').css('display', 'none');

      if (this.opt.EventBeforeSelected) {
        /**
         * Client side code executed when a row is selected (selected by the user to perform an action on it), e.g. by single click
         * @event ak_datagrid#EventBeforeSelected
         * @type {object}
         */
        app.controller.callAkiomaCode(this, this.opt.EventBeforeSelected);
      }

      if (this.contextMenuId) {
        const oGrid = this.dhx;
        const oCell = oGrid.cells(cRowId, cIndex);
        let iExtra = 0;

        // if in form
        if (oSelf.cSearchInputID)
          iExtra += $(`#${oSelf.cSearchInputID}`).height();


        const iTop = $(oGrid.hdr).height() + iExtra + $(oCell.cell).parent()[0].offsetTop + ($(oCell.cell).parent().height() / 2) - $(oCell.cell).closest('.objbox').scrollTop();
        $(`#${this.contextMenuId}`).attr('rowid', cRowId).css({ top: iTop });
      }

      // set selected index in datasource
      if (this.dataSource.view.toLowerCase() !== 'businessentity2')
        this.dataSource.setIndex(cRowId);
      else
        this.dataSource.setIndex(cRowId, oSelf.opt.entityName);

      if (oSelf.opt.EventRowSelected) {
        /**
         * Client side code executed when a row is selected (selected by the user to perform an action on it), e.g. by single click
         * @event ak_datagrid#EventRowSelected
         * @type {object}
         */
        app.controller.callAkiomaCode(oSelf, oSelf.opt.EventRowSelected);
      }

      if (this.opt.name == 'tranListB') {
        const cTranType = this.dataSource.dynObject.getValue('reltype');
        if (cTranType == 'Desc') {
          this.dynObject.container.getObject2('itTextBase').controller.parent.dhx.showView('dummy');
          this.dynObject.container.getObject2('itTextTarget').controller.parent.dhx.showView('dummy');
        } else {
          this.dynObject.container.getObject2('itTextBase').controller.parent.dhx.showView('def');
          this.dynObject.container.getObject2('itTextTarget').controller.parent.dhx.showView('def');
        }

      }

      const cObjectID = this.opt.name + this.opt.linkid;

      app.messenger.publish({
        link: {
          LinkName: 'DISPLAY',
          LinkType: 'TRG',
          ObjName: cObjectID
        },
        method: 'dataAvailable',
        caller: this
      });


      // Used to keep filter focus after refresh
      if (oSelf.oKeepFilter) {
        $(oSelf.oKeepFilter).focus();
        oSelf.oKeepFilter = false;
      } else
        oSelf.setRowFocus(cRowId);


      return true;
    },

    /**
     * Method used for enabling before selecting a row, used for unblocking  key nav
     * @instance
     * @memberof ak_datagrid
     * @return  {void}
     */
    enableBeforeSelectBlocking: function() {
      this.beforeSelectBlock = true;
    },

    /**
     * Method used for disabling before selecting a row, used for blocking temporary key nav while loading
     * @instance
     * @memberof ak_datagrid
     * @return  {void}
     */
    disableBeforeSelectBlocking: function() {
      this.beforeSelectBlock = false;
    },

    /**
     * Method for setting the selection details
     * @private
     * @instance
     * @memberOf ak_datagrid2
     * @param {object} oObj
     */
    setKeepSelection: function(oObj) {
      this.oKeepSelectionData = oObj;
    },

    /**
     * Method used for setting the keepSelection data after a refresh of data
     * @private
     * @instance
     * @memberOf ak_datagrid2
     * @param {object} oObj
     * @return {void}
     */
    keepSelection: function() {
      if (this.oKeepSelectionData) {
        const oSettings = this.oKeepSelectionData;
        const identifier = oSettings.identifier;
        const value = oSettings.value;
        const aVals = value.split(',');
        for (const v in aVals) {
          let cId = this.dataSource.getIdFrom(identifier, aVals[v]);
          if (cId !== undefined)
            this.dhx.selectRowById(cId, true, false, true);
          else {
            cId = this.dataSource.dhx.first();
            this.dhx.selectRowById(cId, true, false, true);
          }
        }
      } else {
        const cId = this.dataSource.getStore(this.dataSource.entityName).first();

        if (cId)
          this.dhx.selectRowById(cId, true, false, true);

      }
    },

    /**
     * Method used for checking if scroll bottom has been reached
     * @instance
     * @memberOf ak_datagrid2
     * @return {boolean} True, if bottom has been reached, otherwise, false
     */
    hasReachedScrollBottom() {
      return (this.dhx.objBox.scrollTop + this.dhx.objBox.offsetHeight) >= this.dhx.objBox.scrollHeight;
    },

    /**
     * Method used for getting the selected records
     * @instance
     * @memberOf ak_datagrid2
     * @return {array} The selected records
     */
    getSelectedRecords: function() {
      const oSelf = this;
      const aItems = [];
      if (oSelf.oKeepSelectionData) {
        const oSettings = oSelf.oKeepSelectionData;
        const identifier = oSettings.identifier;
        const value = oSettings.value;
        const aVals = value.split(',');
        for (const v in aVals) {
          const cId = oSelf.dataSource.getIdFrom(identifier, aVals[v]);
          if (cId) {
            const item = oSelf.dataSource.dhx.item(cId);
            aItems.push(item);
          }
        }
      } else {
        const item = oSelf.dataSource.getSelectedRecord();
        aItems.push(item);
      }
      return aItems;
    },


    // showfolder ******************************
    showFolder: function(cRowId, cIndex) {
      // check for folder
      const cFolder = this.cols.folder[cIndex];
      if (cFolder) {

        // get column
        const oCol = this.childs[cIndex];
        if (oCol) {

          // call dialog
          app.controller.launchContainer({
            proc: 'launchContainer.r',
            para: `RunFile=${cFolder}&SelfHdl=${cRowId}&Page=0,1`,
            data: true,
            extLink: cRowId,
            self: this
          });
        }
      }
    },

    // row dblclick *******************************
    rowDblClick: function(iRow) {
      // set selected index in datasource
      this.dataSource.setIndex(iRow);
      this.fileOpen();
      return true;
    },

    // open file ****************************
    fileOpen: function() {
      // get actual element
      const oGrid = this.dhx;
      const cRowId = oGrid.getSelectedRowId();

      let cHdl = '';

      // if rowid is valid -> call routine
      if (cRowId) {
        const oSource = this.dataSource.dhx;
        const oItem = oSource.item(cRowId);
        cHdl = oItem[this.dataSource.opt.identifier];

        // check if we are in popup
        const oPopup = this.getAncestor('popup');
        if (oPopup && oPopup.transferField) {

          // get target
          oPopup.transferField({
            dataSource: this.dataSource,
            rowId: cRowId
          });

          dhtmlx.delay(() => {
            oPopup.close();
          });
          return true;
        }

        // check if we have to run a program
        const cAction = this.opt.action;
        if (cAction == 'RUN') {
          // activate window
          app.controller.launchContainer({
            self: this,
            proc: this.opt.actionLink,
            para: `SelfHdl=${cHdl}&Page=0,1`,
            extLink: cHdl,
            data: true
          });
        }
      }
    },

    // add row ****************************
    recordAdd: function() {
      const oGrid = this.dhx;

      // add new record in datasource
      this.dataSource.addRecord({});

      // get actual record
      const cRow = `${this.dataSource.dhx.getCursor()}`;
      oGrid.selectRowById(cRow, false, true);

      // open cells for all create fields
      $.each(this.childs, function(i) {
        oGrid.setCellExcellType(cRow, i, this.opt.createType);
      });

      // get in edit mode
      oGrid.selectCell(cRow, 0);
      oGrid.editCell();
    },

    // save row *******************************
    recordSave: function(oElm) {
      // stop edit mode
      this.dhx.editStop();

      // send update to datasource
      //
      if (this.dataSource.view.toLowerCase() == 'businessentity')
        this.dataSource.updateRecord({});
      else
        this.dataSource.updateRecord({ entityTable: oElm.entityTable });
    },

    // copy row **********************************
    Copy: function() {
      // get selected element
      const oGrid = this.dhx;
      const cRowId = oGrid.getSelectedRowId();
      // if valid rowid -> save it for copy
      if (cRowId)
        this.copyGrid = cRowId;
    },

    // paste row **********************************
    Paste: function() {
      // check if we do have a copied row
      const cCopyId = this.copyGrid;
      if (cCopyId) {

        // copy row contents to new selected row
        // get selected element
        const oGrid = this.dhx;
        const cRowId = oGrid.getSelectedRowId();

        const oSrc = this.dataSource;
        if (oSrc) {
          const aException = new Array();

          // get exceptions -> copy only fields which are readable
          oGrid.forEachCell(cRowId, (cell, index) => {
            if (oGrid.getColType(index) == 'ron')
              aException.push();
          });
          oSrc.copyRecord(cRowId);
          oSrc.copyContent(cCopyId, `${cRowId}Upd`);
        }
      }
    },

    // add record ********************
    fileAddBrowser: function() {
      // check if we have to call a dialog
      if (this.opt.recordCreate) {
        // call dialog
        app.controller.launchContainer({
          proc: `${this.opt.recordCreate}.r`,
          data: true,
          self: this
        });
      } else { // if not -> add row
        // check if we have to run a program
        const cAction = this.opt.action;
        if (cAction == 'RUN') {

          // activate window
          app.controller.launchContainer({
            self: this,
            proc: this.opt.actionLink,
            para: 'SelfHdl=&Page=0,1',
            add: true
          });
        }
      }
    },

    // drag in ***********************
    dragIn: function() {
      return true;
    },

    // drag in ***********************
    dragOut: function() {
      return true;
    },

    // rows to batch
    rowsToBatch: function(oElm) {
      const rows = (typeof oElm == 'object') ? oElm.clickId : oElm;
      this.opt.rowsToBatch = rows;
    },

    /**
     * Method handling focusOut event on Grid filters
     * @instance
     * @memberof ak_datagrid2
     * @private
     */
    _onFocusOutFilter: function() {
      const oSelf = this;
      const $selector = $(oSelf.dhx.hdr).find('.hdrcell.filter input');

      $selector.on('focusout', e => {
        oSelf.dhx.setActive(false);
        const gridcol = oSelf.childs.find(child => child.dhx === e.currentTarget);

        if (gridcol && gridcol.opt.leaveEvent) {
          oSelf.leaveEventPromise = callReturnAkiomaCode(gridcol, gridcol.opt.leaveEvent);
          if (!isNull(oSelf.leaveEventPromise)) {
            oSelf.leaveEventPromise.catch(() => {
              console.error();
            });
          }
        }

        if (oSelf.opt.filterOnLeave) {
          try {
            const bActiveWindow = oSelf.dynObject.container.controller.dhx.isOnTop();
            if (bActiveWindow) {

              // check if input from grid header is in focus
              if ($.contains(oSelf.dhx.hdr, e.currentTarget)) {
                const filterAkId = $(e.currentTarget).closest('td').attr('akId'); // akId is a unique identifier for filter input
                if (oSelf.filterPrevValues[filterAkId] != $(e.currentTarget).val())
                  oSelf.FilterGo();
              }

            }
          } catch (e) {
            akioma.log.error('Error on focusOut', e);
          }
        }
      });
    },

    /**
     * Method handling focusIn event on Grid filters
     * @instance
     * @memberof ak_datagrid2
     * @private
     */
    _onFocusInFilter: function() {
      const oSelf = this;

      const $selector = $(oSelf.dhx.hdr).find('.hdrcell.filter input');
      $selector.on('focusin', e => {
        try {
          oSelf.dhx.setActive(true);
          if (oSelf.opt.filterOnLeave) {
            const bActiveWindow = oSelf.dynObject.container.controller.dhx.isOnTop();
            if (bActiveWindow) {

              // check if input from grid header is in focus
              if ($.contains(oSelf.dhx.hdr, e.currentTarget)) {
                const filterAkId = $(e.currentTarget).closest('td').attr('akId'); // akId is a unique identifier for filter input
                oSelf.filterPrevValues[filterAkId] = $(e.currentTarget).val();
              }
            }
          }
        } catch (e) {
          akioma.log.error('Error on focusIn', e);
        }
      });
    },

    // destroy *********************************
    destroy: function() {
      if (this.dhx.gridCalendar) {
        this.dhx.gridCalendar.unload();
        this.dhx.gridCalendar = null;
      }
      Mousetrap.unbind(`enter.${this.opt.id}`);

      $(this.dhx.entBox).find('.filterforgridcolhdr').off();
      $(this.dhx.entBox).find('.xhdr tr:nth-child(3) td').off();

      // deletes column pointers, in header
      $(this.dhx.hdrBox).find('input,.select2-selection,.logicalFilter').each(function() {
        delete this.column;
      });

      if (akioma.aLoadedTGF) {
        // screen
        delete akioma.aLoadedTGF[`ak_${this.view}_${this.opt.name}`];
      }

      for (const o in this.aPanelHdrDynselects)
        this.aPanelHdrDynselects[o].businessEntity.destroy();


      this.aPanelHdrDynselects = [];

      if (this.contextMenuObject)
        this.contextMenuObject.destroy();


      this.headerInputMasks.forEach(input => {
        input.inputmask('remove');
      });

      delete this.headerInputMasks;

      this.oMouseTrap.reset();
      delete this.oMouseTrap;

      this.dhx.destructor();
      this.dhx = null;
    }
  });
})(jQuery, jQuery);
