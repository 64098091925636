$.extend({

  ak_block: function(options) {
    const defaults = {};

    akioma.BaseFormFieldContainer.call(this);

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;
    this.registerVuexWatcher = true;

    this.setOriginalSizes();
    // get parent
    const oParent = this.parent;
    if (oParent) {

      this.prop = {
        labelCol: {},
        fields: [
          {
            type: 'settings',
            position: (this.opt.positionMode) ? this.opt.positionMode : 'label-left',
            labelWidth: (this.opt.labelWidth) ? this.opt.labelWidth : 120,
            noteWidth: (this.opt.noteWidth) ? this.opt.noteWidth : 100,
            labelAlign: 'left'
          }
        ]
      };

      this.opt.label = akioma.tran(`${akioma.getForm(this).opt.name}.${this.opt.name}`, { defaultValue: this.opt.label });

      oParent.prop.fields.push({
        type: 'block',
        name: this.opt.name,
        disabled: this.opt.disabled || false,
        hidden: (this.opt.visible == false),
        className: 'w4-formField w4-inputField',
        list: this.prop.fields
      });

      // append to elements in form
      if (this.parent.view == 'form')
        this.parent.elements.push(this);

    }
    $.extend(this, { elements: [] });
  }
});

Object.assign($.ak_block.prototype, akioma.BaseFormFieldContainer.prototype, {
  componentOptions: function() {
    const oSelf = this;
    return {
      watch: {
        'getters.getCustomStates': {
          fn: function(customStates) {
            oSelf._customStatesWatcher(customStates);
          },
          params: [this.opt.id]
        }
      }
    };
  },
  // finish construct **********
  finishConstruct: function() {
    // get field
    this.form = akioma.getForm(this).dhx;
    this.dhx = null;

    const blockNode = akioma.getDhxFormElement(this.form, this.opt.name);

    this.$domElement = $(blockNode);
    this.$baseDomElement = this.$domElement.find('> div > div > .dhxform_base');

    this.setResponsiveSizes();
    this.domTag = blockNode;
    this._setTabIndex(this.domTag);
  },

  endConstruct: function() {
    if (this.opt.visible == false)
      this.form.hideItem (this.opt.name);
  },

  /*
     * stub for the corresponding form function (only needed for
     * absolute positioning)
     */
  labelLeft: function(oElm) {
    return this.parent.labelLeft(oElm);
  }
});
