/**
 * RefreshEmitterHelper, class for handling data refresh
 * @class RefreshEmitterHelper
 * @extends EventListenerHelper
 * @namespace akioma
 */
akioma.RefreshEmitterHelper = class extends akioma.EventListenersHelper {
  /**
   * Method for refreshing data in external screen
   * @param {object} data Custom data sent via socket.io emit
   * @memberOf RefreshEmitterHelper
   * @returns {void}
   */
  refreshDataExternal(data) {
    // emit refreshScheme event
    const externalScreens = akioma.ExternalScreen.externalScreens;

    for (const screenName of externalScreens) {
      const emitData = {
        type: `${screenName}:refreshScheme`,
        user: app.sessionData.userKey,
        UUIDBrowserSession: akioma.ExternalScreen.UUIDBrowserSession,
        customData: { ...data }
      };

      if (akioma.socket)
        akioma.socket.emit('externalScreen', emitData);
    }

  }

  /**
   * Method for emitting refresh event via EventEmitter and Socket.io
   * @param {string} EventName Name of Event
   * @param {object} State State object
   * @param {boolean} [SocketSend=true] Send event via socket.io, default true
   * @memberOf RefreshEmitterHelper
   * @returns {void}
   */
  emitData(EventName, State, SocketSend = true) {

    akioma.eventEmitter.emit(EventName, State);

    if (SocketSend) {
      this.refreshDataExternal({
        event: EventName[1],
        state: State
      });
    }
  }

  /**
   * Method for refreshing data programatically
   * @param {string} entityName Entity table or list of entity tables
   * @param {object} payload
   * @param {boolean} [sendSocketEvent=true] Default is set to true, will send events via Socket.io
   * @memberOf RefreshEmitterHelper
   */
  refreshData(entityName, payload, sendSocketEvent = true) {
    const entityNames = entityName.split(',');


    // apply for each table
    for (const x in entityNames) {
      const entityName = entityNames[x];

      const State = {
        parentContainer: payload.name,
        lastRowState: payload.lastRowState, // delete or add
        lastUpdatedRecord: payload.lastUpdatedRecord,
        entityNameSourceEmit: entityName
      };

      // first check if using wildcard
      if (!entityName.includes('*')) {

        // send information, from which screen, if it was an add or delete

        this.emitData([ 'dataChanged', entityName ], State, sendSocketEvent);

        // look for begins events setup
        const aBeginsDataChangedEvents = _.filter(Object.keys(akioma.eventEmitter._events), item => {
          if (item.startsWith('dataChanged,') && item.includes('*')) {
            const aSplit = item.split(',');
            const ev = aSplit[1];
            if (ev && entityName.startsWith(ev.replace('*', '')))
              return true;
          }
        });
        // if found, prepare search filterValues
        if (aBeginsDataChangedEvents && aBeginsDataChangedEvents.length > 0) {
          aBeginsDataChangedEvents.forEach(el => {
            // send information, from which screen, if it was an add or delete
            this.emitData([ 'dataChanged', el.split(',')[1] ], State, sendSocketEvent);
          });
        }
      } else {

        // emit socket.io event if begins
        if (sendSocketEvent) {
          this.refreshDataExternal({
            event: entityName,
            state: State
          });
        }

        // look for events that match the wildcard
        const aMatchedEvents = _.filter(Object.keys(akioma.eventEmitter._events), item => {
          if (item.startsWith(`dataChanged,${entityName.replace('*', '')}`))
            return true;

        });

        // emit Refresh event for all matched events
        aMatchedEvents.forEach(item => {
          const SplitEvent = item.split(',');
          this.emitData([ 'dataChanged', SplitEvent[1] ], State, sendSocketEvent);
        });


        // look for begins events setup
        const aBeginsDataChangedEvents = _.filter(Object.keys(akioma.eventEmitter._events), item => {
          if (item.startsWith('dataChanged,') && item.includes('*'))
            return true;

        });

        // if found, prepare search filterValues
        let aEventBeginsFilters;
        if (aBeginsDataChangedEvents && aBeginsDataChangedEvents.length > 0)
          aEventBeginsFilters = aBeginsDataChangedEvents.map(item => item.replace('dataChanged,', '').replace('*', ''));

        // if any of the filterValues match the current Entity, emit refresh event/datachanged
        if (aEventBeginsFilters && aEventBeginsFilters.length > 0) {
          aEventBeginsFilters.forEach(filterVal => {
            if (entityName.startsWith(filterVal)) {
              // send information, from which screen, if it was an add or delete
              this.emitData([ 'dataChanged', `${filterVal}*` ], State, sendSocketEvent);
            }
          });
        }

      }
    }

  }

  /**
   * Emits event for dynObject given
   * @param {dynObject} Object
   * @param {string}  EventName
   * @memberof RefreshEmitterHelper
   */
  Emit(Object, EventName) {
    const Window = this.Window(Object);
    const EntityName = EventName[1];

    const State = {
      parentContainer: Window.name,
      lastRowState: Object.controller.lastRowState, // delete or add
      lastUpdatedRecord: Object.controller.lastUpdatedRecord,
      SourceRefreshScheme: Object.controller.RefreshScheme,
      sourceId: Object.controller.opt.id,
      entityNameSourceEmit: EntityName
    };
    // call parent emitter with payload data
    super.Emit(Object, EventName, State);

    // emit refresh via socket.io
    this.refreshDataExternal({
      event: EntityName,
      state: State
    });
  }
};

akioma.RefreshEmitter = new akioma.RefreshEmitterHelper();

