const handlebarsTemp = {
  listbarDetailTemplate: `
<div class='w4-listBarItem'>
<p class='listbar'>
{{formatIcon imgSrc}}
</p>
<p class='listbar'>{{label}}</p>
<a href='' onClick='return false;' data-id='ListItem-{{id}}' class='close-item-dataview' ></a>
</div>
`
};


dhtmlx.Type.add(dhtmlXDataView, {
  name: 'ListbarDetail',
  template: function(obj) {
    const templateF = Handlebars.compile(handlebarsTemp.listbarDetailTemplate);
    const compiledHtml = templateF(obj);
    return compiledHtml;
  },
  // template: compiledHtml,
  template_loading: 'Loading...',
  height: 50
});
dhtmlx.Type.add(dhtmlXDataView, {
  name: 'ListbarList',
  template: '<img class=\'w4-listBarImg\' src=\'#imgSrc#\' /> #label#',
  template_loading: 'Loading...',
  height: 20
});

(function($) {

  // ********************* listbar ******************
  $.extend({
    ak_listbar: function(options) {
      const defaults = { tree: null };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;

      this.registerDynObject = true;
      this.registerVuexModule = true;

      this.aContextMenus = [];
      this.opt.id = `listbar_${uuid()}`;

      // initialize tab
      const oParent = this.parent.dhx;
      if (oParent) {
        oParent._isCell = false;
        const oAcc = oParent.attachAccordion();
        oParent._isCell = true;

        $.extend(this, { dhx: oAcc });

      } else
        !_isIE && console.error('No valid parent for accordion');

    }
  });

  // methods for listbar
  $.ak_listbar.prototype = {

    // finish construct
    finishConstruct: function() {
      if (this.dataSource)
        this.dhx.bind(this.dataSource.dhx, () => true);

      // add listbar header button
      const oParent = this.parent;
      if (oParent) {
        oParent.dhx.showHeader();
        this.attachContextMenu(oParent);
      }

    },
    attachContextMenu: function(oPanel) {
      const oHeader = oPanel.dhx;
      const oSelf = this;

      const btnConf = { className: 'class-headerGrid-btn', width: 16, height: 16 };
      const popup = oHeader.attachHeaderButton(btnConf, '');
      const layout = popup.attachLayout(125, 140, '1C');

      /* !!!Test*/
      const ribbon = layout.attachRibbon({
        items: [
          {
            id: 'options', type: 'block', text: 'Settings',
            list: [
              { id: 'refresh', type: 'button', text: 'Refresh', img: 'imgs/akioma/refresh-26.png' },
              { id: 'addGroup', type: 'button', text: 'Add Group', img: 'imgs/akioma/add_folder-26.png' }
            ]
          }
        ]
      });
      const r = layout.base.getElementsByClassName('dhx_cell_layout');
      const h = layout.base.getElementsByClassName('dhx_cell_hdr');
      $(h).remove();
      if (r != null && r[0] != null)
        r[0].style.width = '';

      if (layout.dataNodes.ribbonObj != null) layout.dataNodes.ribbonObj.style.width = '';

      /* !!!Test*/
      (function(oSelf) {
        ribbon.attachEvent('onClick', cId => {


          oSelf.contextMenu(cId);


          popup.hide();
        });

      })(oSelf);

      oSelf.aContextMenus.push(popup);
      return;


    },
    contextMenu: function(cId) {

      switch (cId) {
        case 'addGroup':
          this.addGroup();
          break;

        case 'refresh':
          this.dataAvailable({});
          break;

      }
    },

    // add group **********
    addGroup: function() {
      const oList = this,
        cHdl = oList.dataSource.getIndex();
      const oBox = dhtmlx.modalbox({
        title: 'Neue Gruppe',
        text: 'Titel der neuen Gruppe: <input type=\'text\' class=\'w4-inputField\' name=\'newgroup\' />',
        buttons: [ 'Save', 'Cancel' ],
        callback: function(result) {
          if (result == 0) {
            app.controller.callServerMethod('akioma/ak_listbar.r',
              [
                { type: 'iCHAR', value: cHdl },
                { type: 'iCHAR', value: $(oBox).find('input').val() }
              ]);

            oList.dataAvailable({});
            oList.dhx.cells(oList.childs[oList.childs.length - 1].opt.id).open();
          }
        }
      });
    },
    getListBar: function(cHdl) {
      const oSelf = this;

      $.ajax({
        async: true,
        type: 'POST',
        url: '/akioma/getlistbar.xml',
        dataType: 'json',
        data: `Mode=get&ID=${this.opt.id}&Hdl=${cHdl}`,
        success: function(data) {
          try {
            // delete children
            while (oSelf.childs.length > 0)
              oSelf.childs[0].destroy();

            app.controller.parseProc(data, oSelf);
          } catch (e) {
            akioma.log.error(`Error while parsing of 'listbar': ${e}`);
          }
        },
        error: function(xhr, textStatus, errorThrown) {
          akioma.log.error(`Error loading html from 'listbar': ${textStatus} -> ${errorThrown}`);
        }
      });
    },
    // data available
    dataAvailable: function() {
      const oSelf = this;
      // get key
      const cHdl = this.dataSource.getIndex();
      if (cHdl) {
        if (this.dATimeout)
          clearTimeout(this.dATimeout);
        this.dATimeout = setTimeout(() => {
          oSelf.getListBar(cHdl);
        }, 1);
      }
    },

    // show / hide listbar
    tbViewQuickStart: function() {
      // get panel
      const oParent = this.parent;
      if (oParent.view == 'panel') {
        // toggle panel
        oParent.toggleShowHide();
      }
    },

    // get tree ***************
    getTree: function() {
      const oDyn = this.dynObject.getLink('TREE:SOURCE');
      return (oDyn) ? oDyn.controller : null;
    },

    destroy: function() {
      // check if dhtmlx element exists -> destroy all attached elements
      if (this.dhx) {
        try { // for breathing window destroy, fix for stability and performance
          new Promise(resolve => {
            for (const i in this.aContextMenus) {
              const contextMenu = this.aContextMenus[i];
              if (typeof (contextMenu) === 'object' && typeof (contextMenu.unload) === 'function')
                contextMenu.unload();
            }
            resolve();
          });

          // check if we are attached in a dhx element
          this.dhx.detachAllEvents();
          this.dhx.unload();
        } catch (e) {
          !_isIE && console.error([ 'Error destroying', this.view, this.opt.name, e.message ]);
        }
      }
    }
  };
})(jQuery, jQuery);

// methods for accordion -> copy from form
// e= _settings
// i= _initBindSource
// X= _server_feed
// la= _check_data_feed
// ca= _ignore_feed
(function() {

  // dhx4 modif below
  // console.log("accordion modif, check akioma.listbar.js #847372")

  // someone did extension for compressed code based on compressed code,
  // later I can show how to add custom code/modules/extensions into libCompiler

  dhtmlXAccordion.prototype.bind = function(target) {
    window.dhxg.BaseBind.bind.apply(this, arguments);
    target.getBindData(this._settings.id);
  };
  dhtmlXAccordion.prototype.unbind = function(target) {
    window.dhxg.BaseBind._unbind.call(this, target);
  };
  dhtmlXAccordion.prototype._initBindSource = function() {
    if (window.dhxg.isUndefined(this._settings)) {
      this._settings = {
        id: dhtmlx.uid(),
        dataFeed: this._server_feed
      };
      window.dhxg.ui.views[this._settings.id] = this;
    }
  };
  dhtmlXAccordion.prototype._check_data_feed = function(data) {
    if (!this._settings.dataFeed || this._ignore_feed || !data) return true;
    let url = this._settings.dataFeed;
    if (typeof url == 'function')
      return url.call(this, (data.id || data), data);
    url = `${url + (url.indexOf('?') == -1 ? '?' : '&')}action=get&id=${encodeURIComponent(data.id || data)}`;
    this.load(url);
    return false;
  };

  dhtmlXAccordion.prototype.dataFeed = function(value) {
    if (this._settings)
      this._settings.dataFeed = value;
    else
      this._server_feed = value;
  };

  dhtmlXAccordion.prototype.refresh = dhtmlXAccordion.prototype.isVisible = function() {
    return true;
  };

  // akioma custom
  dhtmlXAccordion.prototype.setValues = function() {
    // tell listbar to get new entries
    this.akElm.dataAvailable();
  };
  dhtmlXAccordion.prototype.getValues = function() {
    return true;
  };

})();
