/**
 * EventListenerHelper class for handling Refreshing of datasources
 * @class EventListenersHelper
 * @namespace akioma
 */

akioma.EventListenersHelper = class {
  /** Typically called in EventOnClose in which case only the attributes are valid
   *  the object itself is not valid anymore like name, controller, etc. and probably
   *  none of its function will work
   * @static
   * @param Object
   */
  CleanUp(Object) {

    const Window = this.Window(Object);
    // objects on a window may subscribe on initialize of a tabpage in case they may not
    // subscribe if that tabpage is not opened in that case there is no reference added
    // to the EventListenersDataObject
    if (this.EventListenersDataObject
        && this.EventListenersDataObject[Window.attributes.name]
        && this.EventListenersDataObject[Window.attributes.name][Window.attributes.id]) {
      // unsubscribe the window instance from all events
      this.EventListenersDataObject[Window.attributes.name][Window.attributes.id].forEach(CurrentValue => {
        akioma.eventEmitter.off([CurrentValue.EventName], CurrentValue.Function);
      });

      // remove this window from the eventlisteners object
      delete this.EventListenersDataObject[Window.attributes.name][Window.attributes.id];
    }
  }

  /**
   * @static
   * @param Object the swat object that is subscribed
   * @param EventName
   * @param Function
   */
  Add(Object, EventName, Function) {

    const Window = this.Window(Object);

    // create object for eventlisteners encapsulated in this class
    if (!this.EventListenersDataObject)
      this.EventListenersDataObject = {};

    // create object for this window in the EventListenersDataObject
    // could be SendungDetailWindow which could have several instances active
    if (!this.EventListenersDataObject[Window.name])
      this.EventListenersDataObject[Window.name] = {};

    // create object entry/attribute for this instance
    if (!this.EventListenersDataObject[Window.name][Window.attributes.id])
      this.EventListenersDataObject[Window.name][Window.attributes.id] = new Array();

    // add event handler to eventlistener object, needed for cleaning up see DesktopOnClose
    this.EventListenersDataObject[Window.name][Window.attributes.id].push({
      EventName: EventName,
      Function: Function
    });
    // subscribe eventhandler/Function to the EventName event
    akioma.eventEmitter.on(EventName, Function);
  }

  Emit(Object, EventName, payload) {
    // send information, from which screen, if it was an add or delete
    akioma.eventEmitter.emit(EventName, payload);
  }

  /**
   * @private
   * @param Object any (Simple)Swat object like window, datasource, grid, form, etc.
   * @returns {object} The window where the object resides
   */
  Window(Object) {
    let Window;
    if (Object.container && Object.container.type == 'window') {
      if (Object.container.name == 'mainDesktopW')
        Window = Object.parent;
      else
        Window = Object.container;
    } else if (Object.container && Object.container.type == 'frame')
      Window = Object.container.container;
    else if (!Object.container && Object.attributes.type == 'window')
      Window = Object;
    else
      akioma.notification({ type: 'error', text: 'Could not determine window object in Window function in akioma.EventListenersHelper class.' });
    return Window;
  }
};
