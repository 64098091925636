// ***************** datasource ******************
$.ak_businessEntity2 = class extends akioma.BaseDataSource {
  constructor(options) {
    super(options);
    const oSelf = this;

    this.templateDescription = (this.opt.templateDescription || 'Item {{selfkey}} - {{selfdesc}}');
    this.clientOrderBy = {};

    if (this.opt.ObjectInstanceGuid)
      app.controller.aDataSources[this.opt.ObjectInstanceGuid] = this;

    this.view = 'businessEntity2';
    this.aAfterFillAlways = [];
    this.bSendAllRecords = false;

    try {
      if (this.opt.multiStore) {
        this.aStores = [];
        this.aStoresFromKeys = [];
        this.aStoresConnectorsFromKeys = [];
        this.aStoreConnectors = [];
      } else
        this.dhx = new dhtmlXDataStore({ datatype: 'json' });

      oSelf.bAfterAddCatalog = akioma.restSession.addCatalog(oSelf.catalogURI)
        .then(oSelf.onAfterCatalogAdd.bind(oSelf))
        .catch(oSelf.onAfterCatalogAdd.bind(oSelf));
    } catch (oErr) {
      akioma.log.error('addCatalog Error', oErr);
    }
  }

  throwErrorMessages(request) {
    const oSelf = this;
    let hasSmartMessageError = false;

    for (const x in request.jsrecords) {
      const oRec = request.jsrecords[x];
      const cErrorMsg = oRec.getErrorString();
      if (cErrorMsg) {
        if (cErrorMsg.indexOf('SmartMessage') >= 0) { // if there is at least one SmartMessage
          hasSmartMessageError = true;
          const aErrorSplit = cErrorMsg.split(String.fromCharCode(3));
          const aDefferedObjs = [];
          const aFinalMsg = [];

          for (const i in aErrorSplit) {
            const cCurrentErrorPart = aErrorSplit[i];
            if (cCurrentErrorPart.indexOf('SmartMessage') == 0) {
              let urlMessageRequest = '';
              const mySplitResult1 = cCurrentErrorPart.split('\t').splice(0, cCurrentErrorPart.split('\t').length - 1);
              const mySplitResult = mySplitResult1.concat(cCurrentErrorPart.split('\t').splice(cCurrentErrorPart.split('\t').length - 1, 1)[0].split(String.fromCharCode(4)));

              if (mySplitResult.length >= 2)
                urlMessageRequest = `${urlMessageRequest}/${mySplitResult[1]}/${mySplitResult[2]}`;

              try {
                for (const i in request.batch.operations)
                  this.jsdo._defaultTableRef._processed[request.batch.operations[i].jsrecord.data._id] = null;

              } catch (e) {
                akioma.log.error(e);
              }

              const loadMessages = (oSelf, aFinalMsg, mySplitResult, aDefferedObjs, urlMessageRequest) => {
                // if already temporary cached on BusinessEntity
                if (oSelf.aTempSmartMessages[urlMessageRequest] != undefined) {
                  const result = oSelf.aTempSmartMessages[urlMessageRequest];
                  const oSM = oSelf.getSmartMessagesLoaded(result, mySplitResult);
                  aFinalMsg.push(oSM);
                } else {
                  const oDeffPromiseSmartMessage = $.ajax({ url: `${oSelf.serviceURI}/SmartMessage${urlMessageRequest}` });
                  oDeffPromiseSmartMessage.done(result => {
                    oSelf.aTempSmartMessages[urlMessageRequest] = result;
                    const oSM = oSelf.getSmartMessagesLoaded(result, mySplitResult);
                    aFinalMsg.push(oSM);
                  });
                  aDefferedObjs.push(oDeffPromiseSmartMessage);
                }
              };
              loadMessages(oSelf, aFinalMsg, mySplitResult, aDefferedObjs, urlMessageRequest);
            } else
              aFinalMsg.push({ text: cCurrentErrorPart, modal: true });

          } // end go over each error part

          // after al deffered ended get complete message string
          $.when(...aDefferedObjs).then(() => {
            let cFinalMsg = '';
            let bModal = true;
            let messageType = 'information';
            for (const i in aFinalMsg) {
              cFinalMsg += (`${aFinalMsg[i].text}</br>`);
              bModal = aFinalMsg[i].modal;
              messageType = aFinalMsg[i].type;
            }
            if (bModal) {
              const aAnswers = ['Ok'];
              for (const a in aAnswers)
                aAnswers[a] = akioma.tran(`Modalbox.answer_${aAnswers[a].toLowerCase()}`, { defaultValue: aAnswers[a] });

              dhtmlx.modalbox({
                text: cFinalMsg,
                buttons: aAnswers,
                width: 500
              });
            } else {
              switch (messageType) {
                case 'information':
                case 'question':
                  akioma.notification({ type: 'info', text: cFinalMsg });
                  break;
                case 'error':
                  akioma.notification({ type: 'error', text: cFinalMsg });
                  break;
                case 'warning':
                  akioma.notification({ type: 'warning', text: cFinalMsg });
              }

            }

          });
        } else if (cErrorMsg.indexOf('SmartMessage') < 0 && cErrorMsg.indexOf('_') != 0) { // if there isn't a SmartMessage
          hasSmartMessageError = true;
          akioma.message({
            type: 'error',
            text: cErrorMsg || '500 Internal Server Error',
            moretext: cErrorMsg ? null : JSON.stringify(request.response, null, 4)
          });
        }
      }
    }

    if (!hasSmartMessageError) {
      let cErrorMsg = '';
      if (request.response) {
        Object.keys(request.response).forEach(dsKey => {
          const oDS = request.response[dsKey];
          const prodsErrors = oDS['prods:errors'];

          if (prodsErrors) {
            Object.keys(prodsErrors).forEach(key => {
              const errorList = prodsErrors[key];
              for (const err in errorList)
                cErrorMsg = cErrorMsg ? `${cErrorMsg}\n${errorList[err]['prods:error']}` : errorList[err]['prods:error'];
            });

            cErrorMsg = replaceNewLines(cErrorMsg);
            akioma.message({
              type: 'error',
              text: cErrorMsg || '500 Internal Server Error',
              moretext: cErrorMsg ? null : JSON.stringify(request.response, null, 4)
            });
          }
        });
      }
    }
  }

  // methods for datasource
  // finish construct ************
  finishConstruct() {
    const oSelf = this;

    if (oSelf.jsdo == undefined) {
      // wait for catalog load
      oSelf.callFinishConstruct = true;

      return false;
    }

    if (this.opt.serverProp) {
      const aProp = this.opt.serverProp.split('|');

      let aVal;
      for (const i in aProp) {
        aVal = aProp[i].split('#');
        this.setSrvProp(aVal[0], aVal[1]);
      }
    }

    // set initial namedquery
    if (this.opt.namedQuery)
      this.oNamedQuery = this.opt.namedQuery;

    if (this.opt.onInit)
      app.controller.callAkiomaCode(this, this.opt.onInit);

    // if we are a primary sdo -> link it with frame
    if (this.opt.primarySDO) {
      // check for frame
      const oFrame = this.parent;
      if (oFrame && oFrame.view == 'frame') {
        const cObjName = oFrame.getObjName('DATA', 'TRG');

        const aSource = app.messenger.getObjects(this.dynObject, `DATA:SRC:${cObjName}`);
        if (aSource && aSource.length > 0)
          aSource[0].controller.addObserver(this);

        // now check if parent of frame is a frame -> exchange reference for foreignkey
        if (oFrame.parent.view == 'frame') {
          // if foreignkey is the same
          if (oFrame.parent.opt.foreignKey == this.opt.foreignKey)
            this.opt.extKey = oFrame.parent.opt.extKey;
        }
      }
    }
    if (this.opt.multiStore) {
      for (const t in oSelf.aStores)
        this.bindDataSourceEvents(this.aStores[t]);

    } else
      this.bindDataSourceEvents(this.dhx);

    // changedata with callback to change success
    this.jsdo.subscribe('afterUpdate', (jsdo, record, success, request) => {

      if (oSelf.bSendAllRecords == false) {
        if (success) {
          const oDataConnector = oSelf.getStoreConnector(oSelf.entityName);
          const oDataStore = oSelf.getStore(oSelf.entityName);

          oSelf.jsdo.acceptChanges();

          oDataStore.update(record.getId(), $.extend(oSelf.objectKeysToLowerCase(record.data), { _id: record.getId() }));

          if (oDataConnector.updatedRows[record.data._id] != undefined) {
            oDataConnector.setUpdated(oDataStore.getCursor(), false);
            oDataConnector.setUpdated(record.data._id, false);
          } else {
            oDataConnector.setUpdated(record.getId(), false);
            oDataConnector.setUpdated(oDataStore.getCursor(), false);
          }

          // check successful message signaling
          if (oSelf.opt.signalSuccessfulSave) {
            const cTextSuccessSave = akioma.tran('BusinessEntity.successfulSave', { defaultValue: 'Datensatz erfolgreich gespeichert' });
            akioma.notification({ type: 'info', text: cTextSuccessSave, expire: 5000 });
          }

          // cleanup business entity ui validation
          if (oSelf.oUiActions)
            delete oSelf.oUiActions;

          if (oSelf.dataSource && oSelf.dataSource.view == 'treegrid') {
            const oTree = oSelf.dataSource;
            if (oTree.view == 'treegrid') {
              oTree.refreshAllVisibileNodes(() => {
                if (oTree.bSkipSelect != undefined && !oTree.bSkipSelect) {
                  oTree.dhx.selectRowById(oTree.cNewNodeToSelect, false, true, false);
                  delete oTree.cNewNodeToSelect;
                }
              });
            }
          }
        } else { // other
          const cErrorMsg = request.jsrecord.getErrorString();

          // display normal errors if not smarMessage or uiQuestions
          if (cErrorMsg && cErrorMsg.indexOf('SmartMessage') < 0) {
            if (cErrorMsg.substr(0, 1) != '_') { // then we need to display error if not starting with underscore
              // update operation error

              akioma.showServerMessage(cErrorMsg);
              for (const i in request.batch.operations)
                jsdo._defaultTableRef._processed[request.batch.operations[i].jsrecord.data._id] = null;

              record.rejectRowChanges();
            }
          }
        }
      }
    });

    this.jsdo.subscribe('afterSaveChanges', (jsdo, success, request) => {
      /* number of resource operations invoked by saveChanges() */
      let len;
      if (request.batch)
        len = request.batch.operations.length;

      // check if one request only
      if (oSelf.bSendAllRecords && success) {
        const aKeys = Object.keys(request.jsdo._buffers);
        jsdo.acceptChanges();

        try {
          for (const k in aKeys) {
            let cTableName = aKeys[k];
            if (request.response[jsdo._dataSetName][cTableName] != undefined) {
              const oTableData = request.jsrecords;

              for (const j in oTableData) {
                cTableName = oTableData[j]._tableRef._name;
                const oDataStore = oSelf.getStore(cTableName);
                const oDataConnector = oSelf.getStoreConnector(cTableName);

                const data = oTableData[j].data;
                const oItem = oDataStore.item(data._id);
                if (data['prods:rowState'] && data['prods:rowState'] == 'deleted')
                  continue;

                let updateID;
                if (oItem == undefined) {
                  const el = _.findWhere(oDataStore.data.pull, { id: data.id });
                  if (el)
                    updateID = _.findWhere(oDataStore.data.pull, { id: data.id }).id;
                  else
                    updateID = oDataStore.getCursor();
                } else
                  updateID = data._id;
                if (oItem && oItem.id !== data._id)
                  oDataStore.changeId(oItem.id, data._id);

                oDataStore.update(updateID, $.extend(oSelf.objectKeysToLowerCase(data), { _id: data._id }));
                oDataConnector.setUpdated(updateID, false);
              }
            }
          }
          this.throwErrorMessages(request);
        } catch (e) {
          console.warn(e);
        }
      } else if (oSelf.bSendAllRecords && !success) {
        this.throwErrorMessages(request);
        jsdo.rejectChanges();
      }

      if (oSelf.bSendAllRecords == false) {
        if (!success) {
          /* one or more resource operations invoked by saveChanges() failed */
          for (let idx = 0; idx < len; idx++) {
            const operationEntry = request.batch.operations[idx];
            if (!operationEntry.success) {
              /* handle operation error condition */
              if (operationEntry.response && operationEntry.response._errors &&
                                  operationEntry.response._errors.length > 0) {

                const lenErrors = operationEntry.response._errors.length;
                for (let idxError = 0; idxError < lenErrors; idxError++) {

                  const error = operationEntry.response._errors[idxError];
                  const errorMsg = error._errorMsg;
                  akioma.showError({ text: errorMsg });
                }
              } else {
                const cUiActions = request.batch.operations[0].jsrecord.data.akUiActions;
                // call uiActions
                if (cUiActions != undefined && cUiActions != '') {
                  oSelf.oUiActions = JSON.parse(cUiActions);
                  oSelf.callUiActions(request);
                  oSelf.callUiAttributes();
                }
                jsdo.rejectChanges();
              }
            }
          } /* for each CUD operation */
        } else {
          // remove from list of records with smartmessage errors
          if (request.batch) {
            const iSmartErrIndex = oSelf.aErrRowsSmartMessages.indexOf(request.batch.operations[0].jsrecord.data.id);
            if (oSelf.aErrRowsSmartMessages.indexOf(request.batch.operations[0].jsrecord.data.id) != -1)
              oSelf.aErrRowsSmartMessages.splice(iSmartErrIndex, 1);
          }

          // special case when all requests are sent at once
          if (oSelf.bSendAllRecords)
            oSelf.getStoreConnector(oSelf.entityName).updatedRows = [];

          /* operation succeeded call one time eventAfterSave */
          for (const ev in oSelf._eventAfterSaveChanges) {
            try {
              oSelf._eventAfterSaveChanges[ev](success, jsdo, oSelf.objectKeysToLowerCase(request.batch.operations[0].jsrecord.data));
            } catch (e) {
              akioma.notification({ type: 'error', text: 'Could not call oneTime _eventAfterSaveChanges in businessEntity.' });
            }
          }
          oSelf._eventAfterSaveChanges = [];

          // Notifications handling
          if (request.batch) {
            let oUiActions;
            const cUiActions = request.batch.operations[0].jsrecord.data.akUiActions;
            if (cUiActions)
              oUiActions = JSON.parse(cUiActions);
            if (oUiActions && oUiActions.Notifications) {
              for (const n in oUiActions.Notifications) {
                const notification = oUiActions.Notifications[n];
                const aAnswers = [];

                for (const a in aAnswers)
                  aAnswers[a] = akioma.tran(`Modalbox.answer_${aAnswers[a].toLowerCase()}`, { defaultValue: aAnswers[a] });

                // show notifications here
                oSelf._showNotification(notification, aAnswers);
              }
            }
          }
        }

        // calls events afterSave
        let i = oSelf._aFireAfterSaveCallbacks.length;
        while (i--) {
          const callbackFn = oSelf._aFireAfterSaveCallbacks[i];
          callbackFn(jsdo, success, request, oSelf.objectKeysToLowerCase(request.batch.operations[0].jsrecord.data));
          if (!oSelf.containsSmartMessage())
            oSelf._aFireAfterSaveCallbacks.splice(i, 1);
        }
      }
    });

    // add a simple record
    this.jsdo.subscribe('afterCreate', (jsdo, record, success, request) => {
      if (oSelf.bSendAllRecords == false) {
        if (success) {
          /* Call getErrorString() on each record of request.jsrecords to
              identify records returned with a defined _errorString property
              value
              and handle the errors for any records that have one;
              otherwise do the normal thing for a successful submit operation
              */
          oSelf.jsdo.acceptChanges();
          let oDhxStore;
          let oDataConnector;

          // check successful message signaling
          if (oSelf.opt.signalSuccessfulSave) {
            const cTextSuccessCreate = akioma.tran('BusinessEntity.successfulCreate', { defaultValue: 'Datensatz wurde angelegt' });
            akioma.notification({ type: 'info', text: cTextSuccessCreate, expire: 5000 });
          }

          if (oSelf.opt.multiStore == undefined) {
            oSelf.dhx.update(oSelf.dhx.getCursor(), $.extend(oSelf.objectKeysToLowerCase(record.data), { _id: record.getId() }));
            oDhxStore = oSelf.dhx;
            oDataConnector = oSelf.dc;
          } else {
            const index = Object.keys(oSelf.jsdo._buffers).indexOf(oSelf.entityName);
            oSelf.aStores[index].update(oSelf.aStores[index].getCursor(), $.extend(oSelf.objectKeysToLowerCase(record.data), { _id: record.getId() }));
            oDhxStore = oSelf.aStores[index];
            oDataConnector = oSelf.aStoreConnectors[index];
          }

          // this will fix the id problem after creating
          const oItem = oDhxStore.item(oDhxStore.getCursor());
          if (oItem.id) {
            oDataConnector.setUpdated(oItem.id, false);
            oDataConnector.setUpdated(oDhxStore.getCursor(), false);
          }
          if (oDhxStore.item(oItem.id) == undefined)
            oDataConnector.setUpdated(oDhxStore.getCursor(), false);

          if (oSelf.aCallback['afterCreate']) {

            oSelf.aCallback['afterCreate'](true, oItem);
            delete oSelf.aCallback;
          }

          // check for tree
          if (oSelf.dataSource && oSelf.dataSource.view == 'treegrid') {
            const oTree = oSelf.dataSource;
            if (oSelf.bTreeRefresh != undefined && oSelf.bTreeRefresh != false) {
              oSelf.bTreeRefresh = false;
              oTree.refreshAllVisibileNodes(() => { });
            }
          }
          oDataConnector.setUpdated(record.data._id, false);
        } else {
          const cErrorMsg = request.jsrecord.getErrorString();
          if (cErrorMsg && cErrorMsg.indexOf('SmartMessage') < 0 && cErrorMsg.substr(0, 1) != '_')
            akioma.showServerMessage(cErrorMsg);
          else { // if normal error and not akUiQuestions
            // add to list of records with smartmessage errors
            oSelf.aErrRowsSmartMessages[request.jsrecord.data.id] = request.jsrecord.getId();
          }
          if (request.response && request.response._errors &&
                              request.response._errors.length > 0) {
            const lenErrors = request.response._errors.length;
            for (let idxError = 0; idxError < lenErrors; idxError++) {
              const errorEntry = request.response._errors[idxError];
              const errorMsg = errorEntry._errorMsg;
              /* handle submit operation error */
              akioma.notification({ type: 'error', text: errorMsg });
            }
          }
        }
      }
    });

    // deletedata with callback to change success
    this.jsdo.subscribe('afterDelete', (jsdo, record, success, request) => {
      if (oSelf.bSendAllRecords == false) {
        if (success) {
          /* If before-image data is enabled, call getErrorString()
              on the record to identify a defined _errorString property
              value and handle the error if it has one; otherwise,
              do the normal thing for a successful delete operation.
              For example, get the values from the record that was
              deleted to display a confirmation message */
          oSelf.jsdo.acceptChanges();
          oSelf.getStoreConnector(oSelf.entityName).setUpdated(record.data._id, false);
          // check successful message signaling
          if (oSelf.opt.signalSuccessfulSave) {
            const cTextSuccessDelete = akioma.tran('BusinessEntity.successfulDelete', { defaultValue: 'Datensatz gelöscht' });
            akioma.notification({ type: 'info', text: cTextSuccessDelete, expire: 5000 });
          }
        } else {
          const cErrorMsg = request.jsrecord.getErrorString();
          if (cErrorMsg && cErrorMsg.indexOf('SmartMessage') < 0 && cErrorMsg.substr(0, 1) != '_')
            akioma.showServerMessage(cErrorMsg);

          if (request.response && request.response._errors &&
                              request.response._errors.length > 0) {
            const lenErrors = request.response._errors.length;
            for (let idxError = 0; idxError < lenErrors; idxError++) {
              const errorEntry = request.response._errors[idxError];
              const errorMsg = errorEntry._errorMsg;
              akioma.notification({ type: 'error', text: errorMsg });
              /* handle delete operation error */
            }
          }
        }
      }
    });

    /* end of jsdo */

    if (oSelf.opt.initialFetch !== '#none')
      oSelf.openQuery(oSelf.oQueryParam);
    else
      oSelf.bInitialFetchedReq = true;

    if (oSelf.callOpenQuery && !oSelf.bInitialFetchedReq) {
      oSelf.callOpenQuery = false;
      oSelf.openQuery(oSelf.callOpenQuery.elm, oSelf.callOpenQuery.callback);
    }

    if (this.opt.rowsToBatch)
      this.NumRecords = this.opt.rowsToBatch;
    else
      this.NumRecords = 50;

    return true;
  }

  getStore(cTable) {
    return this.aStoresFromKeys[cTable];
  }

  getStoreConnector(cTable) {
    if (this.opt.multiStore == undefined)
      return this.dc;
    return this.aStoresConnectorsFromKeys[cTable];
  }

  _getSchemaFieldName(cFieldName) {
    const oSelf = this;
    const mapping = [];
    const oSchema = oSelf.jsdo[oSelf.entityName].getSchema();

    for (const j in oSchema) {
      const oSchemaField = oSchema[j];
      mapping[oSchemaField.name.toLowerCase()] = oSchemaField.name;
    }

    if (mapping[cFieldName.toLowerCase()])
      return mapping[cFieldName.toLowerCase()];
  }

  _getSchemaType(cFieldName) {
    const oSelf = this;
    const mapping = [];
    const oSchema = oSelf.jsdo[oSelf.entityName].getSchema();

    for (const j in oSchema) {
      const oSchemaField = oSchema[j];
      mapping[oSchemaField.name.toLowerCase()] = oSchemaField.type;
    }

    if (mapping[cFieldName.toLowerCase()])
      return mapping[cFieldName.toLowerCase()];
  }

  _getObjFromSchema(obj) {
    const newObj = {};
    const oSelf = this;
    obj = oSelf.objectKeysToLowerCase(obj);
    for (const i in obj) {
      const cFieldName = oSelf._getSchemaFieldName(i);
      const cType = oSelf._getSchemaType(i);
      if (cFieldName != undefined) {
        if (cType == 'boolean')
          newObj[cFieldName] = !!obj[i.toLowerCase()];
        else
          newObj[cFieldName] = obj[i.toLowerCase()];

      } else if (newObj[i.toLowerCase()])
        newObj[i] = obj[i];
    }
    return newObj;
  }

  /**
   * Checks if any of the linked controls have changes, recursively.
   *
   * @private
   * @memberof ak_businessEntity
   * @param {Array<ak_businessEntity>} [visitedBEs=[]] Visited business entities.
   * @returns {boolean}
   */
  _recursiveHasChanges(visitedBEs = []) {
    if (visitedBEs.includes(this)) return false;
    visitedBEs.push(this);

    if (isNull(this.dynObject)) return false;

    // go over each linked obeserver and check has changes
    const ObserversData = this.dynObject.getLinks('DATA:TARGET') || [];
    const ObserversDisplay = this.dynObject.getLinks('DISPLAY:TARGET') || [];
    const aAllLinks = ObserversData.concat(ObserversDisplay);

    for (const link in aAllLinks) {
      const obs = aAllLinks[link].controller;

      if ((obs.view === 'form' || obs.view === 'docviewer') && obs.oVuexState.attributes.hasChanges)
        return true;

      if (obs.view == 'businessEntity' || obs.view == 'businessEntity2') {
        if (obs._recursiveHasChanges(visitedBEs))
          return true;
      }
    }

    return false;
  }

  _handleBeforeUpdate(rid, status, obj) {
    const oSelf = this;
    const cFristChar = status[0].toLowerCase();

    obj = oSelf._getObjFromSchema(obj);

    // before update bind all changed records to new values
    // get all objects and call the switch for each

    // console.log('new object from schema', obj);
    switch (cFristChar) {
      case 'u': {
      // ToDo: JSDO tells that the row was updated but after refreshing it looks like it wasn't updated
        oSelf.beforeUpdate(rid, status);

        let oSelectedRecord = oSelf.jsdo[oSelf.entityName].findById(rid);

        // this is for elements created on the fly, because the id is generated by dhtmlx and now we need to get the correct id
        if (oSelectedRecord == null) {
          const index = Object.keys(oSelf.jsdo._buffers).indexOf(oSelf.entityName);
          oSelectedRecord = oSelf.jsdo[oSelf.entityName].findById(oSelf.aStores[index].item(rid)._id);
        }

        if (oSelectedRecord == null) {
          const index = Object.keys(oSelf.jsdo._buffers).indexOf(oSelf.entityName);
          oSelectedRecord = oSelf.jsdo[oSelf.entityName].findById(oSelf.aStores[index].item(rid).id);
        }

        oSelf.jsdo[oSelf.entityName].assign(obj);
        if (!oSelf.bSendAllRecords)
          oSelf.jsdo.saveChanges();
        else
          oSelf.getStore(oSelf.entityName).update(rid, $.extend(oSelf.objectKeysToLowerCase(oSelectedRecord.data), { _id: oSelectedRecord.getId() }));

        break;
      }
      case 'd': {
      // ToDo: JSDO's error appears when we are trying to delete another row (second time)
      // oSelf.jsdo[oSelf.entityName].findById(rid) - record isn't found

        let oSelectedRecord = oSelf.jsdo[oSelf.entityName].findById(rid);

        if (isNull(oSelectedRecord)) {
          const obj = oSelf.jsdo[oSelf.entityName]._data.find(obj => {
            const item = oSelf.objectKeysToLowerCase(obj);
            return item[this.opt.identifier] === rid;
          });
          if (!isNull(obj))
            oSelectedRecord = oSelf.jsdo[oSelf.entityName].findById(obj._id);
        }

        if (oSelectedRecord) {
          oSelf.jsdo[oSelf.entityName].remove();
          if (!oSelf.bSendAllRecords)
            oSelf.jsdo.saveChanges();
        }
        break;
      }
      case 'i': {
        akioma.skipQuery = true;

        let oSelectedRecord = oSelf.jsdo[oSelf.entityName].findById(rid);
        // this is for elements created on the fly, because the id is generated by dhtmlx and now we need to get the correct id
        if (oSelectedRecord == null)
          oSelectedRecord = oSelf.jsdo[oSelf.entityName].findById(oSelf.getStore(oSelf.entityName).item(rid)._id);
        if (oSelectedRecord == null) {
          oSelectedRecord = oSelf.jsdo[oSelf.entityName].add(obj);
          oSelf.jsdo[oSelf.entityName].findById(oSelectedRecord.getId());
          oSelf.getStore(oSelf.entityName).item(rid)._id = oSelectedRecord.getId();
        } else
          oSelf.jsdo[oSelf.entityName].assign(obj);

        if (!oSelf.bSendAllRecords)
          oSelf.jsdo.saveChanges();

        akioma.skipQuery = false;
        break;
      }
    }
  }

  bindOnBeforeDataConnector(oDhxDataConnector) {
    const oSelf = this;
    oDhxDataConnector.attachEvent('onBeforeUpdate', function(rid, status, obj) {
      if (Object.prototype.hasOwnProperty.call(obj, '!nativeeditor_status'))
        delete obj['!nativeeditor_status'];

      akioma.skipQuery = true;
      // normal one record before send data
      if (this.beforeSendDataAll == undefined)
        oSelf._handleBeforeUpdate(rid, status, obj);
      else {
        // all records at once
        for (const r in obj)
          oSelf._handleBeforeUpdate(rid[r], status[r], obj[r]);

      }

      akioma.skipQuery = false;
      return false;
    });

    if (oSelf.opt.initialFetch !== '#none')
      oSelf.openQuery(oSelf.oQueryParam);
    else
      oSelf.bInitialFetchedReq = true;

    if (oSelf.callOpenQuery && oSelf.opt.autoQuery == undefined && !oSelf.bInitialFetchedReq) {
      oSelf.openQuery(oSelf.callOpenQuery.elm, oSelf.callOpenQuery.callback);
      oSelf.callOpenQuery = false;
    }

    if (this.opt.rowsToBatch)
      this.NumRecords = this.opt.rowsToBatch;
    else
      this.NumRecords = 50;

    return true;
  }

  bindDataSourceEvents(oDhxSource) {
    const oSelf = this;
    oDhxSource.attachEvent('onAfterCursorChange', () => {

      if (oSelf.parent) {
        oSelf.setTitle();

        if (akioma.eventEmitter) {
          akioma.eventEmitter.emit([ 'DISPLAY', 'TRG', oSelf.opt.name ], {
            // data1
            elm: oSelf
          });
        }
        const cObjectID = oSelf.opt.name + oSelf.opt.linkid;

        // tell all observers that cursor has been changed
        app.messenger.publish({
          link: {
            LinkName: 'DISPLAY',
            LinkType: 'TRG',
            ObjName: cObjectID
          },
          method: 'cursorChange',
          caller: oSelf
        });
        // tell all observers that cursor has been changed
        app.messenger.publish({
          link: {
            LinkName: 'DATA',
            LinkType: 'TRG',
            ObjName: cObjectID
          },
          method: 'dataAvailable',
          caller: oSelf
        });

        // event onDataAvail
        if (oSelf.opt.onDataAvail) {
          dhtmlx.delay(() => {
            applyAkiomaCode(oSelf, oSelf.opt.onDataAvail);
          });
        }
      }
    });

    oDhxSource.attachEvent('onXLS', function() {
      if (oSelf.batchSize.skip == undefined) // don't clear up if in batchmode
        this.clearAll();
    });

    oDhxSource.attachEvent('onXLE', function() {
      if (this.dataCount() > 0) {
        this.setCursor(this.first());
        oSelf.dataLoaded = true;
      } else if (oSelf.jsdo[oSelf.opt.entityName].record !== undefined &&
        oSelf.jsdo[oSelf.opt.entityName].getData()[0] && oSelf.jsdo[oSelf.opt.entityName].getData()[0]._id) {
        // fix for dhx select node
        if (this.item(oSelf.jsdo[oSelf.opt.entityName].getData()[0]._id) != null)
          this.setCursor(oSelf.jsdo[oSelf.opt.entityName].getData()[0]._id);
        else
          this.setCursor(oSelf.jsdo[oSelf.opt.entityName].getData()[0].id);
      } else
        this.refresh();
      if (oSelf.aAfterFillOnce) {
        for (const i in oSelf.aAfterFillOnce)
          oSelf.aAfterFillOnce[i]();

        oSelf.aAfterFillOnce = [];
      }
    });

    oDhxSource.attachEvent('onAfterAdd', () => {
      try {
        // add all tables from jsdo
        const aTablesNames = [];
        for (const key in oSelf.jsdo._buffers) {
          if (Object.prototype.hasOwnProperty.call(oSelf.jsdo._buffers, key))
            aTablesNames.push(key);

        }

        const aForeignKeyFields = [],
          aForeignKeyValues = [];

        if (akioma.accordionAddForeignKeys != undefined) {
          for (const i in akioma.accordionAddForeignKeys) {
            aForeignKeyFields.push(akioma.accordionAddForeignKeys[i].name);
            aForeignKeyValues.push(akioma.accordionAddForeignKeys[i].value);
          }

          delete akioma.accordionAddForeignKeys;
        }
        if (aForeignKeyFields.length == 0) {
          aForeignKeyFields.push('');
          aForeignKeyValues.push('');
        }
      } catch (e) {
        console.error('Could not load initial values.', e);
      }
    });

    oDhxSource.data.scheme({
      $init: function() {
        oSelf.prop.update = false;
      },
      $update: function() {
        oSelf.prop.update = true;
      }
    });
  }

  /**
   * Switches JSDO working record table - for multitable store
   * @param {string} cEntityName The entity name of the store
   */
  switchJSDOWorkingRecord(cEntityName) {
    try {
      this.opt.entityName = cEntityName;
      this.entityName = cEntityName;
      this.jsdo._buffers[cEntityName]._jsdo.useRelationships = false;
      this.jsdo._buffers[cEntityName]._setRecord(this.jsdo._buffers[cEntityName]._findFirst());
      this.jsdo._buffers[cEntityName]._jsdo.useRelationships = true;
    } catch (e) {
      console.error(e);
    }
  }

  getSmartMessagesLoaded(result, mySplitResult) {
    let oSmartMessageObj = null;
    if (result) {
      let substituteMessage = result.MessageText;

      // check for modal/non-modal
      let bModal = true;
      if (result.MessageBoxStyle)
        bModal = (result.MessageBoxStyle.toLowerCase() != 'nonmodal');

      // replace placeholders globally in text
      for (let iSubstitute = 3; iSubstitute < mySplitResult.length; iSubstitute++) {
        const oSubstRegEx = new RegExp(`&${iSubstitute - 2}`, 'g');
        substituteMessage = substituteMessage.replace(oSubstRegEx, mySplitResult[iSubstitute]);
      }

      // replace CR/LF in SmartMessage text
      substituteMessage = substituteMessage.replace(/(?:\r\n|\r|\n)/g, '<br />');
      oSmartMessageObj = {
        text: substituteMessage,
        modal: bModal
      };
      // set smartmessage message type
      if (result.MessageType)
        oSmartMessageObj.type = result.MessageType.toLowerCase();
    }
    return oSmartMessageObj;
  }

  // on after catalog Add method
  onAfterCatalogAdd(res) {

    const oSelf = this;

    // check for error type object
    if (akioma.isObjOfTypeError(res)) {
      akioma.log.error(res);
      return;
    }

    const { result, info } = res;
    const bError = (result != progress.data.Session.SUCCESS);

    if (oSelf.jsdo == undefined && !bError) {
      oSelf.jsdo = new progress.data.JSDO({ name: oSelf.resourceName });
      oSelf.jsdo.autoApplyChanges = false;
    }

    if (oSelf.opt.multiStore && oSelf.jsdo) {
      // define dataStore for each table
      const aTables = Object.keys(oSelf.jsdo._buffers);
      for (const t in aTables)
        oSelf.aStores[t] = new dhtmlXDataStore({ datatype: 'json' });

    }

    // check for catalog add errors
    if (bError) {

      if (info.result === progress.data.Session.AUTHENTICATION_FAILURE)
        akioma.notification({ type: 'error', text: `Authentication error: <br/>${info.catalogURI}` });
      else if (info.result === progress.data.Session.GENERAL_FAILURE)
        akioma.notification({ type: 'error', text: `General Catalog load error: <br/>${oSelf.opt.resourceName}` });
      else
        akioma.notification({ type: 'error', text: `Catalog load error: <br/>${oSelf.opt.resourceName}` });

    }

    if (oSelf.opt.multiStore) {
      const aTables = Object.keys(oSelf.jsdo._buffers);
      for (const t in aTables) {
        oSelf.aStores[t] = new dhtmlXDataStore({ datatype: 'json' });
        oSelf.aStoresFromKeys[aTables[t]] = oSelf.aStores[t];
      }

      // for each store create dataConnector and bind onBeforeDataConnector event
      for (const t in this.aStores) {
        this.aStoreConnectors[t] = new dataConnector();
        this.aStoresConnectorsFromKeys[aTables[t]] = this.aStoreConnectors[t];
        this.aStoreConnectors[t].init(this.aStores[t]);
        this.bindOnBeforeDataConnector(this.aStoreConnectors[t]);
      }
    } else {
      this.dc = new dataConnector();
      this.dc.init(this.dhx);
      this.bindOnBeforeDataConnector(this.dc);
    }

    if (oSelf._eventAfterCatalogAdd) {
      for (const i in oSelf._eventAfterCatalogAdd)
        oSelf._eventAfterCatalogAdd[i]();

      oSelf._eventAfterCatalogAdd = [];
    }

    if (oSelf.opt.initialFetch !== '#none')
      oSelf.openQuery(oSelf.oQueryParam);
    else
      oSelf.bInitialFetchedReq = true;

    if (oSelf.callFinishConstruct) {
      delete oSelf.callFinishConstruct;
      if (oSelf.continueFinConstr)
        app.controller.parseProc(oSelf.continueFinConstr.data, oSelf.continueFinConstr.parent);
      else
        oSelf.finishConstruct();

      if (oSelf.opt.initialFetch !== '#none') {
        oSelf.openQuery(oSelf.oQueryParam);
        delete oSelf.oQueryParam;
      } else
        oSelf.bInitialFetchedReq = true;

      if (oSelf.callOpenQuery && oSelf.opt.autoQuery == undefined && !oSelf.bInitialFetchedReq) {

        oSelf.openQuery(oSelf.callOpenQuery.elm, oSelf.callOpenQuery.callback);
        oSelf.callOpenQuery = false;
      }
    }
  }

  addAfterFillAlways(fn) {
    const oSelf = this;
    oSelf.aAfterFillAlways.push(fn);
  }

  // record description
  getRecordDescription() {
    const cTemplate = this.templateDescription;
    let result = '';
    const template = Handlebars.compile(cTemplate);
    if (this.jsdo && this.jsdo[this.opt.entityName].record.data) {
      const oData = this.objectKeysToLowerCase(this.jsdo[this.opt.entityName].record.data);

      if (!$.isEmptyObject(oData))
        result = template(oData);
    }

    return result;
  }

  getIdFrom(cFieldName, cFieldValue, entityName) {
    const items = this.getStore(entityName || this.entityName).data.pull;

    for (const i in items) {
      if (cFieldValue == items[i][cFieldName] || cFieldValue == items[i][cFieldName.toLowerCase()])
        return i;
    }
  }

  setMultiTableFiltering(oPar) {
    this.oMultiTableParam = oPar;
  }

  // named query parameter
  setNamedQueryParam(queryName, paramName, paramVal, paramType) {
    try {
      const oSelf = this;

      // init queryname if it wasn't already
      if (oSelf.oNamedQuery == undefined)
        oSelf.oNamedQuery = { name: queryName, parameters: [] };

      // select current query name
      const currentNameQuery = oSelf.oNamedQuery;

      // now set parameter
      if (paramType == undefined)
        paramType = 'char';

      // look if param already added and select
      const aCurParam = $.grep(currentNameQuery.parameters, e => e.name == paramName);

      // if the param doesn't exist already
      if (aCurParam.length == 0) {
        const oParam = {
          'name': paramName,
          'type': paramType,
          'value': paramVal
        };
        currentNameQuery.parameters.push(oParam);
      } else { // if it exists then update values
        aCurParam[0].type = paramType;
        aCurParam[0].value = paramVal;
      }
    } catch (e) {
      akioma.log.error('There was an error setting the businessEntity Named Query: ', e);
    }
  }

  callUiActions() {
    const aReplys = [ 'ReplyYes', 'ReplyNo', 'ReplyOk', 'ReplyCancel' ];
    const oSelf = this;
    const askQuestion = i => {
      const iIndQuestion = i;
      const onSelect = (bSel, i) => {
        switch (bSel) {
          case '0':
            oSelf.oUiActions.Questions[i].MessageReply = aReplys[0];
            break;
          case '1':
            oSelf.oUiActions.Questions[i].MessageReply = aReplys[1];
            break;
          case '2':
            oSelf.oUiActions.Questions[i].MessageReply = aReplys[3];
        }
        askQuestion(i + 1);
      };
      if (oSelf.oUiActions && oSelf.oUiActions.Questions && oSelf.oUiActions.Questions[i]) {
        const oCurrentQ = oSelf.oUiActions.Questions[i];
        const aAnswers = oCurrentQ.MessageButtons.split(/(?=[A-Z])/);

        for (const a in aAnswers)
          aAnswers[a] = akioma.tran(`Modalbox.answer_${aAnswers[a].toLowerCase()}`, { defaultValue: aAnswers[a] });

        // only show the question if unaswered
        if (oCurrentQ.MessageReply.toLowerCase() == 'unanswered') {
          // smartmessage in akuiActions
          if (oCurrentQ.MessageText.indexOf('SmartMessage') > -1) {
            const cErrorMsg = oCurrentQ.MessageText;
            const aErrorSplit = cErrorMsg.split(String.fromCharCode(3));
            const aDefferedObjs = [];
            const aFinalMsg = [];

            for (const i in aErrorSplit) {
              const cCurrentErrorPart = aErrorSplit[i];
              if (cCurrentErrorPart.indexOf('SmartMessage') == 0) {
                let urlMessageRequest = '';
                const mySplitResult1 = cCurrentErrorPart.split('\t').splice(0, cCurrentErrorPart.split('\t').length - 1);
                const mySplitResult = mySplitResult1.concat(cCurrentErrorPart.split('\t').splice(cCurrentErrorPart.split('\t').length - 1, 1)[0].split(String.fromCharCode(4)));

                if (mySplitResult.length >= 2)
                  urlMessageRequest = `${urlMessageRequest}/${mySplitResult[1]}/${mySplitResult[2]}`;

                (function(oSelf, aFinalMsg, mySplitResult, aDefferedObjs, urlMessageRequest) {

                  // if already temporary cached on BusinessEntity
                  if (oSelf.aTempSmartMessages[urlMessageRequest] != undefined) {
                    const result = oSelf.aTempSmartMessages[urlMessageRequest];
                    const oSM = oSelf.getSmartMessagesLoaded(result, mySplitResult);
                    aFinalMsg.push(oSM);
                  } else {
                    const oDeffPromiseSmartMessage = $.ajax({ url: `${oSelf.serviceURI}/SmartMessage${urlMessageRequest}` });
                    oDeffPromiseSmartMessage.done(result => {
                      oSelf.aTempSmartMessages[urlMessageRequest] = result;
                      const oSM = oSelf.getSmartMessagesLoaded(result, mySplitResult);
                      aFinalMsg.push(oSM);
                    });
                    aDefferedObjs.push(oDeffPromiseSmartMessage);
                  }
                })(oSelf, aFinalMsg, mySplitResult, aDefferedObjs, urlMessageRequest);
              } else
                aFinalMsg.push({ text: cCurrentErrorPart, modal: true });


              // after al deffered ended get complete message string
              $.when(...aDefferedObjs).then(() => {
                let cFinalMsg = '';
                let bModal = true;
                let messageType = 'information';
                for (const i in aFinalMsg) {
                  cFinalMsg += (`${aFinalMsg[i].text}</br>`);
                  bModal = aFinalMsg[i].modal;
                  messageType = aFinalMsg[i].type;
                }
                if (bModal) {
                  dhtmlx.modalbox({
                    text: cFinalMsg,
                    buttons: aAnswers,
                    width: 500,
                    callback: function(cSelected) {
                      onSelect(cSelected, iIndQuestion);
                    }
                  });
                } else {
                  switch (messageType) {
                    case 'information':
                    case 'question':
                      akioma.notification({ type: 'info', text: cFinalMsg });
                      break;
                    case 'error':
                      akioma.notification({ type: 'error', text: cFinalMsg });
                      break;
                    case 'warning':
                      akioma.notification({ type: 'warning', text: cFinalMsg });
                  }
                }
              });
            }
          } else {
            dhtmlx.modalbox({
              title: oCurrentQ.MessageTitle,
              text: oCurrentQ.MessageText,
              buttons: aAnswers,
              width: 500,
              callback: function(cSelected) {
                onSelect(cSelected, iIndQuestion);
              }
            });
          }
        }
      } else if (oSelf.oUiActions.Questions) {
        const oelm = oSelf.dhx.item(oSelf.dhx.getCursor());
        oelm.akUiActions = JSON.stringify(oSelf.oUiActions);
        oelm.akuiactions = oelm.akUiActions;
        oSelf.dhx.update(oSelf.dhx.getCursor(), oelm);
        if (oelm._id != undefined) {
          oSelf.jsdo[oSelf.entityName].findById(oelm._id);
          oSelf.jsdo[oSelf.entityName].assign(oelm);
        } else if (oSelf.aErrRowsSmartMessages[oSelf.dhx.getCursor()])
          oSelf.jsdo[oSelf.entityName].add(oelm);

        akioma.skipQuery = true;
        oSelf.jsdo.saveChanges();
        akioma.skipQuery = false;
      }

    };

    let questionsUnanswered = 0;
    for (const q in oSelf.oUiActions.Questions) {
      const question = oSelf.oUiActions.Questions[q];
      if (question.MessageReply.toLowerCase() == 'unanswered') {
        questionsUnanswered++;
        askQuestion(q);
        break;
      }

    }

    // clean up akUiActions because there are no other questions to answer
    if (oSelf.oUiActions.Questions) {
      if (questionsUnanswered == 0) {
        const oelm = oSelf.dhx.item(oSelf.dhx.getCursor());
        oelm.akuiactions = '';
        oelm.akUiActions = '';
        oSelf.dhx.update(oSelf.dhx.getCursor(), oelm);
      }
    }
  }

  /**
   * Method for clearing data stores data
   * @instance
   * @memberof ak_businessEntity
   */
  clearAllDataStores() {
    try {
      if (this.aStores) {
        for (const i in this.aStores)
          this.aStores[i].clearAll();
      } else
        this.dhx.clearAll();
    } catch (e) {
      akioma.log.error(e);
    }
  }

  // show akUiActions.Notifications
  _showNotification(notification, aAnswers) {
    const oSelf = this;
    aAnswers = ['Ok'];
    let cMessageBoxStyle = 'nonmodal';
    // smartmessage in akuiActions
    if (notification.Text.indexOf('SmartMessage') > -1) {
      const cNotificationMsg = notification.Text;
      const aNotificationSplit = cNotificationMsg.split(String.fromCharCode(3));
      const aDefferedObjs = [];
      const aFinalMsg = [];

      for (const i in aNotificationSplit) {
        const cCurrentErrorPart = aNotificationSplit[i];
        if (cCurrentErrorPart.indexOf('SmartMessage') == 0) {
          let urlMessageRequest = '';
          const mySplitResult1 = cCurrentErrorPart.split('\t').splice(0, cCurrentErrorPart.split('\t').length - 1);
          const mySplitResult = mySplitResult1.concat(cCurrentErrorPart.split('\t').splice(cCurrentErrorPart.split('\t').length - 1, 1)[0].split(String.fromCharCode(4)));

          if (mySplitResult.length >= 2)
            urlMessageRequest = `${urlMessageRequest}/${mySplitResult[1]}/${mySplitResult[2]}`;

          (function(oSelf, aFinalMsg, mySplitResult, aDefferedObjs, urlMessageRequest) {

            // if already temporary cached on BusinessEntity
            if (oSelf.aTempSmartMessages[urlMessageRequest] != undefined) {
              const result = oSelf.aTempSmartMessages[urlMessageRequest];
              const oSM = oSelf.getSmartMessagesLoaded(result, mySplitResult);
              aFinalMsg.push(oSM);
            } else {
              const oDeffPromiseSmartMessage = $.ajax({ url: `${oSelf.serviceURI}/SmartMessage${urlMessageRequest}` });
              oDeffPromiseSmartMessage.done(result => {
                oSelf.aTempSmartMessages[urlMessageRequest] = result;
                const oSM = oSelf.getSmartMessagesLoaded(result, mySplitResult);
                if (oSM.MessageBoxStyle != undefined)
                  cMessageBoxStyle = oSM.MessageBoxStyle;
                aFinalMsg.push(oSM);
              });
              aDefferedObjs.push(oDeffPromiseSmartMessage);
            }

          })(oSelf, aFinalMsg, mySplitResult, aDefferedObjs, urlMessageRequest);
        } else
          aFinalMsg.push({ text: cCurrentErrorPart, modal: true });

        // after al deffered ended get complete message string
        $.when(...aDefferedObjs).then(() => {
          let cFinalMsg = '';
          const bModal = (cMessageBoxStyle.toLowerCase() == 'modal');
          let messageType = 'information';
          for (const i in aFinalMsg) {
            cFinalMsg += (`${aFinalMsg[i].text}</br>`);
            messageType = aFinalMsg[i].type;
          }
          if (bModal) {

            dhtmlx.modalbox({
              text: cFinalMsg,
              buttons: aAnswers,
              width: 500
            });
          } else {
            switch (messageType) {
              case 'information':
              case 'question':
                akioma.notification({ type: 'info', text: cFinalMsg });
                break;
              case 'error':
                akioma.notification({ type: 'error', text: cFinalMsg });
                break;
              case 'warning':
                akioma.notification({ type: 'warning', text: cFinalMsg });
            }
          }
        });
      }
    } else {
      for (const oCurrentQ in oSelf.oUiActions.Questions) {
        dhtmlx.modalbox({
          title: oCurrentQ.MessageTitle,
          text: oCurrentQ.Text,
          buttons: aAnswers,
          width: 500
        });
      }
    }

    // clean up akUiActions
    const oelm = oSelf.dhx.item(oSelf.dhx.getCursor());
    oelm.akuiactions = '';
    oelm.akUiActions = '';
    oSelf.dhx.update(oSelf.dhx.getCursor(), oelm);
  }

  dataAvailable() {
    this.openQuery({});
  }

  setTitle() { }


  reqOpenQuery(oElm, callback) {
    const oSelf = this,
      oData = this.dhx;

    const deferred = $.Deferred();

    if (oSelf.jsdo.fillxhr) {
      oSelf.jsdo.fillxhr.request.deferred._promise.catch(e => {
        akioma.log.info(e);
      });
      // Set _pendingrequest to the state it was before abort
      const pendingRequest = oSelf._pendingrequest;
      oSelf.jsdo.fillxhr.abort();
      oSelf._pendingrequest = pendingRequest;
    }

    if (oSelf.parent != undefined && oSelf.parent.dhx.progressOn && oElm.skipProgress == undefined)
      oSelf.parent.dhx.progressOn();

    // check default sorting
    if (oSelf.opt.defaultSort && this.query.aSorting == undefined) {
      const cSort = oSelf.opt.defaultSort;
      oSelf.query.setStringSorting(cSort);
      oSelf.query.buildQuery();
    }

    if (oElm.extLink)
      oSelf.query.addUniqueCondition('selfhdl', 'eq', oElm.extLink);

    // copy urlquery
    let oPar = this.getUrlQuery();

    let bStopQuery = false;
    // check for specials
    if (oElm.foreignKey) {
      oPar.foreignKey = this.opt.foreignKey;
      oPar[this.opt.foreignKey] = oElm.foreignKey;
      this.query.addUniqueCondition('refhdl', 'eq', oElm.foreignKey);

    } else if (this.dataSource && this.opt.foreignKey) {
      const cRefHdl = this.dataSource.getIndex();
      const aForeignKeyData = this.opt.foreignKey.split('.');
      if (cRefHdl) {
        // check foreignKey attribute for tablename and fieldname
        this.query.addUniqueCondition((aForeignKeyData[1] || aForeignKeyData[0]), 'eq', this.dataSource.getFieldValue(this.opt.extKey));
      } else {
        bStopQuery = true;
        oSelf.dataSource.addAfterFillOnceCallback(() => {
          oSelf.query.addUniqueCondition((aForeignKeyData[1] || aForeignKeyData[0]), 'eq', oSelf.dataSource.getFieldValue(oSelf.opt.extKey));
          oSelf.openQuery({});
        });
      }
    }

    if (bStopQuery)
      return bStopQuery;

    if (oElm.extKey)
      oPar.extKey = oElm.extKey;
    if (oElm.extLink)
      oPar.extLink = oElm.extLink;
    if (oElm.sort) {
      this.serverProp.sortField = oElm.sort;
      this.serverProp.sortDir = oElm.dir;
    }

    // check for mode -> add mode elements to parameter
    if (oElm.mode)
      $.extend(oPar, oElm.mode, oPar);

    // reset bind mechanism
    if (this.opt.multiStore == undefined) {
      oData.data.detachEvent('onBeforeFilter');
      oData.data.feed = undefined;
      oData.dataFeed_setter(true);
    } else {
      for (const t in this.aStores) {
        this.aStores[t].data.detachEvent('onBeforeFilter');
        this.aStores[t].data.feed = undefined;
        this.aStores[t].dataFeed_setter(true);
      }
    }

    if (this.prop.bind && this.dhx.e && (typeof oElm.bind == 'undefined' || oElm.bind == true)) {
      // get target datastore element
      const key = this.dhx.e.id, // e = settings
        oTarget = dhx.ui.get(key);

      // reset bind in target
      oTarget.ia[key] = false; // ia = _bind_settings
      this.dhx.callEvent('onBindRequest');

      // reset binding for second query (filter)
      this.prop.bind = false;

    } else {
      (function(oSelf) {
        oSelf.jsdo.subscribe('afterFill', function onAfterFill(jsdo, success, request) {
          jsdo.unsubscribe('afterFill', onAfterFill);
          try {

            oSelf.dataLoaded = true;

            // check sortable and filterable attributes
            const oSchema = jsdo[oSelf.entityName].getSchema();
            oSelf._checkSortableAndFilterable(oSchema);

            const rows = [];

            if (!success && request.response) {
              akioma.notification({
                type: 'error',
                text: (request.response.title || request.response.error),
                moretext: JSON.stringify(request.response, null, 4)
              });
              akioma.showMessage({
                type: 'error',
                text: request.response._errors[0]._errorMsg
              });
            }
            // set context value
            if (request.response && request.response.pcContext) {
              oSelf.cPrevContext = request.response.pcPrevContext;
              if (oSelf.cNextContext)
                oSelf.cContext = oSelf.cNextContext;
              else
                oSelf.cContext = '';
              oSelf.cNextContext = request.response.pcContext;
            }

            if (oSelf.opt.multiStore == undefined) {
              if (jsdo[oSelf.entityName]) {
                jsdo[oSelf.entityName].foreach(offerstruct => {
                  rows.push($.extend(oSelf.objectKeysToLowerCase(offerstruct.data), { id: offerstruct.getId() }));
                });
              }

              if (callback)
                callback(rows);
              else if (oSelf.batchMode) {
                const oReturn = {
                  data: rows,
                  pos: oSelf.batchSize.lastIndex || 0, // from what position did you receive this value
                  total_count: oSelf.noOfRecords
                };
                oData.parse(oReturn, 'json');
              } else
                oData.parse(rows);

              oSelf.recordIndex = 0;
              rows.forEach(record => {
                if (!$.isEmptyObject(oSelf.dhx.data.pull)) {
                  const cUiActions = record.akuiactions;
                  // call uiActions
                  if (cUiActions != undefined && cUiActions != '') {
                    oSelf.oUiActions = JSON.parse(cUiActions);
                    oSelf.callUiAttributes();
                  }
                  oSelf.recordIndex += 1;
                }
              });

            } else {
              let aOrderTableFields, aOrderTableNames;
              if (oSelf.clientOrderBy) {
                aOrderTableFields = oSelf.clientOrderBy.field;
                aOrderTableNames = oSelf.clientOrderBy.tables;
              }

              const aTables = Object.keys(jsdo._buffers);
              for (const t in oSelf.aStores) {
                rows[t] = [];

                // order by
                if (aOrderTableNames) {
                  let cOrderByField;
                  const iOrderByField = aOrderTableNames.indexOf(aTables[t]);
                  if (iOrderByField > -1)
                    cOrderByField = aOrderTableFields[iOrderByField];

                  if (cOrderByField) {
                    jsdo[aTables[t]]._data = _.sortBy(jsdo[aTables[t]]._data, cOrderByField);

                    for (const j in jsdo[aTables[t]]._data)
                      jsdo[aTables[t]]._index[jsdo[aTables[t]]._data[j]._id].index = j;

                  }
                }

                for (const i in jsdo[aTables[t]]._data) {
                  const offerstruct = jsdo[aTables[t]]._data[i];
                  rows[t].push($.extend(oSelf.objectKeysToLowerCase(offerstruct), { id: offerstruct._id }));
                }

              }

              // clear datasources
              if (oElm.skipClear == undefined)
                oSelf.clearAllDataStores();

              try {
                for (const t in oSelf.aStores)
                  oSelf.aStores[t].parse(rows[t]);

              } catch (e) {
                console.warn(e);
              }
            }

            // fire onBeforeFetch code if available
            if (oSelf.opt.onAfterFetch)
              applyAkiomaCode(oSelf, oSelf.opt.onAfterFetch);

            if (oSelf.aCallback['afterFill']) {

              oSelf.aCallback['afterFill'](rows);
              delete oSelf.aCallback['afterFill'];
            }

            if (oSelf.aAfterFillOnce) {
              for (const i in oSelf.aAfterFillOnce)
                oSelf.aAfterFillOnce[i](oSelf.getStore(oSelf.entityName).data.getIndexRange());

              oSelf.aAfterFillOnce = [];
            }
            if (oSelf.aAfterFillAlways) {
              for (const i in oSelf.aAfterFillAlways)
                oSelf.aAfterFillAlways[i](request);

              if (request.success)
                oSelf.aAfterFillAlways = [];
            }

            deferred.resolve();

          } catch (e) {
            // if loading undefined values it will trigger error
            // but we still need it to run
            // so we silently report because we might actually have an error we don't want to miss
            console.warn(`warning: parsing loading rows for ${oSelf.opt.name}`, e);
            deferred.reject();
          }

          if (oSelf.parent != undefined && oSelf.parent.dhx.progressOff)
            oSelf.parent.dhx.progressOff();

          oSelf._pendingrequest = false;
          oSelf._callAfterPendingRequestListeners();
        });
      })(oSelf);

      const oGenericProps = {};
      oGenericProps.clientSessionId = docCookies.getItem('ak_UsrSI');
      const bAllowContext = false;

      if (bAllowContext) {
        // check for next context, for batching
        if (oSelf.cNextContext != '' && !oElm.goBackwards)
          oPar.Context = oSelf.cNextContext;
        else if (oElm.goBackwards) {
          if (oSelf.cPrevContext != '')
            oPar.Context = oSelf.cPrevContext;
        }

      }

      // smart component library unicode fix ** replace unicode values **
      const JSON_stringify = (s, emit_unicode) => {
        const json = JSON.stringify(s);
        return emit_unicode ? json : json.replace(/\\u0000/g,
          () => '\\u0005'
        );
      };

      // fire onBeforeFetch code if available
      if (oSelf.opt.onBeforeFetch)
        applyAkiomaCode(oSelf, oSelf.opt.onBeforeFetch);

      // afterFill make sure to print errors
      const onAfterFill = (jsdo, success, request) => {
        if (!success) {
          if (request.response && request.response.message)
            akioma.notification({ type: 'error', text: request.response.message });

        }
      };
      oSelf.jsdo.subscribe('afterFill', onAfterFill);

      // check for multitable param
      if (oSelf.oMultiTableParam)
        oPar = oSelf.oMultiTableParam;

      const tableRef = oSelf.getTableRefFilter();
      if (tableRef)
        oPar.filters = { ...oPar.filters, 'tableRef': tableRef };

      // fill jsdo
      if (oSelf.oNamedQuery) {
        if (oElm.applyFilters == undefined)
          oPar = {};
        oPar.NamedQuery = oSelf.oNamedQuery;
        if (oElm.applyFilters == undefined)
          delete oPar.akQuery;

        const bTypesHardcoded = (oSelf.opt.resourceName == 'Consultingwerk.SmartFramework.Repository.Class.ObjectTypeBusinessEntity');
        if (oSelf.opt.customContext || bTypesHardcoded) {
          if (oPar.filters == undefined)
            oPar.filters = {};

          oPar.filters.CustomContext = 'NoCalcFields';
        }
        if (oElm.applyFilters == undefined)
          oSelf.jsdo.fill(JSON_stringify(oPar, false));
        else
          oSelf.jsdo.fill(oPar);
      } else {
        if (oSelf.batchSize && oSelf.batchSize.skip != undefined) {
          oPar.filters = {};
          oPar.filters.skip = oSelf.batchSize.skip;
          oPar.filters.top = oSelf.batchSize.top;
        }

        const bTypesHardcoded = (oSelf.opt.resourceName == 'Consultingwerk.SmartFramework.Repository.Class.ObjectTypeBusinessEntity');
        if (oSelf.opt.customContext || bTypesHardcoded) {
          if (oPar.filters == undefined)
            oPar.filters = {};

          oPar.filters.CustomContext = 'NoCalcFields';
        }

        oSelf.jsdo.fill(oPar);
      }
    }

    return deferred.promise();
  }

  // open query ****************
  openQuery(oElm, callback) {
    const oSelf = this,
      deferred = $.Deferred();

    if (isNull(oElm))
      oElm = {};

    if (isNull(this.jsdo)) {
      this.addAfterCatalogAdd(() => {
        this.setQueryParam(oElm);
        this.stop = false;
        this.openQuery(oElm, callback);
      });
    } else {

      if (oSelf.stop)
        return false;

      this._pendingrequest = true;

      if (this.timeoutQuery)
        clearTimeout(this.timeoutQuery);

      this.timeoutQuery = setTimeout(() => {
        oSelf.reqOpenQuery(oElm, callback).then(() => {
          deferred.resolve();
        }).fail(() => {
          deferred.reject();
        });
      }, 5);

    }

    return deferred.promise();
  }

  logTablesData() {
    const oSelf = this;
    const aTables = Object.keys(oSelf.jsdo._buffers);
    akioma.log.info('Tables Available:', aTables);

    for (const i in oSelf.aStores)
      akioma.log.info(`${aTables[i]} data:`, oSelf.aStores[i]);
  }

  // update record **************
  updateRecord(oElm) {
    const oSelf = this;

    akioma.skipQuery = true;
    const cObjectID = oSelf.opt.name + oSelf.opt.linkid;
    oSelf.switchJSDOWorkingRecord(oElm.entityTable);
    // save forms in batch mode
    this.getStore(oElm.entityTable).saveBatch(() => {
      // tell all observers to update data in source
      app.messenger.publish({
        link: {
          LinkName: 'DISPLAY',
          LinkType: 'TRG',
          ObjName: cObjectID
        },
        method: 'submitRow',
        caller: oSelf
      });
    });
    oSelf.getStoreConnector(oElm.entityTable).sendData();
    akioma.skipQuery = false;
  }

  // set index ***********
  setIndex(value, entityTable) {
    const store = this.getStore(entityTable);
    store._cursor = '';
    store.setCursor(value);
  }

  // set filter ************
  setFilter(oFilter, oControl) {
    this._setFilter(oFilter, oControl);
  }

  /**
   * Method for checking JSDO has changes
   * @instance
   * @memberof ak_businessEntity
   * @returns {boolean}
   */
  hasJSDOChanges() {
    return this.jsdo.hasChanges();
  }

  // before update
  beforeUpdate() {}

  destroy() {
    try {
      for (const x in this.aStoresFromKeys) {
        const dhx = this.aStoresFromKeys[x];
        if (dhx && dhx._settings && dhx._settings.id) {
          if (dhx.unbind)
            dhx.unbind(dhx);

          if (window.RootDhx.ui.views[dhx._settings.id])
            delete window.RootDhx.ui.views[dhx._settings.id];

        }
      }

      if (this.jsdo) {
        this.jsdo.unsubscribeAll();
        progress.data.ServicesManager.cleanSession(this.jsdo._session);
        delete this.jsdo;
      }

      this.dc = null;

      delete this.aStoresFromKeys;
      delete this.aStoresConnectorsFromKeys;

      this.serverProp = null;
      this.urlQuery = null;
      this.observers = null;

    } catch (e) {
      console.error(e);
    }
  }
};
