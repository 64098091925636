// global animation cofig
akioma.animation = { bAllowRippleEffect: true };

// ripple plugin used for sidebar items // css in akcustom.scss
$.fn.ripple = function(options) {
  let container;
  const properties = { color: 'grey' };

  $.extend(properties, options);
  let bAllowSidebarSelect = true; // to prevent desktop sidebar select bug- -->

  $(this).mousedown(function(e) {

    // prevent fast selection bug
    if (!bAllowSidebarSelect) {
      Animation.playRippleOnDrop(e, { colorone: 'rgba(51,153,204,0.2)' });
      // custom settings, apply on existing ink color red, resize to size vals and then opacity0, remove
      return false;
    }
    bAllowSidebarSelect = false;


    $('.ink').remove();
    container = $(this);
    // check if already selected
    const bItemIsSelected = container.hasClass('dhxsidebar_item_selected');
    if ((bItemIsSelected && container.find('.ink').length > 0))
      return false;

    container.css({
      overflow: 'hidden',
      position: 'relative'
    });


    if (container.find('.ink').length === 0)
      container.prepend('<span class=\'ink\'></span>');

    const ink = container.find('.ink');

    ink.removeClass('animated');

    if (!ink.height() && !ink.width()) {
      const size = Math.max(container.outerWidth(), container.outerHeight());
      ink.css({ background: properties.color, height: size, width: size });
    }

    const x = e.pageX - container.offset().left - ink.width() / 2;
    const y = e.pageY - container.offset().top - ink.height() / 2;

    // remove all existing end states
    ink.parent().parent().find('.end').remove();

    // add rippple animation
    ink.css({
      top: y,
      left: x
    }).addClass('animated');

    // on ripple animation finished add end class
    akioma.animation.bAllowRippleEffect = false;
    ink.one('animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd', () => {
      // code to execute after transition ends
      ink.addClass('end');
      bAllowSidebarSelect = true; // enable sselection again
    });
  });
};

Animation.playRippleOnDrop = function(e, options) {

  let circle1color = '#009688';
  if (options) {
    if (options.colorone)
      circle1color = options.colorone;
  }


  const circle1 = new mojs.Shape({
    fill: 'none',
    radius: 25,
    strokeWidth: { 50: 0 },
    scale: { 0: 1 },
    angle: { 'rand(-35, -70)': 0 },
    duration: 700,
    left: 0, top: 0,
    easing: 'cubic.out',
    stroke: circle1color,
    onComplete: function() {
      $(circle1.el).remove();
    }
  });

  const circle2 = new mojs.Shape({
    fill: 'none',
    scale: { 0: 1 },
    angle: { 'rand(-35, -70)': 0 },
    duration: 700,
    left: 0, top: 0,
    easing: 'cubic.out',
    radius: { 0: 15 },
    strokeWidth: { 30: 0 },
    stroke: '#00c0ae',
    delay: 'rand(75, 150)',
    onComplete: function() {
      $(circle2.el).remove();
    }
  });

  circle1
    .tune({ x: e.pageX, y: e.pageY })
    .replay();

  circle2
    .tune({ x: e.pageX, y: e.pageY })
    .replay();
};
