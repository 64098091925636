dhtmlXForm.prototype.items.fileSelector = {
  render(item, data) {

    item._type = 'fileSelector';
    item._enabled = true;

    this.doAddLabel(item, data);

    const oSelf = $.getObjectByName({ id: data.userdata.id });

    item.akElm = oSelf;
    item._name - oSelf.opt.name;
    oSelf.dhx = item;

    // -> bind input to form
    $('<div class="dhxform_control">'
      + '<i class="fad fa-file-search akFileSelectorImg"></i>'
      + '<input type="text" class="akFileSelectorField dhxform_textarea" />'
      + '</div>')
      .appendTo(item)
      .css({
        left: data.inputLeft + 3,
        top: data.inputTop
      })
      .find('input.akFileSelectorField')
      .blur(() => {
        oSelf.eventLeave();
      })
      .focus(() => {
        oSelf.eventFocus();
      })
      .change(() => {
        oSelf.eventChange();
      })
      .end()
      .find('.akFileSelectorImg')
      .hover(
        function() {
          $(this)
            .css({ cursor: 'hand' });
        },
        function() {
          $(this)
            .css({ cursor: 'pointer' });
        }
      )
      .click(() => {
        oSelf.eventClick();
      })
      .end()
      .get(0);

    // set akStyle in fileSelector
    $(oSelf.dhx).find('.dhxform_control').attr('akStyle', oSelf.opt.customStyle);

    return this;
  },

  enable(item) {
    $('input:first', item).removeAttr('readonly');
  },

  disable(item) {
    $('input:first', item).attr('readonly', 'readonly');
  },

  setValue(item, value) {
    item._value = value;
    if (value && value.length > 0)
      $(item).addClass('active');
    else
      $(item).removeClass('active');

    $('input:first', item).val(value);
    if (item.akElm)
      item.akElm._old_val = value;
  },

  getValue(item) {
    return $(item).find('input').val();
  },

  setFocus(item) {
    $('input:first', item).focus();
  },

  getfileSelector(item) {
    return $(item).find('input').get(0);
  }
};
(function() {
  for (const a in { doAddLabel: 1, destruct: 1, doUnloadNestedLists: 1, setText: 1, getText: 1, isEnabled: 1, setWidth: 1 })
    dhtmlXForm.prototype.items.fileSelector[a] = dhtmlXForm.prototype.items.input[a];
})();
(function() {
  dhtmlXForm.prototype.items.fileSelector._getItemNode = function(item) {
    return item;
  };
})();
dhtmlXForm.prototype.getfileSelector = function(name) {
  return this.doWithItem(name, 'getfileSelector');
};
dhtmlXForm.prototype.setFormData_fileSelector = function(name, value) {
  return this.doWithItem(name, 'setValue', value);
};
dhtmlXForm.prototype.getFormData_fileSelector = function(name) {
  return this.doWithItem(name, 'getValue');
};

/**
 * SwatFileSelector Control
 * @class ak_fileSelector
 * @param {Object} options Repository attributes for SwatFileSelector.
 * @param {string} options.align alignment of column. can be left, right or centered
 * @param {boolean} options.CanFilter Set to FALSE if this field should not be used to filter the data object query.
 * @param {boolean} options.CanSort Set to FALSE if this field should not be used to sort the data object query.
 * @param {boolean} options.CHECKED
 * @param {number} options.COLUMN Column position. This may currently be used when rendering some objects. There is no getColumns function, use getCol to retrieve the realized value from an object.
 * @param {boolean} options.CreateField
 * @param {string} options.DATA-TYPE WidgetAttributes Data-Type
 * @param {boolean} options.ENABLED WidgetAttributes Enabled
 * @param {string} options.EventAkValidate Client side validation code (JavaScript)
 * @param {string} options.EventClick Client side validation code (JavaScript)
 * @param {string} options.EventEntry Event which should run on entry of a field
 * @param {string} options.EventEntryType Language of "entry" trigger
 * @param {string} options.EventLeave Event when leaving a field
 * @param {string} options.EventLeaveType Language, the event is written in.
 * @param {string} options.FieldName The name of the associated SDO field this SmartDataField maps to. This is usually 'set' from the containing SmartDataViewer.
 * @param {string} options.filter
 * @param {string} options.FilterOperator
 * @param {string} options.FolderWindowToLaunch If Dynamics is running, this property specifies a window to launch upon the occurence of toolbar events "View", "Copy", "Modify" or "Add".
 * @param {string} options.FORMAT WidgetAttributes Format
 * @param {number} options.HEIGHT-CHARS Height in characters. This may currently be used when rendering some objects. There is no get function, use getHeight to retrieve the realized value from an object.
 * @param {string} options.HELP WidgetAttributes Help
 * @param {string} options.InitialValue WidgetAttributes InitialValue
 * @param {string} options.KeyField Name of the Dynamic Lookup/Dynamic Combo key field to assign value from (Table.Field)
 * @param {string} options.LABEL WidgetAttributes Label
 * @param {boolean} options.LABELS If false then NO-LABEL is used.  This attribute applies to most field level widgets and frames
 * @param {string} options.LIST-ITEM-PAIRS
 * @param {string} options.LIST-ITEMS
 * @param {boolean} options.Mandatory WidgetAttributes Mandatory
 * @param {boolean} options.MULTIPLE
 * @param {number} options.ROW Row position.
 * @param {string} options.SUBTYPE
 * @param {string} options.TableName WidgetAttributes TableName
 * @param {string} options.TemplateFile The relative path and filename of the static object used as the template at design time
 * @param {string} options.Validation Validation-Rules, e.g. ValidInteger,ValidEMail...
 * @param {string} options.ViewAs The 'ViewAs' definition  of the selection.&#10;- combo-box,radio-set,selection-list OR browse &#10;- Uses colon as separator to define SUB-TYPE for combo-box or &#10;horizontal/vertical radio-set,
 * @param {boolean} options.VISIBLE WidgetAttributes Visible
 * @param {string} options.VisualizationType WidgetAttributes VisualizationType
 * @param {string} options.Width A widget's width. The unit of measurement is determined by another&#10;parameter.
 * @param {number} options.WIDTH-CHARS Width in characters. This may currently be used when rendering some objects. There is no get function, use getWidth to retrieve the realized value from an object.
 */
$.ak_fileSelector = class {
  constructor(options) {

    akioma.BaseFormDataField.call(this, options);

    const defaults = {};

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;
    this.view = options.view;

    this.registerVuexWatcher = true;
    this.registerDynObject = true;
    this.useParentDynObjectLink = true;

    // get parent
    const oParent = this.parent;


    if (oParent) {

      if (!this.opt.customStyle)
        this.opt.customStyle = this.view;

      // this.opt.label = akioma.tran( akioma.getForm(this).opt.name + "." + this.opt.name, { defaultValue: this.opt.label } );

      if (oParent.prop.fields) {
        oParent.prop.fields.push({
          type: 'fileSelector',
          inputTop: parseInt(this.opt.top),
          inputLeft: parseInt(this.opt.left),
          inputWidth: parseInt(this.opt.width),
          label: (this.opt.LABELS) ? this.opt.LABEL : '',
          labelTop: parseInt(this.opt.top),
          labelLeft: oParent.labelLeft(this),
          name: this.opt.name,
          disabled: this.opt.disabled ? true : false,
          errorMsg: '',
          hasError: false,
          required: (this.opt.required) ? this.opt.required : false,
          mandatory: (this.opt.required) ? this.opt.required : false,
          enabled: !this.opt.enabled,
          className: 'w4-formField w4-inputField fileSelectorFormField active',
          position: 'label-top',
          userdata: { id: this.opt.id }
        });
      }

      // append to elements in form
      if (this.parent.view == 'form')
        this.parent.elements.push(this);

      $.extend(this, { security: {} });
    }
  }
};

Object.assign($.ak_fileSelector.prototype, akioma.BaseFormDataField.prototype, {
  componentOptions() {
    const oSelf = this;
    return {
      watch: {
        'getters.getFormFieldState': {
          fn: function(newValue, oldValue) {
            oSelf._hasFormFieldChangesWatcher(newValue, oldValue);
          },
          params: [this.opt.id]
        },
        'getters.getFormFieldEnabled': {
          fn: function(bEnabled) {
            oSelf._enabledFormFieldWatcher(bEnabled);
          },
          params: [this.opt.id]
        },
        'getters.getFormFieldMandatory': {
          fn: function(bMandatory) {
            oSelf._mandatoryFormFieldWatcher(bMandatory);
          },
          params: [this.opt.id]
        },
        'getters.getFormFieldError': {
          fn: function(newValue, oldValue) {
            oSelf._errorFormFieldWatcher(newValue, oldValue);
          },
          params: [this.opt.id]
        },
        'getters.getFormFieldErrorMsg': {
          fn: function(cErrorMsg) {
            oSelf._errorFormFieldMsgWatcher(cErrorMsg);
          },
          params: [this.opt.id]
        }
      }
    };
  },

  /**
 * Watcher for the enable/disable fileSelector
 * @memberof ak_fileSelector
 * @instance
 * @private
 * @param {Boolean} bEnabled
 */
  _enabledFormFieldWatcher(bEnabled) {
    const oForm = akioma.getForm(this);
    const cName = this.opt.name;

    if (oForm.dhx) {
      if (bEnabled) {
        oForm.dhx.enableItem(cName);
        this.security.readOnly = false;
      } else {
        oForm.dhx.disableItem(cName);
        this.security.readOnly = true;
      }
    }
  },

  /**
 * Watcher for the mandatory fields in fileSelector
 * @memberof ak_fileSelector
 * @instance
 * @private
 * @param {Boolean} bMandatory
 */
  _mandatoryFormFieldWatcher(bMandatory) {
    const oForm = akioma.getForm(this);
    const cName = this.opt.name;

    if (oForm && oForm.dhx) {
      if (typeof (this.setRequired) == 'function')
        this.setRequired(bMandatory);

      oForm.dhx.setRequired(cName, bMandatory);
    }
  },

  /**
 * Watcher for the fileSelector error message
 * @memberof ak_fileSelector
 * @instance
 * @private
 * @param {Boolean} newValue
 */
  _errorFormFieldMsgWatcher(newValue) {
    const oForm = akioma.getForm(this);
    const cName = this.opt.name;
    const oFormField = akioma.getDhxFormElement(oForm.dhx, cName);

    // setup error message under input
    if (oFormField) {
      const oFormCtrl = $(oFormField).find('> .dhxform_control');
      if (oFormCtrl.find('.validation-error-smartmessage').length == 0)
        oFormCtrl.append(`<span class="validation-error-smartmessage">${newValue}</span>`);
      else
        oFormCtrl.find('.validation-error-smartmessage').text(newValue);
    }

  },

  /**
 * Get the value of the fileSelector
 * @memberof ak_fileSelector
 * @instance
 * @returns {String}
 */
  getValue() {
    return this.form.getItemValue(this.opt.name);
  },

  /**
 * Set the value of the fileSelector
 * @memberof ak_fileSelector
 * @instance
 * @param {String} cValue
 */
  setValue(cValue) {
    if (!this.form)
      return;

    this.form.doWithItem(this.opt.name, 'setValue', cValue);
  },

  /**
 * Method executed on fileSelector input changes
 * @memberof ak_fileSelector
 * @instance
 */
  eventChange() {
    const oForm = akioma.getForm(this);
    this.eventBlur();
    oForm.dataSource.setChanged(true);
    const cVal = $(oForm.dhx.getfileSelector(this.opt.name)).val();
    this._old_val = this._val;
    this._val = cVal;
  },

  /**
 * Method executed on leave
 * @memberof ak_fileSelector
 * @instance
 */
  eventLeave() {
    if (this.opt.leaveEvent)
      app.controller.callAkiomaCode(this, this.opt.leaveEvent);
    this.eventBlur();
  },

  /**
 * Method executed on focus
 * @memberof ak_fileSelector
 * @instance
 */
  eventFocus() {
    const formDiv = akioma.getForm(this).dhx.cont.children[0];
    $(formDiv).children().removeClass('focusedinp');
    const $itemcont = $(this.form.getfileSelector(this.opt.name)).parent().parent();
    $itemcont.addClass('active').addClass('focusedinp');
  },

  /**
 * Method executed on blur
 * @memberof ak_fileSelector
 * @instance
 */
  eventBlur() {
    const $itemcont = $(this.form.getfileSelector(this.opt.name)).parent().parent();
    const cVal = $(this.form.getfileSelector(this.opt.name)).val();
    if (cVal.length == 0)
      $itemcont.removeClass('active');

    $itemcont.removeClass('focusedinp');

    // vuex dirty change
    if (this._old_val !== cVal) {
      const oForm = this.form.akElm;
      oForm.setFieldHasChanges(this.opt.name, true);
    }

  },

  /**
 * Method executed on click
 * @memberof ak_fileSelector
 * @instance
 */
  eventClick() {
    if (this.security.readOnly)
      return;

    akioma.swat.chooseAndAssignFile(this, this.opt.fileType, this.opt.name);
  },

  /**
 * Code executed after the construct is done.
 * @memberof ak_fileSelector
 * @instance
 */
  finishConstruct() {
    try {
      this.form = akioma.getForm(this).dhx;

      const fileSelectorNode = akioma.getDhxFormElement(this.form, this.opt.name);
      this.$domElement = $(fileSelectorNode);
      this.setResponsiveSizes();
      this.domTag = this.$domElement.find('.akFileSelectorField')[0];
      this._setTabIndex(this.domTag);
    } catch (e) {
      console.error('Error', e);
    }
  }
});
