
// old inputnum no longer used
dhtmlXForm.prototype.items.inputnum = {
  render: function(item, data) {

    item._type = 'ta';
    item._enabled = true;

    this.doAddLabel(item, data);
    this.doAddInput(item, data, 'INPUT', 'TEXT', true, true, 'dhxlist_txt_textarea dhxform_textarea');
    this.doAttachEvents(item);

    const oSelf = $.getObjectByName({ id: data.userdata.id });
    item.akElm = item._self = oSelf;
    item.name = data.name;
    return this;
  },
  destruct: function() {},
  doAttachEvents: function(item) {
    let node = item.childNodes[item._ll ? 1 : 0].childNodes[0];

    if (typeof (node.tagName) != 'undefined' && { 'input': 1, 'textarea': 1, 'select': 1 }[node.tagName.toLowerCase()] == 1) {
      const that = this;
      node.onfocus = function() {
        let i = this.parentNode.parentNode;
        const oVal = $(i).find('input').val();
        this._value = i._value;
        if (i._df != null) this.value = oVal || '';
        i.getForm()._ccActivate(i._idd, this, this.value);
        i.getForm().callEvent('onFocus', [i._idd]);
        i = null;
      };
      node.onblur = function() {
        let i = this.parentNode.parentNode;
        i.getForm()._ccDeactivate(i._idd);
        that.updateValue(i, true);
        if (i.getForm().live_validate) that._validate(i);
        i.getForm().callEvent('onBlur', [i._idd]);
        i = null;
      };

    }
    node = null;
  },

  /**
   * Used for formating a inputnum value
   * @instance
   * @memberof ak_inputnum
   * @param {String} value
   * @param {Object} format
   * @param {Boolean} hasTrailingZeros
   * @returns {String}
   */
  _getFormatted: function(value, format, hasTrailingZeros = false) {
    if (!format || value === null || value === '')
      return value;

    // prefix: [minusSign]currency
    const prefix = (parseFloat(value) < 0 ? '-' : '') + format.s_bef;

    // Move *format.d_len* decimals to integer part by shifting left, round the obtained integer number
    let newFormat = null;
    if (typeof (value) === 'string' && value.split('.')[1] && !hasTrailingZeros)
      newFormat = Math.min(value.split('.')[1].length, format.d_len);
    else if (value.split('.')[1] === undefined && !hasTrailingZeros)
      return `${prefix} ${Math.abs(value)}${format.s_aft}`;
    else
      newFormat = format.d_len;

    let formattedValue = Math.abs(Math.round(parseFloat(value) * Math.pow(10, newFormat > 0 ? newFormat : 0))).toString();

    if (formattedValue === '0' && hasTrailingZeros) {
      let contor = newFormat;
      while (contor > 1) {
        formattedValue += '0';
        contor--;
      }
    }

    // Split number by digits. Add zeros if value smaller than decimals length. Reverse the obtained digit array.
    formattedValue = formattedValue.split('').reverse();

    // Add decimal separator after last integer part digit. If no integer part: set the digit as 0 + separator
    formattedValue[newFormat] = (formattedValue[newFormat] || '0') + format.d_sep;

    // Check if integer grouping enabled
    if (format.i_len > 0) {
      // Add group separator to the format.i_len digits, skipping decimal digits
      for (let j = newFormat + format.i_len; j < formattedValue.length; j += format.i_len)
        formattedValue[j] += format.i_sep;
    }

    // Reverse back digits and build formattedValue
    return `${prefix} ${formattedValue.reverse().join('')}${format.s_aft}`;
  },

  setValue: function(item, value) {
    try {
      if (value && item) {
        if (String(value).length > 0)
          $(item).addClass('active');
      }
    } catch (e) {
      akioma.log.error(e);
    }

    item._value = (typeof (value) != 'undefined' ? value : '');

    // (String(item._value)||"") - ||"" couldn't find a reason for the or
    let v = ((value === null) ? null : (String(item._value) || ''));
    let k = item.childNodes[item._ll ? 1 : 0].childNodes[0];

    // check if formatting available
    if (item._df != null) v = this._getFormatted(v, item._df, item.akElm.opt.hasTrailingZeros);

    if (k.value != v) {
      k.value = v;
      item.getForm()._ccReload(item._idd, v);
    }

    k = null;

    if (item._self && item._self.dynObject && (!item._self.dynObject.akEvent || (value != item._self.dynObject.akEvent.currentValue.Numeric))) {
      const akEvent = {};
      const lastVal = (item._self.dynObject.akEvent ? item._self.dynObject.akEvent.currentValue.Numeric : 0);
      akEvent.lastValue = {
        Numeric: (lastVal === null) ? null : Number(lastVal),
        String: item._self.dynObject.akEvent ? item._self.dynObject.akEvent.currentValue.String : undefined
      };
      akEvent.currentValue = {
        Numeric: (item._value === null) ? null : Number(item._value),
        String: v
      };
      item._self.dynObject.akEvent = akEvent;
      item._self.iOldVal = akEvent.currentValue.Numeric;
    }
  },

  getValue: function(item) {
    const cDisplayValue = $(item._self.dhx).val();
    try {
      // Return Numeric of akEvent if it matches display value.
      const akEvent = item._self.dynObject.akEvent;

      if (akEvent && cDisplayValue === '' && akEvent.currentValue.Numeric === null)
        return null;

    } catch (e) {
      console.warn(e);
    }

    if (item._value === null || item._value === '')
      return null;

    const precision = item.akElm.opt.DecimalsPrecision;
    return (isNull(item._value.toFixed) || isNull(precision)) ? parseFloat(item._value) : parseFloat(item._value.toFixed(precision));
  },

  // required for setting it as mandatory
  _getItemNode: function(item) {
    return item;
  },

  setNumberFormat: function(item, format, g_sep, d_sep, refresh) {

    if (typeof (refresh) != 'boolean') refresh = true;

    if (format == '') {
      item._df = null;
      if (refresh) this.setValue(item, item._value);
      return true;
    }

    if (typeof (format) != 'string') return;

    const fmt = window.dhx4.template._parseFmt(format, g_sep, d_sep);
    if (fmt == false) return false; else item._df = fmt;

    if (refresh) this.setValue(item, item._value);

    return true;

  },
  _validate: function(item) {
    const val = this.getValue(item);

    if (item.akElm) {
      const oForm = item.akElm.form.akElm;
      const fieldEnabled = oForm.getFormFieldEnabled(item.akElm.opt.name);
      if (!fieldEnabled)
        return true;


      const fieldHidden = oForm.getFieldIsHidden(item.akElm.opt.name);
      if (fieldHidden)
        return true;

    }

    let resRequired = true;
    if (item._validate) {
      for (let q = 0; q < item._validate.length; q++) {
        const v = `is${item._validate[q]}`;

        if ((val == null || val.length == 0 || val == 0 || isNaN(val)) && v == 'isNotEmpty' && item._type != 'container') {
          // field required and empty
          resRequired = false;
        }
      }
    }

    let res = true;
    if (item.akElm) {
      const oForm = akioma.getForm(item.akElm);
      res = oForm.checkSmartMessageValidation(item.akElm.opt.name);
    } else {
      const ExtendedItemsInputValidate = dhtmlXForm.prototype.items.input._validate;
      res = ExtendedItemsInputValidate.call(this, item);
    }

    const oForm = item.getForm();
    const cFieldName = item.akElm.opt.name;
    const cFieldId = item.akElm.opt.id;

    let errorMessage = '';

    if ((item.akElm.numeric.maxVal !== null && item.akElm.numeric.maxVal < val)) {
      res = false;
      errorMessage = `${akioma.tran('InputnumMessages.numGreaterThanMax', { defaultValue: 'Number is greater than' })} ${item.akElm.numeric.maxVal}`;
    } else if (item.akElm.numeric.minVal !== null && item.akElm.numeric.minVal > val) {
      res = false;
      errorMessage = `${akioma.tran('InputnumMessages.numLessThanMin', { defaultValue: 'Number is less than' })} ${item.akElm.numeric.minVal}`;
    }

    // turn on/off error state in form
    const bValid = (res && resRequired);

    // set form Error State
    if (!bValid) {
      oForm.akElm._setFormFieldError(cFieldName, {
        hasError: true,
        errorMsg: errorMessage
      });

      // add dirty errors state
      if (!oForm.akElm.oVuexState.attributes.hasErrors)
        oForm.akElm._dispatch('incrementHasErrors', 1);
      oForm.akElm._dispatch('setHasErrors', true);

    } else {
      // remove dirty errors state
      const fieldsWithErrorsNo = $(oForm.cont).find('.validate_error').length;
      const bSame = ($(oForm.cont).find('.validate_error').length == 0 || $(oForm.cont).find('.validate_error')[0] == item);
      // remove dirty errors state
      if (oForm.akElm.oVuexState.children[cFieldId].hasError && fieldsWithErrorsNo <= 1 && bSame)
        oForm.akElm._dispatch('decrementHasErrors', 1);


      oForm.akElm._setFormFieldError(cFieldName, {
        hasError: false,
        errorMsg: ''
      });
    }

    return bValid;
  }
};
(function() {
  for (const a in { doAddLabel: 1, doAddInput: 1, destruct: 1, doUnloadNestedLists: 1, setText: 1, getText: 1, enable: 1, disable: 1, setWidth: 1, setReadonly: 1, isRadonly: 1, updateValue: 1, getInput: 1, setFocus: 1 })
    dhtmlXForm.prototype.items.inputnum[a] = dhtmlXForm.prototype.items.input[a];
})();
dhtmlXForm.prototype.setFormData_inputnum = function(name, value) {
  return this.doWithItem(name, 'setValue', value);
};
dhtmlXForm.prototype.getFormData_inputnum = function(name) {
  return this.doWithItem(name, 'getValue');
};

// ********************* input ********************
/**
 * SwatInputNum Control
 * @class ak_inputnum
 * @augments ak_global
 * @param {Object} options Repository attributes for SwatInputNum.
 * @param {boolean} options.CanSort Set to FALSE if this field should not be used to sort the data object query.
 * @param {number} options.WIDTH-CHARS Width in characters. This may currently be used when rendering some objects. There is no get function, use getWidth to retrieve the realized value from an object.
 * @param {string} options.extendedFormat Sets a custom format for the field. Format is numbeFormat|groupSep|decSep. More details can be found on the Confluence page: https://help.build.one/display/AKDOC/Numeric+fields+formatting
 */
$.extend({
  ak_inputnum: function(options) {

    akioma.BaseFormDataField.call(this, options);

    const oSelf = this,
      defaults = { sign: '€ ' };

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;
    this.view = options.view;

    this.registerDynObject = true;
    this.registerVuexWatcher = true;
    this.useParentDynObjectLink = true;

    // get parent
    const oParent = this.parent;


    if (oParent) {
      if (!this.opt.customStyle)
        this.opt.customStyle = this.view;

      if (!this.opt.label) this.opt.label = ' ';
      this.opt.label = akioma.tran(`${akioma.getForm(this).opt.name}.${this.opt.name}`, { defaultValue: this.opt.label });


      if (app.sessionData.objectNamesInTitles) {
        if (!this.opt.note)
          this.opt.note = '';
        this.opt.note = `${this.opt.name} | ${this.opt.note}`;
      }

      if (this.opt.dataType)
        this.opt.dataType = this.opt.dataType.toLowerCase();

      if (this.opt.validate) {
        if (this.opt.validate.startsWith('regExp'))
          this.opt.validate = this.opt.validate.split(':')[1];
      } else if (this.opt.dataType == 'integer')
        this.opt.validate = 'ValidInteger';
      else if (this.opt.dataType == 'decimal')
        this.opt.validate = 'ValidNumeric';
      else this.opt.validate = '';

      // this.opt.numberFormat = "0,000.00"; . , ,
      if (this.opt.dataType == 'integer' || this.opt.dataType == 'decimal') {
        this.numeric = {};
        const cFormat = app.sessionData.globalNumericFormat || akioma.globalData.globalNumericFormat.numberFormat;
        const cDecSep = app.sessionData.globalNumericDecSep || akioma.globalData.globalNumericFormat.decSep;
        const cGroupSep = app.sessionData.globalNumericGroupSep || akioma.globalData.globalNumericFormat.groupSep;
        const cIntFormat = app.sessionData.globalNumericFormat.split('.')[0] || '0,000';

        if (this.opt.extendedFormat) {
          try {
            // At this point this.opt.extendedFormat contains only ' and no "
            // First replace all ' to " so we can JSON parse, then """ -> "'" so we support ' at the cost of not supporting ".
            const parsedJSON = JSON.parse(this.opt.extendedFormat.replace(/'/g, '"').replace(/"""/g, '"\'"'));
            this.numeric.cFormat = (parsedJSON.format) ? parsedJSON.format : cFormat;
            this.numeric.groupSep = (parsedJSON.groupSep) ? parsedJSON.groupSep : cGroupSep;
            this.numeric.decSep = (parsedJSON.decSep) ? parsedJSON.decSep : cDecSep;
            this.numeric.maxLength = (parsedJSON.maxLength) ? parsedJSON.maxLength : 0;
            this.numeric.minVal = parseFloat(parsedJSON.minVal) ? parseFloat(parsedJSON.minVal) : null;
            this.numeric.maxVal = parseFloat(parsedJSON.maxVal) ? parseFloat(parsedJSON.maxVal) : null;
          } catch (e) {
            /* If extendedFormat can't be parsed as JSON we assume its pipes */
            const aParts = this.opt.extendedFormat.split('|');
            this.numeric.cFormat = (aParts[0]) ? aParts[0] : cFormat;
            this.numeric.groupSep = (aParts[1] != undefined) ? aParts[1] : cGroupSep;
            this.numeric.decSep = (aParts[2] != undefined) ? aParts[2] : cDecSep;
            this.numeric.maxLength = (aParts[3] != undefined) ? aParts[3] : 0;
            this.numeric.minVal = parseFloat(aParts[4]) ? parseFloat(aParts[4]) : null;
            this.numeric.maxVal = parseFloat(aParts[5]) ? parseFloat(aParts[5]) : null;
          }

          if (this.opt.dataType == 'integer')
            this.numeric.cFormat = this.numeric.cFormat.split('.')[0] || '0,000';

          if (this.numeric.cFormat.startsWith('+')) {
            this.numeric.cFormat = this.numeric.cFormat.substr(1);
            this.numeric.bPositive = true;
          }
        } else {
          this.numeric.cFormat = (this.opt.dataType == 'integer') ? cIntFormat : cFormat;
          this.numeric.groupSep = cGroupSep;
          this.numeric.decSep = (this.opt.dataType == 'integer') ? '' : cDecSep;
        }
      }

      const oConfig = {
        type: 'inputnum',
        inputTop: parseInt(this.opt.top),
        inputLeft: parseInt(this.opt.left),
        inputWidth: parseInt(this.opt.width),
        label: this.opt.label,
        labelTop: parseInt(this.opt.top),
        labelLeft: oParent.labelLeft(this),
        name: this.opt.name,
        hidden: (this.opt.visible == false),
        className: 'w4-formField w4-inputField active',
        note: { text: (this.opt.note) ? this.opt.note : '', width: this.opt.width },
        position: 'label-top',
        decimals: this.opt.decimals || 0,
        errorMsg: '',
        info: !!this.opt.infoButton,
        hasError: false,
        enabled: !this.opt.disabled,
        mandatory: this.opt.required,
        required: this.opt.required,
        formatnumbers: (this.numberFormat) ? this.numberFormat : '',
        format: (this.opt.format) ? this.opt.format : '',
        readonly: this.opt.disabled,
        validate: (this.opt.validate.startsWith('$')) ? oSelf.validateField() : this.opt.validate,
        userdata: { id: this.opt.id }
      };


      oParent.prop.fields.push(oConfig);

      // append to elements in form
      if (this.parent.view == 'form')
        this.parent.elements.push(this);
      else if (this.parent.view == 'fieldset')
        this.parent.parent.elements.push(this);

      oSelf.customValidationFunctions = {
        checkLength: function(value) {
          if (value.length > 5)
            return true;
          else
            return false;
        },
        greater100: function(value) {
          if (value > 100)
            return true;
          else
            return false;
        }
      };

    }
  }
});

// methods for form
Object.assign($.ak_inputnum.prototype, akioma.BaseFormDataField.prototype, {
  componentOptions: function() {
    const oSelf = this;
    return {
      watch: {
        'getters.getFormFieldState': {
          fn: function(newValue, oldValue) {
            oSelf._hasFormFieldChangesWatcher(newValue, oldValue);
          },
          params: [this.opt.id]
        },
        'getters.getCustomStates': {
          fn: function(customStates) {
            oSelf._customStatesWatcher(customStates);
          },
          params: [this.opt.id]
        },
        'getters.getFormFieldEnabled': {
          fn: function(bEnabled) {
            oSelf._enabledFormFieldWatcher(bEnabled);
          },
          params: [this.opt.id]
        },
        'getters.getFormFieldMandatory': {
          fn: function(bMandatory) {
            oSelf._mandatoryFormFieldWatcher(bMandatory);
          },
          params: [this.opt.id]
        },
        'getters.getFormFieldError': {
          fn: function(newValue, oldValue) {
            oSelf._errorFormFieldWatcher(newValue, oldValue);
          },
          params: [this.opt.id]
        },
        'getters.getFormFieldErrorMsg': {
          fn: function(cErrorMsg) {
            oSelf._errorFormFieldMsgWatcher(cErrorMsg);
          },
          params: [this.opt.id]
        }
      }
    };
  },

  _enabledFormFieldWatcher: function(bEnabled) {
    const oSelf = this;
    const oForm = akioma.getForm(oSelf);
    const cName = oSelf.opt.name;

    if (oForm.dhx) {
      if (bEnabled)
        oForm.setFormFieldReadonly(cName, false);
      else
        oForm.setFormFieldReadonly(cName, true);

    }
  },

  _mandatoryFormFieldWatcher: function(bMandatory) {
    const oSelf = this;
    const oForm = akioma.getForm(oSelf);
    const cName = oSelf.opt.name;

    if (oForm && oForm.dhx) {
      if (typeof (oSelf.setRequired) == 'function')
        oSelf.setRequired(bMandatory);
      oForm.dhx.setRequired(cName, bMandatory);
    }
  },

  /**
   * Watcher for the form error message
   */
  _errorFormFieldMsgWatcher: function(newValue) {
    const oSelf = this;
    const cName = oSelf.opt.name;
    const oForm = akioma.getForm(oSelf);
    const oFormField = akioma.getDhxFormElement(oForm.dhx, cName);

    // setup error message under input
    if (oFormField) {
      const oFormCtrl = $(oFormField).find('> .dhxform_control');
      if (oFormCtrl.find('.validation-error-smartmessage').length == 0)
        oFormCtrl.append(`<span class="validation-error-smartmessage">${newValue}</span>`);
      else
        oFormCtrl.find('.validation-error-smartmessage').text(newValue);
    }

  },

  /**
   * Set field as required.
   * @memberof ak_inputnum
   * @instance
   * @param {Boolean} bRequired
   */
  setRequired: function(bRequired) {
    const oSelf = this;
    const oInput = $(oSelf.dhx).parent()[0];
    (bRequired) ? $(oInput).addClass('akMandatory akInputnumMandatory') : $(oInput).removeClass('akMandatory akInputnumMandatory');
  },

  // finish construct **********
  finishConstruct: function() {
    // get field
    const parentForm = akioma.getForm(this);
    const formAkId = parentForm.akId;
    this.form = parentForm.dhx;

    let oInput;
    try {
      this.dhx = this.form.getInput(this.opt.name);
      oInput = this.dhx;
      const oInputSelector = $(oInput).parent()[0];
      $(oInputSelector).attr('akId', `${formAkId}-${this.opt.name}`); // set akId in inputnum
      $(oInputSelector).attr('akStyle', this.opt.customStyle); // set akStyle in inputnum

      const cCurrSymbol = (this.opt.isCurrency && !this.opt.extendedFormat) ? app.sessionData.mainCurrencySymbol : '';
      this.numeric.cCurrency = cCurrSymbol;
      this.setNumberFormat(cCurrSymbol + this.numeric.cFormat, this.numeric.groupSep, this.numeric.decSep);
    } catch (e) {
      akioma.log.error('error in inputnum_finishconstruct setting .dhx');
    }

    if (this.opt.required)
      $(oInput).parent().addClass('akMandatory akInputnumMandatory');

    if (this.opt.initial)
      this.setValue(this.opt.initial);

    oInput = this.form.getInput(this.opt.name);
    const $input = $(oInput),
      $itemcont = $input.parent().parent();

    $input.css({ textAlign: 'right' });
    // on input focus from tab select text
    $input.on('focusin', () => {
      if (!$input.hasClass('mdown')) {
        $input.select();
        $input.removeClass('mdown');
      }
      $input.css({ textAlign: 'left' });
      this.eventFocus();
    })
      .on('mousedown', () => {
        $input.addClass('mdown');
      })
      .on('blur', () => {
        this.eventBlur();
        $input.removeClass('mdown').css({ textAlign: 'right' });
        $itemcont.removeClass('focusedinp');
      });

    const field = this.form.getInput(this.opt.name);
    this.$domElement = $(field).parent().parent();
    this._setAutocomplete($(field).parent());
    this.setResponsiveSizes();
    this.domTag = $(field)[0];
    this._setTabIndex(this.domTag);
  },

  /**
   * Method called when focusing in inputnum control
   * @memberof ak_inputnum
   * @instance
   * @private
   * @returns {void}
   */
  eventFocus: function() {
    const cValue = $(this.dhx).val();
    this.cOldVal = cValue;

    const formDiv = akioma.getForm(this).dhx.cont.children[0];
    $(formDiv).children().removeClass('focusedinp');

    const $itemcont = $(this.form.getInput(this.opt.name)).parent().parent();
    $itemcont.addClass('active').addClass('focusedinp');

  },

  /**
   * Method called on focusout from inputnum
   * @memberof ak_inputnum
   * @instance
   * @private
   * @returns {void}
   */
  eventBlur: function() {
    const $itemcont = $(this.form.getInput(this.opt.name)).parent().parent();
    $itemcont.removeClass('focusedinp');
  },

  /**
   * Sets the numeric format
   * @param  {String} cFormat  The numeric format (e.g 0,000.00)
   * @param  {String} groupSep The group separator
   * @param  {String} decSep   The decimal separator
   * @instance
   * @memberOf ak_inputnum
   */
  setNumberFormat: function(cFormat, groupSep, decSep) {
    const oSelf = this;
    if (oSelf.opt.dataType == 'integer' || oSelf.opt.dataType == 'decimal') {
      oSelf.form.setNumberFormat(oSelf.opt.name, cFormat, groupSep, decSep);
      akioma.numeric.preventInput(oSelf, oSelf.dhx);
    }
  },

  /**
   * Sets input as anonymised (blurred data)
   * @instance
   * @memberOf ak_inputnum
   */
  setAnonymised: function() {
    const control = $(this.dhx).parent()[0];
    $(control).addClass('anonymisedClass');
  },

  validateField: function() {
    const t = this;
    return function() {
      const funcName = t.opt.validate.substring(1);
      return t.customValidationFunctions[funcName].apply(t, arguments);
    };
  },

  // get value *****************
  getValue: function() {
    const val = this.form.getItemValue(this.opt.name);
    // don't convert null to 0 by using numeric
    return (val === null) ? val : Number(val);
  },

  /**
 * Setting the value of the inputnum
 * @memberof ak_inputnum
 * @instance
 * @param {Object} cValue
 */
  setValue: function(cValue) {
    this.cOldVal = '';
    this.form.setItemValue(this.opt.name, cValue);

    /**
 * Code executed on the client side for validation
 * @event ak_inputnum#EventAkValidate
 * @type {object}
 */
    if (this.opt.validateEvent)
      app.controller.callAkiomaCode(this, this.opt.validateEvent);

  },

  cursorChange: function() {
    this.cOldVal = '';
  },

  // event leave *******************
  eventOnValueChanged: function() {
    try {
      const cValue = (($(this.dhx).val() || ($(this.dhx).val() === null)) ? $(this.dhx).val() : '0');
      let iValue;
      if (this.numeric === null)
        iValue = null;
      else
        iValue = akioma.numeric.unformatValue(cValue, this.numeric);

      const iOldValue = ((this.iOldVal || this.iOldVal === null) ? this.iOldVal : 0);

      // Get formatting for this inputnum
      const customFormat = this.dhx.offsetParent._df;

      // Format values
      const formatFunc = customFormat ? this._getFormatted : null;
      const oValueString = {
        Value: formatFunc ? formatFunc(iValue, customFormat) : iValue.toLocaleString(),
        OldValue: formatFunc ? formatFunc(iOldValue, customFormat) : iValue.toLocaleString()
      };

      if (iOldValue == iValue) return; // Value was not changed, do not update akEvent

      // Set akEvent
      this.dynObject.akEvent = {
        lastValue: {
          Numeric: iOldValue,
          String: oValueString.OldValue
        },
        currentValue: {
          Numeric: iValue,
          String: oValueString.Value
        }
      };

      this.iOldVal = cValue != undefined ? iValue : iOldValue;
    } catch (e) {
      console.warn(e);
    }

  },

  eventLeave: function() {
    /**
         * Code executed on the client side when an input looses focus.
         * @event ak_inputnum#EventLeave
         * @type {object}
         */
    app.controller.callAkiomaCode(this, this.opt.leaveEvent);

    this.callRules({
      eventEntity: this.opt.InstanceName,
      eventName: 'onLeave',
      eventSource: this.opt.InstanceName,
      eventObject: this
    });
  },

  destroy: function() {
    $(this.dhx).off();
    this.dhx.akElm = null;
  }
});
