/**
 * SwatTimeline Control
 * @class ak_timeline
 * @param {Object} options Repository attributes for SwatTimeline.
 * @param {string} options.BorderTitle The Title of a dynamic Viewer or Browser
 * @param {string} options.EventOnInitialize client side code to run when Container has been initialized
 * @param {string} options.contextMenu the id of a menuStructure which will be used as a context-menu
 * @param {string} options.floatingActionButton the id of a menustructure, which will be rendered as a FAB
 * @param {string} options.LayoutOptions List of multi-layout options for the object.
 * @param {string} options.panelMenu comma separated list of menu-structures which will be shown as panelHeader-Buttons. </br>
 * Can also contain the flag #NoDropDown that specifies that the menu should not be loaded as a dropdown but each menu item should be added in the panel-Header. </br>
 * For example: </br>
 * <code>menuStructSave,menuSettings#NoDropDown,menuLookup</code> </br>
 * The buttons support font icons with the following attributes: </br>
 * 1. Css attributes, defined like this: fa fa-user#color:red </br>
         * 2. Css classes, defined like this: fa fa-user#_style:module_prod
         * 3. Stacked font icons, defined like this: fas fa-circle$fas fa-flag. Both icons also support Css attributes or Css classes, like this: fas fa-circle#color:red$fas fa-flag#_style:module_prod </br>
 * @param {string} options.EntityName The Name of the Business Entity used by the component
 * @param {string} options.showGridFilter controls which filter-controls are shown for a grid.&#10;all,none,column-only
 * @param {boolean} options.ENABLED WidgetAttributes Enabled
 * @param {string} options.Template
 * @param {string} options.titleHeader specifies which panelHeader to use. when empty, then uses the header of its own panel. if "none" then no header at all. if "parent" it uses the header of the parent panel
 */
$.ak_timeline = class {
  constructor(options) {
    const defaults = { rows: 50 };

    this.registerDynObject = true;
    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;
    this.view = options.view;

    // initialize tab
    const oParent = this.parent.dhx;
    if (oParent) {
      const timelineParent = $(oParent.cell).find('.dhx_cell_cont_layout');
      timelineParent.prepend('<div class=\'timeline-container\'><div class=\'timeline\'></div></div>');

      $('.timeline').kendoTimeline({
        dataSource: {
          data: [],
          schema: { model: { fields: { date: { type: 'date' } } } }
        },
        alternatingMode: true,
        collapsibleEvents: true,
        orientation: this.opt.Orientation == 'horizontal' ? this.opt.Orientation : 'vertical'
      });

      if (!this.opt.customStyle)
        this.opt.customStyle = this.view;

      this.opt.title = akioma.tran(`${this.opt.name}._title`, { defaultValue: this.opt.title });
      // set title in panel
      this.addObjectDebugInfo();
    } else
      !_isIE && console.error(`No valid parent for timeline ${this.opt.name}`);

  }

  /**
   * Finish construct method
   * @memberof ak_timeline
   * @instance
   */
  finishConstruct() {
    const oSelf = this;

    // set title and panelMenu buttons in panel header
    akioma.setPanelHeader(oSelf);

    if (this.dataSource) {
      const oSource = this.dataSource.dhx;

      oSource.attachEvent('onXLE', () => {
        try {
          const dataSourceData = oSelf.dataSource.getData();
          const dataArray = [];
          let templateOptions = oSelf.opt.templateOptions;
          let templateOptionsObject = { };

          if (templateOptions) {
            templateOptions = templateOptions.replace(/'/g, '"');
            templateOptionsObject = JSON.parse(templateOptions);

            for (const dataSourceDataKey in dataSourceData) {
              for (const [ objectKey, objectValue ] of Object.entries(templateOptionsObject)) {
                if (objectKey === 'date')
                  dataSourceData[dataSourceDataKey][objectKey] = new Date(dataSourceData[dataSourceDataKey][objectValue]);
                else
                  dataSourceData[dataSourceDataKey][objectKey] = dataSourceData[dataSourceDataKey][objectValue];
              }
              dataArray.push(dataSourceData[dataSourceDataKey]);
            }
          }

          $(oSelf.parent.dhx.cell).find('.timeline').data().kendoTimeline.setDataSource(new kendo.data.DataSource({ data: dataArray }));
        } catch (e) {
          akioma.log.error(e);
        }
      });
    }

    // init event
    if (this.opt.onInit)
      app.controller.callAkiomaCode(this, this.opt.onInit);
  }

  /**
   * Destroy method
   */
  destroy() {
    const timeline = $(this.parent.dhx.cell).find('.timeline').data().kendoTimeline;
    if (timeline)
      timeline.destroy();

  }
};
