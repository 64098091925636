/**
 * The Notification Message object, created for handling the Notifications (Non-modal) top right messages
 * @class NotificationMessage
 * @namespace akioma
 */

akioma.NotificationMessage = class {

  /**
     * Displays a notification message to the user
     * @memberOf uiMessage
     * @param {object} opts
     */
  static message(opts) {

    // check for lifetime -> change to expire
    if (!opts.expire && opts.lifetime)
      opts.expire = opts.lifetime;

    if (opts.expire == undefined)
      opts.expire = this.expiredef || 10000;

    if (opts.expire == 0)
      opts.expire = -1;

    let type = 'error';
    if (opts.type !== undefined)
      type = opts.type.toLowerCase();

    // show notification based on type
    switch (type) {
      case 'information':
      case 'info':
      case 'question':
        this.showInfo(opts);
        break;
      case 'warning':
      case 'warn':
        this.showWarning(opts);
        break;
      case 'success':
        this.showSuccess(opts);
        break;
      case 'error':
      case 'alert':
        this.showError(opts);
        break;
      case 'link':
        this.showLink(opts);
        break;
      default:
        this.showError(opts);
    }
  }

  /**
   * Method for showing link type notification messages
   * @param  {string} message
   * @param  {string} linkValue
   * @param  {string} linkDesc
   * @param  {string} bottom
   * @param  {string} options
   * @example
   * akioma.NotificationMessage.showLink({ message: 'Hello from Akioma!', link: 'link-here' });
   * @memberOf uiMessage
   * @return {void}
   */
  static showLink({ message, linkValue, linkDesc, bottom, options }) {
    this.openMessage(message, linkValue, linkDesc, bottom, options);
  }

  /**
     * Notification message with link to open a given link
     * @param  {string} cMessage
     * @param  {string} cLinkValue
     * @param  {string} cLinkDesc
     * @param  {string} cBottom
     * @param  {string} cOptions
     * @memberOf uiMessage
     * @return {void}
     */
  static openMessage(cMessage, cLinkValue, cLinkDesc, cBottom, cOptions) {

    let cLink = '',
      cClick = '';
    let cTarget = '';

    cLink = `javascript: akioma.NotificationMessage.runLink( "${cLinkValue}", "${cLinkDesc}", "${cOptions}" ); `;
    cClick = '';

    // !_isIE && console.log( [ "Link:", cLink]);
    if (_isIE)
      cClick = `${cLink} event.cancelBubble = true; return false;`;
    else if (_isFF)
      cClick = `${cLink} arguments[0].cancelBubble = true; return false;`;
    else
      cClick = `${cLink} arguments[0].cancelBubble = true; return false;`;


    // !_isIE && console.log( [ "Link", cLink]);
    // !_isIE && console.log( [ "click", cClick]);

    if (cMessage != '')
      cMessage += '<br/>';

    cLink = '';
    // cTarget = "blank" + Math.ceil(Math.random()*1000);
    cTarget = '_blank';

    cMessage += `<a href='${cLink}' onclick='${cClick}' target='${cTarget}'>${cLinkDesc}</a>`;


    if (cBottom != '')
      cMessage += `<br/>${cBottom}`;

    // !_isIE && console.log( [ "Message", cMessage]);
    // text:"<img src='assets/alert_small.png'> Need more <a target='blank' href='http://en.wikipedia.org/wiki/Coffee'>coffee!</a><br/><br/> You can use any type of html content here - links, images, etc."

    this.showNotification({
      id: 'openingBox',
      text: cMessage,
      lifetime: -1,
      expire: -1,
      type: 'info'
    });

    return;
  }

  /**
     * Method used for opening the link created using akioma.NotificationMessage.openMessage
     * @param  {string} cLink
     * @param  {string} cLinkDesc
     * @param  {string} cOptions
     * @memberOf uiMessage
     * @private
     * @return {void}
     */
  static runLink(cLink, cLinkDesc, cOptions) {

    // hide the current open box
    dhtmlx.message.hide('openingBox');

    if (cLink.substring(0, 1) === '$')
      app.controller.callAkiomaCode (cOptions, cLink);
    else {
      app.controller.launchContainer({
        proc: 'launchContainer.r',
        para: `SelfHdl=${cLink}&Page=0,1`,
        data: true,
        extLink: cLink,
        self: this
      });
    }
  }

  /**
     * Method for showing info Success notification messages
     * @param  {object} opt List of possible options
     * @param {object} opt.moretext The text to add inside the resulting window, eg. error callstack
     * @param {string} opt.text Text used in notification popup
     * @param {string} opt.expire 0 if you want it to never expire or number of miliseconds, default is 10 seconds if not specified
     * @example
     * akioma.NotificationMessage.showInfo({ text: 'Hello from Akioma!' });
     * @memberOf uiMessage
     * @return {void}
     */
  static showSuccess(opt) {
    let cText = opt.text;

    // check for lifetime -> change to expire
    if (!opt.expire && opt.lifetime)
      opt.expire = opt.lifetime;

    if (opt.expire == undefined)
      opt.expire = this.expiredef || 10000;

    if (opt.expire == 0)
      opt.expire = -1;

    opt.text = replaceNewLines(opt.text);

    let uniqueMoreID;
    if (opt.moretext) {
      uniqueMoreID = `morebtn-${dhtmlx.uid()}`;
      cText = `<p>${opt.text}</p><a href="#" id="${uniqueMoreID}">More</a>`;
    }


    this.showNotification({
      text: cText,
      lifetime: (opt.lifetime ? opt.lifetime : this.expiredef), // keep for old support
      expire: (opt.expire ? opt.expire : this.expiredef),
      type: 'success',
      callback: opt.callback
    });

    if (opt.moretext)
      this.attachMoreWindow(opt, uniqueMoreID);

  }

  /**
     * Method for showing info type notification messages
     * @param  {object} opt List of possible options
     * @param {object} opt.moretext The text to add inside the resulting window, eg. error callstack
     * @param {string} opt.text Text used in notification popup
     * @param {string} opt.expire 0 if you want it to never expire or number of miliseconds, default is 10seconds if not specified
     * @example
     * akioma.NotificationMessage.showInfo({ text: 'Hello from Akioma!' });
     * @memberOf uiMessage
     * @return {void}
     */
  static showInfo(opt) {
    let cText = opt.text;

    // check for lifetime -> change to expire
    if (!opt.expire && opt.lifetime)
      opt.expire = opt.lifetime;

    if (opt.expire == undefined)
      opt.expire = this.expiredef || 10000;

    if (opt.expire == 0)
      opt.expire = -1;

    opt.text = replaceNewLines(opt.text);

    let uniqueMoreID;
    if (opt.moretext) {
      uniqueMoreID = `morebtn-${dhtmlx.uid()}`;
      cText = `<p>${opt.text}</p><a href="#" id="${uniqueMoreID}">More</a>`;
    }


    this.showNotification({
      text: cText,
      lifetime: (opt.lifetime ? opt.lifetime : this.expiredef), // keep for old support
      expire: (opt.expire ? opt.expire : this.expiredef),
      type: 'info',
      callback: opt.callback
    });

    if (opt.moretext)
      this.attachMoreWindow(opt, uniqueMoreID);

  }

  /**
     * uiMessage more notification type window, sets up event listener used when clicking on more button in nofification
     * @param  {object} opt
     * @param {object} opt.moretext The text to add inside the resulting window, eg. error callstack
     * @param  {string} uniqueMoreID The name of the DOM element, more button in notification
     * @private
     * @memberOf uiMessage
     * @return {void}              [description]
     */
  static attachMoreWindow(opt, uniqueMoreID) {
    $(`#${uniqueMoreID}`).on('click', () => {
      akioma.createModalWindows();

      const win = akioma.oWindowsModals.createWindow({
        id: dhtmlx.uid(),
        caption: akioma.tran('messageBox.title.more', { defaultValue: 'More information' }),
        width: 600,
        height: 500
      });
      win.button('park').hide();
      win.button('minmax').hide();
      win.centerOnScreen();
      win.bringToTop();
      win.setModal(true);

      // attach html content
      // console.log(opt.moretext);
      $(win.cell).find('.dhx_cell_cont_wins').css('overflow', 'auto');
      win.attachHTMLString(`<div style="color:#B22556;word-break:break-word;padding: 20px;white-space: pre;">${opt.moretext}</div>`);
    });
  }

  /**
     * Display notification of type error
     * @param  {object} opt List of possible options
     * @param {object} opt.moretext The text to add inside the resulting window, eg. error callstack
     * @param {string} opt.text Text used in notification popup
     * @param {string} opt.expire 0 if you want it to never expire or number of miliseconds, default is 10seconds if not specified
     * @example
     * // display an error that will show up for 3 seconds  and include the more button for error info
     * akioma.NotificationMessage.showError({ text: 'Something bad happend!' , moretext: JSON.stringify(request.response, null, 4), expire: 3000 });
     * @memberOf uiMessage
     * @return {void}
     */
  static showError(opt) {
    let cText = opt.text;

    // check for lifetime -> change to expire
    if (!opt.expire && opt.lifetime)
      opt.expire = opt.lifetime;

    if (opt.expire == undefined)
      opt.expire = this.expiredef || 10000;

    if (opt.expire == 0)
      opt.expire = -1;

    opt.text = replaceNewLines(opt.text);

    let uniqueMoreID;
    if (opt.moretext) {
      uniqueMoreID = `morebtn-${dhtmlx.uid()}`;
      cText = `<p>${opt.text}</p><a href="#" id="${uniqueMoreID}">More</a>`;
    }


    this.showNotification({
      text: cText,
      lifetime: (opt.lifetime ? opt.lifetime : this.expiredef), // keep for old support
      expire: (opt.expire ? opt.expire : this.expiredef),
      type: 'error',
      callback: opt.callback
    });

    if (opt.moretext)
      akioma.NotificationMessage.attachMoreWindow(opt, uniqueMoreID);


  }

  /**
     * Method for clearing all visibile notifications
     * @memberOf uiMessage
     * @return {void}
     */
  static clearAllMessages() {
    for (const i in dhtmlx.message.pull)
      dhtmlx.message.hide(i);

  }

  /**
     * Display notification of type Warning
     * @param  {object} opt List of possible options
     * @param {string} opt.text Text used in notification popup
     * @param {string} opt.expire 0 if you want it to never expire or number of miliseconds, default is 10seconds if not specified
     * @example
     * akioma.NotificationMessage.showWarning({ text: 'Something bad happend!', expire: 3000 });
     * @memberOf uiMessage
     * @return {void}
     */
  static showWarning(opt) {
    // check for lifetime -> change to expire
    if (!opt.expire && opt.lifetime)
      opt.expire = opt.lifetime;

    if (opt.expire == undefined)
      opt.expire = this.expiredef || 10000;

    if (opt.expire == 0)
      opt.expire = -1;

    opt.text = replaceNewLines(opt.text);

    this.showNotification({
      text: opt.text,
      lifetime: (opt.lifetime ? opt.lifetime : this.expiredef), // keep for old support
      expire: (opt.expire ? opt.expire : this.expiredef),
      type: 'warning',
      callback: opt.callback
    });
  }

  /**
     * Fetches the message text, type, details etc. for the message number.
     * Note that the function is async and returns a jQuery promise (see example).
     * @param {string} msgGroup The message group
     * @param {number} msgNum The message number
     * @example
     * akioma.NotificationMessage.getMessage("OpenEdge", 1).done(function(result) {console.log(result)});
     * @memberOf uiMessage
     * @return {promise} Returns a jQuery promise
     */
  static getMessage(msgGroup, msgNum) {

    return akioma.SmartMessage.getMessage(msgGroup, msgNum);
  }


  /**
     * Displays a message box with the text, title, type etc. for a message number
     * @param {string} msgGroup The message group
     * @param {number} msgNum The message number
     * @param {object} opts Additional akioma.message options or callback function
     * @example
     * akioma.NotificationMessage.displayMessage("OpenEdge", 1, function(result) {console.log("Done")});
     * @memberOf uiMessage
     * @return {promise} Returns a jQuery promise
     */
  static displayMessage(msgGroup, msgNum, opts) {

    opts = opts || {};

    const ret = akioma.NotificationMessage.getMessage(msgGroup, msgNum);

    ret.done(function(result) {
      const msg = { text: result.MessageText };

      if (opts.showMessageCode === false)
        msg.text = msg.text.replace(/ *\([^)]*\) */g, '');

      switch (result.MessageType.toLowerCase()) {
        case 'information':
          msg.type = 'information';
          break;

        case 'error':
          msg.type = 'error';
          break;

        case 'warning':
          msg.type = 'warning';
          break;

        case 'success':
          msg.type = 'success';
          break;
      }

      if (typeof opts === 'function')
        msg.callback = opts;
      else
        Object.assign(msg, opts);


      this.showNotification(msg);
    });

    return ret;
  }

  /**
     * Dhtmlx notification type messages
     * @memberof uiMessage
     * @param {object} opts
     */
  static showNotification(opts) {

    opts.text = replaceNewLines(opts.text);

    if (opts.callback)
      opts.callback();

    dhtmlx.message(opts);
  }
};

// def property
akioma.NotificationMessage.expiredef = 10000;
