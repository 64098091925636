$.extend({
  ak_ribbontab: function(options) {
    const defaults = {};

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;

    this.registerDynObject = true;

    // get parent
    const oParent = this.parent;
    if (oParent) {

      this.prop = { fields: [] };

      this.opt.label = akioma.tran(`${this.parent.opt.name}.${this.opt.name}`, { defaultValue: this.opt.label });

      oParent.prop.fields.push({
        'id': this.opt.name,
        'type': 'tab',
        'active': true,
        'text': this.opt.label,
        'items': this.prop.fields
      });
    }
  }
});

$.ak_ribbontab.prototype = {
  // finish construct **********
  finishConstruct: function() {
    this.dhx = null;

    // check visible attribute for tab
    try {
      if (this.opt.hidden)
        this.getAncestor('ribbon').dhx.hide(this.opt.id);
    } catch (e) {
      akioma.log.error(`could not hide ribbon ${this.opt.type}`, e);
    }

    try {
      if (this.opt.disabled)
        this.getAncestor('ribbon').dhx.disable(this.opt.id);
    } catch (e) {
      akioma.log.error(`could not disable ribbon ${this.opt.type}`, e);
    }
  },

  endConstruct: function() {

    if (this.opt.hidden == true)
      this.getAncestor('ribbon').hide(this.opt.name);
    if (this.opt.disabled == true)
      this.getAncestor('ribbon').disable(this.opt.name);

  },
  getValueCombo: function(cId) {
    const oRibbonCtl = this.getAncestor('ribbon').dhx;

    let dhxComboCtl = null;
    dhxComboCtl = oRibbonCtl._items[cId].combo;
    return dhxComboCtl.getSelectedValue();
  }
};
