/**
 * Class for handling backend ckeditor configuration
 *
 * @class
 */
class CkEditorConfig {

  /**
     * Constructor of the CkEditor Config.
     */
  constructor() {
    /**
         * CKEditor configuration object.
         *
         * @protected
         *
         * @type {Object}
         */
    this._config = {};

    /**
         * List of property names which are considered arrays.
         *
         * @protected
         *
         * @type {Array<string>}
         */
    this._arrayProperties = [ 'templates', 'styles', 'specialChars' ];
  }

  /**
     * Initializes the ckEditor configuration from the server.
     *
     * @return {Promise<Object>}
     */
  loadFromServer() {
    const getSettingsPromise = akioma.invokeServerTask({
      name: 'Akioma.Crm.MasterData.System.CkEditorBT',
      methodName: 'GetCkEditorSettings',
      paramObj: { plcParameter: {} }
    });

    getSettingsPromise.then(response => {
      const config = this._parseConfigResponse(response);

      this.setConfig(config, true);
    });

    return getSettingsPromise;
  }

  /**
     * Parses the response from server and returns a ck editor configuration object.
     *
     * @private
     *
     * @return {Object}
     */
  _parseConfigResponse(response) {
    let extendedConfig = Object.assign({}, response.plcParameter.CkEditorConfig);

    extendedConfig = this._parseArrayProperties(extendedConfig, this._arrayProperties);
    extendedConfig = this._parseAdvancedSettings(extendedConfig);

    return extendedConfig;
  }

  /**
     * Parses the advanced settings property of the configuration object and returns a new ckEditor configuration object.
     *
     * @private
     *
     * @param {Object} config Configuration object of which to parse 'editorAdvancedSettings'
     *
     * @return {Object}
     */
  _parseAdvancedSettings(config) {
    let extendedConfig = {};

    if (config.editorAdvancedSettings > '') {
      try {
        extendedConfig = JSON.parse(config.editorAdvancedSettings);
      } catch (err) {
        akioma.log.error('Invalid ckEditor editorAdvancedSettings json!');
        akioma.log.error(err);
      }
    } else
      akioma.log.info('No ckEditor editorAdvancedSettings json has been provided!');

    extendedConfig = Object.assign({}, config, extendedConfig);
    delete extendedConfig.editorAdvancedSettings;

    return extendedConfig;
  }

  /**
     * Parses the array properties of the configuration object and returns a new ckEditor configuration object.
     *
     * @private
     *
     * @param {Object} config Configuration object of which to parse array properties.
     * @param {Array<string>} arrayProperties Array property names which require parsing.
     *
     * @return {Object}
     */
  _parseArrayProperties(config, arrayProperties) {
    const extendedConfig = Object.assign({}, config);

    arrayProperties.forEach(property => {
      const parsedValue = this._parseArrayProperty(extendedConfig[property]);

      if (isNull(parsedValue))
        delete extendedConfig[property];
      else
        extendedConfig[property] = parsedValue;
    });

    return extendedConfig;
  }

  /**
     * Parses the received property as an array. Returns null if property is null/empty.
     *
     * @private
     *
     * @param {string} property Property string to parse.
     * @param {string} [separator=","] Separator used for parsing to array.
     *
     * @returns {Array<string>|null}
     */
  _parseArrayProperty(property, separator = ',') {
    if (isNull(property) || property == '')
      return null;

    return property.split(separator);
  }

  /**
     * Method for getting the configuration
     * @memberOf CkEditor
     * @return {object} Returns configuration
     */
  getConfig() {
    return this._config;
  }

  /**
     * Method for getting a configuration property
     * @memberOf CkEditor
     * @param  {object} name Property name
     * @return {*} Returns configuration property value
     */
  getConfigProperty(name) {
    return this._config[name];
  }

  /**
     * Method for setting the configuration
     * @param {object} config Configuration
     * @param {boolean} [overwrite=false] Overwrite existing configuration?
     * @memberOf CkEditor
     */
  setConfig(config, overwrite = false) {
    this._config = (overwrite) ? config : $.extend(this._config, config);
  }

  /**
     * Method for setting a configuration property
     * @param {string} name Configuration property
     * @param {*} value Property value
     * @memberOf CkEditor
     */
  setConfigProperty(name, value) {
    this._config[name] = value;
  }
}

akioma.CkEditorConfig = CkEditorConfig;
