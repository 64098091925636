akioma.treeDragAndDrop = {
  // dialogue in popup before drag operation
  getDragForm: function() {
    const aSortCopy = [],
      aSortMove = [];

    if (this.drop.srcElm.dynObject.type == 'datagrid') {
      this.drop.srcHdlCopy = this.drop.srcObj.akElm.dataSource.dynObject.getValue('selfhdl');
      if (!this.drop.srcHdlCopy) // quick hack to support dragDrop of repository objects
        this.drop.srcHdlCopy = this.drop.srcObj.akElm.dataSource.dynObject.getValue('smartobject_obj');
    } else {
      // sort selected items
      const aSrcCopy = this.drop.srcHdlCopy.split(',');
      const aSrcMove = this.drop.srcHdlMove.split(',');
      const aSubItem = this.drop.srcElm.dhx.getAllItemIds().split(',');// this should be in the tree order
      for (const i in aSubItem) {
        if ($.inArray(aSubItem[i], aSrcCopy) > -1)
          aSortCopy.push(aSubItem[i]);
        if ($.inArray(aSubItem[i], aSrcMove) > -1)
          aSortMove.push(aSubItem[i]);
      }
      this.drop.srcHdlCopy = aSortCopy.join(',');
      this.drop.srcHdlMove = aSortMove.join(',');
    }

    // analyze source hdl
    const aChildsCopy = [];
    for (const i in aSortCopy) {
      const aSubItem = this.drop.srcElm.dhx.getAllSubItems(aSortCopy[i]).split(',');
      let lChild = true;
      for (const j in aSortCopy) {
        if ($.inArray(aSortCopy[j], aSubItem) > -1)
          lChild = false;
      }
      aChildsCopy.push((lChild) ? 'yes' : 'no');
    }

    const aChildsMove = [];
    aSortMove.forEach(() => aChildsMove.push('yes'));

    this.drop.childsCopy = aChildsCopy.join(',');
    this.drop.childsMove = aChildsMove.join(',');

    // run dialog
    const oData = app.controller.callServerMethod('stubs/getDragDropOptions.p',
      [
        { type: 'iCHAR', value: this.drop.mode }, // copy/move
        { type: 'iCHAR', value: this.drop.srcElm.dataSource.getIndex() },
        { type: 'iCHAR', value: this.drop.srcHdlCopy },
        { type: 'iCHAR', value: this.drop.trgElm.dataSource.getIndex() },
        { type: 'iCHAR', value: this.drop.trgHdl },
        { type: 'iINT', value: (this.drop.dropmode == 'child') ? 3 : 0 },
        { type: 'iCHAR', value: '' },
        { type: 'oCHAR', name: 'proc' },
        { type: 'oCHAR', name: 'status' }
      ]);

    if (this.drop.srcObj.akElm.ctrlKey) {
      this.drop.mode = 'copy';
      this.drop.defForm = 'gcdragdropmode#character#copy|strincludeinsum#character#AS-IS|strmultiplyqty#undefined#1,00|cpoptqtymode#character#multi|strrecalcprices#undefined#yes|gcprophandling#character#ignore|gcspecialoptions#undefined#';
      akioma.treeDragAndDrop.executeDrop.call(this, null);
      return;
    } else if (this.drop.srcObj.akElm.shiftKey) {
      this.drop.mode = 'move';
      this.drop.defForm = 'gcdragdropmode#character#move|strincludeinsum#character#AS-IS|strmultiplyqty#undefined#1,00|cpoptqtymode#character#multi|strrecalcprices#undefined#yes|gcprophandling#character#ignore|gcspecialoptions#undefined#';
      akioma.treeDragAndDrop.executeDrop.call(this, null);
      return;
    }


    if (oData.status != '')
      akioma.message({ type: 'alert', title: 'Copy/Move tree', text: oData.status });
    else if (oData.proc == '$source') {
      const oForm = this.drop.srcElm.dynObject.container.getObject('vDragDrop');
      this.drop.mode = 'copy';
      akioma.treeDragAndDrop.executeDrop.call(this, oForm);

    } else if (oData.proc) {
      app.controller.launchContainer({
        async: true,
        proc: 'popup.r',
        para: `RunFile=${oData.proc}&TargetId=${this.opt.id}`,
        self: this,
        data: true,
        skipDesktopToggle: true
      });
    } else if (!oData.status) {
      this.drop.mode = 'copy';
      akioma.treeDragAndDrop.executeDrop.apply(this, null);
    } else
      akioma.message({ type: 'alert', title: 'Copy/Move tree', text: oData.status });

  },

  // execute drag and drop mechanism -> refresh tree
  executeDrop: function(oForm) {
    this.drop.mode = !isNull(oForm) ? oForm.getValue('gcdragdropmode') : this.drop.mode;
    const mode = this.drop.mode;

    const parameters = {
      DropMode: this.drop.dropmode,
      Target: this.drop.trgHdl,
      Source: mode === 'copy' ? this.drop.srcHdlCopy : this.drop.srcHdlMove,
      IncludeChildren: mode === 'copy' ? this.drop.childsCopy : this.drop.childsMove
    };

    if (!isNull(this.drop.defForm)) {
      parameters.FormVal = this.drop.defForm;
      delete this.drop.defForm;
    } else if (!isNull(oForm))
      parameters.FormVal = oForm.getAllValues(oForm);


    if (!isNull(oForm))
      oForm.getParentOfType('window').controller.dhx.progressOn();

    const methodName = {
      copy: 'CopyStructRecords',
      move: 'MoveStructRecords',
      link: 'LinkStructRecords'
    }[mode];

    akioma.invokeServerTask({
      name: this.getOperationsEntity(),
      methodName,
      paramObj: { plcParameter: parameters },
      showWaitCursor: true,
      uiContext: this.dynObject
    }).done(({ plcParameter }) => {
      try {
        const response = JSON.parse(plcParameter.Response);

        if (response.result === 'error') {
          !_isIE && console.error([ 'Error drag in tree', this, response ]);
          akioma.message({ type: 'alert-error', title: 'Fertig', text: `Fehler: ${this.drop.mode} in tree!<br />${response.message}` });
          return;
        }

        if (response.result === 'warning') {
          akioma.notification({
            type: 'warning',
            lifetime: -1,
            expire: -1,
            text: `${akioma.tran(`TreeDragAndDrop.${this.drop.mode}.warning`, { defaultValue: `Info ${this.drop.mode} erfolgreich beendet!` })}<br />${response.message}`
          });
        } else {
          akioma.notification({
            type: 'info',
            lifetime: 10000,
            expire: 10000,
            text: akioma.tran(`TreeDragAndDrop.${this.drop.mode}.info`, { defaultValue: `${this.drop.mode} erfolgreich beendet!` })
          });
        }

        // REFRESH TREE

        // if move -> delete source elements
        if (this.drop.mode === 'move') {
          const sourceRows = this.drop.srcHdlMove.split(',');
          for (const sourceRow of sourceRows)
            this.drop.srcElm.dhx.deleteRow(sourceRow);
        }

        // refresh nodes after drag&drop
        this.drop.trgElm.refreshNodes(this.drop.trgHdl, this.drop.dropmode);

        // select record -> will be done at the end of openItem
        if (response.select)
          this.prop.select = response.select.split(',');
      } catch (e) {
        !_isIE && console.error([ 'Error moving/copying tree', this, e.message ]);
        akioma.message({
          type: 'alert-error',
          title: 'Moving/Copying in tree',
          text: `${akioma.tran(`TreeDragAndDrop.${this.drop.mode}.error`, { defaultValue: 'Error while moving/copying in tree: ' })}<br />${e.message}`
        });
      } finally {
        this.drop.trgElm.dragDropActive = false;

        // close popup
        if (!isNull(oForm)) {
          oForm.getParentOfType('window').controller.dhx.progressOff();

          if (!oForm.container.controller.dhx.isSticked())
            oForm.container.controller.close();
        }
      }
    }).fail(err => {
      console.error(err);

      this.drop.trgElm.dragDropActive = false;

      // close popup
      if (!isNull(oForm)) {
        oForm.getParentOfType('window').controller.dhx.progressOff();

        if (!oForm.container.controller.dhx.isSticked())
          oForm.container.controller.close();
      }
    });
  },

  initializeDialog: function(self, oContext, cType) {
    if (cType != 'viewer')
      return;

    let isSameOwner = false;
    const oTree = self.container.caller.controller;
    if (oTree.drop.srcElm == oTree.drop.trgElm)
      isSameOwner = true;

    try {
      const oField = self.getField('gcDragDropMode');
      if (isSameOwner)
        oField.controller.dynSelectControl.positionToRecord([{ listItemKey: 'move', listItemDesc: 'Verschieben' }]);
      else
        oField.controller.dynSelectControl.positionToRecord([{ listItemKey: 'copy', listItemDesc: 'Kopieren' }]);


      const oSDO = self.container.caller.parent.getLink('PRIMARYSDO:TARGET');
      if (isValidObj(oSDO) == false) {
        akioma.log.error(`cannot get Primary-SDO Target for: ${self.caller.name}`);
        return '';
      }
    } catch (err) {
      akioma.log.error(`error initDragDrop: ${err.message}`);
    }
  },

  onDialogOk: function(self) {
    const oForm = self.getLink('TOOLBAR:TARGET');
    const cTargetId = self.container.controller.opt.targetId;
    const oTarget = $.getObjectByName({ id: cTargetId });
    akioma.treeDragAndDrop.executeDrop.apply(oTarget, [oForm]);
  },

  onDialogCancel: function(self) {
    self.container.controller.close();
  }
};
