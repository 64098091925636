/* Group */
$.extend({
  ak_ribbongroup: function(options) {
    const defaults = {};

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;

    // get parent
    const oParent = this.parent;
    if (oParent) {

      this.prop = { fields: [{}] };

      this.opt.label = akioma.tran(`${this.parent.opt.name}.${this.opt.name}`, { defaultValue: this.opt.label });

      oParent.prop.fields.push({

        'id': this.opt.name,
        'type': 'group',
        'text_pos': this.opt.textPos,
        'mode': this.opt.mode,
        'text': this.opt.title,
        'list': this.prop.fields

      });

      // append to elements in ribbon
      if (this.parent.elements)
        this.parent.elements.push(this);

    }
  }
});


$.ak_ribbongroup.prototype = {
  // finish construct **********
  finishConstruct: function() {
    this.dhx = null;
  },

  endConstruct: function() {
    if (this.opt.visible == false)
      this.form.hideItem (this.opt.name);
  }

};
