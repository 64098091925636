/**
 * Function used to load the text Languages
 * @param  {object} oSelf The toolbar dynObject
 */
// global context function
// eslint-disable-next-line no-unused-vars
function getTextLang(oSelf) {
  // get options
  const cResourceName = 'Akioma.Crm.MasterData.General.GenericListEntryEntity|lang';

  const oBEoptions =
    {
      'att': {
        cacheLimit: 50,
        catalogURI: '',
        dataSource: '',
        entityName: 'eGenericListEntry',
        id: 'offerw45613645_businessEntity',
        identifier: 'SelfHdl',
        name: 'businessEntity',
        rowsToBatch: 1000,
        resourceName: cResourceName,
        serviceURI: ''
      }
    };

  const businessEntity = new $['ak_businessEntity'](oBEoptions);
  businessEntity.finishConstruct();
  businessEntity.endConstruct();
  const deferred = $.Deferred();
  oSelf.parent.textLangPromise = deferred.promise();

  businessEntity.addAfterFillOnceCallback(oOptions => {
    try {
      // object was already removed
      if (oSelf.setComboOptions == null) {
        deferred.resolve();
        return;
      }

      if (oOptions) {
        const aOpts = [];
        oOptions.each(item => {
          const oItm = { value: item.selfdesc, key: item.selfkey, hdl: item.selfhdl };

          oItm.image = app.nodeTypes[item.selfhdl] ? app.nodeTypes[item.selfhdl].image : null;
          aOpts.push(oItm);
        });


        oSelf.setComboOptions(aOpts);


        // check for pin
        if (app.sessionData.textPin)
          oSelf.click(app.sessionData.textLang);
        else if (oSelf.parent.textTypePromise && oSelf.parent.textTypePromise.state() == 'resolved') {
          // select entry with actual data language
          oSelf.click(app.sessionData.dataLanguage);
        } else
          oSelf.setFieldValue(app.sessionData.dataLanguage);


        deferred.resolve();
      }
    } catch (e) {
      deferred.reject();
      akioma.log.error(e);
      akioma.notification({ type: 'error', text: 'Error loading combo options in getTextLang.', expire: 4000 });
    }

  });

  businessEntity.openQuery({});
}

// global context function
// eslint-disable-next-line no-unused-vars
function textSaveStateChanged(oSelf) {
  let cNewState = '';
  const cState = oSelf.dynObject.getValue('txtTranState');
  const cLang = oSelf.dynObject.getValue('textLang');
  const cType = oSelf.dynObject.getValue('textType');

  let cHdl = oSelf.dynObject.getLink('TABLEIO:TARGET').controller.dataSource.dynObject.getValue('selfhdl');
  const cOptions = '';
  let cText = '';

  if (cLang == app.sessionData.masterLanguage) {
    akioma.showServerMessage('Übersetzungsstatus der Firmensprache kann nicht geändert werden!');
    return;
  }

  cHdl = `${cHdl},${cType},${cLang}`;

  if (cState == 'T')
    cNewState = 'P';
  else
    cNewState = 'T';

  if (cNewState == 'T')
    cText = 'Status ändern auf Übersetzt?';
  else
    cText = 'Status ändern auf Übersetzung notwendig?';

  akioma.message({
    type: 'confirm',
    title: 'Übersetzungsstatus ändern',
    text: cText,
    ok: 'Ändern',
    cancel: 'Abbrechen',
    callback: function(lOk) {
      if (lOk) {
        // send new data language to server
        app.controller.callServerMethod('std/tran/setTranState.p', [
          { type: 'iCHAR', value: cHdl },
          { type: 'iCHAR', value: cNewState },
          { type: 'iCHAR', value: cOptions },
          { type: 'oCHAR', name: 'result' }
        ]);
        oSelf.dynObject.setValue('txtTranState', cNewState);
      }
    }
  });

  return;
}

// global context function
// eslint-disable-next-line no-unused-vars
function textTranStateChanged(oSelf) {
  return;
}

// global context function
// eslint-disable-next-line no-unused-vars
function getTextTranStateOptions(oSelf) {
  const aData = [ { 'hdl': '-', 'key': '-', 'value': ' ' }, { 'hdl': 'T', 'key': 'T', 'value': 'Übersetzt' }, { 'hdl': 'U', 'key': 'U', 'value': 'Unbekannt' }, { 'hdl': 'W', 'key': 'W', 'value': 'In Übersetzung' }, { 'hdl': 'P', 'key': 'P', 'value': 'Übersetzung notwendig' } ];

  oSelf.setComboOptions(aData);
  dhtmlx.delay(() => {
    oSelf.click(aData[0].hdl);
  });
}

// get text types
/**
 * Function used to load text types.
 * @param  {object} oSelf The toolbar dynobject
 */
// global context function
// eslint-disable-next-line no-unused-vars
function getTextTypes(oSelf) {
  const oForm = oSelf.dynObject.getLink('TABLEIO:TARGET').controller,
    cTypeRange = oForm.opt.typeRange;

  const cResourceName = 'Akioma.Swat.System.TypeEntity';
  const deferred = $.Deferred();
  oSelf.parent.textTypePromise = deferred.promise();

  const oBEoptions =
    {
      'att': {
        cacheLimit: 50,
        catalogURI: '',
        dataSource: '',
        entityName: 'eType',
        id: 'offerw45613645_businessEntity',
        identifier: 'SelfHdl',
        name: 'businessEntity',
        rowsToBatch: 1000,
        resourceName: cResourceName,
        serviceURI: ''
      }
    };

  const businessEntity = new $['ak_businessEntity'](oBEoptions);

  businessEntity.addAfterFillOnceCallback(res => {
    try {
      const aOpt = [];
      res.each(item => {
        aOpt.push({
          hdl: item.selfhdl,
          key: item.selfkey,
          value: item.selfdesc
        });
      });
      if (aOpt.length > 0) {

        oSelf.setComboOptions(aOpt);
        dhtmlx.delay(() => {
          if (oSelf.parent.textLangPromise && oSelf.parent.textLangPromise.state() == 'resolved')
            oSelf.click(aOpt[0].hdl);
          else
            oSelf.setFieldValue(aOpt[0].hdl);

          deferred.resolve();
        });
      }


    } catch (e) {
      deferred.reject();
      akioma.notification({ type: 'error', text: 'Error loading combo options.', expire: 500 });
    }


  });
  businessEntity.finishConstruct();
  businessEntity.endConstruct();

  businessEntity.query.addCondition('selfkey', 'begins', cTypeRange);
  businessEntity.query.addCondition('typesort', 'gt', 0);

  businessEntity.openQuery({});
}

// global context function
// eslint-disable-next-line no-unused-vars
function setAkTextIgnoreTranstate() {}

/**
 * Function used for loading the Text Data in a ak_ittext
 * @param  {DynObject} oSelf    The toolbar dynObject.
 * @param  {string} cOptions
 */
function getTextData(oSelf, cOptions) {
  const oTool = oSelf.dynObject,
    cType = oTool.getValue('textType');
  let cLang = oTool.getValue('textLang');

  if (cType && cLang) {

    const oForm = oSelf.dynObject.getLink('TABLEIO:TARGET').controller;
    if (oForm) {

      let cKey;
      if (oForm.opt.foreignFields)
        cKey = oForm.opt.foreignFields.split(',')[1];
      else
        cKey = 'selfhdl';

      // check if changed
      let cActIndex = '';

      if (oForm.dataSource && oForm.dataSource.getIndex())
        cActIndex = oForm.dataSource.getFieldValue(cKey);

      if (cOptions && cOptions == 'external' && akioma.entry(1, cActIndex, ':') == '015')
        cLang = '';


      const serviceURI = window.location.origin,
        jsdoSettings = {
          serviceURI: serviceURI,
          catalogURIs: `${serviceURI}/web/Catalog/Akioma.Swat.Text.TextBT`,
          authenticationModel: progress.data.Session.AUTH_TYPE_FORM
        };

      const onAfterAddCatalog = () => {
        if (oSelf.jsdotext && oSelf.jsdotext.jsdocallxhr)
          oSelf.jsdotext.jsdocallxhr.abort();
        else {
          const dsTasks = new progress.data.JSDO({ name: 'Akioma.Swat.Text.TextBT' });
          oSelf.jsdotext = dsTasks;
        }

        // Perform the asynchronous call
        // true = asynchronously
        let call;
        try {
          call = oSelf.jsdotext.GetText({
            plcParameter: {
              refHdl: cActIndex,
              typeHdl: cType,
              langHdl: cLang
            }
          }, true).deferred.promise();
        } catch (e) {
          akioma.log.warn(e);
        }

        call.then (({ request }) => {
          // Retrieve JavaScript object from ABL serializable parameter
          const oResult = request.response;
          const data = oResult.plcParameter;

          // form object already removed, stop
          if (oForm.dhx == null || oForm.dhx.setItemValue == null)
            return;
          const txtField = oForm.dynObject.getChildOfType('ittext');
          const fieldName = txtField.controller.opt.name;
          oForm.pauseDirtyWatch(fieldName);
          txtField.controller.addChangeEventOnce(() => {
            oForm.resumeDirtyWatch(fieldName);
          });
          oForm.dhx.setItemValue(fieldName, data.HTMLText);

          const cNewLang = data.LangHdl;
          const cNewType = data.TypeHdl;
          const cTranState = data.TranState;

          oTool.setValue('txtTranState', cTranState);
          if (isValidHdl(cNewLang))
            oTool.setValue('textLang', cNewLang);
          if (isValidHdl(cNewType))
            oTool.setValue('textType', cNewType);

          // call.done
        }).catch(({ request }) => {
          let cMoreText = '';

          if (request.response.callStack)
            cMoreText = request.response.callStack;
          else
            cMoreText = request.exception.message;
          const opts = {
            type: 'error',
            text: 'Error fetching text',
            expire: 7000
          };
          if (cMoreText) {
            opts.more = true;
            opts.moretext = cMoreText;
          }
          akioma.notification(opts);
        });
      };

      // create a new session object
      const jsdosession = akioma.restSession;
      jsdosession.addCatalog(jsdoSettings.catalogURIs).then(({ jsdosession, result, info }) => {
        onAfterAddCatalog(jsdosession, result, info);
      });
    }
  }
}

// get text
/**
 * Function that checks if text type combo and text lang combo BE options are loaded and loads the Text Data
 * @param  {DynObject} oSelf    The toolbar dynObject
 * @param  {string} cOptions
 */
// global context function
// eslint-disable-next-line no-unused-vars
function getAkText(oSelf, cOptions) {
  // check of we have to load a text
  if (oSelf.textTypePromise && oSelf.textLangPromise) {
    $.when(oSelf.textTypePromise, oSelf.textLangPromise).then(() => {
      getTextData(oSelf, cOptions);
    });
  } else
    getTextData(oSelf, cOptions);

}

// save text
// global context function
// eslint-disable-next-line no-unused-vars
function saveAkText(oSelf) {
  // check of we can save text
  const oTool = oSelf.dynObject,
    cType = oTool.getValue('textType'),
    cLang = oTool.getValue('textLang'),
    oForm = oTool.getLink('TABLEIO:TARGET').controller;

  const lNoTranState = oTool.controller.dhx.getItemState ('txtNoTranState');

  const oText = oForm.childs[0];
  if (oText && oText.opt.disabled) {
    akioma.showServerMessage('Text ist Schreibgeschützt! Sie haben nicht die notwendigen Zugriffsrechte.');
    return;
  }

  if (cType && cLang) {
    let cKey;
    if (oForm.opt.foreignFields)
      cKey = oForm.opt.foreignFields.split(',')[1];
    else
      cKey = 'selfhdl';

    let cHdl;
    if (oForm.dataSource)
      cHdl = oForm.dataSource.getFieldValue(cKey);

    // get html and convert text
    const cHTML = oText.getValueFormatted();
    let cText;
    try {
      cText = oText.getValue();
    } catch (e) {
      akioma.log.error(`Error converting HTML to text: ${e.message}`);
    }

    if (lNoTranState) {
      akioma.notification({
        type: 'info',
        text: 'Speichern war Übersetzungsneutral<br>Schalter wieder deaktiviert',
        expire: 5000
      });
    }

    akioma.invokeServerTask({
      name: 'Akioma.Swat.Text.TextBT',
      methodName: 'SetText',
      paramObj: {
        plcParameter: {
          ParentHdl: cHdl,
          TypeHdl: cType,
          LangHdl: cLang,
          Options: '',
          ASCIIText: cText,
          HTMLText: cHTML
        }
      },
      showWaitCursor: true,
      uiContext: oTool
    }).done(oResult => {
      const oReturn = oResult.plcParameter;
      oTool.setValue('txtTranState', oReturn.TranState);
      oForm._dispatch('decrementHasChanges', 1);
    }).fail(() => {
      akioma.notification({ type: 'error', text: 'Error saving text' });
    });
  } else
    akioma.notification({ type: 'warn', text: 'Zum Speichern muss Texttyp und Sprache gewählt sein!' });

  oTool.controller.dhx.setItemState ('txtNoTranState', false);

  /* in case of saving text in tree node save the node itself also to refresh tree */
  if (oForm.dynObject.parent.name == 'txtposofrf' || oForm.dynObject.parent.name == 'txtposbomf')
    oForm.dynObject.parent.container.getObject('itTree').controller.tbNodeSave(oSelf);

  if (oForm.opt.eventAfterSave)
    app.controller.callAkiomaCode(oForm, oForm.opt.eventAfterSave);
}
