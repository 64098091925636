$.extend({
  // get parent for dynobject
  getDynParent: function(oElm) {
    if (!oElm)
      return null;

    // check if we are a dynobject
    if (oElm.dynObject && oElm.dynObject.type != 'tabbar' && oElm.dynObject.type != 'sidebar')
      return oElm;

    // try to get it in parents
    if (oElm.parent)
      return $.getDynParent(oElm.parent);

    return null;
  },
  /**
   * Gets object from tree by the controller object, by checking the name
   * @param  {object} oElm Parameters for searching in tree structure
   * @param  {object} oElm.start The container from where to start the search in tree
   * @param {string} oElm.name The name of the object that you want to look for
   * @instance
   * @memberOf ak_global
   * @return {object}      The object found or null
   */
  getObjectByName: function(oElm) {
    const oStart = (oElm.start) ? oElm.start : oDhxTree,
      cView = (oElm.view) ? oElm.view : null,
      cName = (oElm.name) ? oElm.name : null,
      cId = (oElm.id) ? oElm.id : null;

    const searchTree = oStart => {
      let lView, lName, lId, oResult, i;

      if (!oStart || !oStart.childs)
        return null;

      for (i in oStart.childs) {
        lName = (cName) ? oStart.childs[i].opt.name === cName : true;
        lView = (cView) ? oStart.childs[i].view === cView : true;
        lId = (cId) ? oStart.childs[i].opt.id === cId : true;
        if (lName && lView && lId)
          return oStart.childs[i];

        oResult = searchTree(oStart.childs[i]);
        if (oResult)
          return oResult;
      }

      return null;
    };
    return searchTree(oStart);
  }
});
