/**
 * Root Level Logger
 * @class Logger
 * @namespace akioma
 */
akioma.Logger = class extends akioma.BaseLog {
  /**
     * Init root logger
     */
  constructor(log) {
    super('root', log);
    // logger for client-side, setup prefixer
    const prefixer = prefix.noConflict();
    prefixer.reg(log);
    prefixer.apply(log);

    this.loggers = { 'root': this };

  }

  /**
     * Returns logger from mondule name given
     * @memberof Logger
     */
  getLogger(name) {
    if (this.loggers[name] !== undefined)
      return this.loggers[name];

    const logger = new akioma.BaseLog(name, this.log);
    this.loggers[name] = logger;
    return logger;
  }

  /**
     * Returns list of all module loggers
     * @memberof Logger
     */
  getLoggers() {
    return this.loggers;
  }
};
