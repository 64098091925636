// ********************* input text ********************
$.extend({
  ak_inputtext: function(options) {
    const defaults = {};

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;

    this.registerDynObject = true;
    this.useParentDynObjectLink = true;

    // get parent
    const oParent = this.parent;
    if (oParent) {

      this.opt.label = akioma.tran(`${akioma.getForm(this).opt.name}.${this.opt.name}`, { defaultValue: this.opt.label });


      oParent.prop.fields.push({
        type: 'template',
        inputTop: (this.parent.opt.positionMode == 'absolute') ? parseInt(this.opt.top) : 0,
        inputLeft: (this.parent.opt.positionMode == 'absolute') ? parseInt(this.opt.left) : 0,
        inputWidth: (this.opt.inputWidth == 'auto') ? 'auto' : parseInt(this.opt.width),
        inputHeight: parseInt(this.opt.rows * 10),
        label: this.opt.label,
        labelTop: parseInt(this.opt.top),
        labelLeft: oParent.labelLeft(this),
        name: this.opt.name,
        disabled: this.opt.disabled,
        hidden: (this.opt.visible == false),
        className: 'w4-formField w4-inputField akinputtext',
        position: 'label-top',
        rows: (this.opt.rows) ? this.opt.rows : 0,
        value: (this.opt.value) ? this.opt.value : ''
      });

      // append to elements in form
      if (this.parent.view == 'form')
        this.parent.elements.push(this);
      else if (this.parent.view == 'fieldset')
        this.parent.parent.elements.push(this);

    }
  }
});

// methods for form
$.ak_inputtext.prototype = {
  // finish construct **********
  finishConstruct: function() {
    // get field
    this.form = akioma.getForm(this).dhx;
    this.dhx = null;

    if (this.opt.initial)
      this.setValue(this.opt.initial);

    if (this.opt.visible == false)
      this.form.hideItem (this.opt.name);
  },

  // get value *****************
  getValue: function() {
    return this.form.getItemValue(this.opt.name);
  },

  // set value ********************
  setValue: function(cValue) {
    this.form.setItemValue(this.opt.name, cValue);
  },

  // event leave *******************
  eventLeave: function() {
    // check if we have to call the create event
    if (this.opt.leaveEvent)
      app.controller.callAkiomaCode(this, this.opt.leaveEvent);
  }

};
