// ************************* ribboninput ********************************
$.extend({
  ak_ribboninput: function(options) {
    const defaults = {
      id: '',
      name: '',
      title: ''
    };

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;

    this.registerDynObject = true;
    this.useParentDynObjectLink = true;

    // get new element
    const cName = this.opt.name;
    const cTitle = this.opt.title;
    const iPos = this.parent.opt.pos + 1;
    this.parent.opt.pos = iPos;
    this.parent.prop.fields.push({
      'id': cName,
      'text': cTitle,
      'img': '',
      'disable': false,
      'isbig': false,
      'type': 'input'
    });
  }
});

// methods for fill in
$.ak_ribboninput.prototype = {

  // enter text ***************
  enterText: function(cValue) {
    const oRibbon = akioma.getRibbon(this);
    this.value = cValue;

    if (oRibbon.ribbonMenuStructure != undefined) {
      const oMenu = oRibbon.ribbonMenuStructure.getMenuItemByCode(this.opt.id);
      const oMenuAction = oRibbon.ribbonMenuStructure.aMenuActions[oMenu.id];
      this.opt.actiontype = oMenuAction.ActionType;
      this.opt.action = oMenuAction.Action;
      this.opt.category = oMenuAction.Category;
    }
    // call action
    app.controller.callAction({
      ActionType: this.opt.actiontype,
      Action: this.opt.action,
      Category: this.opt.category,
      RunPar: this.opt.RunPar,
      CallObject: this.opt.logicalObject,
      caller: this,
      click: this.opt.name
    },
    this);
    return true;
  },

  // get field value *********
  getFieldValue: function() {
    return this.value;
  },

  finishConstruct: function() {
    this.parent.parent.aItems.push(this.opt.id);
  }
};
