dhtmlXWindows.prototype._zOrder = [
  { name: 'modal', value: true },
  { name: 'sticked', value: true },
  { name: 'sticked', value: false },
  { name: 'sticked', value: 'bottom' }
];

dhtmlXWindowsCell.prototype.keepOnBottom = function(mode) {
  this.wins.w[this._idd].conf.sticked = (mode == true ? 'bottom' : false);
  this.wins._winMakeActive(this.conf.last_active, true);
};

dhtmlXWindowsCell.prototype.isKeepOnBottom = function() {
  return (this.wins.w[this._idd].conf.sticked == 'bottom') ? true : false;
};
