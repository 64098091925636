/**
 * MenuStructureFactory class used for caching the menus in Memory
 */
akioma.MenuStructureFactory = class {
  /**
   * Initializes menuStructure Factory
   */
  static init() {
    this.menuStructures = {};
  }

  /**
   * Adds menu data to memory class
   * @param {string} id MenuStructure id
   * @param {object} menuData JSON menu data
   * @returns {void}
   */
  static add(id, menuData) {
    this.menuStructures[id] = menuData;
  }

  /**
   * Displays Menu load Error
   * @param {object} res response object
   * @param {array} aPendingMenus List of loaded menus
   * @returns {void}
   */
  static showMenuLoadError(res, aPendingMenus) {

    const cPendingMenus = aPendingMenus.map(pendingMenu => ` ${pendingMenu.opt.id}`).join(',');

    if (!akioma.ignoreClientLogicErrors) {
      akioma.notification({
        type: 'error',
        text: `Could not load menu structures: <br/>${cPendingMenus}`,
        more: true,
        moretext: JSON.stringify(res.responseJSON, null, 4)
      });
    }

  }

  /**
   * Method for loading a list of menu structures
   * @param {array} aPendingMenus A list of menuStructure codes
   * @returns {Promise}
   */
  static loadMenus(aPendingMenus) {
    // build params query string
    const sParams = aPendingMenus.map(pendingMenu => `${pendingMenu.opt.id}:${pendingMenu.opt.depth}`).join(',');
    const urlPath = `/web/SwatMenuStructures?menustructurecodedepth=${sParams}`;

    return $.ajax({ url: urlPath });
  }

};
// initialize MenuStructure Factory
akioma.MenuStructureFactory.init();
