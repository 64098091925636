// ********************** data grid col *****************
$.extend({
  /**
     * SwatGridCol
     * @class  ak_datagridcol
     * @param  {object} options Repository Attributes for SwatGridCol
     * @param {string} options.FilterOperator Specify Filter as a string. Options are:
     *
     * ```'eq', '=', 'neq','ne','<>', 'startswith', 'begins', 'contains', 'matches', 'ends', 'gte', 'ge', '>=', 'gt', '>', 'lte','le','<=','lt','<','and','or'```
     * .. or you can specify an event eg. ```$ akioma.getFilterOpSample(self)```.
     * Code Sample:
     * ```
     * akioma.getFilterOpSample = function(oCol){
     *    console.log(['getFilterOp', oCol]);
     *    var oParent = oCol.parent,
     *        cFilter = 'eq';
     *
     *    if(oParent.opt.name == 'AdresseGrid')
     *        cFilter = 'startswith';
     *
     *    // return the filter to use per column
     *    return cFilter;
     * };
     * ```
     * @param {string} options.objectName New Object name for grid column.
     * @param {string} options.align alignment of column. can be left, right or centered
     * @param {string} options.CanSort Set to FALSE if this field should not be used to sort the data object query.
     * @param {string} options.Width Column width.
     * @param {string} options.DATA-TYPE Specify column data type.
     * @param {string} options.FolderWindowToLaunch Window to launch from a grid column.
     * @param {string} options.LABEL The label of the grid column.
     * @param {string} options.EventClick Code executed on click event.
     * @param {string} options.EventEntry Code executed on focus event.
     * @param {string} options.EventLeave Code executed on blur/leave event.
     * @param {string} options.EventAkValidate
     * @param {string} options.cacheStrategy Specify type of cache strategy to use. Use <code>client</code> for saving in the local store or empty for disabling the local store cache.
     * @param {string} options.extendedFormat Defines the font-icons used for logical_filter columns. Pipe-separated list of font-icons: emptyIcon|trueIcon|falseIcon|nullIcon|filterTrueIcon|filterFalseIcon. Every icon is optional. The first four icons refer to the rows cells, the last two icons refer to the column header cells.
     * @param {boolean} options.multiple Specifies single selection (false) or multiple selection (true). Default is false.
     *
     */
  ak_propertygridcol: function(options) {
    const oSelf = this;
    this.opt = $.extend({}, options.att);
    this.parent = options.parent;
    this.security = {};

    if (this.opt.filter == '#dynselect_filter') {
      const oDynSelect = oSelf.opt.dynSelect;

      akioma.createBusinessEntity(this, this.opt.dynSelect);

      if (oSelf.opt.dynSelect.preLoad && oSelf.businessEntity) {
        if (oSelf.businessEntity.jsdo == undefined) {
          const oDynSelectLoadPromise = oSelf.loadDynselectBE();
          oSelf.parent.aPromises.push(oDynSelectLoadPromise);
        }
      }

      oSelf.tokenSeparators = [];
      oSelf.maximumSelectionLength = 0;
      if (oDynSelect.maximumSelectionLength)
        oSelf.maximumSelectionLength = oDynSelect.maximumSelectionLength;
      if (oDynSelect.tokenSeparators)
        oSelf.tokenSeparators = oDynSelect.tokenSeparators.split('|');
    }

    this.getSortPhrase();

    if (this.opt.dataField)
      this.parent.columns[this.opt.dataField.toLowerCase()] = this;
  }
});

// methods for datagridcol
$.ak_propertygridcol.prototype = {
  finishConstruct: function() { },

  /**
     * Method used for loading the dynselect filter options for display of data cell values in grid.
     * @instance
     * @private
     * @return {Promise}
     */
  loadDynselectBE: function() {
    const oSelf = this;
    const def = $.Deferred();

    oSelf.businessEntity.batchSize = { top: 500 };

    const oPromiseCache = akCacheDb.getFromCache(`${oSelf.parent.opt._ObjectName}_${oSelf.opt._InstanceName}`);

    // cleanup if there is a record // no cache Strategy setup
    if (oSelf.opt.cacheStrategy === '') {
      oPromiseCache.done(() => {
        // remove from cache
        akCacheDb.deleteFromCache(`${oSelf.parent.opt._ObjectName}_${oSelf.opt._InstanceName}`);

      });
      // load no-cache mode
      oSelf._parseLoadedDynselect([], def);
    } else if (oSelf.opt.cacheStrategy == 'client') {
      // check and load from akCacheDb - indexed db records
      oPromiseCache.always(res => {
        // call business entity method to get lastChangedTimestamp
        akCacheDb.checkForNew(oSelf.businessEntity.resourceName).done(resTimestamp => {
          // use timestamp and check for indexedDb entry, if not load from BE
          // loads from indexedDb based on timestamp returned
          oSelf._parseLoadedDynselect(res, def, resTimestamp.plcParameter.Value);
        });

      });
    }
    return def.promise();
  },

  /**
     * closeEvent in dynselect filter
     * @param  {Object} e event
     * @private
     * @instace
     * @memberOf ak_datagridcol2
     * @return {Promise}
     */
  closeResults: function() {},

  /**
     * Parse dynselect data for preloading filter options and cells descriptions, if no cache then it will request for
     * data and save in the local store if the cacheStrategy is set to 'client'
     * @param  {Object} res IndexedDb PromiseCache result
     * @param {String} timestampLastChanged The returned GetTimeLastChanged
     * @param  {Deferred} def The promise to resolve for final data display, waiting for options load
     * @private
     * @instace
     * @memberOf ak_datagridcol2
     * @return {Promise}
     */
  _parseLoadedDynselect: function(res, def, timestampLastChanged) {
    const oSelf = this,
      cCacheStrategy = oSelf.opt.cacheStrategy.toLowerCase(),
      bUseCache = (cCacheStrategy === 'client');

    // if there was an entry in the indexeddb, compare if saved timestamp is older than the timestampLastChanged
    if (bUseCache && res.data && timestampLastChanged === res.timestamp && !isNull(timestampLastChanged)) {
      oSelf.rows = res.data;
      oSelf.businessEntity.batchSize.top = 10;
      def.resolve(oSelf.rows);
    } else {
      // load from BE, preload data
      oSelf.businessEntity.addAfterCatalogAdd(() => {
        oSelf.businessEntity.setNamedQueryParam('AutoCompleteSearch', 'SearchKey', '', 'character');
        oSelf.businessEntity.aAfterFillOnce = [];

        oSelf.businessEntity.addAfterFillOnceCallback(rows => {
          oSelf.businessEntity.batchSize.top = 10;

          const aRecs = [];
          rows.each(item => {
            aRecs.push(item);
          });
          oSelf.rows = aRecs;

          // add to indexdb cache
          // only if cacheStrategy is 'client'
          if (bUseCache)
            akCacheDb.addToCache(`${oSelf.parent.opt._ObjectName}_${oSelf.opt._InstanceName}`, timestampLastChanged, aRecs);

          def.resolve(oSelf.rows);
        });
        oSelf.businessEntity.openQuery({ applyFilters: true });

      });
    }
  },

  setDefaultAttributes: function() {
    const oSelf = this;
    const oDynSelect = oSelf.opt.dynSelect;

    if (!oSelf.businessEntity) {
      const aListItemFields = [ 'listitemkey', 'listitemdesc', 'listitemshortdesc' ];
      if (aListItemFields.indexOf(oDynSelect.lookupKeyField.toLowerCase()) == -1)
        oDynSelect.lookupKeyField = 'listItemDesc';
    }
  },

  displayMultiFields: function(oSelected) {
    const oSelf = this;
    let cRes = '';
    let bField = false;

    if (oSelf.opt.dynSelect.lookupKeyField) {
      const aFields = oSelf.opt.dynSelect.lookupKeyField.split(',');
      for (let i = 0; i < aFields.length; i++) {
        const cField = aFields[i];
        if (cField.indexOf('\'') == -1) {
          if (!isNull(oSelected[cField])) {
            cRes += oSelected[cField];
            bField = true;
          }
        } else
          cRes += cField.replace(/["']/g, '');

      }
    }

    if (bField == false)
      cRes = '';
    oSelf.cLookupToDisplay = cRes;
    return cRes;
  },

  selectionChanged: function(e, tag) {
    const oSelf = this;
    const oGrid = this.parent;
    const data = e.params.data;
    const lookupKeyField = oSelf.cLookupToDisplay;
    const lookupKeyValueColumn = oSelf.opt.dynSelect.lookupKeyValueColumn;
    const multiple = oSelf.opt.dynSelect.multiple;


    if (oGrid) {
      setTimeout(() => {
        oGrid.bFilterDynselect = true;
      }, 300);
    }
    oSelf.bFilterDynselect = true;
    oGrid.removeEmptyTag_dynSelect(e.target.nextSibling);
    const cCurMultiFilterColName = oGrid.aDynSelectFilters[tag.index].colname;

    let oSelectedItem;
    if (oSelf.businessEntity) {
      oSelectedItem = {
        'id': data[lookupKeyValueColumn] || data.id,
        'desc': lookupKeyField || data.id
      };
    } else {
      oSelectedItem = {
        'id': data.listItemKey || data.id,
        'listItemKey': data.listItemKey || data.id,
        'listItemDesc': data.listItemDesc || data.id,
        'listItemShortDesc': data.listItemShortDesc || data.id,
        'listItemText': data.listItemText || data.id
      };
    }

    if (multiple == false)
      oSelf.clearSelect(e, tag);

    oGrid.setDynSelectFilters(cCurMultiFilterColName, oSelectedItem);
    const joinedVals = oGrid.aDynSelectStatus.aSelectedValues[cCurMultiFilterColName].join('|');
    $(tag).find('select.dynSelect')[0].dataset.selectVal = joinedVals;

    if (oSelf.opt.dynSelect.validateEvent)
      app.controller.callAkiomaCode(oSelf, oSelf.opt.dynSelect.validateEvent);

    $(tag).find('span.select2-selection--multiple').scrollTop(0);
  },

  selectionRemoved: function(e, tag) {
    const oSelf = this;
    const oGrid = this.parent;
    const lookupKeyValueColumn = oSelf.opt.dynSelect.lookupKeyValueColumn;

    function deleteFilter(list, filterVal, bMode) {
      if (bMode) {
        for (let i = 0; i < list.length; i++) {
          if (list[i].id == filterVal)
            list.splice(i, 1);
        }
      } else {
        for (let i = 0; i < list.length; i++) {
          if (list[i] == filterVal)
            list.splice(i, 1);
        }
      }
    }

    const cCurMultiFilterColName = oGrid.aDynSelectFilters[tag.index].colname;
    deleteFilter(oGrid.aDynSelectStatus.aSelectedItems[cCurMultiFilterColName], e.params.data[lookupKeyValueColumn] || e.params.data.id, true);
    deleteFilter(oGrid.aDynSelectStatus.aSelectedValues[cCurMultiFilterColName], e.params.data[lookupKeyValueColumn] || e.params.data.id);
    const joinedVals = oGrid.aDynSelectStatus.aSelectedValues[cCurMultiFilterColName].join('|');
    $(tag).find('select.dynSelect')[0].dataset.selectVal = joinedVals;
    oSelf.bRemoveVal = true;
  },

  clearSelect: function(e, tag) {
    const oGrid = this.parent;
    const cCurMultiFilterColName = oGrid.aDynSelectFilters[tag.index].colname;
    oGrid.aDynSelectStatus.aSelectedValues[cCurMultiFilterColName] = [];
    oGrid.aDynSelectStatus.aSelectedItems[cCurMultiFilterColName] = [];
  },

  openingResults: function(e, tag) {
    const oSelf = this;

    if ($(tag).data('unselecting')) {
      $(tag).removeData('unselecting');
      e.preventDefault();
    }
    if ($(tag).find('select.dynSelect').data('bClose') && oSelf.opt.dynSelect.multiple)
      e.preventDefault();
  },

  openResults: function(e, tag) {
    $(tag).find('span.select2-selection--multiple').scrollTop(0);
    $('span.select2-dropdown').addClass('custom-dropdown');

    const oParent = this.parent;

    if (oParent) {
      if (this.timeoutFilterOpened)
        clearTimeout(this.timeoutFilterOpened);
      this.timeoutFilterOpened = setTimeout(() => {
        oParent.bFilterDynselect = false;

      }, 500);
    }
  },

  unselectingResult: function(e, tag) {
    $(tag).data('unselecting', true);
  },

  // edit cell ***********************
  editCell: function(cEvent) {
    // check if there is a code for this event
    if (this.opt[cEvent]) {
      try {
        app.controller.callAkiomaCode(this, this.opt[cEvent]);
      } catch (e) {
        akioma.log.error(`Error while executing event: ${cEvent} in col '${this.opt.dataField}': ${e.message}`);
      }
    }
  },

  // get sort phrase attribute setting
  getSortPhrase: function() {
    let cSortPhrase = this.opt.sortPhrase;
    const oSort = null;

    if (cSortPhrase) {
      cSortPhrase = this.opt.sortPhrase;
      this.parent.aSortPhrases[this.opt.dataField] = cSortPhrase;
    }
    return oSort;
  },

  // get value **************
  getValue: function() {
    const oGrid = this.parent.dhx,
      cRow = oGrid.getSelectedRowId(),
      iCol = oGrid.getColIndexById(this.opt.dataField),
      oCell = oGrid.cellById(cRow, iCol);

    return oCell.getValue();
  },

  // get value from server *************
  getValueFromServer: function(cValue, oLookup, cType) {

    const oData = getLookupValue({
        value: cValue,
        tableName: this.opt.lookup_tableName,
        extHdl: this.opt.lookup_extHdl,
        extKey: this.opt.lookup_extKey
      }),
      oParent = this.parent,
      oSource = oParent.dataSource;

    oData.key = (oData.value == '?') ? '?' : cValue;

    if (cType == 'cell') {
      oParent.setFieldValue(this.opt.dataField, oData.value);
      oParent.setFieldValue(this.opt.lookup_showDesc, oData.valueDesc);
      oSource.setFieldValue({ name: this.opt.lookup_showKey, value: oData.key, state: true });
    } else
      return oData;
  },

  // get ext value ****************
  getExtValue: function(oSource) {

    // get value
    const cValueHdl = oSource.getFieldValue(this.opt.lookup_extHdl),
      cValueKey = oSource.getFieldValue(this.opt.lookup_extKey),
      cValueDesc = oSource.getFieldValue(this.opt.lookup_extDesc);

    // get datasource of grid
    const oParent = this.parent;
    oParent.dataSource.setFieldValue({ name: this.opt.lookup_showKey, value: cValueKey, state: 'update' });
    oParent.setFieldValue(this.opt.lookup_showDesc, cValueDesc);
    oParent.setFieldValue(this.opt.dataField, cValueHdl);
  },

  // getLookupKey
  getLookupKey: function(cIndex) {
    const oSource = this.parent.dataSource,
      cKey = oSource.getFieldValue(this.opt.dynSelect.lookupKeyValueBinding, cIndex);

    return cKey;
  },

  setLookupFilter: function(oSource) {
    // get filter field
    const oGrid = this.parent.dhx,
      iCol = oGrid.getColIndexById(this.opt.dataField),
      oInput = oGrid.getFilterElement(iCol),
      cValue = oSource.getFieldValue(this.opt.lookup_extKey),
      cValHdl = oSource.getFieldValue(this.opt.lookup_extHdl);

    oInput.value = cValue;
    oInput.valhdl = (cValHdl == '?') ? null : cValHdl;

    oGrid.filterByAll();
  }
};
