

// *************** panel ***********************
$.ak_panel = class extends akioma.BasePanelSwitcher {
  constructor(options) {
    super();
    const defaults = {
      label: '',
      collapsed: false
    };

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;
    this.aContextMenus = [];
    this.frameContainer = true;

    // flag for active waitCursor
    this.hasActiveCursor = false;

    // flag for batches
    this.loadingBatches = false;

    // get panel
    const oPanelset = this.parent.dhx;
    if (oPanelset) {

      try {
        // get panel
        let cPanelID = this.opt.layout;
        if (cPanelID == '2E')
          cPanelID = 'a';

        this.parent.aPanelIds.push(cPanelID);

        const oPanel = oPanelset.cells(cPanelID);
        if (oPanel) {
          // check for header
          if (this.opt.label) {
            oPanel.setText(this.opt.label);
            oPanel.setCollapsedText(this.opt.label);
          } else
            oPanel.hideHeader();


          if (this.parent.parent.view == 'frame')
            this.opt.width = null;
          if (this.opt.width)
            oPanel.setWidth(parseInt(this.opt.width));

          if (this.opt.height)
            oPanel.setHeight(parseInt(this.opt.height));

          $.extend(this, { dhx: oPanel });

        } else {
          akioma.notification({ type: 'error', title: `Invalid Panel: ${this.opt.layout}`, text: `Error creating panel:<br />Name: ${this.parent.opt.name}<br />` });
          this.cancel = true;
        }
      } catch (e) {
        !_isIE && console.error(`Error creating panel ${this.opt.label} (${this.opt.layout})`, e.message);
        akioma.notification({ type: 'error', title: `Create panel ${this.opt.layout}`, text: `Error creating panel:<br />Name: ${this.parent.opt.name}<br />${e.message}` });
        this.cancel = true;
      }
    } else {
      !_isIE && console.error(`No valid parent for panel ${this.opt.label} (${this.opt.layout})`);
      this.cancel = true;
    }
  }

  finishConstruct() {
    const oPanel = this.dhx;

    if (!this.opt.customStyle)
      this.opt.customStyle = this.view;

    this.oFocus = {};

    try {
      const oSelf = this;
      oPanel.cell.setAttribute('tabindex', 0);
      $(oPanel.cell).focus({ self: oSelf }, e => {
        try {
          e.data.self.setActivePanelState();
        } catch (e) {
          akioma.log.error('could not set active panel state on focus', e);
        }

      });

      $(oPanel.cell).blur({ self: oSelf }, e => {
        try {
          e.data.self.removeActivePanelState();
        } catch (e) {
          akioma.log.error('could not remove active panel state on focus', e);
        }

      });

      // check if panel needs to be hidden
      if (this.opt.hideHeader)
        oPanel.hideHeader();

      // check if disabled/hide arrow so that you can't collapse
      if (this.opt.disabled) {
        oPanel.collapse();
        oPanel.hideArrow();
      }
      // if height or width is available -> set it
      if (this.opt.collapsed)
        oPanel.collapse();


      const fixWidth = (this.opt.fixWidth) ? true : false,
        fixHeight = (this.opt.fixHeight) ? true : false;

      oPanel.fixSize(fixWidth, fixHeight);

    } catch (oSizeErr) {
      akioma.log.error([ 'create panelset restore settings error', this, oSizeErr ]);
    }
  }

  /**
     * Method used for getting the panel of the panel-level object and
     * attaching the panel messages
     * @param {array} namespace The control's namespace
     * @memberof ak_panel
     * @instance
     * @returns {void}
     */
  afterAttachChild(namespace) {
    const oPanel = this.dhx;
    if (oPanel.akElm.getAncestor('window'))
      this.attachPanelMessages(oPanel, namespace);

  }

  /**
     * Method for setting the progress cursor on for this particular object
     * @param {boolean} bPauseProgressOff A value of true will stop all the future progressOff method calls for this element until reset
     * @memberof ak_panel
     * @instance
     * @returns {void}
     */
  progressOn(bPauseProgressOff) {
    if (bPauseProgressOff !== undefined)
      this.dhx.stopProgressOff = bPauseProgressOff;

    this.dhx.progressOn();
    this.hasActiveCursor = true;
  }

  /**
     * Method for setting the progress cursor off for this particular object
     * @param {boolean} bPauseProgressOff A value of true will stop all the future progressOff method calls for
     *  this element until reset
     * @memberof ak_panel
     * @instance
     * @returns {void}
     */
  progressOff(bPauseProgressOff) {
    if (bPauseProgressOff !== undefined)
      this.dhx.stopProgressOff = bPauseProgressOff;

    this.dhx.progressOff();
    this.hasActiveCursor = false;
  }

  /**
     * Method used for returning the next panel inside a panelset
     * @return {ak_panel} The next panel
     * @instance
     * @memberOf ak_panel
     */
  getNextPanel() {
    const oSelf = this;
    const cId = oSelf.opt.layout;
    const aPanels = oSelf.parent.aPanelIds;
    let iNextId;

    for (let i = 0; i < aPanels.length; i++) {
      if (aPanels[i] == cId)
        iNextId = aPanels[i + 1] || aPanels[0];
    }

    const oNextPanel = oSelf.parent.dhx.cells(iNextId);
    if (oNextPanel)
      return (oNextPanel.akElm.view == 'frame') ? oNextPanel.akElm.parent : oNextPanel.akElm;
    else
      return null;
  }

  /**
     * Method used for returning the previous panel inside a panelset
     * @return {ak_panel} The previous panel
     * @instance
     * @memberOf ak_panel
     */
  getPrevPanel() {
    const oSelf = this;
    const cId = oSelf.opt.layout;
    const aPanels = oSelf.parent.aPanelIds;
    let iPrevId;

    for (let i = 0; i < aPanels.length; i++) {
      if (aPanels[i] == cId)
        iPrevId = aPanels[i - 1] || aPanels[aPanels.length - 1];
    }

    const oPrevPanel = oSelf.parent.dhx.cells(iPrevId);
    if (oPrevPanel)
      return (oPrevPanel.akElm.view == 'frame') ? oPrevPanel.akElm.parent : oPrevPanel.akElm;
    else
      return null;
  }

  /**
     * Method used for setting the focus to the first active element in the panel
     * @instance
     * @memberOf ak_panel
     */
  async setFocus(bRowMode, oField) {
    try {
      const oSelf = this;
      let oContent = oSelf.childs.find(obj => [ 'form', 'datagrid2' ].indexOf(obj.view) > -1);
      const oChild = oSelf.childs[0];
      if (oChild.view === 'frame') {
        await oChild.promiseFrame;
        oContent = oChild.getDescendant('panel').childs.find(obj => [ 'form', 'datagrid2' ].indexOf(obj.view) > -1);
      }

      if (!oContent)
        oContent = oSelf;


      const oWindow = oSelf.getAncestor('window');
      if (oWindow)
        oWindow.oActivePanel = oSelf;

      switch (oContent.view) {
        case 'form': {
          const oFirstActive = (oField == 'lastActive') ? oContent.getLastActive() : oField || oContent.getFirstActive();
          if (oFirstActive)
            oContent.setFieldFocus(oFirstActive.name || oFirstActive.opt.name);
          else {
            $(oSelf.dhx.cell).attr('tabindex', '0');
            $(oSelf.dhx.cell).focus();
          }
          break;
        }
        case 'datagrid2':
          if (oContent.dataSource.getNumberofRecords() == 0)
            bRowMode = false;
          if (bRowMode)
            oContent.setRowFocus(oField || 0, true);
          else {
            const iIndex = oContent.getIndex_FirstActiveFilter();
            if (iIndex != null)
              oContent.setHeaderFieldFocus(oField || iIndex);
            else if (!bRowMode)
              oContent.setRowFocus(0, true);
            else {
              $(oContent.dhx.entBox).attr('tabindex', '0');
              $(oContent.dhx.entBox).focus();
            }
          }
          break;
        default:
          $(oSelf.dhx.cell).attr('tabindex', '0');
          $(oSelf.dhx.cell).focus();
          break;
      }
    } catch (e) {
      console.error(e);
    }
  }

  endConstruct() {
    const oPanel = this.dhx;

    const fixWidth = (this.opt.fixWidth) ? true : false,
      fixHeight = (this.opt.fixHeight) ? true : false;

    oPanel.fixSize(fixWidth, fixHeight);
  }

  addPopover(cRepoName, displayType) {
    try {
      const oSelf = this;
      if (cRepoName == undefined)
        cRepoName = 'contactorgf';

      let oNew;
      switch (displayType) {
        case 'LAYOUT':
          oNew = app.controller.parseProc({
            view: 'popover',
            att: {
              id: dhtmlx.uid(),
              loadData: cRepoName
            },
            sub: []
          }, oSelf);
          break;
        case 'RIBBON':
          oNew = app.controller.parseProc({
            view: 'popover',
            att: { id: dhtmlx.uid() },
            sub: [
              {
                view: 'panelset',
                att: {
                  id: dhtmlx.uid(),
                  layout: '1C'
                },
                sub: [
                  {
                    view: 'panel',
                    att: {
                      id: dhtmlx.uid(),
                      layout: 'a'
                    },
                    sub: [
                      {
                        view: 'ribbon',
                        att: {
                          id: dhtmlx.uid(),
                          toolbarkey: `#${cRepoName}`
                        },
                        sub: []
                      }
                    ]
                  }
                ]
              }
            ]
          }, oSelf);
          break;
      }
      oNew.akElm = oNew;
      return oNew;
    } catch (e) {
      console.error('Could not add new popover with repository definition', e);
    }
  }

  setHeaderColor(cColor) {
    try {
      const oPanel = this.dhx;
      $(oPanel.cell).find('> .dhx_cell_hdr').css('background-color', cColor);
    } catch (e) {
      akioma.log.error(`Could not set header color to panel: ${this.opt.id}`, e);
    }
  }

  setActivePanelState() {
    try {
      const oPanel = this.dhx;
      this.parent.oActivePanel = this;

      const aParentsNested = $(oPanel.cell).parents('.dhx_cell_nested_layout');
      let oLastVisibleHdrNestedPanel = null;
      for (let i = aParentsNested.length - 1; i >= 0; i--) {
        const oCurrentPanel = $(aParentsNested[i]);
        if (oCurrentPanel.length > 0 && oCurrentPanel.find('.dhx_cell_hdr_hidden_no_borders').length == 0) {
          oLastVisibleHdrNestedPanel = oCurrentPanel;
          break;
        }
      }

      const $activPanel = $('#active-panel');
      if ($activPanel.length > 0)
        $activPanel.removeAttr('id');

      if (oLastVisibleHdrNestedPanel != null)
        oLastVisibleHdrNestedPanel.attr('id', 'active-panel');
      else if ($(oPanel.cell).find('.dhx_cell_hdr_hidden_no_borders').length == 0)
        $(oPanel.cell).attr('id', 'active-panel');

    } catch (e) {
      akioma.log.error(`Could not set header active state to panel: ${this.opt.id}`, e);
    }
  }

  removeActivePanelState() {
    try {
      this.parent.oActivePanel = null;
      const $activPanel = $('#active-panel');
      if ($activPanel.length > 0)
        $activPanel.removeAttr('id');
    } catch (e) {
      akioma.log.error(`Could not remove header active state from panel: ${this.opt.id}`, e);
    }
  }

  setBG(cColor) {
    try {
      const oPanel = this.dhx;
      $(oPanel.cell).css('background', cColor);
    } catch (e) {
      akioma.log.error('Error setting panel cell bg color', e);
    }
  }

  addNewView(cViewName, cRepoName) {
    try {
      const oSelf = this,
        oPanel = this.dhx;

      const cCurrentView = oPanel.getViewName();

      // creates new view or selects if already available
      oPanel.showView(cViewName);

      if (cCurrentView != cViewName) {
        app.controller.launchContainer({
          target: oSelf,
          proc: 'include.r',
          para: `RunFile=${cRepoName}&Page=0`,
          data: true,
          self: oSelf,
          async: true
        });
      }

    } catch (e) {
      console.error('Could not create new panel', e);
    }
  }

  setOption(option, value, oElm) {
    this.opt[option] = value;

    const oPanel = this.dhx;
    const oPanelset = this.parent.dhx;

    if (oPanel && oPanelset) {
      switch (option) {
        case 'title': {
          let child;
          if (oPanel && oPanel.akElm)
            child = oPanel.akElm.childs[0];
          if (!isNull(child) && child.opt.titleHeader === 'parent-only') {
            const oParentPanel = oPanel.akElm.parent.parent.dhx;
            oParentPanel.showHeader();
            oParentPanel.setText(value);
          } else {
            oPanel.showHeader();
            oPanel.setText(value);
          }
          oPanel.setCollapsedText(value);
          break;
        }
        case 'height':
          if (value)
            oPanel.setHeight(parseInt(value));
          break;
        case 'width':
          if (value)
            oPanel.setWidth(parseInt(value));
          break;
        case 'attachObject':
          oPanel.attachObject(value);
          break;
        case 'treeCont':
          this.prop.treeCont = value;
          break;
        case 'floatingActionButton':
          this.createFabButton(value, oElm);
          break;
        case 'panelPopupObject':
          this.createPopupObject(value);
          break;
        case 'panelMenu':
          akioma.createPanelMenu(value, oElm, this);
          break;
      }
    }
    return this;
  }


  /**
     * Hide the header from a panel
     * @returns {void}
     * @instance
     * @memberOf ak_panel
     */
  hideHeader() {
    this.dhx.hideHeader();
  }

  /**
     * Show the header from a panel
     * @returns {void}
     * @instance
     * @memberOf ak_panel
     */
  showHeader() {
    this.dhx.showHeader();
  }

  /**
     * Expand a panel
     * @returns {void}
     * @instance
     * @memberOf ak_panel
     */
  expand() {
    this.dhx.expand();
  }

  /**
     * Collapse a panel
     * @returns {void}
     * @instance
     * @memberOf ak_panel
     */
  collapse() {
    this.dhx.collapse();
  }

  /**
     * Set panel height
     * @param {number} height
     * @returns {void}
     * @instance
     * @memberOf ak_panel
     */
  setHeight(height) {
    this.dhx.setHeight(height);
  }

  /**
     * Set panel min height
     * @param {number} height
     * @returns {void}
     * @instance
     * @memberOf ak_panel
     */
  setMinHeight(height) {
    this.dhx.setMinHeight(height);
  }

  // attach object
  attachObject(oNode) {
    // get element
    const oPanel = this.dhx;
    if (oPanel)
      oPanel.attachObject(oNode);
  }

  createToolbar(parentId) {
    new dhtmlXToolbarObject({
      parent: parentId,
      items: [{ id: 'test', text: 'Test', type: 'button' }],
      icon_path: oDhx.imagePath
    });
  }

  attachDataviewContextMenu(self) {
    const oHeader = this.dhx;
    const oSelf = self;
    const btnConf = { className: 'class-headerGrid-btn', width: 16, height: 16 };
    const popup = oHeader.attachHeaderButton(btnConf, '');
    const layout = popup.attachLayout(300, 150, '1C');

    /* !!!Test*/
    const ribbon = layout.attachRibbon({ items: [{ id: 'options', type: 'block', text: 'Settings', list: [{ id: 'refresh', type: 'button', text: 'Reload', img: 'dhtmlx/imgs/akioma/refresh-26.png' }] }] });
    const r = layout.base.getElementsByClassName('dhx_cell_layout');
    const h = layout.base.getElementsByClassName('dhx_cell_hdr');
    $(h).remove();
    if (r != null && r[0] != null)
      r[0].style.width = '';

    if (layout.dataNodes.ribbonObj != null) layout.dataNodes.ribbonObj.style.width = '';

    /* !!!Test*/
    ribbon.attachEvent('onClick', () => {
      oSelf.dataSource.reloadData();
      popup.hide();
    });

    return;
  }

  attachGridContextMenu(self) {
    const oHeader = this.dhx;
    const oSelf = self;
    const btnConf = { className: 'class-headerGrid-btn', width: 16, height: 16 };
    const popup = oHeader.attachHeaderButton(btnConf, '');
    const layout = popup.attachLayout(386, 140, '1C');

    /* !!!Test*/
    const ribbon = layout.attachRibbon({
      items: [
        {
          id: 'options', type: 'block', text: 'Settings',
          list: [
            { id: 'hideViewColumns', type: 'button', text: 'Show/hide columns', img: 'imgs/akioma/visible-26.png' },
            { id: 'loadColumnSettings', type: 'button', text: 'Load column settings', img: 'imgs/akioma/upload-26.png' },
            { id: 'clearColumnSettings', type: 'button', text: 'Clear column settings', img: 'imgs/akioma/undo-26.png' },
            { id: 'Multiline', type: 'buttonTwoState', text: 'Multiline', state: true },
            { id: 'Filter', type: 'button', text: 'Filter', img: 'imgs/akioma/filtern.png' }
          ]
        }
      ]
    });
    popup.attachEvent('onShow', () => {
      ribbon.setItemState('Multiline', self.bMultiline);
    });
    const r = layout.base.getElementsByClassName('dhx_cell_layout');
    const h = layout.base.getElementsByClassName('dhx_cell_hdr');
    $(h).remove();
    if (r != null && r[0] != null)
      r[0].style.width = '';

    if (layout.dataNodes.ribbonObj != null) layout.dataNodes.ribbonObj.style.width = '';

    /* !!!Test*/
    ribbon.attachEvent('onClick', cId => {
      if (cId == 'hideViewColumns')
        akioma.gridHideViewColumns(oSelf);
      else if (cId == 'Filter')
        akioma.gridFilterFields(oSelf, null, 'window');
      else if (cId == 'saveColumnSettings')
        UserProfile.saveLocalProfileData(oSelf, akioma.getGridColumnSettings(oSelf));
      else if (cId == 'loadColumnSettings') {
        const oColSettings = UserProfile.loadGridLocalProfileData(oSelf);
        if (oColSettings)
          akioma.applyGridColumnSettings(oSelf, oColSettings);
      } else if (cId == 'clearColumnSettings')
        UserProfile.clearLocalProfileData(oSelf);
      else if (cId == 'saveLayout')
        oSelf.saveProfile();


      popup.hide();
    });
    ribbon.attachEvent('onStateChange', (id, state) => {
      if (id == 'Multiline')
        self.bMultiline = state;

      const oTable = $(self.dhx.obj);
      if (state == true)
        oTable.removeClass('row20px');
      else
        oTable.addClass('row20px');
      popup.hide();

      UserProfile.saveLocalProfileData(oSelf, akioma.getGridColumnSettings(oSelf));
    });

    oSelf.parent.aContextMenus.push(popup);

    return;
  }

  // attach context menu
  attachContextMenu() {
    const cId = 'b';
    const oHeader = this.dhx;
    const oSelf = this;
    const popup = oHeader.attachHeaderButton({}, cId);
    const layout = popup.attachLayout(345, 150, '1C');

    /* !!!Test*/
    const ribbon = layout.attachRibbon({ items: [{ id: 'options', type: 'block', text: 'Settings', list: [{ id: 'saveLayout', type: 'button', text: 'Save Layout', img: 'dhtmlx/imgs/akioma/top_navigation_toolbar-26.png' }] }] });
    const r = layout.base.getElementsByClassName('dhx_cell_layout');
    const h = layout.base.getElementsByClassName('dhx_cell_hdr');
    $(h).remove();

    if (r != null && r[0] != null)
      r[0].style.width = '';

    if (layout.dataNodes.ribbonObj != null) layout.dataNodes.ribbonObj.style.width = '';

    /* !!!Test*/
    ribbon.attachEvent('onClick', () => {
      if (oSelf.childs[0] && oSelf.childs[0].view == 'frame')
        oSelf.childs[0].saveProfile();

      popup.hide();
    });

    this.aContextMenus.push(popup);

    return;
  }

  // context menu
  contextMenu() {
    // check for first frame
    if (this.childs[0] && this.childs[0].view == 'frame')
      this.childs[0].saveProfile();

  }

  toggleShowHide() {
    // get element
    const oPanel = this.dhx;
    if (oPanel) {
      if (oPanel.isCollapsed())
        oPanel.expand();
      else
        oPanel.collapse();
    }
  }

  // load content in container
  loadContent(elm) {
    const deferred = $.Deferred();

    // check if object in frame already loaded
    let progName = this.prop.progName;
    if (progName !== undefined)
      progName = this.prop.progName.replace('.r', '');

    if (progName != elm.screenName.replace('.r', '')) {

      akRepository.selectContainer(elm.screenName);
      akRepository.setCurrentTypeKey(elm.typeKey);

      // load frame in container
      const promiseLaunch = app.controller.launchContainer({
        target: this,
        proc: 'include.r',
        para: `RunFile=${elm.screenName}&Page=0,1&TypeKey=${elm.typeKey}&Datasource=${elm.dataSource}&selfHdl=${elm.rowId}`,
        self: elm.self
      });

      this.prop.progName = elm.screenName;

      akioma.WaitCursor.showProgressState(elm.self.dynObject);

      promiseLaunch.done(res => {
        deferred.resolve(res);
      }).always(() => {
        akioma.WaitCursor.hideProgressState(elm.self.dynObject);
      });

    } else
      deferred.resolve();


    return deferred.promise();
  }

  // show data in content
  showContData(elm) {
    if (this.childs && this.childs.length > 0) {
      const oFrame = this.childs[0];

      // if datasource exists
      let oSource = oFrame.getDataSource();
      if (oSource) {
        oSource = oSource.controller;
        let oMode = null;

        if (elm.self.mode[elm.rowId])
          oMode = elm.self.mode[elm.rowId];

        if (oSource.view == 'businessEntity') {
          if (oMode && oMode.recMode == 'add')
            akioma.WaitCursor.hideProgressState(oFrame.dynObject);
          else {
            let cForeignKey;
            if (oSource.opt.extKey && elm.data) // check if frame DSO extKey is available in data
              cForeignKey = elm.data[oSource.opt.extKey.toLowerCase()];
            if (isNull(cForeignKey)) // use rowId (selfhdl) as backup
              cForeignKey = elm.rowId;
            oSource.openQuery({
              foreignKey: cForeignKey,
              caller: elm.self,
              mode: oMode
            });
            oSource.addAfterFillOnceCallback(() => {
              akioma.WaitCursor.hideProgressState(oFrame.dynObject);
            });
          }
        } else if (oMode && oMode.recMode == 'add') {
          oSource.addRecord({
            extLink: elm.rowId,
            caller: elm.self,
            mode: oMode
          });
        } else {
          oSource.openQuery({
            foreignKey: elm.rowId,
            caller: elm.self,
            mode: oMode
          });
        }

      } else
        akioma.WaitCursor.hideProgressState(oFrame.dynObject);

    }
  }

  // execute option
  execOpt(type, elm) {
    const deferred = $.Deferred();

    switch (type) {
      case 'deleteCont': // delete contents in container
        // destroy children
        if (this.childs.length > 0) {
          this.childs[0].destroy();
          delete this.prop.progName;
        }
        deferred.resolve();
        break;
      case 'openCont': // open container
        // save content data
        this.prop.contData = elm;

        // check if panel is collapsed -> save status
        if (this.dhx.isCollapsed()) {
          // save info
          this.prop.contInfo = elm;
          deferred.resolve();
        } else {
          // check if object in frame already loaded
          const promiseCont = this.loadContent(elm);
          const oSelf = this;

          promiseCont.done(() => {
            // set actual row as external link
            oSelf.showContData(elm);
            deferred.resolve();
          });
        }
        break;
      case 'expand': // expand -> load container and/or data
        // check if something has changed
        if (this.prop.contInfo) {

          // check if we have to load container
          if (this.prop.progName != this.prop.contInfo.progName)
            this.loadContent(this.prop.contInfo);

          // load data
          this.showContData(this.prop.contInfo);

          // delete info
          delete this.prop.contInfo;
        }
        if (this.parent.dhx.cont)
          this.parent.dhx.progressOff();
        deferred.resolve();
        break;

    }
    return deferred.promise();
  }

  createPopupObject(repoObject) {
    const oHeader = this.dhx;
    const btnConf_popover = { className: 'class-headerPanel-btn', width: 16, height: 16 };
    oHeader.attachHeaderButton(btnConf_popover, '', repoObject, '65px');
  }

  createPanelMenu(repoObject, oTarget) {
    let mainMenu;
    const oSelf = this;
    const oHeader = this.dhx;
    const menuStructures = repoObject.split(',');
    let i = 40;

    menuStructures.forEach(structure => {
      const onfinish = function() {
        const oMenu = mainMenu.getMenuStructure();
        let menuStyle = oMenu.style;
        let menuImage = oMenu.icon;

        if (!menuStyle)
          menuStyle = 'CONTEXTMENU';
        if (!menuImage)
          menuImage = 'imgs/akioma/menu_popup.png';

        let btnConf_popover;
        if (menuImage) {
          const iconObject = akioma.icons.getIconType(menuImage);
          switch (iconObject.type) {
            case 'normal-icon':
              btnConf_popover = { className: 'class-headerPanel-btn', width: 16, height: 16, backgroundImg: `url(${menuImage})` };
              break;
            case 'font-icon':
              btnConf_popover = { className: 'class-headerPanelFontIcon-btn', width: 16, height: 16, content: `<i class='${menuImage} fa-fw' aria-hidden='true'></i>` }; // font-icon without style
              break;
            case 'font-icon-attributes':
              btnConf_popover = { className: 'class-headerPanelFontIcon-btn', width: 16, height: 16, content: `<i class='${iconObject.icon} fa-fw' style='${iconObject.attributes}' aria-hidden='true'></i>` }; // font-icon has css attributes attached
              break;
            case 'font-icon-module':
              btnConf_popover = { className: 'class-headerPanelFontIcon-btn', width: 16, height: 16, content: `<i class='${iconObject.icon} fa-fw ${iconObject.style}' aria-hidden='true'></i>` }; // font-icon has style attached
              break;
          }
        }

        let btnStruct_popover, popover;
        switch (menuStyle) {
          case 'CONTEXTMENU':
            popover = oHeader.attachHeaderButton(btnConf_popover, '', undefined, `${i}px`);
            oSelf.createContextMenuButton(popover, mainMenu, oTarget);
            break;
          case 'RIBBON':
            btnStruct_popover = { repoObject: structure, displayType: 'RIBBON' };
            popover = oHeader.attachHeaderButton(btnConf_popover, '', btnStruct_popover, `${i}px`);
            break;
          case 'LAYOUT':
            btnStruct_popover = { repoObject: structure, displayType: 'LAYOUT' };
            popover = oHeader.attachHeaderButton(btnConf_popover, '', btnStruct_popover, `${i}px`);
            break;
        }
        i += 30;
      };
      mainMenu = app.controller.parseProc({
        view: 'menustructure',
        att: { id: structure },
        sub: []
      }, oTarget);
      mainMenu.loadMenuElements(onfinish);

    });
  }

  createFabButton(elm, oTarget) {
    let mainMenu;
    const oSelf = this,
      oPanel = oSelf.dhx;
    try {
      const onfinish = function() {
        // add fab menu items
        mainMenu.scanMenuItemsObj(item => {
          mainMenu.aMainMenuOptions.push({ id: item.id, code: item.code, img: item.icon, tooltip: item.label });
        });

        if (mainMenu.aMenuStructure.eventPre)
          applyAkiomaCode(mainMenu, mainMenu.aMenuStructure.eventPre);

        let bottom = '20px';
        if (oTarget.view == 'datagrid2')
          bottom = '24px';

        const fabButtonID = mainMenu.id + dhtmlx.uid();
        let htmlString = `${'<div class=\'gooeyMenu_container\' style=\'width: 100%; height: 100%; display: contents;\'>' +
                          '<div id=\'gooey-v_'}${fabButtonID}' class='akFabButton' akId='${oTarget.akId}-${mainMenu.id}' style='position: absolute; right: 30px; bottom: ${bottom}';'>` +
                          `<input type='checkbox' class='menu-open' name='menu-open4' id='menu-open4_${fabButtonID}'/>` +
                          `<label class='open-button akFabButtonMenuClose' for='menu-open4_${fabButtonID}'>` +
                          `<i id='plus_${fabButtonID}' class='fa fa-plus' aria-hidden='true'></i></label>`;


        mainMenu.aMainMenuOptions.forEach(fabOption => {
          htmlString += `<a href='#' class='gooey-menu-item' title='${fabOption.tooltip}' data-idItem='${fabOption.id}' akId='${oTarget.akId}-${fabOption.code}'> <span class='${fabOption.img}' style='position: relative;'></span>` +
          '<br class=\'br_gooey\'></a>';
        });

        htmlString += '</div></div>';

        for (let i = 0; i < oPanel.cell.children.length; i++) {
          if (oPanel.cell.children[i].className.includes('gridWrapper')) {
            $(oPanel.cell.children[i]).append(htmlString);
            oPanel.cell.children[i].setAttribute('data-shd', 'true');
            $(oPanel.cell.children[i]).css('z-index', '0');
          }
        }

        const colors = {
          bgColor: '#009688',
          bgColorOpen: 'white',
          contentColor: 'white',
          contentColorOpen: '#009688'
        };

        const $fabMenu = $(`#gooey-v_${fabButtonID}`);

        // init gooeymenu plugin
        $fabMenu.gooeymenu({
          bgColor: colors.bgColor,
          contentColor: colors.contentColor,
          style: 'vertical',
          vertical: {
            menuItemPosition: 'spaced',
            direction: 'up'
          },
          margin: 'small',
          size: 40,
          bounce: true,
          bounceLength: 'small',
          transitionStep: 100,
          hover: colors.bgColor,
          open: function() {
            const $menuItem = $(this).find('.gooey-menu-item'),
              $openBtn = $(this).find('.open-button');
            $menuItem.removeClass('akFabButtonItemClose').addClass('akFabButtonItemOpen');
            $openBtn.removeClass('akFabButtonMenuClose').addClass('akFabButtonMenuOpen');
            $(`#plus_${fabButtonID}`).removeClass().addClass('fa fa-times');
          },
          close: function() {
            const $menuItem = $(this).find('.gooey-menu-item'),
              $openBtn = $(this).find('.open-button');
            $menuItem.removeClass('akFabButtonItemOpen').addClass('akFabButtonItemClose');
            $openBtn.removeClass('akFabButtonMenuOpen').addClass('akFabButtonMenuClose');
            $(`#plus_${fabButtonID}`).removeClass().addClass('fa fa-plus');
          }
        });

        $fabMenu.find('.gooey-menu-item').css('opacity', 0);

        $fabMenu.css('width', 40);
        $fabMenu.css('height', 40);
        $fabMenu.css('padding', 0);


        $fabMenu.find('.gooey-menu-item').off('click').on('click', function() {
          const id = $(this).attr('data-idItem');
          mainMenu.applyAction(id, oTarget);
          $fabMenu.find('input[type="checkbox"]').prop('checked', false).trigger('change');

          return false;
        });
      };
      mainMenu = app.controller.parseProc({
        view: 'menustructure',
        att: { id: elm },
        sub: []
      }, this);
      mainMenu.aMainMenuOptions = [];
      mainMenu.loadMenuElements(onfinish);

    } catch (e) {
      console.warn(`Could not create fabbutton ${this.opt.fabButton}`, e);
    }
  }

  /**
     * Hides a panelMenu button
     * @param {string} name   The button name (SCL menuFunctionCode/menuStructureCode)
     * @instance
     * @memberOf ak_panel
     */
  hidePanelMenuButton(name) {
    this.hideShowPanelMenuButton(name, true);
  }

  /**
     * Shows a panelMenu button
     * @param {string} name   The button name (SCL menuFunctionCode/menuStructureCode)
     * @instance
     * @memberOf ak_panel
     */
  showPanelMenuButton(name) {
    this.hideShowPanelMenuButton(name, false);
  }

  /**
     * Shows/hides a panelMenu button
     * @param {string}  name   The button name (SCL menuFunctionCode/menuStructureCode)
     * @param {boolean} bHide  True for hide, false for show
     * @instance
     * @memberOf ak_panel
     */
  hideShowPanelMenuButton(name, bHide) {
    if (this.aPanelMenuButtons) {
      const button = this.aPanelMenuButtons.find(b => b.code.toLowerCase() === name.toLowerCase());

      if (button) {
        const visible = $(button.dhx).is(':visible');
        if (this.dhx.isCollapsed() || bHide && !visible || !bHide && visible)
          return;

        (bHide) ? $(button.dhx).hide() : $(button.dhx).show();
        const nextButtons = $(button.dhx).nextAll();
        for (const nextButton of nextButtons) {
          const left = parseInt($(nextButton).css('left'), 10);
          (bHide) ? $(nextButton).css('left', left - 25) : $(nextButton).css('left', left + 25);
        }
      }

    }
  }

  /**
     * Loads the filters in the filter menu in panel header
     * @param  {object} oTarget      Target element
     * @param  {object} oContextMenu Dhtmlx Menu Object
     * @param  {boolean} bLoadDef     Loads the default filter, default is true
     * @instance
     * @memberOf ak_panel
     * @private
     * @return {void}
     */
  loadFilterListInMenu(oTarget, oContextMenu, bLoadDef) {
    const aMainMenuOptions = [];

    if (bLoadDef == undefined)
      bLoadDef = true;


    if (oTarget.getFiltersBT == undefined)
      return;


    oContextMenu.clearAll();

    const promiseFilters = oTarget.getFiltersBT();

    oTarget.filtersLoader = promiseFilters;

    $(oContextMenu.base).addClass('filterList');

    let cDef = '';
    // loading filters and maintain filter menu items
    promiseFilters.done(aFilters => {
      aMainMenuOptions.push({ id: 'filterGo', text: 'Refresh' });
      aMainMenuOptions.push({ id: 'filterClear', text: 'Clear' });
      aMainMenuOptions.push({ id: 'divider', type: 'separator' });

      for (const i in aFilters) {
        const oItem = { id: aFilters[i].FilterHdl, text: aFilters[i].Description };

        oTarget.aFtrHdls.push(aFilters[i].FilterHdl);

        if (aFilters[i].IsDefaultFilter) {
          if (bLoadDef)
            oTarget.useFilterBT(aFilters[i].FilterHdl);
          oItem.text = `<i class="filterdef"></i>${oItem.text}`;
          cDef = aFilters[i].FilterHdl;
          oTarget.cDefaultFilterHdl = aFilters[i].FilterHdl;
        }

        aMainMenuOptions.push(oItem);
      }
      aMainMenuOptions.push({ id: 'divider2', type: 'separator' });
      aMainMenuOptions.push({ id: 'maintainFilter', text: 'Maintain Filter' });

      oContextMenu.loadStruct(aMainMenuOptions);
      oTarget.panelHdrContextMenu = oContextMenu;
      if (cDef) {
        $(oContextMenu.idPull[oContextMenu.idPrefix + cDef])
          .parent()
          .find('div[active-filter="true"]')
          .removeAttr('active-filter');
        $(oContextMenu.idPull[oContextMenu.idPrefix + cDef]).attr('active-filter', 'true');
      }

    });
  }

  /**
     * Creates context menu button
     * @param  {object} popover
     * @param  {ak_menustructure} menuStructure The menustructure object
     * @param  {object} oTarget       The target element
     * @instance
     * @private
     * @memberOf ak_panel
     * @return {void}
     */
  createContextMenuButton(popover, menuStructure, oTarget) {
    const oSelf = this;
    const layout = popover.attachLayout(150, 57, '1C');
    popover.p.children[0].className += ' menuPopup';
    layout.cont.className += ' menuLayout';
    layout.cont.children[0].className += ' menuLayout';
    layout.cells('a').hideHeader();

    const aMainMenuOptions = [];
    const oContextMenu = layout.cells('a').attachMenu({});

    oSelf.panelHdrContextMenuParentID = oContextMenu.topId;

    // special grid filter management menu popup
    if (menuStructure.id == 'filterListBT')
      oSelf.loadFilterListInMenu(oTarget, oContextMenu);
    else {
      menuStructure.scanMenuItemsObj(item => {
        if (item.type == 'break')
          aMainMenuOptions.push({ id: item.id, type: 'separator', tooltip: item.tooltip });
        else {
          let iconObject = '';
          if (item.icon)
            iconObject = akioma.icons.getIconType(item.icon);

          if (iconObject && iconObject.type !== 'normal-icon') {
            const iconTemplate = '#icon1# #size# fa-fw #moduleClass1#';
            const styleTemplate = '#iconStyle1#';
            item.iconStyle = akioma.icons.replaceTemplate(styleTemplate, iconObject);
            item.icon = akioma.icons.replaceTemplate(iconTemplate, iconObject);
          }

          aMainMenuOptions.push({ id: item.id, text: item.label, img: item.icon, iconStyle: item.iconStyle, tooltip: item.tooltip });
        }
      });
    }

    function onItemClick(menuitemId) {
      // special filterMangement Header Handling
      if (oTarget.aFtrHdls && oTarget.aFtrHdls.indexOf(menuitemId) != -1)
        oTarget.useFilterBT(menuitemId);
      else if (menuitemId == 'filterGo')
        oTarget.FilterGo();
      else if (menuitemId == 'filterClear')
        oTarget.FilterClearAllAndGo();


      // normal handling, calling the menuStructure function code
      if (menuStructure.applyAction)
        menuStructure.applyAction(menuitemId, oTarget);
      else
        console.warn('No menu Structure: ', menuitemId, oTarget);


      popover.hide();
    }

    function onItemClickActive(menuitemId) {
      if (menuitemId != 'maintainFilter' && menuitemId != 'filterGo' && menuitemId != 'filterClear') {
        $(oContextMenu.idPull[oContextMenu.idPrefix + menuitemId])
          .parent()
          .find('div[active-filter="true"]')
          .removeAttr('active-filter');
        $(oContextMenu.idPull[oContextMenu.idPrefix + menuitemId]).attr('active-filter', 'true');
      }
    }
    oContextMenu.setWebModeTimeout(2000);
    oContextMenu.setIconset('awesome');
    if (menuStructure.id != 'filterListBT')
      oContextMenu.loadStruct(aMainMenuOptions);
    else
      oContextMenu.attachEvent('onClick', onItemClickActive);
    oContextMenu.attachEvent('onClick', onItemClick);

    for (const el in aMainMenuOptions) {
      const cMenuElID = aMainMenuOptions[el].id;
      let cMenuElTooltip = aMainMenuOptions[el].tooltip;

      if (app.sessionData.objectNamesInTitles)
        cMenuElTooltip = `${cMenuElTooltip} >> ${aMainMenuOptions[el].text}`;

      if (cMenuElTooltip)
        oContextMenu.setTooltip(cMenuElID, cMenuElTooltip);
    }

    layout.cont.children[0].children[1].className += ' menuCell';
    if (oContextMenu.cont) {
      for (let i = 0; i < oContextMenu.cont.children.length; i++) {
        $(oContextMenu.cont.children[i].children[0]).css('display', 'inline-block');
        $(oContextMenu.cont.children[i].children[1]).css('display', 'inline-block');
        $(oContextMenu.cont.children[i].children[0]).css('padding', '0 5px 0 15px');

        const styleArray = splitCssString(aMainMenuOptions[i].iconStyle);
        for (const key in styleArray) {
          if (Object.prototype.hasOwnProperty.call(styleArray, key))
            $(oContextMenu.cont.children[i].children[0]).css(key, styleArray[key]);
        }
      }
    }

    oSelf.aContextMenus.push(popover);
  }

  destroy() {
    const oPanel = this.dhx;
    $(oPanel.cell).off('focus');
    $(oPanel.cell).off('blur');

    for (const j in this.aContextMenus) {
      const cm = this.aContextMenus[j];
      cm.unload();
    }

    this.removeActivePanelState();
  }

  /**
     * Get first child of a panel exluding a set of childs, i.e.
     * excluding toolbar, ribbon, menustructure and businessentity
     * @returns {object} panel-level object
     * @instance
     * @memberOf ak_panel
     */
  getFirstChild() {
    const types = [ 'toolbar', 'ribbon', 'menustructure', 'businessentity' ];
    return this.childs.find(element => {
      let match = 0;

      types.forEach(type => {
        if (type.toLowerCase().indexOf(element.view.toLowerCase()) > -1)
          match++;

      });

      if (match === 0)
        return element;

    });
  }

  /**
     * Add an panel message and return the index of the message
     * @param  {object} msg contains the message text and type
     * @instance
     * @memberOf ak_panel
     * @return {number} the index of the message
     */
  displayPanelMessage(msg) {
    const child = this.getFirstChild();
    child._dispatch('setPanelMSG', msg);
    return child._getters('getPanelMSG').length - 1;
  }

  /**
     * Remove an panel message from a controller by the given id
     * @param  {number} id the index of the message
     * @returns {void}
     * @instance
     * @memberOf ak_panel
     */
  removePanelMessageById(id) {
    const child = this.getFirstChild();
    child._dispatch('removePanelMsgById', id);
  }

  /**
     * Remove all panel message from a controller
     * @returns {void}
     * @instance
     * @memberOf ak_panel
     */
  clearAllPanelMessages() {
    const child = this.getFirstChild();
    child._dispatch('clearPanelMsg');
  }

  /**
     * Attach panel messages to panel
     * @param  {object} oPanel
     * @instance
     * @memberOf ak_panel
     */
  attachPanelMessages(oPanel, namespace) {
    const id = oPanel.akElm.getAncestor('window').opt.id;
    let el = '';
    if ($(oPanel.cell).find(' > .dhx_cell_cont_layout .panel-msg').length === 0) {
      $(oPanel.cell).find(' > .dhx_cell_cont_layout').prepend(`<div id="${id}"></div>`);
      el = $(oPanel.cell).find(`#${id}`)[0];
    } else
      el = $(oPanel.cell).find(' > .dhx_cell_cont_layout .panel-msg')[0];


    const controller = oPanel.akElm.childs.find(child => [ 'toolbar', 'ribbon', 'menustructure', 'businessEntity' ].indexOf(child.view) === -1);
    const dataSource = controller && controller.dataSource;

    let datasourceNamespace = [];

    if (dataSource)
      datasourceNamespace = dataSource.getFilteredNamespace();

    try {
      this.VueInstance = new akioma.VueInstancesFactory('panel-messages', {
        namespace,
        datasourceNamespace,
        dataSource,
        controller
      });
      this.VueInstance.mount(el);
    } catch (e) {
      console.error(e);
    }

  }
};
