(function($) {

  // ********************* select list ********************
  $.extend({
    ak_selectlist: function(options) {
      const defaults = {};

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;

      // get parent
      const oParent = this.parent.dhx;
      if (oParent) {
        // -> bind input to form
        const oInput = $('<div style="height: 100%;"><div class="selectlist">Ausgewählt:<br /><ul id="selectlist_in"></ul></div><div class="selectlist">Verfügbar:<br /><ul id="selectlist_out"></ul></div></div>')
          .get(0);

        const aValue = (options.att.value) ? options.att.value.split(' ') : [];

        const aCodeIn = [];
        for (const i in aValue)
          aCodeIn.push(`<li listval="${aValue[i]}" class="ui-widget-content">${this.opt.list[aValue[i]]}</li>`);


        const aCodeOut = [];
        for (const i in this.opt.list) {
          if (aValue.indexOf(i) == -1)
            aCodeOut.push(`<li listval="${i}" class="ui-widget-content">${this.opt.list[i]}</li>`);
        }
        $(oInput)
          .find('ul:first')
          .append(aCodeIn.join(''))
          .end()
          .find('ul:last')
          .append(aCodeOut.join(''))
          .end()
          .find('ul')
          .addClass('ui-helper-reset')
          .sortable()
          .disableSelection()
          .droppable({
            activeClass: 'ui-state-default',
            hoverClass: 'ui-state-hover',
            accept: '#selectlist_in > li, #selectlist_out > li',
            drop: function(event, ui) {
              const oSource = ui.draggable.parent().get(0),
                oTarget = $(this);

              if (oSource != this) {
                ui.draggable.hide('fast', function() {
                  $(this).appendTo(oTarget).show('fast');
                });
              }

            }
          })
          .end();

        oParent.attachObject(oInput);

        oInput.akElm = this;

        $.extend(this, { dom: oInput });
      }
    }
  });

  // methods for select list
  $.ak_selectlist.prototype = {

    // destroy
    destroy: function() {
      // get values
      const aValue = [];
      $('#selectlist_in > li').each(function() {
        aValue.push($(this).attr('listval'));
      });

      // send values
      const oForm = $.getObjectByName({ id: this.opt.target });
      oForm.dynObject.setValue(this.opt.fieldname, aValue.join(' '));

      $(this.dom).remove();
    }
  };

})(jQuery, jQuery);
