
/**
 * Class defining a queue.
 */
class AkQueue {

  /**
   * Constructor of the class
   */
  constructor() {
    /**
     * A queue list.
     *
     * @private
     *
     * @type {Array<*>}
     */
    this._queueList = [];
  }

  /**
   * Length of queued elements.
   *
   * @return {integer}
   */
  get length() {
    return this._queueList.length;
  }

  /**
   * Returns if the queue is empty.
   *
   * @return {boolean}
   */
  isEmpty() {
    return this._queueList.length === 0;
  }

  /**
   * Adds an element at the end of the queue.
   *
   * @param {*} element Element to add in queue.
   *
   * @return {integer} The new length of the queue.
   */
  push(element) {
    return this._queueList.push(element);
  }

  /**
   * Removes the first element from the queue and returns it.
   *
   * @return {*}
   */
  pop() {
    return this._queueList.shift();
  }

  /**
   * Returns the first element from the queue, without removing it.
   *
   * @return {*}
   */
  peek() {
    return this._queueList[0];
  }

  /**
   * Clears the queue. All cleared elements are returned.
   *
   * @return {Array<*>}
   */
  clear() {
    const clearedElements = this._queueList;

    this._queueList = [];

    return clearedElements;
  }

}

akioma.AkQueue = AkQueue;
