// disable focus trap before swal call; re-enable after
const swalOriginal = swal;
window.swal = function(oOptions) {
  akioma.swat.MasterLayout.disableLastFocusTrap();
  const promise = swalOriginal(oOptions);
  return promise.then(() => {
    akioma.swat.MasterLayout.enableLastFocusTrap();
    return promise;
  });
};
