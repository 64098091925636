function eXcell_dhxCalendar(cell) {
  if (cell) {
    this.cell = cell;
    this.grid = this.cell.parentNode.grid;
    if (!this.grid._grid_calendarA) {

      let cal = this.grid._grid_calendarA = new dhtmlxCalendarObject();
      this.grid.callEvent('onDhxCalendarCreated', [cal]);

      const sgrid = this.grid;
      cal.attachEvent('onClick', function() {
        this._last_operation_calendar = true;
        window.setTimeout(() => {
          sgrid.editStop();
        }, 1);
        return true;
      });

      const zFunc = function(e) {
        (e || event).cancelBubble = true;
      };
      dhtmlxEvent(cal.base, 'click', zFunc);
      cal = null;
    }
  }
}
eXcell_dhxCalendar.prototype = new eXcell;

eXcell_dhxCalendar.prototype.edit = function() {
  const iIndex = this.cell._cellIndex,
    cName = this.grid.getColumnId(iIndex),
    cMask = this.grid.calFormats[cName];

  const arPos = this.grid.getPosition(this.cell);

  // hode old instance
  this.grid._grid_calendarA._show(false, false);
  this.grid._grid_calendarA.setPosition(arPos[0], arPos[1] + this.cell.offsetHeight);
  this.grid._grid_calendarA._last_operation_calendar = false;

  this.grid.callEvent('onCalendarShow', [ this.grid._grid_calendarA, this.cell.parentNode.idd, this.cell._cellIndex ]);
  this.cell._cediton = true;
  this.val = this.cell.val;
  this._val = this.cell.innerHTML;

  const t = this.grid._grid_calendarA.draw;
  this.grid._grid_calendarA.draw = function() {};

  this.grid._grid_calendarA.setDateFormat(cMask);

  this.grid._grid_calendarA.setDate(this.val);
  this.grid._grid_calendarA.draw = t;

  // this.grid._grid_calendarA.draw();
};
eXcell_dhxCalendar.prototype.getDate = function() {
  if (this.cell.val)
    return this.cell.val;
  return null;
};

eXcell_dhxCalendar.prototype.getValue = function() {
  const iIndex = this.cell._cellIndex,
    cName = this.grid.getColumnId(iIndex),
    cMask = this.grid.calFormats[cName];
  if (this.cell._clearCell)
    return '';
  if (this.cell.val)
    return this.grid._grid_calendarA.getFormatedDate(cMask, this.cell.val).toString();
  return this.cell.innerHTML.toString()._dhx_trim();
};

eXcell_dhxCalendar.prototype.detach = function() {
  const iIndex = this.cell._cellIndex,
    cName = this.grid.getColumnId(iIndex),
    cMask = this.grid.calFormats[cName];

  if (!this.grid._grid_calendarA)
    return;
  this.grid._grid_calendarA.hide();
  if (this.cell._cediton)
    this.cell._cediton = false;
  else
    return;
  if (this.grid._grid_calendarA._last_operation_calendar) {
    const z1 = this.grid._grid_calendarA.getFormatedDate(cMask);
    const z2 = this.grid._grid_calendarA.getDate();
    this.cell.val = new Date(z2);
    this.setCValue(z1, z2);
    this.cell._clearCell = !z1;
    const t = this.val;
    this.val = this._val;
    return (this.cell.val.valueOf() != t);
  }
  return false;
};

eXcell_dhxCalendar.prototype.setValue = function(val) {
  const iIndex = this.cell._cellIndex,
    cName = this.grid.getColumnId(iIndex),
    cMask = this.grid.calFormats[cName];

  if (val && typeof val == 'object') {
    this.cell.val = val;
    this.cell._clearCell = false;
    this.setCValue(this.grid._grid_calendarA.getFormatedDate(cMask, val).toString(), this.cell.val);
    return;
  }

  if (!val || val.toString()._dhx_trim() == '') {
    val = '&nbsp';
    this.cell._clearCell = true;
    this.cell.val = '';
  } else {
    this.cell._clearCell = false;
    this.cell.val = moment(val.toString())._d;
    // this.grid._grid_calendarA.setFormatedDate(cMask, val );
    // this.grid._grid_calendarA.setDate(new Date(this.cell.val._d));
    val = this.grid._grid_calendarA.getFormatedDate(cMask, this.cell.val);

    // check global format here
    if (window.dhx.dateFormat[window.dhx.dateLang]) {
      if (cMask.indexOf('%H') > -1)
        val = this.grid._grid_calendarA.getFormatedDate(akioma.globalData.dateTimeFormat[window.dhx.dateLang], this.cell.val);
      else
        val = this.grid._grid_calendarA.getFormatedDate(window.dhx.dateFormat[window.dhx.dateLang], this.cell.val);
    }
  }

  if ((this.cell.val == 'NaN') || (this.cell.val == 'Invalid Date')) {
    this.cell._clearCell = true;
    this.cell.val = new Date();
    this.setCValue('&nbsp;', 0);
  } else
    this.setCValue((val || '').toString(), this.cell.val);

};

function eXcell_dhxCalendarA(cell) {
  if (cell) {
    this.cell = cell;
    this.grid = this.cell.parentNode.grid;
    if (!this.grid._grid_calendarA) {

      const cal = this.grid._grid_calendarA = new dhtmlxCalendarObject(false, false, { isYearEditable: true });
      this.grid.callEvent('onDhxCalendarCreated', [cal]);

      const sgrid = this.grid;
      cal.attachEvent('onClick', function() {
        this._last_operation_calendar = true;
        window.setTimeout(() => {
          sgrid.editStop();
        }, 1);
        return true;
      });

      const zFunc = function(e) {
        (e || event).cancelBubble = true;
      };
      dhtmlxEvent(cal.base, 'click', zFunc);
    }
  }
}
eXcell_dhxCalendarA.prototype = new eXcell;

eXcell_dhxCalendarA.prototype.edit = function() {
  const iIndex = this.cell._cellIndex,
    cName = this.grid.getColumnId(iIndex),
    cMask = this.grid.calFormats[cName];

  const arPos = this.grid.getPosition(this.cell);

  this.grid._grid_calendarA._show(false, false);
  this.grid._grid_calendarA.setPosition(arPos[0] * 1 + this.cell.offsetWidth, arPos[1] * 1);
  this.grid.callEvent('onCalendarShow', [ this.grid._grid_calendarA, this.cell.parentNode.idd, this.cell._cellIndex ]);
  this.grid._grid_calendarA._last_operation_calendar = false;
  this.cell._cediton = true;
  this.val = this.cell.val;
  this._val = this.cell.innerHTML;

  this.grid._grid_calendarA.setDateFormat(cMask);
  this.grid._grid_calendarA.setDate(this.val);

  this.cell.atag = ((!this.grid.multiLine) && (_isKHTML || _isMacOS || _isFF)) ? 'INPUT' : 'TEXTAREA';

  this.obj = document.createElement(this.cell.atag);
  this.obj.style.height = `${this.cell.offsetHeight - (_isIE ? 4 : 2)}px`;
  this.obj.className = 'dhx_combo_edit';
  this.obj.wrap = 'soft';
  this.obj.style.textAlign = this.cell.align;
  this.obj.onclick = function(e) {
    (e || event).cancelBubble = true;
  };
  this.obj.onmousedown = function(e) {
    (e || event).cancelBubble = true;
  };
  this.obj.value = this.getValue();
  this.cell.innerHTML = '';
  this.cell.appendChild(this.obj);
  if (_isFF) {
    this.obj.style.overflow = 'visible';
    if ((this.grid.multiLine) && (this.obj.offsetHeight >= 18) && (this.obj.offsetHeight < 40)) {
      this.obj.style.height = '36px';
      this.obj.style.overflow = 'scroll';
    }
  }
  this.obj.onselectstart = function(e) {
    if (!e)
      e = event;
    e.cancelBubble = true;
    return true;
  };
  this.obj.focus();

};

eXcell_dhxCalendarA.prototype.getDate = function() {
  if (this.cell.val)
    return this.cell.val;
  return null;
};

eXcell_dhxCalendarA.prototype.getValue = function() {
  const iIndex = this.cell._cellIndex,
    cName = this.grid.getColumnId(iIndex),
    cMask = this.grid.calFormats[cName];
  if (this.cell._clearCell)
    return '';
  if (this.cell.val)
    return this.grid._grid_calendarA.getFormatedDate(cMask, this.cell.val).toString();
  return this.cell.innerHTML.toString()._dhx_trim();
};

eXcell_dhxCalendarA.prototype.detach = function() {
  const iIndex = this.cell._cellIndex,
    cName = this.grid.getColumnId(iIndex),
    cMask = this.grid.calFormats[cName];

  if (!this.grid._grid_calendarA)
    return;
  this.grid._grid_calendarA.hide();
  if (this.cell._cediton)
    this.cell._cediton = false;
  else
    return;
  if (this.grid._grid_calendarA._last_operation_calendar) {
    this.grid._grid_calendarA._last_operation_calendar = false;
    const z1 = this.grid._grid_calendarA.getFormatedDate(cMask);
    const z2 = this.grid._grid_calendarA.getDate();
    this.cell.val = new Date(z2);
    this.setCValue(z1, z2);
    this.cell._clearCell = !z1;
    const t = this.val;
    this.val = this._val;
    return (this.cell.val.valueOf() != (t | '').valueOf());
  }
  this.setValue(this.obj.value);

  const t = this.val;
  this.val = this._val;
  return (this.cell.val.valueOf() != (t || '').valueOf());
};
eXcell_dhxCalendarA.prototype.setValue = function(val) {
  const iIndex = this.cell._cellIndex,
    cName = this.grid.getColumnId(iIndex),
    cMask = this.grid.calFormats[cName];

  if (typeof val == 'object' && val != null) {
    this.cell.val = val;
    this.cell._clearCell = false;
    this.setCValue(this.grid._grid_calendarA.getFormatedDate(cMask, val).toString(), this.cell.val);
    return;
  }

  if (!val || val.toString()._dhx_trim() == '') {
    val = '&nbsp';
    this.cell._clearCell = true;
    this.cell.val = '';
  } else {
    this.cell._clearCell = false;
    this.cell.val = new Date(this.grid._grid_calendarA.setFormatedDate(cMask, val.toString(), null, true));
    // check global format here
    if (window.dhx.dateFormat[window.dhx.dateLang]) {
      if (cMask.indexOf('%H') > -1)
        val = this.grid._grid_calendarA.getFormatedDate(akioma.globalData.dateTimeFormat[window.dhx.dateLang], this.cell.val);
      else
        val = this.grid._grid_calendarA.getFormatedDate(window.dhx.dateFormat[window.dhx.dateLang], this.cell.val);
    }
  }

  if ((this.cell.val == 'NaN') || (this.cell.val == 'Invalid Date')) {
    this.cell.val = new Date();
    this.cell._clearCell = true;
    this.setCValue('&nbsp;', 0);
  } else
    this.setCValue((val || '').toString(), this.cell.val);
};


// ********* Date/Datetime cell in grid ***************
function eXcell_datetime(cell) {
  if (cell) {
    this.cell = cell;
    this.grid = this.cell.parentNode.grid;

    if (!this.grid._grid_calendarA) {
      const cal = this.grid._grid_calendarA = new dhtmlxCalendarObject(false, false, { isYearEditable: true });
      this.grid.callEvent('onDhxCalendarCreated', [cal]);

      const sgrid = this.grid;
      cal.attachEvent('onClick', function() {
        this._last_operation_calendar = true;
        window.setTimeout(() => {
          sgrid.editStop();
        }, 1);
        return true;
      });

      const zFunc = function(e) {
        (e || event).cancelBubble = true;
      };
      dhtmlxEvent(cal.base, 'click', zFunc);
    }
  }
}
eXcell_datetime.prototype = new eXcell;

eXcell_datetime.prototype.edit = function() {
  const iIndex = this.grid.akElm._initColIds.indexOf(this.grid.getColumnId(this.cell.cellIndex)),
    oCol = this.grid.akElm.childs[iIndex];

  const arPos = this.grid.getPosition(this.cell);

  this.grid._grid_calendarA._show(false, false);
  this.grid._grid_calendarA.setPosition(arPos[0] * 1 + this.cell.offsetWidth, arPos[1] * 1);
  this.grid.callEvent('onCalendarShow', [ this.grid._grid_calendarA, this.cell.parentNode.idd, this.cell._cellIndex ]);
  this.grid._grid_calendarA._last_operation_calendar = false;
  this.cell._cediton = true;
  this.val = this.cell.val;
  this._val = this.cell.innerHTML;

  this.grid._grid_calendarA.setDateFormat(oCol.oDate.cServerFormat);
  this.grid._grid_calendarA.setDate(this.val);

  this.cell.atag = ((!this.grid.multiLine) && (_isKHTML || _isMacOS || _isFF)) ? 'INPUT' : 'TEXTAREA';

  this.obj = document.createElement(this.cell.atag);
  this.obj.style.height = `${this.cell.offsetHeight - (_isIE ? 4 : 2)}px`;
  this.obj.className = 'dhx_combo_edit';
  this.obj.wrap = 'soft';
  this.obj.style.textAlign = this.cell.align;
  this.obj.onclick = function(e) {
    (e || event).cancelBubble = true;
  };
  this.obj.onmousedown = function(e) {
    (e || event).cancelBubble = true;
  };
  this.obj.value = this.cell.innerHTML;
  this.cell.innerHTML = '';
  this.cell.appendChild(this.obj);
  if (_isFF) {
    this.obj.style.overflow = 'visible';
    if ((this.grid.multiLine) && (this.obj.offsetHeight >= 18) && (this.obj.offsetHeight < 40))
      this.obj.style.height = '36px';

  }
  this.obj.onselectstart = function(e) {
    if (!e)
      e = event;
    e.cancelBubble = true;
    return true;
  };

  // Apply inputMask over Dhtmlx format
  if (!this.cell.inputmask) {
    $(this.cell).find('input').inputmask('datetime', {
      inputFormat: oCol.oDate.cInputMaskFormat,
      max: oCol.oDate.cMaxDate,
      min: oCol.oDate.cMinDate
    });
    this.cell._inputmask = $(this.cell).find('input')[0].inputmask;
  }

  this.obj.focus();

};

eXcell_datetime.prototype.getDate = function() {
  if (this.cell.val)
    return this.cell.val;
  return null;
};

eXcell_datetime.prototype.getValue = function() {
  const iIndex = this.grid.akElm._initColIds.indexOf(this.grid.getColumnId(this.cell.cellIndex)),
    oCol = this.grid.akElm.childs[iIndex];

  if (this.cell.val)
    return this.grid._grid_calendarA.getFormatedDate(oCol.oDate.cServerFormat, this.cell.val).toString();
  if (this.cell._orig_value == null)
    return null;
  if (this.cell._clearCell)
    return '';
  return this.cell.innerHTML.toString()._dhx_trim();
};

eXcell_datetime.prototype.detach = function() {
  const iIndex = this.grid.akElm._initColIds.indexOf(this.grid.getColumnId(this.cell.cellIndex)),
    oCol = this.grid.akElm.childs[iIndex];

  if (this.cell._inputmask)
    this.cell._inputmask.remove();


  if (!this.grid._grid_calendarA)
    return;
  this.grid._grid_calendarA.hide();
  if (this.cell._cediton)
    this.cell._cediton = false;
  else
    return;
  if (this.grid._grid_calendarA._last_operation_calendar) {
    this.grid._grid_calendarA._last_operation_calendar = false;
    const z1 = this.grid._grid_calendarA.getFormatedDate(oCol.oDate.cServerFormat);
    const z2 = this.grid._grid_calendarA.getDate();
    this.cell.val = new Date(z2);
    this.setCValue(z1, z2);
    this.cell._clearCell = !z1;
    const t = this.val;
    this.val = this._val;
    return (this.cell.val.valueOf() != (t | '').valueOf());
  }
  if (this.cell._orig_value == null && !this.obj.value)
    this.obj.value = null;
  this.setValue(this.obj.value);

  const t = this.val;
  this.val = this._val;
  return (this.cell.val.valueOf() != (t || '').valueOf());
};
eXcell_datetime.prototype.setValue = function(val) {
  const iIndex = this.grid.akElm._initColIds.indexOf(this.grid.getColumnId(this.cell.cellIndex)),
    oCol = this.grid.akElm.childs[iIndex];

  if (typeof val == 'object' && val != null) {
    this.cell.val = val;
    this.cell._clearCell = false;
    this.cell._orig_value = val;
    this.setCValue(this.grid._grid_calendarA.getFormatedDate(oCol.oDate.cServerFormat, val).toString(), this.cell.val);
    return;
  }

  if (!val || val.toString()._dhx_trim() == '') {
    this.cell._clearCell = true;
    this.cell._orig_value = val;
    this.cell.val = '';
    val = '&nbsp';
  } else {
    this.cell._clearCell = false;
    if (new Date(val) == 'Invalid Date')
      this.cell.val = akioma.date.fillDate(oCol.oDate.cInputMaskFormat, val);
    else {
      this.cell.val = new Date(this.grid._grid_calendarA.setFormatedDate(oCol.oDate.cServerFormat, val.toString(), null, true));
      if (this.cell.val == 'Invalid Date')
        this.cell.val = new Date(this.grid._grid_calendarA.setFormatedDate(oCol.oDate.cFormat, val.toString(), null, true));
    }

    val = this.grid._grid_calendarA.getFormatedDate(oCol.oDate.cFormat, this.cell.val);
    this.cell._orig_value = val;
  }

  if (this.cell.inputmask) {
    let stringDate = (val || '').toString();
    if (stringDate !== '') {
      stringDate = moment(stringDate).format(oCol.oDate.cInputMaskFormat.toUpperCase());
      this.cell.inputmask.setValue(stringDate);
    }
  }

  if ((this.cell.val == 'NaN') || (this.cell.val == 'Invalid Date')) {
    this.cell.val = new Date();
    this.cell._clearCell = true;
    this.setCValue('&nbsp;', 0);
  } else
    this.setCValue((val || '').toString(), this.cell.val);

};


