/**
 * SwatbpmnDiagram Control
 * @class ak_bpmnDiagram
 * @param {Object} options Repository attributes for SwatBpmnDiagram.
 */
$.ak_bpmnDiagram = class {
  constructor(options) {
    const defaults = {};
    this.opt		= Object.assign({}, defaults, options.att);
    this.parent	= options.parent;
    this.registerDynObject = true;
    this.registerVuexModule = true;
    this.hasChangesControl = true;
    this.domParser = new DOMParser();
  }

  finishConstruct() {
    const oSelf = this;
    const oParent	= oSelf.parent;

    if (oParent) {
      let id = 'BpmnDiagramContent';
      this.parent.dhx.attachHTMLString(`<div id="BpmnDiagramContainer"><div id="${id}"></div></div>`);

      id = `#${id}`;
      this.bpmnModeler = new BpmnJS({
        container: id,
        keyboard: { bindTo: window }
      });

      const eventBus = this.bpmnModeler.get('eventBus');
      eventBus.on('element.changed', () => {
        const cFieldName = oSelf.opt.ForeignFields.toLowerCase();
        const cCurrentDiagram = oSelf.dataSource.getSelectedRecord()[cFieldName];
        const promise = oSelf.getBpmnDiagram();

        promise.then(result => {
          if (result.xml !== cCurrentDiagram) {
            if (!oSelf.oVuexState.attributes.hasChanges)
              oSelf._dispatch('incrementHasChanges', 1);
            oSelf._dispatch('setHasChanges', true);
          }
        });
      });
    }

    if (this.dataSource) {
      this.renderBpmnDiagram();

      const oSource = this.dataSource.dhx;
      oSource.attachEvent('onAfterCursorChange', () => {
        oSelf.renderBpmnDiagram();
      });
    }
  }

  renderBpmnDiagram() {
    const oSelf = this;
    const dataLinkSrc = this.dynObject.getLink('DISPLAY:SRC');
    const oSelectedRecord = dataLinkSrc.controller.getSelectedRecord();
    const cDefaultDiagramXml = '<?xml version="1.0" encoding="UTF-8"?><bpmn:definitions xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" targetNamespace="http://bpmn.io/schema/bpmn" id="Definitions_1"><bpmn:process id="Process_1" isExecutable="false"><bpmn:startEvent id="StartEvent_1"/></bpmn:process><bpmndi:BPMNDiagram id="BPMNDiagram_1"><bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1"><bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1"><dc:Bounds height="36.0" width="36.0" x="173.0" y="102.0"/></bpmndi:BPMNShape></bpmndi:BPMNPlane></bpmndi:BPMNDiagram></bpmn:definitions>';

    if (oSelectedRecord) {
      const cFieldName = oSelf.opt.ForeignFields.toLowerCase();
      const oXml = oSelf.domParser.parseFromString(oSelectedRecord[cFieldName], 'application/xml');
      if (oXml.getElementsByTagName('parsererror')[0])
        oSelf.bpmnModeler.importXML(cDefaultDiagramXml);
      else
        oSelf.bpmnModeler.importXML(oSelectedRecord[cFieldName]);
    }
  }

  saveBpmnDiagram() {

    const oSelf = this;
    const cFieldName = oSelf.opt.ForeignFields;
    if (!cFieldName) {
      akioma.notification({ type: 'error', text: 'Invalid field name found. Please set the ForeignField attribute first.' });
      return;
    }

    const dataLinkSrc = this.dynObject.getLink('DISPLAY:SRC');
    oSelf.bpmnModeler.saveXML({ format: true }).then(result => {
      dataLinkSrc.controller.setFieldValue({
        name: cFieldName,
        value: result.xml
      });

      dataLinkSrc.controller.updateRecord({});
      oSelf._dispatch('decrementHasChanges', 1);
    }).catch(e => {
      akioma.notification({ type: 'error', text: e });
    });
  }

  getBpmnDiagram() {
    const oSelf = this;
    return oSelf.bpmnModeler.saveXML({ format: true });
  }

  destroy() {
    this.bpmnModeler.destroy();
  }
};
