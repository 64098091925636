
dhtmlXForm.prototype.items.lookup2 = {

  render: function(item, data) {
    item._type = 'combo';
    item._enabled = true;
    item._value = null;
    item._newValue = null;
    item.data = data;

    const skin = item.getForm().skin;
    if (typeof (data.inputWidth) != 'undefined' && skin == 'material' && String(data.inputWidth).match(/^\d*$/) != null)
      data.inputWidth = parseInt(data.inputWidth) + 2;


    this.doAddLabel(item, data);
    this.doAddInput(item, data, 'SELECT', null, true, true, 'dhxform_select');
    this.doAttachEvents(item);
    this.doLoadOpts(item, data);

    // allow selection to prevent broking combo logic
    item.onselectstart = function() {
      return true;
    };

    item.childNodes[item._ll ? 1 : 0].childNodes[0].setAttribute('mode', data.comboType || '');
    if (data.comboImagePath) item.childNodes[item._ll ? 1 : 0].childNodes[0].setAttribute('imagePath', data.comboImagePath);
    if (data.comboDefaultImage) item.childNodes[item._ll ? 1 : 0].childNodes[0].setAttribute('defaultImage', data.comboDefaultImage);
    if (data.comboDefaultImageDis) item.childNodes[item._ll ? 1 : 0].childNodes[0].setAttribute('defaultImageDis', data.comboDefaultImageDis);

    item._combo = new dhtmlXComboFromSelect(item.childNodes[item._ll ? 1 : 0].childNodes[0]);
    item._combo.setSkin(skin);
    item._combo._currentComboValue = item._combo.getSelectedValue();
    item._combo.getInput().id = data.uid;

    if (skin == 'material') item._combo.list.className += ` dhxform_obj_${skin}`;

    const k = this;
    item._combo.attachEvent('onChange', function() {
      k.doOnChange(this);
    });

    if (data.connector) this.doLoadOptsConnector(item, data.connector);

    if (data.filtering)
      item._combo.enableFilteringMode(true);
    else if (data.serverFiltering)
      item._combo.enableFilteringMode(true, data.serverFiltering, data.filterCache, data.filterSubLoad);


    if (data.readonly == true) this.setReadonly(item, true);
    if (data.hidden == true) this.hide(item);

    if (data.style) item._combo.DOMelem_input.style.cssText += data.style;

    item._combo.attachEvent('onFocus', function() {
      let item = this.cont.parentNode.parentNode;
      let f = item.getForm();
      if ((f.skin == 'dhx_terrace' || f.skin == 'material') && this.cont.className.search(/combo_in_focus/) < 0) this.cont.className += ' combo_in_focus';
      f.callEvent('onFocus', [item._idd]);
      f = item = null;
    });

    item._combo.attachEvent('onBlur', function() {
      let item = this.cont.parentNode.parentNode;
      let f = item.getForm();
      if ((f.skin == 'dhx_terrace' || f.skin == 'material') && this.cont.className.search(/combo_in_focus/) >= 0) this.cont.className = this.cont.className.replace(/\s{0,}combo_in_focus/gi, '');
      f.callEvent('onBlur', [item._idd]);
      f = item = null;
    });


    return this;
  },

  destruct: function(item) {

    // unload combo
    item.childNodes[item._ll ? 1 : 0].childNodes[0].onchange = null;

    item._combo._currentComboValue = null;
    item._combo.unload();
    item._combo = null;

    // unload item
    item._apiChange = null;
    this.d2(item);
    item = null;

  },

  doAttachEvents: function(item) {

    const that = this;

    item.childNodes[item._ll ? 1 : 0].childNodes[0].onchange = function() {
      that.doOnChange(this);
      that.doValidate(this.DOMParent.parentNode.parentNode);
    };
  },

  doValidate: function(item) {
    if (item.getForm().hot_validate) this._validate(item);
  },

  doOnChange: function(combo) {
    const item = combo.base.parentNode.parentNode.parentNode;
    if (item._apiChange) return;
    combo._newComboValue = combo.getSelectedValue();
    if (combo._newComboValue != combo._currentComboValue) {
      if (item.checkEvent('onBeforeChange')) {
        if (item.callEvent('onBeforeChange', [ item._idd, combo._currentComboValue, combo._newComboValue ]) !== true) {
          // restore last value
          // not the best solution, should be improved
          window.setTimeout(() => {
            combo.setComboValue(combo._currentComboValue);
          }, 1);
          return false;
        }
      }
      combo._currentComboValue = combo._newComboValue;
      item.callEvent('onChange', [ item._idd, combo._currentComboValue ]);
    }
    item._autoCheck();
  },

  doLoadOptsConnector: function(item, url) {
    let that = this;
    let i = item;
    item._connector_working = true;
    item._apiChange = true;
    item._combo.load(url, () => {
      // try to set value if it was called while options loading was in progress
      i.callEvent('onOptionsLoaded', [i._idd]);
      i._connector_working = false;
      if (i._connector_value != null) {
        that.setValue(i, i._connector_value);
        i._connector_value = null;
      }
      i._apiChange = false;
      that = i = null;
    });
  },

  enable: function(item) {
    if (String(item.className).search('disabled') >= 0) item.className = String(item.className).replace(/disabled/gi, '');
    item._enabled = true;
    item._combo.enable();
  },

  disable: function(item) {
    if (String(item.className).search('disabled') < 0) item.className += ' disabled';
    item._enabled = false;
    item._combo.disable();
  },

  getCombo: function(item) {
    return item._combo;
  },

  setValue: function(item, val) {
    if (item._connector_working) { // attemp to set value while optins not yet loaded (connector used)
      item._connector_value = val;
      return;
    }
    item._apiChange = true;
    const oSelf = $.getObjectByName({ id: item.data.userdata.id });
    if (oSelf.lastValText != undefined) {
      item._combo.setComboValue(oSelf.lastValText, true);
      delete oSelf.lastValText;
    } else
      item._combo.setComboValue(val);

    item._combo._currentComboValue = item._combo.getActualValue();
    item._apiChange = false;

    const $itemcont = $(item._combo.DOMParent).parent().parent();
    if (val.length > 0)
      $itemcont.addClass('active');
    else
      $itemcont.removeClass('active');

  },

  getValue: function(item) {

    const oSelf = $.getObjectByName({ id: item.data.userdata.id });

    if (oSelf.opt.valueField == 'key') {
      if (oSelf.opt.showDesc == '_self') {
        oSelf.val.valueDesc = akioma.getForm(oSelf).dataSource.dynObject.getValue(oSelf.opt.name);
        return oSelf.val.valueDesc;
      } else if (oSelf.opt.showKey == '_self') {
        oSelf.val.valueKey = akioma.getForm(oSelf).dataSource.dynObject.getValue(oSelf.opt.name);
        return oSelf.val.valueKey;
      }
    } else {
      if (oSelf.opt.showKey == '_self') {
        oSelf.lastValText = akioma.getForm(oSelf).dataSource.dynObject.getValue(oSelf.opt.name);
        return oSelf.val.valueKey;
      }
      if (oSelf.val.value == '')
        oSelf.val.value = akioma.getForm(oSelf).dataSource.dynObject.getValue(oSelf.opt.showDesc);

      return oSelf.val.value;
    }
    return item._combo.getActualValue();
  },

  setWidth: function(item, width) {
    item.childNodes[item._ll ? 1 : 0].childNodes[0].style.width = `${width}px`;
  },

  setReadonly: function(item, state) {
    if (!item._combo) return;
    item._combo_ro = state;
    item._combo.readonly(item._combo_ro);
  },

  isReadonly: function(item) {
    return item._combo_ro || false;
  },

  setFocus: function(item) {
    if (item._enabled) item._combo.setFocus();
  },

  _setCss: function(item, skin, fontSize) {
    // update font-size for input and list-options div
    item._combo.setFontSize(fontSize, fontSize);
  }

};

(function() {
  for (const a in { doAddLabel: 1, doAddInput: 1, doLoadOpts: 1, doUnloadNestedLists: 1, setText: 1, getText: 1, isEnabled: 1, _checkNoteWidth: 1 })
    dhtmlXForm.prototype.items.lookup2[a] = dhtmlXForm.prototype.items.select[a];
})();

dhtmlXForm.prototype.items.lookup2.d2 = dhtmlXForm.prototype.items.select.destruct;

dhtmlXForm.prototype.getLookup2 = function(name) {
  return this.doWithItem(name, 'getCombo');
};
dhtmlXForm.prototype.getFormData_lookup2 = function(name) {
  return this.doWithItem(name, 'getValue');
};
dhtmlXForm.prototype.setFormData_lookup2 = function(name, value) {
  return this.doWithItem(name, 'setValue', value);
};
// proxified pattern
const dhtmlXCombo_checkListHeightproxied = dhtmlXCombo.prototype._checkListHeight;
dhtmlXCombo.prototype._checkListHeight = function() {

  let iItems = 10;
  const $domelm = $(this.DOMelem);
  const bGlobalAUTO = $domelm.hasClass('globalautocomplete');
  if ($domelm.hasClass('lookup2') || bGlobalAUTO) {
    if (bGlobalAUTO && akioma.globalSearch && akioma.globalSearch.numRows)
      iItems = parseInt(akioma.globalSearch.numRows);
    try {
      if (!this._isListVisible()) return;

      if (this.conf.item_h == null) {
        let item = this.list.firstChild;
        while (item != null) {
          if (item.style.display == '') {
            this.conf.item_h = item.offsetHeight + (this.hdr != null ? -1 : 0); // multicol rows have -1px margin
            item = null;
          } else
            item = item.nextSibling;

        }
        item = null;
      }

      const s = window.dhx4.screenDim();
      const by = window.dhx4.absTop(this.base);
      const bh = this.base.offsetHeight;
      const hh = (this.hdr != null && this.conf.template.header == true ? this.hdr.offsetHeight : 0); // header_height

      const onTop = Math.max(0, Math.floor((by + hh - s.top) / this.conf.item_h));
      let onBottom = Math.max(0, Math.floor((s.bottom - (by + bh + hh)) / this.conf.item_h));

      const itemsCount = this._getListVisibleCount();

      // top/bottom detect
      if (onBottom < Math.min(this.conf.opts_count_min, itemsCount) && onTop > onBottom) onBottom = null;

      const itemsToShow = (itemsCount < iItems ? itemsCount : iItems);
      const h = `${itemsToShow * this.conf.item_h}px`;

      const ofs = this.conf.sp[this.conf.skin][this.hdr != null && this.conf.template.header == true ? 'hdr_ofs' : 'list_ofs'];

      this.list.style.height = h;
      this.list.style.top = `${onBottom == null ? by - this.list.offsetHeight + ofs : by + bh + hh - ofs}px`;
      if (this.hdr != null && this.conf.template.header == true) this.hdr.style.top = `${onBottom == null ? by - hh - this.list.offsetHeight + ofs : by + bh - ofs}px`;
    } catch (e) {
      akioma.log.warn(e);
    }
  } else
    return dhtmlXCombo_checkListHeightproxied.apply(this, arguments); // calls dhtmlx's method if not lookup type

};

dhtmlXCombo.prototype.setComboValue = function(value, bSkip) {
  // sets text in combobox, only text
  try {
    if (!$(this.DOMelem).hasClass('lookup2') || bSkip) {
      const t = this.getOption(value);
      if (t != null)
        this.selectOption(t.index);
      else {
        this.conf.last_value = value;
        this.base.childNodes[1].value = this.conf.last_value;
        this.base.childNodes[2].value = 'true';
      }
    } else {
      // if diff then _self load
      if (this.akElm.opt.showDesc != '_self' && this.akElm.opt.showKey != '_self')
        value = akioma.getForm(this.akElm).dataSource.dynObject.getValue(this.akElm.opt.showDesc);
      if (this.akElm.opt.showDesc == '_self' || this.akElm.opt.showKey == '_self')
        value = akioma.getForm(this.akElm).dataSource.dynObject.getValue(this.akElm.opt.name);

      // this.akElm.parent.
      if (value == undefined)
        value = '';
      this.setComboText(value);
      this.akElm.lastValText = value;
    }
  } catch (e) {
    akioma.log.error('Error in dhtmlXCombo.prototype.setComboValue', e);
  }

};


(function($) {

  // ********************* lookup no. 2 combo autocomplete ********************
  $.extend({
    /**
     * SwatLookup Control
     * @class ak_lookup2
     * @param {Object} options Repository attributes for SwatLookup.
     * @param {boolean} options.CanSort Set to FALSE if this field should not be used to sort the data object query.
     * @param {number} options.WIDTH-CHARS Width in characters. This may currently be used when rendering some objects. There is no get function, use getWidth to retrieve the realized value from an object.
     * @param {string} options.align alignment of column. can be left, right or centered
     * @param {string} options.HELP WidgetAttributes Help
     * @param {number} options.ROW Row position.
     * @param {string} options.VisualizationType WidgetAttributes VisualizationType
     * @param {string} options.LABEL WidgetAttributes Label
     * @param {string} options.EventEntryType Language of "entry" trigger
     * @param {number} options.HEIGHT-CHARS Height in characters. This may currently be used when rendering some objects. There is no get function, use getHeight to retrieve the realized value from an object.
     * @param {boolean} options.VISIBLE WidgetAttributes Visible
     * @param {boolean} options.CHECKED
     * @param {string} options.TemplateFile The relative path and filename of the static object used as the template at design time
     * @param {string} options.InitialValue WidgetAttributes InitialValue
     * @param {string} options.EventEntry Event which should run on entry of a field
     * @param {string} options.LIST-ITEMS
     * @param {boolean} options.CanFilter Set to FALSE if this field should not be used to filter the data object query.
     * @param {string} options.ViewAs The 'ViewAs' definition  of the selection.&#10;- combo-box,radio-set,selection-list OR browse &#10;- Uses colon as separator to define SUB-TYPE for combo-box or &#10;horizontal/vertical radio-set,
     * @param {string} options.FolderWindowToLaunch If Dynamics is running, this property specifies a window to launch upon the occurence of toolbar events "View", "Copy", "Modify" or "Add".
     * @param {string} options.EventLeaveType Language, the event is written in.
     * @param {string} options.TableName WidgetAttributes TableName
     * @param {string} options.FORMAT WidgetAttributes Format
     * @param {string} options.EventAkValidate Client side validation code (JavaScript)
     * @param {string} options.DATA-TYPE WidgetAttributes Data-Type
     * @param {string} options.Validation Validation-Rules, e.g. ValidInteger,ValidEMail...
     * @param {string} options.FilterOperator
     * @param {boolean} options.LABELS If false then NO-LABEL is used.  This attribute applies to most field level widgets and frames
     * @param {string} options.FieldName The name of the associated SDO field this SmartDataField maps to. This is usually 'set' from the containing SmartDataViewer.
     * @param {boolean} options.CreateField
     * @param {string} options.EventLeave Event when leaving a field
     * @param {string} options.SUBTYPE
     * @param {boolean} options.MULTIPLE
     * @param {string} options.Width A widget's width. The unit of measurement is determined by another&#10;parameter.
     * @param {number} options.COLUMN Column position. This may currently be used when rendering some objects. There is no getColumns function, use getCol to retrieve the realized value from an object.
     * @param {string} options.ServerProps Backend Properties
     * @param {string} options.KeyField Name of the Dynamic Lookup/Dynamic Combo key field to assign value from (Table.Field)
     * @param {string} options.ViewerLinkedFields Dynamic Lookup Linked fields to update value of on viewer, comma list of table.fieldname.
     * @param {string} options.LookupControls A comma seperated list of Control names. The value of the corresponding Columns gets filled into those Controls.
     * @param {string} options.templateOptions options which are passed thtough to the template
     * @param {string} options.FieldLabel Label for the Dynamic Lookup/Dynamic Combo field on the viewer.
     * @param {string} options.LookupKeyValueBinding The data-binding field (in the viewer containing a lookup) that should receive the lookup's LookupKeyValueColumn field
     * @param {string} options.QueryTables Comma list of query tables for Dynamic Lookups/Dynamic Combos (Buffers)
     * @param {string} options.initialFetch if not empty, then the lookup will trigger the fetching of data from backend when it gets focus, before a key has been typed.
     * @param {string} options.EventBeforeFetch code to be executed before fetching data from the backend
     * @param {string} options.ObjectType
     * @param {string} options.LookupKeyField The name of the field which is returned by the SmartLookup.
     * @param {string} options.minimumInputLength Minimum number of characters required to start a search.
     * @param {string} options.ViewerLinkedWidgets Dynamic Lookup linked field corresponding widget names to update value of in viewer, comma list, ? if not widget
     * @param {number} options.maximumSelectionLength The maximum number of items that may be selected in a multi-select control. If the value 0, the number of selected items will not be limited.
     * @param {boolean} options.ENABLED WidgetAttributes Enabled
     * @param {string} options.LookupKeyValueColumn The name of the Data Source column which is used for retrieving the LookupKeyValue property
     * @param {string} options.Template
     * @param {string} options.DisplayedFields A comma-separated list of the columns displayed by the visualization object.
     * @param {string} options.DisplayDataType Datatype of Dynamic Lookup display field.
     * @param {boolean} options.multiMode allow multiple items opened at the same time
     * @param {string} options.EntityTable The primary temp-table of the Business Entity used by the component
     * @param {string} options.LIST-ITEM-PAIRS
     * @param {string} options.resourceName
     * @param {string} options.MappedFields A comma separated paired list of data source field names and widget names on a viewer to be used to map fields from a data source with widgets on a viewer when linked fields are used in a Dynamic Lookup.
     * @param {string} options.EntityName The Name of the Business Entity used by the component
     * @param {boolean} options.allowTagging allow dynamically create new options from text input by the user in the search box
     * @param {string} options.LookupFields A comma seperated list of Column names. The value of those fields gets filled into the corresponding Controls of the Property LookupControls.
     * @param {string} options.AxLookupDialog Axilon: Dialog to choose record within the lookup
     * @param {boolean} options.Mandatory WidgetAttributes Mandatory
     * @param {string} options.EventClick Client side validation code (JavaScript)
     * @param {string} options.filter
     */

    ak_lookup2: function(options) {
      const defaults = {
        disabled: false,
        valueField: 'hdl'
      };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;

      this.registerDynObject = true;

      // get parent
      const oParent = this.parent;
      if (oParent) {

        this.opt.label = akioma.tran(`${akioma.getForm(this).opt.name}.${this.opt.name}`, { defaultValue: this.opt.label });

        oParent.prop.fields.push({
          type: 'lookup2',
          inputTop: parseInt(this.opt.top),
          inputLeft: parseInt(this.opt.left),
          inputWidth: parseInt(this.opt.width),
          label: this.opt.label,
          labelTop: parseInt(this.opt.top),
          labelLeft: oParent.labelLeft(this),
          name: this.opt.name,
          disabled: this.opt.disabled,
          readonly: this.opt.readonly,
          className: (this.opt.autoComplete) ? '' : 'w4-formField w4-inputField',
          position: 'label-top',
          userdata: { id: this.opt.id }
        });


        // append to elements in form
        if (this.parent.view == 'form')
          this.parent.elements.push(this);
        else if (this.parent.view == 'fieldset')
          this.parent.parent.elements.push(this);

        this.serverProp = { srvrProp: [] };

        $.extend(this, {
          val: {
            value: '',
            valueKey: '',
            valueDesc: ''
          }
        });


      }
    }
  });

  // methods for form
  $.ak_lookup2.prototype = {

    // finish construct
    finishConstruct: function() {
      // get field
      this.form = akioma.getForm(this).dhx;
      this.dhx = this.form.getLookup2(this.opt.name);

      const oSelf = this;
      const oCombo = this.dhx;
      oCombo.attachEvent('onBlur', () => {
        oSelf._checkForActiveClass();
      });

      // oCombo.attachEvent( "onChange", function() { oSelf.changed (); } );

      if (this.opt.serverProp) {
        const aProp = this.opt.serverProp.split('|');
        for (const i in aProp) {
          const aVal = aProp[i].split('#');
          this.setSrvProp(aVal[0], aVal[1]);
        }
      }

      let cInputTemplate = '#desc#';
      if (this.opt.showKey == '_self')
        cInputTemplate = '#key#';
      else if (this.opt.showDesc == '_self')
        cInputTemplate = '#desc#';

      oCombo.setTemplate({
        input: cInputTemplate,
        columns: [
          { header: 'key', width: 135, option: '#key#' },
          { header: 'desc', width: 440, option: '#desc#' }
        ]
      });


      // lookup 2 update lookup val attribute to match mappings in showKey,showDesc attributes
      try {
        const oForm = akioma.getForm(this);
        if (oForm && oForm.dataSource) {
          oForm.dataSource.dynObject.controller.dhx.attachEvent('onXLE', () => {
            const oItem = oForm.dataSource.dynObject.controller.dhx.item(oForm.dataSource.dynObject.controller.dhx.getCursor());
            try {
              if (oSelf.opt.showKey == '_self')
                oSelf.val.valueKey = oItem[oSelf.opt.name];
              else
                oSelf.val.valueKey = oItem[oSelf.opt.showKey];
              if (oSelf.opt.showDesc == '_self')
                oSelf.val.valueDesc = oItem[oSelf.opt.name];
              else
                oSelf.val.valueDesc = oItem[oSelf.opt.showDesc];
              if (oSelf.opt.showKey != '_self' && oSelf.opt.showKey != '_self')
                oSelf.val.value = oItem[oSelf.opt.name];

              delete oSelf.lastValText;
            } catch (e) {
              console.warn('Error in lookup2 binding:', oItem, e);
            }

          });

          oForm.dataSource.dynObject.controller.dhx.attachEvent('onBeforeCursorChange', () => {
            delete oSelf.lastValText;
          });
        }
      } catch (e) {
        akioma.log.error('Could not update attributes in lookup2 ', e);
      }

      $(oCombo.DOMelem).addClass('lookup2');
      $(oCombo.DOMelem_button).on('click', () => {
        if (!oSelf.opt.disabled)
          oSelf.popupDialog();
        return false;
      });


      oCombo.setOptionWidth(610);
      oCombo.disableAutocomplete();


      if (this.opt.serverProp) {

        oCombo.attachEvent('onKeyPressed', () => {
          const cProps = oSelf._calculateServerProp();
          oCombo.enableFilteringMode(true, `/akioma/comboFilter.xml?Type=getCombo&name=itemhdl&comboname=${oSelf.opt.name}&ServerProp=${oSelf.serverProp.srvrProp}${cProps}`, false, false);
        });

        const cProps = oSelf._calculateServerProp();
        oCombo.enableFilteringMode(true, `/akioma/comboFilter.xml?Type=getCombo&name=itemhdl&comboname=${oSelf.opt.name}&ServerProp=${this.serverProp.srvrProp}${cProps}`, false, false);
      } else
        oCombo.enableFilteringMode(true, `/akioma/comboFilter.xml?Type=getCombo&name=itemhdl&comboname=${oSelf.opt.name}`, false, false);

      oCombo.load({});

      // create onmisearch classes for custom row styles
      const $comboHead = $(oCombo.hdr);
      const $comboList = $(oCombo.list);

      $comboHead.hide();
      $comboList.hide();

      oCombo.attachEvent('onSelectionChange', () => {
        oSelf.selectionChanged();
        return false;
      });

      // var bFirst = true;
      oCombo.attachEvent('onFocus', () => {
        const oComboInp = oCombo.getInput();
        oComboInp.focus();
      });


      $(oCombo.DOMelem_input).focusout(() => {
        setTimeout(() => {
          $comboList.hide();
          $comboHead.hide();
          akioma.triggerTreeEnterKey = true;
        }, 500);
      });

      $(oCombo.DOMelem).addClass('lookup2');

      oCombo.akElm = this;
    },
    endConstruct: function() {
      this._checkForActiveClass();
    },
    // en- or disable field ********************
    enabled: function() {

      if (typeof this.opt.disabled != 'boolean')
        return;


    },
    _calculateServerProp: function() {
      let cProps = '';
      let iterator = 0;

      for (const i in this.serverProp) {
        if (i != 'srvrProp') {
          let cExtra = '';
          if (iterator > 0)
            cExtra = '&';
          const cProp = `${cExtra + i}=${this.serverProp[i]}`;

          cProps += cProp;
          iterator++;
        }

      }

      cProps = `&${cProps}`;

      return cProps;
    },
    getSrvProp: function(cName) {
      if (this.serverProp[cName])
        return this.serverProp[cName];
      else
        return '';
    },

    setSrvProp: function(cName, cValue) {

      if ($.inArray(cName, this.serverProp.srvrProp) == -1)
        this.serverProp.srvrProp.push(cName);

      if (cValue.substr(0, 1) == '$') {
        let oSelf, self, cCode;
        try {
          // get variable for dynObject
          oSelf = this;
          // required for the eval context
          // eslint-disable-next-line no-unused-vars
          self = oSelf.dynObject;
          cCode = cValue.substr(1);
          this.serverProp[cName] = eval(cCode);
        } catch (e) {
          !_isIE && console.error([ 'Error executing akioma code', oSelf, cCode, e ]);
          akioma.message({ type: 'alert-error', title: 'Error executing akioma code', text: `${cCode}<br />${e.message}` });
        }
      } else
        this.serverProp[cName] = cValue;
    },

    delSrvProp: function(cName) {
      if (this.serverProp[cName]) {
        delete this.serverProp[cName];
        $.removeEntry(this.serverProp.srvrProp, cName);
      }
    },

    // get value *****************
    getValue: function() {
      if (this.opt.valueField == 'key')
        return this.val.valueKey;
      else
        return this.val.value;
    },

    // set value ********************
    setValue: function(cValue) {
      this.val.value = cValue;
      this.lastValText = cValue;
    },

    // get value key *****************
    getValueKey: function() {

      return this.val.valueKey;
    },
    setValueExt: function(cHdl, cKey, cDesc) {
      // set value
      this.setValue(cHdl);
      this.setValueKey(cKey);
      this.setValueDesc(cDesc);

    },
    _checkForActiveClass: function() {
      const val = this.dhx.getComboText();
      const $itemcont = $(this.dhx.DOMParent).parent().parent();

      if (val.length > 0)
        $itemcont.addClass('active');
      else
        $itemcont.removeClass('active');
    },

    // set value key ********************
    setValueKey: function(cValue) {
      this.val.valueKey = cValue;

      if (this.opt.showKey == '_self') {
        this.dhx.setComboText(this.val.valueKey);
        this.lastValText = this.val.valueKey;
      } else {
        try {
          this.form.getLookup2(this.opt.showKey).setComboText(this.val.valueKey);
          this.form.getLookup2(this.opt.showKey).akElm._checkForActiveClass();

          if (this.form.getLookup2(this.opt.showKey).akElm.opt.valueField == 'key')
            this.form.getLookup2(this.opt.showKey).setComboValue(this.val.valueKey, true);
          else
            this.form.getLookup2(this.opt.showKey).setComboValue(this.val.value, true);
          this.form.getLookup2(this.opt.showKey).akElm.val.value = this.val.value;
          this.form.getLookup2(this.opt.showKey).akElm.val.valueKey = this.val.valueKey;
          this.form.getLookup2(this.opt.showKey).akElm.val.valueDesc = this.val.valueDesc;
          // update datasource fields
          if (this.opt.showKey != '_self') {
            akioma.getForm(this).dataSource.setFieldValue({
              name: this.opt.showKey,
              value: this.val.valueKey
            });
          }
          if (this.opt.showDesc != '_self') {
            akioma.getForm(this).dataSource.setFieldValue({
              name: this.opt.showDesc,
              value: this.val.valueDesc
            });
          }

          // last text value
          this.form.getLookup2(this.opt.showKey).akElm.lastValText = this.form.getLookup2(this.opt.showKey).akElm.val.valueKey;
        } catch (e) {
          akioma.log.error(`Could not bind to ${this.opt.showKey} lookup2`, e);
        }
      }

      this._checkForActiveClass();
    },

    // get value desc ********************
    getValueDesc: function() {


      return this.val.valueDesc;
    },


    // set value desc ********************
    setValueDesc: function(cValue) {
      this.val.valueDesc = cValue;


      if (this.opt.showDesc == '_self') {
        this.dhx.setComboText(cValue);
        this.lastValText = cValue;
      } else {
        try {
          if (this.form.getLookup2(this.opt.showDesc) != null) {
            this.form.getLookup2(this.opt.showDesc).setComboText(this.val.valueDesc);
            this.form.getLookup2(this.opt.showDesc).akElm._checkForActiveClass();
          } else if (this.opt.showDesc != '_self' && this.opt.showKey != '_self') {
            this.form.getLookup2(this.opt.name).setComboText(this.val.valueDesc);
            this.form.getLookup2(this.opt.name).akElm._checkForActiveClass();
          }


          if (this.form.getLookup2(this.opt.showDesc).akElm.opt.valueField == 'key')
            this.form.getLookup2(this.opt.showDesc).setComboValue(this.val.valueKey, true);
          else
            this.form.getLookup2(this.opt.showDesc).setComboValue(this.val.value, true);

          this.form.getLookup2(this.opt.showDesc).akElm.val.valueDesc = (this.val.valueDesc);
          this.form.getLookup2(this.opt.showDesc).akElm.val.valueKey = (this.val.valueKey);
          this.form.getLookup2(this.opt.showDesc).akElm.val.value = (this.val.value);

          // update datasource valuedesc
          const cField = this.form.getLookup2(this.opt.showDesc).akElm.opt.showDesc;
          akioma.getForm(this).dataSource.setFieldValue({
            name: cField,
            value: this.val.valueDesc
          });

          this.form.getLookup2(this.opt.showDesc).akElm.lastValText = this.form.getLookup2(this.opt.showDesc).akElm.val.valueDesc;

        } catch (e) {
          akioma.log.error(`Could not bind to ${this.opt.showDesc} lookup2`, e);
        }

      }
      this._checkForActiveClass();
    },

    // get value from external ********
    getExtValue: function(oSource) {

      // get value
      const cValueKey = oSource.getFieldValue(this.opt.extKey);
      const cValueHdl = oSource.getFieldValue(this.opt.extHdl);
      const cValueDesc = oSource.getFieldValue(this.opt.extDesc);

      this.val.value = cValueHdl;
      this.val.valueKey = cValueKey;
      this.val.valueDesc = cValueDesc;


      // set value
      this.setValue(cValueHdl);
      this.setValueKey(cValueKey);
      this.setValueDesc(cValueDesc);

      // check if we have to call the leave event
      if (this.opt.leaveEvent)
        app.controller.callAkiomaCode(this, this.opt.leaveEvent);

      // set focus to lookup field
      this.form.setItemFocus(this.opt.name);
    },

    // popup dialog **********************
    popupDialog: function() {
      const oForm = this.getAncestor('form');

      if (this.security.readOnly)
        return;

      // check for disabled
      if (!this.opt.disabled) {

        // call dialog
        app.controller.launchContainer({
          proc: 'popup.r',
          para: `RunFile=${this.opt.lookupDialog}&FieldName=${this.opt.name}&TargetId=${oForm.opt.id}`,
          self: this,
          data: true
        });
      }
    },

    selectionChanged: function() {
      const oSelected = this.dhx.getSelectedText();
      if (oSelected != '') {
        this.val.value = oSelected.hdl;
        this.val.valueKey = oSelected.key;
        this.val.valueDesc = oSelected.desc;
        this.setValue(oSelected.hdl);
        this.setValueKey(oSelected.key);
        this.setValueDesc(oSelected.desc);

        // tell treegrid that it can now execute enter key event after 500ms
        const oTreeGrid = akioma.getForm(this).dataSource.dataSource;
        oTreeGrid.triggerTreeEnterKey = false;
        if (this.timeout != undefined)
          clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          oTreeGrid.triggerTreeEnterKey = true;
        }, 500);

        // check if we have to call the leave event
        if (this.opt.leaveEvent)
          app.controller.callAkiomaCode(this, this.opt.leaveEvent);
      }


    },
    changed: function() {
      this.val.value = this.dhx.getComboText();
      this.getValueFromServer(this.dhx.getComboText());
    },

    // get value from server *************
    getValueFromServer: function(value) {

      if (!value)
        value = this.getValueKey();

      // check first if values has been changed
      if (this.oldValue == value)
        return;

      const oData = getLookupValue({
        value: value,
        tableName: this.opt.tableName,
        extHdl: this.opt.extHdl,
        extKey: this.opt.extKey
      });

      if (oData) {
        if (akioma.getForm(this).dataSource)
          this.setValueKey(this.getValueKey());
        this.setValue(oData.value);
        this.setValueDesc(oData.valueDesc);

        if (akioma.getForm(this).dataSource)
          akioma.getForm(this).dataSource.setChanged(true);

        // check if we have to call the leave event
        if (this.opt.leaveEvent)
          app.controller.callAkiomaCode(this, this.opt.leaveEvent);
      }
    },

    // get value *****************
    onFocus: function(value) {
      // save old value for compare
      this.oldValue = value;
      akioma.lastFocus.field = this.opt.name;
      akioma.lastFocus.form = this.parent.opt.name;
    },

    cursorChange: function() {
      delete this.lastValText;
      const oCombo = this.dhx;
      const oComboInp = oCombo.getInput();
      const $comboHead = $(oCombo.hdr);
      const $comboList = $(oCombo.list);
      $comboList.addClass('hidelist');
      $comboHead.addClass('hidelist');


      $(oComboInp).one('keypress', () => {

        $comboList.removeClass('hidelist');
        $comboHead.removeClass('hidelist');


      });
    },
    dataAvailable: function() {
      delete this.lastValText;
    },
    eventLeave: function() {
      const oCombo = this.dhx,
        $itemcont = $(oCombo.DOMParent).parent().parent();
      $itemcont.removeClass('focusedinp');
    },
    eventFocus: function() {
      const oCombo = this.form.getCombo(this.opt.name);
      const $itemcont = $(oCombo.DOMParent).parent().parent();
      $itemcont.addClass('active').addClass('focusedinp');
    },
    // destroy ***************
    destroy: function() {}
  };

})(jQuery, jQuery);
