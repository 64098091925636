replaceRunTokens = function(tokens, oSelf) {
  let index;
  let oOptions;
  for (index = 0; index < tokens.length; ++index) {
    oOptions = tokens[index].split('=');
    if (oOptions.length > 1) {
      if (oOptions[1] === 'NAVIGATION-TARGET')
        oOptions[1] = oSelf.dynObject.getLink('NAVIGATION:TARGET').getValue('selfhdl');
      tokens[index] = oOptions.join('=');
    }

  }
};

/**
* Akioma app namespace
* @namespace app
* @property {boolean} trace
* @property {AK_Controller} controller
* @property {AK_Messenger} messenger
* @type {Object}
*/
window.app = {
  trace: false,
  blockTime: 120000,
  sessionData: {
    useComboCache: true,
    useContainerCache: false,
    posFrameCaching: false
  },
  dynObject: {},
  nodeTypes: {},
  numEntries: function(cList, cDeli) {
    if (!cDeli)
      cDeli = ',';
    return cList.split(cDeli).length;
  },
  lookup: function(cValue, cList, cDeli, cDeli2) {
    let i;

    // check delimiter
    if (!cDeli)
      cDeli = ',';

    // create array
    const aList = cList.split(cDeli);

    // get position
    if (cDeli2) {
      for (i in aList) {
        if (aList[i].split(cDeli2)[0] == cValue)
          return i;
      }
    } else
      return aList.indexOf(cValue);
  },
  entry: function(iNum, cList, cDeli) {

    // correct number of entry -> js begins at 0
    if (iNum)
      iNum -= 1;

    // check delimiter
    if (!cDeli)
      cDeli = ',';

    // create array
    const aList = cList.split(cDeli);

    // check if length of array exceeds number
    if (iNum > aList.length)
      return null;

    return aList[iNum];
  },
  trim: function(cString, cDeli) {
    const oReg = new RegExp(`^${cDeli}+|${cDeli}+$`, 'g');
    return cString.replace(oReg, '');
  },
  ltrim: function(cString, cDeli) {
    const oReg = new RegExp(`^${cDeli}+`, 'g');
    return cString.replace(oReg, '');
  },
  rtrim: function(cString, cDeli) {
    const oReg = new RegExp(`${cDeli}+$`, 'g');
    return cString.replace(oReg, '');
  },
  dataSource: {}
};
app.messenger = new AK_Messenger();
app.controller = new AK_Controller();

akioma.launchContainer = app.controller.launchContainer;
