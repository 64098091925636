/**
 * Base Class for Frame and Window Container Objects
 * @class BaseLayoutContainer
 *
 */
akioma.BaseLayoutContainer = function() {};

akioma.BaseLayoutContainer.prototype = {
  /**
     * Parses the rules settings
     * Cannot be called when creating the object
     * @memberOf BaseLayoutContainer
     * @instance
     */
  _parseRulesSettings: function() {

    const dynObject = this.dynObject;
    const rulesSettings = {
      behaviour: {},
      metadata: {},
      hasRules: false,
      hasLeaveRules: false,
      hasKeyUpRules: false,
      hasChangeRules: false,
      hasDataAvailableRules: false
    };

    if (dynObject) {

      if (dynObject.attributes.RulesBehaviour) {

        const behaviour = JSON.parse(dynObject.attributes.RulesBehaviour.replace(/'/g, '"'));

        if (behaviour.RuleExecutionTrigger) {
          behaviour.RuleExecutionTrigger = behaviour.RuleExecutionTrigger.split(',');
          behaviour.RuleExecutionTriggerMap = behaviour.RuleExecutionTrigger.reduce((map, entity) => {
            map[entity] = entity;
            return map;
          }, {});
        }

        rulesSettings.behaviour = behaviour;
      }

      if (dynObject.attributes.RulesMetadata) {

        const metadata = JSON.parse(dynObject.attributes.RulesMetadata.replace(/'/g, '"'));

        if (metadata.EntityList) {
          metadata.EntityList = metadata.EntityList.split(',');
          metadata.EntityMap = metadata.EntityList.reduce((map, entity) => {
            map[entity] = entity;
            return map;
          }, {});
        }

        rulesSettings.metadata = metadata;
      }

      if (rulesSettings.behaviour.RuleFlow) {
        rulesSettings.hasRules = true;

        if (rulesSettings.behaviour.RuleExecutionTrigger) {
          rulesSettings.hasLeaveRules = rulesSettings.behaviour.RuleExecutionTriggerMap['onLeave'];
          rulesSettings.hasChangeRules = rulesSettings.behaviour.RuleExecutionTriggerMap['onChange'];
          rulesSettings.hasKeyUpRules = rulesSettings.behaviour.RuleExecutionTriggerMap['onKeyUp'];
          rulesSettings.hasDataAvailableRules = rulesSettings.behaviour.RuleExecutionTriggerMap['onDataAvailable'];
        }
      }
    }

    this._rulesSettings = rulesSettings;
  },

  /**
     * Returns the rules settings
     * @memberOf BaseLayoutContainer
     * @instance
     */
  getRulesSettings: function() {
    if (!this._ruleSettings)
      this._parseRulesSettings();

    return this._rulesSettings;
  },

  /**
     * Method to check if it has Rules
     * @memberOf BaseLayoutContainer
     * @instance
     */
  hasRules: function() {
    return this.getRulesSettings().hasRules;
  },

  /**
     * Method to check if Leave Rules are enabled
     * @memberOf BaseLayoutContainer
     * @instance
     */
  hasLeaveRules: function() {
    return this.getRulesSettings().hasLeaveRules;
  },

  /**
     * Method to check if Change Rules are enabled
     * @memberOf BaseLayoutContainer
     * @instance
     */
  hasChangeRules: function() {
    return this.getRulesSettings().hasChangeRules;
  },

  /**
     * Method to check if Change Key Up Rules are enabled
     * @memberOf BaseLayoutContainer
     * @instance
     */
  hasKeyUpRules: function() {
    return this.getRulesSettings().hasKeyUpRules;
  },

  /**
     * Method to check if Leave Rules are enabled
     * @memberOf BaseLayoutContainer
     * @instance
     */
  hasDataAvailableRules: function() {
    return this.getRulesSettings().hasDataAvailableRules;
  },

  /**
     * Method to initialize rules
 * @protected
     * @memberOf BaseLayoutContainer
     * @param {Object) evt The event object.
     */
  callRules: function(evt) {

    const screen = this.dynObject;
    if (!screen) return;

    Corticon.callRules(screen, evt);
  }
};
