/**
 * Generic class used throught the application for icon handling
 * @class akioma.icons
 */
akioma.icons = { defaultTab: 'fad fa-file' };

/**
 * Checks image and returns an object containing the corresponding attributes. Works with normal images, font-icons and stacked font-icons.
 * @param  {string}   image Image
 * @example
 * akioma.icons.getIconType('fad fa-list#color:red) will return:
 *  {  attributes: "color:red"
 *     icon: "fad fa-list"
 *     style: ""
 *     type: "font-icon-attributes"
 *  }
 * @instance
 * @memberOf akioma.icons
 * @return {object} Object containing icon attributes
 */
akioma.icons.getIconType = function(image) {

  function addFontIcon(fontIcon) {
    if (fontIcon.indexOf('#') > -1) {
      const parts = fontIcon.split('#');
      const icon = parts[0];
      const attributes = parts[1];
      if (attributes.startsWith('_style')) {
        const style = attributes.split(':')[1];
        return { type: 'font-icon-module', icon: icon, attributes: '', style: style };
      } else
        return { type: 'font-icon-attributes', icon: icon, attributes: attributes, style: '' };
    } else
      return { type: 'font-icon', icon: fontIcon, attributes: '', style: '' };
  }

  if (image.indexOf('.') == -1) {
    if (image.indexOf('$') > -1) {
      const fontIcons = image.split('$');
      const icon1 = addFontIcon(fontIcons[0]);
      const icon2 = addFontIcon(fontIcons[1]);
      return { type: 'font-icon-stacked', icon1: icon1, icon2: icon2 };
    }
    return addFontIcon(image);
  } else
    return { type: 'normal-icon', icon: image };

};

/**
 * Replaces template with icon properties (including Css attributes, style, stacked icons). Returns the replaced template
 * @param  {string} template Template
 * @param  {object} iconObject Object containing the icon attributes
 * @instance
 * @memberOf akioma.icons
 * @return {string} Template replaced with the icon corresponding values
 */
akioma.icons.replaceTemplate = function(template, iconObject) {
  const oNewIcon = akioma.icons.replaceIcon(iconObject);

  template = template.replace('#title#', iconObject.title);
  template = template.replace('#icon1#', oNewIcon.icon1);
  template = template.replace('#icon2#', oNewIcon.icon2);
  template = template.replace('#iconStyle1#', oNewIcon.attributes1);
  template = template.replace('#iconStyle2#', oNewIcon.attributes2);
  template = template.replace('#moduleClass1#', oNewIcon.style1);
  template = template.replace('#moduleClass2#', oNewIcon.style2);
  template = template.replace('#size#', (oNewIcon.icon2) ? ('fa-stack-2x fa-2x') : 'fa-stack-lg fa-lg');
  template = template.replace('#stackedIcon#', (oNewIcon.icon2) ? ('fa-stack stackedIcon') : '');

  return template;
};

/**
 * Method for forcing all icons to a given forceIconStyle classname
 * @param {string} iconClass Icon className
 * @returns {string}
 */
akioma.icons.forceIconStyle = function(iconClass) {
  const applicationSettings = akioma.applicationSettings;
  if (!isNull(iconClass) && applicationSettings.uiStyle && applicationSettings.uiStyle.forceIconStyle) {
    const iconsStyles = [ 'fas', 'fal', 'far', 'fad' ].filter(iconStyle => iconStyle !== applicationSettings.uiStyle.forceIconStyle);
    iconsStyles.forEach(iconStyle => {
      iconClass = iconClass.replace(iconStyle, applicationSettings.uiStyle.forceIconStyle);
    });
  }
  return iconClass;
};

/**
 * Returns an object which contains all the icon properties combined (including Css attributes, style, stacked icons)
 * @param  {object} iconObject Object containing the icon attributes
 * @instance
 * @memberOf akioma.icons
 * @return {object} Object which contains all the icon properties combined
 */
akioma.icons.replaceIcon = function(iconObject) {
  const oNewIcon = {};
  const oIcon1 = (iconObject.icon1) ? iconObject.icon1 : iconObject;
  const oIcon2 = (iconObject.icon2) ? iconObject.icon2 : '';

  oNewIcon.icon1 = oIcon1.icon;
  oNewIcon.icon2 = (oIcon2) ? oIcon2.icon : '';

  oNewIcon.icon1 = akioma.icons.forceIconStyle(oNewIcon.icon1);
  oNewIcon.icon2 = akioma.icons.forceIconStyle(oNewIcon.icon2);

  oNewIcon.attributes1 = (oIcon1).attributes ? oIcon1.attributes : '';
  oNewIcon.attributes2 = (oIcon2) ? oIcon2.attributes : '';
  oNewIcon.style1 = (oIcon1.style) ? oIcon1.style : '';
  oNewIcon.style2 = (oIcon2) ? oIcon2.style : '';

  return oNewIcon;
};
