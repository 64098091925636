/**
 * Gets the active window (modal or window)
 * @return {ak_window} Active modal or window
 * @instance
 * @memberOf akioma
 */
akioma.getActiveWindow = function() {
  for (let i = 0; i < akioma.aWindows.length; i++) {
    if (akioma.aWindows[i].dhx.isModal())
      return akioma.aWindows[i];
  }

  // If not modal, return active window
  for (let i = 0; i < akioma.aWindows.length; i++) {
    if (akioma.aWindows[i].dhx.isOnTop() && akioma.aWindows[i] != akioma.mainWindowAkUX)
      return akioma.aWindows[i];
  }
};

akioma.makeTransparent = function(oWindow, iOpacity) {
  const $window = $(oWindow.controller.dhx.cell).parent();
  if (!iOpacity)
    iOpacity = 0.7;

  // make window transparent
  $window.css('background', `rgba(255,255,255,${iOpacity})`);
  $window.find('div.dhxwin_brd,.dhx_cell_cont_wins,.dhxform_obj_material,.dhx_cell_wins,.dhx_cell_layout').css({
    border: 0,
    background: 'transparent'
  });
  $window.find('.dhx_cell_cont_layout')[0].style.cssText += 'background: transparent !important;';
  // remove buttons
  $window.find('.dhxwin_btns').remove();
};

/**
 * Method for toggling Layout desktop and taskbar modes
 * @namespace akioma
 * @param {boolean} bState
 */
akioma.toggleAkMultiWindow = function(bState) {
  if (akioma.makeActiveOpenedWindow == false) {
    bState = !bState;
    delete akioma.makeActiveOpenedWindow;
  }

  const cIdPrefix = akioma.homeMultiWindowsToolbar.idPrefix;
  const oHomeBtn = akioma.homeMultiWindowsToolbar.objPull[`${cIdPrefix}home`].obj;

  if (bState == undefined) {
    if (akioma.mainWindowView) {
      $('#mw-wins-container').css('opacity', 0);
      $('#mw-main-win-container').css('opacity', 1);
      akioma.mainWindowView = false;
      akioma.homeMultiWindowsToolbar.setItemState('home', true, false);
    } else {
      $('#mw-wins-container').css('opacity', 1);
      $('#mw-main-win-container').css('opacity', 0);
      akioma.mainWindowView = true;
      akioma.homeMultiWindowsToolbar.setItemState('home', false, false);
    }
  } else if (bState == true) {
    $('#mw-wins-container').css('opacity', 0);
    $('#mw-main-win-container').css('opacity', 1);
    akioma.mainWindowView = false;
    akioma.homeMultiWindowsToolbar.setItemState('home', true, false);
  } else {
    $('#mw-wins-container').css('opacity', 1);
    $('#mw-main-win-container').css('opacity', 0);
    akioma.mainWindowView = true;
    akioma.homeMultiWindowsToolbar.setItemState('home', false, false);
  }
  akioma.toggleFlag = akioma.mainWindowView; // used for setting previous active dataview tab

  if (akioma.mainWindowView)
    $(oHomeBtn).find('i').removeClass('desktopModeTrue').addClass('desktopModeFalse');
  else
    $(oHomeBtn).find('i').removeClass('desktopModeFalse').addClass('desktopModeTrue');

  if (akioma.oWindowParentCell.childs.length > 0) {
    const oWindow = akioma.mainWindowAkUX.dhx,
      bVisibleWin = $(oWindow.cell).is(':visible');

    if (bVisibleWin && akioma.mainWindowAkUX.bRecentlyResized && akioma.bResizeNextHomeViewToggle
      && (typeof oWindow.isMaximized == 'function') && (oWindow.isMaximized())) {
      delete akioma.bResizeNextHomeViewToggle;
      oWindow.akElm.stopSavingSettingsFlag = true;
      oWindow.minimize();
      oWindow.maximize();
      oWindow.akElm.stopSavingSettingsFlag = false;
    }

    if (akioma.bResizeNextMultiWindowsViewToggle && akioma.toggleFlag) {
      delete akioma.bResizeNextMultiWindowsViewToggle;
      akioma.oWindowsParentCell.dhx.forEachWindow(win => {
        win.akElm.loadSettingsForWindow();
      });

    }

    akioma.swat.MasterLayout.disableLastFocusTrap();
    akioma.swat.MasterLayout.lastActiveWindow = akioma.swat.MasterLayout.getTopMostWindow();
    akioma.swat.MasterLayout.enableLastFocusTrap();
  }

  akioma.eventEmitter.emit([ 'Desktop', 'AfterToggle' ], akioma.toggleFlag);

  setTimeout(() => {
    let oWdw;
    if (bState)
      oWdw = akioma.aWindows[0];
    else
      oWdw = akioma.getActiveWindow();
    if (oWdw && oWdw.oActivePanel) {
      const oPanel = oWdw.oActivePanel;
      oPanel.setActivePanelState();

      // Handling for Grid rows
      let bRowMode = false;
      const oContent = oPanel.getDescendant('datagrid2');
      if (oPanel.oFocus.bRowMode || (oContent && oContent.getIndex_FirstActiveFilter() == null))
        bRowMode = true;
      if (!oWdw.dhx.isParked())
        (bRowMode) ? oPanel.setFocus(true, oPanel.oFocus.oActiveRow) : oPanel.setFocus(false, oPanel.oFocus.oActiveField);

    }
  }, 300);
};

setWindowStyle = function(self, cStyle) {
  const oWin = self.container;
  oWin.controller.posWindow(cStyle);
};

/**
 * Method used setting akContainerSize attribute on a container
 * @param {HTMLElement} container DOM element
 * @returns {void}
 */
akioma.setContainerSize = function(container) {
  const cont = $(container);
  const width = cont.width();
  switch (true) {
    case (width < 480): {
      cont.attr('akContainerSize', 'xxs');
      break;
    }
    case (width < 768): {
      cont.attr('akContainerSize', 'xs');
      break;
    }
    case (width < 992): {
      cont.attr('akContainerSize', 'sm');
      break;
    }
    case (width < 1200): {
      cont.attr('akContainerSize', 'md');
      break;
    }
    case (width >= 1200): {
      cont.attr('akContainerSize', 'lg');
      break;
    }
  }
};

/**
 * set initial layout settings
 */
akioma.setInitialLayoutSettings = function() {
  akioma.layoutSettings.initSizes = true;
  switch (akioma.Themes.getTheme()) {
    case 'dark-simple':
      akioma.layoutSettings.isThemeDark = true;
      akioma.layoutSettings.isThemeAkioma = false;
      akioma.layoutSettings.isThemePlaygroundDark = false;
      break;
    case 'akioma-simple':
      akioma.layoutSettings.isThemeDark = false;
      akioma.layoutSettings.isThemeAkioma = true;
      akioma.layoutSettings.isThemePlaygroundDark = false;
      break;
    case 'playground-dark':
      akioma.layoutSettings.isThemeDark = false;
      akioma.layoutSettings.isThemeAkioma = false;
      akioma.layoutSettings.isThemePlaygroundDark = true;
      break;
    default:
      break;
  }
};

/**
 * Method used for setting the server description as a class for body element.
 */
akioma.setServerDescription = function() {
  if (app.sessionData.serverDescription && app.sessionData.serverDescription !== '') {
    try {
      const body = document.getElementsByTagName('BODY')[0];
      const serverDescriptionClasses = app.sessionData.serverDescription.split(' ');
      body.classList.add(...serverDescriptionClasses);
    } catch (err) {
      akioma.log.warn('Invalid value for serverDescription sessionData, please provide list of CSS classes.', app.sessionData.serverDescription);
    }
  }
};

// global context function
// eslint-disable-next-line no-unused-vars
function getSDOValue(oSelf, cFieldName) {
  const oCaller = oSelf.container;
  if (isValidObj(oCaller) == false) {
    akioma.log.error(`cannot get container for: ${oSelf.name}`);
    return '';
  }

  const oNavTarget = oCaller.getLink('PRIMARYSDO:TARGET');
  if (isValidObj(oNavTarget) == false) {
    akioma.log.error(`cannot get Primary-SDO Target for: ${oCaller.name} (${oCaller.type})`);
    return '';
  }

  return oNavTarget.getValue(cFieldName);
}

// global context function
// eslint-disable-next-line no-unused-vars
function getCallerValue(oSelf, cFieldName) {
  const oCaller = oSelf.container.caller;
  if (isValidObj(oCaller) == false) {
    akioma.log.error(`cannot get container for: ${oSelf.name}`);
    return '';
  }

  const oNavTarget = oCaller.container.getLink('PRIMARYSDO:TARGET');
  if (isValidObj(oNavTarget) == false) {
    akioma.log.error(`cannot get Primary-SDO Target for: ${oCaller.name} (${oCaller.type})`);
    return '';
  }

  return oNavTarget.getValue(cFieldName);
}

// global context function
// eslint-disable-next-line no-unused-vars
function getCallerValue2(oSelf, cFieldName) {
  const oCaller = oSelf.container.caller;
  if (isValidObj(oCaller) == false) {
    akioma.log.error(`cannot get container for: ${oSelf.name}`);
    return '';
  }

  const oNavTarget = oCaller.parent.getLink('PRIMARYSDO:TARGET');
  if (isValidObj(oNavTarget) == false) {
    akioma.log.error(`cannot get Primary-SDO Target for: ${oCaller.name} (${oCaller.type})`);
    return '';
  }

  return oNavTarget.getValue(cFieldName);
}

/**
 * Method used for applying a layout for diagram
 * @param   {object} self   Diagram dynObject
 * @param   {string} layout Layout name
 * @namespace akioma
 */
akioma.applyDiagramLayout = function(self, layout) {
  self.controller.applyDiagramLayout(layout);
};

getActionContainer = function(oAction, lSkip) {
  let oCaller = oAction.container;
  if (lSkip === true)
    oCaller = oCaller.caller.container;
  if (isValidObj(oCaller) == false) {
    akioma.log.error(`cannot get caller for: ${oAction.name}`);
    return '';
  }

  const oTree = oCaller.getObject('itTree');
  return oTree.controller.getContainer().childs[0].dynObject;
};

akioma.getContainerByName = function(cName) {
  try {
    const aConts = app.controller.getContainersOpened();
    return aConts[cName];
  } catch (e) {
    console.warn(e);
  }
};
