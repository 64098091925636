

/**
 * The ResourceService class is used for retriving data.
 * @class ResourceService
 *
 */
akioma.ResourceService = class {

  /**
   * Method for getting text data.
   * @memberOf ResourceService
   * @instance
   * @static
   * @param {Object} context
   * @param {string} hdl
   * @param {string} type
   * @return {Object}
   */
  static fetchText(context, hdl, data) {
    if (context && hdl && data) {
      return $.ajax({
        type: 'GET',
        context: context,
        url: `${akioma.sessionContext.eSwatSessionContext.hostURL}/web/AppData/text/${hdl}`,
        data: data
      });
    } else
      return null;
  }

  /**
   * Method for getting img data.
   * @memberOf ResourceService
   * @instance
   * @static
   * @param {string} hdl
   * @param {string} type
   * @return {string}
   */
  static fetchImage(hdl, type) {
    if (hdl && type)
      return `${akioma.sessionContext.eSwatSessionContext.hostURL}/web/AppData/image/${hdl}?type=${type}`;
    else
      return '';
  }
};
