
/**
 * Adapter for Kinvey DataStore for usage in an akioma.businessEntity
 * @class
 */
class KinveyDataAdapter extends akioma.DataAdapter {

  /**
   * Constructor of the Kinvey Data Adapter.
   *
   * @param {string} collectionName Name of the resource from which to obtain data.
   */
  constructor(collectionName) {
    super();
    /**
     * Underlying Kinvey DataStore (collection).
     *
     * @private
     *
     * @property {Object}
     */
    this._collection = Kinvey.DataStore.collection(collectionName, Kinvey.DataStoreType.Network);

    /**
     * Logger for this kinvey adapter.
     *
     * @private
     *
     * @property {akioma.Logger}
     */
    this._log = akioma.log.getLogger('KinveyDataAdapter');
  }

  /**
   * Checks if kinvey is enabled.
   *
   * @private
   *
   * @returns {boolean}
   */
  _isKinveyEnabled() {
    return akioma.KinveyIsEnabled();
  }

  /**
   * Fill data from the data store.
   *
   * @param {Object} options AkQuery parameter object.
   *
   * @returns {Promise}
   */
  fill(options) {
    if (!this._isKinveyEnabled()) {
      akioma.notification({ type: 'warning', text: 'Kinvey integration not activated!' });
      throw new Error('KinveyDataAdapter: Unable to fetch data, Kinvey integration not activated!');
    }

    const query = akioma.KinveyAdapterHelper.convertAkQueryToKinvey(options);

    const fillPromise = this._collection.find(query).toPromise(); // Return find promise results for fillPromise

    fillPromise.then(records => {
      this._emitEvent('afterFill', records);
    }).catch(error => {
      this._emitEvent('afterFill', error, false);
    });

    return fillPromise;
  }

  /**
   * Accept data changes.
   */
  acceptChanges() {
    // NOT IN USE
  }

  /**
   * Reject changes.
   *
   * @returns {Promise}
   */
  rejectChanges() {
    return this._collection.clearSync();
  }

  /**
   * Removes a record from the data store.
   *
   * @param {Object} entity Record to remove.
   *
   * @returns {Promise}
   */
  remove(entity) {
    const removePromise = this._collection.removeById(entity._id);

    removePromise.then(results => {
      const success = results.count > 0;
      const data = success ? entity : Object.assign(entity, { message: 'Unable to remove.' });
      if (!success)
        this._collection.clearSync();

      this._emitEvent('afterDelete', data, success);
      this._emitEvent('afterSaveChanges', data, success);
    }).catch(error => {
      this._collection.clearSync();
      this._emitEvent('afterDelete', error, false);
      this._emitEvent('afterSaveChanges', error, false);
    });

    return removePromise;
  }

  /**
   * Fetches a record by id.
   *
   * @param {string} id Id of the record to find.
   *
   * @returns {Promise<Object>}
   */
  findById(id) {
    return this._collection.findById(id).toPromise();
  }

  /**
   * Updates a record on the data store.
   *
   * @param {Object} entity Record to update.
   *
   * @returns {Promise}
   */
  update(entity) {
    const updatePromise = this._collection.update(entity);

    updatePromise.then(updatedRecord => {
      this._emitEvent('afterUpdate', updatedRecord);
      this._emitEvent('afterSaveChanges', updatedRecord);
    }).catch(error => {
      this._collection.clearSync();
      this._emitEvent('afterUpdate', error, false);
      this._emitEvent('afterSaveChanges', error, false);
    });

    return updatePromise;
  }

  /**
   * Creates a new record on the data store.
   *
   * @param {Object} entity Record to create.
   *
   * @returns {Promise}
   */
  create(entity) {
    const createPromise = this._collection.create(entity);

    createPromise.then(results => {
      this._emitEvent('afterCreate', results);
      this._emitEvent('afterSaveChanges', results);
    }).catch(error => {
      this._emitEvent('afterCreate', error, false);
      this._emitEvent('afterSaveChanges', error, false);
    });

    return createPromise;
  }

  /**
   * Save record changes to data store.
   */
  saveChanges() {
    throw new Error('SaveChanges not implemented');
  }

}

akioma.KinveyDataAdapter = KinveyDataAdapter;
