/**
 * SwatHtmlContainerPanel Control
 * @class ak_htmlContainerPanel
 * @param {Object} options Repository attributes for SwatHtmlContainerPanel.
 */
$.ak_htmlContainerPanel = class {
  constructor(options) {
    const defaults = {};

    this.opt		= Object.assign({}, defaults, options.att);
    this.parent	= options.parent;
    this.registerDynObject = true;
    this.useParentDynObjectLink = true;

    // get parent
    const oParent	= this.parent;

    if (oParent) {
      const id = this.opt.id;
      this.parent.dhx.attachHTMLString(`<div id="${id}"></div>`);
      this.$domElement = $(this.parent.dhx.cell).find(`#${id}`);
      this.$domElement.parent().addClass('htmlContainerPanel');
    }
  }

  finishConstruct() {
    this.renderVueInstance();
  }

  /**
   * Method for rendering the vue templates
   * @memberof ak_htmlcontainerpanel
   * @returns {void}
   */
  renderVueInstance() {

    const dataSource = this.dataSource;
    const datasourceNamespace = dataSource.getFilteredNamespace();
    if (dataSource) {
      const namespace = this.getFilteredNamespace();
      const template = this.opt.Template;

      this.vueTemplateInstance = new akioma.VueInstancesFactory('template', {
        namespace,
        template,
        controller: this,
        datasourceNamespace,
        dataSource
      });
      this.vueTemplateInstance.mount(this.$domElement[0]);
    }
  }
};
