// ************************* toolbarbutton ********************************
$.extend({
  ak_toolbarbutton: function(options) {
    const oSelf = this,
      defaults = {
        id: '',
        title: '',
        image: '',
        image2: ''
      };

    this.opt = $.extend({}, defaults, options.att);

    this.registerDynObject = true;
    this.useParentDynObjectLink = true;

    this.parent = options.parent;

    // get new element
    const cName = this.opt.name,
      cImage = (this.opt.image == '') ? null : this.opt.image,
      cImage2 = (this.opt.image2 == '') ? null : this.opt.image2;

    let cText = (this.opt.imageOnly) ? '' : this.opt.title;
    if (this.parent.opt.labelDisplay == 'show')
      cText = this.opt.title;
    if (this.parent.opt.labelDisplay == 'hide')
      cText = '';

    cText = akioma.tran(`filterManager.filterToolbar.${cName}`, { defaultValue: cText });

    oSelf.cName = cName;

    let cTooltip = (this.opt.tooltip) ? this.opt.tooltip : this.opt.title;
    if (app.sessionData.objectNamesInTitles)
      cTooltip = `${cTooltip} >> ${this.opt.name}`;


    const iPos = this.parent.opt.pos + 1;
    this.parent.opt.pos = iPos;
    this.oKey = null;

    // get toolbar
    const oToolbar = this.parent.dhx;
    if (oToolbar) {

      if (this.opt.defaultGoBtn)
        this.parent.defaultGoBtn = this;
      else if (this.opt.defaultCancel)
        this.parent.defaultCancelBtn = this;


      if (this.opt.type == 'toggle') {
        if (this.opt.subType == 'segmented') {
          if (this.parent.opt.toolBarKey == 'dataviewImg')
            this.opt.listItems = 'imglarge,imgmedium,imglist';
          else
            this.opt.listItems = 'ficon,ftiles,ftable';

          oToolbar.addButtonTwoState(cName, iPos, '', '/akioma/view_large.gif', '');
          oToolbar.addButtonTwoState(`${cName}_2`, iPos + 1, '', '/akioma/view_medium.gif', '');
          oToolbar.addButtonTwoState(`${cName}_3`, iPos + 2, '', '/akioma/view_list.gif', '');
          oToolbar.addSegmented(cName, [ cName, `${cName}_2`, `${cName}_3` ], cName);
          this.parent.opt.pos += 2;
        } else {
          oToolbar.addButtonTwoState(cName, iPos, cText, cImage, cImage2);
          $(oToolbar.cont).find('.float_left .dhx_toolbar_btn:last').attr('dataStyle', 'akToogle');
        }
      } else
        oToolbar.addButton(cName, iPos, cText, cImage, cImage2);


      /* different styles if this toolbar is displayed in a panel header*/
      if (this.parent.opt.containerObject === 'header' && this.opt.type != 'toggle') {


        const btn = oToolbar.objPull[oToolbar.idPrefix + cName].obj;
        btn.style.float = 'left';
        btn.style.background = 'transparent';
        btn.style.border = 'none';
      }

      /* MLi: shortcut in tooltip */
      if (cTooltip) {
        if (this.opt.shortCut)
          cTooltip += ` (${this.opt.shortCut})`;
        oToolbar.setItemToolTip(cName, cTooltip);
      }

      // check for shortcut
      if (this.opt.shortCut) {

        const oPanelset = this.getAncestor('panelset');
        if (oPanelset)
          oPanelset.registerKey({ key: this.opt.shortCut, action: { target: this } });

      }

      if (this.opt.disabled == true)
        this.parent.disableItem(cName);

      if (this.opt.hidden == true)
        this.parent.dhx.hideItem(cName);

    } else
      !_isIE && console.error(`No valid parent for toolbarbutton ${cName}`);

  }
});

// methods for toolbar
$.ak_toolbarbutton.prototype = {
  // finish ****************
  finishConstruct: function() {
    const oSelf = this;

    // check for key event
    if (this.opt.shortCut) {
      const oCont = this.getAncestor('frame,window'),
        aShort = this.opt.shortCut.split('-'),
        oKey = {
          altKey: false,
          ctrlKey: false,
          shiftKey: false,
          key: aShort[1]
        };

      switch (aShort[0]) {
        case 'ALT':
          oKey.altKey = true;
          break;
        case 'CTRL':
          oKey.ctrlKey = true;
          break;
        case 'SHIFT':
          oKey.shiftKey = true;
          break;
      }

      if (oCont && oCont.dhx) {
        // build key combo for binding
        const aCom = [];
        oKey.cCommand = '';
        if (oKey.altKey)
          aCom.push('alt');
        if (oKey.ctrlKey)
          aCom.push('ctrl');
        if (oKey.shiftKey)
          aCom.push('shift');
        aCom.push(oKey.key);

        oKey.cCommand = aCom.join('+').toLowerCase();
        this.oKey = oKey;
        // bind key shortcut
        let oWindow = oSelf.getAncestor('window');

        if (oWindow == null)
          oWindow = oSelf.getAncestor('popup');

        const mousetrap = oWindow.oMouseTrap;
        mousetrap.bind(oKey.cCommand, () => {
          const bActiveWindow = oSelf.getAncestor('window').dhx.isOnTop();
          if (bActiveWindow)
            oSelf.click();

        });

      }
    }
    const idPrefix = this.parent.dhx.idPrefix;
    this.dhx = this.parent.dhx.objPull[idPrefix + this.opt.name];
    this.parent.aToolbarItems.push(this.opt.name);

    if (akioma.getAkIdMode() == 'extended')
      $(this.dhx.obj).attr('akId', `${this.dynObject.screen.name}-${this.parent.toolbarMenuID}-${this.opt.name}`);
  },

  // click *****************
  click: function(cId) {


    // for menuStructure code loaded Toolbar
    if (this.parent.toolbarMenuStructure != undefined) {

      const oMenu = this.parent.toolbarMenuStructure.getMenuItemByCode(cId);
      this.parent.toolbarMenuStructure.applyAction(oMenu.id, this.parent, this);
      return true;
    }
    // call action
    app.controller.callAction({
      ActionType: this.opt.actiontype,
      Action: this.opt.action,
      Category: this.opt.category,
      RunPar: this.opt.RunPar,
      CallObject: this.opt.logicalObject,
      caller: this
    },
    this.parent);
    return true;
  },

  // state change *****************
  segmentedChange: function(cOption) {
    app.controller.callAction({
      ActionType: this.opt.actiontype,
      Action: this.opt.action,
      Category: this.opt.category,
      RunPar: this.opt.RunPar,
      CallObject: this.opt.logicalObject,
      click: cOption,
      caller: this
    },
    this.parent);

    return true;
  },

  // state change *****************
  stateChange: function(cId) {
    /* if this is part of a segmented button this will be handled by the segmentedChange Event */
    if (this.opt.subType == 'segmented')
      return true;

    if (this.parent.toolbarMenuStructure != undefined) {

      const oMenu = this.parent.toolbarMenuStructure.getMenuItemByCode(cId);
      this.parent.toolbarMenuStructure.applyAction(oMenu.id, this);
      return true;
    }

    app.controller.callAkiomaCode(this, this.opt.action);
    return true;
  },

  // set property *********
  setProperty: function(oElm) {
    setProperty(this, oElm);
  },

  destroy: function() {
    const oCont = this.getAncestor('frame,window');

    if (this.oKey != null) {
      const mousetrap = oCont.oMouseTrap;
      if (mousetrap)
        mousetrap.unbind(this.oKey.cCommand);
    }
  }
};
