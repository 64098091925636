// ************************* toolbarcombo ********************************
$.extend({
  ak_toolbarcombo: function(options) {
    const defaults = {
      id: '',
      title: '',
      disabled: false,
      image: '',
      image2: ''
    };

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;

    this.registerDynObject = true;
    this.useParentDynObjectLink = true;

    // get new element
    const cName = this.opt.name;
    let cTitle = this.opt.title;
    let cImage = this.opt.image;
    let cImage2 = this.opt.image2;
    const aOpts = [];
    let cTooltip = (this.opt.tooltip) ? this.opt.tooltip : cTitle;

    cTooltip = akioma.tran(`toolbar.${cName}_tooltip`, { defaultValue: cTooltip });
    cTitle = akioma.tran(`toolbar.${cName}_label`, { defaultValue: cTitle });

    if (this.opt.options) {
      const aOptRec = this.opt.options.split('|');
      for (const i in aOptRec) {
        const aOptVal = aOptRec[i].split(',');
        aOpts.push([ aOptVal[0], 'obj', aOptVal[1] ]);
      }
    }

    const iPos = this.parent.opt.pos + 1;
    this.parent.opt.pos = iPos;

    cImage = (cImage == '') ? null : cImage;
    cImage2 = (cImage2 == '') ? null : cImage2;

    // get toolbar
    const oToolbar = this.parent.dhx;
    if (oToolbar) {
      oToolbar.addButtonSelect(cName, iPos, cTitle, aOpts, cImage, cImage2, true, true);
      // console.log('toolbar',oToolbar.cont);
      $(oToolbar.cont).find('.float_left .dhx_toolbar_btn:last').attr('dataStyle', 'akCombo');

      if (this.parent.opt.containerObject === 'header') {
        /* different styles if this toolbar is displayed in a panel header*/
        const btn = oToolbar.objPull[oToolbar.idPrefix + cName].obj;
        btn.style.float = 'left';
        btn.style.background = 'transparent';
        // btn.style.border = "none";
      }


      if (cTooltip)
        oToolbar.setItemToolTip(cName, cTooltip);

      if (this.opt.width)
        oToolbar.setWidth(cName, this.opt.width);

      // workaround for dhtmlx problem
      // oToolbar.objPull[ oToolbar.idPrefix + cName ].polygon.style.zIndex= 10000;

    } else
      !_isIE && console.error(`No valid parent for toolbarcombo ${cName}`);

  }
});


// methods for toolbar combo
$.ak_toolbarcombo.prototype = {

  // finalize construct *************
  finishConstruct: function() {
    // check for dyncombo entry
    if (this.opt.objectName || this.opt.ListItemPairs)
      app.toolbar.getDynCombo.apply(this);

    const oParent = this.parent;
    if (oParent.toolbarMenuID && this.opt.name == 'tbfilterChooseFilter') {
      const oFilter = this.dynObject.getLink('FILTER:TARGET');
      oFilter.controller.getFilterList();
    }
    this.parent.aToolbarItems.push(this.opt.name);
  },

  // finalize construct *************
  endConstruct: function() {
    // check for autoselect
    if (this.opt.autoselect || this.opt.name == 'tbNodeType') {
      const oToolbar = this.parent.dhx,
        aOptions = oToolbar.getAllListOptions(this.opt.name),
        oSelf = this;

      if (aOptions && aOptions.length > 0) {
        dhtmlx.delay(() => {
          oSelf.click(aOptions[0]);
        });
      }
    }
  },

  // set combo options ***************
  setComboOptions: function(oElm) {
    if (oElm) {
      const oToolbar = this.parent.dhx;

      this.options = [];

      // stop here toolbar already removed
      if (oToolbar.addListOption == null)
        return;

      let iNum = 0;
      for (const i in oElm) {
        iNum += 1;
        if (oElm[i].key)
          oElm[i].value = akioma.tran(`toolbar.${this.opt.name}_Entries.${oElm[i].key}`, { defaultValue: oElm[i].value });
        oToolbar.addListOption(this.opt.name, oElm[i].hdl, iNum, 'button', oElm[i].value, oElm[i].image);
        this.options[oElm[i].hdl] = oElm[i];
      }

      // workaround for dhtmlx problem
      const combo = oToolbar.objPull[oToolbar.idPrefix + this.opt.name];
      combo.polygon.style.zIndex = 10000;
      $(combo.obj).find('.dhxtoolbar_text').addClass('akToolbarCombo');
    }
  },

  // gets selected options
  getSelectedOption: function() {
    const hdl = this.getFieldValue();
    return this.options[hdl];
  },

  // get field value *********
  getFieldValue: function() {
    if (this.parent.dhx && this.parent.dhx.getListOptionSelected)
      return this.parent.dhx.getListOptionSelected(this.opt.name);
  },

  // set field value ********
  setFieldValue: function(cId) {
    let cImg = null;
    if (this.parent.dhx.getListOptionSelected == null)
      return;

    if (this.parent.dhx.getListOptionSelected(this.opt.name) != cId)
      this.parent.dhx.setListOptionSelected(this.opt.name, cId);

    // show actual field in header
    const cText = this.parent.dhx.getListOptionText(this.opt.name, cId);
    const cUrl = this.parent.dhx.getListOptionImage(this.opt.name, cId);

    // extract image
    if (cUrl) {
      const aUrl = cUrl.split('/');
      cImg = aUrl[aUrl.length - 1];
    }

    this.parent.dhx.setItemText(this.opt.name, cText);
    if (cImg)
      this.parent.dhx.setItemImage(this.opt.name, cImg);
  },

  // click *****************
  click: function(cId) {
    this.setFieldValue(cId);

    if (this.parent.toolbarMenuStructure != undefined) {
      const oMenu = this.parent.toolbarMenuStructure.getMenuItemByCode(this.opt.id);
      const oMenuAction = this.parent.toolbarMenuStructure.aMenuActions[oMenu.id];
      if (oMenuAction) {
        this.opt.actiontype = oMenuAction.ActionType;
        this.opt.action = oMenuAction.Action;
        this.opt.category = oMenuAction.Category;
      }

    }

    // call action
    app.controller.callAction({
      ActionType: this.opt.actiontype,
      Action: this.opt.action,
      Category: this.opt.category,
      RunPar: this.opt.RunPar,
      CallObject: this.opt.logicalObject,
      caller: this,
      launchedFrom: this,
      click: cId
    },
    this.parent);

    if (this.opt.selectEvent)
      app.controller.callAkiomaCode(this, this.opt.selectEvent);

    return true;

  }
};
