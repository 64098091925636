

// ********************* listbaritem ******************
$.extend({
  ak_listbaritem: function(options) {
    const defaults = {
      tree: null,
      title: '',
      id: ''
    };

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;

    // get parent
    const oData = this.parent.dataView;
    if (oData) {

      oData.add({
        id: `ListItem-${this.opt.id}`,
        imgSrc: this.opt.image,
        label: this.opt.label,
        targetHdl: this.opt.targetHdl
      });

    } else
      !_isIE && console.error(`No valid parent for listbaritem ${this.opt.id}`);

  }
});

// methods for listbargroup
$.ak_listbaritem.prototype = {
  delItem: function() {
    const oSelf = this,
      cGroup = this.parent.opt.selfHdl,
      oData = this.parent.parent.dataSource,
      cRow = oData.dhx.getCursor(),
      oItem = oData.dhx.item(cRow),
      cHdl = oItem.selfhdl;

    $.ajax({
      async: false,
      type: 'POST',
      url: '/akioma/getlistbar.xml',
      dataType: 'json',
      data: `Mode=delItem&EntryHdl=${this.opt.id}&GroupHdl=${cGroup}&OwnerHdl=${cHdl}`,
      success: function(data) {
        if (!data.ok)
          akioma.message({ type: 'alert-error', title: 'Delete item', text: `Error deleting item:<br />${data.message}` });
        else {
          oSelf.parent.dataView.remove(`ListItem-${oSelf.opt.id}`);
          oSelf.parent.childs.forEach((entry, index, object) => {
            if (entry.opt.id === oSelf.opt.id)
              object.splice(index, 1);
          });
        }
      },
      error: function(xhr, textStatus, errorThrown) {
        akioma.log.error(`Error loading html from 'listbar': ${textStatus} -> ${errorThrown}`);
      }
    });
  },

  destroy: function() {
  }
};
