/**
 * Global Akioma method for Notification messages
 * @namespace akioma
 * @returns {void}
 */
akioma.notification = function(opts) {
  return akioma.NotificationMessage.message(opts);
};

// remove later but keep for safety
// add namespace akioma should be used, but just for safety we keep it for a bit longer. . 20.09.2019 - Sebastian
// backwards compatibilty with uiMessage -> akioma.NotificationMessage
// global context variable
// eslint-disable-next-line no-unused-vars
const uiMessage = akioma.NotificationMessage;
