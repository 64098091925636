// ************************* ribbonseparator ********************************
$.extend({
  ak_ribbonseparator: function(options) {
    const defaults = { id: '' };

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;

    // get new element
    const cName = this.opt.id;

    const iPos = this.parent.opt.pos + 1;
    this.parent.opt.pos = iPos;

    // get ribbon
    const oribbon = this.parent.dhx;
    if (oribbon)

      oribbon.addSeparator(cName, iPos);

    else
      !_isIE && console.error(`No valid parent for ribbonseparator ${cName}`);

  }
});
