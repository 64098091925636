(function($) {

  // ********************* popover ******************
  $.extend({
    /**
     * SwatPopover Control
     * @class ak_popover
     * @param {Object} options Repository attributes for SwatPopover.
     * @param {string} options.PrivateData
     * @param {string} options.LayoutOptions List of multi-layout options for the object.
     * @param {boolean} options.isModal defines if the container should be modal (blocking all other ui elements)
     * @param {string} options.LABEL WidgetAttributes Label
     * @param {string} options.HtmlClass CLASS property for an HTML tag
     * @param {string} options.typeRange The type range which makes up a combobox in a Toolbar
     * @param {string} options.TITLE Browser, Frame, Dialog and Window title
     * @param {string} options.EventPreInitialize
     * @param {string} options.EventOnInitialize client side code to run when Container has been initialized
     */

    ak_popover: function(options) {
      const oSelf = this,
        defaults = { };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;
      this.view = 'popover';

      const popup = new dhtmlXPopup();
      popup.setDimension(300, 300);

      oSelf.bVisibleOnce = false;
      // load cpmtent when you open the first time
      popup.attachEvent('onShow', () => {
        if (oSelf.opt.loadData && !oSelf.bVisibleOnce) {
          oSelf.bVisibleOnce = true;

          let cSelfHdl;
          const oFrame = oSelf.parent.getDescendant('frame');
          if (oFrame) {
            const oFrameDS = oFrame.getDataSource();
            if (oFrameDS)
              cSelfHdl = oFrameDS.getValue('selfhdl');
          }

          const oLaunchPrommise = app.controller.launchContainer({
            target: oSelf,
            proc: 'include.r',
            para: `RunFile=${oSelf.opt.loadData}&Page=*` + `&guid=${oSelf.opt.id}`,
            data: true,
            self: oSelf,
            async: true
          });


          if (cSelfHdl) {
            oLaunchPrommise.done(oResult => {
              const oData = oResult.getDataSource();

              if (oData) {
                const oDataSource = oData.controller;
                oDataSource.query.addCondition('selfhdl', 'eq', cSelfHdl);
                oDataSource.openQuery({});
              }


            }).fail(() => {
              akioma.notification({ type: 'error', text: 'Error loading popover data.' });
            });
          }

        }

        try {
          const oPanelSet = oSelf.getDescendant('panelset');
          if (oPanelSet)
            oPanelSet.dhx.setSizes();
        } catch (e) {
          akioma.log.error(e);
        }

      });
      popup.attachEvent('onClick', () => {});

      this._iDataSourceToLoad = 0;

      /**
 * @property iDataSourceToLoad is the number of dataSources that still need to be loaded inside the current Window.
 * When it reaches "0" it will emit the dataLoaded event
 */
      Object.defineProperties(this, {
        'iDataSourceToLoad': {
          'get': function() {
            return this._iDataSourceToLoad;
          },
          'set': function(newval) {
            // console.log('set dataSource to load for ' + this.opt.name, newval);
            if (newval == 0)
              akioma.eventEmitter.emit(`${this.opt.id}:dataLoaded`, this);

            this._iDataSourceToLoad = newval;
          }
        }
      });

      $.extend(this, { dhx: popup });

    }
  });

  // methods for frame
  $.ak_popover.prototype = {

    // finish construct
    finishConstruct: function() {

    },

    endConstruct: function() {
    },

    destroy: function() {

      // check if we are attached in a dhx element
      if (this.dhx) {
        this.dhx.unload();
        this.dhx = null;
      }
    },
    hide: function() {
      this.dhx.hide();
    },
    show: function() {
      this.dhx.show();
    }
  };

})(jQuery, jQuery);
