akioma.bpm.saveDiagram = function(self) {

  const oBpmnDiagram = self.parent.controller.getDescendant('bpmnDiagram');
  if (oBpmnDiagram)
    oBpmnDiagram.saveBpmnDiagram();
  else {
    const oDataSource = self.parent.controller.getDescendant('businessEntity');
    if (oDataSource)
      oDataSource.updateRecord({});
  }
};
akioma.bpm.ruleChosen = function() {};

akioma.bpm.assignTask = function(self) {
  const oSDO = self.container.getLink('PRIMARYSDO:TARGET');
  const cHdl = oSDO.getValue('name');
  app.controller.callServerMethod('mod/wflow/taskAction.p', [
    { type: 'iCHAR', value: cHdl },
    { type: 'iCHAR', value: 'assign' },
    { type: 'iCHAR', value: '' },
    { type: 'oCHAR', name: 'cResult' }
  ]);
  oSDO.controller.reloadData();
};

akioma.bpm.completeTask = function(self) {
  const oBusinessEntity = self.parent.controller.getDescendant('businessEntity');
  if (oBusinessEntity && oBusinessEntity.opt.SUBTYPE == 'KinveyCollection') {
    const oSelectedRecord = oBusinessEntity.getSelectedRecord();
    const cId = oSelectedRecord.id;

    if (cId) {
      akioma.invokeServerTask({
        name: 'TestCamunda-CompleteTask',
        methodType: 'kinvey',
        paramObj: { id: cId }
      }).done(resp => {
        if (resp.status === 'success')
          akioma.NotificationMessage.showInfo({ text: `Result: ${resp.message}` });
        else
          akioma.NotificationMessage.showError({ text: `Result: ${resp.message}` });
        oBusinessEntity.openQuery({});
      });
    }
  } else {
    const oSDO = self.container.getLink('PRIMARYSDO:TARGET');
    const cHdl = oSDO.getValue('name');
    const oReturn = app.controller.callServerMethod('mod/wflow/taskAction.p', [
      { type: 'iCHAR', value: cHdl },
      { type: 'iCHAR', value: 'Complete' },
      { type: 'iCHAR', value: '' },
      { type: 'oCHAR', name: 'cResult' }
    ]);

    akioma.messaging.info(oReturn.cResult);
    oSDO.controller.reloadData();
  }
};

akioma.bpm.rejectTask = function(self) {
  const oSDO = self.container.getLink('PRIMARYSDO:TARGET');
  const cHdl = oSDO.getValue('name');
  const oReturn = app.controller.callServerMethod('mod/wflow/taskAction.p', [
    { type: 'iCHAR', value: cHdl },
    { type: 'iCHAR', value: 'reject' },
    { type: 'iCHAR', value: '' },
    { type: 'oCHAR', name: 'cResult' }
  ]);

  akioma.messaging.info(oReturn.cResult);
  oSDO.controller.reloadData();
};

akioma.bpm.reassignTask = function(self) {
  const oSDO = self.container.getLink('PRIMARYSDO:TARGET');
  const cHdl = oSDO.getValue('name');
  const oReturn = app.controller.callServerMethod('mod/wflow/taskAction.p', [
    { type: 'iCHAR', value: cHdl },
    { type: 'iCHAR', value: 'ReAssign' },
    { type: 'iCHAR', value: '' },
    { type: 'oCHAR', name: 'cResult' }
  ]);

  akioma.messaging.info(oReturn.cResult);
  oSDO.controller.reloadData();
};

akioma.bpm.makeTaskAvailable = function(self) {
  const oSDO = self.container.getLink('PRIMARYSDO:TARGET');
  const cHdl = oSDO.getValue('name');
  const oReturn = app.controller.callServerMethod('mod/wflow/taskAction.p', [
    { type: 'iCHAR', value: cHdl },
    { type: 'iCHAR', value: 'MakeAvailable' },
    { type: 'iCHAR', value: '' },
    { type: 'oCHAR', name: 'cResult' }
  ]);

  akioma.messaging.info(oReturn.cResult);
  oSDO.controller.reloadData();
};
