akioma.config.instance = null;
akioma.config.useNode = false;
akioma.config.profiling = false;

akioma.config.showPreview = function(screenID) {
  akioma.log.info(`Preview screen ${screenID}`);
};


akioma.config.initBizRuleDialog = function(self) {
  akioma.config.instance = self;

  $.ajax({
    type: 'POST',
    url: '/akioma/initConfDialog.r',
    dataType: 'json',
    data: `action=Init&DialogId=${self.name}`,
    success: function(data) {
      akioma.config.dialogId = data.dialogId;
      akioma.socket.emit('room', { action: 'join', name: data.dialogId, user: app.sessionData.userKey });
    },
    error: function(xhr, textStatus, errorThrown) {
      akioma.log.error(`Error initializing Dialog: ${textStatus} -> ${errorThrown}`);
    }
  });

  Mousetrap.prototype.stopCallback = function() {
    return false;
  };

  let oForm;
  const cKey = 'f1';
  if (oForm)
    Mousetrap(oForm).bind(cKey, () => {});
  else
    Mousetrap.bind(cKey, () => {});
};


akioma.config.executeConfSheet = function(self) {
  if (akioma.config.profiling)
    akioma.config.startTime = performance.now();

  let iCurrPage = 0;
  if (akioma.config.confSheetExecuting == true)
    return;

  iCurrPage = self.container.getFirstChildByType('tabbar').currentPageNum();

  akioma.config.confSheetExecuting = true;
  if (!akioma.config.useNode) {
    const oForm = self.getFirstParentByType('form');
    let cFields = self.parent.getAllValues();
    const aFields = cFields.split('|');
    aFields.forEach(value => {
      const cFieldName = value.split('#')[0];
      cFields = `${cFields}|${cFieldName}_isVisible#LOGICAL#${!oForm.dhx.isItemHidden(cFieldName)}`;
    });

    const oReturn = app.controller.callServerMethod('conf/executeConfSheet.p', [
      { type: 'iCHAR', value: self.container.name },
      { type: 'iCHAR', value: self.getFirstParentByType('form').dynObject.name },
      { type: 'iCHAR', value: self.name },
      { type: 'iINT', value: iCurrPage },
      { type: 'iCHAR', value: self.getValue() },
      { type: 'iTT', value: cFields },
      { type: 'oCHAR', name: 'cStatus' }
    ]);
    akioma.config.confSheetExecuting = false;
    if (akioma.Themes.getTheme() != 'akioma-simple') {
      if (oReturn.cStatus)
        akioma.messaging.info (oReturn.cStatus);
    }
    if (akioma.config.profiling)
      akioma.messaging.info (`roundtrip time:<br>${performance.now() - akioma.config.startTime} ms.` + `<br>Node: ${akioma.config.useNode}`);

  } else {
    const oData = { action: 'dialog', container: self.container.name, viewer: self.parent.name, control: self.name, page: iCurrPage, newValue: self.getValue(), user: app.sessionData.userKey };
    akioma.socket.emit('room', oData);
    akioma.config.confSheetExecuting = false;
  }


};

akioma.config.doWithControl = function(pcContainer, pcViewer, pcControl, callback) {
  if (akioma.numEntries(pcViewer, '|') > 1) {
    pcControl = akioma.entry(2, pcViewer, '|');
    pcViewer = akioma.entry(1, pcViewer, '|');
  }

  /* Currently in the repository the viewers have the container in the name
    * if the backend sends us the names without the container
    * (because in the payload Dataset the TT is without the container), add it here:
    * */
  if (pcViewer.indexOf(pcContainer) != 0)
    pcViewer = `${pcContainer}_${pcViewer}`;

  const oDialog = akioma.config.instance;
  if (!oDialog) {
    console.warn('cannot find dialog instance');
    return;
  }

  const oControl = oDialog.getObject(pcControl);
  const oViewer = oControl.controller.getAncestor('form').dynObject;
  if (!oViewer) {
    akioma.messaging.info(`cannot find Viewer:<br>${pcViewer}`);
    return;
  }

  callback(oViewer, oControl, pcControl, pcViewer);
  return;

};

akioma.config.FlagsDialog_OK = function(self) {
  let oValues;
  const oViewer = self.container.getObject('vFlags');
  if (oViewer)
    oValues = oViewer.getAllValues(null, 'array');

  const cStructHdl = getCallerValue(self, 'selfhdl');
  if (isValidHdl(cStructHdl) == false) {
    akioma.log.error(`cannot get Parent Record: ${self.container.caller.name}`);
    return '';
  }

  let cValue = '';
  for (const i in oValues) {
    const temp = oValues[i].split('#');
    if (!(temp[2] == 'no' || temp[2] == 'false')) {
      if (cValue)
        cValue += ' ';
      cValue += temp[0];
    }
  }

  cValue = akioma.stringReplace(cValue, 'AttributeFlags_input_', '');
  self.container.caller.setValue(cValue);

  self.container.controller.close();
};

akioma.config.FlagsDialog_Init = function(self) {
  const cStructHdl = getCallerValue(self, 'selfhdl');
  if (isValidHdl(cStructHdl) == false) {
    akioma.log.error(`cannot get Parent Record: ${self.container.caller.name}`);
    return '';
  }

  const oViewer = self.getObject('vFlags');
  const oRadioset = oViewer.getObject('powerGrid_input_');
  if (oRadioset)
    oViewer.setValue('powerGrid_input_', '61');
  else {
    const cValue = self.caller.getValue();
    const oValues = cValue.split(' ');
    for (const i in oValues) {
      if (oValues[i] && oValues[i] != '')
        oViewer.setValue(`AttributeFlags_input_${oValues[i]}`, true);
    }
  }

  return;
};


akioma.config.showAttributes = function(self) {
  const oReturn = app.controller.callServerMethod('conf/getProperties.p', [
    { type: 'iCHAR', value: self.container.name },
    { type: 'iCHAR', value: '' },
    { type: 'iCHAR', value: '' },
    { type: 'iTT', value: self.parent.getAllValues() },
    { type: 'oCHAR', name: 'cStatus' }
  ]);

  akioma.messaging.info (oReturn.cStatus);
};


akioma.config.valueChanged = function(self) {
  akioma.config.executeConfSheet(self);
  return;
};

akioma.config.rowSelected = function(self) {
  akioma.config.executeConfSheet(self);
  return;
};

akioma.config.rowChosen = function() {};
akioma.config.endDialog = function() {};

akioma.config.leave = function(self) {
  akioma.config.executeConfSheet(self);
  return;
};

akioma.config.showHelp = function(self) {
  let oControl = null;
  const cViewer = 'vLog'; // "cdv_sample1_intro1";

  const oDialog = self.container; // akioma.config.instance;
  if (!oDialog) {
    console.warn('cannot find Dialog instance');
    return;
  }

  oControl = oDialog.getLink('PRIMARYSDO:TARGET');
  console.warn([ 'oControl', oControl, cViewer ]);
  if (!oControl) {
    console.warn(`cannot find control ${cViewer}`);
    return;
  }

  oControl.controller.reloadData();
  return;
};

akioma.config.buildConfScreen_init = function() {};

akioma.config.buildConfScreen = function(oSelf) {
  const oForm = oSelf.getLink('TOOLBAR:TARGET');

  let lcOfferHdl = getCallerValue2(oSelf, 'selfhdl');

  let cStructHdl = '';
  let oTree = null;

  if (!isValidHdl(lcOfferHdl)) {
    akioma.showMessage(`Datensatz konnte nicht ermittelt werden für: ${oSelf.caller.container.name}`, 'alert-error');
    return;
  }

  try {
    oTree = oSelf.container.caller.container.getObject('itTree');
    if (oTree) {
      cStructHdl = oTree.getValue('selfhdl');
      if (isValidHdl(cStructHdl))
        lcOfferHdl = `${lcOfferHdl},${cStructHdl}`;
    }
  } catch (err) {
    akioma.log.error(`error getting Tree-Node: ${err.message}`);
  }

  app.controller.callServerMethod('mod/conf/exportScreen.p', [
    { type: 'iCHAR', value: lcOfferHdl },
    { type: 'iCHAR', value: true },
    { type: 'iTT', value: oForm.getAllValues() },
    { type: 'oCHAR', name: 'creturn' },
    { type: 'oCHAR', name: 'cstatus' }
  ], true);

  oSelf.container.controller.close();
};


akioma.config.hideControl = function(pcContainer, pcViewer) {
  akioma.config.doWithControl(pcContainer, pcViewer, '', (oViewer, oControl, cControl) => {
    if (!oControl) {
      console.error(`cannot find dialog control:<br>${pcViewer}`);
      return;
    } else {
      oViewer.controller.dhx.hideItem(cControl);
      oViewer.controller.dhx.hideItem(cControl.toLowerCase());
    }
  });
  return;
};


akioma.config.showControl = function(pcContainer, pcViewer) {
  akioma.config.doWithControl(pcContainer, pcViewer, '', (oViewer, oControl, cControl) => {
    if (!oControl) {
      akioma.messaging.info(`cannot find dialog control:<br>${pcViewer}`);
      return;
    } else {
      oViewer.controller.dhx.showItem(cControl);
      oViewer.controller.dhx.showItem(cControl.toLowerCase());
    }
  });
  return;
};

akioma.config.setNote = function(pcContainer, pcViewer, pcText) {
  akioma.config.doWithControl(pcContainer, pcViewer, '', (oViewer, oControl, cControl) => {
    if (!oControl) {
      akioma.messaging.info(`cannot find dialog control:<br>${pcViewer}`);
      return;
    } else if (!pcText) {
      oViewer.controller.dhx.clearNote(cControl);
      oViewer.controller.dhx.clearNote(cControl.toLowerCase());
    } else {
      oViewer.controller.dhx.setNote(cControl, { text: pcText });
      oViewer.controller.dhx.setNote(cControl.toLowerCase(), { text: pcText });
    }
  });
};

akioma.config.setMandatory = function(pcContainer, pcViewer, plMandatory) {
  akioma.config.doWithControl(pcContainer, pcViewer, '', (oViewer, oControl, cControl) => {
    if (!oControl) {
      akioma.messaging.info(`cannot find dialog control:<br>${pcViewer}`);
      return;
    } else {
      oViewer.controller.dhx.setRequired(cControl, plMandatory);
      oViewer.controller.dhx.setRequired(cControl.toLowerCase(), plMandatory);
    }
  });
};

akioma.config.setSelected = function(pcContainer, pcViewer, pcOption) {
  akioma.config.doWithControl(pcContainer, pcViewer, '', (oViewer, oControl) => {
    if (!oControl) {
      akioma.messaging.info(`cannot find dialog control:<br>${pcViewer}`);
      return;
    } else
      oControl.controller.setValue(pcOption);
  });
  return;
};

akioma.config.setValidation = function(pcContainer, pcViewer, pcValidation) {
  akioma.config.doWithControl(pcContainer, pcViewer, '', (oViewer, oControl, cControl) => {
    if (!oControl) {
      akioma.messaging.info(`cannot find dialog control:<br>${pcViewer}`);
      return;
    } else if (!pcValidation) {
      oViewer.controller.dhx.clearValidation(cControl);
      oViewer.controller.dhx.clearValidation(cControl.toLowerCase());
    } else {
      oViewer.controller.dhx.setValidation(cControl, pcValidation);
      oViewer.controller.dhx.setValidation(cControl.toLowerCase(), pcValidation);
    }
  });
};

akioma.config.enablePage = function(pcContainer, piPage) {
  akioma.config.doWithControl(pcContainer, 'itFolder', '', (oTabbar, oControl) => {
    if (!oControl) {
      akioma.messaging.info(`cannot find dialog page:<br>${piPage}`);
      return;
    } else
      oTabbar.controller.dhx.tabs(oTabbar.controller.dhx.getAllTabs()[piPage - 1]).enable();
  });
};

akioma.config.disablePage = function(pcContainer, piPage) {
  akioma.config.doWithControl(pcContainer, 'itFolder', '', (oTabbar, oControl) => {
    if (!oControl) {
      akioma.messaging.info(`cannot find dialog page:<br>${piPage}`);
      return;
    } else
      oTabbar.controller.dhx.tabs(oTabbar.controller.dhx.getAllTabs()[piPage - 1]).disable();
  });
};

akioma.config.showPage = function(pcContainer, piPage) {
  akioma.config.doWithControl(pcContainer, 'itFolder', '', (oTabbar, oControl) => {
    if (!oControl) {
      akioma.messaging.info(`cannot find dialog page:<br>${piPage}`);
      return;
    } else
      oTabbar.controller.dhx.tabs(oTabbar.controller.dhx.getAllTabs()[piPage - 1]).show();
  });
};

akioma.config.hidePage = function(pcContainer, piPage) {
  akioma.config.doWithControl(pcContainer, 'itFolder', '', (oTabbar, oControl) => {
    if (!oControl) {
      akioma.messaging.info(`cannot find dialog page:<br>${piPage}`);
      return;
    } else
      oTabbar.controller.dhx.tabs(oTabbar.controller.dhx.getAllTabs()[piPage - 1]).hide();
  });
};


akioma.config.setUIvalue = function(pcContainer, pcViewer, pcValue) {
  if (akioma.config.profiling)
    akioma.messaging.info (`roundtrip time:<br>${performance.now() - akioma.config.startTime} ms.` + `<br>Node: ${akioma.config.useNode}`);

  akioma.config.doWithControl(pcContainer, pcViewer, '', (oViewer, oControl, cControl) => {
    if (!oControl) {
      console.error(`cannot find dialog control:<br>${pcViewer}`);
      return;
    } else if (cControl.toLowerCase() === 'akdataview')
      oControl.controller.setValue(pcValue);
    else {
      oViewer.setValue(cControl, pcValue);
      oViewer.setValue(cControl.toLowerCase(), pcValue);
    }
  });
};


akioma.config.navNext = function(self) {
  const oDialog = self.container;
  akioma.messaging.info(`Switching to next page ${oDialog.name}`);
};

akioma.config.generateVocabulary = function(self) {
  const dialog = self.dynObject.screen;
  const form = dialog.getFirstChildByType('form');
  const smartObjectMasterStore = dialog.caller.container.getObject('ActiveMasterBE').controller.getStore('eSmartObjectMaster');
  const smartObjectMaster = smartObjectMasterStore.item(smartObjectMasterStore.getCursor());
  const { objectname } = smartObjectMaster;

  akioma.notification({ text: `Generating vocabulary for ${objectname}!`, type: 'info' });
  akioma.invokeServerTask({
    name: 'Akioma.Swat.Corticon.CorticonBT',
    methodName: 'GenerateVocabularyForRepositoryScreen',
    paramObj: {
      plcParameter: {
        ScreenName: objectname,
        StopOnInvalidName: form.getValue('StopOnInvalidName') == 'yes',
        VocabularyType: form.getValue('VocabularyStrategy')
      }
    },
    showWaitCursor: true,
    uiContext: dialog
  }).done(() => {
    dialog.controller.close();
    akioma.notification({ text: `Vocabulary has been generated successfully for ${objectname}!`, type: 'success' });
  }).fail(() => {
    akioma.notification({ text: `There was an issue generating vocabulary for ${objectname}!`, type: 'error' });
  });
};

