// ************** winframe ************************

$.extend({
  ak_winframe: function(options) {

    this.opt = $.extend({}, options.att);

    this.registerVuexModule = true;
    this.registerDynObject = true;
    this.windowContainer = true;

    akioma.windowParent = this;

    // create windows frame
    let oWindows;
    const oParent = this.opt.parent;
    if (oParent) {
      oWindows = new dhtmlXWindows({
        image_path: oDhx.imagePath,
        skin: oDhx.skin,
        parent: this.opt.parentDOMID || oParent.dhx.cell[0]
      });
      akioma.mainWindows = oWindows;
    } else {
      oWindows = new dhtmlXWindows({
        image_path: oDhx.imagePath,
        skin: oDhx.skin
      });

      $('body').attr('id', 'main');
      akioma.mainWindows = oWindows;
    }

    oWindows.attachEvent('onFocus', win => {

      akioma.swat.MasterLayout.disableLastFocusTrap();
      akioma.swat.MasterLayout.lastActiveWindow = win;
      akioma.swat.MasterLayout.enableLastFocusTrap();

      this.focusWindow(win);
      this._onFocusTaskbarSelect(win);

    });

    // isolate dom node
    const oDom = oWindows.vp;
    oDom.akElm = this;

    // extend object with internal settings
    $.extend(this, {
      parent: null,
      dhx: oWindows,
      dom: oDom
    });
  }
});

// methods for winframe
$.ak_winframe.prototype = {

  // focus window ****************
  focusWindow: function() {
    this.dhx.forEachWindow(win => {

      const cId = win.getId();

      if (win.isOnTop() && akioma.dataViewTabs && akioma.dataViewTabs.exists(cId)) {
        akioma.dataViewTabs.select(cId);
        if (!akioma.toggleFlag)
          akioma.iActiveDataViewIndex = akioma.dataViewTabs.indexById(cId);
      }
    });
  },
  _fitInWin: function(oSelf, id) {
    try {
      const oWin = oSelf.dhx.window(id),
        aDim = oWin.getDimension();

      if (aDim[0] > this.dhx.vp.offsetWidth)
        aDim[0] = this.dhx.vp.offsetWidth;
      if (aDim[1] > this.dhx.vp.offsetHeight)
        aDim[1] = this.dhx.vp.offsetHeight;

      oWin.setDimension(aDim[0], aDim[1]);
      oWin.setPosition(0, 0);

      this.dhx.vp.scrollTop = 0;
    } catch (e) {
      console.warn('Error in _fitInWin method ', e);
    }

  },
  /**
   * Method executed on a focus event on a window for selecting the taskbar
   * @param {dhtmlXWindows} win Window object
   * @instance
   * @private
   * @memberof ak_winframe
   */
  _onFocusTaskbarSelect(win) {
    const bContainsTaskbar = app.containsVueTaskbarHeader;

    if (bContainsTaskbar && akioma.VuexStore && win.akElm) {
      akioma.VuexStore.dispatch('taskbar/selectItem', win.akElm.opt.id);
      if (!win.akElm.disableFocusSelect) {
        const item = akioma.VuexStore.getters['taskbar/getItemById'](win.akElm.opt.id);
        akioma.VueTaskbar.config.globalProperties.$events.$emit('taskbar:selectItem', item);
      }
    }
  },
  denyResizeChildren: function() {
    for (let i = 0; i < this.childs.length; i++)
      this.dhx.window(this.childs[i].opt.id).denyResize();

  },
  allowResizeChildren: function() {
    for (let i = 0; i < this.childs.length; i++)
      this.dhx.window(this.childs[i].opt.id).allowResize();

  },
  newDesktopIcon: function() {},
  destroy: function() {}
};
