/**
 * The ModalMessage object, created for handling the Notifications (Non-modal) top right messages
 * @class ModalMessage
 * @namespace akioma
 */
akioma.ModalMessage = class {
  /**
     * Displays a Modal message to the user
     * @param {object} par
 * @returns {Promise}
 * @static
     * @memberOf ModalMessage
     */
  static message(par) {
    return this.showPopup(par);
  }

  /**
     * Displays a Modal message to the user based on type
     * @param {object} par
 * @returns {Promise}
 * @static
     * @memberOf ModalMessage
     */
  static showPopup(par) {

    let cType = 'error';
    if (par.type !== undefined)
      cType = par.type.split('-')[0].toLowerCase();

    switch (cType) {
      case 'success':
        return this.successPopup(par);
      case 'alert':
        return this.alertPopup(par);
      case 'error':
        return this.alertPopup(par);
      case 'confirm':
      case 'question':
        return this.confirmPopup(par);
      case 'input':
        return this.inputPopup(par);
      case 'information':
      case 'info':
        return this.informationPopup(par);
      case 'warning':
        return this.warningPopup(par);
      case 'custom':
        return this.customPopup(par);
      default:
        return this.alertPopup(par);
    }
  }

  /**
     * Set more button to open an modal inner window
 *
 * @static
 * @param {Object} par
 * @param {HTMLElement} content
 * @returns {void}
     * @memberOf ModalMessage
 */
  static setMoreButton(par, content, oOptions) {
    const uniqueMoreID = `morebtn-${dhtmlx.uid()}`;
    if (Array.isArray(oOptions.buttons))
      $(content).closest('.swal-modal').find('.swal-button--confirm').attr('id', uniqueMoreID);
    else
      $(content).closest('.swal-modal').find('.swal-button--more').attr('id', uniqueMoreID);


    akioma.NotificationMessage.attachMoreWindow(par, uniqueMoreID);
  }

  /**
 * Show the coresponding buttons based on params
 *
 * @static
 * @param {Object} par
 * @param {Object} oOptions
 * @param {Object} buttons
 * @returns {void}
     * @memberOf ModalMessage
 */
  static showButtons(par, oOptions, buttons) {
    const moreButton = { text: akioma.tran('messageBox.button.more', { defaultValue: 'More' }), value: 'More' };

    if (par.moretext && par.buttons) {
      oOptions.buttons = par.buttons;
      if (Array.isArray(par.buttons)) {
        // on buttons array max buttons is 2
        if (par.buttons.length >= 2) {

          // create an object with the buttons
          const buttonsObject = {};
          for (let index = 0; index < par.buttons.length; index++) {
            buttonsObject[par.buttons[index]] = {
              text: par.buttons[index],
              value: par.buttons[index]
            };
          }
          // add the more button
          buttonsObject.more = moreButton;

          // turn array into object
          oOptions.buttons = Object.assign({}, oOptions.buttons);
          oOptions.buttons = buttonsObject;
        } else
          oOptions.buttons.push(moreButton.text);

      } else
        oOptions.buttons.more = moreButton;

    } else if (par.moretext) {
      oOptions.buttons = buttons;
      oOptions.buttons.more = moreButton;
    } else if (par.buttons)
      oOptions.buttons = par.buttons;
    else
      oOptions.buttons = buttons;

  }

  /**
     * Displays an alert Modal message to the user
 *
 * @static
 * @param {Object} par
 * @returns {Promise}
     * @memberOf ModalMessage
 */
  static alertPopup(par) {
    const content = document.createElement('p');

    par.text = replaceNewLines(par.text);

    content.innerHTML = par.text;

    const oOptions = {
      title: par.title || akioma.tran('messageBox.title.error', { defaultValue: 'Error' }),
      content: content,
      className: 'errorModal'
    };

    const buttons = {
      confirm: {
        text: par.buttonText || akioma.tran('messageBox.button.ok', { defaultValue: 'Ok' }),
        value: true
      }
    };

    this.showButtons(par, oOptions, buttons);

    if (par.closeOnEsc !== undefined)
      oOptions.closeOnEsc = par.closeOnEsc;

    if (par.closeOnClickOutside !== undefined)
      oOptions.closeOnClickOutside = par.closeOnClickOutside;

    const popupPromise = swal(oOptions).then(value => {
      if (par.callback)
        par.callback(value);

    });

    if (par.moretext)
      this.setMoreButton(par, content, oOptions);


    return popupPromise;
  }


  /**
     * Displays an warning Modal message to the user
 *
 * @static
 * @param {Object} par
 * @returns {Promise}
     * @memberOf ModalMessage
 */
  static warningPopup(par) {
    const oCancel = {
      text: akioma.tran('messageBox.button.cancel', { defaultValue: 'Cancel' }),
      value: null,
      visible: true,
      closeModal: true
    };
    const content = document.createElement('p');

    par.text = replaceNewLines(par.text);

    content.innerHTML = par.text;

    const oOptions = {
      title: par.title || akioma.tran('messageBox.title.warning', { defaultValue: 'Warning' }),
      content: content,
      className: 'warningModal'
    };

    const buttons = {
      confirm: {
        text: par.buttonText || akioma.tran('messageBox.button.ok', { defaultValue: 'Ok' }),
        value: true
      },
      cancel: oCancel
    };

    this.showButtons(par, oOptions, buttons);

    if (par.closeOnEsc !== undefined)
      oOptions.closeOnEsc = par.closeOnEsc;

    if (par.closeOnClickOutside !== undefined)
      oOptions.closeOnClickOutside = par.closeOnClickOutside;

    const popupPromise = swal(oOptions).then(value => {
      if (par.callback)
        par.callback(value);
    });

    if (par.moretext)
      this.setMoreButton(par, content, oOptions);


    return popupPromise;
  }


  /**
     * Displays an success Modal message to the user
 *
 * @static
 * @param {Object} par
 * @returns {Promise}
     * @memberOf ModalMessage
 */
  static successPopup(par) {
    const content = document.createElement('p');

    par.text = replaceNewLines(par.text);

    content.innerHTML = par.text;

    const oOptions = {
      title: par.title || akioma.tran('messageBox.title.success', { defaultValue: 'Success' }),
      content: content,
      className: 'successModal'
    };

    const buttons = {
      ok: {
        text: par.buttonText || akioma.tran('messageBox.button.ok', { defaultValue: 'Ok' }),
        value: true
      }
    };

    this.showButtons(par, oOptions, buttons);

    if (par.closeOnEsc !== undefined)
      oOptions.closeOnEsc = par.closeOnEsc;

    if (par.closeOnClickOutside !== undefined)
      oOptions.closeOnClickOutside = par.closeOnClickOutside;

    const popupPromise = swal(oOptions).then(value => {
      if (par.callback)
        par.callback(value);
    });

    if (par.moretext)
      this.setMoreButton(par, content, oOptions);


    return popupPromise;
  }

  /**
     * Displays an information Modal message to the user
 *
 * @static
 * @param {Object} par
 * @returns {Promise}
     * @memberOf ModalMessage
 */
  static informationPopup(par) {
    const content = document.createElement('p');

    par.text = replaceNewLines(par.text);

    content.innerHTML = par.text;

    const oOptions = {
      title: par.title || akioma.tran('messageBox.title.info', { defaultValue: 'Info' }),
      content: content,
      className: 'informationModal'
    };

    const buttons = {
      confirm: {
        text: par.buttonText || akioma.tran('messageBox.button.ok', { defaultValue: 'Ok' }),
        value: true
      }
    };

    this.showButtons(par, oOptions, buttons);

    if (par.closeOnEsc !== undefined)
      oOptions.closeOnEsc = par.closeOnEsc;

    if (par.closeOnClickOutside !== undefined)
      oOptions.closeOnClickOutside = par.closeOnClickOutside;

    const popupPromise = swal(oOptions).then(value => {
      if (par.callback)
        par.callback(value);
    });

    if (par.moretext)
      this.setMoreButton(par, content, oOptions);


    return popupPromise;
  }

  /**
     * Displays an confirm Modal message to the user
 *
 * @static
 * @param {Object} par
 * @returns {Promise}
     * @memberOf ModalMessage
 */
  static confirmPopup(par) {

    const question = (par.type.indexOf('question') !== -1) ? true : false;

    // cancel button props
    const oCancel = {
      text: (question) ? akioma.tran('messageBox.button.no', { defaultValue: 'No' }) : akioma.tran('messageBox.button.cancel', { defaultValue: 'Cancel' }),
      value: null,
      visible: true,
      closeModal: true
    };

    const content = document.createElement('p');
    const defaultTitle = (question) ? akioma.tran('messageBox.title.question', { defaultValue: 'Question' }) : akioma.tran('messageBox.title.confirm', { defaultValue: 'Confirm' });
    const defaultBtn = (question) ? akioma.tran('messageBox.button.yes', { defaultValue: 'Yes' }) : akioma.tran('messageBox.button.confirm', { defaultValue: 'Confirm' });

    par.text = replaceNewLines(par.text);

    content.innerHTML = par.text;

    const oOptions = {
      dangerMode: par.dangerMode || false,
      title: par.title || defaultTitle,
      content: content,
      className: 'confirmModal'
    };

    const buttons = {
      confirm: {
        text: par.buttonText || defaultBtn,
        value: true
      },
      cancel: oCancel
    };

    this.showButtons(par, oOptions, buttons);

    if (par.closeOnEsc !== undefined)
      oOptions.closeOnEsc = par.closeOnEsc;

    if (par.closeOnClickOutside !== undefined)
      oOptions.closeOnClickOutside = par.closeOnClickOutside;


    const popupPromise = swal(oOptions).then(value => {
      if (par.callback)
        par.callback(value);
    });

    if (par.moretext)
      this.setMoreButton(par, content, oOptions);


    return popupPromise;
  }

  /**
     * Displays an input Modal message to the user
 *
 * @static
 * @param {Object} par
 * @returns {Promise}
     * @memberOf ModalMessage
 */
  static inputPopup(par) {

    // cancel button props
    const oCancel = {
      text: akioma.tran('messageBox.button.cancel', { defaultValue: 'Cancel' }),
      value: null,
      visible: true,
      closeModal: true
    };

    par.text = replaceNewLines(par.text);

    const oOptions = {
      title: par.title || akioma.tran('messageBox.title.input', { defaultValue: 'Input' }),
      text: par.text,
      className: 'inputModal',
      content: {
        element: 'input',
        attributes: { value: par.inputVal || '' }
      },
      buttons: par.buttons || {
        confirm: {
          text: par.buttonText || akioma.tran('messageBox.button.ok', { defaultValue: 'Ok' }),
          value: true
        },
        cancel: oCancel
      }
    };

    if (par.closeOnEsc !== undefined)
      oOptions.closeOnEsc = par.closeOnEsc;

    if (par.closeOnClickOutside !== undefined)
      oOptions.closeOnClickOutside = par.closeOnClickOutside;

    const popupPromise = swal(oOptions).then(value => {
      if (value)
        par.callback(value);
      else
        par.callback(false);
    });

    return popupPromise;
  }

  /**
     * Displays an custom Modal message to the user
 *
 * @static
 * @param {Object} par
 * @returns {Promise}
     * @memberOf ModalMessage
 */
  static customPopup(par) {

    const oCancel = {
      text: akioma.tran('messageBox.button.cancel', { defaultValue: 'Cancel' }),
      value: null,
      visible: true,
      closeModal: true

    };

    const oOptions = {
      title: par.title,
      content: par.content,
      className: 'customModal',
      buttons: par.buttons || {
        confirm: {
          text: par.buttonText || akioma.tran('messageBox.button.ok', { defaultValue: 'Ok' }),
          value: true
        },
        cancel: oCancel
      }
    };

    if (par.closeOnEsc !== undefined)
      oOptions.closeOnEsc = par.closeOnEsc;

    if (par.closeOnClickOutside !== undefined)
      oOptions.closeOnClickOutside = par.closeOnClickOutside;

    const popupPromise = swal(oOptions).then(value => {
      if (value)
        par.callback(value);
      else
        par.callback(false);
    });

    return popupPromise;
  }
};
