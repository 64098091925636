akioma.handlebarsTemplates = {
  // for dataview
  GenericTemplate: function(aGenericKeys) {
    return `${'<table border=\'0\' width=\'193\' cellpadding=\'0\' cellspacing=\'0\' margin-bottom=\'2px\' style=\'box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)\'>' +
                     '<tr><td width=\'100%\' height=\'40\' class=\'genericItem\'><div>{{#iconHelper '}${aGenericKeys[0]} '${aGenericKeys[1]}' this}}{{/iconHelper}}</div></td></tr>` +
                     '</table>';
  },

  // for dynlookup search results
  GenericAutocompleteSearchTemplate: function(aGenericKeys) {
    // aGenericKeys =
    // _id, param for icon, param for text col 1, param for text col 2
    // console.log('generic array: ', aGenericKeys);
    let imgText = '';
    if (aGenericKeys['img'] != '') {
      if (aGenericKeys['img'].startsWith('fa '))
        imgText = `{{#iconHelper "${aGenericKeys['img']}" ${aGenericKeys['key']} this}}{{/iconHelper}}`;
      else
        imgText = `{{#iconHelper ${aGenericKeys['img']} ${aGenericKeys['key']} this}}{{/iconHelper}}`;
    } else
      imgText = `{{#printCombinedFields "${aGenericKeys['key']}" this}}{{/printCombinedFields}}`;

    return `${'<div class="results-container" style="max-height:400px;overflow: auto;">' +
                    '{{#each results}}' +
                        '<div class="searchItem" dynrowid="{{'}${aGenericKeys['id']}}}" id="{{this.value}}">` +
                            '<div style="display:flex;width:300px;">' +
                                `<div class="grid2Search1" style="flex-basis:100%;"> ${
                                  imgText
                                }</div>` +
                            '</div>' +
                            '<div style="display:flex;width:300px;">' +
                                `<div class="grid2Search2" style="flex-basis:100%;">{{#printCombinedFields "${aGenericKeys['desc']}" this}}{{/printCombinedFields}}</div>` +
                            '</div>' +
                        '</div>' +
                    '{{else}}' +
                        ' <span class="empty">No results found.</span>' +
                    '{{/each}}' +
                '</div>';
  },
  /**
   * Template for grid dynselect in panel header menu, for the filter manager
   * @param {array} aGenericKeys Array of fields to use for the templateOptions attribute
   * @param {object} record The record for which the template is compiled
   * @param {ak_dynSelect} dynSelect The dynselect control object
   */
  GridFilterDynselectSearchTemplate: (aGenericKeys, record, dynSelect) => {
    const currentFilterHdl = dynSelect.dynSelectControl.self.oTarget.FilterManager.getCurrentFilter();
    let iconVal = 'fad fa-filter';
    if (record.isdefaultfilter) {
      // default filter, set icon: fa-check-double
      iconVal = 'fad fa-check-double';
    } else if (record.filterhdl === currentFilterHdl) {
      // currently selected filter icon: fa-check
      iconVal = 'fad fa-check';
    }

    const imgText = `{{#iconHelper "${iconVal}" "${aGenericKeys['key']}" this}}{{/iconHelper}}`;

    return `${'<div class="results-container">' +
              '<div class="searchItem">' +
                  '<div style="display:flex;">' +
                      '<div class="grid2Search1" style="flex-basis:100%;">'}${
      imgText
    }</div>` +
                  '</div>' +
                  '<div style="display:flex;">' +
                      `<div class="grid2Search2" style="flex-basis:100%;">{{#printCombinedFields "${aGenericKeys['desc']}" this}}{{/printCombinedFields}}</div>` +
                  '</div>' +
              '</div>' +
            '</div>';
  },
  // for dynSelect search results
  GenericAutocompleteSearchTemplateDynSelect: function(aGenericKeys) {
    // aGenericKeys =
    // _id, param for icon, param for text col 1, param for text col 2
    // console.log('generic array: ', aGenericKeys);
    let imgText = '';
    if (aGenericKeys['img'] != undefined && aGenericKeys['img'] != '') {
      if (aGenericKeys['img'].startsWith('fa'))
        imgText = `{{#iconHelper "${aGenericKeys['img']}" "${aGenericKeys['key']}" this}}{{/iconHelper}}`;
      else
        imgText = `{{#iconHelper ${aGenericKeys['img']} "${aGenericKeys['key']}" this}}{{/iconHelper}}`;
    } else if (aGenericKeys['key'] != undefined)
      imgText = `{{#printCombinedFields "${aGenericKeys['key']}" this}}{{/printCombinedFields}}`;

    return `${'<div class="results-container">' +
                        '<div class="searchItem">' +
                            '<div style="display:flex;">' +
                                '<div class="grid2Search1" style="flex-basis:100%;"> '}${
      imgText
    }</div>` +
                            '</div>' +
                            '<div style="display:flex;">' +
                                `<div class="grid2Search2" style="flex-basis:100%;">{{#printCombinedFields "${aGenericKeys['desc']}" this}}{{/printCombinedFields}}</div>` +
                            '</div>' +
                        '</div>' +
                '</div>';
  },
  // for dynSelect search results
  GenericAutocompleteSearchTemplateDynSelect_checkbox: function(aGenericKeys) {
    let imgText = '';
    if (aGenericKeys['img'] != undefined && aGenericKeys['img'] != '') {
      if (aGenericKeys['img'].startsWith('fa'))
        imgText = `{{#iconHelper "${aGenericKeys['img']}" "${aGenericKeys['key']}" this}}{{/iconHelper}}`;
      else
        imgText = `{{#iconHelper ${aGenericKeys['img']} "${aGenericKeys['key']}" this}}{{/iconHelper}}`;
    } else if (aGenericKeys['key'] != undefined)
      imgText = `{{#printCombinedFields "${aGenericKeys['key']}" this}}{{/printCombinedFields}}`;

    return `${'<div class="results-container type-checkbox">' +
                '<div class="searchItem">' +
                    '<span class="grid2Search1">'}${imgText}</span>` +
                '</div>' +
        '</div>';
  },

  // for dynSelect search results
  GenericAutocompleteSearchTemplateDynSelect_line: function(aGenericKeys) {
    // aGenericKeys =
    // _id, param for icon, param for text col 1, param for text col 2
    // console.log('generic array: ', aGenericKeys);
    let imgText = '';
    let keySize = '280';
    if (aGenericKeys['img'] != undefined && aGenericKeys['img'] != '') {
      if (aGenericKeys['img'].startsWith('fa'))
        imgText = `{{#iconHelper "${aGenericKeys['img']}" "${aGenericKeys['key']}" this}}{{/iconHelper}}`;
      else
        imgText = `{{#iconHelper ${aGenericKeys['img']} "${aGenericKeys['key']}" this}}{{/iconHelper}}`;
    } else if (aGenericKeys['key'] != undefined)
      imgText = `{{#printCombinedFields "${aGenericKeys['key']}" this}}{{/printCombinedFields}}`;

    if (aGenericKeys['settings']) {
      aGenericKeys['settings'].forEach(setting => {
        const parts = setting.split('=');
        if (parts[0] == 'KeyFieldWidth')
          keySize = parts[1];

      });
    }
    return `${'<div class="results-container">' +
              '<div class="searchItem">' +
                '<div style="display:flex;">' +
                    '<div class="grid2Search2" style="display:flex;flex-basis:100%;padding-top:2px;padding-bottom:2px;"> ' +
                        '<span style="line-height: 1.2em;font-weight:bold;display: block;overflow: hidden; min-width: '}${keySize}px;flex-basis: ${keySize}px;white-space: nowrap;text-overflow: ellipsis;">${imgText}</span> <span style="line-height: 1.2em;">{{#printCombinedFields "${aGenericKeys['desc']}" this}}{{/printCombinedFields}}</span>` +
                    '</div>' +
                  '</div>' +
              '</div>' +
            '</div>';
  },

  DynSelectGridTemplate: function(item) {
    if (item.listItemDesc == item.listItemShortDesc) {
      return '<div class="searchItem">' +
                '<div style="display:flex;">' +
                  '<div class="grid2Search1" style="flex-basis:100%;">{{this.listItemDesc}}</div>' +
                '</div>' +
              '</div>';
    } else {
      return '<div class="searchItem">' +
                '<div style="display:flex;">' +
                  '<div class="grid2Search1" style="flex-basis:100%;font-weight:bold">{{this.listItemShortDesc}}</div>' +
                '</div>' +
                '<div style="display:flex;">' +
                  '<div class="grid2Search2" style="flex-basis:100%;color:rgba(128,128,128,0.8)"> {{this.listItemDesc}}</div>' +
                '</div>' +
              '</div>';
    }
  },
  autocompleteSearchBT: {
    templateResults: '<div class="results-container" style="max-height:400px;overflow: auto;">' +
                        '{{#each results}}' +
                          '<div class="searchItem" id="{{this.value}}">' +
                            '<div style="display:flex;width:300px;">' +
                              '<div class="grid2Search1" style="flex-basis:100%;">{{#iconHelper this.img this.key this}}{{/iconHelper}}</div>' +
                            '</div>' +
                            '<div style="display:flex;width:300px;">' +
                              '<div class="grid2Search2" style="flex-basis:100%;"> {{this.desc}}</div>' +
                            '</div>' +
                          '</div>' +
                        '{{else}}' +
                          ' <span class="empty">None</span>' +
                        '{{/each}}' +
                      '</div>',
    dynLookupTemplateResults: '<div class="results-container" style="max-height:400px;overflow: auto;">' +
                                '{{#each results}}' +
                                  '<div class="searchItem" dynrowid="{{this._id}}" id="{{this.value}}">' +
                                    '<div style="display:flex;width:300px;">' +
                                      '<div class="grid2Search1" style="flex-basis:100%;"> {{this.city}}</div>' +
                                    '</div>' +
                                    '<div style="display:flex;width:300px;">' +
                                      '<div class="grid2Search2" style="flex-basis:100%;"> {{this.name1}}</div>' +
                                    '</div>' +
                                  '</div>' +
                                '{{else}}' +
                                  ' <span class="empty">None</span>' +
                                '{{/each}}' +
                              '</div>',
    templateMenu: '<div class="footer-container" style="max-height:400px;overflow: auto;">' +
                    '{{#each results}}' +
                      '<div class="footer-item" style="display:flex;width:100%;">' +
                        '<div class="footer-add" id="{{this.id}}" style="flex-basis:100%;">{{#iconHelper this.img this.text this}}{{/iconHelper}}</div>' +
                      '</div>' +
                    '{{/each}}' +
                  '</div>',
    icons: {
      normalIcons: '<img src=\'/dhtmlx/imgs/#icon1#\' border=\'0\'>',
      fontIcons: '<i class=\'#icon1# fa-fw #moduleClass#\' style=\'#iconStyle#\'></i>'
    },
    icons_text_right: {
      normalIcons: '<div><img src=\'/dhtmlx/imgs/#icon1#\' border=\'0\'><span>#title#</span></div>',
      fontIcons: '<div class=\'fa-stack fontIconsStacked #stackedIcon#\'><i class=\'#icon1# #size# fa-fw #moduleClass1# fontIcon1\' style=\'#iconStyle1#\'></i>' +
                '<i class=\'#icon2# fa-stack-1x fa-inverse #moduleClass2# fontIcon2\' style=\'#iconStyle2#\'></i></div><span>#title#</span>'
    }
  },
  dynSelectTemplates: {
    gridFilterTemplate: '<div class="searchItem">' +
                          '<div style="display:flex;width:300px;">' +
                            '<div class="grid2Search1" style="flex-basis:100%;font-weight:bold">{{this.shortKey}}</div>' +
                          '</div>' +
                          '<div style="display:flex;width:300px;">' +
                            '<div class="grid2Search2" style="flex-basis:100%;color:rgba(128,128,128,0.8)"> {{this.key}}</div>' +
                          '</div>' +
                        '</div>'
  }
};


Handlebars.registerHelper('printCombinedFields', (options, context) => {

  const aFields = options.split(',');

  let cResultMerge = '';

  for (let i = 0; i < aFields.length; i++) {
    const cCurrentFieldVal = aFields[i];

    if (cCurrentFieldVal.indexOf('\'') > -1)
      cResultMerge += cCurrentFieldVal.replace(/["']/g, '');
    else if (context[cCurrentFieldVal] != undefined)
      cResultMerge += context[cCurrentFieldVal];


  }
  // Return your results...
  return akioma.encodeHTML(cResultMerge);
});

Handlebars.registerHelper('iconHelper', (image, text, context) => {

  const template = akioma.handlebarsTemplates.autocompleteSearchBT['icons_text_right'];

  // handle constant value for icon
  if (image && image.indexOf('\'') > -1)
    image = image.replace(/["']/g, '');


  // for multifields type build text from array of keys
  if (typeof (text) == 'string') {
    const aFieldsKey = text.split(',');

    let cResultMerge = '';

    for (let i = 0; i < aFieldsKey.length; i++) {
      const cCurrentFieldVal = aFieldsKey[i];

      if (cCurrentFieldVal.indexOf('\'') > -1)
        cResultMerge += cCurrentFieldVal.replace(/["']/g, '');
      else if (context[cCurrentFieldVal] != undefined)
        cResultMerge += context[cCurrentFieldVal];
      else
        cResultMerge += cCurrentFieldVal;


    }
    text = cResultMerge;
  } else
    text = '';


  // then we have an image file .png, .jpg
  if (image) {

    let cTemplate = '';
    const iconObject = akioma.icons.getIconType(image);
    if (iconObject.type == 'normal-icon')
      cTemplate = template.normalIcons;
    else
      cTemplate = template.fontIcons;

    iconObject.title = text;
    cTemplate = akioma.icons.replaceTemplate(cTemplate, iconObject);
    return cTemplate;

  } else
    return text;

});


/**
 *  Akioma template handlers, we can specify the handlers for each htmlcontent template
 * @property {handlebars} The handlebars namespace for htmlcontent template handlers, initialize methods for computed props
 * @type {Object}
 */
akioma.handlebars = { handlers: {} };

/**
 * Initialization method for Contact Details HTML Content template
 * @param  {ak_form} self The Form parent dynObject
 * @return {object}      New object with computed properties
 */
akioma.handlebars.handlers.contactdetails_initialize = function(self) { // nameoftemplate_initialize

  // console.log([self, 'contactdetails_initialize']);

  // get business Entity data
  const oData = self.controller.getCurrentlySelectedDataStoreItem();
  const oResult = {};

  if (oData !== undefined) {
    // get sex
    if (oData.sex && oData.sex.toLowerCase() == 'm')
      oResult.computed_sex = 'MALE';
    else if (oData.sex && oData.sex.toLowerCase() == 'f')
      oResult.computed_sex = 'FEMALE';

    // get function desc/official function data
    if (oData.officialfunction == '') {
      oResult.computed_func_label = 'Function Desc';
      oResult.computed_function = oData.functiondesc;
    } else {
      oResult.computed_func_label = 'Official Function';
      oResult.computed_function = oData.officialfunction;
    }

    if (oData.islocked)
      oResult.computed_lockedclass = 'active';
    else
      oResult.computed_lockedclass = 'inactive';

  }
  // return the computed properties
  return oResult;
};

Handlebars.registerHelper('ifCond', function(v1, v2, options) {
  if (v1.indexOf(v2) !== -1)
    return options.fn(this);

  return options.inverse(this);
});


// This helper is currently not used but might be useful in the future
// Receives two parameters from the template: self and method.
// Returns a string which will be a call to a JS function which will trigger callAkiomaCode.

// ISSUE: Every parameter needs to be passed to the function as a string. This is not possible for the self object. A solution for objects is to use JSON.stringify(), but it will fail for self due to circular references.
// An alternative would be to send just the name (self.opt.name), and then get the object based on the name. But this would raise issues when having multiple instances of the same object.

Handlebars.registerHelper('callClapiMethod', (method, options) => {
  const self = options.data;
  callClapi = function(selfName, method) {
    const self = akioma.root.dynObject.getObject(selfName);
    app.controller.callAkiomaCode(self, method);
  };
  return `javascript: callClapi('${self.opt.name}','${method}')`;
});

Handlebars.registerHelper('isdefined', value => value !== undefined);
