(function($) {

  // ***************** map ******************
  $.extend({
    /**
     * SwatMap Control
     * @class ak_map
     * @tutorial map-desc
     * @param {Object} options Repository attributes for SwatMap.
     * @param {string} options.BorderTitle The Title of a dynamic Viewer or Browser
     * @param {string} options.EventOnInitialize client side code to run when Container has been initialized
     * @param {string} options.contextMenu the id of a menuStructure which will be used as a context-menu
     * @param {string} options.titleHeader specifies which panelHeader to use. when empty, then uses the header of its own panel. if "none" then no header at all. if "parent" it uses the header of the parent panel
     * @param {string} options.floatingActionButton the id of a menustructure, which will be rendered as a FAB
     * @param {string} options.LayoutOptions List of multi-layout options for the object.
     * @param {string} options.panelMenu comma separated list of menu-structures which will be shown as panelHeader-Buttons. </br>
     * Can also contain the flag #NoDropDown that specifies that the menu should not be loaded as a dropdown but each menu item should be added in the panel-Header. </br>
     * For example: </br>
     * <code>menuStructSave,menuSettings#NoDropDown,menuLookup</code> </br>
     * The buttons support font icons with the following attributes: </br>
     * 1. Css attributes, defined like this: fa fa-user#color:red </br>
             * 2. Css classes, defined like this: fa fa-user#_style:module_prod
             * 3. Stacked font icons, defined like this: fas fa-circle$fas fa-flag. Both icons also support Css attributes or Css classes, like this: fas fa-circle#color:red$fas fa-flag#_style:module_prod </br>
     * @param {string} options.MapAddress Address fields for GoogleMap visualization
     * @param {string} options.MapName Name fields (comma separated) for showing a name in google maps
     */
    ak_map: function(options) {
      const oSelf = this,
        defaults = { page: 0 };

      this.opt = $.extend({}, defaults, options.att);
      this.parent = options.parent;

      this.registerDynObject = true;

      // get parent
      const oParent = oSelf.parent.dhx;
      if (oParent) {

        // -> bind map to layout

        oParent.attachHTMLString('<div class="mapbox"></div>');

        const mapBox = $(this.parent.dhx.cell).find('.mapbox');
        const myLatLng = { lat: -34.397, lng: 150.644 };

        const oMap = new google.maps.Map(mapBox[0], {
          zoom: 13,
          center: myLatLng,
          mapTypeId: google.maps.MapTypeId.ROADMAP
        });

        oSelf.parent.setOption('title', oSelf.opt.mapname);

        $.extend(oSelf, { dhx: oMap });

      } else
        console.error('No valid parent for map ');

    }
  });

  $.ak_map.prototype = {
    cursorChange: function() {
      if (!this.opt.address)
        this.opt.address = 'ort,strasse';

      if (!this.opt.mapname)
        this.opt.mapname = 'anschrift';

      // get actual element
      const oMap = this.dhx,
        oSource = this.dataSource,
        cIndex = oSource.getIndex();

      let cAddress = '',
        cName = '';
      if (cIndex) {
        const cAFields = this.opt.address.split(',');
        for (const i in cAFields) {
          if (cAddress != '')
            cAddress += ', ';
          cAddress += oSource.getFieldValue(cAFields[i]);
        }
        const cNFields = this.opt.mapname.split(',');
        for (const i in cNFields) {
          if (cName != '')
            cName += ', ';
          if (cNFields[i])
            cName += oSource.getFieldValue(cNFields[i]);
        }
        if (cName == '')
          cName = oSource.getFieldValue('selfdesc');

        // geocode it
        const oGeoCoder = new google.maps.Geocoder();
        oGeoCoder.geocode({ 'address': cAddress }, (response, status) => {
          if (status != google.maps.GeocoderStatus.OK)
            akioma.notification({ type: 'error', title: 'Adresse', text: `Sorry, unable to geocode:<br /> ${cAddress}<br />Reason: ${status}` });
          else {
            const oLocation = response[0].geometry.location;

            // set Marker
            new google.maps.Marker({
              map: oMap,
              position: oLocation,
              title: cName
            });

            // Show position
            oMap.setCenter(oLocation);

            // set the panel title
            oMap.akElm.parent.setOption('title', cName);
          }
        });
      }
    },

    destroy: function() {
    }
  };
})(jQuery, jQuery);
