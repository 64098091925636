// ********************* container ********************
$.extend({
  ak_formGrid: function(options) {
    const defaults = {};

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;
    this.view = 'formGrid';

    const oParent = this.parent;
    if (oParent) {
      oParent.prop.fields.push({
        type: 'container',
        inputTop: parseInt (this.opt.top),
        inputLeft: parseInt (this.opt.left),
        inputWidth: parseInt(this.opt.width),
        inputHeight: parseInt(this.opt.height),
        label: this.opt.label,
        labelTop: parseInt(this.opt.top),
        labelLeft: oParent.labelLeft(this),
        className: 'w4-formField w4-inputField',
        name: this.opt.name,
        position: 'label-top',
        disabled: this.opt.disabled,
        hidden: (this.opt.visible == false),
        tooltip: (this.opt.tooltip) ? this.opt.tooltip : ''
      });
    }
  }
});

// methods for container
$.ak_formGrid.prototype = {

  finishConstruct: function() {
    const oSelf = this;
    const oParent = akioma.getForm(this);
    const oSource = oParent.dataSource.dhx;

    const oGrid = new dhtmlXGridObject(this.parent.dhx.getContainer(this.opt.name));
    oGrid.setHeader(this.opt.columnsName);
    oGrid.setColumnIds(this.opt.columnsIds);
    oGrid.setInitWidths(this.opt.columnsWidth);
    oGrid.setSizes();
    oGrid.enableAutoHeight(false);
    oGrid.enableAutoWidth(true);
    oGrid.enableMultiline(true);
    oGrid.init();
    oGrid.sync(oSource);
    oGrid.akElm = this;

    let $itemcont;
    try {
      $itemcont = $(oGrid.entBox).closest('.dhxform_container.gridbox').parent().parent();
      $itemcont.addClass('active');
    } catch (e) {
      akioma.log.error('No itemcont found for : ', oSelf.opt.name);
    }

    oGrid.attachEvent('onRowSelect', () => {
      $itemcont.addClass('active').addClass('focusedinp');
    });

    if (oSelf.opt.contextMenu != undefined) {
      const cPointer = `pointer-menu-${oSelf.opt.id}`;
      $(oGrid.entBox).after(`<i class="fa fa-ellipsis-v more-formGrid-btn" id="${cPointer}"></i>`);

      oSelf.createContextMenu(oSelf.opt.contextMenu, oGrid);

      oGrid.attachEvent('onMouseOver', (id, ind) => {
        const oCell = oGrid.cells(id, ind);
        const iTop = $(oGrid.hdr).height() + $(oCell.cell).parent()[0].offsetTop + $(oCell.cell).closest('.dhxform_container.gridbox')[0].offsetTop + ($(oCell.cell).parent().height() / 2) - $(oCell.cell).closest('.objbox').scrollTop();
        $(`#${cPointer}`).css({ 'top': iTop });
        $(`#${cPointer}`).show();
      });

      $(oGrid.entBox).parent().on('mouseleave', () => {
        $(`#${cPointer}`).hide();
      });
    }

    oGrid.attachEvent('onXLE', () => {
      let i = 0;
      const initialInputHeight = $itemcont[0].clientHeight - oGrid.entBox.clientHeight;
      let newGridHeight = oGrid.entBox.firstChild.clientHeight;

      oGrid.forEachRow(row_id => {
        if (i < 3) {
          const c = oGrid.cellById(row_id, 0); // id - the row id
          newGridHeight += c.cell.clientHeight + 1;
          i++;
        }
      });
      $itemcont[0].clientHeight = newGridHeight + initialInputHeight;
      oParent.dhx.setItemHeight(oSelf.opt.name, 200);
    });

  },

  createContextMenu: function(menuStructure) {
    const oSelf = this;
    const cPointer = `pointer-menu-${oSelf.opt.id}`;
    const aMainMenuOptions = [];

    $(`#${cPointer}`).on('click', function(e) {
      e.preventDefault();

      let mainMenu;
      const oElm = {};
      oElm.x = $(this).offset().left;
      oElm.y = $(this).offset().top;


      // clicking grid row first
      try {
        $(document.elementFromPoint(oElm.x - 10, oElm.y)).click();
      } catch (e) {
        akioma.log.error(e);
      }

      function onButtonClick(menuitemId) {
        mainMenu.applyAction(menuitemId, oSelf);
        return true;
      }

      const onfinish = function() {

        if (oSelf.menuContextLoaded == undefined) {
          oSelf.menuContextLoaded = true;
          mainMenu.scan((itemid, label, icon) => {
            aMainMenuOptions.push({ id: itemid, text: label, img: icon });
          });


          const oContextMenu = new dhtmlXMenuObject({ context: true });

          oContextMenu.setIconset('awesome');
          oContextMenu.loadStruct(aMainMenuOptions);
          oContextMenu.renderAsContextMenu();

          oContextMenu.attachEvent('onClick', onButtonClick);
          oSelf.menu = oContextMenu;
        }
        oSelf.menu.showContextMenu(oElm.x, oElm.y);

      };

      if (oSelf.menu)
        onfinish();

      else {
        const cID = menuStructure;
        mainMenu = app.controller.parseProc({
          view: 'menustructure',
          att: { id: cID },
          sub: []
        }, this);
        mainMenu.loadMenuElements(onfinish);
      }

      return false;

    });
  }
};
