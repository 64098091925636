$.extend({
  ak_ribbonbreak: function(options) {
    const defaults = {};

    this.opt = $.extend({}, defaults, options.att);
    this.parent = options.parent;

    // get parent
    const oParent = this.parent;
    if (oParent) {

      this.prop = { fields: [{}] };

      // console.log(["ribbon break:", this.opt.name, this.opt.title]);
      oParent.prop.fields.push({

        'id': this.opt.name,
        'type': 'newLevel',
        'text_pos': this.opt.textPos,
        'mode': this.opt.mode,
        'text': this.opt.title,
        'list': this.prop.fields

      });

      // append to elements in ribbon
      if (this.parent.elements)
        this.parent.elements.push(this);
    }
  }
});


$.ak_ribbonbreak.prototype = {
  // finish construct **********
  finishConstruct: function() {
    this.dhx = null;
  },

  endConstruct: function() {
    if (this.opt.visible == false)
      this.form.hideItem (this.opt.name);
  }
};
