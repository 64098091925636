/**
 * Akioma Control object store class
 * @class ObjectStore
 * @namespace akioma
 */
akioma.ObjectStore = function() {};
akioma.ObjectStore.prototype = {

  /**
   * Method for generating the control namespace
   * @private
   * @instance
   * @memberOf ObjectStore
   * @return {void}
   */
  generateNamespace() {
    if (this.view !== 'winframe' && this.view !== 'root') {
      if (this.parent) {

        if (this.parent.namespace) {
          this.namespace = this.parent.namespace.slice();
          this.objectsnamespace = this.parent.objectsnamespace.slice();
        } else {
          this.namespace = this.getParentNamespace();
          this.objectsnamespace = this.getParentObjectsNamespace();
        }

      } else {
        this.namespace = [];
        this.objectsnamespace = [];
      }

      try {
        if (this.opt.id) {
          if (this.namespace.length > 0)
            this.namespace.push('children');

          this.namespace.push(this.opt.id);
        }
        // if tab add index not name/label
        if (this.parent && this.parent.childs && this.view == 'tab')
          this.objectsnamespace.push(this.parent.childs.length + 1);
        else if (this.parent && this.parent.view == 'panel') {
          const cPanelCell = this.getAncestor('panel').opt.layout;
          this.objectsnamespace.push(cPanelCell);
        } else
          this.objectsnamespace.push(this.opt.name);

      } catch (e) {
        console.warn(e);
      }

      const namespace = this.getNamespace();
      this.filterednamespace = namespace.filter(element => element !== 'children');
    }
  },

  /**
   * Method for checking if control has enabled Vuex Module registration
   */
  hasModuleRegister() {
    return this.registerVuexModule;
  },

  /**
   * Return the parent's namespace
   * @private
   * @instance
   * @memberOf ObjectStore
   * @return {array}Namespace array
   */
  getParentNamespace() {
    try {
      if (this.parent && this.parent.namespace)
        return this.parent.namespace.slice();
      else if (this.parent == undefined || this.parent.view == 'root')
        return [];
      else
        return this.parent.getParentNamespace();
    } catch (e) {
      console.warn(e);
    }
  },

  /**
   * Return the parent's namespace
   * @private
   * @instance
   * @memberOf ObjectStore
   * @return {array}Namespace array
   */
  getParentObjectsNamespace() {
    try {
      if (this.parent && this.parent.objectsnamespace)
        return this.parent.objectsnamespace.slice();
      else if (this.parent == undefined || this.parent.view === 'root')
        return [];
      else
        return this.parent.getParentObjectsNamespace();
    } catch (e) {
      console.warn(e);
    }
  },

  /**
   * Returns the current control namespace
   * @public
   * @instance
   * @memberOf ObjectStore
   * @return {array} The control's namespace
   */
  getNamespace: function() {
    return [ 'swat', 'screens' ].concat(this.namespace);
  },

  /**
   * Returns the current control filtered namespace
   * @public
   * @instance
   * @memberOf ObjectStore
   * @return {array} The control's filtered namespace
   */
  getFilteredNamespace: function() {
    return this.getNamespace().filter(element => element !== 'children');
  },

  /**
   * Returns the current control namespace used for the userprofile settings saving
   * @public
   * @instance
   * @memberOf ObjectStore
   * @return {array} The control's namespace
   */
  getObjectsNamespace() {
    return [ 'swat', 'screens' ].concat(this.objectsnamespace);
  },

  getNestedObject(nestedObj, pathArr) {
    return pathArr.reduce((obj, key) =>
      (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
  },

  /**
   * Get the control vuex module to be able to register it dynamically
   * @param {string} cView The control's object type. eg. form, dataview, tabbar...
   * @instance
   * @private
   * @memberOf ObjectStore
   * @returns {VuexModule} The vuex module
   */
  _getControlVuexModule(cView) {
    switch (cView) {
      case 'datagrid':
      case 'datagrid2':
        return akioma.VuexModules.gridModule;
      case 'form':
        return akioma.VuexModules.formModule;
      case 'SwatSidebar':
        return akioma.VuexModules.sidebarModule;
      case 'businessEntity':
        return akioma.VuexModules.dataSourceModule;
      default:
        return akioma.VuexModules.commonModule;
    }
  },

  /**
   * Method used for registering the Vuex Module for the control
   * @instance
   * @private
   * @memberOf ObjectStore
   * @return {void}
   */
  registerModule() {
    try {
      const namespace = this.getNamespace();
      let nestedObj = this.getNestedObject(akioma.VuexStore.state, namespace);
      if (nestedObj == undefined) {
        const oVuexModule = this._getControlVuexModule(this.view);
        akioma.VuexStore.registerModule(namespace, oVuexModule);
        akioma.log.info('Registering module for ', this, namespace.join('/'));
        nestedObj = this.getNestedObject(akioma.VuexStore.state, namespace);
        this.oVuexState = nestedObj;
      }
    } catch (e) {
      console.warn(e);
    }
  },

  /**
   * Method used for unregistering the current object's Vuex Module
   * @instance
   * @private
   * @memberOf ObjectStore
   * @return {void}
   */
  unregisterModule() {
    const namespace = this.getNamespace();
    let nestedObj;
    if (this.namespace && this.namespace.length > 0)
      nestedObj = this.getNestedObject(akioma.VuexStore.state, namespace);

    if (this.oVuexState && nestedObj) {
      const destroyStore = item => {
        if (item.childs.length > 0) {
          item.childs.forEach(child => {
            destroyStore(child);
            if ([ 'tabbar', 'frame', 'tab' ].indexOf(this.view) === -1)
              child._destroyVuexModule();
          });
        }
      };
      if ([ 'window', 'tabbar', 'frame', 'tab' ].indexOf(this.view) > -1) {
        destroyStore(this);
        this.getAllChildrenByType([ 'tabbar', 'frame' ]).forEach(() => {
          this._destroyVuexModule();
        });
      }
    }
  },

  /**
   * Method to unregister current object Module
   * @instance
   * @memberof ObjectStore
   * @private
   */
  _destroyVuexModule() {
    const namespace = this.getNamespace();
    let nestedObj;
    if (this.namespace && this.namespace.length > 0)
      nestedObj = this.getNestedObject(akioma.VuexStore.state, namespace);

    if (this.oVuexState && nestedObj) {
      akioma.VuexStore.unregisterModule(namespace);
      delete this.oVuexState;
    }
  },

  /**
   * Method for calling vuex getters per object
   * @instance
   * @memberof ObjectStore
   * @return {any}
   */
  _getters(name, val) {
    const oSelf = this;
    const namespace = `${oSelf.filterednamespace.join('/')}/${name}`;
    let oRes;

    if (val)
      oRes = akioma.VuexStore.getters[namespace](val);
    else
      oRes = akioma.VuexStore.getters[namespace]();

    return oRes;
  },

  /**
   * Commits a Vuex store mutation on the control's vuex module
   * @instance
   * @public
   * @memberOf ObjectStore
   * @return void
   */
  _commit(name, val) {
    if (this.filterednamespace) {
      const namespace = `${this.filterednamespace.join('/')}/${name}`;
      akioma.VuexStore.commit(namespace, val);
    }
  },

  /**
   * Dispatches an action on the control's vuex module
   * @instance
   * @public
   * @memberOf ObjectStore
   * @return void
   */
  _dispatch(name, val) {
    const namespace = `${this.filterednamespace.join('/')}/${name}`;
    akioma.VuexStore.dispatch(namespace, {
      value: val,
      namespace: namespace.split('/')
    });
  },

  /**
   * Method for setting up the Vuex Module and binding the watchers
   * @instance
   * @public
   * @memberOf ObjectStore
   * @return void
   */
  setupVuexStore() {
    this.aWatchers = [];
    this.registerModule();
    this.bindWatchers();
  },

  /**
   * Method for destroying the Vuex Module and unbinding the watchers
   * @instance
   * @protected
   * @memberof ObjectStore
   */
  destroyVuexStore() {
    this.unbindWatchers();
    this.unregisterModule();
  },

  /**
   * Method that binds all the control watchers defined under the control
   * @instance
   * @memberOf ObjectStore
   * @private
   */
  bindWatchers() {
    if (this.componentOptions) {
      const watchers = this.componentOptions().watch;
      for (const key in watchers) {
        const watcher = this.createVuexWatcher(key, watchers[key]);
        this.aWatchers.push(watcher);
      }
      akioma.log.info('Binding watchers:', this, this.aWatchers);
    }
  },

  /**
   * Method for binding the watchers for the form fields
   * @memberOf ObjectStore
   * @instance
   */
  bindFormWatchers() {
    try {
      // add all form fields watchers
      if (this.view == 'form') {
        const aFields = this.getChildrenRegisterWatch();
        for (const f in aFields) {
          const el = aFields[f];
          const formWatchers = el.componentOptions().watch;

          for (const formKey in formWatchers) {
            const watcher = this.createVuexWatcher(formKey, formWatchers[formKey]);
            this.aWatchers.push(watcher);
          }
        }
        akioma.log.info('Binding watchers:', this, this.aWatchers);
      }
    } catch (e) {
      console.warn(e);
    }
  },

  /**
   * Method for returning all children elements that have watcher registration enabled
   * @memberof ObjectStore
   * @returns {array} Form fields that have watcher registration enabled
   */
  getChildrenRegisterWatch() {
    let aList = [];

    if (this.registerVuexWatcher)
      aList.push(this);

    for (const x in this.childs) {
      const oChild = this.childs[x];
      const aChildrenFound = oChild.getChildrenRegisterWatch();

      if (aChildrenFound.length > 0)
        aList = aList.concat(aChildrenFound);
    }

    return aList;
  },

  /**
   * Method for registering Vuex Watchers
   * @param {string} key Property/key to watch on inside the vuex store
   * @param {*} watchCallback Wacther callback function
   * @memberof ObjectStore
   * @return {VuexWatcher}
   */
  createVuexWatcher(key, watchCallback) {
    const aSplitKey = key.split('.');
    const oSelf = this;
    let params;
    let watchOptions = {};

    if (typeof (watchCallback) !== 'function') {
      params = watchCallback.params;
      watchOptions = watchCallback.watchOptions || {};
      watchCallback = watchCallback.fn;
    }

    const watcher = akioma.VuexStore.watch((state, getters) => {
      try {
        let namespaceOrigin;
        let namespace;
        let bState = false;
        let oReturn;

        if (aSplitKey[0] == 'state') {
          namespaceOrigin = state;
          namespace = oSelf.getNamespace();
          bState = true;
        } else if (aSplitKey[0] == 'getters') {
          namespaceOrigin = getters;
          namespace = oSelf.filterednamespace;
        }

        // get to the control namespace
        if (bState) {
          for (const x in namespace) {
            const namespaceEntry = namespace[x];
            namespaceOrigin = namespaceOrigin[namespaceEntry];
          }

          oReturn = namespaceOrigin;
          for (let j = 1; j < aSplitKey.length; j++)
            oReturn = oReturn[aSplitKey[j]];

        } else {
          const aGetterKey = oSelf.filterednamespace.slice();
          for (let j = 1; j < aSplitKey.length; j++)
            aGetterKey.push(aSplitKey[j]);

          if (namespaceOrigin && namespaceOrigin[aGetterKey.join('/')]) {
            if (params)
              oReturn = namespaceOrigin[aGetterKey.join('/')].apply(akioma.VuexStore, params);
            else
              oReturn = namespaceOrigin[aGetterKey.join('/')];
          }
        }

        return oReturn;
      } catch (e) {
        console.error(e);
      }
    }, watchCallback, watchOptions);

    return watcher;
  },

  /**
   * Method used for unbinding the watchers from the control
   * @memberof ObjectStore
   * @instance
   */
  unbindWatchers() {
    for (const x in this.aWatchers) {
      const watch = this.aWatchers[x];
      watch();
    }
    akioma.log.info('Unbinding watchers:', this, this.aWatchers);
    this.aWatchers = [];
  }
};
